const messagingTranslations = {
  conversation: {
    type: {
      label: "Kategorie",
      values: {
        submitCaseInfo: "Informationen/Neuigkeiten zum Fall",
        other: "Allgemeine Nachricht",
        questionNextSteps: "Rückfrage zum weiteren Vorgehen",
        legalQuestion: "Rechtliche Frage an den Anwalt",
        technicalQuestion: "Frage zur Servicewelt",
      },
    },
  },
};
export default messagingTranslations;
