import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { displayMediaObjectStyles } from "./displayMediaObjectStyle";
import IconButton from "@mui/material/IconButton";
import {
  ArrowBackIos,
  ArrowForwardIos,
  FileDownloadOutlined,
  RotateLeft,
  RotateRight,
  ZoomIn,
  ZoomOut,
  ZoomOutMap,
} from "@mui/icons-material";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Box, Button, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import EditableTitle from "./EditableTitle";
import ApiClient from "../../services/ApiClient";
import _ from "lodash";
import { apiGet } from "../../services/Api/apiCall";
import { mimeTypes } from "../../services/Files/fileService";

const DisplayMediaObject = ({
  mediaObject,
  closeDialog,
  switchImage,
  hasPreviousImage,
  hasNextImage,
  refreshMediaObjects,
  setMediaObjectToPreview,
  titleIsEditable,
}) => {
  const [imageRotation, setImageRotation] = useState(0);
  const [displayUrl, setDisplayUrl] = useState(mediaObject?.displayUrl);
  const [downloadUrl, setDownloadUrl] = useState(mediaObject?.downloadUrl);

  const PlaybackErrorMessage = () => (
    <Stack padding={4} spacing={2}>
      <Typography variant={"body1"}>
        {mimeTypes.video.includes(mediaObject.mimeType) ? "Das Video" : "Die Audiodatei"} konnte nicht geladen werden.
      </Typography>
      <a href={downloadUrl} target={"_blank"}>
        <Button variant={"contained"} fullWidth={false} startIcon={<FileDownloadOutlined />}>
          Hier herunterladen
        </Button>
      </a>
    </Stack>
  );

  const ShowVideo = ({ mediaObject }) => {
    const [videoError, setVideoError] = useState(false);
    if (videoError) {
      return <PlaybackErrorMessage />;
    }

    return (
      <Stack
        sx={{ ...displayMediaObjectStyles.multimediaContainer, ...displayMediaObjectStyles["rotate" + imageRotation] }}
      >
        <video controls onError={() => setVideoError(true)} height={"auto"} width={"800px"}>
          <source src={displayUrl} type={mediaObject.mimeType} />
          <PlaybackErrorMessage />
        </video>
      </Stack>
    );
  };

  const ShowAudio = ({ mediaObject }) => {
    const [playbackError, setPlaybackError] = useState(false);

    if (playbackError) {
      return <PlaybackErrorMessage />;
    }

    return (
      <Stack
        sx={{ ...displayMediaObjectStyles.multimediaContainer, ...displayMediaObjectStyles["rotate" + imageRotation] }}
      >
        <audio controls onError={() => setPlaybackError(true)}>
          <source src={displayUrl} type={mediaObject.mimeType} />
          <PlaybackErrorMessage />
        </audio>
      </Stack>
    );
  };

  useEffect(() => {
    if (!mediaObject) {
      return;
    }
    if (!mediaObject.displayUrl || !mediaObject.downloadUrl) {
      setDisplayUrl(null);
      setDownloadUrl(null);
      apiGet("media_objects", mediaObject.id).then((responseMediaObject) => {
        setDisplayUrl(responseMediaObject.displayUrl);
        setDownloadUrl(responseMediaObject.downloadUrl);
      });
    } else {
      setDisplayUrl(mediaObject.displayUrl);
      setDownloadUrl(mediaObject.downloadUrl);
    }
  }, [mediaObject]);

  if (!mediaObject || !displayUrl || !downloadUrl) {
    return null;
  }

  const rotateImage = (direction) => {
    if (direction === "clockwise") {
      if (imageRotation > 270) {
        setImageRotation(90);
        return;
      }
      setImageRotation(imageRotation + 90);
    }
    if (direction === "counterclockwise") {
      if (imageRotation < 90) {
        setImageRotation(270);
        return;
      }
      setImageRotation(imageRotation - 90);
    }
  };

  const ShowImage = ({ mediaObject }) => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      setIsLoading(true);
    }, [mediaObject]);

    if (mediaObject.mimeType === "application/pdf") {
      return (
        <Box sx={displayMediaObjectStyles.embedContainer}>
          {isLoading && (
            <Box sx={displayMediaObjectStyles.loadingWrapper}>
              <CircularProgress />
            </Box>
          )}
          <embed
            onLoad={() => setIsLoading(false)}
            src={displayUrl}
            type={mediaObject.mimeType}
            width="100%"
            height="95%"
            style={displayMediaObjectStyles["rotate" + imageRotation]}
          />
        </Box>
      );
    }

    if (mimeTypes.audio.includes(mediaObject.mimeType)) {
      return (
        <Box sx={displayMediaObjectStyles.embedContainer}>
          <ShowAudio mediaObject={mediaObject} />
        </Box>
      );
    }

    if (mimeTypes.video.includes(mediaObject.mimeType)) {
      return (
        <Box sx={displayMediaObjectStyles.embedContainer}>
          <ShowVideo mediaObject={mediaObject} />
        </Box>
      );
    }

    return (
      <Grid container justifyContent={"center"}>
        <Grid item>
          <TransformWrapper
            wheel={{
              disabled: true,
            }}
          >
            {({ zoomIn, zoomOut, resetTransform }) => (
              <>
                {isLoading && (
                  <Box sx={displayMediaObjectStyles.loadingWrapper}>
                    <CircularProgress />
                  </Box>
                )}
                <Grid container justifyContent={"center"} alignItems={"center"}>
                  <Grid item>
                    <IconButton onClick={zoomIn} size="large">
                      <ZoomIn />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={zoomOut} size="large">
                      <ZoomOut />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={resetTransform} size="large">
                      <ZoomOutMap />
                    </IconButton>
                  </Grid>
                </Grid>
                <TransformComponent>
                  <img
                    onLoad={() => setIsLoading(false)}
                    src={displayUrl}
                    alt={mediaObject.originalName}
                    style={{ ...displayMediaObjectStyles.image, ...displayMediaObjectStyles["rotate" + imageRotation] }}
                  />
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </Grid>
      </Grid>
    );
  };

  const handleTitleSave = async (newDescription) => {
    const updatedMediaObject = await ApiClient.put(mediaObject["@id"], {
      body: JSON.stringify({ description: newDescription }),
    });
    await refreshMediaObjects();
    setMediaObjectToPreview(updatedMediaObject);
  };

  return (
    <LegalbirdIoModal
      handleClose={closeDialog}
      open={!!mediaObject}
      title={
        titleIsEditable ? (
          <EditableTitle title={mediaObject.description} handleSave={handleTitleSave} />
        ) : (
          _.truncate(mediaObject.description, { length: 50 })
        )
      }
      hasActions={false}
      maxWidth={"md"}
    >
      <Box sx={displayMediaObjectStyles.imageDialog}>
        {(hasNextImage || hasPreviousImage) && (
          <Grid container>
            <Grid item xs={12}>
              <IconButton onClick={() => switchImage("previous")} disabled={!hasPreviousImage} size="large">
                <ArrowBackIos />
              </IconButton>
              <IconButton onClick={() => switchImage("next")} disabled={!hasNextImage} size="large">
                <ArrowForwardIos />
              </IconButton>
            </Grid>
          </Grid>
        )}
        <Box sx={displayMediaObjectStyles.imageContainer}>
          <ShowImage mediaObject={mediaObject} />
        </Box>
        <Box sx={displayMediaObjectStyles.buttonContainer}>
          <IconButton onClick={() => rotateImage("counterclockwise")} size="large">
            <RotateLeft />
          </IconButton>
          <IconButton onClick={() => rotateImage("clockwise")} size="large">
            <RotateRight />
          </IconButton>
        </Box>
      </Box>
    </LegalbirdIoModal>
  );
};

DisplayMediaObject.propTypes = {
  mediaObject: PropTypes.object,
  closeDialog: PropTypes.func.isRequired,
  switchImage: PropTypes.func,
  hasPreviousImage: PropTypes.bool,
  hasNextImage: PropTypes.bool,
  refreshMediaObjects: PropTypes.func,
  titleIsEditable: PropTypes.bool,
};

DisplayMediaObject.defaultProps = {
  switchImage: () => {},
  hasPreviousImage: false,
  hasNextImage: false,
  refreshMediaObjects: () => {},
  titleIsEditable: false,
  setMediaObjectToPreview: () => {},
};

export default DisplayMediaObject;
