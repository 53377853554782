import React from "react";
import _ from "lodash";
import { makeStyles } from "@mui/styles";
import paidTasksCaseStyle from "./paidTasksCaseStyle";
import {
  PAID_TASK_STATUS_ASSIGNED,
  PAID_TASK_STATUS_CREATED,
  PAID_TASK_STATUS_IN_REVIEW,
  PAID_TASK_STATUS_REQUESTED,
} from "../paidTaskStatus";
import PaidTaskCase from "./PaidTaskCase";
import ContentBox from "../../ContentBox/ContentBox";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";

const useStyles = makeStyles(paidTasksCaseStyle);
const PaidTasksCase = ({ product }) => {
  const classes = useStyles();
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();
  let paidTaskFilters = {
    backofficeCase: product.backofficeCase["@id"],
    "exists[deletedDate]": false,
    "status[0]": PAID_TASK_STATUS_CREATED,
    "status[1]": PAID_TASK_STATUS_ASSIGNED,
    "status[2]": PAID_TASK_STATUS_IN_REVIEW,
  };
  if (!currentUser.isExternal) {
    paidTaskFilters["status[3]"] = PAID_TASK_STATUS_REQUESTED;
  }
  const { data: paidTasks, isLoading } = useQuery(
    queryKeys.collection("paid_tasks", paidTaskFilters),
    () => fetchCollection("paid_tasks", paidTaskFilters),
    {
      enabled: !!product,
    }
  );

  const refreshFunction = () => queryClient.invalidateQueries(queryKeys.collection("paid_tasks", paidTaskFilters));

  if (!paidTasks || paidTasks["hydra:totalItems"] === 0) {
    return null;
  }

  const paidTasksFiltered = _.filter(paidTasks["hydra:member"], (paidTask) => {
    return (
      !currentUser.isExternal || paidTask.status === PAID_TASK_STATUS_CREATED || paidTask.assignee.id === currentUser.id
    );
  });
  const paidTasksOpen = _.filter(paidTasksFiltered, (paidTask) => {
    return (
      paidTask.status === PAID_TASK_STATUS_CREATED ||
      paidTask.status === PAID_TASK_STATUS_ASSIGNED ||
      paidTask.status === PAID_TASK_STATUS_REQUESTED
    );
  });
  const paidTasksInReview = _.filter(paidTasksFiltered, (paidTask) => {
    return paidTask.status === PAID_TASK_STATUS_IN_REVIEW;
  });

  return (
    <ContentBox headline="Ausgeschriebene Aufgaben">
      {paidTasksOpen.length > 0 ? (
        _.map(paidTasksOpen, (paidTask) => (
          <div className={classes.activityContainer} key={paidTask.id}>
            <PaidTaskCase paidTask={paidTask} refreshFunction={refreshFunction} />
          </div>
        ))
      ) : (
        <div className={classes.faded}>Keine Aufgabe vorhanden</div>
      )}
      <div className={classes.horizontalLine} />
      <div className={classes.headline}>Abzunehmende Aufgaben</div>
      {paidTasksInReview.length > 0 ? (
        _.map(paidTasksInReview, (paidTask) => (
          <div className={classes.activityContainer} key={paidTask.id}>
            <PaidTaskCase paidTask={paidTask} refreshFunction={refreshFunction} />
          </div>
        ))
      ) : (
        <div className={classes.faded}>Keine Aufgabe vorhanden</div>
      )}
    </ContentBox>
  );
};
export default PaidTasksCase;
