import { Message } from "../../types/Messaging/Message";
import { Box, Paper, Stack, Typography } from "@mui/material";
import moment from "moment";
import caseMessageStyle from "./caseMessageStyle";
import React from "react";
import MessageUserReadStatus from "../../types/Messaging/MessageUserReadStatus";
import DisplayableMediaObjects from "../MediaObject/DisplayableMediaObjects";

function CaseMessage({ message }: { message: Message }) {
  const isCustomerMessage = message.senderType === "customer";
  const customerReadTime = message.userReadStatus.find(
    (status: MessageUserReadStatus) => status.messagingUser?.messagingUserType === "customer"
  )?.created;
  return (
    <>
      <Box
        sx={{
          ...caseMessageStyle.messageContainer,
          ...(isCustomerMessage ? caseMessageStyle.otherMessageContainer : caseMessageStyle.ownMessageContainer),
        }}
      >
        <Paper
          elevation={0}
          sx={{
            ...caseMessageStyle.messageBubble,
            ...(isCustomerMessage ? caseMessageStyle.otherMessageBubble : caseMessageStyle.ownMessageBubble),
          }}
        >
          <Stack display={"flex"} direction={"row"} justifyContent={"space-between"}>
            <Typography fontWeight={"bold"}>
              {message.senderType === "system" ? "Legalbird" : message.sender.messagingUserFullName}
            </Typography>
            <Typography>{moment(message.created).format("DD.MM.YYYY, HH:mm") + " Uhr"}</Typography>
          </Stack>
          <Box
            sx={{ overflowWrap: "anywhere", margin: "2rem 0" }}
            dangerouslySetInnerHTML={{ __html: message.messageText }}
          />
          {message.attachments && <DisplayableMediaObjects mediaObjects={message.attachments} />}
        </Paper>
        {!isCustomerMessage && customerReadTime && (
          <>
            <Box sx={caseMessageStyle.readBox}>
              <Typography>Gelesen: {moment(customerReadTime).format("DD.MM.YYYY, HH:mm") + " Uhr"}</Typography>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

export default CaseMessage;
