import * as PropTypes from "prop-types";
import { previewCaseFile } from "../../services/Files/fileService";
import React, { useState } from "react";
import ButtonLoading from "../Button/ButtonLoading";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import makeStyles from '@mui/styles/makeStyles';
import previewButtonStyle from "./previewButtonStyle";

const useStyles = makeStyles(previewButtonStyle);

const PreviewButton = ({
  product,
  documentType,
  documentIdentifier,
  previewFunction,
  buttonText,
  modalHeadline,
  modalSize,
  buttonProps,
}) => {
  const classes = useStyles();

  const [open, setIsOpen] = useState(false);
  const [src, setSrc] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const openPreview = async () => {
    setIsLoading(true);
    const base64src = await previewFunction({ product, documentType, documentIdentifier });
    setSrc(base64src);
    setIsOpen(true);
    setIsLoading(false);
  };

  return (
    <>
      <ButtonLoading onClick={openPreview} isLoading={isLoading} {...buttonProps}>
        {buttonText}
      </ButtonLoading>
      <LegalbirdIoModal
        handleClose={() => setIsOpen(false)}
        open={open}
        title={modalHeadline}
        hasActions={false}
        maxWidth={modalSize}
      >
        <div className={classes.imageContainer}>
          <div className={classes.embedContainer}>
            <embed src={src} type="application/pdf" width="100%" height="100%" />
          </div>
        </div>
      </LegalbirdIoModal>
    </>
  );
};

PreviewButton.propTypes = {
  product: PropTypes.object.isRequired,
  documentIdentifier: PropTypes.string.isRequired,
  documentType: PropTypes.oneOf(["pdf", "email"]),
  previewFunction: PropTypes.func,
  buttonText: PropTypes.string,
  modalHeadline: PropTypes.string,
  modalSize: PropTypes.oneOf(["xs", "md", "xl"]),
  buttonProps: PropTypes.object,
};

PreviewButton.defaultProps = {
  documentType: "pdf",
  previewFunction: previewCaseFile,
  buttonText: "Vorschau Dokument",
  modalHeadline: "Vorschau Dokument",
  modalSize: "xl",
  buttonProps: {},
};

export default PreviewButton;
