import customerTranslations from "./customerTranslations";
import basicCaseTranslations from "./basicCaseTranslations";
import contractClaimTranslations from "./contractClaimTranslations";

const otherContractTranslations = {
  label: "Sonstiger Vertrag",
  endOfContractDate: {
    label: "Datum Vertragsende",
  },
  customer: customerTranslations,
  ...basicCaseTranslations,
  ...contractClaimTranslations,
};

export default otherContractTranslations;
