import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  headline: {
    textAlign: "center",
    fontSize: "1.25rem",
  },
  editorWrapper: {
    backgroundColor: "#f8f4d0",
  },
  editor: {
    minHeight: "10rem",
  },
  dialogContainer: {
    padding: "1rem",
    maxWidth: 700,
  },
  checkbox: {
    color: "#137f7b",
  },
}));

export default useStyles;
