import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { Calendar } from "react-multi-date-picker";
import { formValue } from "../../services/formServiceFunctions";
import _ from "lodash";
import useForm from "../../hooks/useForm";
import ValidatorForm from "../Validator/ValidatorForm";
import moment from "moment";
import ButtonLoading from "../Button/ButtonLoading";
import ContentBox from "../ContentBox/ContentBox";
import { apiGet, apiPut } from "../../services/Api/apiCall";
import useStyles from "./calendarStyle";
import Holidays from "../../types/Holidays";

export default function HolidaySelection({}) {
  const calendarClasses = useStyles();
  const initialValues = {
    holidays: [],
  };
  const onSubmit = async ({ values }: { values: Record<string, any> }) => {
    const holidays = _.map(values.holidays, (holiday) => moment(holiday.toDate()).format("YYYY-MM-DD"));
    await apiPut("holidays", 1, {
      holidays: holidays,
    });
  };

  const { values, handleChange, handleSubmit, updateAllValues, isLoading } = useForm({
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    apiGet("holidays", 1).then((holidaysResource) => {
      const convertedHolidays = _.map(holidaysResource.holidays, (holidays) => moment(holidays).valueOf());
      updateAllValues({
        holidays: convertedHolidays,
      });
    });
  }, []);

  return (
    <ContentBox headline="Feiertage einpflegen">
      <ValidatorForm onSubmit={handleSubmit}>
        <Grid container justifyContent={"center"} spacing={2}>
          <Grid item xs={12}>
            <Calendar
              className={calendarClasses.calender}
              value={formValue(values, "holidays")}
              onChange={(dates) =>
                handleChange({
                  target: {
                    name: "holidays",
                    value: dates,
                  },
                })
              }
              showOtherDays
              weekDays={["S", "M", "D", "M", "D", "F", "S"]}
              months={[
                "Januar",
                "Februar",
                "März",
                "April",
                "Mai",
                "Juni",
                "Juli",
                "August",
                "September",
                "Oktober",
                "November",
                "Dezember",
              ]}
              mapDays={({ date }) => {
                const isWeekend = _.includes([0, 6], date.weekDay.index);
                if (isWeekend) {
                  return {
                    disabled: true,
                  };
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonLoading isLoading={isLoading} variant="contained" type="submit">
              Speichern
            </ButtonLoading>
          </Grid>
        </Grid>
      </ValidatorForm>
    </ContentBox>
  );
}
