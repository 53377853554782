import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  listContainer: {
    paddingBottom: "2rem",
  },
  button: {
    marginLeft: "auto",
    marginRight: "1rem",
    display: "block",
  },
  activityLogEntries: {
    marginLeft: "2rem",
  },
  changeRow: {
    margin: ".3rem 0",
  },
  note: {
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    overflowWrap: "break-word",
  },
});

export default useStyles;
