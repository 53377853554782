import React from "react";
import { Divider } from "@mui/material";
import _ from "lodash";
import { makeStyles } from "@mui/styles";
import paidTaskListStyle from "./paidTaskListStyle";
import Typography from "@mui/material/Typography";
import { PAID_TASK_STATUS_REQUESTED } from "../../../components/PaidTask/paidTaskStatus";
import PaidTaskListItem from "./PaidTaskListItem";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";

const useStyles = makeStyles(paidTaskListStyle);

const RequestedPaidTasks = () => {
  const classes = useStyles();
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();
  const paidTasksFilters = {
    "exists[deletedDate]": false,
    status: PAID_TASK_STATUS_REQUESTED,
    requestedUser: currentUser["@id"],
  };
  const { data: paidTasks, isLoading } = useQuery(queryKeys.collection("paid_tasks", paidTasksFilters), () =>
    fetchCollection("paid_tasks", paidTasksFilters)
  );

  if (!paidTasks || !paidTasks["hydra:member"] || paidTasks["hydra:member"].length === 0) {
    return null;
  }

  return (
    <>
      <Typography variant={"h2"} align={"center"}>
        Anfragen für Aufgaben
      </Typography>
      <br />
      {_.map(paidTasks["hydra:member"], (paidTask, index) => (
        <React.Fragment key={paidTask.id}>
          <PaidTaskListItem paidTask={paidTask} hideCase isExternalView />
          {index !== paidTasks.length - 1 && (
            <div className={classes.divider}>
              <Divider />
            </div>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
export default RequestedPaidTasks;
