import { InputAdornment } from "@mui/material";
import React from "react";
import { buildPaths } from "./organizationFields";
import {
  beaSafeIdFieldDefault,
  isPhoneNumberWithoutSpecialCharactersDefault,
  numberFieldDefault,
  requiredFieldDefault,
} from "../../../validationRules";

export function courtFields({ path = "", translationPrefix = "" }: { path?: string; translationPrefix?: string }) {
  const { dataPath, translationPath } = buildPaths(path, translationPrefix);
  return [
    {
      type: "ValidatorTextField",
      path: `${dataPath}name`,
      translationPath: `${translationPath}name`,
      validators: requiredFieldDefault,
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}address.streetAddress`,
      translationPath: `${translationPath}address.streetAddress`,
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}address.postalCode`,
      translationPath: `${translationPath}address.postalCode`,
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}address.addressLocality`,
      translationPath: `${translationPath}address.addressLocality`,
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}address.postOfficeBoxNumber`,
      translationPath: `${translationPath}address.postOfficeBoxNumber`,
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}telephone`,
      translationPath: `${translationPath}telephone`,
      isDisabled: () => true,
      validators: isPhoneNumberWithoutSpecialCharactersDefault,
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}beaSafeId`,
      translationPath: `${translationPath}beaSafeId`,
      validators: beaSafeIdFieldDefault,
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}fax`,
      translationPath: `${translationPath}fax`,
      isDisabled: () => true,
      additionalProps: {
        InputProps: {
          startAdornment: <InputAdornment position="start">+49</InputAdornment>,
        },
      },
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}advoAssistId`,
      translationPath: `${translationPath}advoAssistId`,
      validators: numberFieldDefault,
    },
  ];
}
