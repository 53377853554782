import React from "react";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { getBadges, getCaseLabel } from "../../../services/Product/ProductService";
import { AbstractCase } from "../../../types/AbstractCase";
import BadgesList from "../../Badges/BadgesList";
import InsuranceData from "../../Case/CaseProgressBar/InsuranceData";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import AgentPicker from "../../AgentPicker/AgentPicker";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";

type CaseHeaderProps = {
  product: AbstractCase;
};

export default function CaseHeader({ product }: CaseHeaderProps) {
  const currentUser = useCurrentUser();
  const { accountManagers, lawyers } = useBackofficeUser();

  return (
    <Paper sx={{ width: "100%", padding: "2rem" }}>
      <Stack spacing={2}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant="h1">{getCaseLabel(product)}</Typography>
          <Stack direction={"row"}>
            {product.backofficeCase?.lawyer && (
              <AgentPicker
                label={"Anwalt"}
                currentAgent={product.backofficeCase?.lawyer}
                product={product}
                type={"lawyer"}
                agentList={lawyers}
                disabled
              />
            )}
            {product.backofficeCase?.accountManager && (
              <AgentPicker
                label={"Zuständiger"}
                currentAgent={product.backofficeCase?.accountManager}
                product={product}
                type={"accountManager"}
                agentList={accountManagers}
                disabled
              />
            )}
          </Stack>
        </Stack>
        {!!product.opposingParty && <>./. {product.opposingParty}</>}
        <BadgesList badges={getBadges(product, currentUser)} />
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Box>Unser Zeichen: {product.reference}</Box>
          {product.paymentType === "insurance" && (
            <Box>
              <InsuranceData product={product} />
            </Box>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
}
