import React from "react";
import { Link } from "react-router-dom";
import { Button, Paper } from "@mui/material";
import externals404PageStyle from "./externals404PageStyle";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(externals404PageStyle);

const Externals404Page = () => {
  const classes = useStyles();
  return (
    <div className={classes.grey}>
      <Paper className={classes.noAccessBox}>
        <Typography variant={"h3"}>Leider ist auf diese Seite kein Zugriff möglich</Typography>
        <Button component={Link} variant={"text"} to={"/services/extern"}>
          Zurück zur Übersicht
        </Button>
      </Paper>
    </div>
  );
};

export default Externals404Page;
