const STATUS_CREATED = 10;
const STATUS_DATA_COMPLETED = 20;
const STATUS_POWER_OF_ATTORNEY_GRANTED = 30;
const STATUS_DOCUMENTS_COMPLETE = 40;
const STATUS_CHECKED = 50;
const STATUS_SUBMITTED = 60;
const STATUS_PAID = 70;
const STATUS_DATE_SET_SETTLEMENT = 80;
const STATUS_DATE_SET_TRIAL = 90;
const STATUS_DECISION_MADE = 100;
const STATUS_COMPLETED = 110;

export {
  STATUS_CREATED,
  STATUS_DATA_COMPLETED,
  STATUS_POWER_OF_ATTORNEY_GRANTED,
  STATUS_DOCUMENTS_COMPLETE,
  STATUS_CHECKED,
  STATUS_SUBMITTED,
  STATUS_PAID,
  STATUS_DATE_SET_SETTLEMENT,
  STATUS_DATE_SET_TRIAL,
  STATUS_DECISION_MADE,
  STATUS_COMPLETED,
};
