import * as stagesCase from "../../Stages/StagesCase";
import { formValue } from "../../formServiceFunctions";
import mobileProviders from "../../mobileProviders";

const providerProductSpecificDefinition = (product) => [
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.providerType`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [
      { value: "mobile" },
      { value: "dsl" },
      { value: "electricity" },
      { value: "gas" },
      { value: "other" },
    ],
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.reasonForDispute`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [
      { value: "priceIncrease" },
      { value: "cancellationByProvider" },
      { value: "serviceNotAsAgreed" },
      { value: "cancellationRefused" },
      { value: "invoiceIncorrect" },
      { value: "schufaPositiveData" },
      { value: "other" },
    ],
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.mobileProviderOrganizationId`,
    isDisabled: () => true,
    options: () =>
      mobileProviders.map((provider) => {
        return { value: provider.value };
      }),
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.contractSignedBy`,
    isDisabled: () => true,
    isHidden: ({ values, product }) =>
      formValue(values, `${product.productClassName}.reasonForDispute`) !== "extraOptionGas",
    options: () => [{ value: "client" }, { value: "landlord" }],
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.extraOptionGasNotification`,
    isDisabled: () => true,
    isHidden: ({ values, product }) =>
      formValue(values, `${product.productClassName}.reasonForDispute`) !== "extraOptionGas",
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.contractHasPriceFixing`,
    isDisabled: () => true,
    isHidden: ({ values, product }) =>
      formValue(values, `${product.productClassName}.reasonForDispute`) !== "extraOptionGas" ||
      formValue(values, `${product.productClassName}.contractSignedBy`) !== "client",
    options: () => [{ value: "yes" }, { value: "no" }, { value: "unknown" }],
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.cancellationReceivedDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }) =>
      formValue(values, `${product.productClassName}.reasonForDispute`) !== "cancellationByProvider",
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.cancellationDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }) =>
      formValue(values, `${product.productClassName}.reasonForDispute`) !== "cancellationRefused",
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.endOfContractDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }) =>
      formValue(values, `${product.productClassName}.reasonForDispute`) !== "cancellationRefused",
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.opponentReactionToCancellation`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }) =>
      formValue(values, `${product.productClassName}.reasonForDispute`) !== "cancellationRefused",
    options: () => [{ value: "noReaction" }, { value: "cancellationRefused" }, { value: "differentDate" }],
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.endOfContractDateOpponent`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }) =>
      formValue(values, `${product.productClassName}.reasonForDispute`) !== "cancellationRefused" ||
      formValue(values, `${product.productClassName}.opponentReactionToCancellation`) !== "differentDate",
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.priceIncreaseAnnouncementDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }) =>
      formValue(values, `${product.productClassName}.reasonForDispute`) !== "priceIncrease",
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.specialTerminationDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }) =>
      formValue(values, `${product.productClassName}.reasonForDispute`) !== "priceIncrease",
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.contractNumber`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.salesChannel`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }) =>
      formValue(values, `${product.productClassName}.reasonForDispute`) !== "schufaPositiveData",
    options: () => [
      { value: "providerWebsite" },
      { value: "comparisonPortal" },
      { value: "localStore" },
      { value: "phone" },
      { value: "other" },
    ],
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.schufaDataCopyDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }) =>
      formValue(values, `${product.productClassName}.reasonForDispute`) !== "schufaPositiveData",
  },
];

export default providerProductSpecificDefinition;
