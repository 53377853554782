import Dialog from "@mui/material/Dialog";
import React from "react";
import { legalbirdIoModalStyle } from "./legalbirdIoModalStyle";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import Button from "@mui/material/Button";
import { Box, DialogActions, DialogContent, DialogTitle, Divider, Stack } from "@mui/material";
import { DialogProps } from "@mui/material/Dialog/Dialog";

type LegalbirdIoModalProps = {
  children?: React.ReactNode;
  title: string | React.ReactNode;
  open: boolean;
  handleClose: Function;
  hasActions?: boolean;
  hideCancelButton?: boolean;
  submitButton: React.ReactNode;
  disableBackdropClick?: boolean;
  actionsInfo?: React.ReactNode;
} & DialogProps;

const LegalbirdIoModal = ({
  children,
  title,
  open,
  handleClose = () => {},
  hasActions = true,
  hideCancelButton = false,
  submitButton,
  disableBackdropClick = false,
  actionsInfo = null,
  ...rest
}: LegalbirdIoModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason !== "backdropClick" || !disableBackdropClick) {
          handleClose();
        }
      }}
      {...rest}
    >
      <DialogTitle>
        <Box sx={legalbirdIoModalStyle.flexContainer}>
          <Box sx={legalbirdIoModalStyle.fixedSizeFlexItem} />
          <Box sx={legalbirdIoModalStyle.flexHeadline}>{title}</Box>
          <Box sx={legalbirdIoModalStyle.fixedSizeFlexItem}>
            <IconButton onClick={() => handleClose()} size="large">
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={legalbirdIoModalStyle.dialogContent}>{children}</DialogContent>
      {hasActions && (
        <>
          <Box sx={legalbirdIoModalStyle.dividerContainer}>
            <Divider sx={legalbirdIoModalStyle.divider} />
          </Box>
          {actionsInfo}
          <DialogActions>
            <Stack spacing={2} sx={legalbirdIoModalStyle.stack}>
              {submitButton}
              {!hideCancelButton && <Button onClick={() => handleClose()}>Abbrechen</Button>}
            </Stack>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default LegalbirdIoModal;
