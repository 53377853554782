import React, { useState } from "react";
import * as PropTypes from "prop-types";
import _ from "lodash";
import MediaObject from "./MediaObject";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import DisplayMediaObjects from "../MediaObject/DisplayMediaObjects";
import { queryKeys } from "../../services/ReactQuery/reactQueryService";
import { useQueryClient } from "@tanstack/react-query";

export default function MediaObjectListView({ product, mediaObjects }) {
  const [showAllMediaObjects, setShowAllMediaObjects] = useState(false);
  const [mediaObjectToPreview, setMediaObjectToPreview] = useState(null);
  const queryClient = useQueryClient();

  const toggleShowAll = () => {
    setShowAllMediaObjects(!showAllMediaObjects);
  };

  const handleSetMediaObjectToPreview = (mediaObject) => {
    setMediaObjectToPreview(mediaObject);
  };

  const mediaObjectsToDisplay = showAllMediaObjects ? mediaObjects : mediaObjects.slice(0, 3);

  const refreshMediaObjects = async () => {
    await queryClient.invalidateQueries(queryKeys.collections("media_objects"));
  };

  return (
    <>
      {mediaObjectsToDisplay.length > 0 ? (
        _.map(mediaObjectsToDisplay, (mediaObject) => (
          <Box key={mediaObject.id} sx={{ marginBottom: "2rem" }}>
            <MediaObject
              mediaObject={mediaObject}
              updateMediaObjects={refreshMediaObjects}
              handleOpenMediaObject={handleSetMediaObjectToPreview}
            />
          </Box>
        ))
      ) : (
        <Box sx={{ textAlign: "center", color: "#dcdcdc", margin: "2rem" }}>
          <Typography variant={"h4"}>Es sind noch keine Dokumente vorhanden</Typography>
        </Box>
      )}
      {mediaObjects.length > 3 && (
        <Button onClick={toggleShowAll} sx={{ margin: "0 auto" }}>
          {showAllMediaObjects ? "Weniger Anzeigen" : "Alle Anzeigen"}
        </Button>
      )}
      <DisplayMediaObjects
        setMediaObjectToDisplay={setMediaObjectToPreview}
        mediaObjects={mediaObjects}
        mediaObjectToDisplay={mediaObjectToPreview}
        titleIsEditable
        refreshMediaObjects={refreshMediaObjects}
      />
    </>
  );
}

MediaObjectListView.propTypes = {
  mediaObjects: PropTypes.array.isRequired,
  product: PropTypes.object.isRequired,
};

MediaObjectListView.defaultProps = {};
