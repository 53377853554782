import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Paper, Tab, Tabs, useMediaQuery } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import navigationExtensionAllDataStyle from "../NavigationBar/navigationExtensionAllDataStyle";

const useStyles = makeStyles(navigationExtensionAllDataStyle);

const NavigationExtensionPaidTask = () => {
  const [topOffset, setTopOffset] = useState(69);
  const smDown = useMediaQuery("(max-width:1149px)");
  const classes = useStyles({ top: topOffset });

  return (
    <Paper square className={classes.tabs} ref={(el) => el && setTopOffset(el.getBoundingClientRect().top)}>
      <Tabs variant={smDown ? "scrollable" : "standard"} scrollButtons="auto" value={false} centered={!smDown}>
        <Tab component={Link} to={"#definition"} label={"Aufgabe"} />
        <Tab component={Link} to={"#submission"} label={"Bearbeitung"} />
        <Tab component={Link} to={"#approval"} label={"Abnahme"} />
      </Tabs>
    </Paper>
  );
};

export default NavigationExtensionPaidTask;
