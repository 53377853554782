import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import ValidatorSelect from "../../../Validator/ValidatorSelect";
import _ from "lodash";
import useForm from "../../../../hooks/useForm";
import Button from "@mui/material/Button";
import ApiClient from "../../../../services/ApiClient";
import { TextField } from "@mui/material";
import LegalbirdIoModal from "../../../Modal/LegalbirdIoModal";
import { apiPut } from "../../../../services/Api/apiCall";

const DivorceRelatedMediaObjects = ({ product, mediaObjects, refreshPage }) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [requestDocumentText, setRequestDocumentText] = useState("");

  let initialValues = {
    marriageCertificate: product.marriageCertificate ? product.marriageCertificate["@id"] : "__null__",
  };

  _.forEach(product.children, (child) => {
    initialValues[child["@id"]] = child.birthCertificate ? child.birthCertificate["@id"] : "__null__";
  });

  const handleUpdate = (withReset) => {
    let updateCalls = [];

    _.forEach(values, (value, key) => {
      if (initialValues[key] !== value) {
        let uri = product["@id"]; /* "/divorces/<id>" */
        let propertyName = "marriageCertificate";
        let fieldname = "marriageCertificate";
        let previousMediaObject = product.marriageCertificate;
        let description = "Heiratsurkunde";
        if (key !== "marriageCertificate") {
          uri = "divorce_" + _.trimStart(key, "/"); /* "/divorce_children/<id>" */
          propertyName = "birthCertificate";
          fieldname = "children.birthCertificate";
          const child = _.find(product.children, (child) => child["@id"] === key);
          previousMediaObject = child.birthCertificate;
          description = "Geburtsurkunde " + child.name;
        }

        const propertyValue = value !== "__null__" ? value : null;
        updateCalls.push(ApiClient.put(uri, { body: JSON.stringify({ [propertyName]: propertyValue }) }));

        if (previousMediaObject) {
          updateCalls.push(
            ApiClient.put(previousMediaObject["@id"], {
              body: JSON.stringify({
                description: previousMediaObject.description + " (ungültig)",
                fieldname: fieldname + ".previous",
              }),
            })
          );
        }

        if (value !== "__null__") {
          updateCalls.push(
            ApiClient.put(value, {
              body: JSON.stringify({
                description: description,
                fieldname: fieldname,
              }),
            })
          );
        }
      }
    });

    if (withReset) {
      updateCalls.push(
        apiPut("divorces", product.id, {
          requestDocumentText: requestDocumentText,
        })
      );
    }

    Promise.all(updateCalls).then(() => refreshPage());
  };

  const onSubmit = async ({ values }) => {
    let reset = false;
    _.forEach(values, (value) => {
      reset = reset || value === "__null__";
    });

    if (reset) {
      setConfirmationDialogOpen(true);
      return;
    }

    handleUpdate(false);
  };

  const { values, handleChange, handleSubmit, handleBlur, touchedValues } = useForm({
    initialValues,
    onSubmit,
    identifier: mediaObjects,
  });

  const BirthCertificateSelect = ({ child }) => {
    return (
      <ValidatorSelect
        key={child.id}
        label={"Geburtsurkunde " + child.name}
        name={child["@id"]}
        value={values[child["@id"]]}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        {_.map(mediaObjects, (mediaObject) => (
          <MenuItem
            key={mediaObject.id}
            value={mediaObject["@id"]}
            disabled={
              !!_.find(values, (value) => {
                return value === mediaObject["@id"];
              })
            }
          >
            {mediaObject.description}
          </MenuItem>
        ))}
        <MenuItem value={"__null__"}>Erneut anfordern</MenuItem>
      </ValidatorSelect>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <ValidatorSelect
          label={"Heiratsurkunde"}
          name={"marriageCertificate"}
          value={values.marriageCertificate}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {_.map(mediaObjects, (mediaObject) => (
            <MenuItem
              key={mediaObject.id}
              value={mediaObject["@id"]}
              disabled={
                !!_.find(values, (value) => {
                  return value === mediaObject["@id"];
                })
              }
            >
              {mediaObject.description}
            </MenuItem>
          ))}
          <MenuItem value={"__null__"}>Erneut anfordern</MenuItem>
        </ValidatorSelect>
        {_.map(product.children, (child) => (
          <BirthCertificateSelect key={child.id} child={child} />
        ))}
        <br />
        <br />
        <br />
        <Button variant={"contained"} type={"submit"} disabled={_.keys(touchedValues).length === 0}>
          Speichern und Abschicken
        </Button>
      </form>
      <LegalbirdIoModal
        handleClose={() => setConfirmationDialogOpen(false)}
        open={confirmationDialogOpen}
        title={"Dokumente neu anfordern?"}
        submitButton={
          <Button
            variant={"contained"}
            disabled={requestDocumentText.length <= 30}
            onClick={() => {
              setConfirmationDialogOpen(false);
              handleUpdate(true);
            }}
          >
            Dokumente anfordern
          </Button>
        }
      >
        <p className={"center"}>
          Der Kunde erhält eine E-Mail, dass er etwas neu einreichen muss. Bitte geben Sie im Feld unten genau an, was
          noch fehlt und was der Kunde beim Hochladen der Dokumente beachten soll.{" "}
          <strong>Bitte auf Rechtschreibung achten - der Kunde erhält diesen Text als Hinweis in der Email!</strong>
        </p>
        <TextField
          label={"Begründung/Text an den Kunden"}
          multiline
          rows={8}
          value={requestDocumentText}
          onChange={({ target }) => setRequestDocumentText(target.value)}
          placeholder={"Hinweise/Anregungen an den Kunden, was fehlt oder was neu hochgeladen werden muss."}
        />
      </LegalbirdIoModal>
    </>
  );
};

export default DivorceRelatedMediaObjects;
