import React from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

interface ButtonLoadingProps {
  isLoading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  [x: string]: any;
}

export default function ButtonLoading({ isLoading = false, children, disabled = false, ...rest }: ButtonLoadingProps) {
  return (
    <Button disabled={disabled || isLoading} {...rest}>
      {isLoading ? <CircularProgress size={28} /> : children}
    </Button>
  );
}
