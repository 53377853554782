export const costBoxStyles = {
  table: {
    margin: "2rem auto",
    maxWidth: 400,
  },
  tableCell: {
    fontSize: 10,
    padding: 0,
    borderBottom: "none",
  },
  tableRow: {
    height: 24,
  },
  disclaimer: {
    fontSize: 10,
    lineHeight: "1rem",
  },
  total: {
    fontSize: 12,
    fontWeight: 500,
  },
  tableCellValue: {
    fontSize: 10,
    padding: "0 !important",
    borderBottom: "none",
    textAlign: "right",
  },
  tableCellHeader: {
    paddingTop: "1rem",
  },
  underlined: {
    borderBottom: "1px solid #333333",
  },
  primary: {
    fontSize: 12,
    fontWeight: 500,
  },
  doubleUnderlinedRow: {
    height: 3,
    padding: 0,
  },
  doubleUnderlinedCell: {
    height: 3,
    borderBottom: "1px solid #333333",
    borderTop: "1px solid #333333",
    padding: 0,
  },
};
