import React, { useMemo } from "react";
import { makeStyles } from "@mui/styles";
import paidTaskTableStyle from "./paidTaskTableStyle";
import {
  getCaseLinkByBackofficeCase, getFullProductLabel,
  getProductData,
  getProductNameBy
} from "../../../services/Product/ProductService";
import { Avatar, Button, TableCell, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import { convertFromFloat } from "../../../services/formServiceFunctions";
import moment from "moment";
import {
  PAID_TASK_STATUS_APPROVED,
  PAID_TASK_STATUS_ASSIGNED,
  PAID_TASK_STATUS_CREATED,
  PAID_TASK_STATUS_REQUESTED,
} from "../paidTaskStatus";
import _ from "lodash";
import CasePaidTaskAccept from "../../CasePaidTask/CasePaidTaskAccept";
import { MoveToInboxOutlined, TaskAltOutlined } from "@mui/icons-material";

const useStyle = makeStyles(paidTaskTableStyle);

export default function ExternalPaidTaskTableRow({ paidTask, isMinimalView = false, isRequestedView = false }) {
  const classes = useStyle();
  const productClassName = useMemo(() => {
    return getProductNameBy("lbProductClass", paidTask.backofficeCase.lbProductClass);
  }, [paidTask.backofficeCase.lbProductClass]);
  const fullProductLabel = useMemo(() => {
    return getFullProductLabel({
      productClassName,
      productCategory: getProductData(productClassName, "productCategory")
    });
  }, [productClassName]);

  const getPaidTaskClasses = (paidTask) => {
    if (paidTask.status >= PAID_TASK_STATUS_APPROVED) {
      return classes.done;
    }

    if (paidTask.status === PAID_TASK_STATUS_CREATED && moment().isAfter(paidTask.assignmentDeadline, "day")) {
      return classes.overdue;
    }

    if (paidTask.status === PAID_TASK_STATUS_ASSIGNED && moment().isAfter(paidTask.doneDeadline, "day")) {
      return classes.overdue;
    }
  };

  const caseLink = getCaseLinkByBackofficeCase(paidTask.backofficeCase, "/extern");
  return (
    <TableRow className={getPaidTaskClasses(paidTask)}>
      <TableCell size={"small"} padding={"checkbox"}>
        <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main }}>
          {isRequestedView ? <MoveToInboxOutlined /> : <TaskAltOutlined />}
        </Avatar>
      </TableCell>
      <TableCell>
        {paidTask.status < PAID_TASK_STATUS_ASSIGNED ? (
          <>{paidTask.backofficeCase.label}</>
        ) : (
          <Link className={classes.link} to={caseLink}>
            {paidTask.backofficeCase.label}
          </Link>
        )}
      </TableCell>
      <TableCell>{fullProductLabel}</TableCell>
      <TableCell>{paidTask.subject}</TableCell>
      {!isMinimalView && <TableCell>{convertFromFloat(paidTask.fee)} €</TableCell>}
      {isRequestedView && <TableCell>{moment(paidTask.assignmentDeadline).format("DD.MM.YYYY")}</TableCell>}
      <TableCell>{moment(paidTask.doneDeadline).format("DD.MM.YYYY")}</TableCell>
      <TableCell>
        {_.includes([PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED], paidTask.status) ? (
          <CasePaidTaskAccept paidTask={paidTask} />
        ) : (
          <Button component={Link} className={classes.link} to={caseLink + "/ausschreibung/" + paidTask.id}>
            Details
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
}
