import customerTranslations from "./customerTranslations";
import documentTranslations from "./documentTranslations";
import abstractCaseTranslations from "./abstractCaseTranslations";
import personTranslations from "./personTranslations";

const shortTimeWorkTranslations = {
  label: "Erstberatung Kurzarbeit",
  customer: customerTranslations,
  ...documentTranslations,
  ...abstractCaseTranslations,
  personalTextMessage: {
    label: "Fragen und Anmerkungen",
  },
  client: personTranslations,
  petitioner: {
    gender: {
      label: "Anrede",
      values: {
        male: "Herr",
        female: "Frau",
      },
    },
    givenName: {
      label: "Vorname",
    },
    familyName: {
      label: "Nachname",
    },
    birthDate: {
      label: "Geburtsdatum",
    },
  },
  hasChildren: {
    label: "Kinder im Haushalt?",
  },
  taxClass: {
    label: "Steuerklasse",
    values: {
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
    },
  },
  reason: {
    label: "Grund",
    values: {
      corona: "Corona",
      other: "Anderes",
      unsure: "Nicht sicher",
    },
  },
  shortTimeWorkStatus: {
    label: "Status",
    values: {
      announced: "Angekündigt",
      applied_for: "Beantragt",
      implemented: "Eingeführt",
    },
  },
  affectedEmployees: {
    label: "Wer ist betroffen?",
    values: {
      just_me: "Nur ich",
      some: "Mehrere Mitarbeiter",
      all: "Alle",
    },
  },
  hasCollectiveAgreement: {
    label: "Tarifvertrag?",
    values: {
      yes: "Ja",
      no: "Nein",
      unsure: "Unsicher",
    },
  },
  workCouncilAgreement: {
    label: "Betriebsrat zugestimmt?",
    values: {
      yes: "Ja",
      no: "Nein",
      unsure: "Unsicher",
    },
  },
  shortTimeClauseInContract: {
    label: "Arbeitsvertrag Regelung Kurzarbeit?",
    values: {
      yes: "Ja",
      no: "Nein",
      unsure: "Unsicher",
    },
  },
  companySize: {
    label: "Wie viele Mitarbeiter",
    values: {
      "<10": "Weniger als 10",
      "10+": "10 oder mehr",
    },
  },
  hasWorkCouncil: {
    label: "Betriebsrat?",
  },
  employeeHasAgreed: {
    label: "Persönlich Kurzarbeit zugestimmt?",
  },
  contractTerminated: {
    label: "Gekündigt?",
  },
  contractTerminationReceivedDate: {
    label: "Datum Erhalt der Kündigung",
  },
  percentageWorkTime: {
    label: "Neue Arbeitszeit in %",
  },
  grossSalary: {
    label: "Mtl. Bruttogehalt in €",
  },
  weeklyWorkingHours: {
    label: "Anzahl Wochenarbeitsstunden",
  },
  agencyWorker: {
    label: "Leiharbeiter oder Zeitarbeitskraft?",
  },
  employerName: {
    label: "Name Ihres Unternehmens",
  },
  legalExpenseInsurance: {
    label: "Rechtsschutzversichert?",
  },
  wantsConsulting: {
    label: "Beratung gewünscht?",
  },
  insurance: {
    insurancePolicyNumber: {
      label: "Versichertennummer",
    },
    caseReferenceNumber: {
      label: "Schadens-/Leistungsnummer",
    },
    insurance: {
      label: "Versicherungsgesellschaft",
    },
  },
};

export default shortTimeWorkTranslations;
