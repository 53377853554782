import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  fullWidth: {
    width: "100%",
    padding: "0.5rem 2rem",
  },
  inactive: {
    border: "2px solid #ff9600",
  },
  lost: {
    border: "1px solid #ff0000",
  },
  won: {
    border: "2px solid #137f7b",
  },
  open: {},
  menu: {
    display: "flex",
    justifyContent: "flex-end",
  },
  label: {
    marginBottom: 0,
    wordBreak: "break-all",
  },
});

export default useStyles;
