import React from "react";
import PageContentContainer from "../../Container/PageContentContainer";
import PageHeadline from "../../PageHeadline/PageHeadline";
import Datasets from "../../Datasets/Datasets";

export default function DatasetsPage() {
  return (
    <PageContentContainer size={"small"}>
      <PageHeadline main="Datensätze anlegen/bearbeiten" />
      <Datasets />
    </PageContentContainer>
  );
}