import React from "react";
import pageHeadlineStyle from "./pageHeadlineStyle";
import { getCaseLabel, getFullProductLabel } from "../../services/Product/ProductService";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

export function ProductHeadline({ product, title }) {
  return <PageHeadline sup={title} main={getCaseLabel(product)} sub={getFullProductLabel(product)} />;
}

ProductHeadline.propTypes = {
  title: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
};

export default function PageHeadline({ sup, main, sub }) {
  return (
    <Box sx={pageHeadlineStyle.headlineContainer}>
      <Typography variant="h5">{sup}</Typography>
      <Typography sx={{ margin: "0.5rem" }} variant="h1">
        {main}
      </Typography>
      <Typography variant="h5">{sub}</Typography>
    </Box>
  );
}
PageHeadline.propTypes = {
  sub: PropTypes.string.isRequired,
  sup: PropTypes.string.isRequired,
  main: PropTypes.string.isRequired,
};
PageHeadline.defaultProps = {
  sub: "",
  sup: "",
};
