import React from "react";
import caseAlertsStyle from "./caseAlertsStyle";
import { AbstractCase } from "../../../types/AbstractCase";
import { Box } from "@mui/material";

export default function CaseAlerts({ product }: { product: AbstractCase }) {
  return (
    <>
      {product.preventAllSystemEmails && (
        <Box sx={caseAlertsStyle.caseAlert}>Achtung: Automatische E-Mails und Nachrichten sind pausiert!</Box>
      )}
      {product.backofficeCase?.preventAllSystemBeaOrFax && (
        <Box sx={caseAlertsStyle.caseAlert}>Achtung: Automatischer beA-/Fax-Versand ist pausiert!</Box>
      )}
      {product.preventAccounting && (
        <Box sx={caseAlertsStyle.caseAlert}>Achtung: Automatische Kontoeinträge/Rechnungen sind pausiert!</Box>
      )}
      {product.backofficeCase?.preventAllSystemActivities && (
        <Box sx={caseAlertsStyle.caseAlert}>Achtung: Automatische Aktivitäten sind pausiert!</Box>
      )}
    </>
  );
};
