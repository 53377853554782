import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  icon: {
    width: 30,
    height: 30,
    marginTop: 5,
  },
  productItem: {
    padding: "15px 25px",
  },
  paper: {
    padding: "1rem",
  },
  pageTitle: {
    margin: "2rem 0",
    textAlign: "center",
  },
}));

export default useStyles;
