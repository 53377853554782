import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 200,
    marginBottom: 200,
  },
  headline: {
    textAlign: "center",
  },
  subHeadline: {
    textAlign: "center",
    fontSize: "1rem",
  },
  pageHeading: {
    marginBottom: "2rem",
  },
  addPaymentRow: {
    margin: "1rem 0",
  },
  currentCredit: {
    fontWeight: 700,
    fontSize: "1rem",
  },
  tablePaper: {
    padding: "2rem",
  },
  summaryText: {
    fontWeight: 700,
  },
  bottomBackButton: {
    marginTop: "2rem",
  },
}));

export default useStyles;
