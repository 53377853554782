export default function personFields({ path, isDisabled = () => false, isHidden = () => false }) {
  return [
    {
      type: "ValidatorSelect",
      path: `${path}.gender`,
      isDisabled: isDisabled,
      isHidden: isHidden,
      options: () => [{ value: "male" }, { value: "female" }],
    },
    {
      type: "ValidatorTextField",
      path: `${path}.givenName`,
      isDisabled: isDisabled,
      isHidden: isHidden,
    },
    {
      type: "ValidatorTextField",
      path: `${path}.familyName`,
      isDisabled: isDisabled,
      isHidden: isHidden,
    },
  ];
}
