import React, { useState } from "react";
import ContentBox from "../ContentBox/ContentBox";
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import ButtonLoading from "../Button/ButtonLoading";
import ApiClient from "../../services/ApiClient";
import { editorStateToHTML } from "../../services/Editor/editorFunctions";
import { Roles } from "../../types/BackofficeUser";
import releaseUpdateStyles from "./releaseUpdatesStyles";

export default function CreateReleaseUpdate() {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isInternalReleaseUpdate, setIsInternalReleaseUpdate] = useState(true);
  const [isExternalReleaseUpdate, setIsExternalReleaseUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);

    let visibleToRoles = [Roles.admin, Roles.developer];

    if (isInternalReleaseUpdate) {
      visibleToRoles.push(Roles.accounting, Roles.accountmanager, Roles.traineeLawyer, Roles.lawyer);
    }
    if (isExternalReleaseUpdate) {
      visibleToRoles.push(Roles.external);
    }

    await ApiClient.post("/release_updates", {
      body: JSON.stringify({
        visibleToRoles: visibleToRoles,
        content: editorStateToHTML(editorState),
      }),
    });
    setEditorState(EditorState.createEmpty());
    setIsExternalReleaseUpdate(false);
    setIsInternalReleaseUpdate(true);
    setIsLoading(false);
  };

  return (
    <ContentBox>
      <Grid container justifyContent={"center"} alignItems={"center"} spacing={2}>
        <Grid item xs={12}>
          <Editor
            toolbar={{
              options: ["inline", "list", "textAlign", "history", "link"],
              inline: {
                options: ["bold", "italic", "underline"],
              },
              list: {
                options: ["unordered", "ordered"],
              },
            }}
            stripPastedStyles
            editorState={editorState}
            onEditorStateChange={setEditorState}
            localization={{ locale: "de" }}
            editorStyle={releaseUpdateStyles.editorStyle}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h4"} sx={{ textAlign: "center" }}>
            Sichtbarkeit
          </Typography>
        </Grid>
        <Grid item>
          <FormControlLabel
            labelPlacement="start"
            control={
              <Checkbox
                checked={isInternalReleaseUpdate}
                onChange={({ target }) => setIsInternalReleaseUpdate(target.checked)}
              />
            }
            label={"Intern"}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            labelPlacement="start"
            control={
              <Checkbox
                checked={isExternalReleaseUpdate}
                onChange={({ target }) => setIsExternalReleaseUpdate(target.checked)}
              />
            }
            label={"Extern"}
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item>
          <ButtonLoading
            isLoading={isLoading}
            fullWidth={false}
            variant={"contained"}
            disabled={
              (!isExternalReleaseUpdate && !isInternalReleaseUpdate) || !editorState.getCurrentContent().hasText()
            }
            onClick={onSubmit}
            sx={releaseUpdateStyles.button}
          >
            Releaseupdate veröffentlichen
          </ButtonLoading>
        </Grid>
      </Grid>
    </ContentBox>
  );
}
