import React from "react";
import { connectCurrentRefinements } from "react-instantsearch-dom";
import Button from "@mui/material/Button";
import * as PropTypes from "prop-types";

const LegalbirdClearRefinements = ({ items, refine, buttonText }) => {
  return (
    <Button onClick={() => refine(items)} disabled={!items.length}>
      {buttonText}
    </Button>
  );
};

LegalbirdClearRefinements.propTypes = {
  buttonText: PropTypes.string,
};
LegalbirdClearRefinements.defaultProps = {
  buttonText: "Filter entfernen",
};

export default connectCurrentRefinements(LegalbirdClearRefinements);
