import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  noteContainer: {
    marginLeft: 72,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
    padding: "1rem",
    backgroundColor: "#f8f4d0",
    overflowWrap: "break-word",
  },
  note: {
    overflowWrap: "anywhere",
    overflow: "hidden",
    minHeight: "2rem",
  },
  collapsedNote: {
    height: "2rem",
  },
  icon: {
    color: "#000",
    display: "block",
    margin: "0 0 0 auto",
  },
}));

export default useStyles;
