const dateRangePickerStyles = {
  datePickerContainer: {
    margin: "0 auto 3rem auto",
    textAlign: "center",
  },
  closeButton: {
    display: "block",
    margin: "0 0 0 auto",
  },
  datePicker: {
    border: "1px solid #999",
    borderRadius: 8,
    overflow: "hidden",
    marginRight: "1rem"
  }
};
export default dateRangePickerStyles;
