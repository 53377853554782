import React, { useState } from "react";
import * as PropTypes from "prop-types";
import useStyles from "./processCostSupportStyle";
import TextField from "@mui/material/TextField";
import ButtonLoading from "../Button/ButtonLoading";
import Paper from "@mui/material/Paper";
import { APPLICATION_STATUS_CHECKING } from "../../services/LeadStatus/StatusProcessCostSupport";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../services/ReactQuery/reactQueryService";

const ProcessCostSupportMissingDocuments = ({ processCostSupport }) => {
  const classes = useStyles({});
  const [customerText, setCustomerText] = useState(processCostSupport.customerText || "");
  const [isLoading, setIsLoading] = useState(false);
  const updateMutation = useMutation(updateResource);

  const handleConfirm = async () => {
    setIsLoading(true);
    await updateMutation.mutateAsync({
      uri: "process_cost_supports",
      id: processCostSupport.id,
      data: {
        applicationStatus: 500,
        customerText: customerText,
      },
    });
    setIsLoading(false);
  };

  return (
    <Paper className={classes.paper}>
      <div className={classes.missingDocumentsContainer}>
        <div className={classes.paperHeadline}>Belege unvollständig</div>
        <div className={classes.question}>
          Es fehlen Belege oder es müssen Belege neu vom Kunden hochgeladen werden? Dann schreiben Sie hier kurz einen
          Text an den Kunden, sodass er genau weiß, was er hochladen muss. Er erhält diese Hinweise dann direkt als
          E-Mail.
        </div>
        <TextField
          label={"Ihr Text"}
          InputLabelProps={{
            shrink: true,
          }}
          name={"customerText"}
          value={customerText}
          onChange={({ target }) => setCustomerText(target.value)}
          multiline
          rows={"12"}
          maxRows={"12"}
          margin={"normal"}
          fullWidth
        />
        <ButtonLoading
          onClick={handleConfirm}
          isLoading={isLoading}
          variant={"contained"}
          disabled={
            processCostSupport.customerText === customerText ||
            customerText === "" ||
            processCostSupport.applicationStatus !== APPLICATION_STATUS_CHECKING
          }
        >
          Belege anfordern
        </ButtonLoading>
      </div>
    </Paper>
  );
};

ProcessCostSupportMissingDocuments.propTypes = {
  processCostSupport: PropTypes.object.isRequired,
};

export default ProcessCostSupportMissingDocuments;
