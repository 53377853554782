import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import _ from "lodash";
import { makeStyles } from "@mui/styles";
import paidTaskTableStyle from "./paidTaskTableStyle";
import PaidTaskTableRow from "./PaidTaskTableRow";

const useStyles = makeStyles(paidTaskTableStyle);

const PaidTaskTable = ({ paidTasks, refreshFunction }) => {
  const classes = useStyles();

  const PaidTaskTableRows = ({ paidTasks, isExternalView, hideCase }) => {
    if (paidTasks.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={10} align={"center"}>
            Keine Aufgaben
          </TableCell>
        </TableRow>
      );
    }

    return _.map(paidTasks, (paidTask) => (
      <PaidTaskTableRow key={paidTask.id} paidTask={paidTask} refreshFunction={refreshFunction} />
    ));
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.activityTable}>
        <TableHead>
          <TableRow>
            <TableCell>Fall</TableCell>
            <TableCell>Rechtsprodukt</TableCell>
            <TableCell>Aufgabe</TableCell>
            <TableCell>Honorar</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Angefragt bei</TableCell>
            <TableCell>Angenommen von</TableCell>
            <TableCell>Annahme bis</TableCell>
            <TableCell>Erledigen bis</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <PaidTaskTableRows paidTasks={paidTasks} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default PaidTaskTable;
