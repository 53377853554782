import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  name: {
    color: "#137f7b",
  },
  deleteText: {
    textAlign: "center",
    margin: "2rem 0",
  },
  link: {
    color: "#137f7b",
    textDecoration: "none",
  },
}));

export default useStyles;
