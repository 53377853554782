export default function addressFields({
  path,
  translationPath = "",
  isDisabled = () => false,
  isHidden = () => false,
  hidePostOfficeBoxFields = () => true,
}) {
  translationPath = translationPath !== "" ? translationPath : path;
  return [
    {
      type: "ValidatorTextField",
      path: `${path}.streetAddress`,
      translationPath: `${translationPath}.streetAddress`,
      isDisabled: isDisabled,
      isHidden: isHidden,
    },
    {
      type: "ValidatorTextField",
      path: `${path}.postalCode`,
      translationPath: `${translationPath}.postalCode`,
      isDisabled: isDisabled,
      isHidden: isHidden,
    },
    {
      type: "ValidatorTextField",
      path: `${path}.addressLocality`,
      translationPath: `${translationPath}.addressLocality`,
      isDisabled: isDisabled,
      isHidden: isHidden,
    },
    {
      type: "ValidatorTextField",
      path: `${path}.postOfficeBoxNumber`,
      translationPath: `${translationPath}.postOfficeBoxNumber`,
      isDisabled: isDisabled,
      isHidden: hidePostOfficeBoxFields || isHidden,
    },
    {
      type: "ValidatorTextField",
      path: `${path}.postOfficeBoxPostalCode`,
      translationPath: `${translationPath}.postOfficeBoxPostalCode`,
      isDisabled: isDisabled,
      isHidden: hidePostOfficeBoxFields || isHidden,
    },
    {
      type: "ValidatorTextField",
      path: `${path}.postOfficeBoxAddressLocality`,
      translationPath: `${translationPath}.postOfficeBoxAddressLocality`,
      isDisabled: isDisabled,
      isHidden: hidePostOfficeBoxFields || isHidden,
    },
  ];
}
