import _ from "lodash";
import { transformToFormattedValue } from "../formServiceFunctions";
import { calculateFees, calculateValueInDispute } from "../../components/DivorceCalculator/costCalculation";
import FeesService from "../../components/DivorceCalculator/FeesService";
import CostCalculator from "../../components/DivorceCalculator/CostCalculator";
import { buildCalendlyLink, buildCalendlyLinkLawyer } from "../calendlyService";
import { getAdvoAssistRepresentative, getCaseLink, getServiceWorldCaseLink } from "../Product/ProductService";

const SPECIAL_PATHS_WITH_VALUE = ["customer.contactTime"];

export const fillTemplate = (templateString, productEntity, formValues) => {
  /*
  transform parameters into readable keys to check if they are present in the productEntity
  because the whole thing crashes if the method tries to replace a placeholder which doesnt exist,
  for example if someone puts <%= wurst.broetchen %> as a placeholder and there is no wurst object

  const parameterMatches = templateString.match(/<%=.+%>/g);
  const parameters = _.map(parameterMatches, match => {
    let isolatedParameter = _.trimStart(match, "<%=");
    isolatedParameter = _.trimEnd(isolatedParameter, "%>");
    return _.trim(isolatedParameter);
  });

  check if all keys exist in productentity with _.every or something like that
  */

  const compiled = _.template(templateString);
  try {
    return compiled({
      [productEntity.productClassName]: productEntity,
      product: productEntity,
      formValues: formValues,
      translate,
      salutation,
      formOfAddressWithGenderedPrefix,
      getCaseLink,
      getServiceWorldCaseLink,
      wrapInLinkTag,
    });
  } catch (e) {
    console.error(e);
    return "Fehler in der Vorlage. Dies liegt höchstwahrscheinlich an einem falschen Platzhalter.";
  }
};

export const salutation = (product, formValues) => {

  if (!formValues?.recipientType) {
    return getPersonalSalutation(product.client);
  }

  if (_.startsWith(formValues.recipientType, "processParticipantsPeople")) {
    return getPersonalSalutation(_.get(product, _.replace(formValues.recipientType, ".email", "")));
  }

  switch (formValues.recipientType) {
    case "contractualPartnerLegalEntity":
      return product.contractualPartnerType === "contractualPartnerPerson"
        ? getPersonalSalutation(product.contractualPartnerPerson)
        : "Sehr geehrte Damen und Herren,";
    case "opponentLawFirm":
      return getLawyerSalutation(product.opponentLawyer);
    case "authority":
    case "prosecution":
    case "court":
      return "";
    case "customer":
    case "petitioner":
    case "client":
      return getPersonalSalutation(product.client);
    case "advoAssistRepresentative":
      return getLawyerSalutation(getAdvoAssistRepresentative(product));
    case "debtCollectionAgency":
    case "insurance":
    case "custom":
    default:
      return "Sehr geehrte Damen und Herren,";
  }
};

const getPersonalSalutation = (person) => {
  if (!person) {
    return "";
  }
  const salutation = person.gender === "female" ? "Sehr geehrte Frau" : "Sehr geehrter Herr";
  return salutation + " " + person.familyName + ",";
};

const getLawyerSalutation = (person) => {
  if (!person) {
    return "";
  }
  const salutation = person.gender === "female" ? "Sehr geehrte Frau Kollegin" : "Sehr geehrter Herr Kollege";
  return salutation + " " + person.familyName + ",";
};

export const formOfAddressWithGenderedPrefix = (product, malePrefix, femalePrefix) => {
  let salutation = product.petitioner.gender === "female" ? "Frau" : "Herr";
  const prefix = product.petitioner.gender === "female" ? femalePrefix : malePrefix;
  return prefix + " " + salutation + " " + product.petitioner.familyName;
};

const processCostsDivorce = (divorce) => {
  const valueInDispute = calculateValueInDispute(divorce);
  const costs = calculateFees(valueInDispute.total, divorce);
  return (costs.lawyerCourt + costs.expenses).toLocaleString("de-DE", { style: "currency", currency: "EUR" });
};

const installmentRateDivorce = (divorce) => {
  const valueInDispute = calculateValueInDispute(divorce);
  const installmentRate =
    (FeesService.getExpenses(true) +
      CostCalculator.getHearingCost(valueInDispute.total) +
      CostCalculator.getProcessCost(valueInDispute.total)) /
    10;
  return installmentRate.toLocaleString("de-DE", { style: "currency", currency: "EUR" });
};

const generateCalendlyLinkLawyer = (product) => {
  return wrapInLinkTag(buildCalendlyLinkLawyer(product), "Termin vereinbaren");
};

const generateCalendlyLinkRoundRobin = (product) => {
  return wrapInLinkTag(buildCalendlyLink(product), "Termin vereinbaren");
};

const wrapInLinkTag = (url, label = "Hier klicken") => {
  return "<a href=" + url + "><u><strong>" + _.toUpper(label) + "</strong></u></a>";
};

const getRubrumContractLaw = (product) => {
  switch (product.caseCategory) {
    case "defendClaim":
      return product.opponentVsClientString;
    default:
      return product.clientVsOpponentString;
  }
};

const handleSpecialPaths = (product, path) => {
  switch (path) {
    case "installmentRateDivorce":
      return installmentRateDivorce(product);
    case "rubrumContractLaw":
      return getRubrumContractLaw(product);
    case "processCostsDivorce":
      return processCostsDivorce(product);
    case "calendlyLink":
    case "calendlyLinkRoundRobin":
      return generateCalendlyLinkRoundRobin(product);
    case "calendlyLinkLawyer":
      return generateCalendlyLinkLawyer(product);
    case "appealRevokeLink":
      return wrapInLinkTag(
        getServiceWorldCaseLink(product) +
          "/einspruch-ruecknahme?&utm_source=legalbird&utm_medium=email&utm_campaign=feedback",
        "Jetzt Rückmeldung geben"
      );
    case "furtherProcessLink":
      return wrapInLinkTag(
        getServiceWorldCaseLink(product) + "/rueckmeldung?&utm_source=legalbird&utm_medium=email&utm_campaign=feedback",
        "Jetzt weiteres Vorgehen festlegen"
      );
    case "customer.contactTime":
      const value = _.get(product, path);
      return value === "flexible" ? "Ich bin flexibel" : "gegen " + value + " Uhr";
    default:
      break;
  }
  return "";
};

export const translate = (product, path) => {
  let value = _.get(product, path);
  if (value === undefined || value === null || _.includes(SPECIAL_PATHS_WITH_VALUE, path)) {
    return handleSpecialPaths(product, path);
  }
  return transformToFormattedValue(product, path);
};
