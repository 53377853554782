import React from "react";
import { isMobile } from "react-device-detect";
import Layout from "../components/Layout/Layout";
import LayoutMobile from "../mobileComponents/Layout/Layout";
import LayoutExternal from "../components/Externals/Layout/Layout";
import LayoutExternalMobile from "../mobileComponents/Externals/Layout/Layout";

import PropTypes from "prop-types";

const LayoutDeviceBased = ({ children, isExternal }) => {
  if (isExternal) {
    return isMobile ? (
      <LayoutExternalMobile>{children}</LayoutExternalMobile>
    ) : (
      <LayoutExternal>{children}</LayoutExternal>
    );
  }

  return isMobile ? <LayoutMobile>{children}</LayoutMobile> : <Layout>{children}</Layout>;
};
LayoutDeviceBased.propTypes = {
  isExternal: PropTypes.bool,
};
LayoutDeviceBased.defaultProps = {
  isExternal: false,
};
export default LayoutDeviceBased;
