import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import * as PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  imageCircleContainer: (props) => ({
    width: props.iconContainerSize,
    height: props.iconContainerSize,
    borderRadius: "50% !important",
    border: "1px solid #333333",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#333",
  }),
  icon: (props) => ({
    width: props.iconSize,
    height: props.iconSize,
  }),
}));

const CircleIcon = (props) => {
  const classes = useStyles(props);
  const { icon, type } = props;

  return (
    <div className={classes.imageCircleContainer}>
      {type === "muiIcon" ? icon : <img className={classes.icon} src={icon} alt={"activityIcon"} />}
    </div>
  );
};

CircleIcon.propTypes = {
  iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconContainerSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.any.isRequired,
  type: PropTypes.string,
};

CircleIcon.defaultProps = {
  iconSize: 24,
  iconContainerSize: 40,
};

export default CircleIcon;
