import React from "react";
import useStyles from "./dutyNotificationStyles";
import Grid from "@mui/material/Grid";
import * as PropTypes from "prop-types";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import { useSnackbar } from "notistack";

const CustomNotification = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { notification, id } = props;
  const { closeSnackbar } = useSnackbar();

  const closeNotification = () => {
    closeSnackbar(id);
  };

  return (
    <Paper ref={ref} className={classes.notificationContainer}>
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item xs={2} />
        <Grid item xs={8} className={classes.headline}>
          Neue Nachricht!
        </Grid>
        <Grid item xs={2}>
          <IconButton className={classes.icon} onClick={closeNotification} size="large">
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <strong>Nachricht: </strong>
          {notification}
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <Button onClick={closeNotification}>Ok</Button>
        </Grid>
      </Grid>
    </Paper>
  );
});

CustomNotification.propTypes = {
  notification: PropTypes.string,
};

export default CustomNotification;
