import React from "react";
import PageContentContainer from "../../../components/Container/PageContentContainer";
import PaidTaskContainer from "../../PaidTask/PaidTaskTable/PaidTaskContainer";

const PaidTaskCollectionPage = () => {
  return (
    <PageContentContainer>
      <PaidTaskContainer displayType={"internalView"} headline={"Übersicht ausgeschriebene Aufgaben"} />
    </PageContentContainer>
  );
};

export default PaidTaskCollectionPage;
