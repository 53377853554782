import React, { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import * as PropTypes from "prop-types";
import useStyles from "./validatorStyle";

const ValidatorSelect = ({
                           label,
                           registerValidators = () => {},
                           validators = [],
                           dependentValidationFields = [],
                           helperText = "",
                           isMandatory = [],
                           error = false,
                           children,
                           ...rest
                         }) => {
  const classes = useStyles();
  //seperate extraction to keep it in rest
  const { endAdornment, name, disabled, value } = rest;
  useEffect(() => {
    if (registerValidators && validators && !disabled) {
      registerValidators(name, validators, dependentValidationFields);
    }
    return () => {
      registerValidators && registerValidators(name, []);
    }
  }, [registerValidators, dependentValidationFields, name, disabled]);

  const iconClass = endAdornment ? { icon: classes.icon } : null;

  return (
    <FormControl
      fullWidth={true}
      margin={"normal"}
      className={isMandatory && (value === null || value === "") ? classes.mandatory : null}
      error={error}
      disabled={disabled}
    >
      <InputLabel>{label}</InputLabel>
      <Select {...rest} label={label} classes={iconClass}>
        {children}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

ValidatorSelect.propTypes = {
  validators: PropTypes.arrayOf(PropTypes.object),
  dependentValidationFields: PropTypes.array,
  registerValidators: PropTypes.func,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.any,
  isMandatory: PropTypes.bool,
};

ValidatorSelect.defaultProps = {
  isMandatory: false,
  validators: [],
  dependentValidationFields: [],
};

export default ValidatorSelect;
