import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import DutyNotification from "../Notifications/DutyNotification";
import { isCookieFetched, mercureFetchCookie, mercureSubscribe } from "../../services/mercure";
import CustomNotification from "../Notifications/CustomNotification";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

const BackofficeUserNotifications = () => {
  const [notification, setNotification] = useState(null);
  const currentUser = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const cookieFetched = isCookieFetched();
  useEffect(() => {
    if (cookieFetched) {
      const eventSource = mercureSubscribe(currentUser["@id"] + "/messages", setNotification);
      return () => {
        eventSource.close();
      };
    } else {
      mercureFetchCookie();
    }
  }, [cookieFetched]);
  useEffect(() => {
    if (!!notification) {
      switch (notification.type) {
        case "mandatoryActivity":
          enqueueSnackbar("", {
            content: (key) => (
              <DutyNotification
                id={key}
                description={notification.activity.subject}
                timeString={
                  notification.activity.dueTime === "allDay" ? "Ganztägig" : notification.activity.dueTime + " Uhr"
                }
                link={notification.caseLink}
              />
            ),
            persist: true,
          });
          break;
        case "customNotification":
          enqueueSnackbar("", {
            content: (key) => <CustomNotification id={key} notification={notification.notification} />,
            persist: true,
          });
          break;
      }
    }
  }, [notification]);
  return null;
};
export default BackofficeUserNotifications;
