import React from "react";
import { Box } from "@mui/material";
import loginPageBackgroundStyles from "./loginPageBackgroundStyles";

type LoginPageBackgroundWrapperProps = {
  children: React.ReactNode;
};

export default function LoginPageBackgroundWrapper({ children }: LoginPageBackgroundWrapperProps) {
  return (
    <Box sx={loginPageBackgroundStyles.background}>
      {children}
    </Box>
  );
};
