import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  headline: {
    fontSize: "2rem",
    fontWeight: 700,
    textAlign: "center",
  },
  commonText: {
    marginTop: "1rem",
    fontSize: "1rem",
  },
  errorText: {
    marginTop: "3rem",
    fontSize: "small",
    color: "#999999",
  },
  closeButton: {
    display: "block",
    margin: "0 0 0 auto",
  },
  errorContainer: {
    padding: "2rem",
  },
  confirmButton: {
    marginTop: "2rem",
  },
}));

export default useStyles;
