import React, { useMemo, useState } from "react";
import { Button, Stack, TableCell, TableRow } from "@mui/material";
import LegalbirdIoModal from "../../../Modal/LegalbirdIoModal";
import BeaSearch from "../../../BeaSearch/BeaSearch";
import { AbstractCase } from "../../../../types/AbstractCase";
import { BeaSearchResult } from "../../../../types/Bea/BeaSearchResult";
import _ from "lodash";

type BeaLawyerImportButtonProps = {
  product: AbstractCase;
  handleChange: Function;
  values: {
    [key: string]: string;
  };
};

export default function BeaLawyerImportButton({ product, handleChange, values }: BeaLawyerImportButtonProps) {
  const [modalOpen, setModalOpen] = useState(false);

  const onImportAllDataClick = ({
    surname,
    streetnumber,
    city,
    safeId,
    street,
    postalcode,
    firstname,
  }: BeaSearchResult) => {
    handleChange({ target: { name: `${product.productClassName}.opponentLawyer.familyName`, value: surname || "" } });
    handleChange({ target: { name: `${product.productClassName}.opponentLawyer.givenName`, value: firstname || "" } });
    handleChange({
      target: {
        name: `${product.productClassName}.opponentLawFirm.address.streetAddress`,
        value: street + " " + streetnumber || "",
      },
    });
    handleChange({
      target: {
        name: `${product.productClassName}.opponentLawFirm.address.postalCode`,
        value: postalcode || "",
      },
    });
    handleChange({
      target: {
        name: `${product.productClassName}.opponentLawFirm.address.addressLocality`,
        value: city || "",
      },
    });
    handleChange({ target: { name: `${product.productClassName}.opponentLawFirm.beaSafeId`, value: safeId || "" } });
    setModalOpen(false);
  };

  const onImportSafeIdClick = (beaSafeId: string) => {
    handleChange({ target: { name: `${product.productClassName}.opponentLawFirm.beaSafeId`, value: beaSafeId || "" } });
    setModalOpen(false);
  };

  const SearchResultExtension = ({ searchResult }: { searchResult: BeaSearchResult }) => {
    return (
      <TableRow>
        <TableCell colSpan={6}>
          <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
            <Button fullWidth={false} onClick={() => onImportAllDataClick(searchResult)}>
              Alle Daten Übernehmen
            </Button>
            <Button variant={"contained"} fullWidth={false} onClick={() => onImportSafeIdClick(searchResult.safeId)}>
              Bea-Adresse Übernehmen
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
    );
  };

  const initialValues = useMemo(() => {
    return {
      surname: _.get(values, `${product.productClassName}.opponentLawyer.familyName`, ""),
      firstname: _.get(values, `${product.productClassName}.opponentLawyer.givenName`, ""),
      postalCode: _.get(values, `${product.productClassName}.opponentLawFirm.address.postalCode`, ""),
      city: _.get(values, `${product.productClassName}.opponentLawFirm.address.addressLocality`, ""),
    };
  }, [modalOpen]);

  return (
    <Stack justifyContent={"center"} alignItems={"flex-end"} sx={{ height: "100%" }}>
      <Button onClick={() => setModalOpen(true)} fullWidth={false}>
        Daten aus Bea einfügen
      </Button>
      <LegalbirdIoModal
        handleClose={() => setModalOpen(false)}
        open={modalOpen}
        submitButton={<></>}
        hasActions={false}
        title={"Daten aus beA einfügen"}
        maxWidth={"lg"}
      >
        <BeaSearch
          ResultRowExtension={SearchResultExtension}
          initialValues={initialValues}
          searchResultTypeFilter={"RECHTSANWALT"}
        />
      </LegalbirdIoModal>
    </Stack>
  );
}
