import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import React, { useState } from "react";
import ApiClient from "../../../services/ApiClient";
import PropTypes from "prop-types";

function ContractResetModal({ product, open, handleClose, refreshPage }) {
  const [isLoading, setIsLoading] = useState(false);

  const resetContractCase = async () => {
    setIsLoading(true);
    await ApiClient.post(
      "case_special_actions/" + product.productClassName + "/" + product.id + "/reset_contract_advisory_case"
    );
    refreshPage();
    handleClose();
  };

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={"Fall zurücksetzen?"}
      submitButton={
        <ButtonLoading onClick={resetContractCase} color="primary" variant={"contained"} isLoading={isLoading}>
          Fall zurücksetzen
        </ButtonLoading>
      }
    >
      <p className={"center"}>
        Wollen Sie den Fall wirklich zurücksetzen? Damit kann der Mandant eine Forderung durchsetzen oder abwehren. Der
        Mandant wird darüber per Email informiert und muss seine Angaben vervollständigen.
      </p>
    </LegalbirdIoModal>
  );
}
ContractResetModal.propTypes = {
  product: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refreshPage: PropTypes.func,
};

ContractResetModal.defaultProps = {
  refreshPage: () => {},
};
export default ContractResetModal;
