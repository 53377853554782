import React, { useMemo, useState } from "react";
import { AbstractCase } from "../../../types/AbstractCase";
import { ReferenceFile } from "../../../types/ReferenceFile";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import MediaObjectFolderView from "../../MediaObjectSection/MediaObjectFolderView";
import ContentBox from "../../ContentBox/ContentBox";
import ApiClient from "../../../services/ApiClient";
import ButtonLoading from "../../Button/ButtonLoading";
import { Box, Button, Stack, Typography } from "@mui/material";
import caseReferenceFileStyles from "./caseReferenceFileStyles";
import _ from "lodash";
import LegalbirdIoConfirm from "../../Modal/LegalbirdIoConfirm";
import { useSnackbar } from "notistack";
import Snackbar from "../../Snackbar/Snackbar";
import { getCaseLink } from "../../../services/Product/ProductService";
import { MediaObject } from "../../../types/MediaObject";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";

type CaseReferenceFileProps = {
  product: AbstractCase;
  referenceFile: ReferenceFile;
  refreshReferenceFile: () => void;
};

export default function CaseReferenceFile({ product, referenceFile, refreshReferenceFile }: CaseReferenceFileProps) {
  const [selectedMediaObjects, setSelectedMediaObjects] = useState(referenceFile.selectedMediaObjects);
  const [isLoading, setIsLoading] = useState(false);
  const [sendConfirmationModalOpen, setSendConfirmationModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  let mediaObjectsFilter = {
    product: product.productClassName,
    productId: product.id,
  };
  const { data: mediaObjects } = useQuery(
    queryKeys.collection("media_objects", mediaObjectsFilter),
    () => fetchCollection("media_objects", mediaObjectsFilter),
    {
      enabled: !!product,
    }
  );

  const filteredMediaObjects = useMemo(() => {
    if (!mediaObjects) {
      return null;
    }
    return mediaObjects["hydra:member"].filter(
      (mediaObject: MediaObject) => mediaObject.showInExternalView || mediaObject.showInGallery
    );
  }, [mediaObjects]);

  if (!mediaObjects) {
    return <LegalbirdLoader centered />;
  }

  const handleFileSave = async () => {
    setIsLoading(true);
    const response = await ApiClient.put(referenceFile["@id"], {
      body: JSON.stringify({
        selectedMediaObjects: selectedMediaObjects,
      }),
    });
    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar
            message={_.isError(response) ? "Es ist ein Fehler aufgetreten" : "Handakte wurde erfolgreich gespeichert"}
            isNonInteractive={true}
          />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    await refreshReferenceFile();
    setIsLoading(false);
  };

  const handleSendReferenceFile = async () => {
    setIsLoading(true);
    await ApiClient.put(referenceFile["@id"], {
      body: JSON.stringify({
        selectedMediaObjects: selectedMediaObjects,
      }),
    });
    const result = await ApiClient.post(`/send_reference_file/${referenceFile.id}`);
    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar
            message={_.isError(result) ? "Es ist ein Fehler aufgetreten" : "Handakte wurde erfolgreich versendet"}
            buttonType={"link"}
            buttonLink={getCaseLink(product)}
            buttonTarget={"_self"}
            buttonText={"Zum Fall"}
          />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });

    await refreshReferenceFile();
    setIsLoading(false);
  };

  const courtAppointment = _.find(
    product.courtAppointments,
    (appointment) => appointment.id + "" === referenceFile.courtAppointmentId
  );

  return (
    <ContentBox headline={"Dokumente"}>
      <Stack alignItems={"center"} spacing={2}>
        <Box sx={{ width: "100%" }}>
          <MediaObjectFolderView
            product={product}
            mediaObjects={filteredMediaObjects}
            selectableView
            selectedMediaObjects={selectedMediaObjects}
            setSelectedMediaObjects={setSelectedMediaObjects}
          />
        </Box>
        <ButtonLoading
          isLoading={isLoading}
          onClick={handleFileSave}
          variant={"outlined"}
          sx={caseReferenceFileStyles.button}
        >
          Auswahl speichern
        </ButtonLoading>
        <Button
          onClick={() => setSendConfirmationModalOpen(true)}
          variant={"contained"}
          sx={caseReferenceFileStyles.button}
          disabled={!_.get(courtAppointment, "advoAssist.partnerLawyer") || selectedMediaObjects.length < 1}
        >
          Handakte versenden
        </Button>
        {referenceFile.lastSent && (
          <Typography>
            Handakte wurde zuletzt versendet am {moment(referenceFile.lastSent).format("DD.MM.YYYY, HH:mm")} Uhr
          </Typography>
        )}
        <LegalbirdIoConfirm
          handleClose={() => setSendConfirmationModalOpen(false)}
          open={sendConfirmationModalOpen}
          headline={"Handakte versenden"}
          handleConfirm={handleSendReferenceFile}
          confirmText={"Handakte versenden"}
          content={
            'Mit einem Klick auf "Handakte versenden" erhält der Terminsvertreter eine E-Mail mit Zugang zur Handakte, welcher dann bis zum Gerichtstermin gültig ist.'
          }
        />
      </Stack>
    </ContentBox>
  );
}
