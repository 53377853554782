import React from "react";
import TermsOfServiceExternalLawyers from "../../TermsOfServiceExternalLawyers/TermsOfServiceExternalLawyers";
import PageContentContainer from "../../Container/PageContentContainer";

const TermsOfServiceExternalLawyerPage = () => {
  return (
    <PageContentContainer size={"tiny"} backgroundColor={"white"}>
      <TermsOfServiceExternalLawyers />
    </PageContentContainer>
  );
};

export default TermsOfServiceExternalLawyerPage;
