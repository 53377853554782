import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ServicesRoutes from "./ServicesRoutes";
import ExternalRoutes from "./ExternalRoutes";
import RequireRole from "../components/RequireAuth/RequireRole";
import { trackPage } from "../services/GoogleAnalytics/googleAnalytics";
import AdminPage from "../components/_Pages/AdminPage/AdminPage";
import { Roles } from "../types/BackofficeUser";
import CallcenterRoutes from "./CallcenterRoutes";

export default function BaseRoutes() {
  const { pathname } = useLocation();
  useEffect(() => trackPage(pathname), [pathname]);
  return (
    <Routes>
      <Route path={"/papageienaufstand"} element={<AdminPage />} />
      <Route
        path={"callcenter/*"}
        element={
          <RequireRole allowedRoles={[Roles.admin, Roles.developer, Roles.callcenter]}>
            <CallcenterRoutes />
          </RequireRole>
        }
      />
      <Route
        path={"extern/*"}
        element={
          <RequireRole allowedRoles={[Roles.admin, Roles.external, Roles.developer]}>
            <ExternalRoutes />
          </RequireRole>
        }
      />
      <Route
        path={"*"}
        element={
          <RequireRole
            allowedRoles={[Roles.admin, Roles.accountmanager, Roles.traineeLawyer, Roles.lawyer, Roles.accounting, Roles.developer]}
          >
            <ServicesRoutes />
          </RequireRole>
        }
      />
    </Routes>
  );
}
