const boxStyle = {
  padding: "1rem",
  marginBottom: "2rem",
  border: "1px solid #ddd",
}

const caseInquiryToCustomerStyle: any = {
  open: {...boxStyle, backgroundColor: "#fbf4ec", borderColor: "#f9b256"},
  inProgress: {...boxStyle, backgroundColor: "#effbfa", borderColor: "#137f7b"},
  overdue: {...boxStyle, backgroundColor: "#fdecf4", borderColor: "#fe3061"},
}
export default caseInquiryToCustomerStyle;