import React, { useCallback, useEffect, useState } from "react";
import ApiClient from "../../../services/ApiClient";
import _ from "lodash";
import { PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_PAID } from "../../../components/PaidTask/paidTaskStatus";
import PaidTaskFilter from "./PaidTaskFilter";
import paidTaskListStyle from "./paidTaskListStyle";
import { paidTaskListFilters } from "../../../services/paidTaskService";
import makeStyles from "@mui/styles/makeStyles";
import RequestedPaidTasks from "./RequestedPaidTasks";
import PageHeadline from "../../../components/PageHeadline/PageHeadline";
import PaidTaskTable from "../../../components/PaidTask/PaidTaskTable/PaidTaskTable";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";

const useStyles = makeStyles(paidTaskListStyle);

const getApiFilterByDisplayType = (displayType, currentUser) => {
  switch (displayType) {
    case "assignableView":
      return "?exists[deletedDate]=false&status=" + PAID_TASK_STATUS_CREATED;
    case "assigneeView":
      return "?exists[deletedDate]=false&assignee.id=" + currentUser.id + "&status[lt]=" + PAID_TASK_STATUS_PAID;
    default:
    case "internalView":
      return "?exists[deletedDate]=false&status[lt]=" + PAID_TASK_STATUS_PAID;
  }
};

const PaidTaskContainer = ({ displayType, headline }) => {
  const currentUser = useCurrentUser();
  const [paidTasks, setPaidTasks] = useState(null);
  const [paidTasksFiltered, setPaidTasksFiltered] = useState(null);
  const [paidTaskFilter, setPaidTaskFilter] = useState(() => {
    switch (displayType) {
      case "assignableView":
        return "open";
      case "assigneeView":
        return "assigned";
      case "internalView":
      default:
        return "inReview";
    }
  });

  const classes = useStyles({});

  const refreshFunction = useCallback(() => {
    ApiClient.get("paid_tasks" + getApiFilterByDisplayType(displayType, currentUser)).then((paidTasks) =>
      setPaidTasks(paidTasks["hydra:member"])
    );
  }, [displayType, currentUser]);

  useEffect(() => {
    refreshFunction();
  }, [refreshFunction]);

  useEffect(() => {
    if (paidTasks === null) {
      return;
    }

    const currentFilter = _.get(paidTaskListFilters, displayType + "." + paidTaskFilter);

    if (!currentFilter) {
      setPaidTasksFiltered([]);
      return;
    }
    const filteredPaidTasks = _.filter(paidTasks, currentFilter.filter);
    const sortedPaidTasks = !!currentFilter.sorter
      ? _.sortBy(filteredPaidTasks, currentFilter.sorter)
      : filteredPaidTasks;
    setPaidTasksFiltered(sortedPaidTasks);
  }, [paidTasks, paidTaskFilter]);

  if (paidTasks === null || paidTasksFiltered === null) {
    return null;
  }

  return (
    <div>
      {_.includes(["assignableView", "assigneeView"], displayType) && <RequestedPaidTasks />}
      <PageHeadline main={headline} />
      <PaidTaskFilter displayType={displayType} paidTaskFilter={paidTaskFilter} setPaidTaskFilter={setPaidTaskFilter} />
      <PaidTaskTable paidTasks={paidTasksFiltered} refreshFunction={refreshFunction} />
    </div>
  );
};
export default PaidTaskContainer;
