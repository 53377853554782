import React, { useState } from "react";
import { Message } from "../../../../types/Messaging/Message";
import Grid from "@mui/material/Grid";
import CircleIcon from "../../../CircleIcon/CircleIcon";
import { TextsmsOutlined, UnfoldLess, UnfoldMore } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import moment from "moment";

type CaseHistoryMessageProps = {
  message: Message;
  conversationSubject: string;
};

export default function CaseHistoryMessage({ message, conversationSubject }: CaseHistoryMessageProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Grid container>
      <Grid item xs={2} md={1}>
        <CircleIcon icon={<TextsmsOutlined />} type={"muiIcon"} />
      </Grid>
      <Grid item xs={8} md={10}>
        <Typography variant={"h5"}>
          <strong>{conversationSubject}</strong>
        </Typography>
        <Typography variant={"body1"}>
          {moment(message.created).format("DD.MM.YYYY")} um {moment(message.created).format("HH:mm")} Uhr
        </Typography>
        {isExpanded && <Box dangerouslySetInnerHTML={{ __html: message.messageText }} sx={{ marginTop: "1rem" }} />}
      </Grid>
      <Grid item xs={2} md={1}>
        <IconButton onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <UnfoldLess /> : <UnfoldMore />}
        </IconButton>
      </Grid>
    </Grid>
  );
}
