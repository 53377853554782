import { JSXElementConstructor, ReactElement } from "react";
import { Controller, ControllerFieldState, ControllerRenderProps, FieldValues } from "react-hook-form";
import { FormControl, FormHelperText, InputLabel, Select, SelectChangeEvent } from "@mui/material";

interface SelectElementProps {
  name: string;
  label: string;
  children: React.ReactNode;
  disabled?: boolean;
  helperText?: string;
  onChange?: Function;
}

export default function SelectElement({
  name,
  label,
  children,
  onChange = () => {},
  disabled = false,
  helperText = "",
}: any) {
  return (
    <Controller
      render={function ({
        field,
        fieldState,
      }: {
        field: ControllerRenderProps<FieldValues, string>;
        fieldState: ControllerFieldState;
      }): ReactElement<any, string | JSXElementConstructor<any>> {
        return (
          <FormControl fullWidth={true} margin={"normal"} disabled={disabled} error={!!fieldState.error}>
            <InputLabel>{label}</InputLabel>
            <Select
              {...field}
              value={field.value || ""}
              onChange={(event: SelectChangeEvent<unknown>) => {
                field.onChange(event.target.value);
                onChange(event.target.value);
              }}
              label={label}
            >
              {children}
            </Select>
            {(!!fieldState.error || helperText) && (
              <FormHelperText>{fieldState.error?.message || helperText}</FormHelperText>
            )}
          </FormControl>
        );
      }}
      name={name}
    />
  );
}
