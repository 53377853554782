import React, { useCallback, useEffect, useState } from "react";
import PageContentContainer from "../../Container/PageContentContainer";
import _ from "lodash";
import ActivityOverviewHeader from "../../Activities/ActivityOverviewHeader/ActivityOverviewHeader";
import moment from "moment";
import ApiClient from "../../../services/ApiClient";
import { ACTIVITY_OVERDUE } from "../../../services/Stages/Stages";
import { buildApiFilters, buildSearchParams, prepareFilters } from "../../../services/Activities/activityFunctions";
import ActivityTable from "../../Activities/ActivityTable/ActivityTable";
import useActivitiesFetching from "../../../hooks/useActivitiesFetching";
import { Paper, TableContainer } from "@mui/material";
import { deadlineActivityTypes } from "../../../services/Activities/activityTypes";
import PageHeadline from "../../PageHeadline/PageHeadline";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";

const DeadlineOverview = () => {
  const { lawyers, backofficeUsers } = useBackofficeUser();
  const currentUser = useCurrentUser();

  const initialFilters = prepareFilters(currentUser, lawyers, "deadlineFilters", "allDeadlines");

  const [productFilter, setProductFilter] = useState(initialFilters.productFilter);
  const [activityTypeFilter, setActivityTypeFilter] = useState(initialFilters.activityTypeFilter);
  const [userFilter, setUserFilter] = useState(initialFilters.userFilter);
  const [timespanFilter, setTimespanFilter] = useState(initialFilters.timespanFilter);
  const [userActivitiesToday, setUserActivitiesToday] = useState([]);
  const [refreshCounter, setRefreshCounter] = useState(0);

  const dataSource = buildSearchParams(
    _.merge(
      buildApiFilters({
        productFilter: productFilter,
        activityTypeFilter: activityTypeFilter,
        userFilter: userFilter,
        timespanFilter: timespanFilter,
      }),
      {
        refreshCounter: refreshCounter,
      }
    )
  );

  let dataSourceString = dataSource.toString();

  if (userFilter === "all") {
    _.forEach(lawyers, (lawyer) => {
      dataSourceString += "&assignedUser[]=" + lawyer["@id"];
    });
  }

  const { activities, isLoading } = useActivitiesFetching(dataSourceString, backofficeUsers);

  const postProcessFilterActivities = useCallback(
    (activities) => {
      if (timespanFilter === "overdue") {
        activities = _.filter(activities, ["activityStatus", ACTIVITY_OVERDUE]);
      }
      return activities;
    },
    [timespanFilter]
  );

  const updateUserActivitiesToday = useCallback(async () => {
    if (userFilter === "all") {
      // this is only used to show the number of activities, which is not needed if all users' activities are shown
      setUserActivitiesToday([]);
      return;
    }
    const filters = {
      assignedUser: userFilter,
      "dueDate[before]": moment().format("YYYY-MM-DD"),
      type: deadlineActivityTypes,
      done: false,
      deleted: false,
      pagination: false,
    };

    let searchParams = buildSearchParams(filters);

    const userActivitiesResult = await ApiClient.get("activities?" + searchParams.toString());
    setUserActivitiesToday(userActivitiesResult["hydra:member"]);
  }, [userFilter]);

  const updateActivities = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  useEffect(() => {
    updateUserActivitiesToday();
  }, [userFilter, updateUserActivitiesToday]);

  useEffect(() => {
    localStorage.setItem(
      "deadlineFilters",
      JSON.stringify(
        _.merge(
          {},
          {
            productFilter: productFilter,
            activityTypeFilter: activityTypeFilter,
            userFilter: userFilter,
            timespanFilter: timespanFilter,
          }
        )
      )
    );
  }, [productFilter, userFilter, timespanFilter, activityTypeFilter]);

  const filteredActivities = isLoading ? [] : postProcessFilterActivities(activities);

  if (lawyers.length < 1) {
    return null;
  }

  return (
    <PageContentContainer>
      <PageHeadline main="Fristenkalender" />
      <ActivityOverviewHeader
        activityCount={filteredActivities.length}
        setProductFilter={(data) => {
          updateActivities();
          setProductFilter(data);
        }}
        productFilter={productFilter}
        setActivityTypeFilter={(data) => {
          updateActivities();
          setActivityTypeFilter(data);
        }}
        activityTypeFilter={activityTypeFilter}
        userFilter={userFilter}
        setUserFilter={(data) => {
          updateActivities();
          setUserFilter(data);
        }}
        timespanFilter={timespanFilter}
        setTimespanFilter={(data) => {
          updateActivities();
          setTimespanFilter(data);
        }}
        userActivitiesToday={userActivitiesToday}
        isLoading={isLoading}
        agents={lawyers}
        isDeadlineView
      />
      <TableContainer component={Paper}>
        <ActivityTable isLoading={isLoading} activities={filteredActivities} update={updateActivities} />
      </TableContainer>
    </PageContentContainer>
  );
};

export default DeadlineOverview;
