import React from "react";
import { Paper, Typography } from "@mui/material";
import PropTypes from "prop-types";
import contentBoxStyle from "./contentBoxStyle";
import _ from "lodash";

export default function ContentBox({ children, headline, headlineVariant, padding, margin }) {
  return (
    <Paper sx={{ ...contentBoxStyle[padding + "Padding"], ...contentBoxStyle[margin + "Margin"] }}>
      {!_.isEmpty(headline) && (
        <Typography sx={contentBoxStyle.headline} variant={headlineVariant}>
          {headline}
        </Typography>
      )}
      {children}
    </Paper>
  );
}

ContentBox.propTypes = {
  padding: PropTypes.oneOf(["default", "none"]),
  margin: PropTypes.oneOf(["default", "none"]),
  headlineVariant: PropTypes.oneOf(["h1", "h2", "h3"]).isRequired,
};

ContentBox.defaultProps = {
  padding: "default",
  margin: "default",
  headlineVariant: "h3",
  headline: "",
};
