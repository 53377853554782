//used so that general email templates have these placeholders available in the template creation tool
const applicationTranslations = {
  status: {
    label: "Status",
    values: {
      applied: "Zu Prüfen",
      denied: "Abgelehnt",
    },
  },
};

export default applicationTranslations;
