//used so that general email templates have these placeholders available in the template creation tool
const generalTranslations = {
  customer: {
    telephone: {
      label: "Telefonnummer",
    },
    email: {
      label: "E-Mail-Adresse",
    },
    verification: {
      label: "E-Mail bestätigt am",
    },
    contactTime: {
      label: "Erreichbarkeit",
      values: {
        flexible: "Ich bin flexibel",
        time: "Uhr",
      },
    },
  },
  petitioner: {
    gender: {
      label: "Anrede",
      values: {
        male: "Herr",
        female: "Frau",
      },
    },
    givenName: {
      label: "Vorname",
    },
    familyName: {
      label: "Nachname",
    },
    residenceAddress: {
      streetAddress: {
        label: "Straße und Hausnummer",
      },
      postalCode: {
        label: "PLZ",
      },
      addressLocality: {
        label: "Ort",
      },
    },
  },
  productCategory: {
    labourLaw: "Arbeitsrecht",
    familyLaw: "Familienrecht",
    contractLaw: "Vertragsrecht",
    trafficLaw: "Verkehrsrecht",
  },
  dateTime: {
    monday: "Montag",
    tuesday: "Dienstag",
    wednesday: "Mittwoch",
    thursday: "Donnerstag",
    friday: "Freitag",
  }
};

export default generalTranslations;
