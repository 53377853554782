import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import PageHeadline from "../PageHeadline/PageHeadline";
import NewCaseCard from "./NewCaseCard";
import { isCookieFetched, mercureFetchCookie, mercureSubscribe } from "../../services/mercure";
import _ from "lodash";
import ApiClient from "../../services/ApiClient";
import { BackofficeCase } from "../../types/BackofficeCase";

export default function NewCases() {
  const cookieFetched = isCookieFetched();
  const [backofficeCases, setBackofficeCases] = useState([]);
  const [newBackofficeCase, setNewBackofficeCase] = useState({});

  useEffect(() => {
    if (cookieFetched) {
      const eventSource = mercureSubscribe("/cases/new", setNewBackofficeCase);
      return () => {
        eventSource.close();
      };
    } else {
      mercureFetchCookie();
    }
  }, [cookieFetched]);

  useEffect(() => {
    ApiClient.get("backoffice_cases?order[id]=desc").then((result) => {
      setBackofficeCases(result["hydra:member"].slice(0, 5));
    });
  }, []);

  useEffect(() => {
    if (!_.isEmpty(newBackofficeCase)) {
      appendNewBackofficeCase(newBackofficeCase);
    }
  }, [newBackofficeCase]);

  const appendNewBackofficeCase = (backofficeCase: any) => {
    let newBoCases = [backofficeCase, ...backofficeCases.slice(0, 4)];
    // @ts-ignore
    setBackofficeCases(newBoCases);
  };

  return (
    <>
      <PageHeadline main="Neue Fälle" />
      <Stack alignItems={"center"} spacing={3} sx={{ width: "100%" }}>
        {_.map(backofficeCases, (backofficeCase: BackofficeCase) => (
          <NewCaseCard key={backofficeCase.id} backofficeCase={backofficeCase} />
        ))}
      </Stack>
    </>
  );
}
