const navigationExtensionAllDataStyle = {
  tabs: (props) => ({
    position: "sticky",
    top: props.top || 69,
    zIndex: 1099,
    clipPath: "polygon(0% 0%, 100% 0%, 100% 120%, 0% 120%)",
    paddingTop: 8,
  }),
};

export const navigationExtensionEmotionStyle = {
  tabs: (props) => ({
    position: "sticky",
    top: props.top || 69,
    zIndex: 1099,
    paddingTop: 1,
  }),
};

export default navigationExtensionAllDataStyle;
