import PageHeadline from "../../PageHeadline/PageHeadline";
import React, { useEffect, useState } from "react";
import PageContentContainer from "../../Container/PageContentContainer";
import ApiClient from "../../../services/ApiClient";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import { BeaMessage } from "../../../types/Bea/BeaMessage";
import BeaMessagesAttachments from "../../BeaAttachments/BeaMessagesAttachments";

export default function BeaAttachmentsPage()
{
  const [beaMessages, setBeaMessages] = useState<Array<BeaMessage>>([]);
  useEffect(() => {
    ApiClient.get("bea_messages?mergeStatus=manual").then(
      (beaMessagesResponse) => setBeaMessages(beaMessagesResponse["hydra:member"])
    )
  }, []);

  if(!beaMessages) {
    return <LegalbirdLoader centered={true} />
  }

  return (
    <PageContentContainer>
      <PageHeadline main="beA-Nachrichten Anhänge verarbeiten" />
      <BeaMessagesAttachments beaMessages={beaMessages}/>
    </PageContentContainer>
  )
}