import React, { useEffect } from "react";
import * as PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import useStyles from "./validatorStyle";
//TODO find out whats the difference between default props and default parameters in regards to the persistance of a value.
//if we set dependentValidationFields = [] in the method head the useeffect triggers more than once without the array changing
//this doesnt happen when its in the defaultprops
//so be careful when refactoring this since it will kill validators that were set manually via registervalidators
const ValidatorTextField = ({
  name,
  value,
  registerValidators,
  validators,
  isMandatory ,
  dependentValidationFields,
  label,
  ...rest
}) => {
  const classes = useStyles();
  useEffect(() => {
    registerValidators && validators && registerValidators(name, validators, dependentValidationFields);
    return () => {
      registerValidators && registerValidators(name, []);
    };
  }, [registerValidators, dependentValidationFields, name]);

  return (
    <TextField
      {...rest}
      name={name}
      value={value}
      label={label}
      className={isMandatory && (value === null || value === "") ? classes.mandatory : null}
    />
  );
};

ValidatorTextField.propTypes = {
  validators: PropTypes.arrayOf(PropTypes.object),
  dependentValidationFields: PropTypes.array,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  registerValidators: PropTypes.func,
  isMandatory: PropTypes.bool,
};
ValidatorTextField.defaultProps = {
  isMandatory: false,
  dependentValidationFields: [],
};

export default ValidatorTextField;
