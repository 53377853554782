import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { AbstractCase } from "../../../types/AbstractCase";
import { getResponsibleInstitutes } from "../../../services/Product/ProductService";
import {
  getBeaSafeId,
  getCommunicationMedium,
  handleReferenceLogic,
  hasFaxOrBea,
} from "./communicationHelperFunctions";
import MenuItem from "@mui/material/MenuItem";
import _ from "lodash";
import { translate } from "../../../services/Translations/translatorService";

interface CommunicationMediumProviderData {
  communicationMedium: string;
  setCommunicationMedium: (communicationMedium: string) => void;
  recipientType: string;
  setRecipientType: (recipientType: string) => void;
  assignedLawyerHasBea: boolean;
  communicationMediumEditable: boolean;
  recipientMenuItems: Array<JSX.Element | undefined>;
  open: boolean;
  handleClose: () => void;
  product: AbstractCase;
  externalReference: string;
  externalReferenceIsEditable: boolean;
}

const CommunicationMediumProviderContext = createContext<CommunicationMediumProviderData | null>(null);

export default function CommunicationMediumProvider({
  children,
  product,
  open,
  handleClose,
}: {
  children?: React.ReactNode;
  product: AbstractCase;
  open: boolean;
  handleClose: () => void;
}) {
  const [communicationMedium, setCommunicationMedium] = useState<string>("bea");
  const [recipientType, setRecipientType] = useState<string>("");
  const assignedLawyerHasBea = useMemo(() => !!product?.backofficeCase?.lawyer?.person?.beaData, [product]);
  const recipientMenuItems = useMemo(() => {
    const { courtPath, authorityPath, prosecutionPath } = getResponsibleInstitutes(product);
    let accumulatedRecipientMenuItems = [];
    if (hasFaxOrBea(product, courtPath)) {
      accumulatedRecipientMenuItems.push(
        <MenuItem key={"court"} value={"court"}>
          Zuständiges Gericht
        </MenuItem>
      );
    }

    if (hasFaxOrBea(product, authorityPath)) {
      accumulatedRecipientMenuItems.push(
        <MenuItem key={"authority"} value={"authority"}>
          Zuständige Behörde
        </MenuItem>
      );
    }

    if (hasFaxOrBea(product, prosecutionPath)) {
      accumulatedRecipientMenuItems.push(
        <MenuItem key={"prosecution"} value={"prosecution"}>
          Zuständige Staatsanwaltschaft
        </MenuItem>
      );
    }

    if (
      product.productCategory === "contractLaw" &&
      product.contractualPartnerType === "contractualPartnerLegalEntity" &&
      _.get(product, "contractualPartnerLegalEntity.fax")
    ) {
      accumulatedRecipientMenuItems.push(
        <MenuItem key={"contractualPartnerLegalEntity"} value={"contractualPartnerLegalEntity"}>
          Gegenseite
        </MenuItem>
      );
    }

    if (product.opponentLawFirm && hasFaxOrBea(product, "opponentLawFirm")) {
      accumulatedRecipientMenuItems.push(
        <MenuItem key={"opponentLawyer"} value={"opponentLawyer"}>
          Rechtsanwalt Gegenseite
        </MenuItem>
      );
    }
    if (product.productCategory === "contractLaw" && hasFaxOrBea(product, "debtCollectionAgency")) {
      accumulatedRecipientMenuItems.push(
        <MenuItem key={"debtCollectionAgency"} value={"debtCollectionAgency"}>
          Inkassobüro
        </MenuItem>
      );
    }
    if (product.processParticipantsOrganizations) {
      const processParticipantsOrganizationsMenuItems = _.map(
        product.processParticipantsOrganizations,
        (organization, index) => {
          if (organization.fax) {
            return (
              <MenuItem key={index} value={"processParticipantsOrganizations[" + index + "]"}>
                {translate("participant.labelType.values." + organization.labelType) + " - " + organization.legalName}
              </MenuItem>
            );
          }
        }
      );

      accumulatedRecipientMenuItems = [...accumulatedRecipientMenuItems, ...processParticipantsOrganizationsMenuItems];
    }
    accumulatedRecipientMenuItems.push(
      <MenuItem key={"custom"} value={"custom"}>
        Empfänger frei wählen
      </MenuItem>
    );
    return accumulatedRecipientMenuItems;
  }, [product]);
  const communicationMediumEditable = useMemo(() => {
    return !!(product?.backofficeCase?.lawyer?.person?.beaData && getBeaSafeId(product, recipientType) === "");
  }, [product, recipientType]);
  useEffect(() => {
    setCommunicationMedium(getCommunicationMedium(product, recipientType));
  }, [product, recipientType]);

  const { externalReference, externalReferenceIsEditable } = useMemo(
    () => handleReferenceLogic(product, recipientType),
    [product, recipientType]
  );

  const data = {
    communicationMedium,
    setCommunicationMedium,
    recipientType,
    setRecipientType,
    assignedLawyerHasBea,
    communicationMediumEditable,
    recipientMenuItems,
    open,
    handleClose: () => {
      setCommunicationMedium("bea");
      setRecipientType("");
      handleClose();
    },
    product,
    externalReference,
    externalReferenceIsEditable,
  };

  return (
    <CommunicationMediumProviderContext.Provider value={data}>{children}</CommunicationMediumProviderContext.Provider>
  );
}

export const useCommunicationMedium = () => {
  const communicationMediumContext = useContext(CommunicationMediumProviderContext);
  if (!communicationMediumContext) {
    throw new Error("useCommunicationMedium can only be used inside CommunicationMediumProvider");
  }
  return communicationMediumContext;
};
