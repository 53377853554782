import React, { useEffect, useState } from "react";
import { ExternalLawyerApplication } from "../../types/ExternalLawyerApplication";
import { Grid, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { translate } from "../../services/Translations/translatorService";
import ContentBox from "../ContentBox/ContentBox";
import ButtonLoading from "../Button/ButtonLoading";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../services/ApiClient";
import DisplayableMediaObject from "../MediaObject/DisplayableMediaObject";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

type ExternalApplicationProps = {
  application: ExternalLawyerApplication;
};

export default function ExternalApplication({ application }: ExternalApplicationProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [acceptApplicationOpen, setAcceptApplicationOpen] = useState(false);
  const [denyApplicationOpen, setDenyApplicationOpen] = useState(false);
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!["applied", "denied"].includes(application.status)) {
      navigate("/services/tools/bewerbungen");
    }
  }, []);

  const handleApplication = (status: string) => {
    setIsLoading(true);
    ApiClient.put("external_lawyer_applications/" + application.id, {
      body: JSON.stringify({ status: status, checkedBy: currentUser["@id"] }),
    }).then(() => {
      setIsLoading(false);
      navigate("/services/tools/bewerbungen");
    });
  };

  return (
    <>
      <Stack alignItems={"center"}>
        <Typography>{translate("application.status.values." + application.status)}</Typography>
      </Stack>
      <ContentBox headline={"Angaben zum Anwalt/zur Anwältin"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth={true} margin={"normal"}>
              <InputLabel>Anrede</InputLabel>
              <Select label={"Anrede"} disabled value={application.personalData.gender}>
                <MenuItem value={"female"}>Frau</MenuItem>
                <MenuItem value={"male"}>Herr</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField label={"Vorname"} disabled value={application.personalData.givenName} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField label={"Nachname"} disabled value={application.personalData.familyName} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label={"Straße und Hausnummer"}
              disabled
              value={application.personalData.residenceAddress.streetAddress}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField label={"PLZ"} disabled value={application.personalData.residenceAddress.postalCode} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField label={"Ort"} disabled value={application.personalData.residenceAddress.addressLocality} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField label={"E-Mail-Adresse"} disabled value={application.personalData.email} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField label={"Telefonnummer"} disabled value={application.personalData.telephone} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth={true} margin={"normal"}>
              <InputLabel>Berufserfahrung (in Jahren)</InputLabel>
              <Select label={"Berufserfahrung (in Jahren)"} disabled value={application.yearsOfExperience}>
                <MenuItem value={"<1"}>weniger als 1 Jahr</MenuItem>
                <MenuItem value={"1-5"}>1 - 5 Jahre</MenuItem>
                <MenuItem value={"5-10"}>5 - 10 Jahre</MenuItem>
                <MenuItem value={">10"}>mehr als 10 Jahre</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth={true} margin={"normal"}>
              <InputLabel>Fachanwaltstitel vorhanden</InputLabel>
              <Select label={"Fachanwaltstitel vorhanden"} disabled value={application.hasSpecialistTitles}>
                <MenuItem value={"true"}>Ja</MenuItem>
                <MenuItem value={"false"}>Nein</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth={true} margin={"normal"}>
              <InputLabel>Arbeitsstunden pro Woche</InputLabel>
              <Select label={"Arbeitsstunden pro Woche"} disabled value={application.weeklyHours}>
                <MenuItem value={"10"}>10 Stunden</MenuItem>
                <MenuItem value={"15"}>15 Stunden</MenuItem>
                <MenuItem value={"20"}>20 Stunden</MenuItem>
                <MenuItem value={"25"}>25 Stunden</MenuItem>
                <MenuItem value={"30"}>30 Stunden</MenuItem>
                <MenuItem value={"35"}>35 Stunden</MenuItem>
                <MenuItem value={"40"}>40 Stunden</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth={true} margin={"normal"}>
              <InputLabel>Rechtsbereiche</InputLabel>
              <Select multiple label={"Rechtsbereiche"} disabled value={application.legalFieldExperience}>
                <MenuItem value={"contractLaw"}>Vertragsrecht</MenuItem>
                <MenuItem value={"familyLaw"}>Familienrecht</MenuItem>
                <MenuItem value={"trafficLaw"}>Verkehrsrecht</MenuItem>
                <MenuItem value={"labourLaw"}>Arbeitsrecht</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth={true} margin={"normal"}>
              <InputLabel>Aufgabenart</InputLabel>
              <Select multiple label={"Aufgabenart"} disabled value={application.taskType}>
                <MenuItem value={"legalDocuments"}>
                  Schriftsätze fertigen (Außergerichtliche Schreiben, Klageschriften etc.)
                </MenuItem>
                <MenuItem value={"checkCases"}>Fallprüfung und Ersteinschätzung Vertragsrecht</MenuItem>
                <MenuItem value={"investigationFiles"}>Ermittlungsakten prüfen (z.B. im Verkehrsrecht)</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField label={"Link für Referenz"} disabled value={application.referenceSites} />
          </Grid>
        </Grid>
      </ContentBox>
      <ContentBox headline={"Anwaltszulassung und Titel"}>
        {application.lawyerCertificate !== null && (
          <DisplayableMediaObject mediaObject={application.lawyerCertificate} label={"Anwaltszulassung"} />
        )}
        {application.specialistLawyerCertificate !== null && (
          <DisplayableMediaObject mediaObject={application.specialistLawyerCertificate} label={"Titel"} />
        )}
      </ContentBox>
      <Stack alignItems={"center"} spacing={2}>
        <Button onClick={() => setAcceptApplicationOpen(true)} variant={"contained"} fullWidth={false}>
          Bewerbung annehmen
        </Button>
        <Button onClick={() => setDenyApplicationOpen(true)} fullWidth={false}>
          Bewerbung ablehnen
        </Button>
      </Stack>
      <LegalbirdIoModal
        open={acceptApplicationOpen}
        handleClose={() => setAcceptApplicationOpen(false)}
        title={"Annehmen und Nutzer freischalten"}
        submitButton={
          <ButtonLoading variant={"contained"} isLoading={isLoading} onClick={() => handleApplication("accepted")}>
            Freischalten
          </ButtonLoading>
        }
      >
        Möchten Sie den Nutzer freischalten? Er kann sich dann ein Passwort für sein Konto vergeben und Aufgaben bei
        Legalbird bearbeiten
      </LegalbirdIoModal>
      <LegalbirdIoModal
        open={denyApplicationOpen}
        handleClose={() => setDenyApplicationOpen(false)}
        title={"Bewerbung ablehnen"}
        submitButton={
          <ButtonLoading variant={"contained"} isLoading={isLoading} onClick={() => handleApplication("denied")}>
            Ablehnen
          </ButtonLoading>
        }
      >
        Möchten Sie die Bewerbung ablehnen? Wichtig: Der Nutzer kann sich (zumindest mit der genutzten E-Mail) nicht
        erneut bei Legalbird bewerben.
      </LegalbirdIoModal>
    </>
  );
}
