import React, { useState } from "react";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import useStyles from "./authorityInvoiceStyle";
import { useSnackbar } from "notistack";
import ApiClient from "../../services/ApiClient";
import Snackbar from "../Snackbar/Snackbar";
import ButtonLoading from "../Button/ButtonLoading";

const AuthorityInvoices = () => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const processAuthorityInvoices = async () => {
    setIsLoading(true);
    const res = await ApiClient.post("service/process_authority_invoices");

    if (_.isError(res)) {
      enqueueSnackbar(res.errors, {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } else {
      enqueueSnackbar("", {
        content: () => (
          <div>
            <Snackbar
              message={"Erstellung der EA-Rechnungsdaten CSV wurde erfolgreich angestoßen. Es kann etwas dauern..."}
              buttonText={"zur CSV Datei"}
              buttonLink={"https://drive.google.com/drive/folders/1TtVXM83YB4wAf6Kz_86iGvFP8UzJkTT8"}
            />
          </div>
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
    setIsLoading(false);
  };

  return (
    <Grid item xs={12} className={classes.buttonContainer}>
      <ButtonLoading onClick={processAuthorityInvoices} variant={"contained"} isLoading={isLoading}>
        Ordner EA Rechnungen auslesen
      </ButtonLoading>
    </Grid>
  );
};

export default AuthorityInvoices;
