import { translate } from "../../Translations/translatorService";
import { AbstractCase } from "../../../types/AbstractCase";
import { validateFields } from "../statusChangeValidators";

export const statusCheckedValidatorsTraffic = (traffic: AbstractCase, returnRequiredFields = false) => {
  let requiredFields = [
    "paymentType",
    "responsibleAuthority",
    "authorityNotification",
  ];

  if (!traffic.processStoppedDate) {
    requiredFields.push("fineNoticeReference");
  }

  if (traffic.legalExpenseInsurance === true || traffic.insuranceRequested === "yes") {
    requiredFields.push("insurance.deductible", "insurance.insurance", "insurance.insurancePolicyNumber");
  }

  let errorMessages = [];

  if (traffic.authorityNotification === "no_notice") {
    errorMessages.push(
      `”${translate("traffic.authorityNotification.label")}” darf nicht “${translate(
        "traffic.authorityNotification.values.no_notice"
      )}” sein!`
    );
  }

  if (traffic.accusation === "speeding" && traffic.speeding.speeding === ">40") {
    errorMessages.push(`Die Geschwindigkeit muss spezifiziert werden!`);
  }

  if (!traffic.backofficeCase || traffic.backofficeCase.accountManager === null) {
    errorMessages.push("Fall hat keinen Zuständigen - bitte zuordnen!");
  }
  if (returnRequiredFields) {
    return requiredFields;
  }
  return [...errorMessages, ...validateFields(requiredFields, traffic)];
}
