import React, { useEffect, useState } from "react";
import ContentBox from "../ContentBox/ContentBox";
import { Chip, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import ButtonLoading from "../Button/ButtonLoading";
import _ from "lodash";
import { Add } from "@mui/icons-material";
import ApiClient from "../../services/ApiClient";

export default function BeaAttachmentFilter() {
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState([]);
  const [registryEntryIri, setRegistryEntryIri] = useState("");
  const [initialFilters, setInitialFilters] = useState([]);
  const [newFilter, setNewFilter] = useState("");

  useEffect(() => {
    fetchFilters()
      .then((result) => {
        const sortedResult = _.sortBy(result);
        setInitialFilters(sortedResult);
        setFilters(sortedResult);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleSaveFilters = () => {
    setIsLoading(true);

    const payload = {
      registryValue: JSON.stringify(filters)
    };

    ApiClient.put(registryEntryIri, { body: JSON.stringify(payload)}).then(() => {
      setIsLoading(false);
    });
  };

  const fetchFilters = async () => {
    const resultCollection = await ApiClient.get("registry_entries?registryKey=beaAttachmentFilters")
    const entry = _.head(resultCollection["hydra:member"]);
    setRegistryEntryIri(entry["@id"]);
    const filters = entry["registryValue"];
    return JSON.parse(filters);
  };

  const removeFilter = (filterToRemove) => {
    setFilters(_.filter(filters, (filter) => filter !== filterToRemove));
  };

  const addFilter = () => {
    setFilters(_.sortBy([...filters, newFilter]));
    setNewFilter("");
  };

  return (
    <ContentBox>
      <Typography>
        Hier bitte die Wörter eingeben, nach denen unser System Anhänge ausfiltern soll. Anhänge die diese Wörter
        enthalten, sind dann nicht mehr im Tool "beA-Anlagen" sichtbar.
        <br />
        <br />
        Beispiel: pruefvermerk, vermerk_pruefung, prüfvermerk
      </Typography>
      <Grid container sx={{ margin: "1rem 0" }} spacing={2}>
        {_.map(filters, (filter, index) => (
          <Grid item key={index}>
            <Chip onDelete={() => removeFilter(filter)} label={filter} variant={"outlined"} />
          </Grid>
        ))}
      </Grid>
      <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={2}>
        <TextField
          placeholder={"Filterwort"}
          value={newFilter}
          onChange={(event) => setNewFilter(event.target.value)}
        />
        <IconButton disabled={newFilter === ""} onClick={addFilter}>
          <Add />
        </IconButton>
      </Stack>
      <ButtonLoading
        onClick={handleSaveFilters}
        isLoading={isLoading}
        variant={"contained"}
        disabled={_.isEqual(filters, initialFilters)}
      >
        Speichern
      </ButtonLoading>
    </ContentBox>
  );
}
