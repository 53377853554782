import { Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  calender: {
    margin: "2rem auto",
    "& .rmdp-wrapper": {
      borderColor: theme.palette.secondary.main,
      border: "1px solid",
      boxShadow: "0 0 5px",
    },
    "& .rmdp-panel-body li": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: "0 0 2px",
    },
    "& .rmdp-week-day": {
      color: theme.palette.primary.main,
    },
    "& .rmdp-day.rmdp-deactive": {
      color: theme.palette.secondary.main,
    },
    "& .rmdp-range": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: "0 0 3px",
    },
    "& .rmdp-arrow": {
      border: "solid",
      borderColor: theme.palette.primary.main,
      borderWidth: "0 2px 2px 0",
    },
    "& .rmdp-arrow-container:hover": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: "0 0 3px",
    },
    "& .rmdp-panel-body::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
    },
    "& .rmdp-day.rmdp-today span": {
      backgroundColor: theme.palette.primary.light,
      border: "3px solid " + theme.palette.primary.main,
      fontWeight: 900,
    },
    "& .rmdp-rtl .rmdp-panel": {
      borderLeft: "unset",
      borderRight: "1px solid",
      borderColor: theme.palette.secondary.main,
    },
    "& .rmdp-day.rmdp-selected span:not(.highlight)": {
      backgroundColor: "#ff0000",
      boxShadow: "0 0 3px",
    },
    "& .rmdp-day:not(.rmdp-day-hidden) span:hover": {
      backgroundColor: "#ff0000",
      opacity: 0.2,
    },
    "& .b-deselect": {
      color: theme.palette.secondary.light,
      backgroundColor: "white",
    },
    "& .rmdp-action-button": {
      color: theme.palette.primary.main,
    },
    "& .rmdp-button:not(.rmdp-action-button)": {
      backgroundColor: theme.palette.primary.main,
    },
    "& .rmdp-button:not(.rmdp-action-button):hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

export default useStyles;
