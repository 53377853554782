import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import activityActionsStyles from "./activityActionsStyles";
import PopUpMenu from "../../../components/PopUpMenu/PopUpMenu";
import EditActivity from "../../../components/Activities/EditActivity/EditActivity";
import LegalbirdIoModal from "../../../components/Modal/LegalbirdIoModal";
import ButtonLoading from "../../../components/Button/ButtonLoading";
import CalendlyAssessmentModal from "../../../components/CalendlyAssessments/CalendlyAssessmentModal";
import { Box, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { getAvailableActivityActions, isCalendlyActivity } from "../../../services/Activities/activityFunctions";
import EditActivityNote from "../../../components/Activities/ActivityActions/EditActivityNote";
import { Activity } from "../../../types/Activity";
import { AbstractCase } from "../../../types/AbstractCase";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";

interface ActivityActionsProps {
  activity: Activity;
  updateActivities: (...args: any[]) => any;
  product: AbstractCase;
}

const ActivityActions = ({ activity, updateActivities, product }: ActivityActionsProps) => {
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [assessmentOpen, setAssessmentOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const updateMutation = useMutation(updateResource);
  const currentUser = useCurrentUser();

  const handleDeleteActivity = async () => {
    setIsLoading(true);
    await updateMutation.mutateAsync({
      uri: "activities",
      id: activity.id || 0,
      data: {
        deleted: true,
      },
    });
    setIsLoading(false);
    updateActivities();
    setDeleteOpen(false);
  };

  const toggleActivityDone = async () => {
    if (isCalendlyActivity(activity)) {
      if (!activity.done) {
        setAssessmentOpen(true);
      }
      return;
    }
    setIsLoading(true);
    await updateMutation.mutateAsync({
      uri: "activities",
      id: activity.id || 0,
      data: {
        done: !activity.done,
      },
    });
    updateActivities();
  };

  const availableActions = getAvailableActivityActions(activity, currentUser, product);
  return (
    <>
      <Grid item xs={3}>
        <ButtonLoading
          isLoading={isLoading}
          variant={"contained"}
          color={"primary"}
          onClick={toggleActivityDone}
          disabled={!availableActions.canComplete}
          sx={activityActionsStyles.doneButton}
          fullWidth={false}
        >
          Erledigt
        </ButtonLoading>
      </Grid>

      {availableActions.canEditActivityNote && (
        <Grid item xs={3}>
          <EditActivityNote activity={activity} updateActivities={updateActivities} />
        </Grid>
      )}

      {availableActions.canDelete && availableActions.canEdit && (
        <Grid item xs={3}>
          <PopUpMenu>
            <MenuItem onClick={() => setEditOpen(true)}>Bearbeiten</MenuItem>
            <MenuItem onClick={() => setDeleteOpen(true)}>Löschen</MenuItem>
          </PopUpMenu>
        </Grid>
      )}

      {availableActions.canDelete && !availableActions.canEdit && (
        <Grid item>
          <IconButton onClick={() => setDeleteOpen(true)}>
            <Delete />
          </IconButton>
        </Grid>
      )}

      {availableActions.canEdit && (
        <EditActivity
          key={activity.id}
          activity={activity}
          open={editOpen}
          closeDialog={() => setEditOpen(false)}
          updateActivities={updateActivities}
        />
      )}

      {availableActions.canDelete && (
        <LegalbirdIoModal
          handleClose={() => setDeleteOpen(false)}
          open={deleteOpen}
          title={"Achtung"}
          submitButton={
            <ButtonLoading variant={"contained"} onClick={handleDeleteActivity} isLoading={isLoading}>
              Ja
            </ButtonLoading>
          }
        >
          <Box sx={activityActionsStyles.deleteDialogText}>
            Diese Aktivität wird unwiderruflich gelöscht. Sind Sie sicher, dass Sie die Aktivität löschen möchten?
          </Box>
        </LegalbirdIoModal>
      )}

      {availableActions.canOpenCalendlyAssessmentModal && (
        <CalendlyAssessmentModal
          key={"calendlyAssessmentModal." + activity.id}
          open={assessmentOpen}
          handleClose={() => setAssessmentOpen(false)}
          activity={activity}
          update={updateActivities}
        />
      )}
    </>
  );
};

export default ActivityActions;
