import React, { useState } from "react";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import ValidatorSelect from "../Validator/ValidatorSelect";
import ValidatorTextField from "../Validator/ValidatorTextField";
import CostCalculatorDivorceResponse from "../../types/CostCalculatorDivorceResponse";
import useForm from "../../hooks/useForm";
import { formValue } from "../../services/formServiceFunctions";
import { apiPost } from "../../services/Api/apiCall";
import { costBoxStyles } from "./costBoxStyles";
import { UseForm } from "../../types/UseForm";

interface DivorceProxy {
  netIncomePetitioner: string;
  netIncomePartner: string;
  pensionsExcluded: boolean;
  numberPensions: number | null;
  numberPensionsPartner: number | null;
}

export default function CostBox() {
  const [costCalculatorResponse, setCostCalculatorResponse] = useState<CostCalculatorDivorceResponse | null>(null);

  const initialValues: DivorceProxy = {
    netIncomePetitioner: "",
    netIncomePartner: "",
    pensionsExcluded: false,
    numberPensions: null,
    numberPensionsPartner: null,
  };

  function onSubmit() {
    setCostCalculatorResponse(null);
    apiPost("lb/calculate_divorce_fees", {
      ...values,
      netIncomePetitioner: parseInt(values.netIncomePetitioner),
      netIncomePartner: parseInt(values.netIncomePartner),
    }).then((response) => {
      setCostCalculatorResponse(response);
    });
  }

  let { values, handleChange, registerValidators }: UseForm = useForm({
    initialValues,
    onSubmit: () => {},
  });

  const eur = (val: any) => {
    return val.toLocaleString("de-DE", { style: "currency", currency: "EUR" });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <FormControl fullWidth={true}>
            <ValidatorTextField
              label="Ihr mtl. Nettoeinkommen"
              id="netIncomePetitioner"
              name="netIncomePetitioner"
              value={formValue(values, "netIncomePetitioner")}
              onChange={handleChange}
              validators={[]}
              isMandatory={true}
              registerValidators={registerValidators}
              dependentValidationFields={[]}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl fullWidth={true}>
            <ValidatorTextField
              label="Mtl. Nettoeinkommen Partner"
              id="netIncomePartner"
              name="netIncomePartner"
              value={formValue(values, "netIncomePartner")}
              onChange={handleChange}
              validators={[]}
              isMandatory={true}
              registerValidators={registerValidators}
              dependentValidationFields={[]}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <br />
          <Grid container>
            <Grid item xs={10}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="pensionsExcluded"
                    name="pensionsExcluded"
                    checked={formValue(values, "pensionsExcluded")}
                    onChange={handleChange}
                    value="pensionsExcluded"
                    color="primary"
                  />
                }
                label="Wir möchten den Versorgungsausgleich ausschließen"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ValidatorSelect
            label={"Ihre Rentenversicherungen"}
            fullWidth
            margin={"none"}
            value={formValue(values, "numberPensions")}
            disabled={!!formValue(values, "pensionsExcluded")}
            onChange={handleChange}
            name={"numberPensions"}
            inputProps={{
              name: "numberPensions",
              id: "numberPensions",
            }}
            validators={[]}
            helperText={"Wie viele Versicherungen haben Sie?"}
            registerValidators={registerValidators}
          >
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </ValidatorSelect>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ValidatorSelect
            label={"Rentenversicherungen Partner"}
            fullWidth
            margin={"none"}
            value={formValue(values, "numberPensionsPartner")}
            disabled={formValue(values, "pensionsExcluded")}
            onChange={handleChange}
            name={"numberPensionsPartner"}
            inputProps={{
              name: "numberPensionsPartner",
              id: "numberPensionsPartner",
            }}
            validators={[]}
            registerValidators={registerValidators}
          >
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </ValidatorSelect>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button sx={{ paddingX: "8px" }} variant="contained" type="submit" fullWidth onClick={onSubmit}>
          {!!costCalculatorResponse ? "Kosten erneut berechnen" : "Kosten berechnen"}
        </Button>
      </Grid>
      {!!costCalculatorResponse && (
        <Grid item xs={12}>
          <Table sx={costBoxStyles.table}>
            <TableBody>
              <TableRow sx={costBoxStyles.tableRow}>
                <TableCell sx={costBoxStyles.tableCell}>
                  <strong>Gegenstandswert der Scheidung</strong>
                </TableCell>
                <TableCell sx={costBoxStyles.tableCellValue} />
              </TableRow>
              <TableRow sx={costBoxStyles.tableRow}>
                <TableCell sx={costBoxStyles.tableCell}>Gegenstandswert Scheidungsverfahren</TableCell>
                <TableCell sx={costBoxStyles.tableCellValue}>
                  {eur(costCalculatorResponse.valueInDisputeDivorce)}
                </TableCell>
              </TableRow>
              <TableRow sx={costBoxStyles.tableRow}>
                <TableCell sx={costBoxStyles.tableCell}>Gegenstandswert Versorgungsausgleich</TableCell>
                <TableCell sx={costBoxStyles.tableCellValue}>
                  {eur(costCalculatorResponse.valueInDisputePensions)}
                </TableCell>
              </TableRow>
              <TableRow sx={costBoxStyles.tableRow}>
                <TableCell sx={{ ...costBoxStyles.tableCell, ...costBoxStyles.underlined }}>
                  Gegenstandswert insgesamt
                </TableCell>
                <TableCell sx={{ ...costBoxStyles.tableCellValue, ...costBoxStyles.underlined }}>
                  <strong>{eur(costCalculatorResponse.valueInDispute)}</strong>
                </TableCell>
              </TableRow>
              <TableRow sx={costBoxStyles.tableRow}>
                <TableCell sx={{ ...costBoxStyles.tableCell, ...costBoxStyles.tableCellHeader }}>
                  <strong>Rechtsanwaltsgebühren inkl. MwSt</strong>
                </TableCell>
                <TableCell sx={costBoxStyles.tableCellValue} />
              </TableRow>
              <TableRow sx={costBoxStyles.tableRow}>
                <TableCell sx={costBoxStyles.tableCell}>Verfahrensgebühr</TableCell>
                <TableCell sx={costBoxStyles.tableCellValue}>{eur(costCalculatorResponse.processFee)}</TableCell>
              </TableRow>
              <TableRow sx={costBoxStyles.tableRow}>
                <TableCell sx={costBoxStyles.tableCell}>Auslagenpauschale</TableCell>
                <TableCell sx={costBoxStyles.tableCellValue}>{eur(costCalculatorResponse.expenses)}</TableCell>
              </TableRow>
              <TableRow sx={costBoxStyles.tableRow}>
                <TableCell sx={costBoxStyles.tableCell}>Terminsgebühr</TableCell>
                <TableCell sx={costBoxStyles.tableCellValue}>{eur(costCalculatorResponse.hearingFee)}</TableCell>
              </TableRow>
              <TableRow sx={costBoxStyles.tableRow}>
                <TableCell sx={{ ...costBoxStyles.tableCell, ...costBoxStyles.underlined }}>
                  Rechtsanwaltsgebühren insgesamt
                </TableCell>
                <TableCell sx={{ ...costBoxStyles.tableCellValue, ...costBoxStyles.underlined }}>
                  <strong>{eur(costCalculatorResponse.sumOfAllFeesEstimate)}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={costBoxStyles.tableCell}>
                  <strong>Gerichtskosten</strong>
                </TableCell>
                <TableCell sx={costBoxStyles.tableCellValue}>
                  <strong>{eur(costCalculatorResponse.courtFee)}</strong>
                </TableCell>
              </TableRow>
              <TableRow sx={costBoxStyles.doubleUnderlinedRow}>
                <TableCell sx={costBoxStyles.doubleUnderlinedCell} />
                <TableCell sx={costBoxStyles.doubleUnderlinedCell} />
              </TableRow>
              <TableRow>
                <TableCell sx={costBoxStyles.tableCell}>
                  <Typography sx={costBoxStyles.primary} color={"primary"}>
                    Scheidungskosten insgesamt
                  </Typography>
                </TableCell>
                <TableCell sx={costBoxStyles.tableCellValue}>
                  <Typography color={"primary"} sx={costBoxStyles.total}>
                    {eur(costCalculatorResponse.courtFee + costCalculatorResponse.sumOfAllFeesEstimate)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography fontSize={"10px"}>
            Diese Kostenberechnung ist lediglich eine grobe Schätzung. Die genauen Kosten ergeben sich erst nach der
            Angabe aller Daten. Maßgeblich ist allein der vom Gericht festgesetzte Wert.
          </Typography>
        </Grid>
      )}
    </>
  );
}
