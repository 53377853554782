import { PaidTask } from "../../../types/PaidTask";
import _ from "lodash";
import { PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED } from "../paidTaskStatus";
import Button from "@mui/material/Button";
import RequestExternalLawyerModal from "./RequestExternalLawyerModal";
import React, { useCallback, useState } from "react";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import Snackbar from "../../Snackbar/Snackbar";
import { getCaseLinkByBackofficeCase } from "../../../services/Product/ProductService";
import { useSnackbar } from "notistack";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";

export default function RequestButton({ paidTask }: { paidTask: PaidTask }) {
  const currentUser = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const [modalOpen, setModalOpen] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const updateMutation = useMutation(updateResource);

  const dismissRequestedUser = useCallback(async () => {
    setIsLoading(true);
    await updateMutation.mutateAsync({
      uri: "paid_tasks",
      id: paidTask.id,
      data: {
        requestedUser: null,
        requestedUserDate: null,
        status: 10,
      },
    });
    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar
            message={"Anfrage wurde gelöscht"}
            buttonType={"link"}
            buttonLink={getCaseLinkByBackofficeCase(paidTask.backofficeCase)}
            buttonTarget={"_self"}
            buttonText={"Zum Fall"}
          />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    setIsLoading(false);
    setModalOpen("");
  }, [paidTask]);

  if (
    currentUser.isExternal ||
    !_.includes([PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED], paidTask.status) ||
    paidTask.assignee !== null
  ) {
    return null;
  }

  if (paidTask.requestedUser === null) {
    return (
      <>
        <Button color={"primary"} onClick={() => setModalOpen("requestLawyer")}>
          Anwalt anfragen
        </Button>
        <RequestExternalLawyerModal
          paidTask={paidTask}
          open={modalOpen === "requestLawyer"}
          closeModal={() => setModalOpen("")}
        />
      </>
    );
  } else {
    return (
      <>
        <Button color={"primary"} onClick={() => setModalOpen("dismissRequestLawyer")}>
          Anfrage löschen
        </Button>
        <LegalbirdIoModal
          handleClose={() => setModalOpen("")}
          open={modalOpen === "dismissRequestLawyer"}
          title={"Anfrage löschen"}
          submitButton={
            <ButtonLoading variant={"contained"} color={"primary"} onClick={dismissRequestedUser} isLoading={isLoading}>
              Anfrage löschen
            </ButtonLoading>
          }
        >
          Möchten Sie die Anfrage löschen? Die Aufgabe kann dann frei von allen Nutzern angenommen werden.
        </LegalbirdIoModal>
      </>
    );
  }
}
