/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React from "react";
import {Box, Stack, useTheme} from "@mui/material";
import footerStyle from "./footerStyle";
import Grid from "@mui/material/Grid";
import legalbirdLogo from "../../assets/img/logo/legalbird-logo-white.svg";
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";

export default function Footer() {
  const style = footerStyle(useTheme());
  return (
    <Box sx={style.footer}>
      <footer>
        <Grid container sx={style.footerContainer} alignItems={"center"}>
          <Grid item xs={12} md={3} lg={2}>
            <Box component={"img"} src={legalbirdLogo} alt={"Legalbird"} sx={style.logo} />
          </Grid>
          <Grid item xs={6} md={3} lg={2}>
            <Stack spacing={2} direction={"row"}>
              <Typography component={Link} color={"inherit"} to={"/nutzungsbedingungen"}>
                Nutzungsbedingungen
              </Typography>
              <Typography component={Link} color={"inherit"} to={"/datenschutz"}>
                Datenschutz
              </Typography>
              <Typography component={Link} color={"inherit"} to={"/impressum"}>
                Impressum
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </footer>
    </Box>
  );
}
