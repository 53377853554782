import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  loginIcon: {
    height: 30,
    width: "auto",
  },
}));

export default useStyles;
