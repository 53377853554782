import React from "react";
import { Box, CardActionArea } from "@mui/material";
import _ from "lodash";
import { HitProperties } from "./hitType";
import hitStyles from "./hitStyles";

export default function ProsecutionHit({ hit, resetHits, onHitClick, itemSize }: HitProperties) {
  const highlightResult = hit._highlightResult;
  const prosecutionId = parseInt(_.replace(hit.objectID, "prosecution::", ""));

  if (!highlightResult || !prosecutionId) {
    return null;
  }

  const handleCardClick = () => {
    resetHits();
    onHitClick && onHitClick(prosecutionId);
  };

  return (
    <CardActionArea sx={{ height: itemSize + "px", ...hitStyles.cardActionArea }} onClick={handleCardClick}>
      <Box sx={hitStyles.headline}>
        {highlightResult.name && <span dangerouslySetInnerHTML={{ __html: highlightResult.name.value + " " }} />}
        {highlightResult.department && (
          <span dangerouslySetInnerHTML={{ __html: highlightResult.department.value + " " }} />
        )}
        {highlightResult.subDepartment && (
          <span dangerouslySetInnerHTML={{ __html: highlightResult.subDepartment.value }} />
        )}
      </Box>
      {!!hit.streetAddress && (
        <Box marginBottom={"0.5rem"}>
          {hit.streetAddress}
          <br />
          {hit.postalCode + " "}
          {highlightResult.addressLocality && (
            <span dangerouslySetInnerHTML={{ __html: highlightResult.addressLocality.value }} />
          )}
        </Box>
      )}
      {!!hit.postOfficeBoxNumber && (
        <Box>
          {hit.postOfficeBoxNumber}
          <br />
          {hit.postOfficeBoxPostalCode} {hit.postOfficeBoxAddressLocality}
        </Box>
      )}
      <Box>
        {hit.email && (
          <>
            E-Mail: {hit.email}
            <br />
          </>
        )}
        {hit.telephone && (
          <>
            Tel: {hit.telephone}
            <br />
          </>
        )}
        {hit.fax && (
          <>
            Fax: {hit.fax}
            <br />
          </>
        )}
      </Box>
    </CardActionArea>
  );
}
