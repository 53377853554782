const scannedMediaObjectListStyle = {
  centerText: {
    textAlign: "center",
  },
  button: {
    display: "block",
    width: 300,
    margin: "2rem auto 0 auto",
  },
  identifierColumn: {
    minWidth: 300,
  },
  searchColumn: {
    width: 200,
  },
};

export default scannedMediaObjectListStyle;
