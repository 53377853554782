import { apiGet, apiPut, apiPost, apiDelete } from "../Api/apiCall";

export const queryKeys = {
  all: (uri: string) => [uri] as const,
  collections: (uri: string) => [...queryKeys.all(uri), "collections"] as const,
  collection: (uri: string, filters: object = {}) => [...queryKeys.collections(uri), { filters }] as const,
  items: (uri: string) => [...queryKeys.all(uri), "items"] as const,
  item: (uri: string, id: number) => [...queryKeys.items(uri), id] as const
};

export const fetchResource = async (uri: string, id: number, options?: Record<string, string>) => {
  return await apiGet(uri, id, {}, options);
};

// urlParams is Record<string, any> because URLSearchParams won't accept Record<string, number|string>
export const fetchCollection = async (
  uri: string,
  urlParams: Record<string, any> = {},
  options?: Record<string, any>
) => {
  return await apiGet(uri, null, urlParams, options);
};

interface CreateResourceParams {
  uri: string;
  data: Record<string, any>;
  options?: Record<string, string>;
}

export const createResource = async ({ uri, data, options }: CreateResourceParams): Promise<any> => {
  return await apiPost(uri, data, options);
};

export interface UpdateResourceParams extends CreateResourceParams {
  id: number;
}

export const updateResource = async ({ uri, id, data, options }: UpdateResourceParams): Promise<any> => {
  return await apiPut(uri, id, data, options);
};

interface DeleteResourceParams {
  uri: string;
  id: number;
}

export const deleteResource = async ({ uri, id }: DeleteResourceParams): Promise<any> => {
  return await apiDelete(uri, id);
};
