import React from "react";
import AddDataset from "./AddDataset";
import { datasetConfigs } from "./datasetHelper";
import EditDataset from "./EditDataset";

export default function DatasetEntityPicker({
  datasetType,
  datasetAction,
}: {
  datasetType: string;
  datasetAction: string;
}) {
  return datasetAction === "add" ? (
    <AddDataset datasetConfig={datasetConfigs[datasetType]} />
  ) : (
    <EditDataset datasetConfig={datasetConfigs[datasetType]} />
  );
}
