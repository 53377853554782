import React from "react";
import { Link } from "react-router-dom";
import { Button, Paper } from "@mui/material";
import error404PageStyle from "./error404PageStyle";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(error404PageStyle);

const Error404Page = () => {
  const classes = useStyles();
  return (
    <div className={classes.grey}>
      <Paper className={classes.noAccessBox}>
        <Typography variant={"h3"}>Die aufgerufene Seite konnte nicht gefunden werden</Typography>
        <Button component={Link} variant={"text"} to={"/services"}>
          Zurück zur Übersicht
        </Button>
      </Paper>
    </div>
  );
};

export default Error404Page;
