import PropTypes from "prop-types";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import React, { useState } from "react";
import SubmitButton from "../../Button/SubmitButton";
import useForm from "../../../hooks/useForm";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { getProductData } from "../../../services/Product/ProductService";
import ReactGA from "react-ga4";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../services/ReactQuery/reactQueryService";

function PauseModal({ product, open, handleClose, refreshPage }) {
  const [isLoading, setIsLoading] = useState(false);
  const updateMutation = useMutation(updateResource);

  const initialValues = {
    preventAllSystemEmails: product.preventAllSystemEmails,
    preventAccounting: product.preventAccounting,
    preventAllSystemActivities: product.backofficeCase.preventAllSystemActivities,
    preventAllSystemBeaOrFax: product.backofficeCase.preventAllSystemBeaOrFax,
  };
  const onSubmit = async () => {
    setIsLoading(true);

    if (values.preventAllSystemEmails && !product.preventAllSystemEmails) {
      ReactGA.event({
        category: "UserAction",
        action: "preventAllSystemEmails",
      });
    }

    if (values.preventAccounting && !product.preventAccounting) {
      ReactGA.event({
        category: "UserAction",
        action: "preventAccounting",
      });
    }

    if (values.preventAllSystemActivities && !product.backofficeCase.preventAllSystemActivities) {
      ReactGA.event({
        category: "UserAction",
        action: "preventAllSystemActivities",
      });
    }

    if (values.preventAllSystemBeaOrFax && !product.backofficeCase.preventAllSystemBeaOrFax) {
      ReactGA.event({
        category: "UserAction",
        action: "preventAllSystemBeaOrFax",
      });
    }

    if (
      values.preventAllSystemEmails !== product.preventAllSystemEmails ||
      values.preventAccounting !== product.preventAccounting
    ) {
      await updateMutation.mutateAsync({
        id: product.id,
        uri: getProductData(product.productClassName, "apiUri"),
        data: {
          preventAllSystemEmails: values.preventAllSystemEmails,
          preventAccounting: values.preventAccounting,
        },
      });
    }
    if (
      values.preventAllSystemActivities !== product.backofficeCase.preventAllSystemActivities ||
      values.preventAllSystemBeaOrFax !== product.backofficeCase.preventAllSystemBeaOrFax
    ) {
      await updateMutation.mutateAsync({
        id: product.backofficeCase.id,
        uri: "backoffice_cases",
        data: {
          preventAllSystemActivities: values.preventAllSystemActivities,
          preventAllSystemBeaOrFax: values.preventAllSystemBeaOrFax,
        },
      });
    }
    refreshPage();
    setIsLoading(false);
    handleClose();
  };

  const { values, handleSubmit, handleChange } = useForm({
    initialValues,
    onSubmit,
    identifier: product,
  });
  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={"Automatische Funktionen pausieren"}
      submitButton={
        <SubmitButton isLoading={isLoading} onClick={handleSubmit} variant={"contained"}>
          Speichern
        </SubmitButton>
      }
    >
      <p>Welche automatischen Funktionen möchten Sie pausieren?</p>
      <Grid container>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="preventAllSystemEmails"
                checked={values.preventAllSystemEmails}
                onChange={handleChange}
                value="preventAllSystemEmails"
                color="primary"
              />
            }
            label="E-Mails und Nachrichten pausieren"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="preventAllSystemBeaOrFax"
                checked={values.preventAllSystemBeaOrFax}
                onChange={handleChange}
                value="preventAllSystemBeaOrFax"
                color="primary"
              />
            }
            label="beA/Fax pausieren"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="preventAccounting"
                checked={values.preventAccounting}
                onChange={handleChange}
                value="preventAccounting"
                color="primary"
              />
            }
            label="Konto/Rechnungen pausieren"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="preventAllSystemActivities"
                checked={values.preventAllSystemActivities}
                onChange={handleChange}
                value="preventAllSystemActivities"
                color="primary"
              />
            }
            label="Aktivitäten pausieren"
          />
        </Grid>
      </Grid>
    </LegalbirdIoModal>
  );
}

PauseModal.propTypes = {
  product: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refreshPage: PropTypes.func,
};

PauseModal.defaultProps = {
  refreshPage: () => {},
};
export default PauseModal;
