import * as stagesDocument from "../../../Stages/StagesDocument";
import { insurancePickerValidator, postalCodeDefault, textFieldDefault } from "../../../validationRules";

export const fullFormDefinition = product => ({
  product: {
    sections: [
      {
        label: "Angaben Antragsteller",
        elements: [
          {
            type: "ValidatorSelect",
            path: "death.petitioner.gender",
            options: () => [{ value: "male" }, { value: "female" }],
          },
          {
            type: "ValidatorTextField",
            path: "death.petitioner.givenName",
          },
          {
            type: "ValidatorTextField",
            path: "death.petitioner.familyName",
          },
          {
            type: "ValidatorTextField",
            path: "death.petitioner.residenceAddress.streetAddress",
            validators: textFieldDefault,
            isDisabled: ({ product }) => product.stage >= stagesDocument.STAGE_CHECKED,
          },
          {
            type: "ValidatorTextField",
            path: "death.petitioner.residenceAddress.postalCode",
            validators: [...textFieldDefault, postalCodeDefault],
            isDisabled: ({ product }) => product.stage >= stagesDocument.STAGE_CHECKED,
          },
          {
            type: "ValidatorTextField",
            path: "death.petitioner.residenceAddress.addressLocality",
            validators: textFieldDefault,
            isDisabled: ({ product }) => product.stage >= stagesDocument.STAGE_CHECKED,
          },
        ],
      },
      {
        label: "Daten zum Anliegen",
        elements: [
          {
            type: "ValidatorSelect",
            path: "death.generalCategory",
            isDisabled: ({ product }) => product.stage >= stagesDocument.STAGE_CHECKED,
            options: () => [{ value: "death" }, { value: "inheritance" }, { value: "both" }],
          },
          {
            type: "ValidatorSelect",
            path: "death.deathCategory",
            isDisabled: ({ product }) => product.stage >= stagesDocument.STAGE_CHECKED,
            options: () => [{ value: "current" }, { value: "preparation" }],
          },
          {
            type: "ValidatorSelect",
            path: "death.inheritanceCategory",
            isDisabled: ({ product }) => product.stage >= stagesDocument.STAGE_CHECKED,
            options: () => [{ value: "heir" }, { value: "bequeather" }],
          },
          {
            type: "ValidatorTextField",
            path: "death.personalTextMessage",
            isDisabled: ({ product }) => product.stage >= stagesDocument.STAGE_CHECKED,
            additionalProps: {
              multiline: true,
              rows: 12,
              fullWidth: true,
            },
          },
        ],
      },
    ],
  },
  additional: {
    sections: [
      {
        label: "",
        elements: [
          {
            type: "ValidatorTextField",
            path: "death.reference",
            isDisabled: () => true,
          },
        ],
      },
      {
        label: "Angaben zur Versicherung",
        elements: [
          {
            type: "InsurancePicker",
            path: "death.insurance.insurance",
            validators: insurancePickerValidator(product),
          },
          {
            type: "ValidatorTextField",
            path: "death.insurance.insurancePolicyNumber",
            validators: textFieldDefault,
          },
          {
            type: "ValidatorTextField",
            path: "death.insurance.caseReferenceNumber",
            validators: textFieldDefault,
          },
        ],
      },
    ],
  },
});
