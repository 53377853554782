import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    clipPath: "polygon(-20% 0%, 100% 0%, 100% 100%, -20% 100%)",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    zIndex: 1110,
    width: 140,
    right: -70,
    position: "fixed",
    height: "100vh",
    transition: "right 300ms ease-in-out",
    "& .actionButton a": {
      color: "rgba(0, 0, 0, 0.54)",
    },
  },
  enteringTransition: {
    right: 0,
  },
  enteredTransition: {
    right: 0,
  },
  exitingTransition: {
    right: -70,
  },
  exitedTransition: {
    right: -70,
  },
  actionsTopDistanceExtendedNavigationBar: {
    top: 130,
  },
  actionTitle: {
    fontSize: 10,
    textAlign: "center",
    marginTop: "-5px",
  },
  fab: {
    position: "fixed",
    right: 10,
    bottom: "4rem",
    width: 60,
    height: 60,
    zIndex: 3,
    backgroundColor: "#EFF2F9",
    borderRadius: "20%",
  },
  drawer: {
    backgroundColor: "#EFF2F9",
    paddingBottom: "0.5rem",
    "& .actionButton": {
      maxWidth: "33.33%",
      flexBasis: "33.33%",
    },
  },
  drawerTitle: {
    margin: "1.5rem 0 1rem 0",
    textAlign: "center",
    fontSize: "1.5rem",
  },
}));

export default useStyles;
