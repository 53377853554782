import personFields from "./personFields";
import { insurancePickerValidator } from "../../../validationRules";

export default function insuranceFields({ path, product, isHidden = () => false }) {
  return [
    {
      type: "InsurancePicker",
      path: `${path}.insurance`,
      isHidden: isHidden,
      validators: insurancePickerValidator(product),
    },
    {
      type: "ValidatorTextField",
      path: `${path}.caseReferenceNumber`,
      isHidden: isHidden,
    },
    {
      type: "ValidatorTextField",
      path: `${path}.insurancePolicyNumber`,
      isHidden: isHidden,
    },
    /*{
      type: "ValidatorSelect",
      path: `${path}.policyHolderType`,
      isDisabled: isDisabled,
      isHidden: isHidden,
      options: () => [
        {value: "me"},
        {value: "familyMember"},
      ],
    },*/
    ...personFields({
      path: `${path}.policyHolder`,
      isHidden: ({ product }) =>
        isHidden({ product }) || !product.insurance.policyHolder || product.insurance.policyHolderType === "me",
    }),
  ];
}
