import React, { useRef, useState } from "react";
import { Menu } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import * as PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import { ExpandMore } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import _ from "lodash";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../services/ReactQuery/reactQueryService";

const useStyles = makeStyles({
  container: {
    height: 50,
    width: 230,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "1rem 0",
  },
  avatar: {
    backgroundColor: "#a1ccca",
    marginRight: "1rem",
  },
  label: {
    fontSize: 10,
    fontWeight: 300,
  },
  text: {
    fontSize: 16,
    fontWeight: 500,
    width: 150,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
});

const AgentPicker = ({ agentList, currentAgent, product, type, label, refreshPage, disabled }) => {
  const classes = useStyles({});
  const myAnchor = useRef();
  const [selectedAgent, setSelectedAgent] = useState(currentAgent);
  const [menuOpen, setMenuOpen] = useState(false);
  const updateMutation = useMutation(updateResource);

  const pickAgent = async (event, agent) => {
    event.stopPropagation();
    setSelectedAgent(agent);
    await updateMutation.mutateAsync({id: product.backofficeCase.id, uri: "backoffice_cases", data: { [type]: agent["@id"] }});
    setMenuOpen(false);
    if (refreshPage) {
      await refreshPage();
    }
  };

  const handleOpenMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const { givenName, familyName } = selectedAgent ? selectedAgent.person : {};

  return (
    <div className={classes.container} onClick={!disabled ? handleOpenMenu : () => {}}>
      {selectedAgent ? (
        <>
          <Avatar src={selectedAgent.avatar} className={classes.avatar}>
            {givenName.charAt(0) + familyName.charAt(0)}
          </Avatar>
          <div>
            <div className={classes.label}>{label}</div>
            <div ref={myAnchor} />
            <div className={classes.text}>{givenName + " " + familyName}</div>
          </div>
        </>
      ) : (
        <>
          <Avatar className={classes.avatar}>!</Avatar>
          <div>
            <div className={classes.label}>{label}</div>
            <div ref={myAnchor} />
            <div className={classes.text}>Bitte auswählen</div>
          </div>
        </>
      )}
      {!disabled && <ExpandMore />}
      <Menu open={menuOpen} anchorEl={myAnchor.current} onClose={handleCloseMenu}>
        {_.map(agentList, (agent) => (
          <MenuItem
            onClick={(event) => {
              pickAgent(event, agent);
            }}
            key={agent.id}
            value={agent.id}
          >
            {agent.person.givenName + " " + agent.person.familyName}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

AgentPicker.propTypes = {
  label: PropTypes.string.isRequired,
  agentList: PropTypes.array.isRequired,
  currentAgent: PropTypes.object,
  product: PropTypes.object,
  refreshPage: PropTypes.func,
  type: PropTypes.oneOf(["lawyer", "accountManager"]),
  disabled: PropTypes.bool,
};

AgentPicker.defaultProps = {
  disabled: false,
};

export default AgentPicker;
