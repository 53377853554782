import React, { useContext } from "react";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../services/ReactQuery/reactQueryService";

interface IdentifierData {
  description: string;
  showInGallery?: boolean;
}

interface Identifiers {
  [identifier: string]: IdentifierData;
}

interface IdentifiersByProductClass {
  [productClass: string]: Identifiers;
}

interface FileIdentifierContextInterface {
  identifiers: IdentifiersByProductClass | null;
  getIdentifiersByProductClass: (a: string) => Identifiers | null;
  isLoading: boolean;
}

const FileIdentifierContext = React.createContext<FileIdentifierContextInterface | null>(null);

export default function FileIdentifierProvider({ children }: { children?: React.ReactNode }) {
  const {
    data: identifiers,
    isLoading,
    error,
  } = useQuery(queryKeys.all("documentLabels"), () => fetchCollection("lb/services/document_labels"), {
    retry: false,
  });

  const getIdentifiersByProductClass = (productClassName: string) => {
    if (!identifiers) {
      return null;
    }
    return _.merge(identifiers[productClassName], identifiers.common);
  };
  const data: FileIdentifierContextInterface = {
    identifiers,
    getIdentifiersByProductClass,
    isLoading,
  };
  return <FileIdentifierContext.Provider value={data}>{children}</FileIdentifierContext.Provider>;
}

export const useFileIdentifier = () => {
  const fileIdentifierContext = useContext(FileIdentifierContext);
  if (!fileIdentifierContext) {
    throw new Error("useFileIdentifier can only be used inside FileIdentifierProvider");
  }
  return fileIdentifierContext;
};
