import React, { createContext, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchResource, queryKeys } from "../services/ReactQuery/reactQueryService";

interface HolidayProviderData {
  holidays: Array<string>;
  isLoading: boolean;
}

const HolidaysProviderContext = createContext<HolidayProviderData | null>(null);

export default function HolidaysProvider({ children }: { children?: React.ReactNode; type?: string }) {
  const { data: holidays, isLoading } = useQuery(queryKeys.item("holidays", 1), () => fetchResource("holidays", 1));
  const data: HolidayProviderData = {
    holidays: holidays?.holidays || [],
    isLoading,
  };
  return <HolidaysProviderContext.Provider value={data}>{children}</HolidaysProviderContext.Provider>;
}

export const useHolidays = () => {
  const holidaysContext = useContext(HolidaysProviderContext);
  if (!holidaysContext) {
    throw new Error("useHolidays can only be used inside HolidaysProvider");
  }
  return holidaysContext;
};
