import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import { DeleteForeverOutlined } from "@mui/icons-material";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import { DropzoneArea } from "mui-file-dropzone";
import ButtonLoading from "../../Button/ButtonLoading";
import Typography from "@mui/material/Typography";
import * as PropTypes from "prop-types";

const useStyles = makeStyles({
  container: {
    padding: "1rem",
  },
  headline: {
    fontSize: "1rem",
    padding: "1.5rem",
    fontWeight: 700,
  },
  paper: {
    margin: "2rem 0",
  },
  file: {
    textAlign: "center",
  },
  icon: {
    color: "black",
  },
  fileName: {
    color: "#137f7b",
    fontSize: "1rem",
    "&:hover": {
      cursor: "pointer",
    },
  },
  deleteModalText: {
    textAlign: "center",
    marginTop: "1rem",
  },
  fileSectionContainer: {
    marginBottom: "2rem",
  },
  addFileButton: {
    paddingRight: "2rem",
  },
});

const FileSection = ({
  headline,
  setMediaObjectToDisplay,
  subText,
  deleteFile,
  uploadFile,
  currentMediaObjects,
  fileLimit,
  readOnly,
  acceptedFiles,
}) => {
  const classes = useStyles();
  const [filePickerOpen, setFilePickerOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(0);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseFilePicker = () => {
    setFilePickerOpen(false);
    setFiles([]);
  };

  const handleUploadFile = async () => {
    setIsLoading(true);
    await uploadFile(files);
    setFiles([]);
    setFilePickerOpen(false);
    setIsLoading(false);
  };

  const handleDeleteFile = async (id) => {
    setIsLoading(true);
    await deleteFile(id);
    setIsLoading(false);
    setFileToDelete(0);
  };

  return (
    <div className={classes.fileSectionContainer}>
      <Typography variant={"h3"}>{headline}</Typography>
      {subText && (
        <>
          <br />
          <Typography>{subText}</Typography>
        </>
      )}
      <Grid container className={classes.container}>
        {_.map(currentMediaObjects, (receipt, key) => {
          return (
            <Grid item xs={12} key={key}>
              <Grid container justifyContent={"flex-end"} alignItems={"center"} spacing={2}>
                <Grid item className={classes.file}>
                  <div onClick={() => setMediaObjectToDisplay(receipt)} className={classes.fileName}>
                    {receipt.originalName}
                  </div>
                </Grid>
                {!readOnly && (
                  <Grid item className={classes.fileAction}>
                    <IconButton
                      onClick={() => setFileToDelete(receipt.id)}
                      disabled={isLoading}
                      className={classes.icon}
                      size="large">
                      <DeleteForeverOutlined />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      {(!fileLimit || currentMediaObjects.length < fileLimit) && (
        <Grid container justifyContent={"flex-end"}>
          <Grid item className={classes.addFileButton}>
            <Button onClick={() => setFilePickerOpen(true)}>Datei hinzufügen</Button>
          </Grid>
        </Grid>
      )}
      <LegalbirdIoModal
        handleClose={handleCloseFilePicker}
        open={filePickerOpen}
        title={""}
        submitButton={
          <ButtonLoading
            disabled={files.length !== 1}
            variant={"contained"}
            onClick={handleUploadFile}
            isLoading={isLoading}
          >
            Datei am Fall speichern
          </ButtonLoading>
        }
      >
        <DropzoneArea
          onChange={(files) => setFiles(files)}
          filesLimit={1}
          dropzoneText={"Datei hierher ziehen oder Fläche anklicken"}
          showFileNames
          showAlerts={false}
          maxFileSize={300000000}
          useChipsForPreview
          acceptedFiles={acceptedFiles}
        />
      </LegalbirdIoModal>
      <LegalbirdIoModal
        handleClose={() => setFileToDelete(0)}
        open={fileToDelete > 0}
        title={"Datei löschen?"}
        submitButton={
          <ButtonLoading
            variant={"contained"}
            onClick={async () => {
              await handleDeleteFile(fileToDelete);
            }}
            isLoading={isLoading}
          >
            Datei löschen
          </ButtonLoading>
        }
      >
        <div className={classes.deleteModalText}>Möchten Sie diese Datei unwiderruflich löschen?</div>
      </LegalbirdIoModal>
    </div>
  );
};

FileSection.propTypes = {
  headline: PropTypes.string.isRequired,
  setMediaObjectToDisplay: PropTypes.func.isRequired,
  subText: PropTypes.string,
  deleteFile: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  currentMediaObjects: PropTypes.array.isRequired,
  fileLimit: PropTypes.number,
  readOnly: PropTypes.bool,
  acceptedFiles: PropTypes.array,
};

FileSection.defaultProps = {
  readOnly: false,
  acceptedFiles: [],
};

export default FileSection;
