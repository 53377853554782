import makeStyles from '@mui/styles/makeStyles';

//maybe combine all notification style files
const useStyles = makeStyles(() => ({
  notificationContainer: {
    padding: "1rem",
    border: "1px solid #f28d4f",
    borderRadius: 5,
    maxWidth: 400,
    fontSize: "1rem",
  },
  headline: {
    fontSize: "1.25rem",
    textAlign: "center",
  },
  icon: {
    color: "#000",
  },
}));

export default useStyles;
