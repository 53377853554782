import Contact from "../../Form/Contact";
import ExtendedContact from "../../Form/ExtendedContact";
import React from "react";
import PropTypes from "prop-types";
import ContentBox from "../../ContentBox/ContentBox";

const CaseContact = ({ product, headline }) => {
  return (
    <ContentBox headline={headline}>
      <Contact product={product} />
      <ExtendedContact product={product} />
    </ContentBox>
  );
};
CaseContact.propTypes = {
  product: PropTypes.object.isRequired,
  headline: PropTypes.string,
};
CaseContact.defaultProps = {
  headline: "Kontakt",
};
export default CaseContact;
