import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  bottomNavigationContainer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 3,
  },
  navigation: {
    backgroundColor: "#F1F4FB",
  },
  navigationActionLabel: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  navigationAction: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 70,
      maxWidth: 70,
    },
  },
}));

export default useStyles;
