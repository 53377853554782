import React from "react";
import { Grid } from "@mui/material";
import FormElement from "../Case/CaseForm/FormElement";

interface EntityFieldsProps {
  formElements: {[x: string]: any};
  useFormProps: {
    values: any;
    [x: string]: any;
  };
  product: any;
  disabled?: boolean;
  fullWidth?: boolean;
  initialValues?: {[x: string]: any} | null;
  validationCallback?: (e?: any) => void;
}

export default function EntityFields({
  formElements,
  useFormProps,
  product,
  disabled = false,
  initialValues = null,
  fullWidth = false,
  validationCallback = () => {}
}: EntityFieldsProps) {
  let useFormPropsInternal = { ...useFormProps, handleBlur: (e: any) => {validationCallback(e); useFormProps.handleBlur(e);} };
  if (disabled === true) {
    useFormPropsInternal.values = initialValues;
  }
  return (
    <>
      {formElements.map((formElementProps: any) => {
        if (formElementProps.isHidden && formElementProps.isHidden({ values: useFormPropsInternal.values, product })) {
          return null;
        }
        let formElementPropsInternal = { ...formElementProps };
        if (disabled === true) {
          formElementPropsInternal.isDisabled = () => true;
        }
        const fullWidthOverride = fullWidth || !!formElementPropsInternal.additionalProps?.fullWidth;
        return (
          <Grid
            key={formElementPropsInternal.path}
            item
            xs={12}
            md={!fullWidthOverride && 6}
            lg={!fullWidthOverride && 4}
          >
            <FormElement product={product} {...formElementPropsInternal} {...useFormPropsInternal} />
          </Grid>
        );
      })}
    </>
  );
};