import { EditOutlined } from "@mui/icons-material";
import ActivityNote from "../../Activities/ActivityNote";
import React from "react";
import { IconButton } from "@mui/material";
import * as PropTypes from "prop-types";
import PaidTaskCaseHead from "./PaidTaskCaseHead";
import { Link } from "react-router-dom";
import { getCaseLinkByBackofficeCase } from "../../../services/Product/ProductService";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";

const PaidTaskCase = ({ paidTask }) => {
  const currentUser = useCurrentUser();

  return (
    <>
      <PaidTaskCaseHead
        paidTask={paidTask}
        actions={
          <>
            <IconButton
              component={Link}
              to={
                getCaseLinkByBackofficeCase(paidTask.backofficeCase, currentUser.isExternal ? "/extern" : "") +
                "/ausschreibung/" +
                paidTask.id
              }
              size="large"
            >
              <EditOutlined />
            </IconButton>
          </>
        }
      />
      <ActivityNote noteContent={paidTask.description} />
    </>
  );
};

PaidTaskCase.propTypes = {
  paidTask: PropTypes.object,
  refreshFunction: PropTypes.func,
};

PaidTaskCase.defaultProps = {
  refreshFunction: () => {},
};

export default PaidTaskCase;
