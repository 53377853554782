import React from "react";
import { Box } from "@mui/material";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import layoutStyle from "../layoutStyle";
import { userHasRole } from "../../../services/backofficeUserService";
import { Roles } from "../../../types/BackofficeUser";
import { useTechStatus } from "../../../provider/TechStatusProvider";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";

export default function BlockPage() {
  const currentUser = useCurrentUser();
  const techStatus = useTechStatus();

  if (userHasRole(currentUser, Roles.admin) || !techStatus?.release) {
    return null;
  }

  return (
    <Box sx={layoutStyle.blockPage}>
      <LegalbirdLoader />
    </Box>
  );
}
