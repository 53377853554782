const courtAppointmentTranslations = {
  courtDate: {
    appointmentDate: {
      label: "Gerichtstermin Datum",
    },
    appointmentTime: {
      label: "Gerichtstermin Uhrzeit",
    },
  },
  courtDateType: {
    label: "Art des Termins",
    values: {
      hearing: "Mündliche Verhandlung",
      hearingOfEvidence: "Mündliche Verhandlung mit Beweisaufnahme",
      settlement: "Gütetermin",
      trialLabourLaw: "Kammertermin",
      trialTrafficLaw: "Gerichtstermin",
      divorce: "Scheidungstermin",
    }
  }
};

export default courtAppointmentTranslations;
