const courtHitStyles = {
  highlighted: {
    fontWeight: 700,
    fontSize: "1rem",
    marginBottom: "0.25rem",
  },
  hitContainer: {
    padding: "1rem",
    //fixed height for react window
    height: 200,
  },
  entry: {
    lineHeight: "1.43",
    fontSize: "1rem",
  },
};

export default courtHitStyles;
