const adminDeleteModalStyle = {
  warning: {
    color: "red",
    border: "1px solid red",
    borderRadius: 5,
    padding: "1rem",
    textAlign: "center",
  },
};
export default adminDeleteModalStyle;
