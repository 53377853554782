import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import * as PropTypes from "prop-types";
import _ from "lodash";
import Badge from "./Badge";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles(() => ({
  container: {
    "& > div": {
      margin: "5px",
    },
    display: "flex",
  },
}));

const BadgesList = (props) => {
  const { badges } = props;
  const classes = useStyles(props);
  return (
    <Grid container className={classes.container}>
      {_.map(badges, (badge, index) => (
        <Grid item key={index}>
          <Badge isActive={badge.isActive} text={badge.text} linkTo={badge.linkTo} />
        </Grid>
      ))}
    </Grid>
  );
};

BadgesList.propTypes = {
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      isActive: PropTypes.bool,
      linkTo: PropTypes.string,
    })
  ),
};

export default BadgesList;
