export const legalbirdIoModalStyle = {
  dividerContainer: {
    marginBottom: "1rem",
  },
  divider: {
    backgroundColor: "#cdcdcd",
  },
  dialogContent: {
    minWidth: { xs: "none", md: "600px" },
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  flexHeadline: {
    flexGrow: 1,
    textAlign: "center",
  },
  fixedSizeFlexItem: {
    width: "48px",
  },
  stack: {
    margin: "0 auto",
    minWidth: "200px",
  },
};
