import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  pageTitle: {
    textAlign: "center",
    marginBottom: "1rem",
  },
  paper: {
    marginTop: ".5rem",
    padding: "1rem",
  },
  templateType: {
    flexGrow: "1",
  },
}));

export default useStyles;
