const paidTaskListItemStyle = {
  smallFont: {
    fontSize: 10,
  },
  singleLineOverflowHidden: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  paidTaskSubject: {
    fontWeight: 500,
  },
  paidTaskButton: {
    fontSize: 10,
    padding: 3,
  },
  divider: {
    margin: "1rem 0",
  },
};

export default paidTaskListItemStyle;
