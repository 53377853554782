import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import ActivityForm from "./ActivityForm";
import { getProductNameBy } from "../../../services/Product/ProductService";
import { editorStateToHTML, htmlToEditorState } from "../../../services/Editor/editorFunctions";
import ReactGA from "react-ga4";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../services/ReactQuery/reactQueryService";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";

const EditActivity = ({ open, closeDialog, activity, updateActivities, productClassName }) => {
  const updateMutation = useMutation(updateResource);
  const [initialValues, setInitialValues] = useState();
  const [activityFormKey, setActivityFormKey] = useState(0);
  const { getUserData } = useBackofficeUser();

  useEffect(() => {
    const formValues = ["type", "subject", "dueDate", "dueTime", "mandatory", "assignedUser", "note", "duration"];
    let activityValues = _.merge({}, _.pick(activity, formValues));
    if (_.isObjectLike(activity.assignedUser) && activity.assignedUser) {
      activityValues.assignedUser = activity.assignedUser["@id"];
    }
    if (activityValues.dueDate && moment(activityValues.dueDate).isValid()) {
      activityValues.dueDate = moment(activityValues.dueDate).format("YYYY-MM-DD");
    }
    if (activityValues.dueTime === "allDay") {
      activityValues.dueTime = "";
    }
    activityValues.note = htmlToEditorState(activityValues.note);
    setInitialValues(activityValues);
  }, [activity]);

  useEffect(() => {
      if (initialValues) {
        setActivityFormKey((prevState) => prevState + 1);
      }
  },[initialValues]);

  const onSubmit = async ({ values }) => {
    let updateData = _.merge({}, values);
    updateData.dueTime = updateData.dueTime !== "" ? updateData.dueTime : "allDay";
    updateData.note = editorStateToHTML(updateData.note, true);
    await updateMutation.mutateAsync({ id: activity.id || 0, uri: "activities", data: updateData });
    await updateActivities();
    setInitialValues(values);

    // This is for Andi's temporary analysis and can be removed in the middle of 2023. Please ask Andi first!
    if (moment(activity.dueDate) !== updateData.dueDate && moment(activity.dueDate) < updateData.dueDate) {
      ReactGA.set({ userId: activity.assignedUser.id });
      ReactGA.event({
        category: "Activity",
        action: "Postponed",
        label: getUserData(activity.assignedUser).person.givenName + " " + getUserData(activity.assignedUser).person.familyName,
      });
    }
    // End Andi

    closeDialog();
  };

  if (!initialValues) {
    return null;
  }

  return (
    <ActivityForm
      key={"activityForm" + activityFormKey}
      open={open}
      initialValues={initialValues}
      onSubmit={onSubmit}
      closeDialog={closeDialog}
      productClassName={productClassName || getProductNameBy("lbProductClass", activity.case.lbProductClass)}
      activity={activity}
      title={"Aktivität bearbeiten"}
    />
  );
};

EditActivity.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  activity: PropTypes.object.isRequired,
  updateActivities: PropTypes.func,
  productClassName: PropTypes.string,
};

export default EditActivity;
