const layoutStyle = {
  blockPage: {
    textAlign: "center",
    paddingTop: 200,
    width: "100%",
    height: "100%",
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 2000,
    backgroundColor: "rgba(125,129,125,0.4)",
  },
  mobileContentContainer: {
    margin: "2rem 1rem 5rem 1rem",
  }
};
export default layoutStyle;