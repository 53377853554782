const paidTasksCaseStyle = (theme) => ({
  container: {
    padding: "2rem",
    marginTop: "1rem",
    width: "100%",
  },
  headline: {
    textAlign: "center",
    fontSize: "1.25rem",
    marginBottom: "1rem",
  },
  activityContainer: {
    marginBottom: "2rem",
  },
  faded: {
    textAlign: "center",
    color: "#dcdcdc",
    fontSize: "1.25rem",
    margin: "2rem",
  },
  horizontalLine: {
    borderBottom: "1px solid #999999",
    marginBottom: "2rem",
  },
  innerHeadline: {
    fontSize: "1rem",
    marginRight: "1rem",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  overdue: {
    color: "#ca0d0d",
  },
  done: {
    textDecoration: "line-through",
  },
  imageGridItem: {
    width: 72,
  },
  activityContent: {
    width: "calc(100% - 72px)",
  },
  activityContentContainer: {
    height: "100%",
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap",
    },
  },
  activityInfo: {
    flexGrow: 1,
    marginLeft: ".5rem",
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 0,
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      whiteSpace: "nowrap",
    },
  },
  activityActions: {
    minWidth: 100,
    textAlign: "right",
  },
});

export default paidTasksCaseStyle;
