import React, { useMemo } from "react";
import _ from "lodash";
import {
  getDeadlinesActivitiesSet,
  getFullActivitiesSet,
  transformActivitySetToAutoCompleteOptions
} from "../../../../services/Activities/activityTypes";
import LegalbirdAutoComplete from "../../../AutoComplete/LegalbirdAutoComplete";

const ActivityTypeFilter = ({ activityTypeFilter, setActivityTypeFilter, isDeadlineView }) => {

  const activityTypes = useMemo(() => {
    return transformActivitySetToAutoCompleteOptions(isDeadlineView ? getDeadlinesActivitiesSet() : getFullActivitiesSet());
  }, []);

  const activityTypeAutoCompleteOptions = [isDeadlineView ? {
    label: "Alle Fristen",
    name: "allDeadlines"
  } : { label: "Alle Aktivitäten", name: "all" }, ...activityTypes];

  const activityTypeAutoCompleteValue = _.find(activityTypeAutoCompleteOptions, (activityType) => activityType.name === activityTypeFilter);

  const handleActivityTypeValueChange = (e, value) => {
    setActivityTypeFilter(value.name);
  };

  return (
    <LegalbirdAutoComplete
      setValue={handleActivityTypeValueChange}
      value={activityTypeAutoCompleteValue}
      options={activityTypeAutoCompleteOptions}
      label={"Aktivitätstyp"}
    />
  );
};

export default ActivityTypeFilter;
