import React, { useState } from "react";
import * as PropTypes from "prop-types";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { FormControl, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import ButtonLoading from "../Button/ButtonLoading";
import ApiClient from "../../services/ApiClient";
import moment from "moment";

export default function BeaAcknowledgementModal({ beaMessage, open, handleClose, refreshBeaMessages }) {
  const [isLoading, setIsLoading] = useState(false);
  const [submit, setSubmit] = useState(true);
  const [explanation, setExplanation] = useState("");
  const [rejectionReason, setRejectionReason] = useState("");

  const handleSubmit = async () => {
    setIsLoading(true);

    let beaMessagePayload = {};
    let acknowledgementOfReceiptPayload = {};

    switch (submit) {
      case true:
        acknowledgementOfReceiptPayload = {
          submitOrRejectDateTime: moment().format("c")
        };
        break;
      case false:
        acknowledgementOfReceiptPayload = {
          rejectionReason: rejectionReason,
          explanation: explanation,
          submitOrRejectDateTime: moment().format("c")
        };
        break;
    }

    await ApiClient.put(beaMessage.acknowledgementOfReceipt["@id"], {
      body: JSON.stringify(acknowledgementOfReceiptPayload)
    });

    refreshBeaMessages();
    setIsLoading(false);
    handleClose();
  };

  return (
    <LegalbirdIoModal
      open={open}
      handleClose={handleClose}
      title={"beA-Empfangsbekenntnis"}
      submitButton={
        <ButtonLoading
          isLoading={isLoading}
          onClick={handleSubmit}
          variant={"contained"}
          disabled={!submit && (explanation === "" || rejectionReason === "")}
        >
          Auswahl bestätigen
        </ButtonLoading>
      }
    >
      <Stack direction="row" spacing={1} alignItems="center" justifyContent={"center"}>
        <Typography>eEB abgeben</Typography>
        <Switch value={submit} onClick={() => setSubmit(!submit)} />
        <Typography>eEB ablehnen</Typography>
      </Stack>
      {!submit && (
        <>
          <FormControl fullWidth>
            <InputLabel id="rejection-reason">Ablehnungsgrund</InputLabel>
            <Select
              label="Ablehnungsgrund"
              labelId="rejection-reason"
              name={"rejectionReason"}
              value={rejectionReason}
              onChange={(event) => setRejectionReason(event.target.value)}
            >
              <MenuItem value={"wrongRecipient"}>Zustellungsempfänger nicht am Verfahren beteiligt</MenuItem>
              <MenuItem value={"contentUnclear"}>Inhalt der Sendung unklar oder unvollständig</MenuItem>
              <MenuItem value={"certificateCheckFailed"}>Zertifikatspruefung fehlgeschlagen</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label={"Erläuterung"}
            value={explanation}
            onChange={(event) => setExplanation(event.target.value)}
          />
        </>
      )}
    </LegalbirdIoModal>
  );
}

BeaAcknowledgementModal.propTypes = {
  beaMessage: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refreshBeaMessages: PropTypes.func.isRequired
};
