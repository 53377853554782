const allDataFormTranslations = {
  product: "Angaben zum Fall",
  additional: "Angaben zum Verfahren",
  authority: "Behörde",
  court: "Gericht",
  prosecution: "Staatsanwaltschaft",
  processParticipants: "Prozessbeteiligte",
  additionalProcessParticipants: "Weitere Prozessbeteiligte",
};

export default allDataFormTranslations;
