import React, { useState } from "react";
import * as PropTypes from "prop-types";
import ButtonLoading from "../Button/ButtonLoading";
import ValidatorSelect from "../Validator/ValidatorSelect";
import MenuItem from "@mui/material/MenuItem";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ApiClient from "../../services/ApiClient";


export default function ChangeMediaObjectFolderModal({
  setSelectSubfolderOpen,
  selectSubfolderOpen,
  mediaObject,
  updateMediaObjects,
  title,
  buttonText,
}) {
  const [subFolder, setSubFolder] = useState(mediaObject.electronicFileFolderPath || "ToDo");
  const [subFolderLoading, setSubFolderLoading] = useState(false);

  const handleElectronicfileFolderSave = () => {
    setSubFolderLoading(true);
    ApiClient.put("media_objects/" + mediaObject.id, {
      body: JSON.stringify({
        electronicFileFolderPath: subFolder,
        showInElectronicFile: true,
      }),
    }).then(() => {
      updateMediaObjects();
      setSubFolderLoading(false);
      setSelectSubfolderOpen(false);
    });
  };

  return (
    <LegalbirdIoModal
      handleClose={() => setSelectSubfolderOpen(false)}
      open={selectSubfolderOpen}
      title={title}
      submitButton={
        <ButtonLoading variant={"contained"} onClick={handleElectronicfileFolderSave} isLoading={subFolderLoading}>
          {buttonText}
        </ButtonLoading>
      }
    >
      <ValidatorSelect
        label={"Ordner auswählen"}
        name={"subFolder"}
        value={subFolder}
        onChange={({ target }) => setSubFolder(target.value)}
      >
        <MenuItem value={"Mandant"}>Mandant</MenuItem>
        <MenuItem value={"Gerichtliches Verfahren"}>Gerichtliches Verfahren</MenuItem>
        <MenuItem value={"Außergerichtliches Verfahren"}>Außergerichtliches Verfahren</MenuItem>
        <MenuItem value={"RSV"}>RSV</MenuItem>
        <MenuItem value={"ToDo"}>{"ToDo"}</MenuItem>
        <MenuItem value={"Rechnungen"}>Rechnungen</MenuItem>
        <MenuItem value={"Sonstiges"}>Sonstiges</MenuItem>
      </ValidatorSelect>
    </LegalbirdIoModal>
  );
}

ChangeMediaObjectFolderModal.propTypes = {
  setSelectSubfolderOpen: PropTypes.func.isRequired,
  selectSubfolderOpen: PropTypes.bool.isRequired,
  mediaObject: PropTypes.object.isRequired,
  updateMediaObjects: PropTypes.func.isRequired,
  title: PropTypes.string,
  buttonText: PropTypes.string,
};

ChangeMediaObjectFolderModal.defaultProps = {
  title: "Datei in anderen Ordner verschieben",
  buttonText: "Datei ablegen",
};
