const contactTranslations = {
  email: {
    label: "E-Mail-Adresse",
  },
  telephone: {
    label: "Telefonnummer",
  },
  fax: {
    label: "Faxnummer",
  },
  // faxNumber translation exists for old history logs only
  faxNumber: {
    label: "Faxnummer",
  },
};

export default contactTranslations;
