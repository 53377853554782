const LEAD_STATUS_INITIAL = 10;
const LEAD_STATUS_CHECKING = 20;
const LEAD_STATUS_CHECKED = 30;

const APPLICATION_STATUS_INTRO = 10;
const APPLICATION_STATUS_LEGAL_EXPENSES_INSURANCE = 20;
const APPLICATION_STATUS_PERSONAL = 30;
const APPLICATION_STATUS_JOB = 40;
const APPLICATION_STATUS_REPRESENTATIVE = 50;
const APPLICATION_STATUS_MAINTENANCE_CLAIM = 60;
const APPLICATION_STATUS_ALIMONY = 70;
const APPLICATION_STATUS_SOCIAL_WELFARE = 80;
const APPLICATION_STATUS_INCOME_EMPLOYMENT = 90;
const APPLICATION_STATUS_INCOME_SELFEMPLOYMENT = 100;
const APPLICATION_STATUS_INCOME_RENT = 110;
const APPLICATION_STATUS_INCOME_ASSETS = 120;
const APPLICATION_STATUS_INCOME_CHILD_ALLOWANCE = 130;
const APPLICATION_STATUS_INCOME_LIVING = 140;
const APPLICATION_STATUS_INCOME_ALIMONY = 150;
const APPLICATION_STATUS_INCOME_PENSION = 160;
const APPLICATION_STATUS_INCOME_UNEMPLOYED_ALLOWANCE_1 = 170;
const APPLICATION_STATUS_INCOME_UNEMPLOYED_ALLOWANCE_2 = 180;
const APPLICATION_STATUS_INCOME_SICKNESS = 190;
const APPLICATION_STATUS_INCOME_PARENTAL_ALLOWANCE = 200;
const APPLICATION_STATUS_INCOME_OTHER = 210;
const APPLICATION_STATUS_INCOME_DESCRIPTIVE = 220;
const APPLICATION_STATUS_BANK_ACCOUNT = 230;
const APPLICATION_STATUS_REALESTATE = 240;
const APPLICATION_STATUS_VEHICLES = 250;
const APPLICATION_STATUS_CASH = 260;
const APPLICATION_STATUS_PENSION_INSURANCE = 270;
const APPLICATION_STATUS_OTHER_ASSETS = 280;
const APPLICATION_STATUS_TAX = 290;
const APPLICATION_STATUS_SOCIAL_SECURITY = 300;
const APPLICATION_STATUS_INSURANCES = 310;
const APPLICATION_STATUS_MOBILITY = 320;
const APPLICATION_STATUS_OTHER_EXPENSES = 330;
const APPLICATION_STATUS_LIVING_TYPE = 340;
const APPLICATION_STATUS_PAYMENT_OBLIGATIONS = 350;
const APPLICATION_STATUS_SPECIAL_BURDENS = 360;
const APPLICATION_STATUS_SIGNATURE = 400;
const APPLICATION_STATUS_SUMMARY = 450;
const APPLICATION_STATUS_RECEIPTS = 500;
const APPLICATION_STATUS_CHECKING = 510;
const APPLICATION_STATUS_CHECKED = 1000;

export {
  LEAD_STATUS_INITIAL,
  LEAD_STATUS_CHECKING,
  LEAD_STATUS_CHECKED,
  APPLICATION_STATUS_INTRO,
  APPLICATION_STATUS_LEGAL_EXPENSES_INSURANCE,
  APPLICATION_STATUS_PERSONAL,
  APPLICATION_STATUS_JOB,
  APPLICATION_STATUS_REPRESENTATIVE,
  APPLICATION_STATUS_MAINTENANCE_CLAIM,
  APPLICATION_STATUS_ALIMONY,
  APPLICATION_STATUS_SOCIAL_SECURITY,
  APPLICATION_STATUS_INCOME_ALIMONY,
  APPLICATION_STATUS_INCOME_ASSETS,
  APPLICATION_STATUS_INCOME_CHILD_ALLOWANCE,
  APPLICATION_STATUS_INCOME_DESCRIPTIVE,
  APPLICATION_STATUS_INCOME_EMPLOYMENT,
  APPLICATION_STATUS_INCOME_LIVING,
  APPLICATION_STATUS_INCOME_OTHER,
  APPLICATION_STATUS_INCOME_PARENTAL_ALLOWANCE,
  APPLICATION_STATUS_INCOME_PENSION,
  APPLICATION_STATUS_INCOME_RENT,
  APPLICATION_STATUS_INCOME_SELFEMPLOYMENT,
  APPLICATION_STATUS_INCOME_SICKNESS,
  APPLICATION_STATUS_INCOME_UNEMPLOYED_ALLOWANCE_1,
  APPLICATION_STATUS_INCOME_UNEMPLOYED_ALLOWANCE_2,
  APPLICATION_STATUS_BANK_ACCOUNT,
  APPLICATION_STATUS_REALESTATE,
  APPLICATION_STATUS_VEHICLES,
  APPLICATION_STATUS_CASH,
  APPLICATION_STATUS_PENSION_INSURANCE,
  APPLICATION_STATUS_OTHER_ASSETS,
  APPLICATION_STATUS_TAX,
  APPLICATION_STATUS_SOCIAL_WELFARE,
  APPLICATION_STATUS_INSURANCES,
  APPLICATION_STATUS_MOBILITY,
  APPLICATION_STATUS_OTHER_EXPENSES,
  APPLICATION_STATUS_LIVING_TYPE,
  APPLICATION_STATUS_PAYMENT_OBLIGATIONS,
  APPLICATION_STATUS_SPECIAL_BURDENS,
  APPLICATION_STATUS_SIGNATURE,
  APPLICATION_STATUS_SUMMARY,
  APPLICATION_STATUS_RECEIPTS,
  APPLICATION_STATUS_CHECKING,
  APPLICATION_STATUS_CHECKED,
};
