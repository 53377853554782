import moment from "moment";
import {
  PAID_TASK_STATUS_APPROVED,
  PAID_TASK_STATUS_ASSIGNED,
  PAID_TASK_STATUS_CREATED,
  PAID_TASK_STATUS_IN_REVIEW,
  PAID_TASK_STATUS_REQUESTED,
} from "../components/PaidTask/paidTaskStatus";
import { STAGE_ACTION_REQUIRED, STAGE_CHECKED, STAGE_LAWSUIT, STAGE_PREPARE_LAWSUIT } from "./Stages/StagesCase";
import {
  dateFieldDefault,
  maxNumber,
  minNumber,
  moneyFieldDefault,
  numberFieldDefault,
  requiredFieldDefault,
} from "./validationRules";
import { EditorState } from "draft-js";
import _ from "lodash";
import { htmlToEditorState } from "./Editor/editorFunctions";
import InputAdornment from "@mui/material/InputAdornment";
import React from "react";
import { userHasOneOfTheseRoles, userHasRole } from "./backofficeUserService";
import { Roles } from "../types/BackofficeUser";

const contractLawTemplates = [
  {
    type: "letter",
    label: "Außergerichtliches Schreiben",
    subject: "Außergerichtliches Schreiben",
    description:
      "Im vorliegenden Fall soll ein außergerichtliches Schreiben an die Gegenseite verfasst werden," +
      " das die Zielsetzung des Mandanten so gut wie möglich erfüllt. Als Basis dienen die Detailangaben unter" +
      " “Alle Details anzeigen”, die individuelle Beschreibung des Mandanten und die hochgeladenen Dokumente. " +
      "Sollten für das Schreiben notwendige Angaben fehlen, sind diese beim Mandanten einzuholen.",
    definitionOfDone:
      "- Text Bevollmächtigung: In vorbezeichneter Angelegenheit zeigen wir an, dass uns Frau Maria Mustermann, " +
      "wohnhaft Musterstraße. 1, 55555 Musterhausen, zur Wahrnehmung und Vertretung ihrer rechtlichen Interessen " +
      "beauftragt hat. Eine auf uns lautende Vollmacht haben wir diesem Schreiben beigefügt.\n" +
      "\n" +
      "- Erstellen des Schriftsatzes über den Editor und Speichern des Entwurfs\n" +
      "\n" +
      "- Korrekte Angaben zur Gegenseite als Empfänger im Briefkopf\n" +
      "\n" +
      "- Anliegen/Standpunkt des Mandanten erfasst und verschriftlicht\n" +
      "\n" +
      "- Frist zur Stellungnahme/Erfüllung gesetzt und richtig im Feld “Frist für Gegenseite” gespeichert\n" +
      "\n" +
      "- Besonderheiten oder Informationen zu Rücksprachen mit dem Mandanten sind per Notizfunktion am Fall vermerkt",
    reviewRemarks: "",
    assignmentDeadline: { amount: 5, unit: "days" },
    doneDeadline: { amount: 8, unit: "days" },
    maxFee: "50",
    fee: "25",
    isHidden: ({ product }) => product.stage > STAGE_ACTION_REQUIRED || product.stage < STAGE_CHECKED,
    hasEditor: true,
    fields: [
      {
        type: "ValidatorDateField",
        path: `extrajudicialCommunicationDeadline`,
        validators: dateFieldDefault,
        headline: "Frist für Gegenseite?",
        description:
          "Wenn Sie der Gegenseite eine Frist gesetzt haben, dann tragen Sie diese bitte ein. Andernfalls lassen Sie das Feld einfach leer.",
      },
    ],
  },
  {
    type: "statementOfClaim",
    label: "Klageschrift",
    subject: "Klageschrift verfassen",
    description:
      "Im vorliegenden Fall soll eine Klageschrift an das zuständige Gericht erstellt werden." +
      " Das Klageziel ergibt sich aus dieser Aufgabe, dem außergerichtlichen Schreiben (s. Dokumente) sowie ggf. der Notiz am Fall." +
      " Bei etwaigen Fragen zur Sache selber kann der Mandant angerufen werden. Der Anruf ist per Notiz zu dokumentieren.",
    definitionOfDone:
      "Gerichtsstand ermitteln:\n" +
      " - Der Gerichtsstand ist anhand der Falldaten zu bestimmen und unten im Feld “PLZ Gerichtsstand” einzutragen\n" +
      "\n" +
      "Vorlage:\n" +
      " - Bitte diese Word-Vorlage verwenden: https://drive.google.com/drive/u/1/folders/176VCWhO4XbyHn5qr40bszZZoCTTHgPlk\n" +
      " - Bitte die jeweilige Vorlage des bearbeitenden internen RA verwenden, also entweder mit Endung AW (RAin Anna Wagner), " +
      "FR (RA Frank Riedel), MB (RA Michael Bick), LK (RAin Lara von Kolontaj), KB (RAin Kristina Beyer), " +
      " FK (RAin Friederike Karg), KP (RAin Katharina Pitzen) oder HA (RAin Hasret Alp).\n" +
      "\n" +
      "Folgende Anträge sind zu stellen:\n" +
      " - Hauptsacheantrag inkl. Zinsantrag ab Verzug (ein Tag nach im Aufforderungsschreiben gesetzter Frist, wenn nicht" +
      " ab Rechtshängigkeit): Nacherfüllung\n" +
      " - Anträge 2 - 5 sind aus Vorlage zu übernehmen; Antrag zu 3. muss bei Rücktrittsantrag gestellt werden. " +
      "Ebenso Anregungen über Verfahren (495a ZPO, früher erster Termin)\n" +
      "\n" +
      "Formelle Anforderungen:\n" +
      " - Erstellen des Schriftsatzes als PDF Datei\n" +
      " - Das PDF soll bei Ihnen lokal erzeugt und dann unten hochgeladen werden.\n" +
      " - Anlagen sollen jeweils einzeln erstellt und durchnummeriert werden (K1, K2, etc.). „Anlage K1“ etc. muss oben " +
      "rechts auf Dokument stehen.\n" +
      " - Das Klage-pdf und alle Anlagen als weitere Einzel pdfs sind unten unter “Bearbeitung” hochzuladen\n" +
      " - Besonderheiten oder Informationen zu Rücksprachen mit dem Mandanten sind per Notizfunktion am Fall vermerkt.\n" +
      " - Sachfragen bitte an Mandant direkt stellen; juristische bzw. Verfahrensfragen gerne an jeweiligen im Fall " +
      "hinterlegten RA. Verbesserungsvorschläge sind jederzeit gerne willkommen!",
    reviewRemarks: "",
    assignmentDeadline: { amount: 21, unit: "days" },
    doneDeadline: { amount: 28, unit: "days" },
    maxFee: "100",
    fee: "60",
    isHidden: ({ product }) => !product || product.stage < STAGE_PREPARE_LAWSUIT || product.stage < STAGE_CHECKED,
    hasEditor: false,
    mainDocumentConfig: {
      headline: "Klageschrift",
      description: "Bitte laden Sie hier die Klageschrift als PDF-Datei hoch",
      fileName: "{{YYYYMMDD}}_Klage_{{clientFamilyName}}_aG.pdf",
      fileDescription: "Klage vom {{DD.MM.YYYY}}",
    },
    iterableAttachmentsConfig: {
      identifier: "K{{iterator}}",
      headline: "Anlage",
      description:
        "Bitte laden Sie hier Anlage K{{iterator}} hoch. Achten Sie darauf, dass in dem Dokument am oberen Bildrand “K{{iterator}}”vermerkt ist.",
      fileName: "{{YYYYMMDD}}_Klage_AnlageK{{iterator}}_{{clientFamilyName}}.pdf",
      fileDescription: "Anlage K{{iterator}} Klage vom {{DD.MM.YYYY}}",
    },
    fields: [
      {
        type: "ValidatorTextField",
        path: `courtPostalCode`,
        validators: requiredFieldDefault,
        headline: "Postleitzahl des Gerichtsstands",
        description:
          "Bitte tragen Sie hier die Postleitzahl ein, die Sie für den Gerichtsstand ermittelt haben. Wichtig: Bitte NICHT die Postleitzahl des Gerichtes eintragen, sondern die des Gerichtsstands.",
      },
      {
        type: "ValidatorTextField",
        path: `valueInDispute`,
        validators: [...requiredFieldDefault, ...moneyFieldDefault],
        headline: "Vorläufiger Streitwert",
        description:
          "Bitte tragen Sie hier den vorläufigen Streitwert ein, den Sie in der Klageschrift vermerkt haben.",
        additionalProps: {
          InputProps: {
            endAdornment: <InputAdornment position="start">€</InputAdornment>,
          },
        },
      },
    ],
  },
  {
    type: "statementOfDefense",
    label: "Klageerwiderung des Beklagten",
    subject: "Klageerwiderung des Beklagten",
    description:
      "Im vorliegenden Fall soll eine Klageerwiderung erstellt werden. Weiteres ist dem Fall und den dortigen Dateien zu entnehmen.",
    definitionOfDone:
      "- Verarbeiten der Stellungnahme des Mandanten zum Schriftsatz der Gegenseite (wenn vorhanden)\n" +
      "- Wo nötig Beweis antreten bzw. Bestreiten/mit Nichtwissen bestreiten\n" +
      "- Als Abschluss des Schriftsatzes bitte folgende beiden Absätze verwenden: Soweit das Gericht entgegen der " +
      "diesseitigen Auffassung den Vortrag als unzureichend, unschlüssig oder unsubstantiiert ansieht, um den geltend " +
      "gemachten Anspruch als schlüssig zu qualifizieren, oder wenn sonst Bedenken gegen die gefassten Anträge, die " +
      "Schlüssigkeit und die Substantiierung des Vortrages und die hiesige Sichtweise in der Beweislast bestehen, wird " +
      "um einen entsprechenden Hinweis nach § 139 ZPO bzw. eine prozessleitende Verfügung nach § 273 Abs. 2 Nr. 1 ZPO gebeten.\n\n" +
      "Der Unterzeichner weist allein aus Gründen der Vorsorge gegenüber seinem Mandanten darauf hin, dass die " +
      "gerichtliche Hinweis- und Aufklärungspflicht nach der neueren Rechtsprechung auch gegenüber der anwaltlich " +
      "vertretenden Partei uneingeschränkt besteht (BGH NJW 2001, 2548; OLG Köln NJW-RR 2001, 1724).",
    reviewRemarks: "",
    assignmentDeadline: { amount: 7, unit: "days" },
    doneDeadline: { amount: 14, unit: "days" },
    maxFee: "75",
    fee: "50",
    isHidden: ({ product }) => !product || product.stage < STAGE_LAWSUIT || product.stage < STAGE_CHECKED,
    hasEditor: false,
    mainDocumentConfig: {
      headline: "Klageerwiderung des Beklagten",
      description: "Bitte laden Sie hier die Klageerwiderung als PDF-Datei hoch",
      fileName: "{{YYYYMMDD}}_KE_Klageerwiderung_{{clientFamilyName}}_aG.pdf",
      fileDescription: "Klageerwiderung vom {{DD.MM.YYYY}}",
    },
    iterableAttachmentsConfig: {
      identifier: "B{{iterator}}",
      headline: "Anlage",
      description:
        "Bitte laden Sie hier Beweis B{{iterator}} hoch. Achten Sie darauf, dass in dem Dokument am oberen Bildrand “B{{iterator}}”vermerkt ist.",
      fileName: "{{YYYYMMDD}}_Klageerwiderung_AnlageB{{iterator}}_{{clientFamilyName}}.pdf",
      fileDescription: "Beweis {{iterator}} Klageerwiderung vom {{DD.MM.YYYY}}",
    },
  },
  {
    type: "counterplea",
    label: "Replik des Klägers",
    subject: "Replik auf Klageerwiderung",
    description:
      "Im vorliegenden Fall soll eine Replik erstellt werden. Weiteres ist dem Fall und den dortigen Dateien zu entnehmen.",
    definitionOfDone:
      "- Verarbeiten der Stellungnahme des Mandanten zum Schriftsatz der Gegenseite (wenn vorhanden)\n" +
      "- Wo nötig Beweis antreten bzw. Bestreiten/mit Nichtwissen bestreiten\n" +
      "- Als Abschluss des Schriftsatzes bitte folgende beiden Absätze verwenden: Soweit das Gericht entgegen der" +
      " diesseitigen Auffassung den Vortrag als unzureichend, unschlüssig oder unsubstantiiert ansieht, um den geltend" +
      " gemachten Anspruch als schlüssig zu qualifizieren, oder wenn sonst Bedenken gegen die gefassten Anträge," +
      " die Schlüssigkeit und die Substantiierung des Vortrages und die hiesige Sichtweise in der Beweislast bestehen," +
      " wird um einen entsprechenden Hinweis nach § 139 ZPO bzw. eine prozessleitende Verfügung nach" +
      " § 273 Abs. 2 Nr. 1 ZPO gebeten.\n\n" +
      "Der Unterzeichner weist allein aus Gründen der Vorsorge gegenüber seinem Mandanten darauf hin, dass die" +
      " gerichtliche Hinweis- und Aufklärungspflicht nach der neueren Rechtsprechung auch gegenüber der anwaltlich" +
      " vertretenden Partei uneingeschränkt besteht (BGH NJW 2001, 2548; OLG Köln NJW-RR 2001, 1724).",
    reviewRemarks: "",
    assignmentDeadline: { amount: 7, unit: "days" },
    doneDeadline: { amount: 14, unit: "days" },
    maxFee: "75",
    fee: "50",
    isHidden: ({ product }) => !product || product.stage < STAGE_LAWSUIT || product.stage < STAGE_CHECKED,
    hasEditor: false,
    mainDocumentConfig: {
      headline: "Replik des Klägers",
      description: "Bitte laden Sie hier die Replik als PDF-Datei hoch",
      fileName: "{{YYYYMMDD}}_REP_Replik_{{clientFamilyName}}_aG.pdf",
      fileDescription: "Replik vom {{DD.MM.YYYY}}",
    },
    iterableAttachmentsConfig: {
      identifier: "K{{iterator}}",
      headline: "Anlage",
      description:
        "Bitte laden Sie hier Anlage K{{iterator}} hoch. Achten Sie darauf, dass in dem Dokument am oberen Bildrand “K{{iterator}}”vermerkt ist.",
      fileName: "{{YYYYMMDD}}_Replik_AnlageK{{iterator}}_{{clientFamilyName}}.pdf",
      fileDescription: "Anlage {{iterator}} Replik vom {{DD.MM.YYYY}}",
    },
  },
  {
    type: "rejoinder",
    label: "Duplik des Beklagten",
    subject: "Duplik des Beklagten",
    description:
      "Im vorliegenden Fall soll eine Duplik als Reaktion auf das Schreiben der Gegenseite erstellt werden. Weiteres ist dem Fall und den dortigen Dateien zu entnehmen.",
    definitionOfDone:
      "- Verarbeiten der Stellungnahme des Mandanten zum Schriftsatz der Gegenseite (wenn vorhanden)\n" +
      "- Wo nötig Beweis antreten bzw. Bestreiten/mit Nichtwissen bestreiten\n" +
      "- Als Abschluss des Schriftsatzes bitte folgende beiden Absätze verwenden: Soweit das Gericht entgegen der" +
      " diesseitigen Auffassung den Vortrag als unzureichend, unschlüssig oder unsubstantiiert ansieht, um den geltend" +
      " gemachten Anspruch als schlüssig zu qualifizieren, oder wenn sonst Bedenken gegen die gefassten Anträge," +
      " die Schlüssigkeit und die Substantiierung des Vortrages und die hiesige Sichtweise in der Beweislast bestehen," +
      " wird um einen entsprechenden Hinweis nach § 139 ZPO bzw. eine prozessleitende Verfügung nach" +
      " § 273 Abs. 2 Nr. 1 ZPO gebeten.\n\n" +
      "Der Unterzeichner weist allein aus Gründen der Vorsorge gegenüber seinem Mandanten darauf hin, dass die" +
      " gerichtliche Hinweis- und Aufklärungspflicht nach der neueren Rechtsprechung auch gegenüber der anwaltlich" +
      " vertretenden Partei uneingeschränkt besteht (BGH NJW 2001, 2548; OLG Köln NJW-RR 2001, 1724).",
    reviewRemarks: "",
    assignmentDeadline: { amount: 7, unit: "days" },
    doneDeadline: { amount: 14, unit: "days" },
    maxFee: "75",
    fee: "50",
    isHidden: ({ product }) => !product || product.stage < STAGE_LAWSUIT || product.stage < STAGE_CHECKED,
    hasEditor: false,
    mainDocumentConfig: {
      headline: "Duplik des Beklagten",
      description: "Bitte laden Sie hier die Duplik als PDF-Datei hoch",
      fileName: "{{YYYYMMDD}}_REP_Duplik_{{clientFamilyName}}_aG.pdf",
      fileDescription: "Duplik vom {{DD.MM.YYYY}}",
    },
    iterableAttachmentsConfig: {
      identifier: "B{{iterator}}",
      headline: "Anlage",
      description:
        "Bitte laden Sie hier Beweis B{{iterator}} hoch. Achten Sie darauf, dass in dem Dokument am oberen Bildrand “B{{iterator}}”vermerkt ist.",
      fileName: "{{YYYYMMDD}}_Duplik_AnlageB{{iterator}}_{{clientFamilyName}}.pdf",
      fileDescription: "Beweis {{iterator}} Duplik vom {{DD.MM.YYYY}}",
    },
  },
  {
    type: "surrejoinder",
    label: "Triplik des Klägers",
    subject: "Triplik des Klägers",
    description:
      "Im vorliegenden Fall soll eine Triplik erstellt werden. Weiteres ist dem Fall und den dortigen Dateien zu entnehmen.",
    definitionOfDone:
      "- Verarbeiten der Stellungnahme des Mandanten zum Schriftsatz der Gegenseite (wenn vorhanden)\n" +
      "- Wo nötig Beweis antreten bzw. Bestreiten/mit Nichtwissen bestreiten\n" +
      "- Als Abschluss des Schriftsatzes bitte folgende beiden Absätze verwenden: Soweit das Gericht entgegen der " +
      "diesseitigen Auffassung den Vortrag als unzureichend, unschlüssig oder unsubstantiiert ansieht, um den geltend " +
      "gemachten Anspruch als schlüssig zu qualifizieren, oder wenn sonst Bedenken gegen die gefassten Anträge, die " +
      "Schlüssigkeit und die Substantiierung des Vortrages und die hiesige Sichtweise in der Beweislast bestehen, wird " +
      "um einen entsprechenden Hinweis nach § 139 ZPO bzw. eine prozessleitende Verfügung nach § 273 Abs. 2 Nr. 1 ZPO gebeten.\n\n" +
      "Der Unterzeichner weist allein aus Gründen der Vorsorge gegenüber seinem Mandanten darauf hin, dass die gerichtliche" +
      " Hinweis- und Aufklärungspflicht nach der neueren Rechtsprechung auch gegenüber der anwaltlich vertretenden Partei " +
      "uneingeschränkt besteht (BGH NJW 2001, 2548; OLG Köln NJW-RR 2001, 1724).",
    reviewRemarks: "",
    assignmentDeadline: { amount: 7, unit: "days" },
    doneDeadline: { amount: 14, unit: "days" },
    maxFee: "75",
    fee: "50",
    isHidden: ({ product }) => !product || product.stage < STAGE_LAWSUIT || product.stage < STAGE_CHECKED,
    hasEditor: false,
    mainDocumentConfig: {
      headline: "Triplik des Klägers",
      description: "Bitte laden Sie hier die Triplik des Klägers als PDF-Datei hoch",
      fileName: "{{YYYYMMDD}}_Triplik_{{clientFamilyName}}_aG.pdf",
      fileDescription: "Triplik vom {{DD.MM.YYYY}}",
    },
    iterableAttachmentsConfig: {
      identifier: "K{{iterator}}",
      headline: "Anlage",
      description:
        " Bitte fügen Sie hier Anlage K{{iterator}} als JPG, PNG oder PDF hoch. Achten Sie darauf, dass in dem Dokument am oberen Bildrand “K{{iterator}}”vermerkt ist.",
      fileName: "{{YYYYMMDD}}_Triplik_AnlageK{{iterator}}_{{clientFamilyName}}.pdf",
      fileDescription: "Anlage {{iterator}} Triplik vom {{DD.MM.YYYY}}",
    },
  },
  {
    type: "rebuttal",
    label: "Quadruplik des Beklagten",
    subject: "Quadruplik des Beklagten",
    description:
      "Im vorliegenden Fall soll eine Quadruplik erstellt werden. Weiteres ist dem Fall und den dortigen Dateien zu entnehmen.",
    definitionOfDone:
      "- Verarbeiten der Stellungnahme des Mandanten zum Schriftsatz der Gegenseite (wenn vorhanden)\n" +
      "- Wo nötig Beweis antreten bzw. Bestreiten/mit Nichtwissen bestreiten\n" +
      "- Als Abschluss des Schriftsatzes bitte folgende beiden Absätze verwenden: Soweit das Gericht entgegen der " +
      "diesseitigen Auffassung den Vortrag als unzureichend, unschlüssig oder unsubstantiiert ansieht, um den geltend " +
      "gemachten Anspruch als schlüssig zu qualifizieren, oder wenn sonst Bedenken gegen die gefassten Anträge, die " +
      "Schlüssigkeit und die Substantiierung des Vortrages und die hiesige Sichtweise in der Beweislast bestehen, wird " +
      "um einen entsprechenden Hinweis nach § 139 ZPO bzw. eine prozessleitende Verfügung nach § 273 Abs. 2 Nr. 1 ZPO gebeten.\n\n" +
      "Der Unterzeichner weist allein aus Gründen der Vorsorge gegenüber seinem Mandanten darauf hin, dass die gerichtliche " +
      "Hinweis- und Aufklärungspflicht nach der neueren Rechtsprechung auch gegenüber der anwaltlich vertretenden Partei " +
      "uneingeschränkt besteht (BGH NJW 2001, 2548; OLG Köln NJW-RR 2001, 1724).",
    reviewRemarks: "",
    assignmentDeadline: { amount: 7, unit: "days" },
    doneDeadline: { amount: 14, unit: "days" },
    maxFee: "75",
    fee: "50",
    isHidden: ({ product }) => !product || product.stage < STAGE_LAWSUIT || product.stage < STAGE_CHECKED,
    hasEditor: false,
    mainDocumentConfig: {
      headline: "Quadruplik des Beklagten",
      description: "Bitte laden Sie hier die Quadruplik des Klägers als PDF-Datei hoch",
      fileName: "{{YYYYMMDD}}_Quadruplik_{{clientFamilyName}}_aG.pdf",
      fileDescription: "Quadruplik vom {{DD.MM.YYYY}}",
    },
    iterableAttachmentsConfig: {
      identifier: "B{{iterator}}",
      headline: "Anlage",
      description:
        " Bitte fügen Sie hier Anlage B{{iterator}} als JPG, PNG oder PDF hoch. Achten Sie darauf, dass in dem Dokument am oberen Bildrand “B{{iterator}}”vermerkt ist.",
      fileName: "{{YYYYMMDD}}_Quadruplik_AnlageB{{iterator}}_{{clientFamilyName}}.pdf",
      fileDescription: "Anlage {{iterator}} Quadruplik vom {{DD.MM.YYYY}}",
    },
  },
  {
    type: "contractCheck",
    label: "Vertragsprüfung",
    subject: "",
    description: "",
    definitionOfDone: "",
    reviewRemarks: "",
    assignmentDeadline: { amount: 5, unit: "days" },
    doneDeadline: { amount: 10, unit: "days" },
    maxFee: "50",
    fee: "15",
    isHidden: ({ product }) => product.stage < STAGE_CHECKED,
    hasEditor: true,
  },
  {
    type: "fullMandate",
    label: "Vollmandat",
    subject: "",
    description: "",
    definitionOfDone: "",
    reviewRemarks: "",
    assignmentDeadline: { amount: 5, unit: "days" },
    doneDeadline: { amount: 10, unit: "days" },
    maxFee: "125",
    fee: "15",
    isHidden: ({ product }) => product.stage < STAGE_CHECKED,
    hasEditor: true,
  },
];

const generalPaidTaskTemplates = [
  {
    type: "custom",
    label: "Individuelle Aufgabe",
    subject: "",
    description: "",
    definitionOfDone: "",
    reviewRemarks: "",
    assignmentDeadline: { amount: 5, unit: "days" },
    doneDeadline: { amount: 10, unit: "days" },
    maxFee: "50",
    fee: "15",
    isHidden: ({ product, productStages }) => product.stage < productStages.STAGE_CHECKED,
    hasEditor: true,
  },
];

export const getTemplates = (product) => {
  let templates = [...generalPaidTaskTemplates];
  switch (true) {
    case product.productCategory === "contractLaw":
      templates = [...templates, ...contractLawTemplates];
      break;
    default:
      break;
  }
  return templates;
};

export const paidTaskTemplateFields = {
  basic: ["label", "subject", "description", "definitionOfDone", "reviewRemarks", "fee"],
  date: ["assignmentDeadline", "doneDeadline"],
};

export const getSubmissionCardEnabled = (paidTaskStatus, isExternalUser) => {
  switch (true) {
    case isExternalUser:
      return paidTaskStatus === PAID_TASK_STATUS_ASSIGNED;
    case !isExternalUser:
      return paidTaskStatus === PAID_TASK_STATUS_IN_REVIEW;
    default:
      return false;
  }
};

export const getApprovalCardEnabled = (paidTaskStatus, currentUser) => {
  switch (true) {
    case userHasRole(currentUser, Roles.external):
      return paidTaskStatus >= PAID_TASK_STATUS_ASSIGNED;
    case userHasOneOfTheseRoles(currentUser, [
      Roles.accountmanager,
      Roles.lawyer,
      Roles.traineeLawyer,
      Roles.admin,
      Roles.developer,
    ]):
      return paidTaskStatus >= PAID_TASK_STATUS_IN_REVIEW;
    default:
      return false;
  }
};

export const getInitialValuesForPaidTask = (paidTaskConfig, product, paidTask) => {
  let initialValues = {};

  const formData = paidTask.formData;

  if (paidTaskConfig.hasEditor) {
    initialValues.sigantureType = formData?.sigantureType || "internal";
    initialValues.recipientType = formData?.recipientType || "custom";
    initialValues.address = {
      name: formData?.address?.name || "",
      department: formData?.address?.department || "",
      subDepartment: formData?.address?.subDepartment || "",
      streetAddress: formData?.address?.streetAddress || "",
      postalCode: formData?.address?.postalCode || "",
      addressLocality: formData?.address?.addressLocality || "",
    };
    initialValues.senderMedium = formData?.senderMedium || "none";
    initialValues.externalReference = formData?.externalReference || "";
    initialValues.externalReferenceSelect = formData?.externalReferenceSelect || "free";
    initialValues.filename = formData?.filename || moment().format("YYYYMMDD") + "_Aussergerichtliches_Schreiben";

    initialValues.content = formData?.content ? htmlToEditorState(formData.content) : EditorState.createEmpty();
    initialValues.showInGallery = formData?.showInGallery || "false";
    initialValues.sendEmailToCustomer = formData?.sendEmailToCustomer || "false";
    initialValues.selectedMediaObjects = formData?.selectedMediaObjects ? [...formData.selectedMediaObjects] : [];
  }

  _.forEach(paidTaskConfig.fields, (field) => {
    const path = _.isFunction(field.path) ? field.path({ product }) : field.path;
    let value = _.get(formData, "temporaryField." + path, null);
    if (value && field.type === "ValidatorDateField") {
      value = moment(value);
    }
    initialValues["temporaryField." + path] = value;
  });

  return initialValues;
};

export const paidTaskListFilters = {
  assigneeView: {
    assigned: {
      filter: (paidTask) => {
        return paidTask.status === PAID_TASK_STATUS_ASSIGNED;
      },
      sorter: (paidTask) => {
        return paidTask.doneDeadline;
      },
    },
    inReview: {
      filter: (paidTask) => {
        return paidTask.status === PAID_TASK_STATUS_IN_REVIEW;
      },
      sorter: (paidTask) => {
        return paidTask.doneDeadline;
      },
    },
    approved: {
      filter: (paidTask) => {
        return paidTask.status === PAID_TASK_STATUS_APPROVED;
      },
      sorter: (paidTask) => {
        return paidTask.doneDeadline;
      },
    },
  },
  internalView: {
    assignmentDeadline: {
      filter: (paidTask) => {
        return paidTask.status === PAID_TASK_STATUS_CREATED && moment().isAfter(paidTask.assignmentDeadline, "day");
      },
      sorter: (paidTask) => {
        return paidTask.assignmentDeadline;
      },
    },
    doneDeadline: {
      filter: (paidTask) => {
        paidTask.status === PAID_TASK_STATUS_ASSIGNED && moment().isAfter(paidTask.doneDeadline, "day");
      },
      sorter: (paidTask) => {
        return paidTask.doneDeadline;
      },
    },
    open: {
      filter: (paidTask) => {
        return paidTask.status === PAID_TASK_STATUS_CREATED;
      },
      sorter: (paidTask) => {
        return paidTask.assignmentDeadline;
      },
    },
    requested: {
      filter: (paidTask) => {
        return paidTask.status === PAID_TASK_STATUS_REQUESTED;
      },
      sorter: (paidTask) => {
        return paidTask.assignmentDeadline;
      },
    },
    assigned: {
      filter: (paidTask) => {
        return paidTask.status === PAID_TASK_STATUS_ASSIGNED;
      },
      sorter: (paidTask) => {
        return paidTask.doneDeadline;
      },
    },
    inReview: {
      filter: (paidTask) => {
        return paidTask.status === PAID_TASK_STATUS_IN_REVIEW;
      },
      sorter: (paidTask) => {
        return paidTask.doneDeadline;
      },
    },
    approved: {
      filter: (paidTask) => {
        return paidTask.status === PAID_TASK_STATUS_APPROVED;
      },
      sorter: (paidTask) => {
        return paidTask.approvedDate;
      },
    },
  },
  assignableView: {
    open: {
      filter: (paidTask) => {
        return paidTask.status === PAID_TASK_STATUS_CREATED;
      },
      sorter: (paidTask) => {
        return paidTask.assignmentDeadline;
      },
    },
    requested: {
      filter: (paidTask) => {
        return paidTask.status === PAID_TASK_STATUS_REQUESTED;
      },
      sorter: (paidTask) => {
        return paidTask.assignmentDeadline;
      },
    },
  },
};

export const updateFeeValidators = (registerValidators, paidTaskType, product) => {
  if (!paidTaskType) {
    return;
  }
  const typeConfig = _.find(getTemplates(product), (template) => template.type === paidTaskType);
  const maxFee = parseInt(typeConfig.maxFee);
  registerValidators("fee", [
    ...requiredFieldDefault,
    ...numberFieldDefault,
    {
      validator: minNumber,
      params: [1],
      message: "Der Betrag muss über 0€ liegen",
    },
    {
      validator: maxNumber,
      params: [maxFee],
      message: `Der Betrag darf maximal ${maxFee}€ betragen`,
    },
  ]);
};
