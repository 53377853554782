import React from "react";
import ProcessParticipantPersonForm from "./ProcessParticipantPersonForm";
import { Grid } from "@mui/material";
import _ from "lodash";

const ProcessParticipantsPeopleForm = ({ product, refreshPage }) => {
  return (
    <Grid container>
      {_.map(_.sortBy(product.processParticipantsPeople, "id"), (participant) => (
        <Grid key={participant.id} item xs={12}>
          <ProcessParticipantPersonForm product={product} participant={participant} refreshPage={refreshPage} />
        </Grid>
      ))}
      <Grid item xs={12}>
        <ProcessParticipantPersonForm product={product} refreshPage={refreshPage} />
      </Grid>
    </Grid>
  );
};
export default ProcessParticipantsPeopleForm;
