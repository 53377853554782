import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  headline: {
    fontSize: "1rem",
    textDecoration: "line-through",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 700,
    flexGrow: 1,
  },
  activityActions: {
    minWidth: 30,
  },
  placeholderGridItem: {
    width: 42,
  },
  imageGridItem: {
    width: 72,
  },
  activityContent: {
    width: "calc(100% - 72px)",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  activityContainer: {
    margin: "30px 0 0 0",
  },
  outerActivityContainer: {
    position: "relative",
    width: "100%",
  },
}));

export default useStyles;
