const homePageStyles = {
  listContainer: {
    padding: "20px",
  },
  bold: {
    fontWeight: 500,
  },
  listItemTitle: {
    fontWeight: 500,
    fontSize: "1rem",
    color: "black",
  },
};

export default homePageStyles;
