import React from "react";
import * as PropTypes from "prop-types";
import _ from "lodash";
import ValidatorTextField from "../Validator/ValidatorTextField";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";

const ContractLawRepresentatives = ({ name, values, disabled, onChange, fullWidth }) => {
  const handleChange = (value, index) => {
    let newArray = [...values];
    newArray[index] = value;
    onChange({ target: { name: name, value: newArray } });
  };

  const handleAddRepresentative = () => {
    let newArray = [];
    if (values) {
      newArray = [...values];
    }
    newArray.push("");
    onChange({ target: { name: name, value: newArray } });
  };

  return (
    <Grid container>
      {_.map(values, (representative, index) => {
        return (
          <Grid item xs={12} key={index}>
            <ValidatorTextField
              label={"Vertretungsberechtigter " + (index + 1)}
              name={"additionalRepresentative" + index}
              value={representative}
              onChange={(e) => handleChange(e.target.value, index)}
              disabled={disabled}
            />
          </Grid>
        );
      })}
      {!disabled && (
        <Grid item xs={12}>
          <Button onClick={handleAddRepresentative}>Vertretungsberechtigten hinzufügen</Button>
        </Grid>
      )}
    </Grid>
  );
};

ContractLawRepresentatives.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default ContractLawRepresentatives;
