import React, { useEffect, useState } from "react";
import { VAT_MULTIPLIER } from "../../../services/globalVars";
import ContentBox from "../../ContentBox/ContentBox";
import { apiGet } from "../../../services/Api/apiCall";
import { Typography } from "@mui/material";
import { AbstractCase } from "../../../types/AbstractCase";
import CostCalculatorTrafficResponse from "../../../types/CostCalculatorTrafficResponse";

const CostBoxTraffic = ({ traffic }: { traffic: AbstractCase }) => {
  const [costCalculatorResponse, setCostCalculatorResponse] = useState<CostCalculatorTrafficResponse | any>(null);

  useEffect(() => {
    apiGet("lb/get_fees/" + traffic.id).then((response: CostCalculatorTrafficResponse) => {
      setCostCalculatorResponse(response);
    });
  }, []);

  if (!traffic.fine) {
    return (
      <Typography textAlign={"center"} color={"text.secondary"}>
        Bisher ist die Bußgeldhöhe unbekannt und daher keine Berechnung der Kosten nach RVG möglich.
      </Typography>
    );
  }
  if (!costCalculatorResponse) {
    return null;
  }
  return (
    <ContentBox headline="Berechnete Kosten">
      <p>
        <strong>
          Bußgeldhöhe:{" "}
          {traffic.fine.fine.toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
          })}
        </strong>
        <br />
        <br />
      </p>
      <p>
        <strong>Kosten außergerichtliches Verfahren</strong>
        <br />
        Grundgebühr gem. Nr. 5100 VV RVG: 110,00 €<br />
        Verfahrensgebühr gem. Nr. {costCalculatorResponse.processCostAuthority.rvgReference} VV RVG:{" "}
        {costCalculatorResponse.processCostAuthority.amount.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
        })}
        <br />
        Entgelt für Post- und Telekommunikationsdienstleistungen Nr. 7002 VV RVG: 20,00 €<br />
        Auslagenpauschale für Aktenübersendung Behörde: 12,00 €<br />
        Zwischensumme Netto:{" "}
        {costCalculatorResponse.sumOfCostsAuthority.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
        })}
        <br />
        <strong>
          Summe inkl. MwSt.:{" "}
          {costCalculatorResponse.sumOfCostsAuthorityWithVat.toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
          })}
        </strong>
        <br />
        <br />
      </p>
      <p>
        <strong>Kosten gerichtliches Verfahren (RVG)</strong>
        <br />
        Verfahrensgebühr gem. Nr. {costCalculatorResponse.processCostCourt.rvgReference} VV RVG:{" "}
        {costCalculatorResponse.processCostCourt.amount.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
        })}
        <br />
        Terminsgebühr gem Nr. {costCalculatorResponse.hearingCostCourt.rvgReference} VV RVG:{" "}
        {costCalculatorResponse.hearingCostCourt.amount.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
        })}
        <br />
        Entgelt für Post- und Telekommunikationsdienstleistungen Nr. 7002 VV RVG: 20,00 €<br />
        Zwischensumme Netto:{" "}
        {costCalculatorResponse.sumOfCostsCourt.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
        })}
        <br />
        <strong>
          Summe inkl. MwSt:{" "}
          {costCalculatorResponse.sumOfCostsCourtWithVat.toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
          })}
        </strong>
      </p>
    </ContentBox>
  );
};

export default CostBoxTraffic;
