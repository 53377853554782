import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  actionTitle: {
    fontSize: 10,
    textAlign: "center",
    marginTop: "-5px",
    wordBreak: "break-all",
  },
  icon: {},
  actionButtonDivider: {
    height: 1,
    background: "#aaa",
    width: "70%",
    marginTop: 4,
  },
}));

export default useStyles;
