import React from "react";
import { CalendlyAssessment } from "../../../../types/CalendlyAssessment";
import { Box, Rating, Stack, Typography } from "@mui/material";
import { translate } from "../../../../services/Translations/translatorService";

type CaseHistoryActivityCalendlyAssessmentProps = {
  calendlyAssessment: CalendlyAssessment;
};

export default function CaseHistoryActivityCalendlyAssessment({
  calendlyAssessment,
}: CaseHistoryActivityCalendlyAssessmentProps) {
  return (
    <Stack spacing={1}>
      <Typography>Gespräch stattgefunden: {calendlyAssessment.processed ? "Ja" : "Nein"}</Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {calendlyAssessment.followUp && (
          <Typography>Weitere Mandatstätigkeiten notwendig: {calendlyAssessment.followUp ? "Ja" : "Nein"}</Typography>
        )}
        {calendlyAssessment.followUpAction && (
          <Typography>
            Weitere Tätigkeit:{" "}
            {translate("assessment.short.followUpAction.values." + calendlyAssessment.followUpAction, "-")}
          </Typography>
        )}
      </Box>
      {calendlyAssessment.acceptsMandateTransferal !== null && (
        <Box>
          <Typography>Übernahme des Mandats: {calendlyAssessment.acceptsMandateTransferal ? "Ja" : "Nein"}</Typography>
        </Box>
      )}
      {calendlyAssessment.customerSatisfaction && (
        <Box>
          <Typography>Zufriedenheit Mandant: </Typography>
          <Rating size={"small"} value={calendlyAssessment.customerSatisfaction} readOnly={true} />
        </Box>
      )}
      {calendlyAssessment.note && <Typography>Hinweise: {calendlyAssessment.note}</Typography>}
    </Stack>
  );
}
