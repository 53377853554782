import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  actions: {
    width: "7rem",
    marginLeft: "auto",
  },
  deleteText: {
    textAlign: "center",
    marginTop: "2rem",
  },
  icon: {
    color: "black",
  },
  selectedFont: {
    "& .MuiTableCell-body": {
      color: "#137f7b",
    },
  },
}));

export default useStyles;
