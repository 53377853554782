import React from "react";
import NavigationBar from "../NavigationBar/NavigationBar";
import BackofficeUserNotifications from "../BackofficeUserNotifications/BackofficeUserNotifications";
import GeneralActions from "./GeneralActions";
import layoutStyle from "./layoutStyle";
import GlobalProvider from "../Provider/GlobalProvider";
import BlockPage from "./BlockPage/BlockPage";
import { Box } from "@mui/material";

export default function Layout({ children }: { children?: React.ReactNode }){
  return (
    <GlobalProvider>
      <BlockPage />
      <NavigationBar />
      <Box sx={layoutStyle.layoutContainer}>
        <Box sx={layoutStyle.generalActionsContainer}>
          <GeneralActions />
        </Box>
        <Box sx={layoutStyle.mainContentContainer}>{children}</Box>
        <Box sx={layoutStyle.additionalActionsContainer} />
      </Box>
      <BackofficeUserNotifications />
    </GlobalProvider>
  );
};