import PropTypes from "prop-types";
import _ from "lodash";
import { Navigate, useLocation } from "react-router-dom";
import React from "react";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

export default function RequireRole({ children, allowedRoles, redirectTo }) {
  const currentUser = useCurrentUser();
  const location = useLocation();
  if (!currentUser || _.intersection(currentUser.roles, allowedRoles).length === 0) {
    return <Navigate to={redirectTo} state={{ from: location }} />;
  }
  return children;
}
RequireRole.propTypes = {
  allowedRoles: PropTypes.array.isRequired,
  redirectTo: PropTypes.string,
};
RequireRole.defaultProps = {
  redirectTo: "/login",
};
