import React, { useContext } from "react";
import { BackofficeCase } from "../types/BackofficeCase";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../services/ReactQuery/reactQueryService";

interface AccessibleCasesContextInterface {
  isLoading: boolean;
  error: any;
  accessibleCases: BackofficeCase[] | null;
}

const AccessibleCasesContext = React.createContext<AccessibleCasesContextInterface | null>(null);

export default function AccessibleCasesProvider({ children }: { children?: React.ReactNode }) {
  const {
    data: accessibleCases,
    isLoading,
    error,
  } = useQuery(queryKeys.all("accessibleCases"), () => fetchCollection("/services/external/accessibleCases"), {
    retry: false,
  });
  const data: AccessibleCasesContextInterface = {
    isLoading,
    error,
    accessibleCases,
  };
  return <AccessibleCasesContext.Provider value={data}>{children}</AccessibleCasesContext.Provider>;
}

export const useAccessibleCases = () => {
  const accessibleCasesContext = useContext(AccessibleCasesContext);
  if (!accessibleCasesContext) {
    throw new Error("useAccessibleCases can only be used inside AccessibleCasesProvider");
  }
  return accessibleCasesContext;
};
