import { Box, Grid } from "@mui/material";
import React, { useMemo, useState, useEffect } from "react";
import ButtonLoading from "../../Button/ButtonLoading";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import { transformToFormValues } from "../../../services/formServiceFunctions";
import { useCase } from "../../../provider/CaseProvider";
import useForm from "../../../hooks/useForm";
import _ from "lodash";
import ValidatorForm from "../../Validator/ValidatorForm";
import ApiClient from "../../../services/ApiClient";
import EntityFields from "../../DatasetEntityPicker/EntityFields";
import {
  duplicationChecker,
  DuplicationCheckResult,
  getDatasetConfigFromProduct,
} from "../../DatasetEntityPicker/datasetHelper";
import SearchBar from "../../Algolia/SearchBar";

interface CaseEntityPickerProps {
  path: string;
  type: string;
  disabled?: boolean;
}

export default function CaseEntityPicker({ path, disabled = false, type }: CaseEntityPickerProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [duplicationCheckResult, setDuplicationCheckResult] = useState<DuplicationCheckResult | null>(null);
  const { product, isLoading: productIsLoading, refreshCase } = useCase();

  useEffect(() => {
    const entity = _.get(product, path);
    if (!!entity) {
      duplicationChecker({
        values: entity,
        type: datasetConfig.entityApiData.baseEntityAttribute,
        currentFieldname: "name",
        currentId: entity.id,
      }).then((duplicationCheckResult) => {
        if (duplicationCheckResult !== null) {
          setDuplicationCheckResult(duplicationCheckResult);
        }
      });
    }
  }, [product]);

  const initialValues = useMemo(
    () => ({
      [product?.productClassName || "x"]: transformToFormValues(product),
    }),
    [product]
  );

  const datasetConfig = useMemo(() => {
    return getDatasetConfigFromProduct(type, product);
  }, [product, type]);

  const actionType = useMemo(() => {
    const entity = _.get(product, path);
    if (_.has(entity, "isChecked") && entity.isChecked === false) {
      return "check";
    }
    if (!entity) {
      return "create";
    }
    return "edit";
  }, [product, path]);

  const productDependentKey = useMemo(() => {
    return "" + new Date().getTime();
  }, [product]);

  const onSubmit = async ({ values }: any) => {
    await putEntity(values, product);
  };

  const useFormProps = useForm({
    initialValues,
    onSubmit,
    identifier: productDependentKey,
  });

  const pickEntity = async (entityId: number) => {
    setIsLoading(true);
    await ApiClient.put(datasetConfig.entityApiData.baseEntityApiUri, {
      body: JSON.stringify({
        [datasetConfig.entityApiData.baseEntityAttribute]: datasetConfig.entityApiData.entityApiUri + "/" + entityId,
        ...datasetConfig.entityApiData.additionalBaseEntityData,
      }),
    });
    refreshCase();
    setIsLoading(false);
  };

  const clearEntity = async () => {
    setIsLoading(true);
    await ApiClient.put(datasetConfig.entityApiData.baseEntityApiUri, {
      body: JSON.stringify({
        [datasetConfig.entityApiData.baseEntityAttribute]: null,
        ...datasetConfig.entityApiData.additionalBaseEntityData,
      }),
    });
    refreshCase();
    setIsLoading(false);
  };

  const putEntity = async (values: any, product: any) => {
    setIsLoading(true);
    let entityData = _.get(values, product.productClassName + "." + path);
    switch (actionType) {
      case "create":
        const address = await ApiClient.post("addresses", { body: JSON.stringify(entityData.address || {}) });
        entityData.address = address["@id"];
        entityData = _.merge(entityData, datasetConfig.entityApiData.additionalSaveData);
        const entity = await ApiClient.post(datasetConfig.entityApiData.entityApiUri, {
          body: JSON.stringify(entityData),
        });
        await pickEntity(entity.id);
        break;
      case "check":
      case "edit":
        if (_.has(entityData, "address")) {
          await ApiClient.put(entityData.address["@id"], { body: JSON.stringify(entityData.address) });
          entityData.address = undefined;
        }
        entityData = _.merge(entityData, datasetConfig.entityApiData.additionalSaveData);
        await ApiClient.put(entityData["@id"], { body: JSON.stringify(entityData) });
        refreshCase();
        break;
    }
    setIsLoading(false);
    setEditOpen(false);
  };

  const handleDuplicateCheck = async ({ target }: { target: HTMLInputElement }) => {
    const currentFieldname = target.name.split(".").pop() || "";
    let entityData = _.get(useFormProps.values, product?.productClassName + "." + path);
    const duplicationCheckResult = await duplicationChecker({
      values: entityData,
      type: datasetConfig.entityApiData.baseEntityAttribute,
      currentFieldname: currentFieldname,
      currentId: entityData.id,
    });
    if (duplicationCheckResult !== null) {
      setDuplicationCheckResult(duplicationCheckResult);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <SearchBar
            searchConfig={datasetConfig.searchConfig}
            resultListConfig={datasetConfig.resultListConfig}
            HitComponent={datasetConfig.HitComponent}
            onHitClick={pickEntity}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <ButtonLoading
            disabled={actionType === "create"}
            isLoading={isLoading || productIsLoading}
            onClick={clearEntity}
          >
            Vom Fall entfernen
          </ButtonLoading>
        </Grid>
        <Grid item md={3} xs={12}>
          <ButtonLoading isLoading={isLoading || productIsLoading} onClick={() => setEditOpen(true)}>
            {actionTypeTranslations[actionType].button}
          </ButtonLoading>
        </Grid>
        <EntityFields
          formElements={datasetConfig.entityFormElements}
          useFormProps={useFormProps}
          product={product}
          disabled={true}
          initialValues={initialValues}
        />
      </Grid>
      <LegalbirdIoModal
        handleClose={() => setEditOpen(false)}
        open={editOpen}
        title={actionTypeTranslations[actionType].modalHeadline}
        submitButton={
          <>
            {duplicationCheckResult?.hasPartialMatch && !duplicationCheckResult?.hasFullMatch && (
              <Box
                sx={{
                  color: "orange",
                  padding: "0.5rem",
                  border: "1px solid orange",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                Achtung: Es gibt einen ähnlichen Datensatz. Im Zweifel erst kurz per Suche prüfen, dass tatsächlich
                dieser neue Datensatz notwendig ist. Bitte nur speichern, wenn so keine Dopplung erzeugt wird.
              </Box>
            )}
            {duplicationCheckResult?.hasFullMatch && (
              <Box
                sx={{
                  color: "red",
                  padding: "0.5rem",
                  border: "1px solid red",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                Achtung: Es gibt bereits einen identischen Datensatz. Bitte diese Ansicht schließen und über die Suche
                den bereits vorhandenen Datensatz diesem Fall hinzufügen.
              </Box>
            )}
            <ButtonLoading
              onClick={useFormProps.handleSubmit}
              variant={"contained"}
              isLoading={isLoading}
              disabled={!!duplicationCheckResult?.hasFullMatch}
            >
              Speichern
            </ButtonLoading>
          </>
        }
      >
        <Box
          sx={{
            color: "red",
            padding: "0.5rem",
            border: "1px solid red",
            borderRadius: "5px",
            textAlign: "center",
          }}
        >
          {actionTypeTranslations[actionType].modalHelperText}
        </Box>
        <ValidatorForm onSubmit={useFormProps.handleSubmit}>
          <Grid container>
            <EntityFields
              formElements={datasetConfig.entityFormElements}
              useFormProps={useFormProps}
              product={product}
              fullWidth={true}
              validationCallback={handleDuplicateCheck}
            />
          </Grid>
        </ValidatorForm>
      </LegalbirdIoModal>
    </>
  );
}

const actionTypeTranslations = {
  check: {
    button: "Eintrag prüfen",
    modalHeadline: "Kunden-Angaben für neuen Datensatz nutzen",
    modalHelperText:
      "Achtung: Neuer Eintrag wirklich nötig? Bitte erst über die Suche prüfen, ob es diesen Eintrag bei uns nicht bereits gibt. Wenn nicht, können die Daten des Kunden hier genutzt werden, um einen neuen Eintrag anzulegen.",
  },
  create: {
    button: "Neuen Eintrag erstellen",
    modalHeadline: "Neuen Datensatz anlegen",
    modalHelperText:
      "Achtung: Neuer Eintrag wirklich nötig? Bitte erst über die Suche prüfen, ob es diesen Eintrag bei uns nicht bereits gibt. Wenn nicht, kann hier ein neuer Eintrag angelegt werden.",
  },
  edit: {
    button: "Eintrag bearbeiten",
    modalHeadline: "Vorhandenen Datensatz bearbeiten",
    modalHelperText:
      "Achtung: Diese Änderungen gelten nicht nur für diesen Fall, sondern für alle Fälle, die mit diesem Eintrag verknüpft sind!",
  },
};
