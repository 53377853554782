import React from "react";
import { IconButton, IconButtonProps, Tooltip } from "@mui/material";

type ToolTipIconButtonProps = {
  icon: React.ElementType;
  tooltip: string;
  onClick?: Function;
  to?: string;
  target?: string;
  component?: React.ElementType;
};

export default function ToolTipIconButton({
  icon: Icon,
  tooltip,
  component = "button",
  to,
  target,
  ...rest
}: ToolTipIconButtonProps & IconButtonProps) {
  return (
    <Tooltip title={tooltip}>
      <IconButton component={component} to={to} target={target} {...rest}>
        <Icon />
      </IconButton>
    </Tooltip>
  );
}
