import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Stack, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import investigationAssessmentFormStyle from "./investigationAssessmentFormStyle";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import ValidatorSelect from "../Validator/ValidatorSelect";
import MenuItem from "@mui/material/MenuItem";
import ButtonLoading from "../Button/ButtonLoading";
import useForm from "../../hooks/useForm";
import _ from "lodash";
import moment from "moment";
import ApiClient from "../../services/ApiClient";
import * as trafficStages from "../../services/Stages/StagesTraffic";
import { formValue } from "../../services/formServiceFunctions";
import ContentBox from "../ContentBox/ContentBox";
import { useCase } from "../../provider/CaseProvider";
import Snackbar from "../Snackbar/Snackbar";
import { useSnackbar } from "notistack";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

const useStyles = makeStyles(investigationAssessmentFormStyle);

const InvestigationAssessmentForm = () => {
  const classes = useStyles();
  const currentUser = useCurrentUser();
  const [isLoadingResend, setIsLoadingResend] = useState(false);
  const { refreshCase, product } = useCase();
  const { enqueueSnackbar } = useSnackbar();

  let initialValues = {
    barred: "__null__",
    pictureQuality: "__null__",
    speedMeasurementDocumentation: "__null__",
    speedMeasurementOther: "__null__",
    speedMeasurementProcess: "__null__",
    witnessed: "__null__",
  };

  const investigation = product.investigation;
  _.forEach(initialValues, (value, key) => {
    initialValues[key] = investigation[key] === null ? "__null__" : !!investigation[key] ? "true" : "false";
  });
  initialValues.assessment = _.defaultTo(investigation.assessment, "__null__");
  initialValues.additionalEmailText = _.defaultTo(investigation.additionalEmailText, "");
  initialValues.infoParagraphToDisplayInMail = _.defaultTo(investigation.infoParagraphToDisplayInMail, "__null__");

  const getUpdateValue = (value) => {
    switch (value) {
      case "__null__":
        return null;
      case "true":
        return true;
      case "false":
        return false;
      default:
        return value;
    }
  };

  const resendInvestigationFile = async () => {
    setIsLoadingResend(true);
    const result = await ApiClient.post(
      "case_special_actions/" + product.productClassName + "/" + product.id + "/resend_traffic_investigation_file"
    );

    setIsLoadingResend(false);

    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar
            message={
              result === "email sent"
                ? "Einschätzung erfolgreich neu versendet"
                : "Es gab ein Problem beim erneuten Versenden der Einschätzung"
            }
            isNonInteractive={true}
          />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  };

  const onSubmit = async () => {
    let updateData = {};
    _.forEach(values, (value, key) => {
      updateData[key] = getUpdateValue(value, key);
    });
    await ApiClient.put(investigation["@id"], { body: JSON.stringify(updateData) });
    refreshCase();
  };

  const { values, handleChange, handleSubmit, isLoading } = useForm({
    initialValues,
    onSubmit,
  });

  const isEditable =
    _.intersection(currentUser.roles, ["ROLE_LAWYER", "ROLE_ADMIN"]).length > 0 &&
    investigation.assessmentDate === null &&
    product.stage < trafficStages.STAGE_TRIAL;

  const isSubmittable = () => {
    switch (values.assessment) {
      default:
      case "__null__":
        return false;
      case "process":
        return true;
      case "revoke":
        return _.includes(values, "true");
    }
  };

  return (
    <ContentBox>
      <form onSubmit={handleSubmit}>
        <Grid container alignItems={"center"} alignContent={"center"} className={classes.headlineContainer} spacing={3}>
          <Grid item xs={12} className={classes.headline}>
            Einschätzung der Ermittlungsakte
          </Grid>
          <Grid item xs={7} className={classes.subHeadline}>
            Prüfung
          </Grid>
          <Grid item xs={5} className={classes.subHeadline}>
            Ergebnis
          </Grid>
          <Grid item xs={7}>
            Ist die OWi noch rechtswirksam? (nicht verjährt)
          </Grid>
          <Grid item xs={5}>
            <RadioGroup row={true} name={"barred"} value={values.barred} onChange={handleChange}>
              <FormControlLabel disabled={!isEditable} value={"true"} control={<Radio />} label={"Ja"} />
              <FormControlLabel disabled={!isEditable} value={"false"} control={<Radio />} label={"Nein"} />
              <FormControlLabel disabled={!isEditable} value={"__null__"} control={<Radio />} label={"Keine Angabe"} />
            </RadioGroup>
          </Grid>
          <Grid item xs={7}>
            Durchführung/Überwachung der Messung (u.a. Standort, Personal, Geräte) in Ordnung?
          </Grid>
          <Grid item xs={5}>
            <RadioGroup
              row={true}
              name={"speedMeasurementProcess"}
              value={values.speedMeasurementProcess}
              onChange={handleChange}
            >
              <FormControlLabel disabled={!isEditable} value={"true"} control={<Radio />} label={"Ja"} />
              <FormControlLabel disabled={!isEditable} value={"false"} control={<Radio />} label={"Nein"} />
              <FormControlLabel disabled={!isEditable} value={"__null__"} control={<Radio />} label={"Keine Angabe"} />
            </RadioGroup>
          </Grid>
          <Grid item xs={7}>
            Dokumentation des Messergebnisses im Messprotokoll ordnungsgemäß?
          </Grid>
          <Grid item xs={5}>
            <RadioGroup
              row={true}
              name={"speedMeasurementDocumentation"}
              value={values.speedMeasurementDocumentation}
              onChange={handleChange}
            >
              <FormControlLabel disabled={!isEditable} value={"true"} control={<Radio />} label={"Ja"} />
              <FormControlLabel disabled={!isEditable} value={"false"} control={<Radio />} label={"Nein"} />
              <FormControlLabel disabled={!isEditable} value={"__null__"} control={<Radio />} label={"Keine Angabe"} />
            </RadioGroup>
          </Grid>
          <Grid item xs={7}>
            Andere Fehlerquellen des Messverfahrens ausgeschlossen?
          </Grid>
          <Grid item xs={5}>
            <RadioGroup
              row={true}
              name={"speedMeasurementOther"}
              value={values.speedMeasurementOther}
              onChange={handleChange}
            >
              <FormControlLabel disabled={!isEditable} value={"true"} control={<Radio />} label={"Ja"} />
              <FormControlLabel disabled={!isEditable} value={"false"} control={<Radio />} label={"Nein"} />
              <FormControlLabel disabled={!isEditable} value={"__null__"} control={<Radio />} label={"Keine Angabe"} />
            </RadioGroup>
          </Grid>
          <Grid item xs={7}>
            Qualität des Lichtbildes einer Verkehrsüberwachungskamera in Ordnung?
          </Grid>
          <Grid item xs={5}>
            <RadioGroup row={true} name={"pictureQuality"} value={values.pictureQuality} onChange={handleChange}>
              <FormControlLabel disabled={!isEditable} value={"true"} control={<Radio />} label={"Ja"} />
              <FormControlLabel disabled={!isEditable} value={"false"} control={<Radio />} label={"Nein"} />
              <FormControlLabel disabled={!isEditable} value={"__null__"} control={<Radio />} label={"Keine Angabe"} />
            </RadioGroup>
          </Grid>
          <Grid item xs={7}>
            Können Zeugen/Beamten den Vorfall bezeugen?
          </Grid>
          <Grid item xs={5}>
            <RadioGroup row={true} name={"witnessed"} value={values.witnessed} onChange={handleChange}>
              <FormControlLabel disabled={!isEditable} value={"true"} control={<Radio />} label={"Ja"} />
              <FormControlLabel disabled={!isEditable} value={"false"} control={<Radio />} label={"Nein"} />
              <FormControlLabel disabled={!isEditable} value={"__null__"} control={<Radio />} label={"Keine Angabe"} />
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <TextField
                placeholder={"Ergänzender Text bei Rücknahme"}
                multiline
                rows={8}
                className={classes.textField}
                name={"additionalEmailText"}
                value={formValue(values, "additionalEmailText")}
                onChange={handleChange}
                disabled={!isEditable}
              />
            </Grid>
          </Grid>
          {values.assessment === "revoke" && (
            <Grid item xs={12}>
              <RadioGroup
                name={"infoParagraphToDisplayInMail"}
                value={values.infoParagraphToDisplayInMail}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="__null__"
                  control={<Radio disabled={!isEditable} />}
                  label="Keinen Hinweis einfügen"
                />
                <FormControlLabel
                  value="speedingWarning"
                  control={<Radio disabled={!isEditable} />}
                  label="Hinweis - Fahrverbot bei weiterer Geschwindigkeitsübertretung einfügen"
                />
                <FormControlLabel
                  value="noFlensburgPoints"
                  control={<Radio disabled={!isEditable} />}
                  label="Hinweis - Keine Punkte im FAER einfügen"
                />
              </RadioGroup>
              {/*
              <FormControlLabel
                control={
                  <Checkbox
                    name={"showSpeedingWarning"}
                    checked={values.showSpeedingWarning}
                    onChange={handleChange}
                  />
                }
                label="Hinweis Fahrverbot bei weiterer Geschwindigkeitsübertretung einfügen"
              />*/}
            </Grid>
          )}
          <Grid item xs={6}>
            <ValidatorSelect
              label={"Ergebnis/Einschätzung"}
              name={"assessment"}
              value={values.assessment}
              onChange={handleChange}
              disabled={!isEditable}
            >
              <MenuItem value={"__null__"}>Bitte auswählen</MenuItem>
              <MenuItem value={"revoke"}>Einspruch zurücknehmen</MenuItem>
              <MenuItem value={"process"}>Verfahren weiter führen</MenuItem>
            </ValidatorSelect>
          </Grid>
          <Grid item xs={6}>
            {!investigation.assessmentDate ? (
              <ButtonLoading
                type={"submit"}
                variant={"contained"}
                disabled={!isEditable || !isSubmittable()}
                isLoading={isLoading}
              >
                Einschätzung an Kunden schicken
              </ButtonLoading>
            ) : (
              <Stack alignItems={"center"}>
                <Typography>
                  Am {moment(investigation.assessmentDate).format("DD.MM.YYYY u\\m HH:mm")} Uhr{" "}
                  {values.assessment === "revoke" ? "das erste mal" : ""} an Kunden verschickt
                </Typography>
                {values.assessment === "revoke" && (
                  <ButtonLoading
                    disabled={!isSubmittable()}
                    isLoading={isLoadingResend}
                    onClick={resendInvestigationFile}
                  >
                    Einschätzung erneut versenden
                  </ButtonLoading>
                )}
              </Stack>
            )}
          </Grid>
        </Grid>
      </form>
    </ContentBox>
  );
};

export default InvestigationAssessmentForm;
