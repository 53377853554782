import React, { useCallback } from "react";
import * as PropTypes from "prop-types";
import useStyles from "./templateEditorStyle";
import ApiClient from "../../services/ApiClient";
import _ from "lodash";
import { editorStateToHTML, htmlToEditorState } from "../../services/Editor/editorFunctions";
import { EditorState } from "draft-js";
import useForm from "../../hooks/useForm";
import SubmitButton from "../Button/SubmitButton";
import { Grid, MenuItem } from "@mui/material";
import { requiredFieldDefault, textFieldDefault } from "../../services/validationRules";
import ValidatorTextField from "../Validator/ValidatorTextField";
import ValidatorSelect from "../Validator/ValidatorSelect";
import { Editor } from "react-draft-wysiwyg";
import { formValue } from "../../services/formServiceFunctions";
import { getFlatTranslationPathsWithLabel } from "../../services/Translations/translatorService";
import AddPlaceholder from "./AddPlaceholder";

const TemplateEditor = ({ template, onSubmitCallback, initialProduct, type, withSubject }) => {
  const classes = useStyles({});

  const onSubmit = async ({ values }) => {
    let valuesToPersist = _.merge(
      {
        type: type,
      },
      values
    );
    valuesToPersist.content = _.unescape(editorStateToHTML(values.content));
    if (!template) {
      await ApiClient.post("templates", { body: JSON.stringify(valuesToPersist) });
      clearForm();
    } else {
      await ApiClient.put("templates/" + template.id, { body: JSON.stringify(valuesToPersist) });
    }
    if (_.isFunction(onSubmitCallback)) {
      await onSubmitCallback();
    }
  };

  let initialValues;

  if (template) {
    initialValues = {
      productClassName: template.productClassName,
      label: template.label,
      subject: template.subject,
      content: htmlToEditorState(template.content),
    };
  } else {
    initialValues = {
      productClassName: initialProduct,
      label: "",
      subject: "",
      content: EditorState.createEmpty(),
    };
  }

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    registerValidators,
    touchedValues,
    isLoading,
    clearForm,
  } = useForm({
    initialValues,
    onSubmit,
  });

  const handleEditorStateChange = (editorState) => {
    handleChange({
      target: {
        name: "content",
        value: editorState,
      },
    });
  };

  const getPlaceholders = useCallback(() => {
    const translationPathsWithLabel = getFlatTranslationPathsWithLabel(values.productClassName);
    return _.pickBy(translationPathsWithLabel, (value, key) => _.endsWith(key, "label"));
  }, [values.productClassName]);

  const placeholders = getPlaceholders();

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent={"center"} alignItems={"center"} spacing={3}>
        <Grid item xs={6}>
          <ValidatorSelect
            disabled={!!template}
            label={"Rechtsprodukt"}
            name={"productClassName"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values["productClassName"]}
          >
            <MenuItem value={"divorce"}>Scheidung</MenuItem>
            <MenuItem value={"settlement"}>Abfindung</MenuItem>
            <MenuItem value={"traffic"}>Verkehr</MenuItem>
            <MenuItem value={"death"}>Tod und Erbe</MenuItem>
            <MenuItem value={"alimony"}>Unterhalt</MenuItem>
            <MenuItem value={"patientDecree"}>Patientenverfügung</MenuItem>
            <MenuItem value={"powerOfAttorney"}>Vorsorgevollmacht</MenuItem>
            <MenuItem value={"shortTimeWork"}>Kurzarbeit</MenuItem>
            <MenuItem value={"familyLawCase"}>Familienrecht</MenuItem>
            <MenuItem value={"contractLaw"}>Vertragsrecht</MenuItem>
            <MenuItem value={"general"}>Produktübergreifend</MenuItem>
          </ValidatorSelect>
        </Grid>
        <Grid item xs={6}>
          <ValidatorTextField
            label={"Vorlage"}
            name={"label"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values["label"]}
            registerValidators={registerValidators}
            validators={[...textFieldDefault, ...requiredFieldDefault]}
            error={!!errors["label"]}
            helperText={errors["label"]}
          />
        </Grid>
        {withSubject && (
          <Grid item xs={12}>
            <ValidatorTextField
              label={"Betreff"}
              name={"subject"}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["subject"]}
              registerValidators={registerValidators}
              validators={[...textFieldDefault, ...requiredFieldDefault]}
              error={!!errors["subject"]}
              helperText={errors["subject"]}
              multiline
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Editor
            toolbar={{
              options: ["inline", "list", "history", "link"],
              inline: {
                options: ["bold", "italic", "underline"],
              },
              list: {
                options: ["unordered", "ordered"],
              },
            }}
            stripPastedStyles
            toolbarCustomButtons={[
              <AddPlaceholder placeholders={placeholders} productClassName={values.productClassName} />,
            ]}
            editorState={formValue(values, "content")}
            onEditorStateChange={handleEditorStateChange}
            editorClassName={classes.editor}
            placeholder={"Entwurf"}
            localization={{ locale: "de" }}
          />
        </Grid>
        <Grid item xs={12}>
          <SubmitButton
            error={_.keys(errors).length > 0}
            isLoading={isLoading}
            disabled={_.keys(touchedValues).length === 0}
            variant={"contained"}
            type={"submit"}
          >
            Vorlage speichern
          </SubmitButton>
        </Grid>
      </Grid>
    </form>
  );
};

TemplateEditor.propTypes = {
  template: PropTypes.object,
  onSubmitCallback: PropTypes.func,
  initialProduct: PropTypes.string,
  type: PropTypes.string.isRequired,
  withSubject: PropTypes.bool,
};

export default TemplateEditor;
