const scannedMediaObjectRowStyle = {
  link: {
    color: "#137f7b",
    textDecoration: "none",
  },
  borderLess: {
    "& td": {
      border: "none",
      paddingBottom: 0,
    },
  },
  additionalRow: {
    "& td": {
      paddingTop: 0,
    },
  },
};

export default scannedMediaObjectRowStyle;
