const caseHeaderStyle = {
  caseHeaderContainer: {
    width: "100%",
    padding: "0.5rem 2rem 2rem",
  },
  alignRight: {
    textAlign: "right",
  },
};

export default caseHeaderStyle;
