import React from "react";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  overlay: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "auto",
    background: "#33333380",
    zIndex: 1200,
  },
}));

const Overlay = (props) => {
  const classes = useStyles(props);
  return <div className={classes.overlay} />;
};

export default Overlay;
