export const prosecutionTranslations = {
  label: "Zuständige Staatsanwaltschaft",
  name: {
    label: "Name Staatsanwaltschaft",
  },
  department: {
    label: "Name Staatsanwaltschaft Zeile 2",
  },
  subDepartment: {
    label: "Name Staatsanwaltschaft Zeile 3",
  },
  telephone: {
    label: "Telefon Staatsanwaltschaft",
  },
  fax: {
    label: "Fax Staatsanwaltschaft",
  },
  beaSafeId: {
    label: "beA-Adresse",
  },
  address: {
    streetAddress: {
      label: "Straße und Nr Staatsanwaltschaft",
    },
    postalCode: {
      label: "PLZ Staatsanwaltschaft",
    },
    addressLocality: {
      label: "Ort Staatsanwaltschaft",
    },
    postOfficeBoxNumber: {
      label: "Postfach",
    },
  },
};
