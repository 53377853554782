import React, { useState } from "react";
import * as PropTypes from "prop-types";
import useStyles from "./caseHistoryFilterStyle";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import moment from "moment";
import DateRangePicker from "../../DateRangePicker/DateRangePicker";
import { ButtonGroup } from "@mui/material";
import _ from "lodash";
import { Moment } from "moment";

// TODO this filter should instead be an object like { filterBy: "date" | "type"; dateRange?: { startDate: Moment; endDate: Moment }; type?: string }
// this is not a fast refactoring because the implementation carries across a handful of files

export type PolymorphousTimespanFilter =
  | string
  | {
      startDate: Moment;
      endDate: Moment;
    };

const CaseHistoryFilter = ({
  filter,
  handleFilterChange,
}: {
  filter: PolymorphousTimespanFilter;
  handleFilterChange: (filter: PolymorphousTimespanFilter) => void;
}) => {
  const classes = useStyles({});
  const [dateRangePickerOpen, setDateRangePickerOpen] = useState(false);

  const buttons = [
    {
      label: "Alles",
      filterName: "all",
    },
    {
      label: "Aktivität",
      filterName: "activity",
    },
    {
      label: "Notiz",
      filterName: "note",
    },
    {
      label: "E-Mail",
      filterName: "emailMessage",
    },
    {
      label: "Nachricht",
      filterName: "message",
    },
    {
      label: "Dokument",
      filterName: "mediaObject",
    },
    {
      label: "Änderung",
      filterName: "changes",
    },
    {
      label: "Aufgabe",
      filterName: "paidTask",
    },
    {
      label: "Anfrage",
      filterName: "inquiryToCustomer",
    },
  ];

  return (
    <Grid container justifyContent="space-between">
      <ButtonGroup color="primary">
        {_.map(buttons, (button) => (
          <Button
            key={button.filterName}
            variant={filter === button.filterName ? "contained" : "outlined"}
            onClick={() => handleFilterChange(button.filterName)}
          >
            {button.label}
          </Button>
        ))}
      </ButtonGroup>
      <Button
        className={classes.timespanButton}
        variant={typeof filter === "object" ? "contained" : "text"}
        onClick={() => setDateRangePickerOpen(true)}
      >
        {typeof filter === "object"
          ? moment(filter.startDate).format("DD.MM.YY") + " - " + moment(filter.endDate).format("DD.MM.YY")
          : "Zeitraum auswählen"}
      </Button>
      <DateRangePicker
        setDateRange={handleFilterChange}
        open={dateRangePickerOpen}
        closeModal={() => setDateRangePickerOpen(false)}
      />
    </Grid>
  );
};

CaseHistoryFilter.propTypes = {
  filter: PropTypes.any.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
};

export default CaseHistoryFilter;
