import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  status: {
    flexBasis: 0,
    flexGrow: 1,
    height: 30,
    backgroundColor: "#d6d6d6",
    margin: "0 5px 0 0",
    padding: "0.25rem",
    textAlign: "center",
  },
  clickable: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      boxShadow: "0px 0px 10px 0px rgba(23,107,103,1)",
      cursor: "pointer",
    },
  },
  activeStatus: {
    backgroundColor: "#a1ccca",
  },
  activeStatusLost: {
    backgroundColor: "#f4c3c7",
  },
  inActiveStatus: {
    backgroundColor: "#f2f2f2",
  },
  statusBars: {
    whiteSpace: "nowrap",
    margin: "1rem 0 1rem 0",
    display: "flex",
  },
  subBarInfo: {
    margin: "1rem 0rem",
    fontSize: "1rem",
  },
}));

export default useStyles;
