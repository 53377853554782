import React, { useEffect, useMemo, useState } from "react";
import ContentBox from "../ContentBox/ContentBox";
import ApiClient from "../../services/ApiClient";
import LegalbirdLoader from "../ContentLoader/LegalbirdLoader";
import { Divider, List, ListItem, Typography } from "@mui/material";
import _ from "lodash";
import ReleaseUpdate from "./ReleaseUpdate";
import { ReleaseUpdate as ReleaseUpdateType } from "../../types/ReleaseUpdate";
import moment from "moment";
import { userHasOneOfTheseRoles } from "../../services/backofficeUserService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

export default function ReleaseUpdates() {
  const [releaseUpdates, setReleaseUpdates] = useState<Array<ReleaseUpdateType>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useCurrentUser();

  useEffect(() => {
    fetchReleaseUpdates();
  }, []);

  const fetchReleaseUpdates = async () => {
    setIsLoading(true);
    const result = await ApiClient.get(
      "release_updates?created[after]=" + moment().subtract(2, "months").format("YYYY-MM-DD")
    );
    setReleaseUpdates(result["hydra:member"]);
    setIsLoading(false);
  };

  const filteredReleaseUpdates = useMemo(
    () =>
      _.filter(releaseUpdates, (releaseUpdate) => userHasOneOfTheseRoles(currentUser, releaseUpdate.visibleToRoles)),
    [currentUser, releaseUpdates]
  );

  if (isLoading) {
    return <LegalbirdLoader centered />;
  }

  return (
    <ContentBox>
      {filteredReleaseUpdates.length < 1 && (
        <Typography sx={{ textAlign: "center" }}>In den letzten 2 Monaten gab es kein Release Update</Typography>
      )}
      <List>
        {_.map(filteredReleaseUpdates, (releaseUpdate, index) => (
          <React.Fragment key={releaseUpdate.id}>
            <ListItem sx={{ display: "block", margin: "1rem 0" }}>
              <ReleaseUpdate releaseUpdate={releaseUpdate} fetchReleaseUpdates={fetchReleaseUpdates} />
            </ListItem>
            {index !== filteredReleaseUpdates.length - 1 && <Divider variant="inset" component="li" />}
          </React.Fragment>
        ))}
      </List>
    </ContentBox>
  );
}
