import customerTranslations from "./customerTranslations";
import basicCaseTranslations from "./basicCaseTranslations";
import contractClaimTranslations from "./contractClaimTranslations";
import mobileProviders from "../mobileProviders";

function getMobileProviderTranslations() {
  const mobileProviderTranslations = {};

  mobileProviders.forEach((provider) => {
    mobileProviderTranslations[provider.value] = provider.label;
  });

  return mobileProviderTranslations;
}

const providerContractTranslations = {
  ...basicCaseTranslations,
  ...contractClaimTranslations,
  label: "Handy / DSL / Strom / Gas-Vertrag",
  providerType: {
    label: "Art des Anbieters",
    values: {
      mobile: "Mobilfunk",
      dsl: "DSL/Internet",
      electricity: "Strom",
      gas: "Gas",
      other: "Anderer Anbieter",
    },
  },
  reasonForDispute: {
    label: "Problem mit Anbieter",
    values: {
      extraOptionGas: "Von Gasumlage betroffen",
      cancellationByProvider: "Vertrag wurde von Anbieter gekündigt",
      priceIncrease: "Anbieter hat die Preise erhöht",
      serviceNotAsAgreed: "Leistung wird von Anbieter nicht erbracht",
      cancellationRefused: "Meine Kündigung wird nicht akzeptiert",
      invoiceIncorrect: "Fehlerhafte Rechnung erhalten",
      schufaPositiveData: "Daten-Weitergabe an Schufa",
      other: "Anderes Problem oder Frage",
    },
  },
  mobileProviderOrganizationId: {
    label: "Ausgewählter Mobilfunkanbieter",
    values: getMobileProviderTranslations(),
  },
  salesChannel: {
    label: "Wo Vertrag abgeschlossen?",
    values: {
      providerWebsite: "Website des Anbieters",
      comparisonPortal: "Vergleichsportal",
      localStore: "Vor Ort im Laden",
      phone: "Per Telefon",
      other: "Auf anderem Wege",
    },
  },
  contractSignedBy: {
    label: "Vertrag geschlossen durch",
    values: {
      client: "Mandant",
      landlord: "Vermieter",
    },
  },
  extraOptionGasNotification: {
    label: "Ankündigung Gasumlage am",
  },
  contractHasPriceFixing: {
    label: "Preisbindung/Garantie",
    values: {
      yes: "Ja",
      no: "Nein",
      unknown: "Unbekannt",
    },
  },
  contractNumber: {
    label: "Kundennummer",
  },
  cancellationDate: {
    label: "Kündigungsdatum",
  },
  cancellationReceivedDate: {
    label: "Eingang der Kündigung",
  },
  endOfContractDate: {
    label: "Datum Vertragsende",
  },
  schufaDataCopyDate: {
    label: "Schufa-Auskunft erhalten am",
  },
  opponentReactionToCancellation: {
    label: "Reaktion Gegenseite auf Kündigung",
    values: {
      noReaction: "Keine Reaktion",
      cancellationRefused: "Kündigung abgelehnt",
      differentDate: "Anderes Kündigungsdatum/Verlängerung",
    },
  },
  endOfContractDateOpponent: {
    label: "Vertragsende laut Gegenseite",
  },
  priceIncreaseAnnouncementDate: {
    label: "Datum Ankündigung Preiserhöhung",
  },
  specialTerminationDate: {
    label: "Sonderkündigungsdatum",
  },
  customer: customerTranslations,
};

export default providerContractTranslations;
