import React, { useMemo } from "react";
import humanResourceManagementStyle from "./humanResourceManagementStyle";
import { Grid, MenuItem, Typography } from "@mui/material";
import _ from "lodash";

import { translate } from "../../services/Translations/translatorService";
import { Calendar, DateObject } from "react-multi-date-picker";
import { formValue } from "../../services/formServiceFunctions";
import ValidatorSelect from "../Validator/ValidatorSelect";
import useStyles from "./calendarStyle";
import DailyWorkHours from "../../types/DailyWorkHours";

type UserPresenceConfiguratorFormElementsProps = {
  values: Record<string, any>;
  handleChange: Function;
  handleBlur: Function;
};

const UserPresenceConfiguratorFormElements = ({
  values,
  handleChange,
  handleBlur,
}: UserPresenceConfiguratorFormElementsProps) => {
  const calendarClasses = useStyles();
  const timeSlots = useMemo(() => {
    const startTime = 7;
    const endTime = 20;

    let slots = [];
    for (let i = startTime; i <= endTime; i++) {
      const timeSlotFullHour = i.toLocaleString("de-DE", { minimumIntegerDigits: 2 }) + ":00";
      const timeSlotHalfHour = i.toLocaleString("de-DE", { minimumIntegerDigits: 2 }) + ":30";

      slots.push(
        <MenuItem key={timeSlotFullHour} value={timeSlotFullHour}>
          {timeSlotFullHour} Uhr
        </MenuItem>
      );

      if (i < endTime) {
        slots.push(
          <MenuItem key={timeSlotHalfHour} value={timeSlotHalfHour}>
            {timeSlotHalfHour} Uhr
          </MenuItem>
        );
      }
    }
    return slots;
  }, []);

  function timeStringToHours(timeString: string): number {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours + minutes / 60;
  }

  const weeklyWorkHours = Object.keys(values.workingHours).reduce((acc, day) => {
    return acc + getDailyWorkHours(day).hoursExcludingBreak;
  }, 0);

  function getDailyWorkHours(dayName: string): DailyWorkHours {
    const hoursIncludingBreak =
      timeStringToHours(formValue(values, "workingHours." + dayName + ".end")) -
      timeStringToHours(formValue(values, "workingHours." + dayName + ".start"));
    const breakDuration = hoursIncludingBreak >= 9 ? 0.75 : 0.5;
    const hoursExcludingBreak = hoursIncludingBreak - breakDuration;
    return {
      hoursExcludingBreak: hoursExcludingBreak,
      hoursIncludingBreak: hoursIncludingBreak,
      breakHours: breakDuration,
    };
  }

  return (
    <Grid container justifyContent={"center"} spacing={2}>
      <Grid item md={6} xs={12}>
        <Typography variant="h3" sx={humanResourceManagementStyle.subHeadline}>
          Abwesenheit eintragen
        </Typography>
        <Calendar
          className={calendarClasses.calender}
          value={formValue(values, "blockedDays")}
          onChange={(dates) =>
            handleChange({
              target: {
                name: "blockedDays",
                value: dates,
              },
            })
          }
          currentDate={new DateObject()}
          showOtherDays
          weekDays={["S", "M", "D", "M", "D", "F", "S"]}
          months={[
            "Januar",
            "Februar",
            "März",
            "April",
            "Mai",
            "Juni",
            "Juli",
            "August",
            "September",
            "Oktober",
            "November",
            "Dezember",
          ]}
          mapDays={({ date }) => {
            const isWeekend = _.includes([0, 6], date.weekDay.index);
            if (isWeekend) {
              return {
                disabled: true,
              };
            }
          }}
        />
      </Grid>
      <Grid item xs={12} justifyContent={"center"} alignItems={"center"}>
        <Typography variant="h3" sx={humanResourceManagementStyle.subHeadline}>
          Arbeitszeit eintragen
        </Typography>
        <Grid container>
          {_.map(values.workingHours, (workingHourTimes, workingHourDay) => {
            const workHours = getDailyWorkHours(workingHourDay);
            return (
              <React.Fragment key={workingHourDay}>
                <Grid item xs={4} sx={humanResourceManagementStyle.workTimeSelectGridItem}>
                  <ValidatorSelect
                    label={"Start " + translate("general.dateTime." + workingHourDay, workingHourDay)}
                    name={"workingHours." + workingHourDay + ".start"}
                    value={formValue(values, "workingHours." + workingHourDay + ".start")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {timeSlots}
                  </ValidatorSelect>
                </Grid>
                <Grid item xs={4} sx={humanResourceManagementStyle.workTimeSelectGridItem}>
                  <ValidatorSelect
                    label={"Ende " + translate("general.dateTime." + workingHourDay, workingHourDay)}
                    name={"workingHours." + workingHourDay + ".end"}
                    value={formValue(values, "workingHours." + workingHourDay + ".end")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {timeSlots}
                  </ValidatorSelect>
                </Grid>

                <Grid item xs={4} display={"flex"} justifyContent="center" alignItems="center">
                  <Typography variant={"h5"}>
                    {workHours.hoursIncludingBreak.toLocaleString("de-DE")} Std. - {workHours.breakHours * 60} Min.
                    Pause
                    <br />
                    <strong>= {workHours.hoursExcludingBreak.toLocaleString("de-DE")} Std.</strong>
                  </Typography>
                </Grid>
              </React.Fragment>
            );
          })}
          <Grid item xs={8}>
            {" "}
          </Grid>
          <Grid item xs={4} sx={{ paddingLeft: "2.5rem" }}>
            <Typography fontWeight={"bold"} variant={"h5"}>
              <>
                Arbeitszeit ={" "}
                {!!values?.workingHours && weeklyWorkHours.toLocaleString("de-DE", { minimumFractionDigits: 2 })} Std.
              </>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserPresenceConfiguratorFormElements;
