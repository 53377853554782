import React from "react";
import PageContentContainer from "../../Container/PageContentContainer";
import useStylesFileGeneratePage from "./caseFileGeneratePageStyle";
import CaseFileGenerateForm from "../../CaseFileGenerate/CaseFileGenerateForm";
import CaseInvoiceGenerateForm from "../../CaseFileGenerate/CaseInvoiceGenerateForm";
import CasePdfGenerator from "../../Case/CasePdfGenerator/CasePdfGenerator";
import CaseRelatedActions from "../../Case/CaseRelatedActions/CaseRelatedActions";
import CaseNotFound from "../../Case/CaseNotFound/CaseNotFound";
import { useParams } from "react-router-dom";
import { ProductHeadline } from "../../PageHeadline/PageHeadline";
import CaseProvider, { useCase } from "../../../provider/CaseProvider";
import LegalBirdPageLoading from "../../ContentLoader/LegalBirdPageLoading";

const CaseFileGeneratePage = () => {
  const { productId, productUrlPart } = useParams();
  return (
    <CaseProvider caseUrlPart={productUrlPart} caseId={productId}>
      <CaseFileGeneratePageInner />
    </CaseProvider>
  );
};

const CaseFileGeneratePageInner = () => {
  const { product, isLoading, error, refreshCase } = useCase();
  const classes = useStylesFileGeneratePage();

  if (isLoading) {
    return <LegalBirdPageLoading />;
  }

  if (error?.status === 404 || product?.customer?.isFake) {
    return <CaseNotFound />;
  }

  return (
    <>
      <CaseRelatedActions product={product} refreshPage={refreshCase} />
      <PageContentContainer size={"small"}>
        <ProductHeadline title="Schriftsatz erstellen für" product={product} />
        <CaseFileGenerateForm product={product} />
        <CaseInvoiceGenerateForm product={product} />
        <CasePdfGenerator product={product} />
      </PageContentContainer>
    </>
  );
};

export default CaseFileGeneratePage;
