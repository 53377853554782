import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { HashLink as Link } from "react-router-hash-link";
import { Toolbar } from "@mui/material";
import { getCaseLink } from "../../services/Product/ProductService";
import _ from "lodash";
import ButtonLoading from "../Button/ButtonLoading";
import { API_HOST, API_PATH } from "../../config/_entrypoint";
import { loadToken } from "../../services/localStorage";
import { APPLICATION_STATUS_CHECKED, LEAD_STATUS_CHECKED } from "../../services/LeadStatus/StatusProcessCostSupport";
import { alimonyClaimsAmount } from "../../services/formServiceFunctions";
import navigationBarStyle from "./navigationBarStyle";
import makeStyles from "@mui/styles/makeStyles";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../services/ReactQuery/reactQueryService";

const useStyles = makeStyles(navigationBarStyle);

const NavigationExtensionProcessCostSupport = ({processCostSupport}) => {
  const [isLoading, setIsLoading] = useState(false);
  const updateMutation = useMutation(updateResource);
  const classes = useStyles({});
  if (!processCostSupport) {
    return null;
  }

  const setStatusComplete = async () => {
    setIsLoading(true);
    await updateMutation.mutateAsync({
      uri: "process_cost_supports",
      id: processCostSupport.id,
      data: {
        applicationStatus: APPLICATION_STATUS_CHECKED,
      },
    });
    setIsLoading(false);
  };

  const isProcessCostSupportComplete = () => {
    if (!processCostSupport.signature) {
      return false;
    }

    let requiredReceipts = [];

    if (processCostSupport.isCostCovered) {
      requiredReceipts.push("pcs_receipt_b");
    }

    if (processCostSupport.hasAlimonyClaims) {
      requiredReceipts.push("pcs_receipt_c");
    }

    if (processCostSupport.hasRelativesAlimonyClaims && alimonyClaimsAmount(processCostSupport) > 0) {
      requiredReceipts.push("pcs_receipt_d");
    }

    if (processCostSupport.hasSocialWelfare) {
      requiredReceipts.push("pcs_receipt_social_welfare");
    }

    if (processCostSupport.hasIncomeEmployment) {
      requiredReceipts.push("pcs_receipt_e1");
    }

    if (processCostSupport.hasIncomeSelfEmployment) {
      requiredReceipts.push("pcs_receipt_e2");
    }

    if (processCostSupport.hasIncomeRent) {
      requiredReceipts.push("pcs_receipt_e3");
    }

    if (processCostSupport.hasIncomeAssets) {
      requiredReceipts.push("pcs_receipt_e4");
    }

    if (processCostSupport.hasIncomeLiving) {
      requiredReceipts.push("pcs_eceipt_e6");
    }

    if (processCostSupport.hasIncomePension) {
      requiredReceipts.push("pcs_receipt_e8");
    }

    if (processCostSupport.hasIncomeUnemploymentAllowance) {
      requiredReceipts.push("pcs_receipt_e9");
    }

    if (processCostSupport.hasIncomeUnemploymentAllowance2) {
      requiredReceipts.push("pcs_receipt_e10");
    }

    if (processCostSupport.hasIncomeSickness) {
      requiredReceipts.push("pcs_receipt_e11");
    }

    if (processCostSupport.hasIncomeParentalAllowance) {
      requiredReceipts.push("pcs_receipt_e12");
    }

    if (processCostSupport.hasIncomeOther1) {
      requiredReceipts.push("pcs_receipt_e13");
    }

    if (processCostSupport.hasIncomeOther2) {
      requiredReceipts.push("pcs_receipt_e14");
    }

    if (processCostSupport.hasOtherInsuranceDeduction) {
      requiredReceipts.push("pcs_receipt_f3");
    }

    if (processCostSupport.hasMobilityCostDeduction && processCostSupport.mobilityCostUnit !== "km") {
      requiredReceipts.push("pcs_receipt_f4");
    }

    if (processCostSupport.hasOtherExpensesDeduction) {
      requiredReceipts.push("pcs_receipt_f5");
    }

    if (processCostSupport.hasIncomeLiving === false) {
      requiredReceipts.push("pcs_receipt_h1");
      if (processCostSupport.hasPropertyLoans) {
        _.forEach(processCostSupport.propertyLoans, (loan, key) => {
          requiredReceipts.push("pcs_receipt_h6" + (key + 1));
        });
      }
    }

    if (processCostSupport.hasOtherPaymentObligations) {
      _.forEach(processCostSupport.paymentObligations, (obligation, key) => {
        requiredReceipts.push("pcs_receipt_i" + (key + 1));
      });
    }

    if (processCostSupport.hasSpecialFinancialBurdens) {
      _.forEach(processCostSupport.specialFinancialBurdens, (burden, key) => {
        requiredReceipts.push("pcs_receipt_j" + (key + 1));
      });
    }

    if (processCostSupport.hasBankAccount) {
      _.forEach(processCostSupport.bankAccounts, (account, key) => {
        requiredReceipts.push("pcs_receipt_g1" + (key + 1));
      });
    }

    if (processCostSupport.hasPensionInsurance) {
      requiredReceipts.push("pcs_receipt_g5");
    }

    if (processCostSupport.hasOtherAssets) {
      requiredReceipts.push("pcs_receipt_g6");
    }

    if (processCostSupport.hasIncomeLiving === false) {
      requiredReceipts.push("pcs_receipt_h1");
    }

    let isValid = true;
    _.forEach(requiredReceipts, (receipt) => {
      if (!processCostSupport.receipts[receipt] || processCostSupport.receipts[receipt].length < 1) {
        isValid = false;
      }
    });
    return isValid;
  };

  return (
    <Toolbar className={classes.naviSeperation}>
      <Grid container justifyContent={"space-between"} alignItems={"center"} className={classes.subNaviContainer}>
        <Grid item xs={2}>
          <Button
            component={"a"}
            href={API_HOST + API_PATH + "download/pdf/pcs/" + processCostSupport.id + "?token=" + loadToken()}
            target={"_blank"}
          >
            Entwurf anzeigen
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button component={Link} to={"#personalData"}>
            Angaben prüfen
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button component={Link} to={"#receipts"}>
            Belege prüfen
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            component={Link}
            to={getCaseLink({ productClassName: "divorce", id: _.get(processCostSupport, "divorce.id") })}
          >
            Fall anzeigen
          </Button>
        </Grid>
        <Grid item xs={3}>
          <ButtonLoading
            isLoading={isLoading}
            variant={"contained"}
            onClick={setStatusComplete}
            disabled={processCostSupport.leadStatus >= LEAD_STATUS_CHECKED || !isProcessCostSupportComplete()}
          >
            Antrag einreichbar
          </ButtonLoading>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default NavigationExtensionProcessCostSupport;
