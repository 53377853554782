import React, {useEffect, useRef, useState} from "react";
import * as PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import useStyles from "./validatorStyle";
import {Button} from "@mui/material";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";

// use ref, make props
const ExpandableTextField = ({
                              isMandatory,
                              name,
                              value,
                              registerValidators,
                              validators,
                              dependentValidationFields,
                              label,
                              maxRows,
                              buttonLabel,
                              ...rest
                            }) => {
  const classes = useStyles();
  useEffect(() => {
    registerValidators && validators && registerValidators(name, validators, dependentValidationFields);
    return () => {
      registerValidators && registerValidators(name, []);
    }
  }, [registerValidators, dependentValidationFields, name]);
  const [showShowFullTextButton, setShowShowFullTextButton] = useState(false);
  const [showFullTextModal, setShowFullTextModal] = useState(false);
  const inputRef = useRef()

  useEffect(() => {
    if (inputRef.current) {
      const scrollHeight = inputRef.current.scrollHeight;
      const clientHeight = inputRef.current.clientHeight;
      setShowShowFullTextButton(scrollHeight > clientHeight);
    }
  });

  return (
    <>
      <TextField
        inputRef={inputRef}
        {...rest}
        name={name}
        value={value}
        label={label}
        className={isMandatory && (value === null || value === "") ? classes.mandatory : null}
        maxRows={maxRows}
      />
      {showShowFullTextButton ? (
        <Button onClick={() => setShowFullTextModal(true)}>{buttonLabel}</Button>
      ) : null}
      <LegalbirdIoModal
        handleClose={() => setShowFullTextModal(false)}
        open={showFullTextModal}
        maxWidth={"xl"}
        hasActions={false}
        hideCancelButton={true}
        title={label}
      >
        <div style={{whiteSpace: "pre-line"}}>{value}</div>
      </LegalbirdIoModal>
    </>
  );
};

ExpandableTextField.propTypes = {
  validators: PropTypes.arrayOf(PropTypes.object),
  dependentValidationFields: PropTypes.array,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  registerValidators: PropTypes.func,
  isMandatory: PropTypes.bool,
  buttonLabel: PropTypes.string,
  maxRows:PropTypes.number
};

ExpandableTextField.defaultProps = {
  isMandatory: false,
  dependentValidationFields: [],
  buttonLabel: "Text in neuem Fenster anzeigen",
  maxRows: 15
};

export default ExpandableTextField;
