import { InputAdornment } from "@mui/material";
import React from "react";
import { buildPaths } from "./organizationFields";
import {
  beaSafeIdFieldDefault, faxNumberDefault,
  isPhoneNumberWithoutSpecialCharactersDefault,
  requiredFieldDefault
} from "../../../validationRules";

export function prosecutionFields({ path = "", translationPrefix = "" }: { path?: string; translationPrefix?: string; }) {
  const {dataPath, translationPath} = buildPaths(path, translationPrefix);
  return [
    {
      type: "ValidatorTextField",
      path: `${dataPath}name`,
      translationPath: `${translationPath}name`,
      validators: requiredFieldDefault,
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}department`,
      translationPath: `${translationPath}department`,
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}subDepartment`,
      translationPath: `${translationPath}subDepartment`,
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}address.streetAddress`,
      translationPath: `${translationPath}address.streetAddress`,
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}address.postalCode`,
      translationPath: `${translationPath}address.postalCode`,
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}address.addressLocality`,
      translationPath: `${translationPath}address.addressLocality`,
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}address.postOfficeBoxNumber`,
      translationPath: `${translationPath}address.postOfficeBoxNumber`,
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}telephone`,
      translationPath: `${translationPath}telephone`,
      validators: isPhoneNumberWithoutSpecialCharactersDefault
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}beaSafeId`,
      translationPath: `${translationPath}beaSafeId`,
      validators: beaSafeIdFieldDefault,
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}fax`,
      translationPath: `${translationPath}fax`,
      validators: faxNumberDefault,
      additionalProps: {
        InputProps: {
          startAdornment: <InputAdornment position="start">+49</InputAdornment>,
        },
      },
    },
  ];
}