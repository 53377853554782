import React from "react";
import {Box, Link, Toolbar} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import BirdIcon from "../../assets/icon/legalbird.svg";

const useStyles = makeStyles(() => ({
  navBarIcon: {
    height: 40,
    width: "auto",
  },
  iconContainer: {
    textAlign: "center",
  },
}));

const NavigationBarBrand = (props) => {
  const classes = useStyles(props);

  return <>
    <AppBar position={"sticky"} color={"inherit"}>
      <Toolbar>
        <Grid container alignItems={"center"} justifyContent={"center"}>
          <Grid item xs={12} className={classes.iconContainer}>
            <Link href={"/"}>
            <Box component={"img"} className={classes.navBarIcon} src={BirdIcon} alt={"Legalbird"}/>
            </Link>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  </>;
};

export default NavigationBarBrand;
