import _ from "lodash";

const signatureData = {
  legalbirdLogoUrl: "https://www.legalbird.io/img/legalbird_logo_rechtsanwaelte.png",
  brandName: "Legalbird",
  companyName: "Legalbird Rechtsanwalts UG (haftungsbeschränkt)",
  address: "Anna-Schneider-Steig 5 | 50678 Köln",
  telephone: "Tel. 0221 98651940",
  email: "service@legalbird.de",
  fax: "Fax 0221 98651949",
  executiveBoard: "Geschäftsführer: Frank Riedel, Anna Wagner",
  registeredAt: "Amtsgericht Köln HRB 97467 | USt Ident Nr.: DE324640771",
};

export const getEmailSignature = (backofficeUser, product, onlyComplimentaryClose) => {

  let titles = "";
  if (backofficeUser.person.titles) {
    titles = _.reduce(
      backofficeUser.person.titles,
      (result, value) => {
        if (value.product === product?.productCategory || value.product === "all") {
          return result + value.fullTitle + "<br/>";
        }
        return result;
      },
      "<br/>"
    );
  }

  let signature =
    `<p>Mit freundlichen Grüßen</p>` + `<p><strong>${backofficeUser.person.fullname}</strong>${titles}</p>`;

  if (onlyComplimentaryClose) {
    return signature;
  }

  signature =
    signature +
    `<p>` +
    `<strong>${signatureData.brandName}</strong><br/>` +
    `${signatureData.companyName && signatureData.companyName + "<br/>"}` +
    `${signatureData.address}<br/>` +
    `${signatureData.email}<br/>` +
    `${signatureData.executiveBoard}<br/>` +
    `${signatureData.registeredAt}` +
    `</p>`;

  return signature;
};
