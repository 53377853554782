import React, { useState } from "react";
import * as PropTypes from "prop-types";
import useStyles from "./doneActivityStyle";
import { EditOutlined } from "@mui/icons-material";
import _ from "lodash";
import moment from "moment";
import { Grid, Hidden, IconButton } from "@mui/material";
import { getActivityIcon } from "../../services/Activities/activityTypes";
import CircleIcon from "../CircleIcon/CircleIcon";
import EditActivity from "./EditActivity/EditActivity";
import ActivityNote from "./ActivityNote";
import CalendlyAssessmentActivity from "../CalendlyAssessments/CalendlyAssessmentActivity";
import { useBackofficeUser } from "../../provider/BackofficeUserProvider";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

const DoneActivity = ({ activity, productClassName, updateActivities }) => {
  const classes = useStyles({});
  const { getUserData } = useBackofficeUser();
  const [editOpen, setEditOpen] = useState(false);
  const currentUser = useCurrentUser();

  const dateString = moment(activity.dueDate).format("DD.MM.YYYY");
  const timeString = activity.dueTime !== "allDay" ? "um " + activity.dueTime + " Uhr" : "";
  const assignedUser = getUserData(activity.assignedUser);
  const assignedUserName = assignedUser && assignedUser.person.fullname;
  const subject =
    activity.subject +
    " " +
    dateString +
    (timeString ? " " + timeString : "") +
    " | " +
    (assignedUserName || "Nicht zugewiesen");

  const DoneEntry = ({ activity }) => {
    let timedLog = _.merge({}, activity.activityLog);
    _.forEach(timedLog, (logEntry, key) => {
      logEntry.logDateTime = key;
    });
    const doneLogEntry = _.head(_.filter(timedLog, "done"));
    if (!doneLogEntry) {
      if (!!activity.doneTime) {
        return (
          <div>
            Erledigt: {moment(activity.doneTime).format("DD.MM.YYYY HH:mm")} Uhr | {assignedUserName || "System"}
          </div>
        );
      }
      return null;
    }

    return (
      <div>
        Erledigt: {moment(doneLogEntry.logDateTime).format("DD.MM.YYYY HH:mm")} Uhr | {doneLogEntry.updatedBy}
      </div>
    );
  };

  return (
    <div className={classes.outerActivityContainer}>
      <Grid container className={classes.activityContainer}>
        <Hidden xsDown>
          <Grid className={classes.imageGridItem} item>
            <CircleIcon icon={getActivityIcon(productClassName, activity.type)} type={"muiIcon"} />
          </Grid>
        </Hidden>
        <Grid item className={classes.activityContent}>
          <Grid container alignItems={"center"} wrap={"nowrap"}>
            <Grid item className={classes.headline}>
              {subject}
            </Grid>
            <Grid item className={classes.activityActions}>
              {!currentUser.isExternal && (
                <IconButton onClick={() => setEditOpen(true)} size="large">
                  <EditOutlined />
                </IconButton>
              )}
            </Grid>
          </Grid>
          <DoneEntry activity={activity} />
        </Grid>
      </Grid>
      <ActivityNote noteContent={activity.note} />
      <EditActivity
        key={activity.id}
        activity={activity}
        open={editOpen}
        closeDialog={() => setEditOpen(false)}
        updateActivities={updateActivities}
      />
      <CalendlyAssessmentActivity activity={activity} />
    </div>
  );
};

DoneActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  productClassName: PropTypes.string.isRequired,
  updateActivities: PropTypes.func,
};

export default DoneActivity;
