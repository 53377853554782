import React from "react";
import { Box, IconButton, Stack } from "@mui/material";
import { BeaAttachment } from "../../types/Bea/BeaAttachment";
import { Close } from "@mui/icons-material";
import BeaDroppable from "./BeaDroppable";

type BeaCombine = {
  combinable: Array<BeaAttachment>;
  removeCombinable: Function;
  index: number;
};
export default function BeaCombinable({ combinable, index, removeCombinable }: BeaCombine) {
  return (
    <Box>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <strong>Erstelltes Dokument {index + 1}</strong>
        <IconButton disabled={combinable.length > 0} onClick={() => removeCombinable(index)}>
          <Close />
        </IconButton>
      </Stack>
      <BeaDroppable combinable={combinable} index={index} />
    </Box>
  );
}
