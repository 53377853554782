import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import ButtonLoading from "../Button/ButtonLoading";
import { useSnackbar } from "notistack";
import Snackbar from "../Snackbar/Snackbar";
import ApiClient from "../../services/ApiClient";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

export default function ChangePassword() {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();

  const handleNewPassword = () => {
    setIsLoading(true);

    ApiClient.post("reset_user_password", { body: JSON.stringify({ email: currentUser.email }) })
      .then(() => {
        enqueueSnackbar("", {
          content: () => (
            <div>
              <Snackbar
                message={"Wir haben Ihnen eine E-Mail zum Ändern Ihres Passworts geschickt."}
                isNonInteractive
              />
            </div>
          ),
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Stack alignItems={"center"} justifyContent={"center"} spacing={2}>
      <Typography>
        <strong>Sie möchten Ihr Passwort ändern?</strong>
      </Typography>
      <Typography>Fordern Sie hier ganz einfach eine E-Mail an, um ein neues Passwort zu wählen.</Typography>
      <ButtonLoading onClick={handleNewPassword} isLoading={isLoading} variant={"contained"} sx={{ width: "200px" }}>
        E-Mail anfordern
      </ButtonLoading>
    </Stack>
  );
}
