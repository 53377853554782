import React, { useState } from "react";
import { Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import { UnfoldLess, UnfoldMore } from "@mui/icons-material";
import classNames from "classnames";
import PopUpMenu from "../../PopUpMenu/PopUpMenu";
import MenuItem from "@mui/material/MenuItem";
import EditNote from "../CaseCreateNote/EditNote";
import LegalbirdIoConfirm from "../../Modal/LegalbirdIoConfirm";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../services/ReactQuery/reactQueryService";

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: "#f8f4d0",
    width: "100%",
    padding: "2rem",
    position: "relative",
  },
  warning: {
    backgroundColor: "#f9d0d0",
  },
  info: {
    flexGrow: 1,
    fontWeight: 700,
    fontSize: "1rem",
  },
  note: {
    marginTop: "1rem",
    fontSize: "1rem",
    overflow: "hidden",
    minHeight: "2rem",
  },
  collapsedNote: {
    height: "2rem",
  },
  icon: {
    color: "#000",
  },
  lastChange: {
    fontSize: ".75rem",
    zIndex: 2,
    textAlign: "right",
    position: "relative",
  },
  overlay: {
    backgroundColor: "#FFFFFF99",
    zIndex: 1,
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },
}));

const Note = ({ note, handleChange, startsCollapsed, product, isEditable }) => {
  const classes = useStyles({});
  const updateMutation = useMutation(updateResource);
  const [noteFolded, setNoteFolded] = useState(startsCollapsed);
  const [editNoteOpen, setEditNoteOpen] = useState(false);
  const [deleteNoteOpen, setDeleteNoteOpen] = useState(false);

  const unpinNote = async () => {
    await updateMutation.mutateAsync({ id: note.id, uri: "notes", data: { pinned: false } });
    handleChange();
  };

  const pinNote = async () => {
    await updateMutation.mutateAsync({ id: note.id, uri: "notes", data: { pinned: true } });
    handleChange();
  };

  const deleteNote = async () => {
    await updateMutation.mutateAsync({ id: note.id, uri: "notes", data: { deleted: true, pinned: false } });
    handleChange();
  };

  const closeDialog = () => {
    setEditNoteOpen(false);
    handleChange();
  };

  return (
    <Paper className={classNames(classes.paper, classes.caseOverviewPage, note.isWarning ? classes.warning : null)}>
      <Grid container>
        <Grid item className={classes.info}>
          {note.author ? note.author.person.fullname : "System"} | {moment(note.created).format("DD.MM.YYYY")} |{" "}
          {moment(note.created).format("HH:mm")}
        </Grid>
        {(note.content.length > 100 || note.content.includes("<br>") || note.content.includes("<br/>")) && (
          <Grid item>
            <IconButton onClick={() => setNoteFolded(!noteFolded)} className={classes.icon} size="large">
              {noteFolded ? <UnfoldMore /> : <UnfoldLess />}
            </IconButton>
          </Grid>
        )}
        {isEditable && (
          <Grid item>
            <PopUpMenu>
              <MenuItem onClick={() => setEditNoteOpen(true)}>Bearbeiten</MenuItem>
              <MenuItem onClick={note.pinned ? unpinNote : pinNote}>{note.pinned ? "Losheften" : "Anheften"}</MenuItem>
              <MenuItem onClick={() => setDeleteNoteOpen(true)}>Löschen</MenuItem>
            </PopUpMenu>
            <EditNote open={editNoteOpen} closeDialog={closeDialog} product={product} note={note} />
            <LegalbirdIoConfirm
              handleClose={() => setDeleteNoteOpen(false)}
              open={deleteNoteOpen}
              handleConfirm={deleteNote}
              content="Möchten Sie diese Notiz tatsächlich löschen? Dies kann nicht rückgängig gemacht werden."
              confirmText="Notiz löschen"
              headline="Notiz löschen?"
            />
          </Grid>
        )}
      </Grid>
      <div
        className={classNames(classes.note, noteFolded ? classes.collapsedNote : null)}
        dangerouslySetInnerHTML={{ __html: note.content }}
      />
      {note.deleted && <div className={classes.overlay} />}
      {note.lastTouchedBy && (
        <div className={classes.lastChange}>
          {(note.deleted ? "Gelöscht durch " : "Zuletzt geändert durch ") +
            note.lastTouchedBy.person.fullname +
            " | " +
            moment(note.updated).format("DD.MM.YYYY | HH:mm")}
        </div>
      )}
    </Paper>
  );
};

Note.propTypes = {
  note: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
  startsCollapsed: PropTypes.bool,
  isEditable: PropTypes.bool,
  product: PropTypes.object,
};

Note.defaultProps = {
  startsCollapsed: false,
  isEditable: true,
};

export default Note;
