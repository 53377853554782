import React from "react";
import * as PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import paidTaskListItemStyle from "./paidTaskListItemStyle";
import { Button, Grid } from "@mui/material";
import classNames from "classnames";
import { convertFromFloat } from "../../../services/formServiceFunctions";
import { translate } from "../../../services/Translations/translatorService";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED } from "../../../components/PaidTask/paidTaskStatus";
import { getCaseLinkByBackofficeCase } from "../../../services/Product/ProductService";
import CasePaidTaskAccept from "../../../components/CasePaidTask/CasePaidTaskAccept";

const useStyles = makeStyles(paidTaskListItemStyle);

const PaidTaskListItem = ({ paidTask, isExternalView, hideCase }) => {
  const classes = useStyles();
  const caseLink = getCaseLinkByBackofficeCase(paidTask.backofficeCase, isExternalView ? "/extern" : "");

  return (
    <Grid container spacing={1} className={classes.smallFont}>
      <Grid item xs={12} className={classNames(classes.singleLineOverflowHidden, classes.paidTaskSubject)}>
        {paidTask.subject}
      </Grid>
      <Grid item xs={9}>
        {convertFromFloat(paidTask.fee)} €
      </Grid>
      <Grid item xs={3}>
        {isExternalView ? "" : translate("paidTask.status." + paidTask.status)}
      </Grid>
      <Grid item xs={9}>
        {hideCase ? "" : paidTask.backofficeCase.label || paidTask.backofficeCase.lbProductId}
      </Grid>
      <Grid item xs={3}>
        {isExternalView ? "" : _.get(paidTask, "assignee.person.fullname", "-")}
      </Grid>
      <Grid item xs={3}>
        Ann bis.
      </Grid>
      <Grid item xs={3}>
        {moment(paidTask.assignmentDeadline).format("DD.MM.YYYY")}
      </Grid>
      <Grid item xs={3}>
        Erl bis.
      </Grid>
      <Grid item xs={3}>
        {moment(paidTask.doneDeadline).format("DD.MM.YYYY")}
      </Grid>
      <Grid item xs={9}>
        {hideCase ? (
          ""
        ) : (
          <Button
            className={classes.paidTaskButton}
            variant={"contained"}
            color={"primary"}
            fullWidth={false}
            component={Link}
            to={caseLink}
          >
            Zum Fall
          </Button>
        )}
      </Grid>
      <Grid item xs={3}>
        {isExternalView && _.includes([PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED], paidTask.status) ? (
          <CasePaidTaskAccept paidTask={paidTask} />
        ) : (
          <Button
            className={classes.paidTaskButton}
            component={Link}
            to={caseLink + "/ausschreibung/" + paidTask.id}
            variant={"contained"}
            color={"primary"}
          >
            Details
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

PaidTaskListItem.propTypes = {
  paidTask: PropTypes.object.isRequired,
  isExternalView: PropTypes.bool,
  hideCase: PropTypes.bool,
};

PaidTaskListItem.defaultProps = {};

export default PaidTaskListItem;
