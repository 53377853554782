import { Activity } from "../../../types/Activity";
import {
  Avatar,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import {
  getCaseLinkByBackofficeCase,
  getProductData,
  getProductNameBy,
} from "../../../services/Product/ProductService";
import { CommentOutlined, LocalPhoneOutlined } from "@mui/icons-material";
import { ACTIVITY_OVERDUE } from "../../../services/Stages/Stages";
import moment from "moment";

export default function ExternalAdvisoryActivities({ activities }: { activities: Activity[] }) {
  const getCellStyleByActivityState = (activity: Activity) => {
    if (activity.done) {
      return { textDecoration: "line-through" };
    }
    if (activity.activityStatus === ACTIVITY_OVERDUE) {
      return { color: "#db1919" };
    }
    return {};
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: "600px" }}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Fall</TableCell>
            <TableCell>Rechtsprodukt</TableCell>
            <TableCell>Beschreibung</TableCell>
            <TableCell>Notiz</TableCell>
            <TableCell>Datum</TableCell>
            <TableCell>Uhrzeit</TableCell>
            <TableCell>Dauer</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {activities.length === 0 && (
            <TableRow>
              <TableCell colSpan={9}>
                <Box sx={{ textAlign: "center" }}>Keine Termine gefunden</Box>
              </TableCell>
            </TableRow>
          )}
          {_.map(activities, (activity) => (
            <TableRow key={activity.id}>
              <TableCell size={"small"} padding={"checkbox"} sx={getCellStyleByActivityState(activity)}>
                <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main }}>
                  <LocalPhoneOutlined />
                </Avatar>
              </TableCell>
              <TableCell sx={getCellStyleByActivityState(activity)}>
                <Link to={getCaseLinkByBackofficeCase(activity.case, "/extern")}>
                  {activity.case?.label || activity.case?.lbProductId}
                </Link>
              </TableCell>
              <TableCell sx={getCellStyleByActivityState(activity)}>
                {getProductData(getProductNameBy("lbProductClass", activity.case?.lbProductClass), "generalLabel")}
              </TableCell>
              <TableCell sx={getCellStyleByActivityState(activity)}>{activity.subject}</TableCell>
              <TableCell>
                {!_.isEmpty(activity.note) && (
                  <Tooltip
                    placement={"right"}
                    title={<div dangerouslySetInnerHTML={{ __html: activity.note || "" }} />}
                  >
                    <CommentOutlined />
                  </Tooltip>
                )}
              </TableCell>
              <TableCell sx={getCellStyleByActivityState(activity)}>
                {moment(activity.dueDate).format("DD.MM.YYYY")}
              </TableCell>
              <TableCell sx={getCellStyleByActivityState(activity)}>{activity.dueTime}&nbsp;Uhr</TableCell>
              <TableCell sx={getCellStyleByActivityState(activity)}>{activity.duration} Minuten</TableCell>
              <TableCell sx={getCellStyleByActivityState(activity)}>
                <Button component={Link} to={getCaseLinkByBackofficeCase(activity.case, "/extern")}>
                  Zum Fall
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
