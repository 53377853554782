import PropTypes from "prop-types";
import React, { useState } from "react";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import { dateFieldDefault, relatedFieldDefault } from "../../../services/validationRules";
import moment from "moment";
import { convertToFormattedTime, formValue } from "../../../services/formServiceFunctions";
import _ from "lodash";
import { Grid } from "@mui/material";
import FormElement from "../../Case/CaseForm/FormElement";
import useForm from "../../../hooks/useForm";
import makeStyles from "@mui/styles/makeStyles";
import advoAssistPostponeModalStyle from "./advoAssistPostponeModalStyle";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../services/ReactQuery/reactQueryService";

const useStyles = makeStyles(advoAssistPostponeModalStyle);

export default function AdvoAssistPostponeModal({
  advoAssist,
  legalbirdAppointment,
  product,
  submitAction,
  open,
  handleClose,
  refreshPage,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const updateMutation = useMutation(updateResource);
  const classes = useStyles();
  const initialValues = {
    appointmentDate: null,
    appointmentTime: "",
  };
  const formDefinition = [
    {
      type: "ValidatorDateField",
      path: "appointmentDate",
      translationPath: "courtAppointment.courtDate.appointmentDate",
      validators: [...dateFieldDefault, ...relatedFieldDefault("courtDate.appointmentTime")],
    },
    {
      type: "ValidatorTextField",
      path: "appointmentTime",
      translationPath: "courtAppointment.courtDate.appointmentTime",
      validators: relatedFieldDefault("courtDate.appointmentDate"),
      additionalProps: {
        type: "time",
        InputLabelProps: {
          shrink: true,
        },
      },
    },
  ];

  const onSubmit = async ({ values }) => {
    setIsLoading(true);
    await updateMutation.mutateAsync({
      uri: "appointments",
      id: legalbirdAppointment.id || 0,
      data: {
        appointmentDate: moment(formValue(values, "appointmentDate")).format("YYYY-MM-DD"),
        appointmentTime: convertToFormattedTime(formValue(values, "appointmentTime")),
      },
    });
    await submitAction("postpone", {});
    setIsLoading(false);
    refreshPage();
  };

  const useFormProps = useForm({
    initialValues,
    onSubmit,
    identifier: legalbirdAppointment,
  });

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title="Gerichtstermin verschieben"
      submitButton={
        <ButtonLoading
          disabled={!useFormProps.values.appointmentDate || !useFormProps.values.appointmentTime}
          onClick={useFormProps.handleSubmit}
          color="primary"
          variant={"contained"}
          isLoading={isLoading}
        >
          Termin verschieben
        </ButtonLoading>
      }
    >
      <p>Wenn der Gerichtstermin verschoben werden soll, bitte hier die neuen Daten eingeben:</p>
      <Grid container>
        {_.map(formDefinition, (formElementProps) => (
          <Grid className={classes.spacingRight} item md={6} xs={12} key={formElementProps.path}>
            <FormElement product={product} {...formElementProps} {...useFormProps} />
          </Grid>
        ))}
      </Grid>
      <p>
        <strong>Achtung</strong>: Der Klick auf “Termin verschieben” löst auch eine Terminverschiebung bei AdvoAssist
        aus.
      </p>
    </LegalbirdIoModal>
  );
}
AdvoAssistPostponeModal.propTyes = {
  advoAssist: PropTypes.object.isRequired,
  submitAction: PropTypes.func.isRequired,
  legalbirdAppointment: PropTypes.object.isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
AdvoAssistPostponeModal.defaultProps = {
  open: false,
  handleClose: () => {},
};
