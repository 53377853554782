import React from "react";
import useStyles from "./productsDashboardStyle";
import { Box, Divider, List, ListItem } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import divorceIcon from "../../assets/icon/product/icon_scheidung.png";
import trafficIcon from "../../assets/icon/product/icon_verkehrsrecht.png";
import settlementIcon from "../../assets/icon/product/icon_arbeitsrecht.png";
import documentsIcon from "../../assets/icon/product/icon_dokumente.jpg";
import familyLawIcon from "../../assets/icon/product/icon_familienrecht.png";
import contractLawIcon from "../../assets/icon/product/icon_vertragsrecht.png";
import ContentBox from "../ContentBox/ContentBox";
import { translate } from "../../services/Translations/translatorService";

const ProductsDashboard = ({}) => {
  const classes = useStyles({});

  return (
    <Box sx={{ maxWidth: 360, margin: "auto" }}>
      <ContentBox padding="none">
        <List>
          <ListItem button className={classes.productItem} component={Link} to={"/services/board/scheidung/offen"}>
            <Grid container alignItems={"center"} spacing={3}>
              <Grid item>
                <img src={divorceIcon} className={classes.icon} alt={"Scheidung"} />
              </Grid>
              <Grid item>{translate("divorce.label")}</Grid>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem button className={classes.productItem} component={Link} to={"/services/board/abfindung/offen"}>
            <Grid container alignItems={"center"} spacing={3}>
              <Grid item>
                <img src={settlementIcon} className={classes.icon} alt={"Abfindung"} />
              </Grid>
              <Grid item>{translate("settlement.label")}</Grid>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem button className={classes.productItem} component={Link} to={"/services/board/verkehr/offen"}>
            <Grid container alignItems={"center"} spacing={3}>
              <Grid item>
                <img src={trafficIcon} className={classes.icon} alt={"Verkehr"} />
              </Grid>
              <Grid item>{translate("traffic.label")}</Grid>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem button className={classes.productItem} component={Link} to={"/services/board/dokumente/offen"}>
            <Grid container alignItems={"center"} spacing={3}>
              <Grid item>
                <img src={documentsIcon} className={classes.icon} alt={"Dokumente"} />
              </Grid>
              <Grid item>Dokumente</Grid>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem button className={classes.productItem} component={Link} to={"/services/board/familie/offen"}>
            <Grid container alignItems={"center"} spacing={3}>
              <Grid item>
                <img src={familyLawIcon} className={classes.icon} alt={"Familienrecht"} />
              </Grid>
              <Grid item>{translate("familyLawCase.label")}</Grid>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem button className={classes.productItem} component={Link} to={"/services/board/vertragsrecht/offen"}>
            <Grid container alignItems={"center"} spacing={3}>
              <Grid item>
                <img src={contractLawIcon} className={classes.icon} alt={"Vertragsrecht"} />
              </Grid>
              <Grid item>Beratung / Vertretung Vertragsrecht</Grid>
            </Grid>
          </ListItem>
        </List>
      </ContentBox>
    </Box>
  );
};

export default ProductsDashboard;
