import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  headline: {
    textAlign: "center",
  },
  formPaper: {
    padding: "2rem 5rem",
    maxWidth: 600,
    margin: "0 auto",
  },
}));

export default useStyles;
