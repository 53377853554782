const ACTIVITY_OVERDUE = 10; // 'activityOverdue';
const NO_OPEN_ACTIVITY = 20; // 'noOpenActivity';
const ACTIVITY_TODAY = 30; // 'activityToday';
const ACTIVITY_FUTURE = 40; // 'activityFuture';

const STAGE_FALLBACK = -1;

const stagesBaseMetaData = {
  [STAGE_FALLBACK]: {
    label: "Alle",
    stage: STAGE_FALLBACK,
    leadStatus: 10,
    isActive: () => true,
    isClickable: () => false,
  },
};

export { stagesBaseMetaData, ACTIVITY_OVERDUE, NO_OPEN_ACTIVITY, ACTIVITY_TODAY, ACTIVITY_FUTURE };
