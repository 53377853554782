import React, { useCallback, useMemo, useState } from "react";
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import _ from "lodash";
import ActivityTableRow from "./ActivityTableRow";
import { makeStyles } from "@mui/styles";
import activityStyle from "./activityStyle";
import PropTypes from "prop-types";
import { getProductNameBy } from "../../../services/Product/ProductService";
import { getActivityLabel } from "../../../services/Activities/activityTypes";
import SortableColumnCell from "./SortableColumnCell";

const useStyle = makeStyles(activityStyle);

const ActivityTableRows = ({ activities, update, isLoading, isExternalView, isCompletedView = false }) => {
  if (isLoading) {
    return (
      <TableRow>
        <TableCell colSpan={10} align={"center"}>
          <CircularProgress />
        </TableCell>
      </TableRow>
    );
  }

  if (activities.length === 0) {
    return (
      <TableRow>
        <TableCell colSpan={10} align={"center"}>
          {isCompletedView
            ? "Im ausgewählten Zeitraum wurden keine Aktivitäten erledigt"
            : "Keine Aktivitäten für den ausgewählten Zeitraum geplant"}
        </TableCell>
      </TableRow>
    );
  }

  return _.map(activities, (activity) => (
    <ActivityTableRow key={activity.id} activity={activity} update={update} isExternalView={isExternalView} />
  ));
};

const ActivityTable = ({ activities, update, isLoading, isExternalView, isCompletedView }) => {
  const classes = useStyle();
  const [sortBy, setSortBy] = useState("");

  const toggleSortBy = (attribute) => {
    if (sortBy === attribute) {
      setSortBy("");
      return;
    }
    setSortBy(attribute);
  };

  const sortedByAttribute = useCallback(
    (activitiesToBeSorted) => {
      switch (sortBy) {
        case "type":
          return _.sortBy(activitiesToBeSorted, (activity) => {
            const productClassName = getProductNameBy("lbProductClass", activity.case.lbProductClass);
            return getActivityLabel(productClassName, activity.type);
          });
        case "mandatory":
          return _.sortBy(activitiesToBeSorted, (activity) => {
            return !activity.mandatory;
          });
        default:
          return activitiesToBeSorted;
      }
    },
    [sortBy]
  );

  const sortedActivities = useMemo(() => sortedByAttribute(activities), [activities, sortedByAttribute]);

  return (
    <Table className={classes.activityTable}>
      <TableHead>
        <TableRow>
          <SortableColumnCell attribute={"type"} sortBy={sortBy} toggleSortBy={toggleSortBy}>
            Typ
          </SortableColumnCell>
          <TableCell>Beschreibung</TableCell>
          <TableCell>Rechtsprodukt</TableCell>
          <TableCell>Notiz</TableCell>
          <TableCell>Fall</TableCell>
          <TableCell>Datum</TableCell>
          <TableCell>Uhrzeit</TableCell>
          {isExternalView ? (
            <TableCell>Dauer</TableCell>
          ) : (
            <SortableColumnCell attribute={"mandatory"} sortBy={sortBy} toggleSortBy={toggleSortBy}>
              Pflicht
            </SortableColumnCell>
          )}
          <TableCell>Zu&nbsp;erledigen&nbsp;durch</TableCell>
          {!isExternalView && <TableCell />}
        </TableRow>
      </TableHead>
      <TableBody>
        <ActivityTableRows
          activities={sortedActivities}
          update={update}
          isLoading={isLoading}
          isExternalView={isExternalView}
          isCompletedView={isCompletedView}
        />
      </TableBody>
    </Table>
  );
};

ActivityTable.propTypes = {
  activities: PropTypes.array,
  update: PropTypes.func,
  isLoading: PropTypes.bool,
  isExternalView: PropTypes.bool,
};

ActivityTable.defaultProps = {
  isExternalView: false,
};

export default ActivityTable;
