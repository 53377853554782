import React from "react";
import caseNotFoundStyle from "./caseNotFoundStyle";
import { Box } from "@mui/material";

export default function CaseNotAllowed() {
  return (
    <Box sx={caseNotFoundStyle.noCase}>
      Dieses Mandat können Sie aus datenschutzrechtlichen Gründen leider nicht öffnen, da es Ihnen nicht (mehr)
      zugeteilt ist.
      <br /> Sie sind der Meinung, dass es sich hierbei um einen technischen Fehler handelt, weil Sie den Fall derzeit
      aktiv bearbeiten und Sie benötigen daher Zugang zu dem Fall?
      <br /> Dann melden Sie sich bitte direkt bei uns!
    </Box>
  );
}
