import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  link: {
    margin: "0 auto",
    display: "block",
    textAlign: "center",
    width: 170,
  },
  grid: {
    "& > .MuiGrid-item": {
      [theme.breakpoints.up("md")]: {
        padding: 12,
      },
      minWidth: 320,
    },
  },
  callIcon: {
    display: "block",
    margin: "0 0 0 auto",
    width: 40,
    height: 40,
    padding: 8,
  },
  callGridItem: {
    marginTop: "1.5rem",
  },
  headline: {
    textAlign: "center",
    fontSize: "1.25rem",
    marginTop: "3rem",
  },
  moreButton: {
    marginTop: "1rem",
  },
}));

export default useStyles;
