import React, { useState } from "react";
import useStyles from "./uploadBankPaymentEntriesStyle";
import Grid from "@mui/material/Grid";
import ButtonLoading from "../Button/ButtonLoading";
import ApiClient from "../../services/ApiClient";
import _ from "lodash";
import Snackbar from "../Snackbar/Snackbar";
import { useSnackbar } from "notistack";
import { DropzoneArea } from "mui-file-dropzone";
import PageHeadline from "../PageHeadline/PageHeadline";
import PageContentContainer from "../Container/PageContentContainer";
import ContentBox from "../ContentBox/ContentBox";

const UploadBankPaymentEntries = () => {
  const classes = useStyles({});
  const { enqueueSnackbar } = useSnackbar();

  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const saveFile = async () => {
    if (!files[0]) {
      return;
    }

    setIsLoading(true);

    let formData = new FormData();
    formData.append("file", files[0]);
    let headers = new Headers();
    headers.set("Content-Type", "multipart/form-data");

    const res = await ApiClient.post("service/bank_payment_entries", {
      headers: headers,
      body: formData,
    });

    if (_.isError(res)) {
      enqueueSnackbar(res.errors.csv, {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } else {
      enqueueSnackbar("", {
        content: () => (
          <div>
            <Snackbar
              message={`CSV wurde erfolgreich hochgeladen.
              ${res.totalRows} Zeilen, ${res.relevantPayments} relevant, ${res.identifiedPayments} erkannt, ${res.assignedPayments} zugeordnet`}
            />
          </div>
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      setFiles([]);
    }
    setIsLoading(false);
  };

  const fileChange = (files) => {
    setFiles(files);
  };

  return (
    <PageContentContainer size={"tiny"}>
      <PageHeadline main="Zahlungen hochladen" />
      <ContentBox>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DropzoneArea
              onChange={fileChange}
              onDelete={() => {}}
              clearOnUnmount={true}
              acceptedFiles={["text/csv"]}
              filesLimit={1}
              fileObjects={files}
              dropzoneText={"Datei hierher ziehen oder Fläche anklicken"}
              showFileNames
              showAlerts={["error"]}
              getDropRejectMessage={() => "Die Datei darf höchstens 300 MB groß sein und muss vom Format CSV sein"}
              maxFileSize={300000000}
              useChipsForPreview
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonLoading onClick={saveFile} variant={"contained"} isLoading={isLoading}>
              Zahlungs-CSV hochladen
            </ButtonLoading>
          </Grid>
        </Grid>
      </ContentBox>
    </PageContentContainer>
  );
};

export default UploadBankPaymentEntries;
