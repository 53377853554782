import contactTranslations from "./contactTranslations";
import addressTranslations from "./addressTranslations";

const contractualPartnerLegalEntityTranslations = {
  legalName: {
    label: "Name des Unternehmens",
  },
  legalForm: {
    label: "Gesellschaftsform",
    values: {
      GmbH: "GmbH",
      UG: "UG",
      GmbHCoKG: "GmbH & Co. KG",
      AG: "AG",
      OHG: "OHG",
      GbR: "GbR",
      Limited: "Limited",
      LimitedCoKG: "Limited & Co. KG",
      JuristischePersonDesOeffentlichenRechts: "Juristische Person des öffentlichen Rechts",
      natuerlichePerson: "natürliche Person",
      eingetragenerKaufmann: "eingetragener Kaufmann",
    },
  },
  name: {
    label: "Markenname"
  },
  ...contactTranslations,
  address: addressTranslations,
}
export default contractualPartnerLegalEntityTranslations;