import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./progressionButtonStyle";
import ButtonLoading from "../../Button/ButtonLoading";
import Grid from "@mui/material/Grid";
import _ from "lodash";
import Button from "@mui/material/Button";
import { PAID_TASK_STATUS_ASSIGNED, PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED } from "../paidTaskStatus";

const InternalPaidTaskDefinitionButton = ({ paidTask, isLoading, touchedValues, openDeleteModal }) => {
  const classes = useStyles({});

  switch (true) {
    case _.includes([PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED], paidTask.status):
      return (
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item xs={12}>
            <Grid container justifyContent={"center"}>
              <Grid item>
                <ButtonLoading
                  fullWidth={false}
                  className={classes.button}
                  variant={"contained"}
                  type={"submit"}
                  isLoading={isLoading}
                  disabled={_.keys(touchedValues).length === 0}
                >
                  Speichern
                </ButtonLoading>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button fullWidth={false} className={classes.button} onClick={() => openDeleteModal()}>
              Löschen
            </Button>
          </Grid>
        </Grid>
      );
    case paidTask.status === PAID_TASK_STATUS_ASSIGNED:
      return (
        <Grid container justifyContent={"center"}>
          <Grid item>
            <Button
              fullWidth={false}
              variant={"contained"}
              className={classes.button}
              onClick={() => openDeleteModal()}
            >
              Löschen
            </Button>
          </Grid>
        </Grid>
      );
    default:
      return null;
  }
};

InternalPaidTaskDefinitionButton.propTypes = {
  paidTask: PropTypes.object,
  isLoading: PropTypes.bool,
  openDeleteModal: PropTypes.func,
  touchedValues: PropTypes.any,
};

export default InternalPaidTaskDefinitionButton;
