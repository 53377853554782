import React, { useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import ButtonLoading from "../../Button/ButtonLoading";
import useStyles from "./progressionButtonStyle";
import ApiClient from "../../../services/ApiClient";
import { apiGet } from "../../../services/Api/apiCall";
import Snackbar from "../../Snackbar/Snackbar";
import moment from "moment";
import { useSnackbar } from "notistack";
import { PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED } from "../paidTaskStatus";
import _ from "lodash";
import { getCaseLinkByBackofficeCase } from "../../../services/Product/ProductService";
import DismissButton from "./DismissButton";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";

const AcceptPaidTaskButton = ({ paidTask }) => {
  const [choiceConfirmed, setChoiceConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [myCapacity, setMyCapacity] = useState(null);
  const updateMutation = useMutation(updateResource);
  const classes = useStyles({});
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();
  const caseLink = useMemo(() => {
    return getCaseLinkByBackofficeCase(paidTask.backofficeCase, "/extern");
  }, [paidTask.backofficeCase]);

  useEffect(() => {
    apiGet("services/external/lawyerCapacity", null, { lawyerId: currentUser.id }).then((myCapacity) =>
      setMyCapacity(myCapacity)
    );
  }, [paidTask]);

  const hasUnacceptedRequests = useMemo(() => {
    return !paidTask.requestedUser && myCapacity?.requestedTasks > 0;
  }, [paidTask, myCapacity]);

  const assignPaidTask = async () => {
    setIsLoading(true);
    const tasksForUser = await ApiClient.get(
      "paid_tasks?assignee.id=" + currentUser.id + "&exists[markedAsDoneDate]=false&exists[deletedDate]=false"
    );
    if (tasksForUser["hydra:member"].length > 25) {
      enqueueSnackbar("", {
        content: () => (
          <div>
            <Snackbar message={"Sie können maximal 25 Aufgaben gleichzeitig annehmen."} isNonInteractive={true} />
          </div>
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        autoHideDuration: 3000,
      });
      setIsLoading(false);
      return;
    }

    const checkPaidTask = await apiGet("paid_tasks", paidTask.id);
    if (
      !_.includes([PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED], checkPaidTask.status) ||
      checkPaidTask.deletedDate
    ) {
      enqueueSnackbar("", {
        content: () => (
          <div>
            <Snackbar message={"Diese Aufgabe ist nicht mehr verfügbar"} isNonInteractive={true} />
          </div>
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        autoHideDuration: 3000,
      });
      return;
    }

    await updateMutation.mutateAsync({
      uri: "paid_tasks",
      id: paidTask.id,
      data: {
        assignedDate: moment().format("DD.MM.YYYY"),
        assignee: currentUser["@id"],
      },
    });
    navigate(caseLink + "/ausschreibung/" + paidTask.id);
    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar
            message={"Sie haben die Aufgabe erfolgreich angenommen"}
            buttonType={"link"}
            buttonLink={getCaseLinkByBackofficeCase(paidTask.backofficeCase, "/extern")}
            buttonTarget={"_self"}
            buttonText={"Zum Fall"}
          />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      autoHideDuration: 3000,
    });
  };

  return (
    <Grid container spacing={2} justifyContent={"center"}>
      {!hasUnacceptedRequests && (
        <Grid item xs={12}>
          <Grid container justifyContent={"center"}>
            <Grid item>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Checkbox checked={choiceConfirmed} onChange={({ target }) => setChoiceConfirmed(target.checked)} />
                }
                label={"Ich möchte diese Aufgabe verbindlich annehmen"}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item>
        <ButtonLoading
          className={classes.button}
          variant={"contained"}
          isLoading={isLoading}
          disabled={!choiceConfirmed || hasUnacceptedRequests}
          onClick={() => {
            assignPaidTask();
            setChoiceConfirmed(false);
          }}
        >
          Aufgabe annehmen
        </ButtonLoading>
        {hasUnacceptedRequests && (
          <Box sx={{ textAlign: "center", color: "red" }}>
            Das Annehmen dieser Aufgabe ist nicht möglich, da offene Anfragen an Sie ausstehen. Bitte nehmen Sie
            zunächst die angefragten Aufgaben an.
          </Box>
        )}
        {!!paidTask.requestedUser && (
          <Box marginTop={"1rem"}>
            <DismissButton paidTask={paidTask} isLoading={isLoading} setIsLoading={setIsLoading} />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

AcceptPaidTaskButton.propTypes = {
  paidTask: PropTypes.object,
};

export default AcceptPaidTaskButton;
