import React from "react";
import useStyles from "./activityOverviewStyle";
import { Button, Grid } from "@mui/material";
import ActivityIcon from "../../../components/Activities/ActivityIcon";
import { getCaseLink, getProductData, getProductNameBy } from "../../../services/Product/ProductService";
import moment from "moment";
import { ReportProblemOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import classNames from "classnames";
import ActivityActions from "../ActivityActions/ActivityActions";
import AssignedUser from "./AssignedUser";

const OverviewActivityEntry = ({ activity, update }) => {
  const classes = useStyles({});
  const productClassName = getProductNameBy("lbProductClass", activity.case.lbProductClass);

  return (
    <Grid container spacing={1}>
      <Grid item xs={1}>
        <ActivityIcon activity={activity} />
      </Grid>
      <Grid item xs={11} className={classes.smallFont}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={7} className={classNames(classes.singleLineOverflowHidden, classes.subject)}>
            {activity.subject}
          </Grid>
          <Grid item xs={5}>
            {getProductData(productClassName, "generalLabel")}
          </Grid>
          <Grid item xs={12} className={classes.singleLineOverflowHidden}>
            {(activity.note || "").replace(/(<([^>]+)>)/gi, "")}
          </Grid>
          <Grid item xs={7}>
            {activity.case.label || activity.case.lbProductId}
          </Grid>
          <Grid item xs={5}>
            <AssignedUser activity={activity} updateActivities={update} />
          </Grid>
          <Grid item xs={4}>
            {moment(activity.dueDate).format("DD.MM.YYYY")}
          </Grid>
          <Grid item xs={3}>
            {activity.dueTime !== "allDay" ? <span>{activity.dueTime}&nbsp;Uhr</span> : "-"}
          </Grid>
          <Grid item xs={5}>
            {activity.mandatory ? (
              <Grid container alignItems={"center"} justifyContent={"space-between"}>
                <Grid item xs={1}>
                  <ReportProblemOutlined className={classes.warningIcon} />
                </Grid>
                <Grid item xs={10}>
                  Pflicht
                </Grid>
              </Grid>
            ) : (
              "Keine Pflicht"
            )}
          </Grid>
          <Grid item xs={4}>
            <Button
              variant={"contained"}
              color={"primary"}
              className={classes.activityButton}
              component={Link}
              to={getCaseLink({ productClassName: productClassName, id: activity.case.lbProductId }, "", true)}
              fullWidth={false}
            >
              Zum Fall
            </Button>
          </Grid>
          <ActivityActions activity={activity} updateActivities={update} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OverviewActivityEntry;
