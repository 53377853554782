import React, { useState } from "react";
import * as PropTypes from "prop-types";
import useStyles from "./templateRowStyle";
import { IconButton, TableCell, TableRow } from "@mui/material";
import Grid from "@mui/material/Grid";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import TemplateEditor from "./TemplateEditor";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ButtonLoading from "../Button/ButtonLoading";
import ApiClient from "../../services/ApiClient";
import _ from "lodash";
import { Draggable } from "react-beautiful-dnd";

const TemplateRow = ({ template, refreshTemplateList, index }) => {
  const classes = useStyles({});
  const [mailEditorOpen, setMailEditorOpen] = useState(false);
  const [deleteTemplateOpen, setDeleteTemplateOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const deleteTemplate = async () => {
    setIsLoading(true);
    await ApiClient.delete("templates/" + template.id);
    if (_.isFunction(refreshTemplateList)) {
      await refreshTemplateList();
    }
    setIsLoading(false);
    setDeleteTemplateOpen(false);
  };

  const onSubmitCallback = async () => {
    setIsLoading(true);
    await refreshTemplateList();
    setMailEditorOpen(false);
    setIsLoading(false);
  };

  return (
    <Draggable draggableId={template.id + ""} index={index} isDragDisabled={!isSelected}>
      {(provided) => (
        <TableRow
          className={isSelected ? classes.selectedFont : null}
          selected={isSelected}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <TableCell onClick={() => setIsSelected(!isSelected)}>{template.label}</TableCell>
          <TableCell onClick={() => setIsSelected(!isSelected)}>{template.subject}</TableCell>
          <TableCell>
            <Grid container spacing={1} className={classes.actions}>
              <Grid item xs={6}>
                <IconButton
                  onClick={() => setMailEditorOpen(true)}
                  className={classes.icon}
                  size="large">
                  <EditOutlined />
                </IconButton>
              </Grid>
              <Grid item xs={6}>
                <IconButton
                  onClick={() => setDeleteTemplateOpen(true)}
                  className={classes.icon}
                  size="large">
                  <DeleteOutlined />
                </IconButton>
              </Grid>
            </Grid>
            <LegalbirdIoModal handleClose={() => setMailEditorOpen(false)} open={mailEditorOpen} title={"Vorlage"}>
              <TemplateEditor
                onSubmitCallback={onSubmitCallback}
                template={template}
                type={template.type}
                withSubject={template.type === "email"}
              />
            </LegalbirdIoModal>
            <LegalbirdIoModal
              handleClose={() => setDeleteTemplateOpen(false)}
              open={deleteTemplateOpen}
              title={"Vorlage Löschen?"}
              submitButton={
                <ButtonLoading variant={"contained"} isLoading={isLoading} onClick={deleteTemplate}>
                  Löschen
                </ButtonLoading>
              }
            >
              <div className={classes.deleteText}>Möchten Sie diese Vorlage wirklich löschen?</div>
            </LegalbirdIoModal>
          </TableCell>
        </TableRow>
      )}
    </Draggable>
  );
};

TemplateRow.propTypes = {
  template: PropTypes.object.isRequired,
  refreshTemplateList: PropTypes.func,
};

export default TemplateRow;
