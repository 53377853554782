import React, { useState } from "react";
import * as PropTypes from "prop-types";
import ValidatorTextField from "../Validator/ValidatorTextField";
import MenuItem from "@mui/material/MenuItem";
import useForm from "../../hooks/useForm";
import { formValue, prepareFormCustomerDates } from "../../services/formServiceFunctions";
import ValidatorSelect from "../Validator/ValidatorSelect";
import _ from "lodash";
import { getProductData } from "../../services/Product/ProductService";
import { difference, persistCustomer, persistPerson } from "../../services/Persist/Persister";
import {
  duplicateMailDefault,
  emailDefault,
  isPhoneNumberWithoutSpecialCharactersDefault,
  requiredFieldDefault,
  textFieldDefault,
} from "../../services/validationRules";
import SubmitButton from "../Button/SubmitButton";
import { Call } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import useStyles from "./contactStyle";
import { translate } from "../../services/Translations/translatorService";
import FormElementAlert from "../Case/CaseForm/FormElementAlert";
import InputAdornment from "@mui/material/InputAdornment";
import { useCase } from "../../provider/CaseProvider";

const Contact = ({}) => {
  const [isShowAlert, setIsShowAlert] = useState(false);

  const { product, refreshCase } = useCase();
  const customer = product.customer;
  const petitioner = product.petitioner;
  const classes = useStyles();

  const timeSlots = [];
  for (let i = 8; i <= 18; i++) {
    timeSlots.push(
      <MenuItem key={i} value={"" + i}>
        {i} {translate("customer.contactTime.values.time")}
      </MenuItem>
    );
  }

  const initialValues = {
    customer: prepareFormCustomerDates(customer),
    petitioner: petitioner,
  };

  const onSubmit = async () => {
    const updatedCustomer = await persistCustomer(
      customer.id,
      difference(values.customer, prepareFormCustomerDates(customer))
    );
    await persistPerson(petitioner.id, difference(values.petitioner, petitioner));
    await refreshCase();
    registerValidators("customer.email", [
      ...emailDefault,
      ...requiredFieldDefault,
      ...duplicateMailDefault(updatedCustomer.email),
    ]);
  };

  const identifier = product;
  const { values, errors, handleChange, handleSubmit, handleBlur, registerValidators, touchedValues, isLoading } =
    useForm({
      initialValues,
      onSubmit,
      identifier,
    });

  return (
    <form onSubmit={handleSubmit}>
      <ValidatorTextField
        onChange={handleChange}
        onBlur={handleBlur}
        name={"customer.telephone"}
        value={formValue(values, "customer.telephone")}
        label={translate("customer.telephone.label")}
        registerValidators={registerValidators}
        validators={textFieldDefault}
        error={!!errors["customer.telephone"]}
        helperText={errors["customer.telephone"]}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                color={"secondary"}
                className={classes.callIcon}
                href={"tel:" + formValue(values, "customer.telephone")}
                disabled={!formValue(values, "customer.telephone")}
                size="large"
              >
                <Call />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <ValidatorTextField
        onChange={handleChange}
        onBlur={handleBlur}
        name={"customer.alternativeTelephone"}
        value={formValue(values, "customer.alternativeTelephone")}
        label={translate("customer.alternativeTelephone.label")}
        registerValidators={registerValidators}
        validators={[...textFieldDefault, ...isPhoneNumberWithoutSpecialCharactersDefault]}
        error={!!errors["customer.alternativeTelephone"]}
        helperText={errors["customer.alternativeTelephone"]}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                color={"secondary"}
                className={classes.callIcon}
                href={"tel:" + formValue(values, "customer.alternativeTelephone")}
                disabled={!formValue(values, "customer.alternativeTelephone")}
                size="large"
              >
                <Call />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <ValidatorTextField
        onChange={handleChange}
        onBlur={() => setIsShowAlert(true)}
        name={"customer.email"}
        value={formValue(values, "customer.email")}
        label={translate("customer.email.label")}
        registerValidators={registerValidators}
        validators={[...emailDefault, ...requiredFieldDefault, ...duplicateMailDefault(customer.email)]}
        error={!!errors["customer.email"]}
        helperText={errors["customer.email"]}
      />
      <FormElementAlert
        alert={{
          onChange:
            "Das Ändern der E-Mail-Adresse führt dazu, dass der Kunde sich nicht mehr mit seiner alten Adresse in der Servicewelt anmelden kann, sondern nur noch mit der neuen Adresse.",
        }}
        initialValue={formValue(initialValues, "customer.email")}
        currentValue={formValue(values, "customer.email")}
        path={"customer.email"}
        setIsShowAlert={setIsShowAlert}
        isShowAlert={isShowAlert}
        handleChange={handleChange}
        label={translate("customer.email.label")}
      />
      <ValidatorTextField
        name="customer.verification"
        label={translate("customer.verification.label")}
        value={_.defaultTo(values.customer.verification, "")}
        disabled
      />
      <ValidatorSelect
        label={translate("customer.contactTime.label")}
        name={"customer.contactTime"}
        value={formValue(values, "customer.contactTime")}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        <MenuItem value={"flexible"}>{translate("customer.contactTime.values.flexible")}</MenuItem>
        {timeSlots}
      </ValidatorSelect>
      {product.leadStatus < getProductData(product.productClassName, "minimumCaseStatus") && (
        <>
          <ValidatorSelect
            label={translate(`${product.productClassName}.petitioner.gender.label`)}
            name={`petitioner.gender`}
            value={formValue(values, `petitioner.gender`)}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <MenuItem value={`male`}>{translate(`${product.productClassName}.petitioner.gender.values.male`)}</MenuItem>
            <MenuItem value={`female`}>
              {translate(`${product.productClassName}.petitioner.gender.values.female`)}
            </MenuItem>
          </ValidatorSelect>
          <ValidatorTextField
            onChange={handleChange}
            onBlur={handleBlur}
            name={`petitioner.givenName`}
            value={formValue(values, `petitioner.givenName`)}
            label={translate(`${product.productClassName}.petitioner.givenName.label`)}
            registerValidators={registerValidators}
            validators={[...textFieldDefault, ...requiredFieldDefault]}
            error={!!errors[`petitioner.givenName`]}
            helperText={errors[`petitioner.givenName`]}
          />
          <ValidatorTextField
            onChange={handleChange}
            onBlur={handleBlur}
            name={`petitioner.familyName`}
            value={formValue(values, `petitioner.familyName`)}
            label={translate(`${product.productClassName}.petitioner.familyName.label`)}
            registerValidators={registerValidators}
            validators={[...textFieldDefault, ...requiredFieldDefault]}
            error={!!errors[`petitioner.familyName`]}
            helperText={errors[`petitioner.familyName`]}
          />
        </>
      )}
      <br />
      <br />
      <SubmitButton
        error={_.keys(errors).length > 0}
        isLoading={isLoading}
        disabled={_.keys(touchedValues).length === 0}
        variant={"contained"}
        type={"submit"}
      >
        Speichern
      </SubmitButton>
    </form>
  );
};
Contact.propTypes = {
  product: PropTypes.object.isRequired,
};

export default Contact;
