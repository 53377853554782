const contactStyle = {
  callIcon: {
    display: "block",
    margin: "0 0 0 auto",
    width: 40,
    height: 40,
    padding: 8,
  },
  callGridItem: {
    marginTop: "1.5rem",
  },
};

export default contactStyle;
