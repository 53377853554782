import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { PAID_TASK_STATUS_ASSIGNED } from "../paidTaskStatus";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import Snackbar from "../../Snackbar/Snackbar";
import { getCaseLinkByBackofficeCase } from "../../../services/Product/ProductService";
import { useSnackbar } from "notistack";
import ApiClient from "../../../services/ApiClient";

const RemindExternalLawyerModal = ({ paidTask, open, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  if (paidTask.status !== PAID_TASK_STATUS_ASSIGNED) {
    return null;
  }

  const sendReminder = async () => {
    setIsLoading(true);

    await ApiClient.post("paid_task_actions/" + paidTask.id + "/reminder");

    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar
            message={"Erinnerung erfolgreich versendet"}
            buttonType={"link"}
            buttonLink={getCaseLinkByBackofficeCase(paidTask.backofficeCase)}
            buttonTarget={"_self"}
            buttonText={"Zum Fall"}
          />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    setIsLoading(false);
    closeModal();
  };

  return (
    <LegalbirdIoModal
      handleClose={() => closeModal()}
      open={open}
      title={"Anwalt an Erledigung erinnern"}
      submitButton={
        <ButtonLoading variant={"contained"} color={"primary"} onClick={sendReminder} isLoading={isLoading}>
          Anwalt erinnern
        </ButtonLoading>
      }
    >
      Möchten Sie den Anwalt an die Erledigung der Aufgabe erinnern? Es wird dann eine E-Mail an den betreffenden Anwalt
      samt Link zu dieser Aufgabe verschickt.
    </LegalbirdIoModal>
  );
};

RemindExternalLawyerModal.propTypes = {
  paidTask: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default RemindExternalLawyerModal;
