const courtAppointmentStyle = {
  linked: {
    fontSize: "1rem",
    color: "#317ae2",
    cursor: "pointer",
    textAlign: "center",
  },
  spacingRight: {
    paddingRight: "1rem",
  },
  spacingBottom: {
    paddingBottom: "1rem",
  },
};
export default courtAppointmentStyle;
