import PageHeadline from "../../PageHeadline/PageHeadline";
import React, { useEffect, useState } from "react";
import PageContentContainer from "../../Container/PageContentContainer";
import BeaAttachments from "../../BeaAttachments/BeaAttachments";
import { useParams } from "react-router-dom";
import ApiClient from "../../../services/ApiClient";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";

export default function BeaAttachmentsPage()
{
  const [beaMessage, setBeaMessage] = useState();
  const { beaId } = useParams();
  useEffect(() => {
    ApiClient.get("bea_messages/" + beaId).then(
      (beaMessageResponse) => setBeaMessage(beaMessageResponse)
    )
  }, [beaId]);

  if(!beaMessage) {
    return <LegalbirdLoader centered={true} />
  }

  return (
      <PageContentContainer>
        <PageHeadline main="beA-Anhänge verarbeiten" />
        <BeaAttachments beaMessage={beaMessage}/>
      </PageContentContainer>
  )
}