import customerTranslations from "./customerTranslations";
import basicCaseTranslations from "./basicCaseTranslations";
import abstractCaseTranslations from "./abstractCaseTranslations";
import personTranslations from "./personTranslations";

const familyLawCaseTranslations = {
  label: "Erstberatung Familienrecht",
  client: personTranslations,
  customer: customerTranslations,
  ...abstractCaseTranslations,
  ...basicCaseTranslations,
  serviceProvidedDate: {
    label: "Leistung erbracht am",
  },
  advisoryAppointment: {
    label: "Beratungsgespräch",
  },
};

export default familyLawCaseTranslations;
