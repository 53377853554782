import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import React from "react";
import useStyles from "./dutyNotificationStyles";
import { useSnackbar } from "notistack";
import ActivityNote from "../Activities/ActivityNote";

const SystemNoteNotification = React.forwardRef(({ id, systemNote, caseLink }, ref) => {
  const classes = useStyles({ maxWidth: 600 });
  const { closeSnackbar } = useSnackbar();
  const closeNotification = () => {
    closeSnackbar(id);
  };
  return (
    <Paper ref={ref} className={classes.wideNotificationContainer}>
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item xs={2} />
        <Grid item xs={8} className={classes.headline}>
          Neue Systemnotiz!
        </Grid>
        <Grid item xs={2}>
          <IconButton className={classes.icon} onClick={closeNotification} size="large">
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12} className={classes.noteContainerOverride}>
          <ActivityNote noteContent={systemNote.content} isFolded={true} />
        </Grid>
        <Grid item xs={6}>
          <Button onClick={closeNotification} component={Link} to={caseLink + "/verlauf"}>
            Zum Verlauf
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={closeNotification}>Ok</Button>
        </Grid>
      </Grid>
    </Paper>
  );
});

SystemNoteNotification.propTypes = {
  id: PropTypes.any.isRequired,
  systemNote: PropTypes.object.isRequired,
};

export default SystemNoteNotification;
