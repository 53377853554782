import React, { useMemo } from "react";
import Grid from "@mui/material/Grid";
import Badge from "../../Badges/Badge";
import { translate } from "../../../services/Translations/translatorService";
import RequestButton from "./RequestButton";
import RemindButton from "./RemindButton";
import { PaidTask } from "../../../types/PaidTask";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";

export default function PaidTaskViewHeader({ paidTask }: { paidTask: PaidTask }) {
  const currentUser = useCurrentUser();
  const usernameInBadge = useMemo(() => {
    if (!paidTask) {
      return;
    }
    switch (true) {
      case !!paidTask.assignee:
        return paidTask?.assignee?.person?.fullname;
      case !!paidTask.requestedUser:
        return paidTask?.requestedUser?.person?.fullname;
      default:
        return;
    }
  }, [paidTask]);

  return (
    <Grid container alignItems={"center"} spacing={2}>
      <Grid item xs={12} md={3}>
        {paidTask && <Badge text={translate("paidTask.status." + paidTask.status)} />}
      </Grid>
      <Grid item xs={12} md={3}>
        {usernameInBadge && <Badge text={usernameInBadge} />}
      </Grid>
      <Grid item xs={12} md={3} />
      <Grid item xs={12} md={3}>
        <RequestButton paidTask={paidTask} />
        <RemindButton paidTask={paidTask} />
      </Grid>
    </Grid>
  );
}
