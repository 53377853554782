import React from "react";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { CopyAllOutlined } from "@mui/icons-material";
import { BeaSearchResult } from "../../types/Bea/BeaSearchResult";

type BeaSearchResultRowProps = {
  searchResult: BeaSearchResult;
  RowExtension?: (prop: { searchResult: BeaSearchResult }) => JSX.Element;
};

export default function BeaSearchResultRow({ searchResult, RowExtension }: BeaSearchResultRowProps) {
  return (
    <>
      <TableRow sx={RowExtension ? { "& td": { border: "none", paddingBottom: 0 } } : {}}>
        <TableCell>{`${searchResult.surname}${searchResult.firstname ? ", " + searchResult.firstname : ""}`}</TableCell>
        <TableCell>
          {searchResult.street} {searchResult.streetnumber}
        </TableCell>
        <TableCell>{searchResult.postalcode}</TableCell>
        <TableCell>{searchResult.city}</TableCell>
        <TableCell sx={{ wordBreak: "break-all" }}>
          {searchResult.safeId}{" "}
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(searchResult.safeId);
            }}
          >
            <CopyAllOutlined />
          </IconButton>
        </TableCell>
        <TableCell>{searchResult.type}</TableCell>
      </TableRow>
      {RowExtension ? <RowExtension searchResult={searchResult} /> : null}
    </>
  );
}
