import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import useStyles from "./unassignedActivitiesStyles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import _ from "lodash";
import IconButton from "@mui/material/IconButton";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ApiClient from "../../../services/ApiClient";
import { Table, TableBody, TableContainer } from "@mui/material";
import ActivityTableRow from "../ActivityTable/ActivityTableRow";

const UnassignedActivities = ({ updateFilteredActivities }) => {
  const classes = useStyles({});
  const [expanded, setExpanded] = useState(false);
  const [unassignedActivities, setUnassignedActivities] = useState([]);

  const updateAllActivities = () => {
    fetchUnassignedActivities();
    updateFilteredActivities();
  };

  const fetchUnassignedActivities = () => {
    ApiClient.get("activities?exists[assignedUser]=false&pagination=false&deleted=false").then((activitiesResponse) => {
      setUnassignedActivities(activitiesResponse["hydra:member"]);
    });
  };

  useEffect(() => {
    fetchUnassignedActivities();
    let checkUnassignedActivitiesTimer = setTimeout(fetchUnassignedActivities, 30000);
    return () => {
      clearTimeout(checkUnassignedActivitiesTimer);
    };
  }, []);

  if (unassignedActivities.length === 0) {
    return null;
  }

  return (
    <Paper className={classes.paper}>
      <Grid container alignItems={"center"}>
        <Grid item xs={1} />
        <Grid item xs={10} className={classes.headline}>
          {unassignedActivities.length} Aktivitäten nicht zugeordnet
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={() => setExpanded(!expanded)} size="large">
            {!expanded ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        </Grid>
        {expanded && (
          <TableContainer>
            <Table>
              <TableBody>
                {_.map(unassignedActivities, (activity) => (
                  <ActivityTableRow
                    key={activity.id}
                    activity={activity}
                    update={updateAllActivities}
                    isExternalView={false}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Paper>
  );
};

UnassignedActivities.propTypes = {
  updateFilteredActivities: PropTypes.func,
};

UnassignedActivities.defaultProps = {};

export default UnassignedActivities;
