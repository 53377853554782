import documentTransformations from "./documentTransformations";

const shortTimeWorkTransformations = {
  dateFields: [
    { path: "petitioner.birthDate", type: "date" },
    { path: "contractTerminationReceivedDate", type: "date" },
  ],
  numberFields: [...documentTransformations.numberFields, "percentageWorkTime", "weeklyWorkingHours"],
  moneyFields: [...documentTransformations.moneyFields, "grossSalary"],
  timeFields: [...documentTransformations.timeFields],
};
export default shortTimeWorkTransformations;
