import { AbstractCase } from "../../../types/AbstractCase";
import { Box, Button, Paper, Stack } from "@mui/material";
import caseInquiryToCustomerStyle from "./caseInquiryToCustomerStyle";
import { QuestionAnswerOutlined } from "@mui/icons-material";
import { buildInquiryShortText } from "../../../hooks/useInquiry";
import React, { useState } from "react";
import _ from "lodash";
import ShowInquiryToCustomerModal from "../../Request/ShowInquiryToCustomerModal";
import { InquiryToCustomer } from "../../../types/InquiryToCustomer";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";

export default function CaseInquiryToCustomer({ product }: { product: AbstractCase }) {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  let inquiryToCustomerFilter = {
    productId: product.id,
    "status[lt]": 40,
  };
  const { data: inquiryToCustomerCollection, isLoading } = useQuery(
    queryKeys.collection("inquiry_to_customers", inquiryToCustomerFilter),
    () => fetchCollection("inquiry_to_customers", inquiryToCustomerFilter),
    {
      enabled: !!product,
    }
  );

  if (!inquiryToCustomerCollection || inquiryToCustomerCollection["hydra:totalItems"] === 0) {
    return null;
  }

  const inquiryToCustomer: InquiryToCustomer | undefined = _.head(inquiryToCustomerCollection["hydra:member"]);
  if (!inquiryToCustomer) {
    return null;
  }

  const statusClass: any = caseInquiryToCustomerStyle[inquiryToCustomer.statusText || "open"];

  return (
    <Paper sx={statusClass}>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <QuestionAnswerOutlined />
        <Box flexGrow={1} paddingLeft={"1rem"}>
          {buildInquiryShortText(inquiryToCustomer)}
        </Box>
        <Box>
          <Button onClick={() => setOpen(true)}>Zur Anfrage</Button>
        </Box>
      </Stack>
      <ShowInquiryToCustomerModal
        open={open}
        handleClose={() => setOpen(false)}
        inquiryToCustomer={inquiryToCustomer}
        product={product}
      />
    </Paper>
  );
}
