import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { Box, CircularProgress, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import ApiClient from "../../services/ApiClient";

export default function BeaAttachment({ attachment, product }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const uploadToCase = async () => {
    setIsLoading(true);
    await ApiClient.put("/media_objects/" + attachment.mediaObjectId, {
      body: JSON.stringify({
        product: product.productClassName,
        productId: product.id,
      }),
    });
    setIsLoading(false);
    setMenuOpen(false);
  };

  return (
    <Box display={"flex"} sx={{ border: "1px solid #d9d9d9", padding: ".5rem", height: 40 }}>
      <Typography>{attachment.reference}</Typography>
      {attachment.mediaObjectId && (
        <>
          <IconButton
            sx={{ margin: "0 0 0 1rem", padding: 0 }}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setMenuOpen(!menuOpen);
            }}
            size="large"
          >
            <MoreHoriz />
          </IconButton>
          <Menu
            open={menuOpen}
            anchorEl={anchorEl}
            onBackdropClick={() => {
              setMenuOpen(false);
              setAnchorEl(null);
            }}
          >
            <MenuItem onClick={() => uploadToCase()}>
              {isLoading ? <CircularProgress size={28} sx={{ margin: "0 auto" }} /> : "Datei zu Fall hinzufügen"}
            </MenuItem>
          </Menu>
        </>
      )}
    </Box>
  );
}

BeaAttachment.propTypes = {
  attachment: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
};
