import React from "react";
import useStyles from "./caseAccountingPageStyle";
import PageContentContainer from "../../Container/PageContentContainer";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import _ from "lodash";
import BookingEntry from "../../Accounting/BookingEntry";
import NewBookingEntry from "../../Accounting/NewBookingEntry";
import { getBookingEntriesWithDifferenceAndSummedValues } from "../../../services/accountingService";
import CaseRelatedActions from "../../Case/CaseRelatedActions/CaseRelatedActions";
import { useParams } from "react-router-dom";
import LegalBirdPageLoading from "../../ContentLoader/LegalBirdPageLoading";
import { ProductHeadline } from "../../PageHeadline/PageHeadline";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";
import CaseProvider, { useCase } from "../../../provider/CaseProvider";
import AddWiretransfer from "../../Wiretransfer/AddWiretransfer";
import Snackbar from "../../Snackbar/Snackbar";
import { useSnackbar } from "notistack";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import NavigationExtensionCaseAccountingPage from "../../NavigationBar/NavigationExtensionCaseAccountingPage";
import { scrollToAnchor } from "../../../theme/commonStyles";

const CaseAccountingPage = () => {
  const { productUrlPart, productId } = useParams();
  return (
    <CaseProvider caseUrlPart={productUrlPart} caseId={productId}>
      <CaseAccountingPageInner />
    </CaseProvider>
  );
};

const CaseAccountingPageInner = () => {
  const classes = useStyles({});
  const { product } = useCase();
  const { backofficeUsers } = useBackofficeUser();
  const { enqueueSnackbar } = useSnackbar();
  const [organizationId, setOrganizationId] = React.useState("all");
  const queryClient = useQueryClient();

  let bookingFilter = {
    productId: product?.id,
    deleted: false,
  };

  const { data: bookingEntries, isLoading } = useQuery(
    queryKeys.collection("booking_entries", bookingFilter),
    () => fetchCollection("booking_entries", bookingFilter),
    {
      enabled: !!product,
    }
  );

  if (!product || !bookingEntries) {
    return <LegalBirdPageLoading />;
  }

  const filteredBookingEntries = bookingEntries["hydra:member"].filter(
    (bookingEntry) => organizationId === "all" || bookingEntry.organization.id === organizationId
  );

  const { bookingEntriesWithDifference, summedValues } = getBookingEntriesWithDifferenceAndSummedValues(
    _.sortBy(filteredBookingEntries, (bookingEntry) => bookingEntry.bookingDate)
  );

  const connectedReceiptIds = _.map(bookingEntries, (bookingEntry) =>
    bookingEntry.receipt ? bookingEntry.receipt.id : null
  );

  const wiretransferPostSubmit = () => {
    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar
            message={"Überweisung erfolgreich angelegt"}
            buttonType={"link"}
            buttonLink={"/services/tools/ueberweisung"}
            buttonTarget={"_self"}
            buttonText={"Zur Überweisung"}
          />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  };

  return (
    <>
      <CaseRelatedActions product={product} />
      <NavigationExtensionCaseAccountingPage product={product} />
      <PageContentContainer>
        <ProductHeadline title="Konto von" product={product} />
        <Box sx={scrollToAnchor} id={"account"} />
        <Stack
          direction={"row"}
          className={classes.addPaymentRow}
          alignItems={"center"}
          justifyContent={"end"}
          spacing={2}
        >
          <FormControl>
            <InputLabel>Organisation</InputLabel>
            <Select
              label="Organisation"
              value={organizationId}
              onChange={(event) => setOrganizationId(event.target.value)}
              sx={{ minWidth: 200 }}
            >
              <MenuItem value={"all"}>Alle Gesellschaften</MenuItem>
              <MenuItem value={1}>LBRA</MenuItem>
              <MenuItem value={3}>R&S</MenuItem>
            </Select>
          </FormControl>
          <NewBookingEntry
            bookingEntries={bookingEntriesWithDifference}
            product={product}
            updateBookingEntries={() => queryClient.invalidateQueries(queryKeys.collections("booking_entries"))}
          />
        </Stack>
        <TableContainer component={Paper} className={classes.tablePaper}>
          <Table style={{ minWidth: 1000 }}>
            <TableHead>
              <TableRow>
                <TableCell>Typ</TableCell>
                <TableCell>Datum</TableCell>
                <TableCell>Belegnummer</TableCell>
                <TableCell>Fällig am</TableCell>
                <TableCell>Notiz</TableCell>
                <TableCell>Angelegt von</TableCell>
                <TableCell align={"right"}>Soll in €</TableCell>
                <TableCell align={"right"}>Haben in €</TableCell>
                <TableCell align={"right"}>Differenz</TableCell>
                <TableCell align={"right"}>Account</TableCell>
                <TableCell />
                <TableCell width={"10%"} />
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(bookingEntriesWithDifference, (bookingEntry, key) => {
                return (
                  <BookingEntry
                    key={key}
                    bookingEntry={bookingEntry}
                    updateBookingEntries={() => queryClient.invalidateQueries(queryKeys.collections("booking_entries"))}
                    product={product}
                    connectedReceiptIds={connectedReceiptIds}
                    backofficeUsers={backofficeUsers}
                  />
                );
              })}
              <TableRow selected>
                <TableCell className={classes.summaryText}>Summe</TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell className={classes.summaryText} align={"right"}>
                  {summedValues.debit.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </TableCell>
                <TableCell className={classes.summaryText} align={"right"}>
                  {summedValues.credit.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </TableCell>
                <TableCell className={classes.summaryText} align={"right"}>
                  {Math.abs(summedValues.difference).toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  })}
                  {summedValues.difference < 0 ? "S" : "H"}
                </TableCell>
                <TableCell />
                <TableCell />
                <TableCell className={classes.summaryText} />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ marginTop: "4rem", textAlign: "center" }}>
          <Box sx={scrollToAnchor} id={"addWiretransfer"} />
          <Typography variant="h2">Überweisung / Auskehrungen</Typography>
        </Box>
        <AddWiretransfer postSubmitCallback={wiretransferPostSubmit} currentCase={product} />
      </PageContentContainer>
    </>
  );
};

export default CaseAccountingPage;
