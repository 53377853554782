import { Message } from "../types/Messaging/Message";
import { apiPost } from "./Api/apiCall";

export const markMessagesAsRead = async (messages: Array<Message>) => {
  let promises: Array<Promise<any>> = [];
  messages.forEach((message) => {
    if (!message.readByMe) {
      promises.push(
        apiPost("/message_user_read_statuses", {
          message: "/messages/" + message.id,
        })
      );
    }
  });
  await Promise.all(promises);
};
