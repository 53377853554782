const processCostSupportTranslations = {
  petitioner: {
    gender: {
      label: "Anrede",
      values: {
        male: "Herr",
        female: "Frau",
      },
    },
    givenName: {
      label: "Vorname",
    },
    familyName: {
      label: "Nachname",
    },
    nationality: {
      label: "Staatsangehörigkeit",
    },
    residenceAddress: {
      streetAddress: {
        label: "Straße und Hausnummer",
      },
      postalCode: {
        label: "PLZ",
      },
      addressLocality: {
        label: "Ort",
      },
    },
    addresses: [
      {
        streetAddress: {
          label: "Straße und Hausnummer",
        },
        postalCode: {
          label: "PLZ",
        },
        addressLocality: {
          label: "Ort",
        },
      },
    ],
  },
};

export default processCostSupportTranslations;
