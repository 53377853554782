const hitStyles = {
  headline: {
    fontWeight: "700",
    marginBottom: "0.25rem",
  },
  cardActionArea: {
    padding: "1rem",
    fontSize: "1rem",
  }
}
export default hitStyles;