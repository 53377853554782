import React, { useMemo } from "react";
import Grid from "@mui/material/Grid";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import { formValue } from "../../../services/formServiceFunctions";
import MenuItem from "@mui/material/MenuItem";
import ValidatorTextField from "../../Validator/ValidatorTextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Editor } from "react-draft-wysiwyg";
import casePdfGeneratorStyle from "./casePdfGeneratorStyle";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { convertToRaw } from "draft-js";
import _ from "lodash";
import { getAdvoAssistRepresentative, isContractProduct } from "../../../services/Product/ProductService";
import { matchRegexp } from "../../../services/validationRules";
import {
  getExternalReference,
  getReferenceOptions,
  isContractualPartnerDataComplete,
  isDebtCollectionAgencyDataComplete,
  isOpponentLawyerDataComplete,
} from "./casePdfGeneratorService";
import { translate } from "../../../services/Translations/translatorService";
import { saveSessionStorageState } from "../../../services/browserStorageService";
import PropTypes from "prop-types";

const useStyles = makeStyles(casePdfGeneratorStyle);

const PdfGeneratorFormElements = ({
  product,
  values,
  handleChange,
  handleBlur,
  errors,
  registerValidators,
  mediaObjects,
  minimalView,
  editorToolBarCustomButtons,
}) => {
  const classes = useStyles();

  const handleEditorStateChange = (editorState) => {
    handleChange({
      target: {
        name: "content",
        value: editorState,
      },
    });
    saveFormDataToSessionStorage();
  };

  const prefillExternalReference = ({ target }) => {
    handleChange({ target });
    handleChange({
      target: {
        name: "externalReference",
        value: getExternalReference(product, target.value),
      },
    });
  };

  const saveFormDataToSessionStorage = _.throttle(() => {
    let valuesToPersist = _.merge({}, values);
    valuesToPersist.content = convertToRaw(values.content.getCurrentContent());
    saveSessionStorageState("customPdf", valuesToPersist);
  }, 3000);

  const renderSelectedMediaObject = (selected) => {
    //map over the selected array and find the related mediaobject to get its description
    if (!mediaObjects || !mediaObjects["hydra:member"]) {
      return "";
    }
    return _.map(
      selected,
      (selectedMediaObject) =>
        _.find(mediaObjects["hydra:member"], (mediaObject) => mediaObject.id === selectedMediaObject).description
    ).join(", ");
  };

  const referenceOptions = useMemo(
    () => _.filter(getReferenceOptions(product), (option) => !option.isHidden),
    [product]
  );

  const advoAssistRepresentative = useMemo(() => getAdvoAssistRepresentative(product), [product]);

  return (
    <Grid container alignItems={"center"} justifyContent={"center"} spacing={3}>
      <Grid item xs={12} md={6}>
        <ValidatorSelect
          label={"Empfänger"}
          name={"recipientType"}
          value={formValue(values, "recipientType")}
          helperText={"An wen soll das Schreiben adressiert sein?"}
          onChange={handleChange}
        >
          {product.insurance?.insurance && (
            <MenuItem value={"legalExpenseInsurance"}>Rechtsschutzversicherung</MenuItem>
          )}
          {product.responsibleCourt && <MenuItem value={"court"}>Zuständiges Gericht</MenuItem>}
          {product && product.responsibleAuthority && <MenuItem value={"authority"}>Zuständige Behörde</MenuItem>}
          {product.responsibleProsecution && <MenuItem value={"prosecution"}>Zuständige Staatsanwaltschaft</MenuItem>}
          {product.employerOrganization && <MenuItem value={"employerOrganization"}>Adresse Gegenseite</MenuItem>}
          <MenuItem value={"petitioner"}>Mandant</MenuItem>
          {isContractProduct(product.productClassName) && isContractualPartnerDataComplete(product) && (
            <MenuItem value={"contractualPartnerLegalEntity"}>Gegenseite</MenuItem>
          )}
          {(isContractProduct(product.productClassName) ||
            ["settlement", "divorce"].includes(product.productClassName)) &&
            isOpponentLawyerDataComplete(product) && <MenuItem value={"opponentLawFirm"}>Anwalt Gegenseite</MenuItem>}
          {isContractProduct(product.productClassName) && isDebtCollectionAgencyDataComplete(product) && (
            <MenuItem value={"debtCollectionAgency"}>Inkassobüro</MenuItem>
          )}
          {advoAssistRepresentative && <MenuItem value={"advoAssistRepresentative"}>Terminsvertreter</MenuItem>}
          {!!product.processParticipantsPeople &&
            _.map(product.processParticipantsPeople, (person, index) => {
              return (
                <MenuItem key={index} value={"processParticipantsPeople[" + index + "]"}>
                  {translate("participant.labelType.values." + person.labelType) +
                    " - " +
                    person.givenName +
                    " " +
                    person.familyName}
                </MenuItem>
              );
            })}
          {!!product.processParticipantsOrganizations &&
            _.map(product.processParticipantsOrganizations, (organization, index) => {
              return (
                <MenuItem key={index} value={"processParticipantsOrganizations[" + index + "]"}>
                  {translate("participant.labelType.values." + organization.labelType) + " - " + organization.legalName}
                </MenuItem>
              );
            })}
          <MenuItem value={"custom"}>Empfänger frei wählen</MenuItem>
        </ValidatorSelect>
      </Grid>
      <Grid item xs={12} md={6}>
        <ValidatorTextField
          label={"Dateiname"}
          name={"filename"}
          value={formValue(values, "filename")}
          onChange={handleChange}
          onBlur={handleBlur}
          registerValidators={registerValidators}
          validators={[
            {
              validator: matchRegexp,
              params: [new RegExp(/^([a-zA-Z]|_|-|[0-9])*$/)],
              message: "Bitte nur Buchstaben (keine Umlaute), Ganzzahlen, Binde- und Unterstriche verwenden.",
            },
          ]}
          error={!!errors["filename"]}
          helperText={errors["filename"] || "JJJJMMTT_Name_des_Schreibens"}
          InputProps={{
            endAdornment: <InputAdornment position="end">.pdf</InputAdornment>,
          }}
        />
      </Grid>
      {values.recipientType === "custom" && (
        <>
          <Grid item xs={12} md={6}>
            <ValidatorTextField
              label={"Empfänger"}
              name={"address.name"}
              value={formValue(values, "address.name")}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ValidatorTextField
              label={"Empfänger Zeile 2"}
              name={"address.department"}
              value={formValue(values, "address.department")}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ValidatorTextField
              label={"Empfänger Zeile 3"}
              name={"address.subDepartment"}
              value={formValue(values, "address.subDepartment")}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ValidatorTextField
              label={"Straße und Hausnummer"}
              name={"address.streetAddress"}
              value={formValue(values, "address.streetAddress")}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ValidatorTextField
              label={"Postleitzahl"}
              name={"address.postalCode"}
              value={formValue(values, "address.postalCode")}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ValidatorTextField
              label={"Ort"}
              name={"address.addressLocality"}
              value={formValue(values, "address.addressLocality")}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </>
      )}
      {!minimalView && (
        <>
          <Grid item xs={12} md={4}>
            <ValidatorSelect
              label={"Zustellung per"}
              name={"senderMedium"}
              value={formValue(values, "senderMedium")}
              onChange={handleChange}
            >
              <MenuItem value={"none"}>Keine Anzeige</MenuItem>
              <MenuItem value={"fax"}>per Fax</MenuItem>
              <MenuItem value={"bea"}>über beA</MenuItem>
              <MenuItem value={"email"}>per E-Mail</MenuItem>
              <MenuItem value={"registeredMail"}>per Einschreiben</MenuItem>
            </ValidatorSelect>
          </Grid>
        </>
      )}
      <Grid item xs={12} md={4}>
        <ValidatorSelect
          label={"Aktenzeichenauswahl"}
          name={"externalReferenceSelect"}
          value={formValue(values, "externalReferenceSelect")}
          onChange={prefillExternalReference}
        >
          <MenuItem value={"free"}>Aktenzeichen frei eingeben</MenuItem>
          {_.map(referenceOptions, (referenceOption, index) => (
            <MenuItem value={referenceOption.type} key={index}>
              {referenceOption.label}
            </MenuItem>
          ))}
        </ValidatorSelect>
      </Grid>
      <Grid item xs={12} md={4}>
        <ValidatorTextField
          label={"Aktenzeichen"}
          name={"externalReference"}
          disabled={values.externalReferenceSelect !== "free"}
          value={formValue(values, "externalReference")}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>
      {!minimalView && (
        <>
          <Grid item xs={12} md={4}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Für Kunden sichtbar</FormLabel>
              <RadioGroup row={true} name={"showInGallery"} value={values.showInGallery} onChange={handleChange}>
                <FormControlLabel
                  disabled={values.sendEmailToCustomer === "true"}
                  value={"true"}
                  control={<Radio />}
                  label={"Ja"}
                />
                <FormControlLabel
                  disabled={values.sendEmailToCustomer === "true"}
                  value={"false"}
                  control={<Radio />}
                  label={"Nein"}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl component="fieldset">
              <FormLabel component="legend">E-Mail an Kunden schicken</FormLabel>
              <RadioGroup
                row={true}
                name={"sendEmailToCustomer"}
                value={values.sendEmailToCustomer}
                onChange={(e) => {
                  handleChange(e);
                  handleChange({ target: { name: "showInGallery", value: "true" } });
                }}
              >
                <FormControlLabel value={"true"} control={<Radio />} label={"Ja"} />
                <FormControlLabel value={"false"} control={<Radio />} label={"Nein"} />
              </RadioGroup>
            </FormControl>
          </Grid>
        </>
      )}
      <Grid item xs={12} md={4}>
        <ValidatorSelect
          label={"Dokument anhängen"}
          name={"selectedMediaObjects"}
          multiple
          value={values.selectedMediaObjects}
          onChange={handleChange}
          onBlur={handleBlur}
          renderValue={renderSelectedMediaObject}
        >
          {_.map(mediaObjects ? mediaObjects["hydra:member"] : [], (mediaObject) => (
            <MenuItem
              key={mediaObject.id}
              value={mediaObject.id}
              disabled={mediaObject.mimeType !== "application/pdf" || mediaObject.size > 2000000}
            >
              <Checkbox
                checked={
                  !!_.find(
                    formValue(values, "selectedMediaObjects"),
                    (selectedMediaObject) => selectedMediaObject === mediaObject.id
                  )
                }
              />
              <ListItemText primary={mediaObject.description} />
            </MenuItem>
          ))}
        </ValidatorSelect>
      </Grid>
      <Grid item style={{ width: "100%", maxWidth: 670 }}>
        <Paper className={classes.paper}>
          <Editor
            toolbar={{
              options: ["inline", "list", "textAlign", "history"],
              inline: {
                options: ["bold", "italic", "underline"],
              },
              list: {
                options: ["unordered", "ordered"],
              },
              textAlign: {
                inDropdown: true,
              },
            }}
            editorStyle={{
              fontFamily: "RobotoSlab",
              fontWeight: 300,
              fontSize: "15px",
              color: "#555",
            }}
            stripPastedStyles
            editorState={formValue(values, "content")}
            onEditorStateChange={handleEditorStateChange}
            editorClassName={classes.editor}
            placeholder={"Freitext"}
            toolbarCustomButtons={editorToolBarCustomButtons}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

PdfGeneratorFormElements.propTypes = {
  product: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  registerValidators: PropTypes.func.isRequired,
  mediaObjects: PropTypes.object,
  minimalView: PropTypes.bool,
  editorToolBarCustomButtons: PropTypes.array,
};

PdfGeneratorFormElements.defaultProps = {
  minimalView: false,
  editorToolBarCustomButtons: [],
};

export default PdfGeneratorFormElements;
