import documentTransformations from "./documentTransformations";

const alimonyTransformations = {
  dateFields: [
    { path: "dateSeparation", type: "date" },
    { path: "dateDivorce", type: "date" },
    { path: "dateMarriage", type: "date" },
  ],
  numberFields: [...documentTransformations.numberFields, "numberOfChildren"],
  moneyFields: [
    ...documentTransformations.moneyFields,
    "alimonyPetitioner.netIncome",
    "alimonyPetitioner.ohterIncome",
    "alimonyPetitioner.livingExpenses",
    "alimonyPetitioner.ohterExpenses",
    "alimonyPartner.netIncome",
    "alimonyPartner.ohterIncome",
    "alimonyPartner.livingExpenses",
    "alimonyPartner.ohterExpenses",
  ],
  timeFields: [...documentTransformations.timeFields],
};
export default alimonyTransformations;
