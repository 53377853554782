import backgroundSvg from "../../assets/background/wave_background.svg";

const loginPageBackgroundStyles = {
  background: {
    height: 1000,
    backgroundImage: "url(" + backgroundSvg + ")",
    backgroundRepeat: "no-repeat",
  },
};

export default loginPageBackgroundStyles;
