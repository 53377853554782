import React, { useEffect } from "react";
import * as PropTypes from "prop-types";
import { bool } from "prop-types";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import useStyles from "./validatorStyle";

import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import DateChangeButtonsToolbar from "./DateChangeButtonsToolbar";

const ValidatorDateField = ({
  name,
  value,
  helperText,
  registerValidators,
  validators,
  dependentValidationFields,
  isMandatory,
  onBlur,
  withSkipWeeksToolbar,
  ...rest
}) => {
  const classes = useStyles();

  useEffect(() => {
    registerValidators && validators && registerValidators(name, validators, dependentValidationFields);
    return () => {
      registerValidators && registerValidators(name, []);
    };
  }, [registerValidators, validators, dependentValidationFields, name]);

  return (
    <FormControl fullWidth={true} className={isMandatory && !value ? classes.mandatory : null} error={!!rest.error}>
      <DatePicker
        {...rest}
        value={value}
        renderInput={(props) => (
          <TextField
            {...props}
            inputProps={{
              ...props.inputProps,
              placeholder: "tt.mm.jjjj",
            }}
          />
        )}
        mask={"__.__.____"}
        onAccept={(date) => {
          if (!onBlur) {
            return;
          }
          onBlur({ target: { name: name, value: date } });
        }}
        ToolbarComponent={withSkipWeeksToolbar ? DateChangeButtonsToolbar : null}
        showToolbar={withSkipWeeksToolbar}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

ValidatorDateField.propTypes = {
  validators: PropTypes.arrayOf(PropTypes.object),
  dependentValidationFields: PropTypes.array,
  label: PropTypes.string.isRequired,
  format: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isMandatory: PropTypes.bool,
  withSkipWeeksToolbar: bool,
};

ValidatorDateField.defaultProps = {
  format: "DD.MM.YYYY",
  isMandatory: false,
  dependentValidationFields: [],
  withSkipWeeksToolbar: false,
};

export default ValidatorDateField;
