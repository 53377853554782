import React from "react";
import _ from "lodash";
import { getRoleToDisplay, sortByRole } from "../../../../services/backofficeUserService";
import LegalbirdAutoComplete from "../../../AutoComplete/LegalbirdAutoComplete";

const getOptions = (agents, additionalOptions = []) => {
  const users = _.map(sortByRole(agents), (agent) => {
    return {
      label: agent.person.fullname,
      id: agent["@id"],
      groupBy: getRoleToDisplay(agent),
    };
  });

  return [{ label: "Alle Benutzer", id: "all" }, ...additionalOptions, ...users];
};

const UserFilter = ({ userFilter, setUserFilter, agents, isDeadlineView }) => {
  if (_.isEmpty(agents)) {
    return null;
  }

  const options = getOptions(agents, isDeadlineView ? [] : [{ label: "Alle Kundenberater", id: "accountManager" }, { label: "Alle Anwälte", id: "lawyer" }]);

  const value = _.find(options, (option) => option.id === userFilter);

  return (
    <LegalbirdAutoComplete
      label="Nutzer"
      value={value}
      options={options}
      groupBy={(option) => option.groupBy}
      setValue={(event, value) => {
        setUserFilter(value.id);
      }}
    />
  );
};

export default UserFilter;
