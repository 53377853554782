import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  mandatory: {
    backgroundColor: "#c4dfde",
  },
  icon: { marginRight: "1.5rem" },
}));

export default useStyles;
