import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import editableTitleStyle from "./editableTitleStyle";
import { IconButton, Stack, TextField, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CheckOutlined, ClearOutlined, EditOutlined } from "@mui/icons-material";

const useStyles = makeStyles(editableTitleStyle);

export default function EditableTitle({ handleSave, title }) {
  const [editableTitle, setEditableTitle] = useState(title);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setEditableTitle(title);
  }, [title]);

  const handleAbort = () => {
    setEditableTitle(title);
    setEditMode(false);
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    await handleSave(editableTitle);
    setEditMode(false);
    setIsLoading(false);
  };

  const classes = useStyles();

  if (editMode) {
    return (
      <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
        <TextField
          className={classes.textField}
          value={editableTitle}
          onChange={(e) => setEditableTitle(e.target.value)}
        />
        <Tooltip title={"Änderung speichern"}>
          <IconButton onClick={handleConfirm} disabled={editableTitle.length < 5 || isLoading}>
            <CheckOutlined />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Änderungen verwerfen"}>
          <IconButton onClick={handleAbort} disabled={isLoading}>
            <ClearOutlined />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
      <span className={classes.title}>{title}</span>
      <Tooltip title={"Beschreibung bearbeiten"}>
        <IconButton onClick={() => setEditMode(true)}>
          <EditOutlined />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}
EditableTitle.propTypes = {
  handleSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

EditableTitle.defaultProps = {};
