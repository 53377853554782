import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

type MediaObjectOrderBySelectProps = { setOrderBy: Function; orderBy: string };

export default function MediaObjectOrderBySelect({ orderBy, setOrderBy }: MediaObjectOrderBySelectProps) {
    return (
        <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Sortiert nach</InputLabel>
            <Select onChange={(e) => setOrderBy(e.target.value)} value={orderBy} label={"Sortiert nach"}>
                <MenuItem value={"createdDESC"}>Datum absteigend</MenuItem>
                <MenuItem value={"createdASC"}>Datum aufsteigend</MenuItem>
                <MenuItem value={"originalNameDESC"}>Dateiname absteigend</MenuItem>
                <MenuItem value={"originalNameASC"}>Dateiname aufsteigend</MenuItem>
            </Select>
        </FormControl>
    );
}
