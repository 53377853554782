import React, { useState } from "react";
import * as PropTypes from "prop-types";
import TemplateEditor from "./TemplateEditor";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ButtonLoading from "../Button/ButtonLoading";

const CreateNewTemplate = ({ refreshTemplateList, initialProduct, type }) => {
  const [mailEditorOpen, setMailEditorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitCallback = async () => {
    setIsLoading(true);
    await refreshTemplateList();
    setIsLoading(false);
    setMailEditorOpen(false);
  };

  return (
    <>
      <ButtonLoading isLoading={isLoading} onClick={() => setMailEditorOpen(true)}>
        Neue Vorlage anlegen
      </ButtonLoading>
      <LegalbirdIoModal handleClose={() => setMailEditorOpen(false)} open={mailEditorOpen} title={"Vorlage"}>
        <TemplateEditor
          onSubmitCallback={onSubmitCallback}
          initialProduct={initialProduct}
          type={type}
          withSubject={type === "email"}
        />
      </LegalbirdIoModal>
    </>
  );
};

CreateNewTemplate.propTypes = {
  refreshTemplateList: PropTypes.func,
  initialProduct: PropTypes.string,
  type: PropTypes.string.isRequired,
};

CreateNewTemplate.defaultProps = {
  initialProduct: "divorce",
  refreshTemplateList: () => {},
};

export default CreateNewTemplate;
