import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import ApiClient from "../../../services/ApiClient";
import {
  buildApiFilters,
  buildSearchParams,
  postProcessFilterActivities,
} from "../../../services/Activities/activityFunctions";
import useActivitiesFetching from "../../../hooks/useActivitiesFetching";
import PageContentContainer from "../../../components/Container/PageContentContainer";
import OverviewActivityEntry from "./OverviewActivityEntry";
import { Divider, Grid, Tab, Tabs } from "@mui/material";
import ActivityOverviewHeader from "../ActivityOverviewHeader/ActivityOverviewHeader";
import useStyles from "./activityOverviewStyle";
import UnassignedActivities from "../UnassignedActivities/UnassignedActivities";
import { TabContext, TabPanel } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import { tabItemStyles } from "../../../components/Activities/ActivityOverview/activityTabsStyle";
import { FormatListBulleted, MailOutline, PlaylistAdd } from "@mui/icons-material";
import { userHasOneOfTheseRoles } from "../../../services/backofficeUserService";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";
import { Roles } from "../../../types/BackofficeUser";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";

const useTabItemStyles = makeStyles(tabItemStyles);

const prepareFilters = (currentUser, agents) => {
  const storedFilters = JSON.parse(localStorage.getItem("activityFiltersMobile"));
  let currentFilters = _.merge(
    {
      productFilter: "all",
      activityTypeFilter: "all",
      timespanFilter: "today",
      openOrDoneFilter: "open",
    },
    storedFilters
  );

  if (_.isObjectLike(currentFilters.timespanFilter)) {
    currentFilters.timespanFilter.startDate = _.isString(currentFilters.timespanFilter.startDate)
      ? moment(currentFilters.timespanFilter.startDate)
      : currentFilters.timespanFilter.startDate;
    currentFilters.timespanFilter.endDate = _.isString(currentFilters.timespanFilter.endDate)
      ? moment(currentFilters.timespanFilter.endDate)
      : currentFilters.timespanFilter.endDate;
  }

  if (!currentFilters.userFilter) {
    currentFilters.userFilter = !!_.find(agents, (agent) => agent.id === currentUser.id) ? currentUser["@id"] : "all";
  }

  return currentFilters;
};

const ActivityOverview = () => {
  const currentUser = useCurrentUser();
  const { agents, backofficeUsers } = useBackofficeUser();
  const classes = useStyles({});
  const tabItemStyles = useTabItemStyles();

  const initialFilters = prepareFilters(currentUser, agents);
  const initialTab = localStorage.getItem("savedTab") || "activities";
  const [productFilter, setProductFilter] = useState(initialFilters.productFilter);
  const [activityTypeFilter, setActivityTypeFilter] = useState(initialFilters.activityTypeFilter);
  const [userFilter, setUserFilter] = useState(initialFilters.userFilter);
  const [timespanFilter, setTimespanFilter] = useState(initialFilters.timespanFilter);
  const [userActivitiesToday, setUserActivitiesToday] = useState([]);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [mailAndDocumentActivities, setMailAndDocumentActivities] = useState([]);
  const [checkCaseActivities, setCheckCaseActivities] = useState([]);
  const [selectedTab, setSelectedTab] = useState(initialTab);
  const [openOrDoneFilter, setOpenOrDoneFilter] = useState(initialFilters.openOrDoneFilter);

  const dataSource = buildSearchParams(
    _.merge(
      buildApiFilters({
        productFilter: productFilter,
        activityTypeFilter: activityTypeFilter,
        userFilter: userFilter,
        timespanFilter: timespanFilter,
        openOrDoneFilter: openOrDoneFilter,
        backofficeUsers: backofficeUsers,
      }),
      {
        refreshCounter: refreshCounter,
      }
    )
  );
  const { activities, isLoading } = useActivitiesFetching(dataSource.toString(), backofficeUsers);

  const updateUserActivitiesToday = useCallback(async () => {
    if (userFilter === "all") {
      setUserActivitiesToday([]);
      return;
    }
    const filters = {
      assignedUser: userFilter,
      "dueDate[before]": moment().format("YYYY-MM-DD"),
      done: false,
      deleted: false,
      pagination: false,
    };

    let searchParams = new URLSearchParams();
    _.map(filters, (value, key) => {
      searchParams.append(key, value);
    });

    const userActivitiesResult = await ApiClient.get("activities?" + searchParams.toString());
    setUserActivitiesToday(userActivitiesResult["hydra:member"]);
  }, [userFilter]);

  const updateActivities = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  useEffect(() => {
    localStorage.setItem(
      "activityFiltersMobile",
      JSON.stringify(
        _.merge(
          {},
          {
            productFilter: productFilter,
            activityTypeFilter: activityTypeFilter,
            userFilter: userFilter,
            timespanFilter: timespanFilter,
            openOrDoneFilter: openOrDoneFilter,
          }
        )
      )
    );
  }, [productFilter, userFilter, timespanFilter, activityTypeFilter]);

  useEffect(() => {
    updateUserActivitiesToday();
  }, [userFilter, updateUserActivitiesToday]);

  useEffect(() => {
    let filteredActivities = isLoading ? [] : postProcessFilterActivities(activities, timespanFilter);
    setMailAndDocumentActivities(
      _.remove(filteredActivities, (activity) => _.includes(["mail", "check_document"], activity.type))
    );
    setCheckCaseActivities(_.remove(filteredActivities, (activity) => activity.type === "check_case"));
    setFilteredActivities(filteredActivities);
  }, [
    activities,
    isLoading,
    setMailAndDocumentActivities,
    setCheckCaseActivities,
    setFilteredActivities,
  ]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    localStorage.setItem("savedTab", newValue);
  };

  const TabIcon = ({ icon: Icon, listLength }) => {
    return (
      <Grid container alignItems={"flex-start"}>
        <Grid item xs={3} />
        <Grid item xs={6}>
          <Icon className={classes.icon} />
        </Grid>
        <Grid item xs={3}>
          {!isLoading && listLength > 0 && <span className={"MuiTabItem-tag"}>{listLength}</span>}
        </Grid>
      </Grid>
    );
  };

  return (
    <PageContentContainer>
      {userHasOneOfTheseRoles(currentUser, [Roles.accountmanager, Roles.traineeLawyer, Roles.admin]) && (
        <UnassignedActivities updateFilteredActivities={() => setRefreshCounter(refreshCounter + 1)} />
      )}
      <ActivityOverviewHeader
        activityCount={filteredActivities.length + mailAndDocumentActivities.length + checkCaseActivities.length}
        setProductFilter={(data) => {
          updateActivities();
          setProductFilter(data);
        }}
        productFilter={productFilter}
        setActivityTypeFilter={(data) => {
          updateActivities();
          setActivityTypeFilter(data);
        }}
        activityTypeFilter={activityTypeFilter}
        userFilter={userFilter}
        setUserFilter={(data) => {
          updateActivities();
          setUserFilter(data);
        }}
        timespanFilter={timespanFilter}
        setTimespanFilter={(data) => {
          updateActivities();
          setTimespanFilter(data);
        }}
        openOrDoneFilter={openOrDoneFilter}
        setOpenOrDoneFilter={(data) => {
          updateActivities();
          setOpenOrDoneFilter(data);
        }}
        userActivitiesToday={userActivitiesToday}
        isLoading={isLoading}
        agents={agents}
      />
      <TabContext value={selectedTab}>
        <Tabs value={selectedTab} className={classes.tabs} onChange={handleTabChange}>
          <Tab
            classes={tabItemStyles}
            icon={<TabIcon icon={FormatListBulleted} listLength={filteredActivities.length} />}
            value="activities"
          />
          <Tab
            classes={tabItemStyles}
            icon={<TabIcon icon={MailOutline} listLength={mailAndDocumentActivities.length} />}
            value="inbox"
          />
          <Tab
            classes={tabItemStyles}
            icon={<TabIcon icon={PlaylistAdd} listLength={checkCaseActivities.length} />}
            value="checkCases"
          />
        </Tabs>
        <TabPanel className={classes.tabPanel} value="activities">
          {selectedTab === "activities" &&
            _.map(filteredActivities, (activity, index) => (
              <React.Fragment key={activity.id}>
                <OverviewActivityEntry activity={activity} update={updateActivities} />
                {index !== filteredActivities.length - 1 && (
                  <div className={classes.divider}>
                    <Divider />
                  </div>
                )}
              </React.Fragment>
            ))}
        </TabPanel>
        <TabPanel className={classes.tabPanel} value="inbox">
          {selectedTab === "inbox" &&
            _.map(mailAndDocumentActivities, (activity, index) => (
              <React.Fragment key={activity.id}>
                <OverviewActivityEntry activity={activity} update={updateActivities} />
                {index !== mailAndDocumentActivities.length - 1 && (
                  <div className={classes.divider}>
                    <Divider />
                  </div>
                )}
              </React.Fragment>
            ))}
        </TabPanel>
        <TabPanel className={classes.tabPanel} value="checkCases">
          {selectedTab === "checkCases" &&
            _.map(checkCaseActivities, (activity, index) => (
              <React.Fragment key={activity.id}>
                <OverviewActivityEntry activity={activity} update={updateActivities} />
                {index !== checkCaseActivities.length - 1 && (
                  <div className={classes.divider}>
                    <Divider />
                  </div>
                )}
              </React.Fragment>
            ))}
        </TabPanel>
      </TabContext>
    </PageContentContainer>
  );
};

export default ActivityOverview;
