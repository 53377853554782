import React, { useMemo } from "react";
import { useTechStatus } from "../../provider/TechStatusProvider";
import { Box } from "@mui/material";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

const InfoBar = () => {
  const techStatus = useTechStatus();
  const currentUser = useCurrentUser();

  const showTechnicalError = useMemo(() => {
    if (!techStatus?.technicalError) {
      return false;
    }

    if (
      (currentUser.isExternal && !techStatus.technicalError.showExternal) ||
      (!currentUser.isExternal && !techStatus.technicalError.showInternal)
    ) {
      return false;
    }

    return techStatus?.technicalError && techStatus.technicalError.isActive;
  }, [techStatus?.technicalError]);

  if (!techStatus || !(showTechnicalError || techStatus.release)) {
    return null;
  }

  return (
    <Box textAlign={"center"} backgroundColor={showTechnicalError ? "#f8d7da" : "#fff3cd"}>
      <Box padding={"1rem"}>
        {showTechnicalError && techStatus.technicalError.errorMessage}
        <br />
        {!!techStatus.release && <>Release läuft - Legalbird.io kann in wenigen Minuten wieder benutzt werden.</>}
      </Box>
    </Box>
  );
};

export default InfoBar;
