import addressTranslations from "./addressTranslations";

export const opponentLawFirmTranslations = {
  name: {
    label: "Kanzlei",
  },
  email: {
    label: "E-Mail-Adresse",
  },
  telephone: {
    label: "Telefonnummer",
  },
  fax: {
    label: "Faxnummer",
  },
  beaSafeId: {
    label: "beA-Adresse",
  },
  address: addressTranslations,
};
