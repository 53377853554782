import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  headline: {
    fontSize: "1.5rem",
    textAlign: "center",
    margin: "2rem",
  },
  activityCount: {
    fontSize: "1rem",
    fontWeight: 700,
  },
  filterContainer: {
    padding: "1rem",
  },
  activityTable: {
    minWidth: 650,
  },
  spinner: {
    display: "block",
    margin: "0 auto",
  },
  note: {
    paddingRight: 10,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxHeight: 48,
    maxWidth: 100,
  },
  singleLineOverflowHidden: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  subject: {
    fontWeight: 500,
  },
  activityButton: {
    fontSize: 10,
    padding: 3,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  done: {
    textDecoration: "line-through",
  },
  overdue: {
    color: "#db1919",
  },
  mandatoryCell: {
    minWidth: 130,
  },
  descriptionCell: {
    minWidth: 170,
  },
  noteCell: {
    minWidth: 170,
  },
  activeSorting: {
    color: "#137F7B",
  },
  smallFont: {
    fontSize: 10,
  },
  divider: {
    margin: "1rem 0",
  },
  warningIcon: {
    width: 12,
    height: 12,
  },
  sortableHeadline: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  tabPanel: {
    padding: 0,
  },
  tabs: {
    backgroundColor: "#FFFFFF",
    margin: "0 -1rem 1rem -1rem",
  },
  icon: {
    color: "#333333",
  },
}));

export default useStyles;
