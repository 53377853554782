import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  badge: {
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 5,
    borderColor: "#137f7b",
    textAlign: "center",
    padding: "8px 0 8px 0",
    width: 120,
    fontSize: 16,
  },
  activeBadge: {
    backgroundColor: "#137f7b",
    color: "#fff",
  },
  inactiveBadge: {
    color: "#333",
    cursor: "pointer",
  },
  timespanButton: {
    width: 202,
  },
}));

export default useStyles;
