export const API_HOST = process.env.REACT_APP_API_HOST;
export const API_PATH = "";
export const ALGOLIA_SEARCH_API_KEY = process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY || "";
export const ALGOLIA_APPLICATION_ID = process.env.REACT_APP_ALGOLIA_APPLICATION_ID || "";
export const ALGOLIA_INDEX = process.env.REACT_APP_ALGOLIA_INDEX || "";
export const ALGOLIA_AUTHORITY_INDEX = process.env.REACT_APP_ALGOLIA_AUTHORITY_INDEX || "" ;
export const ALGOLIA_PROSECUTION_INDEX = process.env.REACT_APP_ALGOLIA_PROSECUTION_INDEX || "";
export const ALGOLIA_COURT_INDEX = process.env.REACT_APP_ALGOLIA_COURT_INDEX || "";
export const ALGOLIA_ORGANIZATION_INDEX = process.env.REACT_APP_ALGOLIA_ORGANIZATION_INDEX || "";
export const MERCURE_URL = process.env.REACT_APP_MERCURE_URL;
export const MERCURE_PREFIX = process.env.REACT_APP_MERCURE_PREFIX;
export const LEGALBIRD_BASE_URL = process.env.REACT_APP_LEGALBIRD_BASE_URL;
