import React, { useEffect } from "react";
import { convertFromRaw, EditorState } from "draft-js";
import _ from "lodash";
import { editorStateToHTML } from "../../services/Editor/editorFunctions";
import { loadSessionStorageState } from "../../services/browserStorageService";
import { buildAddress } from "../Case/CasePdfGenerator/casePdfGeneratorService";
import PdfGeneratorFormElements from "../Case/CasePdfGenerator/PdfGeneratorFormElements";
import PdfGeneratorPreview from "../Case/CasePdfGenerator/PdfGeneratorPreview";
import makeStyles from "@mui/styles/makeStyles";
import casePaidTaskPdfGeneratorStyle from "./casePaidTaskPdfGeneratorStyle";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { getMediaObjectFilters } from "../../services/mediaObjectService";

const useStyles = makeStyles(casePaidTaskPdfGeneratorStyle);

const CasePaidTaskPdfGenerator = ({
  product,
  paidTask,
  values,
  handleChange,
  handleBlur,
  errors,
  registerValidators,
  updateAllValues,
  initialValues,
}) => {
  const currentUser = useCurrentUser();
  const classes = useStyles();

  const mediaObjectsFilter = getMediaObjectFilters(product, currentUser);

  const { data: mediaObjects } = useQuery(
    queryKeys.collection("media_objects", mediaObjectsFilter),
    () => fetchCollection("media_objects", mediaObjectsFilter),
    {
      enabled: !!product,
    }
  );
  const getParams = () => {
    return _.merge({}, values, {
      content: editorStateToHTML(values.content),
      address: buildAddress(values, product),
      externalReference: !_.isEmpty(values.externalReference) ? values.externalReference : null,
      senderMedium: values.senderMedium === "none" ? null : values.senderMedium,
      externalLawyer: paidTask.assignee,
    });
  };

  useEffect(() => {
    const sessionStorageValues = loadSessionStorageState("customPdf");
    if (sessionStorageValues) {
      updateAllValues(
        _.merge(initialValues, {
          recipientType: sessionStorageValues.recipientType,
          address: {
            name: sessionStorageValues.address.name,
            department: sessionStorageValues.address.department,
            subDepartment: sessionStorageValues.address.subDepartment,
            streetAddress: sessionStorageValues.address.streetAddress,
            postalCode: sessionStorageValues.address.postalCode,
            addressLocality: sessionStorageValues.address.addressLocality,
          },
          senderMedium: sessionStorageValues.senderMedium,
          externalReference: sessionStorageValues.externalReference,
          externalReferenceSelect: sessionStorageValues.externalReferenceSelect,
          filename: sessionStorageValues.filename,
          content: EditorState.createWithContent(convertFromRaw(sessionStorageValues.content)),
          showInGallery: sessionStorageValues.showInGallery,
          sendEmailToCustomer: sessionStorageValues.sendEmailToCustomer,
          selectedMediaObjects: sessionStorageValues.selectedMediaObjects,
        })
      );
    }
  }, [updateAllValues, initialValues]);

  return (
    <div className={classes.pdfGenerator}>
      <PdfGeneratorFormElements
        values={values}
        product={product}
        registerValidators={registerValidators}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        mediaObjects={mediaObjects}
        minimalView
      />
      <PdfGeneratorPreview product={product} values={values} getParams={getParams} mediaObjects={mediaObjects} />
    </div>
  );
};

CasePaidTaskPdfGenerator.defaultProps = {
  documentTypeLabel: "Individuelles",
  ctaLabel: "Schreiben generieren",
};

export default CasePaidTaskPdfGenerator;
