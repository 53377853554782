import PropTypes from "prop-types";
import React, { useState } from "react";
import _ from "lodash";
import ApiClient from "../../../services/ApiClient";
import SubmitButton from "../../Button/SubmitButton";
import { Grid, MenuItem } from "@mui/material";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import { translate } from "../../../services/Translations/translatorService";
import { formValue } from "../../../services/formServiceFunctions";
import useForm from "../../../hooks/useForm";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import ValidatorTextField from "../../Validator/ValidatorTextField";
import { getIsAccountBalanced } from "../../../services/accountingService";

function StatusModal({ product, open, handleClose, refreshPage }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isAccountBalanced, setIsAccountBalanced] = useState(true);
  const initialValues = {
    status: product.backofficeCase.status,
    lostReason: product.backofficeCase.lostReason,
    lostCategory: product.backofficeCase.lostCategory,
  };

  const onSubmit = async () => {
    setIsLoading(true);
    if (_.includes(["won", "lost"], values.status)) {
      const accountBalancedResponse = await getIsAccountBalanced(product);
      if (!accountBalancedResponse) {
        setIsAccountBalanced(false);
        setIsLoading(false);
        return;
      }
    }
    await ApiClient.put("backoffice_cases/" + product.backofficeCase.id, {
      body: JSON.stringify({
        status: values.status,
        lostReason: values.status === "lost" ? values.lostReason : null,
        lostCategory: values.status === "lost" ? values.lostCategory : null,
      }),
    });
    refreshPage();
    handleClose();
  };

  const { values, handleSubmit, handleChange, clearForm } = useForm({
    initialValues,
    onSubmit,
    identifier: product,
  });

  const isSubmitDisabled = () => {
    if (!isAccountBalanced) {
      return true;
    }
    if (values.status !== "lost") {
      return false;
    }
    if (!values.lostCategory) {
      return true;
    }
    if (values.lostCategory !== "other") {
      return false;
    }
    return _.isEmpty(values.lostReason);
  };

  return (
    <LegalbirdIoModal
      handleClose={() => {
        clearForm();
        setIsAccountBalanced(true);
        handleClose();
      }}
      open={open}
      title={"Status ändern"}
      submitButton={
        <SubmitButton isLoading={isLoading} disabled={isSubmitDisabled()} onClick={handleSubmit} variant={"contained"}>
          Status ändern
        </SubmitButton>
      }
    >
      {!isAccountBalanced ? (
        <p className={"center"}>
          Bei diesem Fall ist das Konto nicht ausgeglichen. Daher kann der Fall nicht abgeschlossen werden. Bitte prüfe
          das Mandantenkonto auf offene Zahlungen.
        </p>
      ) : (
        <>
          <p>Auf welchen Status möchten Sie den Fall ändern?</p>
          <Grid container>
            <Grid item xs={12}>
              <ValidatorSelect
                label="Status des Falls"
                name="status"
                value={formValue(values, "status")}
                onChange={handleChange}
              >
                <MenuItem value="inactive">Inaktiv</MenuItem>
                <MenuItem value="won">Gewonnen</MenuItem>
                <MenuItem value="lost">Verloren</MenuItem>
                <MenuItem value="open">Offen</MenuItem>
              </ValidatorSelect>
            </Grid>
            {values.status === "lost" && (
              <>
                <Grid item xs={12}>
                  <ValidatorSelect
                    label="Verlustgrund auswählen"
                    name="lostCategory"
                    value={formValue(values, "lostCategory")}
                    onChange={handleChange}
                  >
                    <MenuItem value={"noResponse"}>
                      {translate("historyItem.caseStatusChange.lost.lostCategory.noResponse")}
                    </MenuItem>
                    <MenuItem value={"caseChange"}>
                      {translate("historyItem.caseStatusChange.lost.lostCategory.caseChange")}
                    </MenuItem>
                    <MenuItem value={"customerRefusal"}>
                      {translate("historyItem.caseStatusChange.lost.lostCategory.customerRefusal")}
                    </MenuItem>
                    <MenuItem value={"noInsurance"}>
                      {translate("historyItem.caseStatusChange.lost.lostCategory.noInsurance")}
                    </MenuItem>
                    <MenuItem value={"noLegalBasis"}>
                      {translate("historyItem.caseStatusChange.lost.lostCategory.noLegalBasis")}
                    </MenuItem>
                    <MenuItem value={"localLawyer"}>
                      {translate("historyItem.caseStatusChange.lost.lostCategory.localLawyer")}
                    </MenuItem>
                    <MenuItem value={"nonPayment"}>
                      {translate("historyItem.caseStatusChange.lost.lostCategory.nonPayment")}
                    </MenuItem>
                    <MenuItem value={"noCorrespondingProduct"}>
                      {translate("historyItem.caseStatusChange.lost.lostCategory.noCorrespondingProduct")}
                    </MenuItem>
                    <MenuItem value={"other"}>
                      {translate("historyItem.caseStatusChange.lost.lostCategory.other")}
                    </MenuItem>
                  </ValidatorSelect>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorTextField
                    onChange={handleChange}
                    label={"Bitte geben Sie hier kurz den Grund für den Verlust des Falls an."}
                    name="lostReason"
                    value={formValue(values, "lostReason")}
                    inputProps={{ maxLength: 255 }}
                    autoFocus
                    multiline
                    rows={7}
                    fullWidth
                  />
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </LegalbirdIoModal>
  );
}

StatusModal.propTypes = {
  product: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refreshPage: PropTypes.func,
};

StatusModal.defaultProps = {
  refreshPage: () => {},
};
export default StatusModal;
