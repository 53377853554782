import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  bookingText: {
    paddingRight: 10,
  },
  link: {
    color: "#137f7b",
    textDecoration: "none",
  },
  searchContainer: {
    width: 500,
    height: 500,
  },
}));

export default useStyles;
