import React from "react";
import useStyles from "./caseRefreshNotificationStyle";
import Grid from "@mui/material/Grid";
import * as PropTypes from "prop-types";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import { useSnackbar } from "notistack";
import { translate } from "../../services/Translations/translatorService";

const CaseRefreshNotification = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { refreshPage, caseUpdate, id } = props;
  const { closeSnackbar } = useSnackbar();

  const closeNotification = () => {
    closeSnackbar(id);
  };

  const handleRefreshPageClick = () => {
    closeNotification();
    refreshPage();
  };

  return (
    <Paper ref={ref} className={classes.notificationContainer}>
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item xs={2} />
        <Grid item xs={8} className={classes.headline}>
          Neuigkeiten zu diesem Fall
        </Grid>
        <Grid item xs={2}>
          <IconButton className={classes.icon} onClick={closeNotification} size="large">
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          Es liegen neue Daten zu diesem Fall vor. Für einen aktuellen Stand bitte die Seite neu laden.
        </Grid>
        <Grid item xs={12}>
          Folgender Bereich ist betroffen:
        </Grid>
        <Grid item xs={12}>
          {caseUpdate.type === "historyLog" ? (
            <>[ {translate(`historyItem.${caseUpdate.historyLog?.type}.label`)} ]</>
          ) : (
            <>[ Aktivitäten ]</>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handleRefreshPageClick}>Fall neu laden</Button>
        </Grid>
      </Grid>
    </Paper>
  );
});

CaseRefreshNotification.propTypes = {
  id: PropTypes.number.isRequired,
  refreshPage: PropTypes.func.isRequired,
  caseUpdate: PropTypes.object.isRequired,
};

export default CaseRefreshNotification;
