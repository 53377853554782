import React, { useEffect, useState } from "react";
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import ContentBox from "../../../ContentBox/ContentBox";
import ApiClient from "../../../../services/ApiClient";
import _ from "lodash";
import moment from "moment/moment";
import { Delete } from "@mui/icons-material";

export default function ActiveBeaRetries() {
  const [activeBeaRetries, setActiveBeaRetries] = useState<any[]>([]);

  useEffect(() => {
    ApiClient.get("bea_retries?success=false&deleted=false").then((resultCollection: { "hydra:member": Array<any> }) =>
      setActiveBeaRetries(resultCollection["hydra:member"])
    );
  }, []);

  if (activeBeaRetries.length === 0) {
    return null;
  }

  const deleteRetry = async (beaRetry: any) => {
    await ApiClient.put(beaRetry["@id"], {
      body: JSON.stringify({
        deleted: true,
      }),
    });
    ApiClient.get("bea_retries?success=false&deleted=false").then((resultCollection: { "hydra:member": Array<any> }) =>
      setActiveBeaRetries(resultCollection["hydra:member"])
    );
  };

  return (
    <ContentBox headline="beA-Retries löschen">
      <Typography>
        Untenstehend werden alle aktiven Retries aufgelistet. Diese können hier entweder einzeln oder gesamt gelöscht
        werden.
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Betreff</TableCell>
            <TableCell>Fehler</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(activeBeaRetries, (beaRetry, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{moment(beaRetry.created).format("DD.MM.YYYY HH:mm")} Uhr</TableCell>
                <TableCell>{beaRetry.requestData.subject}</TableCell>
                <TableCell>{beaRetry.requestData.lastErrorMessage}</TableCell>
                <TableCell>
                  <IconButton onClick={() => deleteRetry(beaRetry)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </ContentBox>
  );
}
