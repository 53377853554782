import React from "react";
import useStyles from "./caseErrorStyle";

const CaseError = ({ errorMessage, errorStatus }) => {
  const classes = useStyles({});

  return (
    <div className={classes.noCase}>
      <p>
        Leider ist ein technischer Fehler aufgetreten. Versuchen Sie bitte zunächst, die Seite mit STRG + F5 neu zu
        laden.
      </p>
      <p>
        Sollte nach erneutem Laden der Fehler noch auftreten, merken Sie sich bitte in welcher Situation der Fehler
        aufgetreten ist und kontaktieren Sie bitte die IT.
      </p>
      Fehler: "{errorMessage}" [{errorStatus}].
    </div>
  );
};

export default CaseError;
