import React, { useMemo, useState } from "react";
import { DatasetConfig } from "./datasetTypes";
import _ from "lodash";
import ApiClient from "../../services/ApiClient";
import useForm from "../../hooks/useForm";
import { Box, Grid, Paper } from "@mui/material";
import EntityFields from "./EntityFields";
import ButtonLoading from "../Button/ButtonLoading";
import Snackbar from "../Snackbar/Snackbar";
import { useSnackbar } from "notistack";
import { duplicationChecker, DuplicationCheckResult } from "./datasetHelper";

export default function AddDataset({ datasetConfig }: { datasetConfig: DatasetConfig }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [duplicationCheckResult, setDuplicationCheckResult] = useState<DuplicationCheckResult | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = useMemo(() => {
    return datasetConfig.entityFormElements.reduce((accumulator, formElement) => {
      const defaultValue = _.endsWith("additionalRepresentatives", formElement.path) ? [] : "";
      _.set(accumulator, formElement.path, defaultValue);
      return accumulator;
    }, {});
  }, [datasetConfig.entityApiData.entityApiUri]);

  const onSubmit = async ({ values }: any) => {
    setIsLoading(true);
    let updateData = _.merge({}, values, datasetConfig.entityApiData.additionalSaveData);
    if (datasetConfig.formFieldTransformations) {
      for (const [field, transformFunction] of Object.entries(datasetConfig.formFieldTransformations)) {
        if (_.has(updateData, field)) {
          _.set(updateData, field, transformFunction(_.get(updateData, field)));
        }
      }
    }
    const addressUpdateData = _.pickBy(_.get(updateData, "address"), (value) => !_.isEmpty(value));
    const address = await ApiClient.post("addresses", {
      body: JSON.stringify(addressUpdateData),
    });
    updateData.address = address["@id"];
    await ApiClient.post(datasetConfig.entityApiData.entityApiUri, { body: JSON.stringify(updateData) });
    useFormProps.clearForm();
    setIsLoading(false);
    enqueueSnackbar("", {
      content: () => (
        <Box>
          <Snackbar message={"Datensatz wurde erfolgreich angelegt"} />
        </Box>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      autoHideDuration: 10000,
    });
  };

  const useFormProps = useForm({
    initialValues,
    onSubmit,
    identifier: datasetConfig.entityApiData.entityApiUri,
  });

  const handleDuplicateCheck = async ({ target }: { target: HTMLInputElement }) => {
    const duplicationCheckResult = await duplicationChecker({
      values: useFormProps.values,
      type: datasetConfig.entityApiData.baseEntityAttribute,
      currentFieldname: target.name,
    });
    setDuplicationCheckResult(duplicationCheckResult);
  };

  return (
    <Paper sx={{ padding: "2rem", marginTop: "2rem" }}>
      <Grid container spacing={3}>
        <EntityFields
          formElements={datasetConfig.entityFormElements}
          useFormProps={useFormProps}
          product={{}}
          validationCallback={handleDuplicateCheck}
        />
        <Grid item xs={12} sx={{ paddingTop: "2rem" }}>
          {duplicationCheckResult?.hasPartialMatch && !duplicationCheckResult?.hasFullMatch && (
            <Box
              sx={{
                color: "orange",
                padding: "0.5rem",
                border: "1px solid orange",
                borderRadius: "5px",
                textAlign: "center",
              }}
            >
              Achtung: Es gibt einen ähnlichen Datensatz. Im Zweifel erst kurz per Suche prüfen, dass tatsächlich dieser
              neue Datensatz notwendig ist. Bitte nur speichern, wenn so keine Dopplung erzeugt wird.
            </Box>
          )}
          {duplicationCheckResult?.hasFullMatch && (
            <Box
              sx={{
                color: "red",
                padding: "0.5rem",
                border: "1px solid red",
                borderRadius: "5px",
                textAlign: "center",
              }}
            >
              Achtung: Es gibt bereits einen identischen Datensatz. Bitte diese Ansicht schließen und über die Suche den
              bereits vorhandenen Datensatz diesem Fall hinzufügen.
            </Box>
          )}
          <ButtonLoading
            sx={{ marginTop: "1rem" }}
            onClick={useFormProps.handleSubmit}
            variant={"contained"}
            isLoading={isLoading}
            disabled={!!duplicationCheckResult?.hasFullMatch}
          >
            Anlegen
          </ButtonLoading>
        </Grid>
      </Grid>
    </Paper>
  );
}
