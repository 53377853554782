import React from "react";
import { Paper } from "@mui/material";
import { rightBarPlaceholderStyles } from "./RightBarPlaceholderStyles";
import { isMobile } from "react-device-detect";

export default function RightBarPlaceholder() {

  if (isMobile) {
    return null;
  }

  return <Paper sx={rightBarPlaceholderStyles} />;
}
