import {
  dateFieldDefault,
  insurancePickerValidator,
  moneyFieldDefault,
  numberFieldDefault,
  textFieldDefault
} from "../../validationRules";
import React from "react";
import { trueFalseDefaultOptions, yesNoDefaultOptions } from "./formDefinitionFunctions";
import InputAdornment from "@mui/material/InputAdornment";
import { formValue } from "../../formServiceFunctions";

export const fullFormDefinition = product => ({
  product: {
    sections: [
      {
        label: "Angaben Antragsteller",
        elements: [
          {
            type: "ValidatorSelect",
            path: "shortTimeWork.petitioner.gender",
            options: () => [{ value: "male" }, { value: "female" }],
          },
          {
            type: "ValidatorTextField",
            path: "shortTimeWork.petitioner.givenName",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "shortTimeWork.petitioner.familyName",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorDateField",
            path: "shortTimeWork.petitioner.birthDate",
            validators: [...dateFieldDefault],
          },
          {
            type: "ValidatorSelect",
            path: "shortTimeWork.hasChildren",
            options: () => yesNoDefaultOptions("shortTimeWork.hasChildren"),
          },
          {
            type: "ValidatorSelect",
            path: "shortTimeWork.taxClass",
            options: () => [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }],
          },
          {
            type: "ValidatorTextField",
            path: "shortTimeWork.personalTextMessage",
            validators: textFieldDefault,
            additionalProps: {
              multiline: true,
              rows: 12,
              fullWidth: true,
            },
          },
        ],
      },
      {
        label: "Weitere Angaben",
        elements: [
          {
            type: "ValidatorSelect",
            path: "shortTimeWork.reason",
            options: () => [{ value: "corona" }, { value: "unsure" }, { value: "other" }],
          },
          {
            type: "ValidatorSelect",
            path: "shortTimeWork.contractTerminated",
            options: () => yesNoDefaultOptions("shortTimeWork.contractTerminated"),
          },
          {
            type: "ValidatorDateField",
            path: "shortTimeWork.contractTerminationReceivedDate",
            validators: [...dateFieldDefault],
            isHidden: ({ values }) => formValue(values, "shortTimeWork.contractTerminated") !== "yes",
          },
          {
            type: "ValidatorSelect",
            path: "shortTimeWork.shortTimeWorkStatus",
            options: () => [{ value: "announced" }, { value: "applied_for" }, { value: "implemented" }],
          },
          {
            type: "ValidatorTextField",
            path: "shortTimeWork.percentageWorkTime",
            validators: [...numberFieldDefault],
          },
          {
            type: "ValidatorSelect",
            path: "shortTimeWork.affectedEmployees",
            options: () => [{ value: "just_me" }, { value: "some" }, { value: "all" }],
          },
          {
            type: "ValidatorSelect",
            path: "shortTimeWork.hasCollectiveAgreement",
            options: () => [{ value: "yes" }, { value: "no" }, { value: "unsure" }],
          },
          {
            type: "ValidatorSelect",
            path: "shortTimeWork.hasWorkCouncil",
            options: () => yesNoDefaultOptions("shortTimeWork.hasWorkCouncil"),
          },
          {
            type: "ValidatorSelect",
            path: "shortTimeWork.workCouncilAgreement",
            isHidden: ({ values }) => formValue(values, "shortTimeWork.hasWorkCouncil") !== "yes",
            options: () => [{ value: "yes" }, { value: "no" }, { value: "unsure" }],
          },
          {
            type: "ValidatorSelect",
            path: "shortTimeWork.shortTimeClauseInContract",
            options: () => [{ value: "yes" }, { value: "no" }, { value: "unsure" }],
          },
          {
            type: "ValidatorSelect",
            path: "shortTimeWork.employeeHasAgreed",
            options: () => yesNoDefaultOptions("shortTimeWork.employeeHasAgreed"),
          },
          {
            type: "ValidatorTextField",
            path: "shortTimeWork.grossSalary",
            validators: [...moneyFieldDefault],
            additionalProps: {
              InputProps: {
                endAdornment: <InputAdornment position="start">€</InputAdornment>,
              },
            },
          },
          {
            type: "ValidatorTextField",
            path: "shortTimeWork.weeklyWorkingHours",
            validators: [...numberFieldDefault],
          },
          {
            type: "ValidatorSelect",
            path: "shortTimeWork.agencyWorker",
            options: () => yesNoDefaultOptions("shortTimeWork.agencyWorker"),
          },
          {
            type: "ValidatorTextField",
            path: "shortTimeWork.employerName",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorSelect",
            path: "shortTimeWork.companySize",
            options: () => [{ value: "<10" }, { value: "10+" }],
          },
          {
            type: "ValidatorSelect",
            path: "shortTimeWork.legalExpenseInsurance",
            options: () => trueFalseDefaultOptions("shortTimeWork.legalExpenseInsurance"),
          },
          {
            type: "ValidatorSelect",
            path: "shortTimeWork.wantsConsulting",
            options: () => yesNoDefaultOptions("shortTimeWork.wantsConsulting"),
            isHidden: ({ values }) =>
              formValue(values, "shortTimeWork.legalExpenseInsurance") !== true ||
              formValue(values, "shortTimeWork.acquisitionPartner") === "advocard",
          },
          {
            type: "InsurancePicker",
            path: "shortTimeWork.insurance.insurance",
            validators: insurancePickerValidator(product),
            isHidden: ({ values }) =>
              formValue(values, "shortTimeWork.legalExpenseInsurance") !== true ||
              (formValue(values, "shortTimeWork.acquisitionPartner") !== "advocard" &&
                formValue(values, "shortTimeWork.wantsConsulting") !== "yes"),
          },
          {
            type: "ValidatorTextField",
            path: "shortTimeWork.insurance.insurancePolicyNumber",
            validators: [...textFieldDefault],
            isHidden: ({ values }) =>
              formValue(values, "shortTimeWork.legalExpenseInsurance") !== true ||
              (formValue(values, "shortTimeWork.acquisitionPartner") !== "advocard" &&
                formValue(values, "shortTimeWork.wantsConsulting") !== "yes"),
          },
          {
            type: "ValidatorTextField",
            path: "shortTimeWork.insurance.caseReferenceNumber",
            validators: [...textFieldDefault],
            isHidden: ({ values }) =>
              formValue(values, "shortTimeWork.legalExpenseInsurance") !== true ||
              formValue(values, "shortTimeWork.acquisitionPartner") !== "advocard",
          },
        ],
      },
    ],
  },
});
