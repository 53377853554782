import React from "react";
import { makeStyles } from "@mui/styles";
import paidTaskTableStyle from "./paidTaskTableStyle";
import {
  getCaseLinkByBackofficeCase,
  getProductData,
  getProductNameBy,
} from "../../../services/Product/ProductService";
import { Button, TableCell, TableRow } from "@mui/material";
import { translate } from "../../../services/Translations/translatorService";
import { Link } from "react-router-dom";
import { convertFromFloat } from "../../../services/formServiceFunctions";
import moment from "moment";
import { PAID_TASK_STATUS_APPROVED, PAID_TASK_STATUS_ASSIGNED, PAID_TASK_STATUS_CREATED } from "../paidTaskStatus";

const useStyle = makeStyles(paidTaskTableStyle);

const PaidTaskTableRow = ({ paidTask, isExternalView, hideCase }) => {
  const classes = useStyle();
  const productClassName = getProductNameBy("lbProductClass", paidTask.backofficeCase.lbProductClass);

  const getPaidTaskClasses = (paidTask) => {
    if (paidTask.status >= PAID_TASK_STATUS_APPROVED) {
      return classes.done;
    }

    if (paidTask.status === PAID_TASK_STATUS_CREATED && moment().isAfter(paidTask.assignmentDeadline, "day")) {
      return classes.overdue;
    }

    if (paidTask.status === PAID_TASK_STATUS_ASSIGNED && moment().isAfter(paidTask.doneDeadline, "day")) {
      return classes.overdue;
    }
  };

  return (
    <TableRow className={getPaidTaskClasses(paidTask)}>
      <TableCell>
        <Link className={classes.link} to={getCaseLinkByBackofficeCase(paidTask.backofficeCase)}>
          {paidTask.backofficeCase.label || paidTask.backofficeCase.lbProductId}
        </Link>
      </TableCell>
      <TableCell>{getProductData(productClassName, "generalLabel")}</TableCell>
      <TableCell>{paidTask.subject}</TableCell>
      <TableCell>{convertFromFloat(paidTask.fee)} €</TableCell>
      <TableCell>{translate("paidTask.status." + paidTask.status)}</TableCell>
      <TableCell>{paidTask.requestedUser ? paidTask.requestedUser.person.fullname : "-"}</TableCell>
      <TableCell>{paidTask.assignee ? paidTask.assignee.person.fullname : "-"}</TableCell>
      <TableCell>{moment(paidTask.assignmentDeadline).format("DD.MM.YYYY")}</TableCell>
      <TableCell>{moment(paidTask.doneDeadline).format("DD.MM.YYYY")}</TableCell>
      <TableCell>
        <Button component={Link} className={classes.link} to={getCaseLinkByBackofficeCase(paidTask.backofficeCase) + "/ausschreibung/" + paidTask.id}>
          Details
        </Button>
      </TableCell>
    </TableRow>
  );
};
export default PaidTaskTableRow;
