import addressTranslations from "./addressTranslations";

const debtCollectionAgencyTranslations = {
  name: {
    label: "Name",
  },
  email: {
    label: "E-mail",
  },
  telephone: {
    label: "Telefonnummer",
  },
  fax: {
    label: "Faxnummer",
  },
  address: addressTranslations,
}
export default debtCollectionAgencyTranslations;