import React, { createContext, useContext, useEffect, useState } from "react";
import { isCookieFetched, mercureFetchCookie, mercureSubscribe } from "../services/mercure";
import _ from "lodash";
import { apiGet } from "../services/Api/apiCall";
import { TechStatus } from "../types/TechStatus";

let techStatusInitiated = false;

const TechStatusProviderContext = createContext<TechStatus | null | undefined>(undefined);

export default function TechStatusProvider({ children }: { children?: React.ReactNode }) {
  const [techStatus, setTechStatus] = useState<TechStatus | null>(null);
  const cookieFetched = isCookieFetched();
  useEffect(() => {
    if (!cookieFetched) {
      mercureFetchCookie();
      return;
    }
    const setNotification = (eventData: any) => {
      const newTechStatus = _.merge({}, techStatus, eventData);
      setTechStatus(newTechStatus);
    };

    const eventSource = mercureSubscribe(["/tech_status/error", "/tech_status/release"], setNotification);
    return () => {
      eventSource.close();
    };
  }, [cookieFetched]);

  useEffect(() => {
    if (!techStatusInitiated && techStatus === null) {
      techStatusInitiated = true;
      apiGet("notification/tech_status").then((techStatusResult) => setTechStatus(techStatusResult));
    }
  }, [techStatus]);

  return <TechStatusProviderContext.Provider value={techStatus}>{children}</TechStatusProviderContext.Provider>;
}

export const useTechStatus = () => {
  const techStatusContext = useContext(TechStatusProviderContext);
  if (techStatusContext === undefined) {
    throw new Error("useTechStatus can only be used inside TechStatusProvider");
  }
  return techStatusContext;
};
