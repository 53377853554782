import React, { useEffect, useState } from "react";
import { BeaMessage } from "../../types/Bea/BeaMessage";
import { MediaObject } from "../../types/MediaObject";
import ApiClient from "../../services/ApiClient";
import _ from "lodash";
import { Box, Button, Grid, Stack } from "@mui/material";
import MimeTypeIcon from "../MediaObject/MimeTypeIcon";
import { Link } from "react-router-dom";

type BeaMediaObjectsProps = {
  beaMessage: BeaMessage;
};

export default function BeaMediaObjects({ beaMessage }: BeaMediaObjectsProps) {
  const [mediaObjects, setMediaObjects] = useState<Array<MediaObject>>([]);

  useEffect(() => {
    const idFilterQuery = _.reduce(
      beaMessage.attachments,
      (query, beaAttachment) => {
        let addToQuery = "";
        if (beaAttachment.mergeable && !!beaAttachment.mediaObjectId) {
          addToQuery = `id[]=${beaAttachment.mediaObjectId}&`;
        }
        return query + addToQuery;
      },
      ""
    );
    ApiClient.get("media_objects?" + _.trimEnd(idFilterQuery, "&")).then((mediaObjectsResponse) =>
      setMediaObjects(mediaObjectsResponse["hydra:member"])
    );
  }, [beaMessage.id]);

  const mediaObjectGalleryLink = _.trimEnd(
    "/services/tools/gallerie?mediaObjectIds=" +
      _.reduce(mediaObjects, (result, mediaObject) => result + mediaObject.id + "-", ""),
    "-"
  );

  return (
    <Grid container direction="row" alignItems="center">
      {_.map(mediaObjects, (mediaObject) => (
        <Box sx={{ paddingRight: 2, maxWidth: 400, wordBreak: "break-all" }} key={mediaObject.id}>
          <Stack direction="row" alignItems="center" spacing={1} sx={{ cursor: "pointer" }}>
            <MimeTypeIcon mimeType={mediaObject.mimeType} />{" "}
            <Button
              component={Link}
              sx={{ color: "primary.main" }}
              to={mediaObjectGalleryLink + "&initialMediaObjectId=" + mediaObject.id}
              target={"_blank"}
            >
              {mediaObject.originalName}
            </Button>
          </Stack>
        </Box>
      ))}
    </Grid>
  );
}
