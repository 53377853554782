const loginStyle = {
  loginBox: {
    maxWidth: "23rem",
    margin: "2.5rem auto",
  },
  title: {
    textAlign: "center",
    padding: "1rem 1.5rem",
    borderBottom: "1px solid #f4f4f4",
  },
  content: {
    padding: "1.5rem",
  },
  submit: {
    marginTop: "1.5rem",
  },
};

export default loginStyle;
