const editableTitleStyle = {
  title: {
    maxWidth: 500,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  textField: {
    maxWidth: 500,
  },
};

export default editableTitleStyle;
