import { dateFieldDefault, postalCodeDefault, requiredFieldDefault, textFieldDefault } from "../../validationRules";
import { yesNoDefaultOptions } from "./formDefinitionFunctions";
import { formValue } from "../../formServiceFunctions";

const representativeFields = () => {
  let fields = [];

  for (let i = 0; i < 10; i++) {
    fields.push(
      {
        type: "ValidatorSelect",
        path: "powerOfAttorney.representatives[" + i + "].representative.person.gender",
        options: () => [{ value: "male" }, { value: "female" }],
        isHidden: ({ product }) => product.representatives.length < i + 1,
        isDisabled: () => true,
      },
      {
        type: "ValidatorTextField",
        path: "powerOfAttorney.representatives[" + i + "].representative.person.givenName",
        validators: [...textFieldDefault, ...requiredFieldDefault],
        isHidden: ({ product }) => product.representatives.length < i + 1,
        isDisabled: () => true,
      },
      {
        type: "ValidatorTextField",
        path: "powerOfAttorney.representatives[" + i + "].representative.person.familyName",
        validators: [...textFieldDefault, ...requiredFieldDefault],
        isHidden: ({ product }) => product.representatives.length < i + 1,
        isDisabled: () => true,
      },
      {
        type: "ValidatorDateField",
        path: "powerOfAttorney.representatives[" + i + "].representative.person.birthDate",
        validators: [...requiredFieldDefault, ...dateFieldDefault],
        isHidden: ({ product }) => product.representatives.length < i + 1,
        isDisabled: () => true,
      },
      {
        type: "ValidatorTextField",
        path: "powerOfAttorney.representatives[" + i + "].representative.person.residenceAddress.streetAddress",
        validators: [...textFieldDefault, ...requiredFieldDefault],
        isHidden: ({ product }) => product.representatives.length < i + 1,
        isDisabled: () => true,
      },
      {
        type: "ValidatorTextField",
        path: "powerOfAttorney.representatives[" + i + "].representative.person.residenceAddress.postalCode",
        validators: [...textFieldDefault, ...requiredFieldDefault, postalCodeDefault],
        isHidden: ({ product }) => product.representatives.length < i + 1,
        isDisabled: () => true,
      },
      {
        type: "ValidatorTextField",
        path: "powerOfAttorney.representatives[" + i + "].representative.person.residenceAddress.addressLocality",
        validators: [...textFieldDefault, ...requiredFieldDefault],
        isHidden: ({ product }) => product.representatives.length < i + 1,
        isDisabled: () => true,
      },
      {
        type: "ValidatorSelect",
        path: "powerOfAttorney.representatives[" + i + "].decisionType",
        options: () => [{ value: "single" }, { value: "collective" }],
        isHidden: ({ product }) => product.representatives.length < i + 1,
        isDisabled: () => true,
      }
    );
  }
  return fields;
};

export const fullFormDefinition = {
  product: {
    sections: [
      {
        label: "Angaben zur Vorsorgevollmacht",
        elements: [
          {
            type: "ValidatorSelect",
            path: "powerOfAttorney.petitionerSelection",
            isDisabled: () => true,
            options: () => [{ value: "self" }, { value: "other" }],
          },
          {
            type: "ValidatorSelect",
            path: "powerOfAttorney.petitioner.gender",
            isDisabled: () => true,
            options: () => [{ value: "male" }, { value: "female" }],
          },
          {
            type: "ValidatorTextField",
            path: "powerOfAttorney.petitioner.givenName",
            validators: [...textFieldDefault, ...requiredFieldDefault],
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "powerOfAttorney.petitioner.familyName",
            validators: [...textFieldDefault, ...requiredFieldDefault],
            isDisabled: () => true,
          },
          {
            type: "ValidatorDateField",
            path: "powerOfAttorney.petitioner.birthDate",
            validators: [...requiredFieldDefault, ...dateFieldDefault],
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "powerOfAttorney.petitioner.residenceAddress.streetAddress",
            validators: [...textFieldDefault, ...requiredFieldDefault],
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "powerOfAttorney.petitioner.residenceAddress.postalCode",
            validators: [...textFieldDefault, ...requiredFieldDefault, postalCodeDefault],
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "powerOfAttorney.petitioner.residenceAddress.addressLocality",
            validators: [...textFieldDefault, ...requiredFieldDefault],
            isDisabled: () => true,
          },
          {
            type: "ValidatorSelect",
            path: "powerOfAttorney.legallyIncompetent",
            isDisabled: () => true,
            options: () => [{ value: "doctor" }, { value: "certificate" }],
          },
          {
            type: "ValidatorSelect",
            path: "powerOfAttorney.validityPeriod",
            isDisabled: () => true,
            options: () => [{ value: "afterDeath" }, { value: "limited" }],
          },
          ...representativeFields(),
          {
            type: "ValidatorSelect",
            path: "powerOfAttorney.hasReplacementRepresentative",
            isDisabled: () => true,
            options: () => yesNoDefaultOptions("powerOfAttorney.hasReplacementRepresentative"),
          },
          {
            type: "ValidatorSelect",
            path: "powerOfAttorney.replacementRepresentative.person.gender",
            options: () => [{ value: "male" }, { value: "female" }],
            isHidden: ({ values }) => formValue(values, "powerOfAttorney.hasReplacementRepresentative") !== "yes",
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "powerOfAttorney.replacementRepresentative.person.givenName",
            validators: [...textFieldDefault, ...requiredFieldDefault],
            isHidden: ({ values }) => formValue(values, "powerOfAttorney.hasReplacementRepresentative") !== "yes",
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "powerOfAttorney.replacementRepresentative.person.familyName",
            validators: [...textFieldDefault, ...requiredFieldDefault],
            isHidden: ({ values }) => formValue(values, "powerOfAttorney.hasReplacementRepresentative") !== "yes",
            isDisabled: () => true,
          },
          {
            type: "ValidatorDateField",
            path: "powerOfAttorney.replacementRepresentative.person.birthDate",
            validators: [...requiredFieldDefault, ...dateFieldDefault],
            isHidden: ({ values }) => formValue(values, "powerOfAttorney.hasReplacementRepresentative") !== "yes",
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "powerOfAttorney.replacementRepresentative.person.residenceAddress.streetAddress",
            validators: [...textFieldDefault, ...requiredFieldDefault],
            isHidden: ({ values }) => formValue(values, "powerOfAttorney.hasReplacementRepresentative") !== "yes",
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "powerOfAttorney.replacementRepresentative.person.residenceAddress.postalCode",
            validators: [...textFieldDefault, ...requiredFieldDefault, postalCodeDefault],
            isHidden: ({ values }) => formValue(values, "powerOfAttorney.hasReplacementRepresentative") !== "yes",
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "powerOfAttorney.replacementRepresentative.person.residenceAddress.addressLocality",
            validators: [...textFieldDefault, ...requiredFieldDefault],
            isHidden: ({ values }) => formValue(values, "powerOfAttorney.hasReplacementRepresentative") !== "yes",
            isDisabled: () => true,
          },
          {
            type: "ValidatorSelect",
            path: "powerOfAttorney.sub",
            isDisabled: () => true,
            options: () => [{ value: "subYes" }, { value: "subNo" }],
          },
          {
            type: "ValidatorSelect",
            path: "powerOfAttorney.authorizationMedical",
            isDisabled: () => true,
            options: () => [{ value: "amFull" }, { value: "amLimited" }],
          },
          {
            type: "ValidatorSelect",
            path: "powerOfAttorney.medicalType",
            isDisabled: () => true,
            isHidden: ({ values }) => formValue(values, "powerOfAttorney.authorizationMedical") !== "amLimited",
            additionalProps: {
              multiple: true,
            },
            options: () => [
              { value: "seeDocuments" },
              { value: "treatment" },
              { value: "careStaff" },
              { value: "mtNone" },
            ],
          },
          {
            type: "ValidatorSelect",
            path: "powerOfAttorney.freedomType",
            isDisabled: () => true,
            additionalProps: {
              multiple: true,
            },
            options: () => [{ value: "confinement" }, { value: "medical" }, { value: "ftNone" }],
          },
          {
            type: "ValidatorSelect",
            path: "powerOfAttorney.residencyType",
            isDisabled: () => true,
            additionalProps: {
              multiple: true,
            },
            options: () => [{ value: "residency" }, { value: "rent" }, { value: "retirement" }, { value: "rtNone" }],
          },
          {
            type: "ValidatorSelect",
            path: "powerOfAttorney.lawType",
            isDisabled: () => true,
            additionalProps: {
              multiple: true,
            },
            options: () => [{ value: "authority" }, { value: "court" }, { value: "lawyer" }, { value: "ltNone" }],
          },
          {
            type: "ValidatorSelect",
            path: "powerOfAttorney.digital",
            isDisabled: () => true,
            options: () => [{ value: "complete" }, { value: "delete" }, { value: "digitalNone" }],
          },
          {
            type: "ValidatorSelect",
            path: "powerOfAttorney.money",
            isDisabled: () => true,
            options: () => [{ value: "moneyYes" }, { value: "donate" }, { value: "self" }, { value: "moneyNo" }],
          },
          {
            type: "ValidatorSelect",
            path: "powerOfAttorney.additional",
            isDisabled: () => true,
            additionalProps: {
              multiple: true,
            },
            options: () => [
              { value: "post" },
              { value: "telecommunication" },
              { value: "bank" },
              { value: "insurance" },
              { value: "additionalNone" },
            ],
          },
        ],
      },
    ],
  },
};
