import * as stagesCase from "../../../Stages/StagesCase";
import { STAGE_ACTION_REQUIRED } from "../../../Stages/StagesCase";
import personFields from "../modules/personFields";
import {
  dateFieldDefault,
  invoiceNetValueNotOverpaid,
  moneyFieldDefault,
  percentageFieldDefault,
} from "../../../validationRules";
import { trueFalseDefaultOptions } from "../formDefinitionFunctions";
import InputAdornment from "@mui/material/InputAdornment";
import React from "react";
import _ from "lodash";
import { arrayToSelectOptions } from "../../../formServiceFunctions";
import { objectivesLawFirm } from "./fullFormDefinition";

export const stageFormDefinition = (product) => {
  const createdFields = [
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.insurance.coverageGrantedDate`,
      isHidden: ({ product }) => product.acquisitionPartner === "advocard",
      validators: dateFieldDefault,
    },
    ...personFields({ path: `${product.productClassName}.client` }),
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.personalTextMessage`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
  ];

  const dataCompletedFields = [
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.insurance.coverageGrantedDate`,
      isHidden: ({ product }) => product.acquisitionPartner === "advocard",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.caseCategory`,
      isDisabled: ({ product }) => product.caseCategory === "advisory",
      options: ({ product }) => {
        return product.caseCategory === "advisory"
          ? [{ value: "enforceClaim" }, { value: "defendClaim" }, { value: "advisory" }]
          : [{ value: "enforceClaim" }, { value: "defendClaim" }];
      },

      alert: {
        onChange:
          "Das Ändern der Position des Mandanten (Kläger/Beklagter) führt dazu, dass sich viele " +
          "Felder ändern und auch Felder verschwinden. Die neuen Felder müssen dann von Ihnen neu " +
          "befüllt werden, damit der Fall weiter bearbeitet werden kann. Möchten Sie die Position " +
          "des Mandanten im Verfahren tatsächlich ändern?",
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.objectiveClient`,
      isDisabled: () => true,
      isHidden: ({ product }) => product.caseCategory !== "enforceClaim",
      options: () => [
        { value: "compensation" },
        { value: "reduction" },
        { value: "replacement" },
        { value: "repair" },
        { value: "withdrawal" },
        { value: "fulfillment" },
        { value: "other" },
      ],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.defenseProcessStatus`,
      isHidden: ({ product }) => product.caseCategory !== "defendClaim",
      options: () => [
        { value: "reminder" },
        { value: "debtCollection" },
        { value: "paymentOrder" },
        { value: "enforcementOrder" },
        { value: "lawsuit" },
        { value: "none" },
      ],
    },
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.personalTextMessage`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.paymentOrderDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }) => product.caseCategory !== "defendClaim",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.enforcementOrderDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }) => product.caseCategory !== "defendClaim",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfClaimFiledDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }) => product.advisoryHandling === true || product.caseCategory !== "defendClaim",
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.defectsDescriptionClient`,
      isDisabled: () => true,
      isHidden: ({ product }) =>
        product.advisoryHandling === true ||
        !_.has(product, "defectsDescriptionLawFirm") ||
        product.caseCategory !== "enforceClaim" ||
        product.isLawsuitHandling ||
        (!product.hasDefects && !product.reasonForDispute === "hasDefects"),
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.caseCategoryAssessment`,
      isDisabled: ({ product }) => product.stage > stagesCase.STAGE_WAITING_FOR_FEEDBACK,
      isHidden: ({ product }) => product.caseCategory === "advisory",
      options: () => [{ value: "confirm" }, { value: "reject" }],
    },
    {
      type: "CaseRejectionCategorySelect",
      path: `${product.productClassName}.caseRejectionCategory`,
      isDisabled: ({ product }) => product.stage > stagesCase.STAGE_WAITING_FOR_FEEDBACK,
      isHidden: ({ product, values }) =>
        product.caseCategory === "advisory" || values[product.productClassName].caseCategoryAssessment !== "reject",
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.assessmentRejectionText`,
      isHidden: ({ product, values }) =>
        values[product.productClassName].caseCategoryAssessment !== "reject" || product.caseCategory === "advisory",
      additionalProps: {
        multiline: true,
        rows: 12,
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.objectiveLawFirm`,
      isHidden: ({ product, values }) =>
        values[product.productClassName].caseCategoryAssessment === "reject" || product.isLawsuitHandling,
      options: ({ product }) => {
        if (_.isEmpty(product.possibleObjectives)) {
          return objectivesLawFirm;
        } else {
          return arrayToSelectOptions(product.possibleObjectives);
        }
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.objectiveLawFirmSecondary`,
      isHidden: ({ product, values }) =>
        values[product.productClassName].caseCategoryAssessment === "reject" || product.isLawsuitHandling,
      options: ({ product }) => {
        if (_.isEmpty(product.possibleObjectives)) {
          return objectivesLawFirm;
        } else {
          return arrayToSelectOptions(product.possibleObjectives);
        }
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.objectiveLawFirmTertiary`,
      isHidden: ({ product, values }) =>
        values[product.productClassName].caseCategoryAssessment === "reject" || product.isLawsuitHandling,
      options: ({ product }) => {
        if (_.isEmpty(product.possibleObjectives)) {
          return objectivesLawFirm;
        } else {
          return arrayToSelectOptions(product.possibleObjectives);
        }
      },
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.timeBarredDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }) => product.advisoryHandling === true || product.isLawsuitHandling,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.extrajudicialCommunicationDeadline`,
      validators: dateFieldDefault,
      isHidden: ({ product }) => product.advisoryHandling === true || product.isLawsuitHandling,
      additionalProps: {
        withSkipWeeksToolbar: true,
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.claimedAmountLawFirm`,
      validators: moneyFieldDefault,
      isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
      isHidden: ({ product }) => product.advisoryHandling === true || product.isLawsuitHandling,
      additionalProps: {
        InputProps: {
          endAdornment: <InputAdornment position="start">€</InputAdornment>,
        },
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.defectsDescriptionLawFirm`,
      isHidden: ({ product }) =>
        product.advisoryHandling === true ||
        !_.has(product, "defectsDescriptionLawFirm") ||
        product.caseCategory !== "enforceClaim" ||
        product.isLawsuitHandling ||
        (!product.hasDefects && !product.reasonForDispute === "hasDefects"),
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.courtPostalCode`,
      helperText:
        "Bitte hier die Postleitzahl eintragen, die für den Gerichtsstand ermittelt wurde und NICHT die Postleitzahl des Gerichtes.",
      isHidden: ({ product, values }) =>
        values[product.productClassName].caseCategoryAssessment === "reject" ||
        !product.isLawsuitHandling ||
        product.caseCategory === "defendClaim",
      alert: {
        onChange:
          "Das Ändern der Postleitzahl führt dazu, dass das zuständige Gericht erneut ermittelt wird. Möchten Sie die Postleitzahl tatsächlich ändern?",
      },
    },
  ];

  const dataLawFirmCompletedFields = [
    ...dataCompletedFields,
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.additionalIssueDescriptionText`,
      isHidden: ({ product }) => product.advisoryHandling === true || product.isLawsuitHandling,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.additionalClaimText`,
      isHidden: ({ product }) => product.advisoryHandling === true || product.isLawsuitHandling,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.automaticallyGenerateExtrajudicialLetter`,
      isHidden: ({ product }) => product.caseCategoryAssessment === "reject",
      options: ({ product }) =>
        trueFalseDefaultOptions(`${product.productClassName}.automaticallyGenerateExtrajudicialLetter`),
    },
  ];

  const checkedFields = [
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.insurance.coverageGrantedDate`,
      isHidden: ({ product }) => product.acquisitionPartner === "advocard",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.caseCategory`,
      isDisabled: ({ product }) => product.caseCategory === "advisory",
      options: ({ product }) => {
        return product.caseCategory === "advisory"
          ? [{ value: "enforceClaim" }, { value: "defendClaim" }, { value: "advisory" }]
          : [{ value: "enforceClaim" }, { value: "defendClaim" }];
      },
      alert: {
        onChange:
          "Das Ändern der Position des Mandanten (Kläger/Beklagter) führt dazu, dass sich viele " +
          "Felder ändern und auch Felder verschwinden. Die neuen Felder müssen dann von Ihnen neu " +
          "befüllt werden, damit der Fall weiter bearbeitet werden kann. Möchten Sie die Position " +
          "des Mandanten im Verfahren tatsächlich ändern?",
      },
    },
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.personalTextMessage`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    // claim fields
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.caseCategoryAssessment`,
      isDisabled: ({ product }) => product.stage > stagesCase.STAGE_WAITING_FOR_FEEDBACK,
      isHidden: ({ product }) => product.caseCategory === "advisory",
      options: () => [{ value: "confirm" }, { value: "reject" }],
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.timeBarredDate`,
      isDisabled: () => true,
      isHidden: ({ product }) => product.advisoryHandling === true,
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.assessmentRejectionText`,
      isHidden: ({ product, values }) =>
        values[product.productClassName].caseCategoryAssessment !== "reject" || product.caseCategory === "advisory",
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.additionalIssueDescriptionText`,
      isHidden: ({ product }) => product.advisoryHandling === true,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.additionalClaimText`,
      isHidden: ({ product }) => product.advisoryHandling === true,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.defectsDescriptionLawFirm`,
      isHidden: ({ product }) =>
        !_.has(product, "defectsDescriptionLawFirm") || product.caseCategory !== "enforceClaim" || !product.hasDefects,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.extrajudicialCommunicationSent`,
      validators: dateFieldDefault,
      isHidden: ({ product }) => product.advisoryHandling === true || product.isLawsuitHandling,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.extrajudicialCommunicationDeadline`,
      validators: dateFieldDefault,
      isHidden: ({ product }) => product.advisoryHandling === true || product.isLawsuitHandling,
      additionalProps: {
        withSkipWeeksToolbar: true,
      },
    },
  ];

  const completedFields = [
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.caseCategory`,
      isDisabled: ({ product }) => product.caseCategory === "advisory",
      options: ({ product }) => {
        return product.caseCategory === "advisory"
          ? [{ value: "enforceClaim" }, { value: "defendClaim" }, { value: "advisory" }]
          : [{ value: "enforceClaim" }, { value: "defendClaim" }];
      },
      alert: {
        onChange:
          "Das Ändern der Position des Mandanten (Kläger/Beklagter) führt dazu, dass sich viele " +
          "Felder ändern und auch Felder verschwinden. Die neuen Felder müssen dann von Ihnen neu " +
          "befüllt werden, damit der Fall weiter bearbeitet werden kann. Möchten Sie die Position " +
          "des Mandanten im Verfahren tatsächlich ändern?",
      },
    },
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.personalTextMessage`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    // claim fields
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.caseCategoryAssessment`,
      isDisabled: ({ product }) => product.stage > stagesCase.STAGE_WAITING_FOR_FEEDBACK,
      isHidden: ({ product }) => product.caseCategory === "advisory",
      options: () => [{ value: "confirm" }, { value: "reject" }],
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.assessmentRejectionText`,
      isHidden: ({ product, values }) =>
        values[product.productClassName].caseCategoryAssessment !== "reject" || product.caseCategory === "advisory",
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
        rows: 12,
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.finalClaimStatusClient`,
      translationPath: `${product.productClassName}.finalClaimStatusClient.${product.caseCategory}`,
      isDisabled: () => true,
      isHidden: ({ product }) => product.advisoryHandling === true,
      options: () => [
        { value: "fullyAgreed" },
        { value: "partiallyAgreed" },
        { value: "notAgreed" },
        { value: "noReaction" },
      ],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.finalProcessFeedbackClient`,
      translationPath: `${product.productClassName}.finalProcessFeedbackClient.${product.caseCategory}`,
      isDisabled: () => true,
      isHidden: ({ product }) => product.advisoryHandling === true,
      options: () => [{ value: "agreement" }, { value: "lawsuit" }],
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.customerLawsuitCompletedApprovalDate`,
      translationPath: ({ values }) =>
        `${product.productClassName}.customerLawsuitCompletedApprovalDate.${
          values[product.productClassName].lawsuitCompletedReason
        }`,
      isDisabled: () => true,
      isHidden: ({ values }) =>
        _.includes(["courtDecision", "defaultJudgement"], values[product.productClassName].lawsuitCompletedReason) ||
        !values[product.productClassName].lawsuitCompletedReason,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.lawsuitCompletedDate`,
      isDisabled: () => true,
      isHidden: ({ product }) => product.advisoryHandling === true,
    },
  ];

  const inProgressFields = [
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.insurance.coverageGrantedDate`,
      isHidden: ({ product }) => product.acquisitionPartner === "advocard",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.caseCategory`,
      isDisabled: ({ product }) => product.caseCategory === "advisory",
      options: ({ product }) => {
        return product.caseCategory === "advisory"
          ? [{ value: "enforceClaim" }, { value: "defendClaim" }, { value: "advisory" }]
          : [{ value: "enforceClaim" }, { value: "defendClaim" }];
      },
      alert: {
        onChange:
          "Das Ändern der Position des Mandanten (Kläger/Beklagter) führt dazu, dass sich viele " +
          "Felder ändern und auch Felder verschwinden. Die neuen Felder müssen dann von Ihnen neu " +
          "befüllt werden, damit der Fall weiter bearbeitet werden kann. Möchten Sie die Position " +
          "des Mandanten im Verfahren tatsächlich ändern?",
      },
    },
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.personalTextMessage`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.caseCategoryAssessment`,
      isDisabled: ({ product }) => product.stage > stagesCase.STAGE_WAITING_FOR_FEEDBACK,
      isHidden: ({ product }) => product.caseCategory === "advisory",
      options: () => [{ value: "confirm" }, { value: "reject" }],
    },
  ];

  const communicationSendFields = [
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.insurance.coverageGrantedDate`,
      isHidden: ({ product }) => product.acquisitionPartner === "advocard",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.caseCategory`,
      isDisabled: ({ product }) => product.caseCategory === "advisory",
      options: ({ product }) => {
        return product.caseCategory === "advisory"
          ? [{ value: "enforceClaim" }, { value: "defendClaim" }, { value: "advisory" }]
          : [{ value: "enforceClaim" }, { value: "defendClaim" }];
      },
      alert: {
        onChange:
          "Das Ändern der Position des Mandanten (Kläger/Beklagter) führt dazu, dass sich viele " +
          "Felder ändern und auch Felder verschwinden. Die neuen Felder müssen dann von Ihnen neu " +
          "befüllt werden, damit der Fall weiter bearbeitet werden kann. Möchten Sie die Position " +
          "des Mandanten im Verfahren tatsächlich ändern?",
      },
    },
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.personalTextMessage`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.extrajudicialCommunicationSent`,
      isHidden: ({ product }) => product.advisoryHandling === true,
      isDisabled: () => true,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.extrajudicialCommunicationDeadline`,
      isHidden: ({ product }) =>
        product.advisoryHandling === true || product.caseCategory === "defendClaim",
      isDisabled: () => true,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.timeBarredDate`,
      isDisabled: () => true,
      isHidden: ({ product }) => product.advisoryHandling === true,
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.additionalIssueDescriptionText`,
      isHidden: ({ product }) => product.advisoryHandling === true || !product.additionalIssueDescriptionText,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.additionalClaimText`,
      isHidden: ({ product }) => product.advisoryHandling === true || !product.additionalClaimText,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.defectsDescriptionLawFirm`,
      isHidden: ({ product }) =>
        !_.has(product, "defectsDescriptionLawFirm") || product.caseCategory !== "enforceClaim",
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.extrajudicialReactionReceived`,
      validators: dateFieldDefault,
      isHidden: ({ product, values }) => values[product.productClassName].extrajudicialReaction === "noReaction",
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.extrajudicialReaction`,
      translationPath: `${product.productClassName}.extrajudicialReaction.${product.caseCategory}`,
      options: () => [
        { value: "noReaction" },
        { value: "fullyAgreed" },
        { value: "partiallyAgreed" },
        { value: "disagreed" },
      ],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.extrajudicialRecommendation`,
      isHidden: ({ product }) => !_.includes(["defendClaim", "enforceClaim"], product.caseCategory),
      options: ({ product }) =>
        product.caseCategory === "defendClaim"
          ? [
              { value: "agreementDone" },
              { value: "insufficientEvidence" },
              { value: "insufficientChancesOfSuccess" },
              { value: "costBenefitConsiderations" },
              { value: "lawsuit" },
              { value: "individualText" },
            ]
          : [
              { value: "agreementDone" },
              { value: "insufficientEvidence" },
              { value: "insufficientChancesOfSuccess" },
              { value: "costBenefitConsiderations" },
              { value: "anonymousOpponent" },
              { value: "opponentUnableToPay" },
              { value: "lawsuit" },
              { value: "individualText" },
            ],
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.extrajudicialRecommendationText`,
      isHidden: ({ product }) => !_.includes(["defendClaim", "enforceClaim"], product.caseCategory),
      additionalProps: {
        multiline: true,
        fullWidth: true,
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.finalClaimStatusClient`,
      translationPath: `${product.productClassName}.finalClaimStatusClient.${product.caseCategory}`,
      helperText:
        "Achtung: Feld ist über Servicewelt durch Mandant zu befüllen! Wenn dieses Feld von uns befüllt wird, " +
        "kann der Mandant kein Feedback mehr abgeben. Daher nur befüllen, wenn mit Mandant explizit abgesprochen.",
      options: () => [
        { value: "fullyAgreed" },
        { value: "partiallyAgreed" },
        { value: "notAgreed" },
        { value: "noReaction" },
      ],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.finalProcessFeedbackClient`, // Rückmeldung Mandant
      translationPath: `${product.productClassName}.finalProcessFeedbackClient.${product.caseCategory}`, // Rückmeldung Mandant
      helperText:
        "Achtung: Feld ist über Servicewelt durch Mandant zu befüllen! Wenn dieses Feld von uns befüllt wird, " +
        "kann der Mandant kein Feedback mehr abgeben. Daher nur befüllen, wenn mit Mandant explizit abgesprochen.",
      isHidden: ({ values }) => values[product.productClassName].finalClaimStatusClient === "fullyAgreed",
      options: () => [{ value: "agreement" }, { value: "lawsuit" }],
    },
  ];

  const reactionReceivedFields = [
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.insurance.coverageGrantedDate`,
      isHidden: ({ product }) => product.acquisitionPartner === "advocard",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.caseCategory`,
      isDisabled: ({ product }) => product.stage > STAGE_ACTION_REQUIRED || product.caseCategory === "advisory",
      options: ({ product }) => {
        return product.caseCategory === "advisory"
          ? [{ value: "enforceClaim" }, { value: "defendClaim" }, { value: "advisory" }]
          : [{ value: "enforceClaim" }, { value: "defendClaim" }];
      },
      alert: {
        onChange:
          "Das Ändern der Position des Mandanten (Kläger/Beklagter) führt dazu, dass sich viele " +
          "Felder ändern und auch Felder verschwinden. Die neuen Felder müssen dann von Ihnen neu " +
          "befüllt werden, damit der Fall weiter bearbeitet werden kann. Möchten Sie die Position " +
          "des Mandanten im Verfahren tatsächlich ändern?",
      },
    },
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.personalTextMessage`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.extrajudicialCommunicationSent`,
      isHidden: ({ product }) => product.advisoryHandling === true,
      isDisabled: () => true,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.extrajudicialCommunicationDeadline`,
      isHidden: ({ product }) => product.advisoryHandling === true,
      isDisabled: () => true,
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.extrajudicialReaction`,
      translationPath: `${product.productClassName}.extrajudicialReaction.${product.caseCategory}`,
      isDisabled: () => true,
      options: () => [
        { value: "noReaction" },
        { value: "fullyAgreed" },
        { value: "partiallyAgreed" },
        { value: "disagreed" },
      ],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.extrajudicialRecommendation`,
      isDisabled: () => true,
      isHidden: ({ product }) => !_.includes(["defendClaim", "enforceClaim"], product.caseCategory),
      options: ({ product }) =>
        product.caseCategory === "defendClaim"
          ? [
              { value: "agreementDone" },
              { value: "insufficientEvidence" },
              { value: "insufficientChancesOfSuccess" },
              { value: "costBenefitConsiderations" },
              { value: "lawsuit" },
              { value: "individualText" },
            ]
          : [
              { value: "agreementDone" },
              { value: "insufficientEvidence" },
              { value: "insufficientChancesOfSuccess" },
              { value: "costBenefitConsiderations" },
              { value: "anonymousOpponent" },
              { value: "opponentUnableToPay" },
              { value: "lawsuit" },
              { value: "individualText" },
            ],
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.extrajudicialRecommendationText`,
      isDisabled: () => true,
      isHidden: ({ product }) =>
        !_.includes(["defendClaim", "enforceClaim"], product.caseCategory) || !product.extrajudicialRecommendationText,
      additionalProps: {
        multiline: true,
        fullWidth: true,
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.finalClaimStatusClient`,
      translationPath: `${product.productClassName}.finalClaimStatusClient.${product.caseCategory}`,
      helperText:
        "Achtung: Feld ist über Servicewelt durch Mandant zu befüllen! Wenn dieses Feld von uns befüllt wird, " +
        "kann der Mandant kein Feedback mehr abgeben. Daher nur befüllen, wenn mit Mandant explizit abgesprochen.",
      options: () => [
        { value: "fullyAgreed" },
        { value: "partiallyAgreed" },
        { value: "notAgreed" },
        { value: "noReaction" },
      ],
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.finalProcessFeedbackClient`, // Rückmeldung Mandant
      translationPath: `${product.productClassName}.finalProcessFeedbackClient.${product.caseCategory}`, // Rückmeldung Mandant
      helperText:
        "Achtung: Feld ist über Servicewelt durch Mandant zu befüllen! Wenn dieses Feld von uns befüllt wird, " +
        "kann der Mandant kein Feedback mehr abgeben. Daher nur befüllen, wenn mit Mandant explizit abgesprochen.",
      isHidden: ({ values }) => values[product.productClassName].finalClaimStatusClient === "fullyAgreed",
      options: () => [{ value: "agreement" }, { value: "lawsuit" }],
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.courtPostalCode`,
      helperText:
        "Bitte hier die Postleitzahl eintragen, die für den Gerichtsstand ermittelt wurde und NICHT die Postleitzahl des Gerichtes.",
      isHidden: ({ product }) => product.caseCategory === "defendClaim",
      alert: {
        onChange:
          "Das Ändern der Postleitzahl führt dazu, dass das zuständige Gericht erneut ermittelt wird. Möchten Sie die Postleitzahl tatsächlich ändern?",
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.valueInDispute`,
      validators: moneyFieldDefault,
      additionalProps: {
        InputProps: {
          endAdornment: <InputAdornment position="start">€</InputAdornment>,
        },
      },
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfClaimSentDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }) => product.caseCategory !== "enforceClaim",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfClaimFiledDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }) => product.caseCategory !== "defendClaim",
    },
  ];

  const lawsuitFields = [
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.insurance.coverageGrantedDate`,
      isHidden: ({ product }) => product.acquisitionPartner === "advocard",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.caseCategory`,
      isDisabled: () => true,
      options: () => [{ value: "enforceClaim" }, { value: "defendClaim" }, { value: "advisory" }],
    },
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.personalTextMessage`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfClaimSentDate`,
      isHidden: ({ product }) => product.caseCategory !== "enforceClaim",
      isDisabled: () => true,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.paymentOrderDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }) => product.statementOfClaimFiledDate || product.caseCategory !== "defendClaim",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.enforcementOrderDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }) => product.statementOfClaimFiledDate || product.caseCategory !== "defendClaim",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfClaimFiledDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }) => product.advisoryHandling === true || product.caseCategory !== "defendClaim",
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.courtReference`,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfDefenseFiledDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }) =>
        product.caseCategory !== "enforceClaim" || product.lawsuitCompletedReason === "lawsuitRevocation",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.replicaSentDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }) =>
        product.caseCategory !== "enforceClaim" || product.lawsuitCompletedReason === "lawsuitRevocation",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.statementOfDefenseSentDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }) => product.caseCategory !== "defendClaim",
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.replicaFiledDate`,
      validators: dateFieldDefault,
      isHidden: ({ product }) =>
        product.caseCategory !== "defendClaim" || product.lawsuitCompletedReason === "lawsuitRevocation",
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.lawsuitCompletedReason`,
      options: () => [
        { value: "courtDecision" },
        { value: "settlement" },
        { value: "lawsuitRevocation" },
        { value: "resolution" },
        { value: "defaultJudgement" },
      ],
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.customerLawsuitCompletedApprovalDate`,
      translationPath: ({ values }) =>
        `${product.productClassName}.customerLawsuitCompletedApprovalDate.${
          values[product.productClassName].lawsuitCompletedReason
        }`,
      validators: dateFieldDefault,
      isHidden: ({ values }) =>
        _.includes(["courtDecision", "defaultJudgement"], values[product.productClassName].lawsuitCompletedReason) ||
        !values[product.productClassName].lawsuitCompletedReason,
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.lawsuitCompletedDate`,
      translationPath: ({ values }) =>
        `${product.productClassName}.lawsuitCompletedDate.${values[product.productClassName].lawsuitCompletedReason}`,
      isHidden: ({ values }) => !values[product.productClassName].lawsuitCompletedReason,
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.hasStandardInvoicing`,
      options: () => trueFalseDefaultOptions(`${product.productClassName}.hasStandardInvoicing`),
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.subtractExtrajudicialProcessFee`,
      options: () => trueFalseDefaultOptions(`${product.productClassName}.subtractExtrajudicialProcessFee`),
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.netRvgAmount`,
      validators: moneyFieldDefault,
      isHidden: ({ values, product }) => values[product.productClassName].hasStandardInvoicing,
      additionalProps: {
        InputProps: {
          endAdornment: <InputAdornment position="start">€</InputAdornment>,
        },
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.valueInDispute`,
      validators: moneyFieldDefault,
      additionalProps: {
        InputProps: {
          endAdornment: <InputAdornment position="start">€</InputAdornment>,
        },
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.costBearingByOpponentRelative`,
      validators: percentageFieldDefault,
      isDisabled: ({ product }) => !product.lawsuitCompletedDate,
      additionalProps: {
        InputProps: {
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        },
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.principalClaim`,
      validators: moneyFieldDefault,
      isHidden: ({ values, product }) =>
        values[product.productClassName].caseCategory !== "enforceClaim" ||
        values[product.productClassName].lawsuitCompletedReason === "lawsuitRevocation" ||
        values[product.productClassName].costBearingByOpponentRelative === "0",
      additionalProps: {
        InputProps: {
          endAdornment: <InputAdornment position="start">€</InputAdornment>,
        },
      },
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.principalClaimDefaultDate`,
      isHidden: ({ values, product }) =>
        values[product.productClassName].caseCategory !== "enforceClaim" ||
        values[product.productClassName].lawsuitCompletedReason === "lawsuitRevocation" ||
        values[product.productClassName].costBearingByOpponentRelative === "0",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.accessoryClaim`,
      validators: moneyFieldDefault,
      isHidden: ({ values, product }) =>
        values[product.productClassName].caseCategory !== "enforceClaim" ||
        values[product.productClassName].lawsuitCompletedReason === "lawsuitRevocation" ||
        values[product.productClassName].costBearingByOpponentRelative === "0",
      additionalProps: {
        InputProps: {
          endAdornment: <InputAdornment position="start">€</InputAdornment>,
        },
      },
    },
    {
      type: "ValidatorDateField",
      path: `${product.productClassName}.accessoryClaimDefaultDate`,
      isHidden: ({ values, product }) =>
        values[product.productClassName].caseCategory !== "enforceClaim" ||
        values[product.productClassName].lawsuitCompletedReason === "lawsuitRevocation" ||
        values[product.productClassName].costBearingByOpponentRelative === "0",
      validators: dateFieldDefault,
    },
  ];

  const lawsuitCompletedFields = [
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.courtReference`,
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.hasStandardInvoicing`,
      options: () => trueFalseDefaultOptions(`${product.productClassName}.hasStandardInvoicing`),
      isDisabled: () => true,
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.subtractExtrajudicialProcessFee`,
      options: () => trueFalseDefaultOptions(`${product.productClassName}.subtractExtrajudicialProcessFee`),
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.netRvgAmount`,
      validators: moneyFieldDefault,
      isDisabled: () => true,
      isHidden: ({ product }) => product.hasStandardInvoicing,
      additionalProps: {
        InputProps: {
          endAdornment: <InputAdornment position="start">€</InputAdornment>,
        },
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.valueInDispute`,
      validators: moneyFieldDefault,
      isDisabled: () => true,
      additionalProps: {
        InputProps: {
          endAdornment: <InputAdornment position="start">€</InputAdornment>,
        },
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.costBearingByOpponentRelative`,
      validators: percentageFieldDefault,
      isDisabled: () => true,
      additionalProps: {
        InputProps: {
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        },
      },
      isHidden: ({ product }) => product.lawsuitCompletedReason === "lawsuitRevocation",
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.costBearingByOpponentFinalAbsolute`,
      helperText:
        "Bitte hier die vom Gegner auf das gerichtliche Verfahren gezahlten Kosten inkl. MWSt. eintragen. " +
        "Außergerichtliche Gebühren sind nicht zu berücksichtigen.",
      validators: [...moneyFieldDefault, ...invoiceNetValueNotOverpaid(`${product.productClassName}.netRvgAmount`)],
      isDisabled: ({ product }) => !product.lawsuitCompletedDate,
      isHidden: ({ product }) =>
        product.lawsuitCompletedReason === "lawsuitRevocation" ||
        product.costBearingByOpponentRelative === 0.0 ||
        product.costBearingByOpponentRelative === 100.0,
      additionalProps: {
        InputProps: {
          endAdornment: <InputAdornment position="start">€</InputAdornment>,
        },
      },
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.courtCostsFinal`,
      validators: moneyFieldDefault,
      isDisabled: ({ product }) => !product.lawsuitCompletedDate,
      isHidden: ({ product }) =>
        product.lawsuitCompletedReason === "lawsuitRevocation" ||
        product.costBearingByOpponentRelative === 0.0 ||
        product.costBearingByOpponentRelative === 100.0,
      additionalProps: {
        InputProps: {
          endAdornment: <InputAdornment position="start">€</InputAdornment>,
        },
      },
    },
  ];

  return {
    [stagesCase.STAGE_CREATED]: createdFields,
    [stagesCase.STAGE_DATA_COMPLETED]: dataCompletedFields,
    [stagesCase.STAGE_WAITING_FOR_FEEDBACK]: dataCompletedFields,
    [stagesCase.STAGE_DATA_COMPLETED_LAWFIRM]: dataLawFirmCompletedFields,
    [stagesCase.STAGE_CHECKED]: checkedFields,
    [stagesCase.STAGE_IN_PROGRESS]: inProgressFields,
    [stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT]: communicationSendFields,
    [stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED]: reactionReceivedFields,
    [stagesCase.STAGE_ACTION_REQUIRED]: reactionReceivedFields,
    [stagesCase.STAGE_PREPARE_LAWSUIT]: reactionReceivedFields,
    [stagesCase.STAGE_LAWSUIT]: lawsuitFields,
    [stagesCase.STAGE_TRIAL]: lawsuitFields,
    [stagesCase.STAGE_LAWSUIT_COMPLETED]: lawsuitCompletedFields,
    [stagesCase.STAGE_COMPLETED]: completedFields,
  };
};
