import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./activityOverviewHeaderStyles";
import Grid from "@mui/material/Grid";
import ProductFilter from "./ProductFilter/ProductFilter";
import UserFilter from "./UserFilter/UserFilter";
import TimeFilter from "./TimeFilter/TimeFilter";
import DateRangeFilter from "./DateRangeFilter/DateRangeFilter";
import ActivityTypeFilter from "./ActivityTypeFilter/ActivityTypeFilter";
import { ACTIVITY_OVERDUE } from "../../../services/Stages/Stages";
import _ from "lodash";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import { MenuItem } from "@mui/material";

const ActivityOverviewHeader = ({
  isLoading,
  userActivitiesToday,
  productFilter,
  isDeadlineView,
  setProductFilter,
  activityTypeFilter,
  setActivityTypeFilter,
  userFilter,
  setUserFilter,
  timespanFilter,
  setTimespanFilter,
  agents,
  openOrDoneFilter = null,
  setOpenOrDoneFilter = null,
}) => {
  const classes = useStyles();
  const userActivitiesOverdue = _.filter(
    userActivitiesToday,
    (activity) => activity.activityStatus === ACTIVITY_OVERDUE
  );
  return (
    <Grid container className={classes.filterContainer} spacing={2}>
      <Grid item xs={isDeadlineView ? 5 : 3} />
      {!isDeadlineView && (
        <Grid item xs={2}>
          <ValidatorSelect
            label="Status"
            onChange={({ target }) => {
              setOpenOrDoneFilter(target.value);
            }}
            value={openOrDoneFilter}
            className={classes.filterSelect}
            fullWidth={false}
          >
            <MenuItem value={"open"}>Offen</MenuItem>
            <MenuItem value={"done"}>Erledigt</MenuItem>
          </ValidatorSelect>
        </Grid>
      )}
      <Grid item xs={2}>
        <ProductFilter productFilter={productFilter} setProductFilter={setProductFilter} />
      </Grid>
      <Grid item xs={2}>
        <ActivityTypeFilter
          activityTypeFilter={activityTypeFilter}
          setActivityTypeFilter={setActivityTypeFilter}
          isDeadlineView={isDeadlineView}
        />
      </Grid>
      <Grid item xs={3}>
        <UserFilter
          userFilter={userFilter}
          setUserFilter={setUserFilter}
          agents={agents}
          isDeadlineView={isDeadlineView}
        />
      </Grid>
      <Grid item xs={4} className={classes.activityCount}>
        {!["all", "accountManager", "lawyer"].includes(userFilter) && !isLoading && (
          <>
            <span>Heute noch: {userActivitiesToday.length}</span>
            <span className={classes.activitiesOverdue}>Davon überfällig: {userActivitiesOverdue.length}</span>
          </>
        )}
      </Grid>
      <Grid item xs={5}>
        <TimeFilter
          timespanFilter={timespanFilter}
          setTimespanFilter={setTimespanFilter}
          isDeadlineView={isDeadlineView}
          isCompletedView={openOrDoneFilter === "done"}
        />
      </Grid>
      <Grid item xs={3}>
        <DateRangeFilter timespanFilter={timespanFilter} setTimespanFilter={setTimespanFilter} />
      </Grid>
    </Grid>
  );
};

ActivityOverviewHeader.propTypes = {
  activityCount: PropTypes.number,
  productFilter: PropTypes.string.isRequired,
  setProductFilter: PropTypes.func.isRequired,
  userFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setUserFilter: PropTypes.func.isRequired,
  timespanFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  setTimespanFilter: PropTypes.func.isRequired,
  userActivitiesToday: PropTypes.array,
  isLoading: PropTypes.bool,
  isDeadlineView: PropTypes.bool,
  openOrClosedFilter: PropTypes.string,
  setOpenOrClosedFilter: PropTypes.func,
};

export default ActivityOverviewHeader;
