export const tabItemStyles = ({ palette }) => ({
  root: {
    opacity: 1,
    minHeight: 56,
    flex: 1,
    maxWidth: 253,
    padding: "6px 16px",
    overflow: "visible",
  },
  labelIcon: {
    "& $wrapper > *:first-child": {
      marginBottom: 0,
      marginRight: 16,
    },
  },
  wrapper: ({ color = palette.primary.main }) => ({
    color: "rgba(0,0,0,0.6)",
    flexDirection: "row",
    justifyContent: "flex-start",
    textTransform: "initial !important",
    "& .MuiTabItem-labelGroup": {
      minWidth: 0,
    },
    "& .MuiTabItem-label": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiTabItem-tag": {
      backgroundColor: color,
      color: "#fff",
      fontSize: "0.75rem",
      borderRadius: 2,
      lineHeight: "16px",
      padding: "1px 4px",
      letterSpacing: 0.3,
      marginLeft: 8,
      whiteSpace: "nowrap",
      fontWeight: "normal",
    },
  }),
  selected: ({ color }) => ({
    fontWeight: "bold",
    "& $wrapper": {
      color: `${color} !important`,
    },
  }),
});

const tabStyle = {
  color: "rgba(0,0,0,0.6)",
  flexDirection: "row",
  justifyContent: "flex-start",
  textTransform: "initial !important",
};

export const tabItemTagStyle = {
  ...tabStyle,
  backgroundColor: "primary.main",
  color: "#fff",
  fontSize: "0.75rem",
  borderRadius: 2,
  lineHeight: "16px",
  padding: "1px 4px",
  letterSpacing: 0.3,
  marginLeft: 1,
  whiteSpace: "nowrap",
  fontWeight: "normal",
};

export const tabItemLabelStyle = {
  ...tabStyle,
  display: "flex",
  alignItems: "center",
};

export const tabItemLabelGroupStyle = {
  ...tabStyle,
  minWidth: 0,
};
