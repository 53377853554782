import React, { useCallback } from "react";
import layoutStyle from "./layoutStyle";
import GeneralActions from "../../Layout/GeneralActions";
import BackofficeUserNotifications from "../../BackofficeUserNotifications/BackofficeUserNotifications";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import GlobalProvider from "../../Provider/GlobalProvider";
import { Box } from "@mui/material";
import { useAccessibleCases } from "../../../provider/AccessibleCasesProvider";
import _ from "lodash";
import { Hit } from "@algolia/client-search";
import NavigationBar from "../../NavigationBar/NavigationBar";
import { useTechStatus } from "../../../provider/TechStatusProvider";

export default function Layout({ children }: { children?: React.ReactNode }) {
  const techStatus = useTechStatus();
  return (
    <GlobalProvider>
      {techStatus?.release && (
        <Box sx={layoutStyle.blockPage}>
          <LegalbirdLoader />
        </Box>
      )}
      <NavigationBarWithFilter />
      <Box sx={layoutStyle.layoutContainer}>
        <Box sx={layoutStyle.generalActionsContainer}>
          <GeneralActions />
        </Box>
        <Box sx={layoutStyle.mainContentContainer}>{children}</Box>
        <Box sx={layoutStyle.additionalActionsContainer} />
      </Box>
      <BackofficeUserNotifications />
    </GlobalProvider>
  );
}

const NavigationBarWithFilter = () => {
  const { accessibleCases } = useAccessibleCases();
  const accessibleCaseIds = _.map(accessibleCases, (accessibleCase) => accessibleCase.lbProductId);
  const accessibleCasesFilter = useCallback(
    (hits: Hit<any>[]) => {
      return _.filter(hits, (hit) => {
        return _.includes(accessibleCaseIds, hit.productId);
      });
    },
    [accessibleCaseIds]
  );
  return <NavigationBar postSearchFilter={accessibleCasesFilter}/>
}
