import React from "react";
import { getIsApplicationComplete } from "../../../services/Product/ProductService";
import Grid from "@mui/material/Grid";
import { translate } from "../../../services/Translations/translatorService";
import moment from "moment";
import _ from "lodash";
import * as PropTypes from "prop-types";
import ContentBox from "../../ContentBox/ContentBox";

const PreleadInfoBox = ({ product }) => {
  if (!_.includes(["divorce", "settlement"], product.productClassName) || getIsApplicationComplete(product)) {
    return null;
  }

  return (
    <ContentBox headline="Angaben bei Registrierung">
      <Grid container spacing={2}>
        <Grid item xs={5}>
          {translate("customer.prelead.landingPage.label")}:
        </Grid>
        <Grid item xs={5}>
          {product.customer.prelead.landingPage}
        </Grid>
        {product.productClassName === "divorce" && (
          <>
            <Grid item xs={5}>
              {translate("customer.prelead.classification.label")}:
            </Grid>
            <Grid item xs={5}>
              {product.customer.prelead.classification &&
                translate("customer.prelead.classification.values." + product.customer.prelead.classification)}
            </Grid>
          </>
        )}
        {product.productClassName === "settlement" && (
          <>
            <Grid item xs={5}>
              {translate("customer.prelead.numberOfEmployees.label")}:
            </Grid>
            <Grid item xs={5}>
              {product.customer.prelead.numberOfEmployees &&
                translate("customer.prelead.numberOfEmployees.values." + product.customer.prelead.numberOfEmployees)}
            </Grid>
            <Grid item xs={5}>
              {translate("customer.prelead.employedSince.label")}:
            </Grid>
            <Grid item xs={5}>
              {product.customer.prelead.employedSince &&
                moment(product.customer.prelead.employedSince).format("MM/YYYY")}
            </Grid>
            <Grid item xs={5}>
              {translate("customer.prelead.grossSalary.label")}:
            </Grid>
            <Grid item xs={5}>
              {product.customer.prelead.grossSalary} €
            </Grid>
          </>
        )}
      </Grid>
    </ContentBox>
  );
};

PreleadInfoBox.propTypes = {
  product: PropTypes.object.isRequired,
};

export default PreleadInfoBox;
