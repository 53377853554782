import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import React, { useState } from "react";
import ApiClient from "../../../services/ApiClient";
import PropTypes from "prop-types";

function PowerOfAttorneyModal({ product, open, handleClose, refreshPage }) {
  const [isLoading, setIsLoading] = useState(false);

  const resetContractCase = async () => {
    setIsLoading(true);
    await ApiClient.post(
      "case_special_actions/" + product.productClassName + "/" + product.id + "/reset_power_of_attorney"
    );
    refreshPage();
    handleClose();
  };

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={"Vollmacht neu einholen?"}
      submitButton={
        <ButtonLoading onClick={resetContractCase} color="primary" variant={"contained"} isLoading={isLoading}>
          Vollmacht neu einholen
        </ButtonLoading>
      }
    >
      <p className={"center"}>
        Möchten Sie die Vollmacht neu vom Mandanten einholen? Der Mandant erhält dann eine Nachricht zum Unterzeichnen
        der Vollmacht.
      </p>
    </LegalbirdIoModal>
  );
}

PowerOfAttorneyModal.propTypes = {
  product: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refreshPage: PropTypes.func,
};

PowerOfAttorneyModal.defaultProps = {
  refreshPage: () => {},
};
export default PowerOfAttorneyModal;
