import React from "react";
import { HitProperties } from "./hitType";
import _ from "lodash";
import { Box, CardActionArea, Stack } from "@mui/material";
import hitStyles from "./hitStyles";

export default function OrganizationHit({ hit, resetHits, onHitClick, itemSize }: HitProperties) {
  const highlightResult = hit._highlightResult;
  const organizationId = parseInt(_.replace(hit.objectID, "organization::", ""));

  if (!highlightResult || !organizationId) {
    return null;
  }

  const handleCardClick = () => {
    resetHits();
    onHitClick && onHitClick(organizationId);
  };

  return (
    <CardActionArea sx={{height: itemSize + "px", ...hitStyles.cardActionArea}} onClick={handleCardClick}>
      <Box sx={hitStyles.headline}>
        {highlightResult.legalName && (
          <span dangerouslySetInnerHTML={{ __html: highlightResult.legalName.value + " " }} />
        )}
        {highlightResult.name && <span dangerouslySetInnerHTML={{ __html: highlightResult.name.value + " " }} />}
      </Box>
      <Stack direction={"row"}>
        {!!hit["address.streetAddress"] && (
          <Box margin={"0 1rem 0.5rem 0"} minWidth={"50%"}>
            {hit["address.streetAddress"]}
            <br />
            {hit["address.postalCode"]} {hit["address.addressLocality"]}
          </Box>
        )}
        {!!hit["address.postOfficeBoxNumber"] && (
          <Box marginBottom={"0.5rem"}>
            Postfach: {hit["address.postOfficeBoxNumber"]}
            <br />
            {hit["address.postOfficeBoxPostalCode"]} {hit["address.postOfficeBoxAddressLocality"]}
          </Box>
        )}
      </Stack>
      <Box>
        {hit.email && (
          <>
            E-Mail: {hit.email}
            <br />
          </>
        )}
        {hit.telephone && (
          <>
            Tel: {hit.telephone}
            <br />
          </>
        )}
        {hit.fax && (
          <>
            Fax: {hit.fax}
            <br />
          </>
        )}
      </Box>
    </CardActionArea>
  );
}
