import React from "react";
import { styled, TableCell, TableRow } from "@mui/material";
import { ExternalLawyerApplication } from "../../types/ExternalLawyerApplication";
import { translate } from "../../services/Translations/translatorService";
import moment from "moment";
import { Link } from "react-router-dom";
import { useBackofficeUser } from "../../provider/BackofficeUserProvider";
import _ from "lodash";

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.main,
}));

type ExternalApplicationRowProps = {
  application: ExternalLawyerApplication;
};

export default function ExternalApplicationRow({ application }: ExternalApplicationRowProps) {

  const { backofficeUsers } = useBackofficeUser();

  const checkedByUser = _.find(backofficeUsers, user => user["@id"] === application.checkedBy);

  return (
    <TableRow>
      <TableCell>
        <StyledLink to={"/services/tools/bewerbungen/" + application.id}>
          {`${translate("general.petitioner.gender.values." + application.personalData.gender)} ${application.personalData.fullName}`}
        </StyledLink>
      </TableCell>
      <TableCell>{translate("application.status.values." + application.status)}</TableCell>
      <TableCell>{moment(application.created).format("DD.MM.YYYY")}</TableCell>
      <TableCell>{application.deniedDate && moment(application.deniedDate).format("DD.MM.YYYY")}</TableCell>
      <TableCell>{checkedByUser?.person?.fullname}</TableCell>
    </TableRow>
  );
}
