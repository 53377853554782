import React from "react";
import * as PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import formElementAnnotatedStyle from "./formElementAnnotatedStyle";
import FormElement from "./FormElement";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(formElementAnnotatedStyle);

const FormElementAnnotated = ({ headline, description, ...rest }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant={"h3"}>{headline}</Typography>
      <br/>
      <Typography>{description}</Typography>
      <div className={classes.field}><FormElement {...rest} /></div>
    </>
  );
};

FormElementAnnotated.propTypes = {
  /* local Element props */
  type: PropTypes.oneOf([
    "ValidatorTextField",
    "ValidatorSelect",
    "ValidatorDateField",
    "InsurancePicker",
    "AuthorityPicker",
    "ProsecutionPicker",
    "ContractRepresentatives",
    "CaseRejectionCategorySelect",
    "ProcessParticipantsPeopleForm",
    "ProcessParticipantsOrganizationsForm",
    "EmptyElement",
  ]).isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  translationPath: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  helperText: PropTypes.string,
  isDisabled: PropTypes.func,
  options: PropTypes.func,
  validators: PropTypes.arrayOf(PropTypes.object),
  isHidden: PropTypes.func,
  additionalProps: PropTypes.object,
  /* useForm props */
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  registerValidators: PropTypes.func,
  touchedValues: PropTypes.object,
  isLoading: PropTypes.bool,
  handleDateChange: PropTypes.func,
  alert: PropTypes.object,
  headline: PropTypes.string,
  description: PropTypes.string,
};

export default FormElementAnnotated;
