import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  teaserContainer: {
    padding: 20,
    color: "#ffffff",
  },
  highlight: {
    fontWeight: 500,
    marginBottom: 30,
  },
}));

export default useStyles;
