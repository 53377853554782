const beaMessageStyles = {
  icon: { color: "#333333" },
  iconBox: { width: 40, height: 40 },
  faded: {
    textAlign: "center",
    color: "#dcdcdc",
    fontSize: "1.25rem",
    margin: "2rem",
  },
};

export default beaMessageStyles;
