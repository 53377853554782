import React from "react";
import HolidaysProvider from "../../provider/HolidayProvider";
import BackofficeUserProvider from "../../provider/BackofficeUserProvider";
import AccessibleCasesProvider from "../../provider/AccessibleCasesProvider";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

export default function GlobalProvider({ children }: { children?: React.ReactNode }) {
  const currentUser = useCurrentUser();
  return (
    <BackofficeUserProvider>
      <HolidaysProvider>
        {currentUser.isExternal ? (
          <>
            <AccessibleCasesProvider>{children}</AccessibleCasesProvider>
          </>
        ) : (
          <>{children}</>
        )}
      </HolidaysProvider>
    </BackofficeUserProvider>
  );
}
