import React from "react";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import FormElement from "../FormElement";
import SubmitButton from "../../../Button/SubmitButton";
import * as PropTypes from "prop-types";
import useStyles from "./caseFullFormStyle";
import { getRequiredFields } from "../../../../services/StatusChangeValidators/statusChangeValidators";
import { transformToFormValues } from "../../../../services/formServiceFunctions";
import { persist } from "../../../../services/Persist/Persister";
import useForm from "../../../../hooks/useForm";
import ValidatorForm from "../../../Validator/ValidatorForm";
import ContentBox from "../../../ContentBox/ContentBox";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../../services/ReactQuery/reactQueryService";
import { getProductData } from "../../../../services/Product/ProductService";

const SubForm = ({ headline, formDefinition, product, formDisabled, refreshPage }) => {
  const classes = useStyles({});
  const mandatoryFields = getRequiredFields(product);
  const queryClient = useQueryClient();
  const initialValues = {
    [product.productClassName]: transformToFormValues(product),
  };

  const onSubmit = async ({ values }) => {
    await persist(values, product);
    await queryClient.invalidateQueries(queryKeys.item(getProductData(product.productClassName, "apiUri"), product.id));
  };

  const useFormProps = useForm({
    initialValues,
    onSubmit,
    identifier: product,
  });

  return (
    <ContentBox headline={headline} headlineVariant="h2">
      <ValidatorForm onSubmit={useFormProps.handleSubmit}>
        {_.map(formDefinition.sections, (formSection, index) => {
          if (formSection.isHidden && formSection.isHidden({ values: useFormProps.values, product })) {
            return null;
          }

          return (
            <Grid key={index} container className={classes.grid}>
              <Grid item xs={12} className={classes.formHeadline}>
                <Typography variant="h3">{formSection.label}</Typography>
              </Grid>
              {_.map(formSection.elements, (formElementProps) => {
                if (formElementProps.isHidden && formElementProps.isHidden({ values: useFormProps.values, product })) {
                  return null;
                }

                //important! overwrites all individual isDisabled configurations
                if (formDisabled) {
                  formElementProps.isDisabled = () => true;
                }

                const fullWidth = formElementProps.additionalProps && formElementProps.additionalProps.fullWidth;

                return (
                  <Grid key={formElementProps.path} item xs={12} md={!fullWidth && 6} lg={!fullWidth && 4}>
                    <FormElement
                      product={product}
                      {...formElementProps}
                      {...useFormProps}
                      mandatoryFields={mandatoryFields}
                      refreshPage={refreshPage}
                    />
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
        {!formDefinition.withoutSubmit && (
          <Grid container className={classes.grid}>
            <Grid item xs={12}>
              <SubmitButton
                error={_.keys(useFormProps.errors).length > 0}
                isLoading={useFormProps.isLoading}
                disabled={_.keys(useFormProps.touchedValues).length === 0}
                variant={"contained"}
                type={"submit"}
              >
                Änderungen Speichern
              </SubmitButton>
            </Grid>
          </Grid>
        )}
      </ValidatorForm>
    </ContentBox>
  );
};

SubForm.propTypes = {
  product: PropTypes.object.isRequired,
  headline: PropTypes.string.isRequired,
  formDefinition: PropTypes.object.isRequired,
  formDisabled: PropTypes.bool,
};

SubForm.defaultProps = {
  formDisabled: false,
};

export default SubForm;
