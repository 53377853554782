import React from "react";
import { AbstractCase } from "../../../types/AbstractCase";

type InsuranceDataProps = {
  product: AbstractCase;
};

export default function InsuranceData({ product }: InsuranceDataProps) {
  return (
    <>
      {product.insurance.insurance?.name || "-"} | Versicherungsnr: {product.insurance.insurancePolicyNumber || "-"} |
      Schadennr: {product.insurance.caseReferenceNumber || "-"}
    </>
  );
}
