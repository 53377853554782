import React from "react";
import { useParams } from "react-router-dom";
import LegalBirdPageLoading from "../../ContentLoader/LegalBirdPageLoading";
import CaseRelatedActions from "../../Case/CaseRelatedActions/CaseRelatedActions";
import PageContentContainer from "../../Container/PageContentContainer";
import { ProductHeadline } from "../../PageHeadline/PageHeadline";
import CaseProvider, { useCase } from "../../../provider/CaseProvider";
import CaseReferenceFile from "../../Case/CaseReferenceFile/CaseReferenceFile";
import _ from "lodash";
import { Box } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";

const CaseReferenceFilePage = () => {
  const { productId, productUrlPart } = useParams();
  return (
    <CaseProvider caseUrlPart={productUrlPart} caseId={productId}>
      <CaseReferenceFilePageInner />
    </CaseProvider>
  );
};

const CaseReferenceFilePageInner = () => {
  const { courtAppointmentId } = useParams();
  const { product, refreshCase } = useCase();
  const queryClient = useQueryClient();
  const referenceFilesUri = "reference_files";
  const referenceFilesFilters = { courtAppointmentId };
  const { data: referenceFiles, isLoading } = useQuery(
    queryKeys.collection(referenceFilesUri, referenceFilesFilters),
    () => fetchCollection(referenceFilesUri, referenceFilesFilters),
    {
      enabled: !!product,
    }
  );

  if (isLoading || !referenceFiles) {
    return <LegalBirdPageLoading />;
  }

  return (
    <>
      <CaseRelatedActions product={product} refreshPage={refreshCase} />
      <PageContentContainer size={"small"}>
        <ProductHeadline title="Terminsvertreter-Handakte zu" product={product} />
        {referenceFiles["hydra:member"].length < 1 ? (
          <Box sx={{ textAlign: "center" }}>Es gibt keine Handakte für diesen Termin</Box>
        ) : (
          <CaseReferenceFile
            product={product}
            referenceFile={_.head(referenceFiles["hydra:member"])}
            refreshReferenceFile={() =>
              queryClient.invalidateQueries(queryKeys.collection(referenceFilesUri, referenceFilesFilters))
            }
          />
        )}
      </PageContentContainer>
    </>
  );
};

export default CaseReferenceFilePage;
