const releaseUpdateStyles = {
  button: {
    width: "320px",
  },
  editorStyle: {
    minHeight: "20rem",
    border: "1px solid #ddd",
    padding: "0 1rem",
  },
};

export default releaseUpdateStyles;
