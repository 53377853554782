import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  attachment: {
    border: "1px solid #d9d9d9",
    padding: ".5rem",
  },
  iconButton: {
    padding: 0,
  },
  loading: {
    margin: "0 auto",
  },
}));

export default useStyles;
