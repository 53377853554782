import React, { useEffect, useState } from "react";
import PageContentContainer from "../../Container/PageContentContainer";
import PageHeadline from "../../PageHeadline/PageHeadline";
import { useParams } from "react-router-dom";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import ApiClient from "../../../services/ApiClient";
import { Typography } from "@mui/material";
import EditReleaseUpdate from "../../ReleaseUpdates/EditReleaseUpdate";

export default function EditReleaseUpdatePage() {
  const { releaseUpdateId } = useParams();

  const [releaseUpdate, setReleaseUpdate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    ApiClient.get("release_updates/" + releaseUpdateId)
      .then((result) => setReleaseUpdate(result))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <LegalbirdLoader centered />;
  }

  if (!releaseUpdate) {
    return <Typography sx={{ textAlign: "center" }}>Releaseupdate nicht gefunden</Typography>;
  }

  return (
    <PageContentContainer>
      <PageHeadline main={"Release-Update bearbeiten"} />
      <EditReleaseUpdate releaseUpdate={releaseUpdate} />
    </PageContentContainer>
  );
}
