import React from "react";
import _ from "lodash";
import ApiClient from "../../../services/ApiClient";
import FileSection from "./FileSection";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../services/ReactQuery/reactQueryService";

const ProcessCostSupportFileSection = ({
  headline,
  description,
  processCostSupport,
  fieldname,
  setMediaObjectToDisplay,
}) => {
  const updateMutation = useMutation(updateResource);

  const deleteFile = async (id) => {
    let receipts = _.merge({}, processCostSupport.receipts);
    receipts[fieldname] = _.filter(receipts[fieldname], (mediaObject) => {
      return mediaObject.id !== id;
    });
    await updateMutation.mutateAsync({
      uri: "process_cost_supports",
      id: processCostSupport.id,
      data: { receipts: receipts },
    });
  };

  const uploadFile = async (files) => {
    let formData = new FormData();
    formData.append("file", files[0]);
    formData.append("product", "pcs");
    formData.append("description", description);
    formData.append("fieldname", fieldname);
    formData.append("customerId", processCostSupport.customer.id);

    let headers = new Headers();
    headers.set("Content-Type", "multipart/form-data");
    const mediaObject = await ApiClient.post("media_objects", {
      headers: headers,
      body: formData,
    });

    let receipts = { ...processCostSupport.receipts };
    const sectionReceipts = receipts[fieldname] || [];
    let mutableSectionReceipts = [...sectionReceipts];
    if (!mediaObject.status) {
      mutableSectionReceipts.push(mediaObject);
    }
    receipts[fieldname] = mutableSectionReceipts;
    await updateMutation.mutateAsync({
      uri: "process_cost_supports",
      id: processCostSupport.id,
      data: { receipts: receipts },
    });
  };

  return (
    <FileSection
      currentMediaObjects={processCostSupport.receipts[fieldname]}
      uploadFile={uploadFile}
      deleteFile={deleteFile}
      headline={headline + " [" + description + "]"}
      setMediaObjectToDisplay={setMediaObjectToDisplay}
    />
  );
};

export default ProcessCostSupportFileSection;
