import React from "react";
import PageContentContainer from "../../../Container/PageContentContainer";
import PaidTaskContainer from "../../../PaidTask/PaidTaskTable/PaidTaskContainer";

const ExtrenalsAcceptedPaidTasksPage = () => {
  return (
    <PageContentContainer>
      <PaidTaskContainer displayType={"assigneeView"} headline={"Angenommene Aufgaben"} />
    </PageContentContainer>
  );
};

export default ExtrenalsAcceptedPaidTasksPage;
