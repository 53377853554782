const calendlyAssessmentStyle = {
  container: {
    padding: "2rem",
    marginTop: "2rem",
    width: "100%",
  },
  headline: {
    textAlign: "center",
    fontSize: "1.25rem",
    marginBottom: "1rem",
  },
  ratingContainer: {
    paddingTop: 10,
  },
  center: {
    textAlign: "center",
  },
  noteContainer: {
    padding: "0.5rem",
    background: "#fafafa",
  },
  assessmentContainer: {
    marginTop: "1rem",
    marginLeft: "72px",
  },
  topSpace: {
    paddingTop: "1rem",
  },
};
export default calendlyAssessmentStyle;
