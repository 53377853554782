import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  textField: {
    //because the formcontrol form the autocomplete has uneven margins and i dont know how to remove them
    paddingBottom: "7px",
  },
  autoWidthPopper: {
    width: "fit-content",
  },
}));

export default useStyles;
