import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { Box, CircularProgress, IconButton, MenuItem, Tooltip } from "@mui/material";
import { Check, DoDisturbAlt, NotificationImportant } from "@mui/icons-material";
import beaMessageStyles from "./beaMessageStyles";
import BeaAcknowledgementModal from "./BeaAcknowledgementModal";
import PopUpMenu from "../PopUpMenu/PopUpMenu";
import ApiClient from "../../services/ApiClient";
import { userHasOneOfTheseRoles } from "../../services/backofficeUserService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

export default function BeaMessageActions({ beaMessage, refreshBeaMessages, product }) {
  const [beaAcknowledgementModalOpen, setBeaAcknowledgementModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useCurrentUser();

  if (isLoading) {
    return (
      <Box sx={beaMessageStyles.iconBox}>
        <CircularProgress />
      </Box>
    );
  }

  switch (beaMessage.transmissionDirection) {
    case "sent":
      const generateTransmissionProtocoll = async () => {
        setIsLoading(true);
        await ApiClient.post("bea/" + beaMessage.id + "/generateTransmissionReport", {
          body: JSON.stringify({
            productClassName: product.productClassName,
            productId: product.id,
          }),
        });
        refreshBeaMessages();
        setIsLoading(false);
      };

      return (
        <Box sx={beaMessageStyles.iconBox}>
          <PopUpMenu>
            <MenuItem onClick={generateTransmissionProtocoll}>Sendebericht generieren</MenuItem>
          </PopUpMenu>
        </Box>
      );
    default:
      return (
        <>
          {beaMessage.eEBStatus === "requested" && (
            <>
              <Box sx={beaMessageStyles.iconBox}>
                <IconButton
                  onClick={() => setBeaAcknowledgementModalOpen(true)}
                  disabled={!userHasOneOfTheseRoles(currentUser, ["ROLE_LAWYER", "ROLE_ADMIN"])}
                >
                  <Tooltip title={"Empfangsbekenntnis abgeben"}>
                    <NotificationImportant sx={beaMessageStyles.icon} />
                  </Tooltip>
                </IconButton>
              </Box>
              <BeaAcknowledgementModal
                open={beaAcknowledgementModalOpen}
                handleClose={() => setBeaAcknowledgementModalOpen(false)}
                beaMessage={beaMessage}
                refreshBeaMessages={refreshBeaMessages}
              />
            </>
          )}
          {beaMessage.eEBStatus === "submitted" && (
            <Tooltip title={"Empfangsbekenntnis abgegeben"}>
              <Check sx={beaMessageStyles.icon} />
            </Tooltip>
          )}
          {beaMessage.eEBStatus === "rejected" && (
            <Tooltip title={"Empfangsbekenntnis abgelehnt"}>
              <DoDisturbAlt sx={beaMessageStyles.icon} />
            </Tooltip>
          )}
        </>
      );
  }
}

BeaMessageActions.propTypes = {
  beaMessage: PropTypes.object.isRequired,
  refreshBeaMessages: PropTypes.func.isRequired,
};
