import React, { ReactNode } from "react";
import { Box, Grid } from "@mui/material";
import CircleIcon from "../../CircleIcon/CircleIcon";
import caseHistoryItemStyle from "./caseHistoryItemStyle";
import moment from "moment/moment";
import { HistoryLog } from "../../../types/HistoryLog";

interface GenericLogEntryProps {
  subject: string;
  Icon: any;
  iconType?: "muiIcon" | "iconFile";
  info?: ReactNode;
  historyItem: HistoryLog;
}

const GenericLogEntry = ({ subject, Icon, iconType = "muiIcon", info = null, historyItem }: GenericLogEntryProps) => {
  return (
    <Grid container>
      <Grid item xs={1}>
        <CircleIcon icon={iconType === "muiIcon" ? <Icon /> : Icon} type={iconType} />
      </Grid>
      <Grid item xs={11}>
        <Box sx={caseHistoryItemStyle.headline}>{subject}</Box>
        <Box sx={caseHistoryItemStyle.info}>
          {info}
          {moment(historyItem.logDate).format("DD.MM.YYYY HH:mm")} Uhr |{" "}
          {historyItem?.backofficeUser.person.fullname || "System"}
        </Box>
      </Grid>
    </Grid>
  );
};

export default GenericLogEntry;
