import React from "react";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import * as PropTypes from "prop-types";
import useStyles from "./legalbirdAutoCompleteStyle";

const LegalbirdAutoComplete = ({ setValue, label, onFocus = null, ...rest }) => {
  const classes = useStyles({});

  return (
    <Autocomplete
      renderInput={(params) => <TextField {...params} onFocus={onFocus} label={label} className={classes.textField} />}
      getOptionLabel={(option) => option.label}
      disableClearable
      onChange={setValue}
      {...rest}
    />
  );
};

LegalbirdAutoComplete.propTypes = {
  options: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
  getOptionDisabled: PropTypes.func,
  label: PropTypes.string,
  groupBy: PropTypes.func,
};

LegalbirdAutoComplete.defaultProps = {
  getOptionDisabled: () => false,
  label: "",
};

export default LegalbirdAutoComplete;
