import React from "react";
import { getCaseDetailsSpecialActions } from "../../../../services/Product/ProductService";
import * as PropTypes from "prop-types";
import { Box } from "@mui/material";

const CaseDetailsSpecialActions = ({ product }) => {
  const caseDetailsSpecialActions = getCaseDetailsSpecialActions(product);
  if (caseDetailsSpecialActions) {
    return <Box sx={{ marginTop: "1rem" }}>{caseDetailsSpecialActions}</Box>;
  }
  return null;
};
export default CaseDetailsSpecialActions;

CaseDetailsSpecialActions.propTypes = {
  product: PropTypes.object.isRequired,
};
