import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import * as PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  badge: {
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 5,
    textAlign: "center",
    padding: "5px 0 5px 0",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  small: {
    minWidth: 60,
    fontSize: 12,
  },
  large: {
    paddingLeft: 5,
    paddingRight: 5,
    minWidth: 120,
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      minWidth: 106,
    },
  },
  activeBadge: {
    borderColor: theme.palette.border.active,
    color: theme.palette.text.primary,
  },
  inactiveBadge: {
    borderColor: theme.palette.border.inActive,
    color: theme.palette.text.light,
  },
  link: {
    color: theme.palette.text.light,
    textDecoration: "none",
  },
}));

const Badge = (props) => {
  const classes = useStyles(props);

  const { isActive, linkTo, text, size } = props;

  return isActive ? (
    <div className={classNames(classes.badge, classes.activeBadge, classes[size])}>{text}</div>
  ) : (
    <div className={classNames(classes.badge, classes.inactiveBadge, classes[size])}>
      <Link className={classes.link} to={linkTo}>
        {text}
      </Link>
    </div>
  );
};

Badge.propTypes = {
  isActive: PropTypes.bool,
  text: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
  fontSize: PropTypes.number,
  width: PropTypes.number,
};

Badge.defaultProps = {
  isActive: true,
  size: "large",
};

export default Badge;
