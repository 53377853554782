const beaMessageTranslations = {
  acknowledgementOfReceipt: {
    rejectionReason: {
      wrongRecipient: "Zustellungsempfänger nicht am Verfahren beteiligt",
      contentUnclear: "Inhalt der Sendung unklar oder unvollständig",
      certificateCheckFailed: "Zertifikatspruefung fehlgeschlagen",
    },
  },
};

export default beaMessageTranslations;
