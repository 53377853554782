import React, { Component } from "react";

const withScroll = (ComposedComponent) =>
  (class ScrollDecorator extends Component {
    constructor(props) {
      super(props);
      this.state = {
        scrollPosition: this.getWindowScrollTop(),
      };
      this.handleInterval = this.handleInterval.bind(this);
      this.handleRequestAnimationFrame = this.handleRequestAnimationFrame.bind(this);
    }

    componentDidMount() {
      this.intervalID = setInterval(this.handleInterval, 250);
    }

    componentWillUnmount() {
      clearInterval(this.intervalID);
      cancelAnimationFrame(this.requestID);
      this.requestID = null;
      this.intervalID = null;
    }

    getWindowScrollTop = () => {
      return window.pageYOffset || document.documentElement.scrollTop;
    };

    handleInterval() {
      cancelAnimationFrame(this.requestID);
      this.requestID = requestAnimationFrame(this.handleRequestAnimationFrame);
    }

    handleRequestAnimationFrame() {
      const newScrollPosition = this.getWindowScrollTop();
      if (newScrollPosition !== this.state.scrollPosition) {
        this.setState({ scrollPosition: newScrollPosition });
      }
    }

    render() {
      return <ComposedComponent {...this.props} scrollPosition={this.state.scrollPosition} />;
    }
  });

export default withScroll;
