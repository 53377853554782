import makeStyles from '@mui/styles/makeStyles';
import { scrollToAnchor } from "../../../../theme/commonStyles";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "1rem 0 2rem 0",
    padding: "2rem",
  },
  headline: {
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: 700,
  },
  formHeadline: {
    textAlign: "center",
    marginTop: "2rem",
  },
  grid: {
    "& > .MuiGrid-item": {
      [theme.breakpoints.up("md")]: {
        padding: 12,
      },
      minWidth: 320,
    },
  },
  link: {
    margin: "0 auto",
    display: "block",
    textAlign: "center",
    width: 170,
  },
  scrollToAnchor: {
    ...scrollToAnchor,
  },
  loading: {
    textAlign: "center",
    margin: "5rem",
  },
}));

export default useStyles;
