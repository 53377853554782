const trafficTransformations = {
  dateFields: [
    { path: "fineNoticeReceivedDate", type: "date" },
    { path: "fineNoticeDate", type: "date" },
    { path: "fineDeadline", type: "date" },
    { path: "powerOfAttorney", type: "dateTime" },
    { path: "accessToRecordRequested", type: "date" },
    { path: "insuranceCoverageDate", type: "date" },
    { path: "opinionSubmitted", type: "date" },
    { path: "authorityAnswered", type: "date" },
    { path: "courtDecisionDate", type: "date" },
    { path: "petitioner.birthDate", type: "date" },
    { path: "verification", type: "dateTime" },
    { path: "appealRevoked", type: "date" },
    { path: "customerAppealRevocationApproved", type: "date" },
    { path: "processStoppedDate", type: "date" },
  ],
  numberFields: ["leadStatus", "applicationStatus", "responsibleCourt.advoAssistId"],
  moneyFields: [
    "finalFineAmount",
    "insurance.deductible",
  ],
};
export default trafficTransformations;
