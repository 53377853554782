import * as stagesDivorce from "../Stages/StagesDivorce";
import * as stagesSettlement from "../Stages/StagesSettlement";
import * as stagesTraffic from "../Stages/StagesTraffic";
import * as stagesBasicCase from "../Stages/StagesBasicCase";
import * as stagesCase from "../Stages/StagesCase";
import _ from "lodash";
import moment from "moment";
import { translate } from "../Translations/translatorService";
import { getNextStage } from "../Product/ProductService";
import { statusCheckedValidatorsTraffic } from "./Traffic/statusChangeValidatorsTraffic";

const basicCaseValidators = {
  [stagesBasicCase.STAGE_CREATED]: () => [],
  [stagesBasicCase.STAGE_DATA_COMPLETED]: () => [],
  [stagesCase.STAGE_IN_PROGRESS]: () => [],
  [stagesBasicCase.STAGE_COMPLETED]: () => [],
};

const getDataCompletedLawFirmRequiredFields = (product) => {
  let requiredFields = [];

  switch (true) {
    case product.caseCategoryAssessment === "reject":
      requiredFields.push("assessmentRejectionText");
      break;

    case _.includes(["paymentOrder", "enforcementOrder", "lawsuit"], product.defenseProcessStatus):
      requiredFields.push("timeBarredDate");
      if (product.caseCategory === "enforceClaim") {
        requiredFields.push("courtPostalCode");
      }
      if (product.caseCategory === "defendClaim") {
        requiredFields.push("responsibleCourt");
      }
      if (product.defenseProcessStatus === "paymentOrder") {
        requiredFields.push("paymentOrderDate");
      }
      if (product.defenseProcessStatus === "enforcementOrder") {
        requiredFields.push("enforcementOrderDate");
      }
      if (product.defenseProcessStatus === "lawsuit") {
        requiredFields.push("statementOfClaimFiledDate");
      }
      break;

    default:
      requiredFields.push("caseCategoryAssessment");
      requiredFields.push("timeBarredDate");
      if (product.caseCategory === "enforceClaim") {
        requiredFields.push("objectiveLawFirm");
      }
      if (
        product.caseCategory === "enforceClaim"
      ) {
        requiredFields.push("extrajudicialCommunicationDeadline");
      }
      break;
  }
  return requiredFields;
};

const contractDataCompletedLawFirmValidator = (product, returnRequiredFields = false) => {
  const requiredFields = getDataCompletedLawFirmRequiredFields(product);
  return returnRequiredFields ? requiredFields : validateFields(requiredFields, product);
};

const contractCheckedValidator = (product, returnRequiredFields = false) => {
  const requiredFields = getDataCompletedLawFirmRequiredFields(product);
  if (
    product.caseCategoryAssessment !== "reject" &&
    !_.includes(["paymentOrder", "enforcementOrder","lawsuit"], product.defenseProcessStatus)
  ) {
    requiredFields.push("automaticallyGenerateExtrajudicialLetter");
  }
  return returnRequiredFields ? requiredFields : validateFields(requiredFields, product);
};

const contractValidators = {
  [stagesCase.STAGE_CREATED]: () => [],
  [stagesCase.STAGE_DATA_COMPLETED]: () => [],
  [stagesCase.STAGE_WAITING_FOR_FEEDBACK]: (product, returnRequiredFields) =>
    returnRequiredFields ? contractDataCompletedLawFirmValidator(product, true) : [],
  [stagesCase.STAGE_DATA_COMPLETED_LAWFIRM]: contractDataCompletedLawFirmValidator,
  [stagesCase.STAGE_CHECKED]: contractCheckedValidator,
  [stagesCase.STAGE_IN_PROGRESS]: () => [],
  [stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT]: (product, returnRequiredFields = false) => {
    let requiredFields = ["extrajudicialCommunicationSent"];
    if (returnRequiredFields) {
      return requiredFields;
    }
    return validateFields(requiredFields, product);
  },
  [stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED]: (product, returnRequiredFields = false) => {
    let requiredFields = ["extrajudicialReaction"];
    if (product.extrajudicialReaction !== "noReaction") {
      requiredFields.push("extrajudicialReactionReceived");
    }

    if (
      _.includes(["defendClaim", "enforceClaim"], product.caseCategory) &&
      product.extrajudicialReaction !== "fullyAgreed"
    ) {
      requiredFields.push("extrajudicialRecommendation");
    }
    if (returnRequiredFields) {
      return requiredFields;
    }
    return validateFields(requiredFields, product);
  },
  [stagesCase.STAGE_ACTION_REQUIRED]: () => [],
  [stagesCase.STAGE_PREPARE_LAWSUIT]: (product, returnRequiredFields = false) => {
    let requiredFields = ["insurance.coverageGrantedDate"];
    if (returnRequiredFields) {
      return requiredFields;
    }
    return validateFields(requiredFields, product);
  },
  [stagesCase.STAGE_LAWSUIT]: (product, returnRequiredFields = false) => {
    let requiredFields = ["valueInDispute"];
    if (product.caseCategory === "enforceClaim") {
      requiredFields.push("courtPostalCode");
    }
    if (product.caseCategory === "defendClaim") {
      requiredFields.push("statementOfClaimFiledDate");
      requiredFields.push("responsibleCourt");
    }

    if (returnRequiredFields) {
      return requiredFields;
    }
    return validateFields(requiredFields, product);
  },
  [stagesCase.STAGE_TRIAL]: () => [],
  [stagesCase.STAGE_LAWSUIT_COMPLETED]: (product, returnRequiredFields = false) => {
    let requiredFields = [
      "lawsuitCompletedReason",
      "lawsuitCompletedDate",
      "valueInDispute",
      "hasStandardInvoicing",
      "subtractExtrajudicialProcessFee",
    ];
    if (!_.includes(["courtDecision", "defaultJudgement"], product.lawsuitCompletedReason)) {
      requiredFields.push("customerLawsuitCompletedApprovalDate");
    }

    if (product.lawsuitCompletedReason !== "lawsuitRevocation") {
      requiredFields.push("costBearingByOpponentRelative");
    }

    if (returnRequiredFields) {
      return requiredFields;
    }
    let errorMessages = validateFields(requiredFields, product);

    if (
      product.lawsuitCompletedReason === "defaultJudgement" &&
      moment().isBefore(moment(product.lawsuitCompletedDate))
    ) {
      errorMessages.push("Das Versäumnisurteil muss rechtskräftig sein (Rechtskräftige VU am)");
    }

    return errorMessages;
  },
  [stagesCase.STAGE_COMPLETED]: (product, returnRequiredFields = false) => {
    let requiredFields = [];
    if (returnRequiredFields) {
      return requiredFields;
    }
    return validateFields(requiredFields, product);
  },
};

const statusChangedValidators = {
  divorce: {
    [stagesDivorce.STAGE_INCOMPLETE]: () => {
      return [];
    },
    [stagesDivorce.STAGE_READY]: () => {
      return [];
    },
    [stagesDivorce.STAGE_CHECKED]: (divorce, returnRequiredFields = false) => {
      let requiredFields = [
        "netIncomePetitioner",
        "netIncomePartner",
        "marriageRegistrationNumber",
        "responsibleLawyer",
      ];

      if (divorce.processCostSupport) {
        requiredFields.push("processCostSupportVerified");
      }

      if (returnRequiredFields) {
        return requiredFields;
      }

      let errorMessages = validateFields(requiredFields, divorce);
      if (divorce.backofficeCase.accountManager === null) {
        errorMessages.push("Fall hat keinen Zuständigen - bitte zuordnen!");
      }

      if (divorce.marriageCertificate === null) {
        errorMessages.push('Das Dokument "Heiratsurkunde" fehlt!');
      }

      _.forEach(divorce.children, (child) => {
        if (child.birthCertificate === null) {
          errorMessages.push(`Das Dokument "Geburtsurkunde ${child.name}" fehlt!`);
        }
      });

      return errorMessages;
    },
    [stagesDivorce.STAGE_PROCESS_PAID]: () => {
      return [];
    },
    [stagesDivorce.STAGE_SUBMITTED]: () => {
      return [];
    },
    [stagesDivorce.STAGE_JUDICIAL_PROCESS]: (divorce, returnRequiredFields = false) => {
      let requiredFields = ["referenceCourt"];
      if (returnRequiredFields) {
        return requiredFields;
      }
      return validateFields(requiredFields, divorce);
    },
    [stagesDivorce.STAGE_DATE_SET]: (divorce, returnRequiredFields = false) => {
      let requiredFields = ["referenceCourt"];
      if (returnRequiredFields) {
        return requiredFields;
      }
      return validateFields(requiredFields, divorce);
    },
    [stagesDivorce.STAGE_HEARING_PAID]: () => {
      return [];
    },
    [stagesDivorce.STAGE_DIVORCE_DONE]: (divorce, returnRequiredFields = false) => {
      let requiredFields = ["valueInDisputeReal"];
      if (returnRequiredFields) {
        return requiredFields;
      }
      return validateFields(requiredFields, divorce);
    },
    [stagesDivorce.STAGE_COMPLETED]: () => {
      return [];
    },
  },
  eventContract: contractValidators,
  familyLawCase: basicCaseValidators,
  membershipContract: contractValidators,
  otherContract: contractValidators,
  providerContract: contractValidators,
  purchaseContract: contractValidators,
  serviceContract: contractValidators,
  settlement: {
    [stagesSettlement.STAGE_INCOMPLETE]: () => {
      return [];
    },
    [stagesSettlement.STAGE_READY]: () => {
      return [];
    },
    [stagesSettlement.STAGE_CHECKED]: (settlement, returnRequiredFields = false) => {
      let requiredFields = [
        "contractTerminationDate",
        "lastWorkDayCalculated",
        "lastWorkDayCancellation",
        "contractDate",
        "bargainingCoverage",
        "representedBy",
        "employerOrganization.legalForm",
        "employerOrganization.name",
        //needed for court crawler
        "employerOrganization.address.postalCode",
        "responsibleLawyer",
        "paymentType",
        "terminationType",
      ];

      if (settlement.legalExpenseInsurance) {
        requiredFields.push("insurance.deductible", "insurance.insurance", "insurance.insurancePolicyNumber");
      }

      if (returnRequiredFields) {
        return requiredFields;
      }
      let errorMessages = validateFields(requiredFields, settlement);

      const hasCompleteNormalAddress = settlement.employerOrganization.address.streetAddress &&
        settlement.employerOrganization.address.postalCode &&
        settlement.employerOrganization.address.addressLocality;

      const hasCompletePostOfficeBoxAddress = settlement.employerOrganization.address.postOfficeBoxNumber &&
        settlement.employerOrganization.address.postOfficeBoxPostalCode &&
        settlement.employerOrganization.address.postOfficeBoxAddressLocality;

      if (!hasCompleteNormalAddress && !hasCompletePostOfficeBoxAddress) {
        errorMessages.push("Entweder die Standardadresse oder die Postfachadresse des Arbeitgebers müssen vollständig befüllt sein");
      }

      if (settlement.backofficeCase.accountManager === null) {
        errorMessages.push("Fall hat keinen Zuständigen - bitte zuordnen!");
      }

      if (settlement.contract === null) {
        errorMessages.push('Das Dokument "Arbeitsvertrag" fehlt!');
      }

      if (settlement.contractTermination === null) {
        errorMessages.push('Das Dokument "Kündigung" fehlt!');
      }

      return errorMessages;
    },
    [stagesSettlement.STAGE_SUBMITTED]: () => {
      return [];
    },
    [stagesSettlement.STAGE_PAID]: (settlement, returnRequiredFields = false) => {
      let requiredFields = [];
      if (settlement.legalExpenseInsurance) {
        requiredFields.push("insuranceCoverageDate");
      }
      if (returnRequiredFields) {
        return requiredFields;
      }
      return validateFields(requiredFields, settlement);
    },
    [stagesSettlement.STAGE_DATE_SET_SETTLEMENT]: (settlement, returnRequiredFields = false) => {
      let requiredFields = [
        "referenceCourt",
      ];
      if (returnRequiredFields) {
        return requiredFields;
      }
      return validateFields(requiredFields, settlement);
    },
    [stagesSettlement.STAGE_DATE_SET_TRIAL]: () => {
      return [];
    },
    [stagesSettlement.STAGE_DECISION_MADE]: (settlement) => {
      let errorMessages = [];

      if (settlement.settlementReachedDate && settlement.finalJudgement) {
        errorMessages.push(
          `${translate("settlement.settlementReachedDate.label")} und ${translate(
            "settlement.finalJudgement.label"
          )} dürfen nicht beide befüllt sein!`
        );
      }
      if (!settlement.settlementReachedDate && !settlement.finalJudgement) {
        errorMessages.push(
          `${translate("settlement.settlementReachedDate.label")} ODER ${translate(
            "settlement.finalJudgement.label"
          )} müssen befüllt sein!`
        );
      }

      return errorMessages;
    },
    [stagesSettlement.STAGE_COMPLETED]: (settlement, returnRequiredFields = false) => {
      let requiredFields = [];
      let errorMessages = [];
      if (
        !(
          settlement.finalJudgement ||
          (settlement.settlementReachedDate &&
            settlement.settlementRevocationDate &&
            moment(settlement.settlementRevocationDate).isBefore(moment()))
        )
      ) {
        errorMessages.push(`Die Widerrufsfrist des Urteils muss abgelaufen sein!`);
      }

      if (settlement.legalExpenseInsurance) {
        requiredFields.push("insuranceCoverageDate");
      }
      if (returnRequiredFields) {
        return requiredFields;
      }
      return [...errorMessages, ...validateFields(requiredFields, settlement)];
    },
  },
  subscriptionContract: contractValidators,
  traffic: {
    [stagesTraffic.STAGE_INCOMPLETE]: () => {
      return [];
    },
    [stagesTraffic.STAGE_READY]: () => {
      return [];
    },
    [stagesTraffic.STAGE_CHECKED]: statusCheckedValidatorsTraffic,
    [stagesTraffic.STAGE_AUTHORIZED]: statusCheckedValidatorsTraffic,
    [stagesTraffic.STAGE_INSURANCE_COVERAGE_REQUEST]: (traffic, returnRequiredFields = false) => {
      let requiredFields = ["finalFineAmount",];
      return returnRequiredFields ? requiredFields : validateFields(requiredFields, traffic);
    },
    [stagesTraffic.STAGE_ACCESS_TO_RECORDS]: (traffic, returnRequiredFields = false) => {
      let requiredFields = ["responsibleAuthority", "finalFineAmount"];
      if (!traffic.processStoppedDate) {
        requiredFields.push("fineNoticeReceivedDate");
        requiredFields.push("fineNoticeDate");
      }
      if (returnRequiredFields) {
        return requiredFields;
      }
      return validateFields(requiredFields, traffic);
    },
    [stagesTraffic.STAGE_EXTRAJUDICIAL_PAID]: (traffic, returnRequiredFields = false) => {
      let requiredFields = ["responsibleAuthority"];
      if (traffic.paymentType === "insurance") {
        requiredFields.push("insuranceCoverageDate");
      }
      if (returnRequiredFields) {
        return requiredFields;
      }
      return validateFields(requiredFields, traffic);
    },
    [stagesTraffic.STAGE_OPINION_SUBMITTED]: (traffic, returnRequiredFields = false) => {
      let requiredFields = ["responsibleAuthority", "opinionRequired"];

      if (traffic.opinionRequired) {
        requiredFields.push("opinionSubmitted");
      }
      if (returnRequiredFields) {
        return requiredFields;
      }
      return validateFields(requiredFields, traffic);
    },
    [stagesTraffic.STAGE_AUTHORITY_ANSWERED]: (traffic, returnRequiredFields = false) => {
      let requiredFields = [
        "authorityAnswered",
        "authorityDecision",
        "responsibleAuthority",
        "opinionRequired",
      ];
      if (returnRequiredFields) {
        return requiredFields;
      }
      return validateFields(requiredFields, traffic);
    },
    [stagesTraffic.STAGE_TRIAL]: (traffic, returnRequiredFields = false) => {
      let requiredFields = [
        "trialWanted",
        "responsibleCourtAddress.postalCode",
        "responsibleAuthority",
      ];
      let errorMessages = [];
      if (traffic.appealRevoked !== null) {
        errorMessages.push(`“${translate("traffic.appealRevoked.label")}” darf nicht ausgefüllt sein.`);
      }
      if (traffic.trialWanted !== null && traffic.trialWanted !== "yes") {
        errorMessages.push(
          `“${translate("traffic.trialWanted.label")}” ist nicht “${translate(
            "traffic.trialWanted.values.yes"
          )}”.`
        );
      }
      if (traffic.authorityDecision !== "court") {
        errorMessages.push(
          `“${translate("traffic.authorityDecision.label")}” ist nicht “${translate(
            "traffic.authorityDecision.values.court"
          )}”.`
        );
      }
      if (returnRequiredFields) {
        return requiredFields;
      }
      return [...errorMessages, ...validateFields(requiredFields, traffic)];
    },
    [stagesTraffic.STAGE_TRIAL_DATE_SET]: (traffic, returnRequiredFields = false) => {
      let requiredFields = [
        "courtNotice",
        "courtReference",
        "responsibleAuthority",
      ];

      let errorMessages = [];
      if (traffic.courtNotice !== "trial") {
        errorMessages.push(
          `“${translate("traffic.courtNotice.label")}” ist nicht “${translate(
            "traffic.courtNotice.values.trial"
          )}”.`
        );
      }

      if (returnRequiredFields) {
        return requiredFields;
      }
      return [...errorMessages, ...validateFields(requiredFields, traffic)];
    },
    [stagesTraffic.STAGE_TRIAL_PAID]: (traffic, returnRequiredFields = false) => {
      if (traffic.paymentType !== "private") {
        return [];
      }

      let requiredFields = ["responsibleAuthority"];
      if (returnRequiredFields) {
        return requiredFields;
      }
      return validateFields(requiredFields, traffic);
    },
    [stagesTraffic.STAGE_DECISION_MADE]: (traffic, returnRequiredFields = false) => {
      let requiredFields = ["responsibleAuthority"];
      let errorMessages = [];

      if (traffic.processStoppedDate) {
        return [];
      }

      if (traffic.legalExpenseInsurance === true || traffic.insuranceRequested === "yes") {
        requiredFields.push("insurance.deductible");
      }

      if (
        traffic.appealRevoked === null &&
        !traffic.courtDecisionDate &&
        !["revoked", "changed"].includes(traffic.authorityDecision) &&
        !["revoked", "no_trial"].includes(traffic.courtNotice)
      ) {
        errorMessages.push(
          `Eine der folgenden Optionen muss erfüllt sein: "${translate(
            "traffic.appealRevoked.label"
          )}" ist befüllt, "${translate("traffic.courtDecisionDate.label")}" ist befüllt, "${translate(
            "traffic.authorityDecision.label"
          )}" ist "${translate("traffic.authorityDecision.values.revoked")}" oder "${translate(
            "traffic.courtNotice.label"
          )}" ist "${translate("traffic.courtNotice.values.revoked")}" oder "${translate(
            "traffic.courtNotice.values.no_trial"
          )}"`
        );
      }

      if (traffic.courtNotice === "trial" && traffic.appealRevoked === null) {
        requiredFields.push("courtDecisionDate");
      }

      if (traffic.paymentType !== "private") {
        requiredFields.push("insurance.caseReferenceNumber");
      }

      if (returnRequiredFields) {
        return requiredFields;
      }
      return [...errorMessages, ...validateFields(requiredFields, traffic)];
    },
    [stagesTraffic.STAGE_COMPLETED]: (traffic, returnRequiredFields = false) => {
      let requiredFields = ["responsibleAuthority"];

      if (returnRequiredFields) {
        return requiredFields;
      }
      return validateFields(requiredFields, traffic);
    },
  },
  travelContract: contractValidators,
  vehicleContract: contractValidators,
};

const getRequiredFields = (product) => {
  const productClassName = product.productClassName;
  const nextStage = getNextStage(product);
  if (!productClassName || !nextStage) {
    return [];
  }
  const getRequiredFields = statusChangedValidators[productClassName][nextStage.stage];
  if (!getRequiredFields) {
    return [];
  }
  return getRequiredFields(product, true);
};

export const validateFields = (requiredFields, productEntity) => {
  let errorMessages = [];

  _.forEach(requiredFields, (requiredField) => {
    if (
      _.get(productEntity, requiredField) === undefined ||
      _.get(productEntity, requiredField) === null ||
      _.get(productEntity, requiredField) === ""
    ) {
      errorMessages.push(
        `Das Feld "${translate(productEntity.productClassName + "." + requiredField + ".label")}" muss ausgefüllt sein!`
      );
    }
  });
  return errorMessages;
};

export const validateStatusUpdate = (product, stage) => {
  return statusChangedValidators[product.productClassName][stage](product);
};

export default statusChangedValidators;

export { getRequiredFields };
