import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import ButtonLoading from "../../Button/ButtonLoading";
import _ from "lodash";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, updateResource } from "../../../services/ReactQuery/reactQueryService";
import { BackofficeUser } from "../../../types/BackofficeUser";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { electronicFormatIBAN, isValidIBAN } from "ibantools";

export default function BillingForm({ backofficeUser, bankData }: { backofficeUser: BackofficeUser; bankData: any }) {
  const [isLoading, setIsLoading] = useState(false);
  const updateMutation = useMutation(updateResource);
  const queryClient = useQueryClient();

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    await updateMutation.mutateAsync({
      uri: "backoffice_addresses",
      id: _.get(backofficeUser, "person.address.id"),
      data: {
        streetAddress: values.streetAddress,
        postalCode: values.postalCode,
        addressLocality: values.addressLocality,
      },
    });
    await updateMutation.mutateAsync({
      uri: "backoffice_bank_datas",
      id: bankData.id,
      data: {
        accountOwnerFullName: values.accountOwnerFullName,
        iban: values.iban,
      },
    });
    await queryClient.invalidateQueries(queryKeys.all("backoffice_users"));
    await queryClient.invalidateQueries(queryKeys.all("current_user"));
    setIsLoading(false);
  };

  return (
    <FormContainer
      defaultValues={{
        streetAddress: _.get(backofficeUser, "person.address.streetAddress", ""),
        postalCode: _.get(backofficeUser, "person.address.postalCode", ""),
        addressLocality: _.get(backofficeUser, "person.address.addressLocality", ""),
        accountOwnerFullName: bankData.accountOwnerFullName,
        iban: bankData.iban,
      }}
      onSuccess={handleSubmit}
    >
      <Grid container spacing={3} sx={{ marginTop: "2rem" }}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant={"h5"}>Rechnungs-Adresse</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextFieldElement
            name={"streetAddress"}
            label={"Straße und Hausnummer"}
            validation={{
              required: "Straße und Hausnummer ist ein Pflichtfeld",
              pattern: {
                value: /^([a-zA-ZäÄöÖüÜß]{2}.*|[a-uA-U][0-9]{1,2})\s.*[0-9]+.*$/,
                message: "Bitte geben Sie Straße und Hausnummer an",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextFieldElement
            name={"postalCode"}
            label={"Postleitzahl"}
            validation={{
              required: "Postleitzahl ist ein Pflichtfeld",
              pattern: {
                value: /^\d{3,6}$/,
                message: "Die Postleitzahl hat ein falsches Format",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextFieldElement
            name={"addressLocality"}
            label={"Ort"}
            validation={{
              required: "Ort ist ein Pflichtfeld",
              minLength: {
                value: 3,
                message: "Bitte geben den Ort an",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant={"h5"}>Bankdaten</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextFieldElement
            name={"accountOwnerFullName"}
            label={"Kontoinhaber"}
            validation={{
              required: "Kontoinhaber ist ein Pflichtfeld",
              minLength: {
                value: 3,
                message: "Bitte geben den Kontoinhaber an",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextFieldElement
            name={"iban"}
            label={"IBAN"}
            validation={{
              required: "IBAN ist ein Pflichtfeld",
              validate: {
                isIban: (value: string) => isValidIBAN(electronicFormatIBAN(value) || "") || "Die eingegebene IBAN ist nicht korrekt"
              },
            }}
            helperText={
              "Achtung: Diese IBAN wird für die Abrechnung verwendet. Bitte stellen Sie sicher, dass Sie die richtige Bankverbindung angeben."
            }
          />
        </Grid>
        <Grid item xs={12}>
          <ButtonLoading
            variant={"contained"}
            fullWidth={false}
            sx={{ margin: "0 auto", display: "block" }}
            isLoading={isLoading}
            type={"submit"}
          >
            Speichern
          </ButtonLoading>
        </Grid>
      </Grid>
    </FormContainer>
  );
}
