import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import _ from "lodash";
import PaidTaskLogEntry from "./PaidTaskLogEntry";
import moment from "moment";
import { PaidTask } from "../../types/PaidTask";

type PaidTaskLogProps = {
  paidTask: PaidTask;
};

export default function PaidTaskLog({ paidTask }: PaidTaskLogProps) {
  const [showLog, setShowLog] = useState(false);

  return (
    <>
      {showLog && (
        <>
          <Box sx={{ margin: "1rem" }}>
            Erstellt: {moment(paidTask.created).format("DD.MM.YYYY | HH:mm") + " Uhr | " + paidTask.creator?.person?.fullname}
          </Box>
          {_.map(paidTask.paidTaskLog, (logEntry, logDateTime) => (
            <PaidTaskLogEntry logEntry={logEntry} key={logDateTime} logDateTime={logDateTime} />
          ))}
        </>
      )}
      <Button sx={{marginTop: ".5rem"}} onClick={() => setShowLog(!showLog)}>{showLog ? "Historie ausblenden" : "Historie anzeigen"}</Button>
    </>
  );
}
