import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import _ from "lodash";
import { PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED } from "../paidTaskStatus";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import { formValue } from "../../../services/formServiceFunctions";
import { MenuItem } from "@mui/material";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import useForm from "../../../hooks/useForm";
import { requiredFieldDefault } from "../../../services/validationRules";
import ButtonLoading from "../../Button/ButtonLoading";
import moment from "moment";
import { useSnackbar } from "notistack";
import Snackbar from "../../Snackbar/Snackbar";
import { getCaseLinkByBackofficeCase } from "../../../services/Product/ProductService";
import { apiGet } from "../../../services/Api/apiCall";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../services/ReactQuery/reactQueryService";

const RequestExternalLawyerModal = ({ paidTask, open, closeModal }) => {
  const [externalLawyersCapacities, setExternalLawyersCapacities] = useState(null);
  const [externalLawyerRecommendation, setExternalLawyerRecommendation] = useState(null);
  const updateMutation = useMutation(updateResource);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    apiGet("/services/external/capacities").then((capacitiesResponse) =>
      setExternalLawyersCapacities(capacitiesResponse)
    );
    apiGet("/services/external/recommendation", null, { backofficeCaseId: paidTask.backofficeCase?.id }).then(
      (recommendationResponse) => {
        setExternalLawyerRecommendation(recommendationResponse);
        handleChange({
          target: {
            name: "requestedUser",
            value: recommendationResponse?.recommendation
              ? "backoffice_users/" + recommendationResponse.recommendation
              : "",
          },
        });
      }
    );
  }, [paidTask]);

  const initialValues = {
    requestedUser: "",
  };

  const onSubmit = async () => {
    await updateMutation.mutateAsync({
      uri: "paid_tasks",
      id: paidTask.id,
      data: {
        requestedUser: formValue(values, "requestedUser"),
        requestedUserDate: moment().format("DD.MM.YYYY"),
      },
    });
    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar
            message={"Anfrage erfolgreich versendet"}
            buttonType={"link"}
            buttonLink={getCaseLinkByBackofficeCase(paidTask.backofficeCase)}
            buttonTarget={"_self"}
            buttonText={"Zum Fall"}
          />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    closeModal();
  };

  const { values, errors, handleChange, handleSubmit, registerValidators, handleBlur, isLoading } = useForm({
    initialValues,
    onSubmit,
    clearFormAfterSubmit: true,
  });

  if (!_.includes([PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED], paidTask.status)) {
    return null;
  }
  return (
    <LegalbirdIoModal
      handleClose={() => closeModal()}
      open={open}
      title={"Anwalt für Aufgabe anfragen"}
      submitButton={
        <ButtonLoading variant={"contained"} color={"primary"} onClick={handleSubmit} isLoading={isLoading}>
          Anwalt anfragen
        </ButtonLoading>
      }
    >
      Bitte wählen Sie aus, welchen Anwalt Sie bezüglich der Übernahme der Aufgabe anfragen möchten. Es wird dann eine
      E-Mail an den betreffenden Anwalt samt Link zu dieser Aufgabe verschickt.
      <form>
        <ValidatorSelect
          label={"Anfrage für Übernahme senden an"}
          name={"requestedUser"}
          value={formValue(values, "requestedUser")}
          onChange={handleChange}
          onBlur={handleBlur}
          validators={requiredFieldDefault}
          registerValidators={registerValidators}
          error={!!errors["requestedUser"]}
          helperText={errors["requestedUser"]}
        >
          {_.map(externalLawyersCapacities, (externalLawyerCapacity) => {
            return (
              <MenuItem
                key={externalLawyerCapacity.backofficeUser.id}
                value={"backoffice_users/" + externalLawyerCapacity.backofficeUser.id}
                disabled={!externalLawyerCapacity.isAvailable}
              >
                {externalLawyerCapacity.backofficeUser.person.fullname} ({externalLawyerCapacity.requestedTasks}{" "}
                Anfragen | {externalLawyerCapacity.activeTasks} Aufgaben)
              </MenuItem>
            );
          })}
        </ValidatorSelect>
      </form>
    </LegalbirdIoModal>
  );
};

RequestExternalLawyerModal.propTypes = {
  paidTask: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default RequestExternalLawyerModal;
