import React from "react";
import * as stagesTraffic from "../../Stages/StagesTraffic";
import { STAGE_ACCESS_TO_RECORDS, STAGE_EXTRAJUDICIAL_PAID } from "../../Stages/StagesTraffic";
import { formValue } from "../../formServiceFunctions";
import { trueFalseDefaultOptions, yesNoDefaultOptions, yesNoUnknownDefaultOptions } from "./formDefinitionFunctions";
import {
  dateFieldDefault,
  insurancePickerValidator,
  moneyFieldDefault,
  postalCodeDefault,
  textFieldDefault,
} from "../../validationRules";
import InputAdornment from "@mui/material/InputAdornment";
import { hasActiveCourtAppointment } from "../ProductService";
import { getCourtAppointmentFields } from "./contract/fullFormDefinition";
import _ from "lodash";
import { allDataPageCourtFields } from "./allDataPageCourtFields";

const decisionType = ({ product }) => {
  return product.authorityDecision === "revoked" ||
    product.authorityDecision === "changed" ||
    product.appealRevoked !== null
    ? "extrajudicial"
    : "court";
};

const opinionRequiredFields = [
  {
    type: "ValidatorSelect",
    path: "traffic.opinionRequired",
    options: ({ product }) => trueFalseDefaultOptions(`${product.productClassName}.opinionRequired`),
  },
  {
    type: "ValidatorDateField",
    path: "traffic.opinionSubmitted",
    validators: dateFieldDefault,
    isHidden: ({ values }) => formValue(values, "traffic.opinionRequired") === false,
  },
  {
    type: "ValidatorDateField",
    path: "traffic.authorityAnswered",
    validators: dateFieldDefault,
    isHidden: ({ values }) => formValue(values, "traffic.opinionRequired") !== false,
  },
  {
    type: "ValidatorSelect",
    path: "traffic.authorityDecision",
    options: () => [{ value: "__null__" }, { value: "revoked" }, { value: "changed" }, { value: "court" }],
    isHidden: ({ values }) => formValue(values, "traffic.opinionRequired") !== false,
  },
];

const incompleteAndReadyData = [
  {
    type: "ValidatorTextField",
    path: "traffic.powerOfAttorney",
    isDisabled: () => true,
  },
  {
    type: "ValidatorSelect",
    path: "traffic.legalExpenseInsurance",
    options: () => trueFalseDefaultOptions("traffic.legalExpenseInsurance"),
  },
  {
    type: "ValidatorSelect",
    path: "traffic.paymentType",
    options: () => [{ value: "__null__" }, { value: "insurance" }, { value: "private" }],
  },
  {
    type: "ValidatorSelect",
    path: "traffic.accusation",
    isDisabled: () => true,
    options: () => [
      { value: "__null__" },
      { value: "speeding" },
      { value: "red_light" },
      { value: "distance" },
      { value: "other" },
    ],
  },
  {
    type: "ValidatorSelect",
    path: "traffic.accusationOther",
    isDisabled: () => true,
    isHidden: ({ values }) => formValue(values, "traffic.accusation") !== "other",
    options: () => [
      { value: "__null__" },
      { value: "mobile_phone" },
      { value: "drugs" },
      { value: "parking" },
      { value: "other" },
    ],
  },
  /* ============== RED LIGHT =================== */
  {
    type: "ValidatorSelect",
    path: "traffic.roadUsersEndangered",
    isHidden: ({ values }) => formValue(values, "traffic.accusation") !== "red_light",
    options: () => yesNoDefaultOptions("traffic.roadUsersEndangered"),
  },
  {
    type: "ValidatorSelect",
    path: "traffic.damage",
    isHidden: ({ values }) => formValue(values, "traffic.accusation") !== "red_light",
    options: () => yesNoUnknownDefaultOptions("traffic.damage"),
  },
  {
    type: "ValidatorSelect",
    path: "traffic.redLight",
    isHidden: ({ values }) => formValue(values, "traffic.accusation") !== "red_light",
    options: () => [{ value: "__null__" }, { value: "under_1_sec" }, { value: "1_sec_or_more" }, { value: "unknown" }],
  },
  /* ============== DISTANCE =================== */
  {
    type: "ValidatorSelect",
    path: "traffic.speed",
    isHidden: ({ values }) => formValue(values, "traffic.accusation") !== "distance",
    options: () => [
      { value: "__null__" },
      { value: "<81" },
      { value: "81-100" },
      { value: "101-130" },
      { value: ">130" },
      { value: "unknown" },
    ],
  },
  {
    type: "ValidatorSelect",
    path: "traffic.distance",
    isHidden: ({ values }) => formValue(values, "traffic.accusation") !== "distance",
    options: () => [
      { value: "__null__" },
      { value: "5/10" },
      { value: "4/10" },
      { value: "3/10" },
      { value: "2/10" },
      { value: "1/10" },
      { value: "unknown" },
    ],
  },
  /* ============== SPEEDING =================== */
  {
    type: "ValidatorSelect",
    path: "traffic.locality",
    isHidden: ({ values }) => formValue(values, "traffic.accusation") !== "speeding",
    options: () => [{ value: "__null__" }, { value: "in_town" }, { value: "out_of_town" }, { value: "unknown" }],
  },
  {
    type: "ValidatorSelect",
    path: "traffic.speeding",
    isHidden: ({ values }) => formValue(values, "traffic.accusation") !== "speeding",
    options: () => [
      { value: "__null__" },
      { value: "0-20" },
      { value: "21-30" },
      { value: "31-40" },
      { value: ">40" },
      { value: "41-50" },
      { value: "51-60" },
      { value: "61-70" },
      { value: ">70" },
      { value: "unknown" },
    ],
  },
  /* ============== MOBILE PHONE =================== */
  {
    type: "ValidatorSelect",
    path: "traffic.vehicle",
    isHidden: ({ values }) => formValue(values, "traffic.accusationOther") !== "mobile_phone",
    options: () => [
      { value: "__null__" },
      { value: "car" },
      { value: "bike" },
      { value: "motorcycle" },
      { value: "truck" },
      { value: "bus" },
    ],
  },
  /* ============== OPTIONALS END ===================== */
  {
    type: "ValidatorSelect",
    path: "traffic.authorityNotification",
    options: () => [
      { value: "__null__" },
      { value: "hearing_report" },
      { value: "witness_report" },
      { value: "fine_notice" },
      { value: "no_notice" },
    ],
  },
  {
    type: "ValidatorDateField",
    path: "traffic.fineNoticeReceivedDate",
    validators: dateFieldDefault,
  },
];
const checkedData = [
  {
    type: "ValidatorSelect",
    path: "traffic.accusation",
    isDisabled: () => true,
    options: () => [
      { value: "__null__" },
      { value: "speeding" },
      { value: "red_light" },
      { value: "distance" },
      { value: "other" },
    ],
  },
  {
    type: "ValidatorSelect",
    path: "traffic.accusationOther",
    isDisabled: () => true,
    isHidden: ({ values }) => formValue(values, "traffic.accusation") !== "other",
    options: () => [
      { value: "__null__" },
      { value: "mobile_phone" },
      { value: "drugs" },
      { value: "parking" },
      { value: "other" },
    ],
  },
];
const basicAccusationData = [
  {
    type: "ValidatorTextField",
    path: "traffic.fineNoticeReference",
    isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_CHECKED,
    validators: textFieldDefault,
  },
  {
    type: "ValidatorSelect",
    path: "traffic.accusation",
    isDisabled: () => true,
    options: () => [
      { value: "__null__" },
      { value: "speeding" },
      { value: "red_light" },
      { value: "distance" },
      { value: "other" },
    ],
  },
  {
    type: "ValidatorSelect",
    path: "traffic.accusationOther",
    isDisabled: () => true,
    isHidden: ({ values }) => formValue(values, "traffic.accusation") !== "other",
    options: () => [
      { value: "__null__" },
      { value: "mobile_phone" },
      { value: "drugs" },
      { value: "parking" },
      { value: "other" },
    ],
  },
];
const trialData = [
  ...basicAccusationData,
  {
    type: "ValidatorDateField",
    path: "traffic.authorityAnswered",
    isDisabled: () => true,
  },
  {
    type: "ValidatorSelect",
    path: "traffic.authorityDecision",
    isDisabled: () => true,
    options: () => [{ value: "__null__" }, { value: "revoked" }, { value: "changed" }, { value: "court" }],
  },
  {
    type: "ValidatorSelect",
    path: "traffic.courtNotice",
    isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_TRIAL_DATE_SET,
    options: () => [{ value: "__null__" }, { value: "revoked" }, { value: "no_trial" }, { value: "trial" }],
  },
  {
    type: "ValidatorTextField",
    path: "traffic.courtReference",
    validators: textFieldDefault,
  },
  {
    type: "ValidatorDateField",
    path: "traffic.courtDecisionDate",
    validators: dateFieldDefault,
  },
  {
    type: "ValidatorDateField",
    path: "traffic.customerAppealRevocationApproved",
    validators: dateFieldDefault,
    additionalProps: {
      helperText: "Dieses Feld stößt automatisch den Prozess zur Rücknahme des Einspruchs an.",
    },
    alert: {
      onInitial:
        "Das Speichern führt dazu, dass hintergründig ein Fax mit der Einspruchsrücknahme an die zuständige Stelle verschickt wird. Möchten Sie, dass Feld speichern und somit das Fax mit der Rücknahme versenden?",
    },
  },
  {
    type: "ValidatorDateField",
    path: "traffic.appealRevoked",
    validators: dateFieldDefault,
  },
];
const trialDoneData = [
  ...basicAccusationData,
  {
    type: "ValidatorTextField",
    path: "traffic.courtReference",
    isHidden: ({ product }) => decisionType({ product }) !== "court",
    validators: textFieldDefault,
  },
  {
    type: "ValidatorDateField",
    path: "traffic.courtDecisionDate",
    isHidden: ({ product }) => decisionType({ product }) !== "court",
    validators: dateFieldDefault,
  },
  {
    type: "ValidatorSelect",
    path: "traffic.authorityDecision",
    isHidden: ({ product }) => decisionType({ product }) !== "extrajudicial",
    isDisabled: () => true,
    options: () => [{ value: "__null__" }, { value: "revoked" }, { value: "changed" }, { value: "court" }],
  },
  {
    type: "ValidatorDateField",
    path: "traffic.customerAppealRevocationApproved",
    isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_DECISION_MADE,
    validators: dateFieldDefault,
    additionalProps: {
      helperText: "Dieses Feld stößt automatisch den Prozess zur Rücknahme des Einspruchs an.",
    },
    alert: {
      onInitial:
        "Das Speichern führt dazu, dass hintergründig ein Fax mit der Einspruchsrücknahme an die zuständige Stelle verschickt wird. Möchten Sie, dass Feld speichern und somit das Fax mit der Rücknahme versenden?",
    },
  },
  {
    type: "ValidatorDateField",
    path: "traffic.appealRevoked",
    isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_DECISION_MADE,
    validators: dateFieldDefault,
  },
];

export const stageFormDefinition = {
  [stagesTraffic.STAGE_INCOMPLETE]: [...incompleteAndReadyData],
  [stagesTraffic.STAGE_READY]: [
    ...incompleteAndReadyData,
    {
      type: "ValidatorDateField",
      path: "traffic.processStoppedDate",
      isHidden: ({ product }) => product.paymentType !== "private",
      validators: dateFieldDefault,
    },
  ],
  [stagesTraffic.STAGE_CHECKED]: checkedData,
  [stagesTraffic.STAGE_AUTHORIZED]: [
    ...basicAccusationData,
    {
      type: "ValidatorDateField",
      path: "traffic.processStoppedDate",
      isHidden: ({ product }) => product.paymentType !== "private",
      validators: dateFieldDefault,
    },
  ],
  [stagesTraffic.STAGE_INSURANCE_COVERAGE_REQUEST]: [
    {
      type: "ValidatorDateField",
      path: "traffic.processStoppedDate",
      validators: dateFieldDefault,
    },
  ],
  [stagesTraffic.STAGE_ACCESS_TO_RECORDS]: [
    ...checkedData,
    {
      type: "ValidatorDateField",
      path: "traffic.customerAppealRevocationApproved",
      validators: dateFieldDefault,
      additionalProps: {
        helperText: "Dieses Feld stößt automatisch den Prozess zur Rücknahme des Einspruchs an.",
      },
      alert: {
        onInitial:
          "Das Speichern führt dazu, dass hintergründig ein Fax mit der Einspruchsrücknahme an die zuständige Stelle verschickt wird. Möchten Sie, dass Feld speichern und somit das Fax mit der Rücknahme versenden?",
      },
    },
    {
      type: "ValidatorDateField",
      path: "traffic.appealRevoked",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "traffic.processStoppedDate",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "traffic.insuranceCoverageDate",
      isHidden: ({ values }) => formValue(values, "traffic.paymentType") !== "insurance",
      validators: dateFieldDefault,
    },
    ...opinionRequiredFields,
    {
      type: "ValidatorDateField",
      path: "traffic.accessToRecordRequested",
      validators: dateFieldDefault,
    },
  ],
  [stagesTraffic.STAGE_EXTRAJUDICIAL_PAID]: [
    ...basicAccusationData,
    {
      type: "ValidatorDateField",
      path: "traffic.accessToRecordRequested",
      isDisabled: () => true,
    },
    ...opinionRequiredFields,
    {
      type: "ValidatorDateField",
      path: "traffic.customerAppealRevocationApproved",
      validators: dateFieldDefault,
      additionalProps: {
        helperText: "Dieses Feld stößt automatisch den Prozess zur Rücknahme des Einspruchs an.",
      },
      alert: {
        onInitial:
          "Das Speichern führt dazu, dass hintergründig ein Fax mit der Einspruchsrücknahme an die zuständige Stelle verschickt wird. Möchten Sie, dass Feld speichern und somit das Fax mit der Rücknahme versenden?",
      },
    },
    {
      type: "ValidatorDateField",
      path: "traffic.appealRevoked",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "traffic.processStoppedDate",
      validators: dateFieldDefault,
    },
  ],
  [stagesTraffic.STAGE_OPINION_SUBMITTED]: [
    ...basicAccusationData,
    {
      type: "ValidatorDateField",
      path: "traffic.opinionSubmitted",
      isDisabled: () => true,
    },
    {
      type: "ValidatorDateField",
      path: "traffic.authorityAnswered",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorSelect",
      path: "traffic.authorityDecision",
      options: () => [{ value: "__null__" }, { value: "revoked" }, { value: "changed" }, { value: "court" }],
    },
    {
      type: "ValidatorDateField",
      path: "traffic.customerAppealRevocationApproved",
      validators: dateFieldDefault,
      additionalProps: {
        helperText: "Dieses Feld stößt automatisch den Prozess zur Rücknahme des Einspruchs an.",
      },
      alert: {
        onInitial:
          "Das Speichern führt dazu, dass hintergründig ein Fax mit der Einspruchsrücknahme an die zuständige Stelle verschickt wird. Möchten Sie, dass Feld speichern und somit das Fax mit der Rücknahme versenden?",
      },
    },
    {
      type: "ValidatorDateField",
      path: "traffic.appealRevoked",
      validators: dateFieldDefault,
    },
  ],
  [stagesTraffic.STAGE_AUTHORITY_ANSWERED]: [
    ...basicAccusationData,
    {
      type: "ValidatorDateField",
      path: "traffic.opinionSubmitted",
      isDisabled: () => true,
    },
    {
      type: "ValidatorDateField",
      path: "traffic.authorityAnswered",
      isDisabled: () => true,
    },
    {
      type: "ValidatorSelect",
      path: "traffic.authorityDecision",
      isDisabled: () => true,
      options: () => [{ value: "__null__" }, { value: "revoked" }, { value: "changed" }, { value: "court" }],
    },
    {
      type: "ValidatorSelect",
      path: "traffic.trialWanted",
      isHidden: ({ product }) => product.authorityDecision === "revoked",
      options: () => yesNoDefaultOptions("traffic.trialWanted"),
    },
    {
      type: "ValidatorSelect",
      path: "traffic.courtNotice",
      isHidden: ({ product }) => product.authorityDecision === "revoked",
      options: () => [{ value: "__null__" }, { value: "revoked" }, { value: "no_trial" }, { value: "trial" }],
    },
    {
      type: "ValidatorTextField",
      path: "traffic.courtReference",
      isHidden: ({ product }) => product.authorityDecision === "revoked",
      validators: textFieldDefault,
    },
    {
      type: "ValidatorTextField",
      path: "traffic.responsibleCourtAddress.postalCode",
      isHidden: ({ product }) => product.authorityDecision === "revoked",
      validators: textFieldDefault,
      alert: {
        onChange:
          "Das Ändern der Postleitzahl führt dazu, dass das zuständige Gericht erneut ermittelt wird. Möchten Sie die Postleitzahl tatsächlich ändern?",
      },
    },
    {
      type: "ValidatorDateField",
      path: "traffic.customerAppealRevocationApproved",
      validators: dateFieldDefault,
      additionalProps: {
        helperText: "Dieses Feld stößt automatisch den Prozess zur Rücknahme des Einspruchs an.",
      },
      alert: {
        onInitial:
          "Das Speichern führt dazu, dass hintergründig ein Fax mit der Einspruchsrücknahme an die zuständige Stelle verschickt wird. Möchten Sie, dass Feld speichern und somit das Fax mit der Rücknahme versenden?",
      },
    },
    {
      type: "ValidatorDateField",
      path: "traffic.appealRevoked",
      validators: dateFieldDefault,
    },
  ],
  [stagesTraffic.STAGE_TRIAL]: trialData,
  [stagesTraffic.STAGE_TRIAL_DATE_SET]: trialDoneData,
  [stagesTraffic.STAGE_TRIAL_PAID]: trialDoneData,
  [stagesTraffic.STAGE_DECISION_MADE]: trialDoneData,
  [stagesTraffic.STAGE_COMPLETED]: [
    {
      type: "ValidatorSelect",
      path: "traffic.accusation",
      isDisabled: () => true,
      options: () => [
        { value: "__null__" },
        { value: "speeding" },
        { value: "red_light" },
        { value: "distance" },
        { value: "other" },
      ],
    },
    {
      type: "ValidatorSelect",
      path: "traffic.accusationOther",
      isDisabled: () => true,
      isHidden: ({ values }) => formValue(values, "traffic.accusation") !== "other",
      options: () => [
        { value: "__null__" },
        { value: "mobile_phone" },
        { value: "drugs" },
        { value: "parking" },
        { value: "other" },
      ],
    },
    {
      type: "ValidatorDateField",
      path: "traffic.customerAppealRevocationApproved",
      validators: dateFieldDefault,
      isDisabled: () => true,
      isHidden: ({ product }) => !product.customerAppealRevocationApproved,
    },
    {
      type: "ValidatorDateField",
      path: "traffic.appealRevoked",
      validators: dateFieldDefault,
      isDisabled: () => true,
      isHidden: ({ product }) => !product.appealRevoked,
    },
    {
      type: "ValidatorSelect",
      path: "traffic.authorityDecision",
      isDisabled: () => true,
      isHidden: ({ product }) => !product.authorityDecision,
      options: () => [{ value: "__null__" }, { value: "revoked" }, { value: "changed" }, { value: "court" }],
    },
    {
      type: "ValidatorDateField",
      path: "traffic.courtDecisionDate",
      isDisabled: () => true,
      isHidden: ({ product }) => !product.courtDecisionDate,
    },
    {
      type: "ValidatorTextField",
      path: "traffic.fineNoticeReference",
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: "traffic.courtReference",
      isHidden: ({ product }) => !product.courtReference,
    },
  ],
};

const prosecution = {
  label: "Staatsanwaltschaft",
  elements: [
    {
      type: "ProsecutionPicker",
      path: "traffic.responsibleProsecution",
      additionalProps: {
        fullWidth: true,
      },
    },
    {
      type: "ValidatorTextField",
      path: "traffic.prosecutionReference",
      validators: textFieldDefault,
      additionalProps: {
        fullWidth: true,
      },
    },
  ],
};

const authority = {
  label: "Behörde",
  elements: [
    {
      type: "AuthorityPicker",
      path: "traffic.responsibleAuthority",
      additionalProps: {
        fullWidth: true,
      },
    },
  ],
};

export const fullFormDefinition = (product) => ({
  product: {
    sections: [
      {
        label: "Zum Verstoß",
        elements: [
          {
            type: "ValidatorSelect",
            path: "traffic.accusation",
            isDisabled: () => true,
            options: () => [
              { value: "__null__" },
              { value: "speeding" },
              { value: "red_light" },
              { value: "distance" },
              { value: "other" },
            ],
          },
          {
            type: "ValidatorSelect",
            path: "traffic.accusationOther",
            isDisabled: () => true,
            isHidden: ({ values }) => formValue(values, "traffic.accusation") !== "other",
            options: () => [
              { value: "__null__" },
              { value: "mobile_phone" },
              { value: "drugs" },
              { value: "parking" },
              { value: "other" },
            ],
          },
          /* ============== RED LIGHT ==================== */
          {
            type: "ValidatorSelect",
            path: "traffic.roadUsersEndangered",
            isHidden: ({ values }) => formValue(values, "traffic.accusation") !== "red_light",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_CHECKED,
            options: () => yesNoDefaultOptions("traffic.roadUsersEndangered"),
          },
          {
            type: "ValidatorSelect",
            path: "traffic.damage",
            isHidden: ({ values }) => formValue(values, "traffic.accusation") !== "red_light",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_CHECKED,
            options: () => yesNoUnknownDefaultOptions("traffic.damage"),
          },
          {
            type: "ValidatorSelect",
            path: "traffic.redLight",
            isHidden: ({ values }) => formValue(values, "traffic.accusation") !== "red_light",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_CHECKED,
            options: () => [
              { value: "__null__" },
              { value: "under_1_sec" },
              { value: "1_sec_or_more" },
              { value: "unknown" },
            ],
          },
          /* ============== DISTANCE =================== */
          {
            type: "ValidatorSelect",
            path: "traffic.speed",
            isHidden: ({ values }) => formValue(values, "traffic.accusation") !== "distance",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_CHECKED,
            options: () => [
              { value: "__null__" },
              { value: "<81" },
              { value: "81-100" },
              { value: "101-130" },
              { value: ">130" },
              { value: "unknown" },
            ],
          },
          {
            type: "ValidatorSelect",
            path: "traffic.distance",
            isHidden: ({ values }) => formValue(values, "traffic.accusation") !== "distance",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_CHECKED,
            options: () => [
              { value: "__null__" },
              { value: "5/10" },
              { value: "4/10" },
              { value: "3/10" },
              { value: "2/10" },
              { value: "1/10" },
              { value: "unknown" },
            ],
          },
          /* ============== SPEEDING =================== */
          {
            type: "ValidatorSelect",
            path: "traffic.locality",
            isHidden: ({ values }) => formValue(values, "traffic.accusation") !== "speeding",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_CHECKED,
            options: () => [
              { value: "__null__" },
              { value: "in_town" },
              { value: "out_of_town" },
              { value: "unknown" },
            ],
          },
          {
            type: "ValidatorSelect",
            path: "traffic.speeding",
            isHidden: ({ values }) => formValue(values, "traffic.accusation") !== "speeding",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_CHECKED,
            options: () => [
              { value: "__null__" },
              { value: "0-20" },
              { value: "21-30" },
              { value: "31-40" },
              { value: ">40" },
              { value: "41-50" },
              { value: "51-60" },
              { value: "61-70" },
              { value: ">70" },
              { value: "unknown" },
            ],
          },
          /* ============== MOBILE PHONE =================== */
          {
            type: "ValidatorSelect",
            path: "traffic.vehicle",
            isHidden: ({ values }) => formValue(values, "traffic.accusationOther") !== "mobile_phone",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_CHECKED,
            options: () => [
              { value: "__null__" },
              { value: "car" },
              { value: "bike" },
              { value: "motorcycle" },
              { value: "truck" },
              { value: "bus" },
            ],
          },
          /* ============== OPTIONALS END ===================== */
          {
            type: "ValidatorSelect",
            path: "traffic.driversLicensePeriod",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_CHECKED,
            options: () => [{ value: "__null__" }, { value: "<2" }, { value: "2-10" }, { value: ">10" }],
          },
          {
            type: "ValidatorSelect",
            path: "traffic.flensburgPoints",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_CHECKED,
            options: () => [
              { value: "__null__" },
              { value: "none" },
              { value: "1-3" },
              { value: "4-8" },
              { value: "unknown" },
            ],
          },
          {
            type: "ValidatorSelect",
            path: "traffic.flashed",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_CHECKED,
            options: () => yesNoUnknownDefaultOptions("traffic.flashed"),
          },
          {
            type: "ValidatorSelect",
            path: "traffic.driversLicenseIndispensable",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_CHECKED,
            options: () => [
              { value: "__null__" },
              { value: "yes_professional" },
              { value: "yes_other" },
              { value: "no" },
            ],
          },
          {
            type: "ValidatorSelect",
            path: "traffic.legalExpenseInsurance",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_CHECKED,
            options: () => trueFalseDefaultOptions("traffic.legalExpenseInsurance"),
          },
        ],
      },
      {
        label: "Zur Person",
        elements: [
          {
            type: "ValidatorSelect",
            path: "traffic.petitioner.gender",
            options: () => [{ value: "male" }, { value: "female" }],
          },
          {
            type: "ValidatorTextField",
            path: "traffic.petitioner.givenName",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "traffic.petitioner.familyName",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorDateField",
            path: "traffic.petitioner.birthDate",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_CHECKED,
            validators: [...dateFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "traffic.petitioner.residenceAddress.streetAddress",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_COMPLETED,
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "traffic.petitioner.residenceAddress.postalCode",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_COMPLETED,
            validators: [...textFieldDefault, postalCodeDefault],
          },
          {
            type: "ValidatorTextField",
            path: "traffic.petitioner.residenceAddress.addressLocality",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_COMPLETED,
            validators: [...textFieldDefault],
          },
        ],
      },
      {
        label: "Ergänzende Angaben",
        elements: [
          {
            type: "ValidatorSelect",
            path: "traffic.authorityNotification",
            isDisabled: ({ product }) => product.stage > stagesTraffic.STAGE_ACCESS_TO_RECORDS,
            options: () => [
              { value: "__null__" },
              { value: "hearing_report" },
              { value: "witness_report" },
              { value: "fine_notice" },
              { value: "no_notice" },
            ],
          },
          {
            type: "ValidatorDateField",
            path: "traffic.fineNoticeReceivedDate",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_ACCESS_TO_RECORDS,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorDateField",
            path: "traffic.fineNoticeDate",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_ACCESS_TO_RECORDS,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorDateField",
            path: "traffic.fineDeadline",
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "traffic.fineNoticeReference",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_ACCESS_TO_RECORDS,
            validators: textFieldDefault,
          },
          {
            type: "ValidatorTextField",
            path: "traffic.finalFineAmount",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_ACCESS_TO_RECORDS,
            validators: moneyFieldDefault,
            additionalProps: {
              InputProps: {
                endAdornment: <InputAdornment position="start">€</InputAdornment>,
              },
            },
          },
        ],
      },
    ],
  },
  additional: {
    sections: [
      {
        label: "",
        elements: [
          {
            type: "ValidatorTextField",
            path: "traffic.reference",
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "traffic.powerOfAttorney",
            isDisabled: () => true,
          },
          {
            type: "ValidatorSelect",
            path: "traffic.paymentType",
            isDisabled: ({ product }) => product.stage >= stagesTraffic.STAGE_CHECKED,
            options: () => [{ value: "__null__" }, { value: "insurance" }, { value: "private" }],
          },
          {
            type: "ValidatorDateField",
            path: "traffic.customerAppealRevocationApproved",
            isDisabled: ({ product }) =>
              product.stage < stagesTraffic.STAGE_ACCESS_TO_RECORDS ||
              product.stage > stagesTraffic.STAGE_DECISION_MADE,
            validators: dateFieldDefault,
            additionalProps: {
              helperText: "Dieses Feld stößt automatisch den Prozess zur Rücknahme des Einspruchs an.",
            },
            alert: {
              onInitial:
                "Das Speichern führt dazu, dass hintergründig ein Fax mit der Einspruchsrücknahme an die zuständige Stelle verschickt wird. Möchten Sie, dass Feld speichern und somit das Fax mit der Rücknahme versenden?",
            },
          },
          {
            type: "ValidatorDateField",
            path: "traffic.appealRevoked",
            isDisabled: ({ product }) =>
              product.stage < stagesTraffic.STAGE_ACCESS_TO_RECORDS ||
              product.stage > stagesTraffic.STAGE_DECISION_MADE,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorDateField",
            path: "traffic.processStoppedDate",
            isDisabled: ({ product }) =>
              product.stage < stagesTraffic.STAGE_INSURANCE_COVERAGE_REQUEST ||
              product.stage > stagesTraffic.STAGE_EXTRAJUDICIAL_PAID,
            validators: dateFieldDefault,
          },
        ],
      },
      {
        label: "Angaben zur Versicherung",
        isHidden: ({ values }) => formValue(values, "traffic.paymentType") !== "insurance",
        elements: [
          {
            type: "InsurancePicker",
            path: "traffic.insurance.insurance",
            validators: insurancePickerValidator(product),
          },
          {
            type: "ValidatorTextField",
            path: "traffic.insurance.insurancePolicyNumber",
            validators: textFieldDefault,
          },
          {
            type: "ValidatorTextField",
            path: "traffic.insurance.caseReferenceNumber",
            validators: textFieldDefault,
          },
          {
            type: "ValidatorTextField",
            path: "traffic.insurance.deductible",
            validators: moneyFieldDefault,
            additionalProps: {
              InputProps: {
                endAdornment: <InputAdornment position="start">€</InputAdornment>,
              },
            },
          },
          {
            type: "ValidatorDateField",
            path: "traffic.insuranceCoverageDate",
            isHidden: ({ values }) => formValue(values, "traffic.paymentType") !== "insurance",
            isDisabled: ({ product }) => product.stage !== stagesTraffic.STAGE_ACCESS_TO_RECORDS,
            validators: dateFieldDefault,
          },
        ],
      },
      {
        label: "Außergerichtliches Verfahren",
        elements: [
          {
            type: "ValidatorDateField",
            path: "traffic.accessToRecordRequested",
            isDisabled: ({ product }) =>
              ![stagesTraffic.STAGE_ACCESS_TO_RECORDS, stagesTraffic.STAGE_CHECKED].includes(product.stage),
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorSelect",
            path: "traffic.opinionRequired",
            isDisabled: ({ product }) =>
              !_.includes([STAGE_ACCESS_TO_RECORDS, STAGE_EXTRAJUDICIAL_PAID], product.stage),
            options: ({ product }) => trueFalseDefaultOptions(`${product.productClassName}.opinionRequired`),
          },
          {
            type: "ValidatorDateField",
            path: "traffic.opinionSubmitted",
            isDisabled: ({ product }) =>
              product.stage !== stagesTraffic.STAGE_EXTRAJUDICIAL_PAID &&
              product.stage !== stagesTraffic.STAGE_ACCESS_TO_RECORDS,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorDateField",
            path: "traffic.authorityAnswered",
            isDisabled: ({ product }) => product.stage !== stagesTraffic.STAGE_OPINION_SUBMITTED,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorSelect",
            path: "traffic.authorityDecision",
            isDisabled: ({ product }) => product.stage !== stagesTraffic.STAGE_OPINION_SUBMITTED,
            options: () => [{ value: "__null__" }, { value: "revoked" }, { value: "changed" }, { value: "court" }],
          },
        ],
      },
      {
        label: "Gerichtliches Verfahren",
        isHidden: ({ product }) => product.authorityDecision === "revoked",
        elements: [
          {
            type: "ValidatorSelect",
            path: "traffic.trialWanted",
            isDisabled: ({ product }) => product.stage < stagesTraffic.STAGE_AUTHORITY_ANSWERED,
            options: () => yesNoDefaultOptions("traffic.trialWanted"),
          },
          {
            type: "ValidatorSelect",
            path: "traffic.courtNotice",
            isDisabled: ({ product }) => product.stage < stagesTraffic.STAGE_AUTHORITY_ANSWERED,
            options: () => [{ value: "__null__" }, { value: "revoked" }, { value: "no_trial" }, { value: "trial" }],
          },
          {
            type: "ValidatorTextField",
            path: "traffic.courtReference",
            validators: textFieldDefault,
          },
          {
            type: "ValidatorTextField",
            path: "traffic.responsibleCourtAddress.postalCode",
            isDisabled: ({ product }) => product.stage !== stagesTraffic.STAGE_AUTHORITY_ANSWERED,
            isHidden: ({ product }) => product.stage >= stagesTraffic.STAGE_TRIAL,
            validators: textFieldDefault,
            alert: {
              onChange:
                "Das Ändern der Postleitzahl führt dazu, dass das zuständige Gericht erneut ermittelt wird. Möchten Sie die Postleitzahl tatsächlich ändern?",
            },
          },
          {
            type: "ValidatorDateField",
            path: "traffic.courtDecisionDate",
            isDisabled: ({ product }) => product.stage < stagesTraffic.STAGE_AUTHORITY_ANSWERED,
            validators: dateFieldDefault,
          },
        ],
      },
      {
        label: product.courtAppointments.length === 1 ? "Gerichtstermin" : "Gerichtstermine",
        isHidden: ({ product }) => product.courtAppointments.length === 0,
        elements: getCourtAppointmentFields(product),
      },
    ],
  },
  processParticipants: {
    sections: [authority, prosecution],
  },
  additionalProcessParticipants: {
    sections: [
      {
        label: "Personen",
        elements: [
          {
            type: "ProcessParticipantsPeopleForm",
            path: `${product.productClassName}.relatedPeople`,
            additionalProps: {
              fullWidth: true,
            },
          },
        ],
      },
      {
        label: "Unternehmen",
        elements: [
          {
            type: "ProcessParticipantsOrganizationsForm",
            path: `${product.productClassName}.relatedOrganizations`,
            additionalProps: {
              fullWidth: true,
            },
          },
        ],
      },
    ],
    withoutSubmit: true,
  },
  court: {
    sections: [
      {
        elements: [
          {
            type: "CourtPicker",
            path: "traffic.responsibleCourt",
            isDisabled: ({ product }) =>
              product.stage < stagesTraffic.STAGE_TRIAL || hasActiveCourtAppointment(product.courtAppointments),
            additionalProps: {
              fullWidth: true,
              courtTypes: ["Amtsgericht", "Arbeitsgericht", "Landgericht", "Mahngericht"],
            },
          },
          ...allDataPageCourtFields("traffic", "responsibleCourt"),
        ],
      },
    ],
  },
});
