import * as stagesDivorce from "../../Stages/StagesDivorce";
import { trueFalseDefaultOptions } from "./formDefinitionFunctions";
import { formValue } from "../../formServiceFunctions";
import {
  beaSafeIdFieldDefault,
  dateFieldDefault, emailDefault, faxNumberDefault,
  insurancePickerValidator,
  moneyFieldDefault,
  numberFieldDefault,
  postalCodeDefault,
  textFieldDefault,
} from "../../validationRules";
import InputAdornment from "@mui/material/InputAdornment";
import React from "react";
import _ from "lodash";
import nationalities from "../../nationalities";
import { getCourtAppointmentFields } from "./contract/fullFormDefinition";
import { allDataPageCourtFields } from "./allDataPageCourtFields";
import { hasActiveCourtAppointment } from "../ProductService";
import personFields from "./modules/personFields";
import addressFields from "./modules/addressFields";

const childrenFields = () => {
  let fields = [];

  for (let i = 0; i < 6; i++) {
    fields.push(
      {
        type: "ValidatorTextField",
        path: "divorce.children[" + i + "].name",
        validators: textFieldDefault,
        isHidden: ({ product }) => product.numberChildren < i + 1,
        isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
      },
      {
        type: "ValidatorSelect",
        path: "divorce.children[" + i + "].livesWith",
        options: () => [{ value: "petitioner" }, { value: "partner" }],
        isHidden: ({ product }) => product.numberChildren < i + 1,
        isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
      }
    );
  }
  return fields;
};

const installmentData = [
  {
    type: "ValidatorSelect",
    path: "divorce.installmentRateOverdue",
    options: () => trueFalseDefaultOptions("divorce.installmentRateOverdue"),
    isHidden: ({ product }) => !product.installment,
  },
  {
    type: "ValidatorDateField",
    path: "divorce.installmentRateOverdueSince",
    validators: dateFieldDefault,
    isHidden: ({ product }) => !product.installment,
  },
];

const partner = {
  label: "Gegenseite - Ehepartner",
  elements: [
    {
      type: "ValidatorSelect",
      path: "divorce.partner.gender",
      isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
      options: () => [{ value: "male" }, { value: "female" }],
    },
    {
      type: "ValidatorTextField",
      path: "divorce.partner.givenName",
      isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
      validators: [...textFieldDefault],
    },
    {
      type: "ValidatorTextField",
      path: "divorce.partner.familyName",
      isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
      validators: [...textFieldDefault],
    },
    {
      type: "ValidatorSelect",
      path: "divorce.partner.nationality",
      isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
      options: () =>
        _.map(nationalities, (nationality) => {
          return {
            value: nationality.label,
            label: nationality.label,
          };
        }),
    },
    {
      type: "ValidatorTextField",
      path: "divorce.partner.residenceAddress.streetAddress",
      validators: [...textFieldDefault],
    },
    {
      type: "ValidatorTextField",
      path: "divorce.partner.residenceAddress.postalCode",
      validators: [...textFieldDefault, postalCodeDefault],
    },
    {
      type: "ValidatorTextField",
      path: "divorce.partner.residenceAddress.addressLocality",
      validators: [...textFieldDefault],
    },
  ],
};

const checkedAndProcessPaidDisabledFields = [
  {
    type: "ValidatorSelect",
    path: "divorce.numberChildren",
    options: () => [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }],
    isDisabled: () => true,
  },
  {
    type: "ValidatorDateField",
    path: "divorce.dateSeparation",
    isDisabled: () => true,
  },
  {
    type: "ValidatorSelect",
    path: "divorce.movedOut",
    options: () => [
      { value: "__null__" },
      { value: "petitioner" },
      { value: "partner" },
      { value: "nobody" },
      { value: "both" },
    ],
    isDisabled: () => true,
  },
  {
    type: "ValidatorDateField",
    path: "divorce.dateMovedOut",
    isDisabled: () => true,
    isHidden: ({ values }) => formValue(values, "divorce.movedOut") === "nobody",
  },
  {
    type: "ValidatorSelect",
    path: "divorce.pensionsExcluded",
    options: () => trueFalseDefaultOptions("divorce.pensionsExcluded"),
    isDisabled: () => true,
  },
  {
    type: "ValidatorSelect",
    path: "divorce.shortMarriage",
    isDisabled: () => true,
    isHidden: ({ values, product }) =>
      formValue(values, "divorce.pensionsExcluded") === false || product.stage !== stagesDivorce.STAGE_PROCESS_PAID,
    options: () => trueFalseDefaultOptions("divorce.shortMarriage"),
  },
];

const opponentLawyer = {
  label: "Anwalt Gegenseite",
  elements: [
    {
      type: "BeaLawyerImportButton",
      path: "pathPropNotUsedButRequired",
      additionalProps: {
        fullWidth: true,
      },
    },
    ...personFields({ path: `divorce.opponentLawyer` }),
    {
      type: "ValidatorTextField",
      path: `divorce.opponentLawFirm.name`,
    },
    {
      type: "ValidatorTextField",
      path: `divorce.opponentLawFirm.email`,
      validators: emailDefault,
    },
    {
      type: "ValidatorTextField",
      path: `divorce.opponentLawFirm.telephone`,
    },
    {
      type: "ValidatorTextField",
      path: `divorce.opponentLawFirm.fax`,
      validators: faxNumberDefault,
      additionalProps: {
        InputProps: {
          startAdornment: <InputAdornment position="start">+49</InputAdornment>,
        },
      },
    },
    {
      type: "ValidatorTextField",
      path: `divorce.opponentLawFirm.beaSafeId`,
      validators: beaSafeIdFieldDefault,
    },
    ...addressFields({ path: `divorce.opponentLawFirm.address` }),
    {
      type: "ValidatorTextField",
      path: `divorce.referenceOpponentLawyer`,
    },
  ],
};

const submittedFields = [
  {
    type: "ValidatorDateField",
    path: "divorce.applicationSubmittedDate",
    isDisabled: () => true,
  },
  {
    type: "ValidatorTextField",
    path: "divorce.referenceCourt",
    validators: textFieldDefault,
  },
  ...installmentData,
  {
    type: "ValidatorSelect",
    path: "divorce.pensionsExcluded",
    options: () => trueFalseDefaultOptions("divorce.pensionsExcluded"),
    isDisabled: () => true,
  },
  {
    type: "ValidatorSelect",
    path: "divorce.shortMarriage",
    isDisabled: () => true,
    isHidden: ({ values }) => formValue(values, "divorce.pensionsExcluded") === false,
    options: () => trueFalseDefaultOptions("divorce.shortMarriage"),
  },
  {
    type: "ValidatorDateField",
    path: "divorce.courtFeeInvoiceReceivedDate",
    validators: dateFieldDefault,
    isHidden: ({ product }) => product.processCostSupport,
  },
  {
    type: "ValidatorDateField",
    path: "divorce.courtFeePaidDate",
    validators: dateFieldDefault,
    isHidden: ({ product }) => product.processCostSupport,
  },
];

const incompleteAndReadyData = [
  {
    type: "ValidatorTextField",
    path: "divorce.marriageRegistrationNumber",
    validators: textFieldDefault,
    isHidden: ({ product }) => product.stage !== stagesDivorce.STAGE_READY,
  },
  {
    type: "ValidatorTextField",
    path: "divorce.powerOfAttorney",
    isDisabled: () => true,
  },
  {
    type: "ValidatorSelect",
    path: "divorce.processCostSupport",
    options: () => trueFalseDefaultOptions("divorce.processCostSupport"),
    isDisabled: ({ values }) => formValue(values, "divorce.installment") === true,
  },
  {
    type: "ValidatorDateField",
    path: "divorce.processCostSupportVerified",
    validators: dateFieldDefault,
    isHidden: ({ values, product }) =>
      formValue(values, "divorce.processCostSupport") === false || product.stage !== stagesDivorce.STAGE_READY,
  },
  {
    type: "ValidatorSelect",
    path: "divorce.installment",
    isDisabled: ({ values }) => formValue(values, "divorce.processCostSupport") === true,
    options: () => trueFalseDefaultOptions("divorce.processCostSupport"),
  },
  {
    type: "ValidatorDateField",
    path: "divorce.dateMarriage",
    validators: [...dateFieldDefault],
  },
  {
    type: "ValidatorSelect",
    path: "divorce.numberChildren",
    isDisabled: ({ product }) => product.stage !== stagesDivorce.STAGE_INCOMPLETE,
    options: () => [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }],
  },
  {
    type: "ValidatorSelect",
    path: "divorce.statusDivorce",
    options: () => [{ value: "__null__" }, { value: "mutuallyAgreed" }, { value: "unsure" }, { value: "debatable" }],
  },
  {
    type: "ValidatorDateField",
    path: "divorce.dateSeparation",
    validators: [...dateFieldDefault],
  },
  {
    type: "ValidatorSelect",
    path: "divorce.movedOut",
    options: () => [
      { value: "__null__" },
      { value: "petitioner" },
      { value: "partner" },
      { value: "nobody" },
      { value: "both" },
    ],
  },
  {
    type: "ValidatorDateField",
    path: "divorce.dateMovedOut",
    validators: dateFieldDefault,
    isHidden: ({ values }) => formValue(values, "divorce.movedOut") === "nobody",
  },
  {
    type: "ValidatorTextField",
    path: "divorce.netIncomePetitioner",
    validators: [...numberFieldDefault],
    additionalProps: {
      InputProps: {
        endAdornment: <InputAdornment position="start">€</InputAdornment>,
      },
    },
  },
  {
    type: "ValidatorTextField",
    path: "divorce.netIncomePartner",
    validators: [...numberFieldDefault],
    additionalProps: {
      InputProps: {
        endAdornment: <InputAdornment position="start">€</InputAdornment>,
      },
    },
  },
  {
    type: "ValidatorSelect",
    path: "divorce.pensionsQuestions",
    options: () => trueFalseDefaultOptions("divorce.pensionsQuestions"),
  },
  {
    type: "ValidatorSelect",
    path: "divorce.pensionsExcluded",
    options: () => trueFalseDefaultOptions("divorce.pensionsExcluded"),
  },
  {
    type: "ValidatorSelect",
    path: "divorce.shortMarriage",
    isDisabled: () => true,
    isHidden: ({ values }) => formValue(values, "divorce.pensionsExcluded") === false,
    options: () => trueFalseDefaultOptions("divorce.shortMarriage"),
  },
];

export const stageFormDefinition = {
  [stagesDivorce.STAGE_INCOMPLETE]: incompleteAndReadyData,
  [stagesDivorce.STAGE_READY]: incompleteAndReadyData,
  [stagesDivorce.STAGE_CHECKED]: [...installmentData, ...checkedAndProcessPaidDisabledFields],
  [stagesDivorce.STAGE_PROCESS_PAID]: [...installmentData, ...checkedAndProcessPaidDisabledFields],
  [stagesDivorce.STAGE_SUBMITTED]: submittedFields,
  [stagesDivorce.STAGE_JUDICIAL_PROCESS]: submittedFields,
  [stagesDivorce.STAGE_DATE_SET]: [
    {
      type: "ValidatorTextField",
      path: "divorce.referenceCourt",
    },
    ...installmentData,
    {
      type: "ValidatorSelect",
      path: "divorce.pensionsExcluded",
      options: () => trueFalseDefaultOptions("divorce.pensionsExcluded"),
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: "divorce.valueInDisputeReal",
      validators: moneyFieldDefault,
      additionalProps: {
        InputProps: {
          endAdornment: <InputAdornment position="start">€</InputAdornment>,
        },
      },
    },
  ],
  [stagesDivorce.STAGE_HEARING_PAID]: [
    {
      type: "ValidatorTextField",
      path: "divorce.referenceCourt",
    },
    ...installmentData,
    {
      type: "ValidatorSelect",
      path: "divorce.pensionsExcluded",
      options: () => trueFalseDefaultOptions("divorce.pensionsExcluded"),
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: "divorce.valueInDisputeReal",
      validators: moneyFieldDefault,
      additionalProps: {
        InputProps: {
          endAdornment: <InputAdornment position="start">€</InputAdornment>,
        },
      },
    },
  ],
  [stagesDivorce.STAGE_DIVORCE_DONE]: [
    {
      type: "ValidatorTextField",
      path: "divorce.referenceCourt",
    },
    ...installmentData,
    {
      type: "ValidatorSelect",
      path: "divorce.pensionsExcluded",
      options: () => trueFalseDefaultOptions("divorce.pensionsExcluded"),
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: "divorce.valueInDisputeReal",
      validators: moneyFieldDefault,
      additionalProps: {
        InputProps: {
          endAdornment: <InputAdornment position="start">€</InputAdornment>,
        },
      },
    },
  ],
  [stagesDivorce.STAGE_COMPLETED]: [
    ...installmentData,
    {
      type: "ValidatorTextField",
      path: "divorce.valueInDisputeReal",
      isDisabled: () => true,
      additionalProps: {
        InputProps: {
          endAdornment: <InputAdornment position="start">€</InputAdornment>,
        },
      },
    },
  ],
};

export const fullFormDefinition = (product) => ({
  product: {
    sections: [
      {
        label: "Angaben Antragsteller",
        elements: [
          {
            type: "ValidatorSelect",
            path: "divorce.petitioner.gender",
            options: () => [{ value: "male" }, { value: "female" }],
          },
          {
            type: "ValidatorTextField",
            path: "divorce.petitioner.givenName",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "divorce.petitioner.familyName",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorSelect",
            path: "divorce.petitioner.nationality",
            isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
            options: () =>
              _.map(nationalities, (nationality) => {
                return {
                  value: nationality.label,
                  label: nationality.label,
                };
              }),
          },
          {
            type: "ValidatorTextField",
            path: "divorce.petitioner.residenceAddress.streetAddress",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "divorce.petitioner.residenceAddress.postalCode",
            validators: [...textFieldDefault, postalCodeDefault],
          },
          {
            type: "ValidatorTextField",
            path: "divorce.petitioner.residenceAddress.addressLocality",
            validators: [...textFieldDefault],
          },
        ],
      },
      {
        label: "Letzte gemeinsame Adresse",
        elements: [
          {
            type: "ValidatorTextField",
            path: "divorce.commonAddress.streetAddress",
            isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "divorce.commonAddress.postalCode",
            isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
            validators: [...textFieldDefault, postalCodeDefault],
          },
          {
            type: "ValidatorTextField",
            path: "divorce.commonAddress.addressLocality",
            isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
            validators: [...textFieldDefault],
          },
        ],
      },
      {
        label: "Ehe und Scheidung",
        elements: [
          {
            type: "ValidatorDateField",
            path: "divorce.dateMarriage",
            validators: [...dateFieldDefault],
            isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
          },
          {
            type: "ValidatorTextField",
            path: "divorce.cityMarriage",
            isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorSelect",
            path: "divorce.numberChildren",
            options: () => [
              { value: 0 },
              { value: 1 },
              { value: 2 },
              { value: 3 },
              { value: 4 },
              { value: 5 },
              { value: 6 },
            ],
            isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_INCOMPLETE,
          },
          {
            type: "ValidatorSelect",
            path: "divorce.statusDivorce",
            options: () => [
              { value: "__null__" },
              { value: "mutuallyAgreed" },
              { value: "unsure" },
              { value: "debatable" },
            ],
            isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
          },
          {
            type: "ValidatorDateField",
            path: "divorce.dateSeparation",
            isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
            validators: [...dateFieldDefault],
          },
          {
            type: "ValidatorSelect",
            path: "divorce.movedOut",
            isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
            options: () => [{ value: "petitioner" }, { value: "partner" }, { value: "nobody" }, { value: "both" }],
          },
          {
            type: "ValidatorDateField",
            path: "divorce.dateMovedOut",
            validators: dateFieldDefault,
            isDisabled: ({ values, product }) =>
              formValue(values, "divorce.movedOut") === "nobody" || product.stage > stagesDivorce.STAGE_READY,
          },
        ],
      },
      {
        label: "Finanzen",
        elements: [
          {
            type: "ValidatorTextField",
            path: "divorce.netIncomePetitioner",
            validators: [...numberFieldDefault],
            isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
            additionalProps: {
              InputProps: {
                endAdornment: <InputAdornment position="start">€</InputAdornment>,
              },
            },
          },
          {
            type: "ValidatorTextField",
            path: "divorce.netIncomePartner",
            validators: [...numberFieldDefault],
            isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
            additionalProps: {
              InputProps: {
                endAdornment: <InputAdornment position="start">€</InputAdornment>,
              },
            },
          },
          {
            type: "ValidatorSelect",
            path: "divorce.pensionsQuestions",
            options: () => trueFalseDefaultOptions("divorce.pensionsQuestions"),
            isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
          },
          {
            type: "ValidatorSelect",
            path: "divorce.pensionsExcluded",
            options: () => trueFalseDefaultOptions("divorce.pensionsExcluded"),
            isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
          },
          {
            type: "ValidatorSelect",
            path: "divorce.shortMarriage",
            isDisabled: () => true,
            options: () => trueFalseDefaultOptions("divorce.shortMarriage"),
          },
          {
            type: "ValidatorTextField",
            path: "divorce.numberPensions",
            validators: [...numberFieldDefault],
            isDisabled: ({ values, product }) =>
              formValue(values, "divorce.pensionsExcluded") === true || product.stage > stagesDivorce.STAGE_READY,
          },
          {
            type: "ValidatorTextField",
            path: "divorce.numberPensionsPartner",
            validators: [...numberFieldDefault],
            isDisabled: ({ values, product }) =>
              formValue(values, "divorce.pensionsExcluded") === true || product.stage > stagesDivorce.STAGE_READY,
          },
          {
            type: "ValidatorSelect",
            path: "divorce.processCostSupport",
            options: () => trueFalseDefaultOptions("divorce.processCostSupport"),
            isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
          },
        ],
      },
      {
        label: "",
        elements: [
          {
            type: "ValidatorTextField",
            path: "divorce.personalTextMessage",
            isDisabled: () => true,
            additionalProps: {
              multiline: true,
              rows: 12,
              fullWidth: true,
            },
          },
        ],
      },
      {
        label: "Gemeinsame Kinder",
        isHidden: ({ values }) => formValue(values, "divorce.numberChildren") === "0",
        elements: childrenFields(),
      },
    ],
  },
  additional: {
    sections: [
      {
        label: "",
        elements: [
          {
            type: "ValidatorTextField",
            path: "divorce.reference",
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "divorce.powerOfAttorney",
            isDisabled: () => true,
          },
          {
            type: "ValidatorDateField",
            path: "divorce.applicationSubmittedDate",
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "divorce.referenceCourt",
            validators: textFieldDefault,
          },
          {
            type: "ValidatorTextField",
            path: "divorce.marriageRegistrationNumber",
            validators: textFieldDefault,
            isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
          },
          {
            type: "ValidatorDateField",
            path: "divorce.processCostSupportVerified",
            validators: dateFieldDefault,
            isHidden: ({ values }) => formValue(values, "divorce.processCostSupport") === false,
            isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
          },
        ],
      },
      {
        label: "Ratenzahlung",
        isHidden: ({ product }) => product.processCostSupport,
        elements: [
          {
            type: "ValidatorSelect",
            path: "divorce.installment",
            isDisabled: ({ product }) => product.stage > stagesDivorce.STAGE_READY,
            options: () => trueFalseDefaultOptions("divorce.processCostSupport"),
          },
          {
            type: "ValidatorSelect",
            path: "divorce.installmentRateOverdue",
            options: () => trueFalseDefaultOptions("divorce.installmentRateOverdue"),
            isHidden: ({ values }) => formValue(values, "divorce.installment") !== true,
          },
          {
            type: "ValidatorDateField",
            path: "divorce.installmentRateOverdueSince",
            validators: dateFieldDefault,
            isHidden: ({ values }) => formValue(values, "divorce.installment") !== true,
          },
        ],
      },
      {
        label: "Gerichtsgebühr",
        elements: [
          {
            type: "ValidatorDateField",
            path: "divorce.courtFeeInvoiceReceivedDate",
            validators: dateFieldDefault,
            isDisabled: ({ product }) => product.stage !== stagesDivorce.STAGE_SUBMITTED,
          },
          {
            type: "ValidatorDateField",
            path: "divorce.courtFeePaidDate",
            validators: dateFieldDefault,
            isDisabled: ({ product }) => product.stage !== stagesDivorce.STAGE_SUBMITTED,
          },
        ],
      },
      {
        label: "Kooperationspartner",
        isHidden: ({ product }) => !product.acquisitionPartner,
        elements: [
          {
            type: "InsurancePicker",
            path: "divorce.insurance.insurance",
            validators: insurancePickerValidator(product),
          },
          {
            type: "ValidatorTextField",
            path: "divorce.insurance.caseReferenceNumber",
            validators: textFieldDefault,
          },
          {
            type: "ValidatorTextField",
            path: "divorce.insurance.insurancePolicyNumber",
            validators: textFieldDefault,
          },
        ],
      },
      {
        label: "Zum Scheidungstermin",
        elements: [
          {
            type: "ValidatorTextField",
            path: "divorce.valueInDisputeReal",
            validators: moneyFieldDefault,
            isDisabled: ({ product }) =>
              ![
                stagesDivorce.STAGE_HEARING_PAID,
                stagesDivorce.STAGE_DIVORCE_DONE,
                stagesDivorce.STAGE_DATE_SET,
              ].includes(product.stage),
            additionalProps: {
              InputProps: {
                endAdornment: <InputAdornment position="start">€</InputAdornment>,
              },
            },
          },
        ],
      },
      {
        label: product.courtAppointments.length === 1 ? "Gerichtstermin" : "Gerichtstermine",
        isHidden: ({ product }) => product.courtAppointments.length === 0,
        elements: getCourtAppointmentFields(product),
      },
    ],
  },
  processParticipants: {
    sections: [partner, opponentLawyer],
  },
  additionalProcessParticipants: {
    sections: [
      {
        label: "Personen",
        elements: [
          {
            type: "ProcessParticipantsPeopleForm",
            path: `${product.productClassName}.relatedPeople`,
            additionalProps: {
              fullWidth: true,
            },
          },
        ],
      },
      {
        label: "Unternehmen",
        elements: [
          {
            type: "ProcessParticipantsOrganizationsForm",
            path: `${product.productClassName}.relatedOrganizations`,
            additionalProps: {
              fullWidth: true,
            },
          },
        ],
      },
    ],
    withoutSubmit: true,
  },
  court: {
    sections: [
      {
        elements: [
          {
            type: "CourtPicker",
            path: "divorce.responsibleCourt",
            isDisabled: ({ product }) =>
              product.stage < stagesDivorce.STAGE_CHECKED || hasActiveCourtAppointment(product.courtAppointments),
            additionalProps: {
              fullWidth: true,
              courtTypes: ["Familiengericht", "Mahngericht"],
            },
          },
          ...allDataPageCourtFields("divorce", "responsibleCourt"),
        ],
      },
    ],
  },
});
