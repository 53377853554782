import React, { useEffect, useState } from "react";
import { EditorState, Modifier } from "draft-js";
import { MenuItem, Select } from "@mui/material";
import _ from "lodash";
import * as PropTypes from "prop-types";
import ApiClient from "../../services/ApiClient";
import { fillTemplate } from "../../services/Template/templateService";
import { htmlToBlockMap } from "../../services/Editor/editorFunctions";

const AddTextBlock = ({ editorState, onChange, product, formValues }) => {
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    ApiClient.get("templates").then((result) => {
      const textTemplates = _.filter(result["hydra:member"], (template) => {
        return (
          template.type === "text" &&
          _.includes(
            ["general", product.productCategory === "contractLaw" ? "contractLaw" : product.productClassName],
            template.productClassName
          )
        );
      });
      setTemplates(textTemplates);
    });
  }, []);

  const addTextBlock = (templateContent) => {
    if (!templateContent) {
      return;
    }
    const contentToInsert = htmlToBlockMap(fillTemplate(templateContent, product, formValues));
    const contentState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      contentToInsert,
      editorState.getCurrentInlineStyle()
    );

    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  const handlePlaceholderClick = (template) => {
    addTextBlock(`${template.content}`);
  };

  return (
    <Select value={"static"} variant={"standard"} disableUnderline={true}>
      <MenuItem value={"static"} disabled>
        Text einfügen
      </MenuItem>
      {_.map(templates, (template) => (
        <MenuItem key={template.id} value={template.id} onClick={() => handlePlaceholderClick(template)}>
          {template.label}
        </MenuItem>
      ))}
    </Select>
  );
};

AddTextBlock.propTypes = {
  product: PropTypes.object.isRequired,
  formValues: PropTypes.object,
};

export default AddTextBlock;
