import React from "react";
import { Avatar, Box, Stack } from "@mui/material";
import { Activity } from "../../types/Activity";
import { KeyboardArrowRight, LocalPhoneOutlined } from "@mui/icons-material";
import { getCaseLink, getProductData, getProductNameBy } from "../../services/Product/ProductService";
import { Link } from "react-router-dom";

export default function AdvisoryCallWidget({ advisoryCall }: { advisoryCall: Activity }) {
  const productClassName = getProductNameBy("lbProductClass", advisoryCall.case?.lbProductClass);
  return (
    <Box
      component={Link}
      sx={{ color: (theme: any) => theme.palette.text.primary }}
      to={getCaseLink({ productClassName, id: advisoryCall.case?.lbProductId }, "/extern")}
    >
      <Stack sx={{ borderTop: "1px solid #ddd", padding: "15px 0" }} direction={"row"} alignContent={"center"}>
        <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main, marginRight: "1.5rem" }}>
          <LocalPhoneOutlined />
        </Avatar>
        <Box>
          <strong>{advisoryCall.case?.label}</strong>
          <br />
          Heute um {advisoryCall.dueTime} Uhr - {getProductData(productClassName, "generalLabel")}
        </Box>
        <Box sx={{ marginLeft: "auto", alignSelf: "flex-end" }}>
          <KeyboardArrowRight color={"primary"} />
        </Box>
      </Stack>
    </Box>
  );
}
