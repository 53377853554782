import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  notificationContainer: {
    padding: "1rem",
    border: "1px solid #f28d4f",
    borderRadius: 5,
    maxWidth: 400,
    fontSize: "1rem",
  },
  wideNotificationContainer: {
    padding: "1rem",
    border: "1px solid #f28d4f",
    borderRadius: 5,
    maxWidth: 600,
    fontSize: "1rem",
  },
  headline: {
    fontSize: "1.25rem",
    textAlign: "center",
  },
  icon: {
    color: "#000",
  },
  noteContainerOverride: {
    "& .noteContainer": {
      marginLeft: "0px !important",
    },
  },
}));

export default useStyles;
