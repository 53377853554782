import React from "react";
import { getIconByMimeType } from "../../services/Files/fileService";
import CircleIcon from "../CircleIcon/CircleIcon";

type MimeTypeIconProps = {
  mimeType: string | null;
  circled?: boolean;
};

const MimeTypeIcon = ({ mimeType: mimeType, circled = false }: MimeTypeIconProps) => {
  const icon = getIconByMimeType(mimeType);
  return circled ? <CircleIcon icon={icon} type={"muiIcon"} /> : icon;
};

export default MimeTypeIcon;
