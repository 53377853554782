import React, { useState } from "react";
import * as PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../services/ReactQuery/reactQueryService";

export default function ChangeMediaObjectVisibilityModal({
  mediaObject,
  updateMediaObjects,
  changeVisibilityOpen,
  setChangeVisibilityOpen,
  closeMenu,
}) {
  const updateMutation = useMutation(updateResource);
  const [showInGallery, setShowInGallery] = useState(mediaObject.showInGallery);
  const [showInExternalView, setShowInExternalView] = useState(mediaObject.showInExternalView);

  const handleSave = () => {
    updateMutation
      .mutateAsync({
        uri: "media_objects",
        id: mediaObject.id,
        data: {
          showInGallery: showInGallery,
          showInExternalView: showInExternalView,
        },
      })
      .then(() => updateMediaObjects());
    setChangeVisibilityOpen(false);
    closeMenu();
  };

  return (
    <LegalbirdIoModal
      handleClose={() => {
        setChangeVisibilityOpen(false);
        closeMenu();
      }}
      open={changeVisibilityOpen}
      title={"Sichtbarkeit einstellen"}
      submitButton={
        <Button
          variant={"contained"}
          onClick={handleSave}
          disabled={
            showInExternalView === mediaObject.showInExternalView && showInGallery === mediaObject.showInGallery
          }
        >
          Speichern
        </Button>
      }
    >
      <Stack>
        Für wen soll das Dokument sichtbar sein?
        <FormControlLabel
          control={
            <Checkbox checked={showInExternalView} onChange={(event) => setShowInExternalView(event.target.checked)} />
          }
          label={"Für Extern sichtbar"}
        />
        <FormControlLabel
          control={<Checkbox checked={showInGallery} onChange={(event) => setShowInGallery(event.target.checked)} />}
          label={"Für Kunde sichtbar"}
        />
      </Stack>
    </LegalbirdIoModal>
  );
}

ChangeMediaObjectVisibilityModal.propTypes = {
  mediaObject: PropTypes.object.isRequired,
  updateMediaObjects: PropTypes.func.isRequired,
  changeVisibilityOpen: PropTypes.bool.isRequired,
  setChangeVisibilityOpen: PropTypes.func.isRequired,
  closeMenu: PropTypes.func,
};

ChangeMediaObjectVisibilityModal.defaultProps = {
  closeMenu: () => {},
};
