import ReactGA from "react-ga4";
import { googleAnalyticsConfig } from "./universalAnalytics";

const { trackingId } = googleAnalyticsConfig();
ReactGA.initialize(trackingId);
ReactGA.set({ anonymizeIp: false });

export function trackPage(page) {
  ReactGA.set({ page });
  ReactGA.send({ hitType: "pageview", page: page });
}
