export const rightBarPlaceholderStyles = {
  clipPath: "polygon(-20% 0%, 100% 0%, 100% 100%, -20% 100%)",
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
  zIndex: 1110,
  width: 140,
  right: -70,
  position: "fixed",
  height: "100vh",
};
