import React, { useState } from "react";
import * as PropTypes from "prop-types";
import useForm from "../../hooks/useForm";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import ContentBox from "../ContentBox/ContentBox";
import ValidatorForm from "../Validator/ValidatorForm";
import ValidatorTextField from "../Validator/ValidatorTextField";
import { formValue } from "../../services/formServiceFunctions";
import { translate } from "../../services/Translations/translatorService";
import { duplicateMailDefault, emailDefault, requiredFieldDefault } from "../../services/validationRules";
import FormElementAlert from "../Case/CaseForm/FormElementAlert";
import _ from "lodash";
import SubmitButton from "../Button/SubmitButton";
import { persistCustomer } from "../../services/Persist/Persister";
import ButtonLoading from "../Button/ButtonLoading";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import moment from "moment";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../services/ReactQuery/reactQueryService";

export default function CustomerPageContent({ customer }) {
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteCustomerModalOpen, setDeleteCustomerModalOpen] = useState(false);
  const updateMutation = useMutation(updateResource);

  const initialValues = {
    email: customer.email,
    verification: customer.verification ? moment(customer.verification).format("DD.MM.YYYY HH:mm") : "",
  };

  const onSubmit = async () => {
    const updatedCustomer = await persistCustomer(customer.id, {
      email: values.email,
    });
    registerValidators("email", [
      ...emailDefault,
      ...requiredFieldDefault,
      ...duplicateMailDefault(updatedCustomer.email),
    ]);
  };

  const { values, errors, handleChange, handleSubmit, registerValidators, touchedValues } = useForm({
    initialValues,
    onSubmit,
    identifier: customer.id,
  });

  if (customer.isFake) {
    return <Box sx={{ textAlign: "center", color: "grey" }}>Dieser Mandant wurde gelöscht</Box>;
  }

  const deleteCustomer = async () => {
    setIsLoading(true);
    await updateMutation.mutateAsync({ uri: "customers", id: customer.id, data: { isFake: true } });
    setIsLoading(false);
    setDeleteCustomerModalOpen(false);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ padding: "2rem" }}>
            <Typography variant="h1">
              {customer.person.givenName} {customer.person.familyName}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={4}>
          <ContentBox headline={"Kontakt"}>
            <ValidatorForm onSubmit={handleSubmit}>
              <ValidatorTextField
                onChange={handleChange}
                onBlur={() => setIsShowAlert(true)}
                name={"email"}
                value={formValue(values, "email")}
                label={translate("customer.email.label")}
                registerValidators={registerValidators}
                validators={[...emailDefault, ...requiredFieldDefault, ...duplicateMailDefault(customer.email)]}
                error={!!errors["email"]}
                helperText={errors["email"]}
              />
              <FormElementAlert
                alert={{
                  onChange:
                    "Das Ändern der E-Mail-Adresse führt dazu, dass der Kunde sich nicht mehr mit seiner alten Adresse in der Servicewelt anmelden kann, sondern nur noch mit der neuen Adresse.",
                }}
                initialValue={formValue(initialValues, "email")}
                currentValue={formValue(values, "email")}
                path={"email"}
                setIsShowAlert={setIsShowAlert}
                isShowAlert={isShowAlert}
                handleChange={handleChange}
                label={translate("customer.email.label")}
              />
              <ValidatorTextField
                name="verification"
                label={translate("customer.verification.label")}
                value={formValue(values, "verification")}
                disabled
              />
              <Box sx={{ marginTop: "1rem" }}>
                <SubmitButton
                  error={_.keys(errors).length > 0}
                  isLoading={isLoading}
                  disabled={_.keys(touchedValues).length === 0}
                  variant={"contained"}
                  type={"submit"}
                >
                  Speichern
                </SubmitButton>
              </Box>
            </ValidatorForm>
          </ContentBox>
          <Button onClick={() => setDeleteCustomerModalOpen(true)} variant={"contained"}>
            Konto löschen
          </Button>
          <LegalbirdIoModal
            handleClose={() => setDeleteCustomerModalOpen(false)}
            open={deleteCustomerModalOpen}
            title={"Kunden löschen"}
            submitButton={
              <ButtonLoading isLoading={isLoading} onClick={deleteCustomer} variant={"contained"}>
                Löschen
              </ButtonLoading>
            }
          >
            Möchten Sie das Kundenkonto zu dieser E-Mail-Adresse unwiderruflich löschen?
          </LegalbirdIoModal>
        </Grid>
      </Grid>
    </>
  );
}

CustomerPageContent.propTypes = {
  customer: PropTypes.object.isRequired,
};
