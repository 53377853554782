import React, { createContext, useContext } from "react";
import { BackofficeUser } from "../types/BackofficeUser";

const CurrentUserProviderContext = createContext<BackofficeUser | undefined>(undefined);

interface CurrentUserProviderProps {
  children?: React.ReactNode;
  currentUser: BackofficeUser;
}

export default function CurrentUserProvider({ children, currentUser }: CurrentUserProviderProps) {
  return <CurrentUserProviderContext.Provider value={currentUser}>{children}</CurrentUserProviderContext.Provider>;
}

export const useCurrentUser = () => {
  const currentUserContext = useContext(CurrentUserProviderContext);
  if (currentUserContext === undefined) {
    throw new Error("useCurrentUser can only be used inside CurrentUserProvider");
  }
  return currentUserContext;
};
