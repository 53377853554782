const STATUS_CREATED = 10;
const STATUS_DATA_COMPLETED = 20;
const STATUS_WAITING_FOR_FEEDBACK = 25;
const STATUS_DATA_COMPLETED_LAWFIRM = 30;
const STATUS_CHECKED = 50;
const STATUS_IN_PROGRESS = 100;
const STATUS_COMPLETED = 200;

// additional in progress status

const STATUS_EXTRAJUDICIAL_COMMUNICATION_SENT = 110;
const STATUS_EXTRAJUDICIAL_REACTION_RECEIVED = 120;
const STATUS_ACTION_REQUIRED = 125;
const STATUS_PREPARE_LAWSUIT = 127;
const STATUS_LAWSUIT = 130;
const STATUS_TRIAL = 140;
const STATUS_LAWSUIT_COMPLETED = 180;

export {
  STATUS_CREATED,
  STATUS_DATA_COMPLETED,
  STATUS_WAITING_FOR_FEEDBACK,
  STATUS_DATA_COMPLETED_LAWFIRM,
  STATUS_CHECKED,
  STATUS_IN_PROGRESS,
  STATUS_COMPLETED,
  STATUS_EXTRAJUDICIAL_COMMUNICATION_SENT,
  STATUS_EXTRAJUDICIAL_REACTION_RECEIVED,
  STATUS_ACTION_REQUIRED,
  STATUS_PREPARE_LAWSUIT,
  STATUS_LAWSUIT,
  STATUS_TRIAL,
  STATUS_LAWSUIT_COMPLETED,
};
