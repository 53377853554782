const humanResourceManagementStyle = {
  paper: {
    maxWidth: 300,
    margin: "0 auto",
    padding: "1rem",
  },
  headline: {
    textAlign: "center",
  },
  subHeadline: {
    textAlign: "center",
    margin: "1rem",
  },
  workTimeSelectGridItem: { paddingRight: "0.5rem", paddingLeft: "1.5rem" },
};

export default humanResourceManagementStyle;
