import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { checkToken, loadToken } from "../../services/localStorage";
import { BackofficeUser } from "../../types/BackofficeUser";
import CurrentUserProvider from "../../provider/CurrentUserProvider";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../services/ReactQuery/reactQueryService";

export default function RequireAuth({
  children,
  redirectTo = "/login",
}: {
  children?: React.ReactNode;
  redirectTo?: string;
}) {
  const location = useLocation();
  const {
    data: currentUserResponse,
    isLoading,
    error,
  } = useQuery(queryKeys.all("current_user"), () => fetchCollection("backoffice_users/me"), { retry: false });
  if (isLoading) {
    return null;
  }

  if (error) {
    return <Navigate to={redirectTo} state={{ from: location }} />;
  }

  const currentUser: BackofficeUser | null = _.head(currentUserResponse["hydra:member"]) || null;
  return checkToken(loadToken(), currentUser) && !!currentUser ? (
    <CurrentUserProvider currentUser={currentUser}>{children}</CurrentUserProvider>
  ) : (
    <Navigate to={redirectTo} state={{ from: location }} />
  );
}
