import React from "react";
import { TableCell, TableRow, Tooltip } from "@mui/material";
import ActivityIcon from "../ActivityIcon";
import { getCaseLink, getProductData, getProductNameBy } from "../../../services/Product/ProductService";
import { makeStyles } from "@mui/styles";
import activityStyle from "./activityStyle";
import { Link } from "react-router-dom";
import moment from "moment";
import { CommentOutlined, ReportProblemOutlined } from "@mui/icons-material";
import ActivityActions from "../ActivityActions/ActivityActions";
import PropTypes from "prop-types";
import AssignedUser from "./AssignedUser";
import { ACTIVITY_OVERDUE } from "../../../services/Stages/Stages";
import classNames from "classnames";
import _ from "lodash";

const useStyle = makeStyles(activityStyle);

const ActivityTableRow = ({ activity, update, isExternalView }) => {
  const classes = useStyle();
  const productClassName = getProductNameBy("lbProductClass", activity.case.lbProductClass);
  const activityClasses = activity.done ? classes.done : null;

  const getClasses = (activity, baseClass = null) => {
    if (activity.done) {
      return classNames(classes.done, baseClass);
    }
    if (activity.activityStatus === ACTIVITY_OVERDUE) {
      return classNames(classes.overdue, baseClass);
    }
    return baseClass;
  };

  return (
    <TableRow className={activityClasses}>
      <TableCell className={getClasses(activity)}>
        <ActivityIcon activity={activity} withToolTip />
      </TableCell>
      <TableCell className={getClasses(activity, classes.descriptionCell)}>{activity.subject}</TableCell>
      <TableCell className={getClasses(activity)}>{getProductData(productClassName, "generalLabel")}</TableCell>
      <TableCell className={getClasses(activity)}>
        {!_.isEmpty(activity.note) && (
          <Tooltip
            placement={"right"}
            title={<div dangerouslySetInnerHTML={{ __html: activity.note }} />}
            className={classes.note}
          >
            <CommentOutlined />
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        <Link
          className={classes.link}
          to={getCaseLink(
            { productClassName: productClassName, id: activity.case.lbProductId },
            isExternalView ? "/extern" : ""
          )}
        >
          {activity.case.label || activity.case.lbProductId}
        </Link>
      </TableCell>
      <TableCell className={getClasses(activity)}>{moment(activity.dueDate).format("DD.MM.YYYY")}</TableCell>
      <TableCell className={getClasses(activity)}>
        {activity.dueTime !== "allDay" ? <span>{activity.dueTime}&nbsp;Uhr</span> : "-"}
      </TableCell>
      {isExternalView ? (
        <TableCell className={getClasses(activity)}>{activity.duration} Minuten</TableCell>
      ) : (
        <TableCell className={getClasses(activity)}>
          {activity.mandatory ? (
            <Tooltip title={"Pflichtaktivität"}>
              <ReportProblemOutlined />
            </Tooltip>
          ) : (
            "-"
          )}
        </TableCell>
      )}
      <TableCell className={getClasses(activity)}>
        <AssignedUser activity={activity} updateActivities={update} />
      </TableCell>
      {!isExternalView && (
        <TableCell>
          <ActivityActions activity={activity} updateActivities={update} />
        </TableCell>
      )}
    </TableRow>
  );
};

ActivityTableRow.propTypes = {
  activity: PropTypes.object,
  update: PropTypes.func,
  isExternalView: PropTypes.bool,
};

export default ActivityTableRow;
