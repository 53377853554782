const externalUserTranslations = {
  processableTaskTypes: {
    label: "Freigeschaltet für",
    values: {
      legalDocuments: "Schriftsätze fertigen (Außergerichtliche Schreiben, Klageschriften etc.)",
      advisories: "Beratungen",
      checkCases: "Fallprüfung und Ersteinschätzung Vertragsrecht",
      investigationFiles: "Ermittlungsakten prüfen (z.B. im Verkehrsrecht)",
    },
  },
}

export default externalUserTranslations;
