import React from "react";
import MenuItem from "@mui/material/MenuItem";
import useStyles from "../activityOverviewHeaderStyles";
import ValidatorSelect from "../../../Validator/ValidatorSelect";
import { translate } from "../../../../services/Translations/translatorService";

const ProductFilter = ({ productFilter, setProductFilter }) => {
  const classes = useStyles();
  return (
    <ValidatorSelect
      label="Rechtsprodukt"
      onChange={({ target }) => {
        setProductFilter(target.value);
      }}
      value={productFilter}
      className={classes.filterSelect}
      fullWidth={true}
    >
      <MenuItem value={"all"}>Alle Rechtsprodukte</MenuItem>
      <MenuItem value={"App\\Entity\\Legalbird\\Divorce\\Divorce"}>{translate("divorce.label")}</MenuItem>
      <MenuItem value={"App\\Entity\\Legalbird\\Settlement\\Settlement"}>{translate("settlement.label")}</MenuItem>
      <MenuItem value={"App\\Entity\\Legalbird\\Traffic\\Traffic"}>{translate("traffic.label")}</MenuItem>
      <MenuItem value={"App\\Entity\\Legalbird\\Document\\ShortTimeWork"}>{translate("shortTimeWork.label")}</MenuItem>
      <MenuItem value={"App\\Entity\\Legalbird\\Document\\Alimony"}>{translate("alimony.label")}</MenuItem>
      <MenuItem value={"App\\Entity\\Legalbird\\Document\\Death"}>{translate("death.label")}</MenuItem>
      <MenuItem value={"App\\Entity\\Legalbird\\BasicCase\\FamilyLawCase"}>{translate("familyLawCase.label")}</MenuItem>
      <MenuItem value={"contractLaw"}>Beratung / Vertretung Vertragsrecht</MenuItem>
      <MenuItem value={"App\\Entity\\Legalbird\\Document\\PatientDecree"}>{translate("patientDecree.label")}</MenuItem>
      <MenuItem value={"App\\Entity\\Legalbird\\Document\\PowerOfAttorney"}>{translate("powerOfAttorney.label")}</MenuItem>
    </ValidatorSelect>
  );
};

export default ProductFilter;
