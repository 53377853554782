import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { login, logout } from "../../../store/backofficeUser/actions";
import ApiClient from "../../../services/ApiClient";
import { useSnackbar } from "notistack";
import Snackbar from "../../Snackbar/Snackbar";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import PageContentContainer from "../../Container/PageContentContainer";
import ContentBox from "../../ContentBox/ContentBox";
import { Box, Grid, Link, Typography } from "@mui/material";
import ButtonLoading from "../../Button/ButtonLoading";
import _ from "lodash";
import ValidatorTextField from "../../Validator/ValidatorTextField";
import ValidatorForm from "../../Validator/ValidatorForm";
import useForm from "../../../hooks/useForm";
import { formValue } from "../../../services/formServiceFunctions";
import {
  ibanFieldDefault,
  passwordFieldDefault,
  requiredFieldDefault,
  textFieldDefault,
} from "../../../services/validationRules";
import { IbanInput } from "../../MaskedInputs/IbanInput";
import { apiGet } from "../../../services/Api/apiCall";

export default function ConfirmAccountPage({}) {
  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const backofficeUserId = query.get("id");
  const token = query.get("t");
  const email = query.get("email");
  const [isLoading, setIsLoading] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  const onSubmit = async () => {
    setIsLoading(true);

    const password = formValue(values, "password");

    const result = await ApiClient.post("set_user_password", {
      body: JSON.stringify({
        password: password,
        token: token,
        id: backofficeUserId,
      }),
    });

    if (!result || _.isEmpty(result.email) || _.toLower(result.email) !== _.toLower(email)) {
      enqueueSnackbar("", {
        content: () => (
          <div>
            <Snackbar
              message={
                "Ihre Daten konnten nicht verifiziert werden. Sollte dieses Problem bestehen bleiben nutzen Sie bitte erneut den Link aus Ihrer E-Mail"
              }
              isNonInteractive
            />
          </div>
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      return;
    }

    await login({ email: email, password: password });

    const externalUserCollection = await ApiClient.get("external_users?backofficeUser=" + backofficeUserId);

    await ApiClient.put("backoffice_bank_datas/" + externalUserCollection["hydra:member"][0].bankData.id, {
      body: JSON.stringify({
        iban: values.iban,
        accountOwnerFullName: values.accountOwnerFullName,
      }),
    });

    navigate("/");
  };

  const recoveryNotFoundMessage = () => {
    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar message={"Der Einmal-Link aus Ihrer E-Mail ist nicht mehr gültig."} isNonInteractive />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  };

  const initialValues = {
    password: "",
    accountOwnerFullName: "",
    iban: "",
  };

  const { values, errors, handleChange, handleSubmit, handleBlur, registerValidators, touchedValues } = useForm({
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    if (!backofficeUserId || !token || !email) {
      navigate("/login");
      return;
    }
    logout();
    apiGet("check_user_password_recovery", null, { id: backofficeUserId, t: token }).then(
      (successResponse) => {
        if (!successResponse.passwordRecoveryExists) {
          recoveryNotFoundMessage();
          navigate("/login");
          return;
        }
        setIsChecking(false);
      },
      () => recoveryNotFoundMessage()
    );
  }, []);

  if (isChecking) {
    return <LegalbirdLoader centered={true} />;
  }

  const password = formValue(values, "password");

  return (
    <>
      <PageContentContainer size={"tiny"}>
        <ContentBox headline={"Ihr Konto bei Legalbird bestätigen"} headlineVariant={"h1"}>
          <Typography variant={"h4"} sx={{ textAlign: "center", margin: "2rem" }}>
            Für das Anlegen Ihres Konto fehlen nun nur noch Ihre Bankdaten für die Vergütung und Ihr persönliches
            Passwort.
          </Typography>
          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container alignItems={"flex-start"} justifyContent={"center"} spacing={3}>
              <Grid item xs={12} md={6}>
                <ValidatorTextField
                  name={"accountOwnerFullName"}
                  label={"Kontoinhaber"}
                  value={formValue(values, "accountOwnerFullName")}
                  onChange={handleChange}
                  registerValidators={registerValidators}
                  validators={[...requiredFieldDefault, ...textFieldDefault]}
                  onBlur={handleBlur}
                  error={!!errors["accountOwnerFullName"]}
                  helperText={errors["accountOwnerFullName"]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ValidatorTextField
                  name={"iban"}
                  label={"IBAN"}
                  value={formValue(values, "iban")}
                  onChange={handleChange}
                  registerValidators={registerValidators}
                  validators={[...requiredFieldDefault, ...ibanFieldDefault]}
                  InputProps={{ inputComponent: IbanInput }}
                  onBlur={handleBlur}
                  error={!!errors["iban"]}
                  helperText={errors["iban"]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ValidatorTextField
                  name={"password"}
                  label={"Passwort"}
                  type={"password"}
                  value={password}
                  onChange={handleChange}
                  registerValidators={registerValidators}
                  validators={[...passwordFieldDefault]}
                  onBlur={handleBlur}
                  error={!!errors["password"]}
                  helperText={errors["password"]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ButtonLoading
                  sx={{ marginTop: "16px" }}
                  isLoading={isLoading}
                  disabled={_.keys(touchedValues).length === 0}
                  variant={"contained"}
                  type={"submit"}
                >
                  Speichern und Anmelden
                </ButtonLoading>
                <Box sx={{ textAlign: "center", fontSize: ".7rem", marginTop: 1 }}>
                  Ich stimme den{" "}
                  <Link href={"/nutzungsbedingungen"} target={"_blank"}>
                    Nutzungsbedingungen
                  </Link>{" "}
                  und dem{" "}
                  <Link href={"/datenschutz"} target={"_blank"}>
                    Datenschutz
                  </Link>{" "}
                  der Legalbird Rechtsanwalts UG zu.
                </Box>
              </Grid>
            </Grid>
          </ValidatorForm>
        </ContentBox>
      </PageContentContainer>
    </>
  );
}
