import React from "react";
import * as PropTypes from "prop-types";
import paidTasksCaseStyle from "./paidTasksCaseStyle";
import Grid from "@mui/material/Grid";
import CircleIcon from "../../CircleIcon/CircleIcon";
import { ReportProblemOutlined, WorkOutlineOutlined } from "@mui/icons-material";
import { PAID_TASK_STATUS_ASSIGNED, PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_IN_REVIEW } from "../paidTaskStatus";
import moment from "moment";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(paidTasksCaseStyle);

const PaidTaskCaseHead = ({ paidTask, actions }) => {
  const classes = useStyles();
  const getPaidTaskClasses = (paidTask) => {
    if (paidTask.status >= PAID_TASK_STATUS_IN_REVIEW || paidTask.deletedDate) {
      return classes.done;
    }

    if (paidTask.status === PAID_TASK_STATUS_CREATED && moment().isAfter(paidTask.assignmentDeadline, "day")) {
      return classes.overdue;
    }

    if (paidTask.status === PAID_TASK_STATUS_ASSIGNED && moment().isAfter(paidTask.doneDeadline, "day")) {
      return classes.overdue;
    }
  };

  return (
    <Grid container>
      <Grid className={classes.imageGridItem} item>
        <CircleIcon icon={<WorkOutlineOutlined color={"secondary"} />} type={"muiIcon"} />
      </Grid>
      <Grid item className={classes.activityContent}>
        <Grid container>
          <Grid item xs={12} className={getPaidTaskClasses(paidTask)}>
            <Grid container className={classes.activityContentContainer} alignItems={"center"}>
              <Grid item className={classes.innerHeadline}>
                {paidTask.subject}
              </Grid>
              {paidTask.status !== PAID_TASK_STATUS_IN_REVIEW && (
                <Grid item>
                  <ReportProblemOutlined />
                </Grid>
              )}
              <Grid item className={classes.activityInfo}>
                {moment(paidTask.doneDeadline).format("DD.MM.YYYY")} |{" "}
                {paidTask.assignee ? paidTask.assignee.person.fullname : "Noch nicht zugeordnet"}
              </Grid>
              {actions && (
                <Grid item className={classes.activityActions}>
                  {actions}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

PaidTaskCaseHead.propTypes = {
  actions: PropTypes.node,
  paidTask: PropTypes.object,
};

export default PaidTaskCaseHead;
