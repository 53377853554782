import React from "react";
import * as PropTypes from "prop-types";

const ValidatorForm = ({ children, submitOnEnter = true, ...rest }) => {

  const checkKeyDown = (e) => {
    if (!submitOnEnter && e.code === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <form autoComplete={"off"} {...rest} onKeyDown={(e) => checkKeyDown(e)}>
      {children}
    </form>
  );
};

export default ValidatorForm;

ValidatorForm.propTypes = {
  submitOnEnter: PropTypes.bool,
};
