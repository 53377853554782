import PropTypes from "prop-types";
import React, { useState } from "react";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";

export default function AdvoAssistCancelModal({ advoAssist, submitAction, open, handleClose, refreshPage }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    await submitAction(
      "cancel",
      {},
      "Termin kann nicht abgesagt werden. Hier liegt entweder ein systemseitiger Fehler vor (z.B. AdvoAssist nicht erreichbar) oder der Termin kann aus anderen Gründen nicht abgesagt werden (Datum liegt in der Vergangenheit etc.)"
    );
    refreshPage();
  };

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title="Gerichtstermin wurde abgesagt"
      submitButton={
        <ButtonLoading onClick={handleConfirm} color="primary" variant={"contained"} isLoading={isLoading}>
          Termin löschen
        </ButtonLoading>
      }
    >
      <p>
        Der Gerichtstermin wurde abgesagt. Mit Klick auf "Termin löschen" wird der Termin entfernt und bei AdvoAssist
        abgesagt.
        <br />
        Bitte beachten: Wenn der Termin abgesagt wird, wird auch das Terminelement gelöscht.
      </p>
    </LegalbirdIoModal>
  );
}
AdvoAssistCancelModal.propTyes = {
  advoAssist: PropTypes.object.isRequired,
  submitAction: PropTypes.func.isRequired,
};
