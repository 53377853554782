import React from "react";
import PageHeadline from "../../PageHeadline/PageHeadline";
import PageContentContainer from "../../Container/PageContentContainer";
import UserManagement from "../../UserManagement/UserManagement";

export default function UserManagementPage() {
  return (
    <PageContentContainer size={"large"}>
      <PageHeadline main="Nutzerverwaltung" />
      <UserManagement />
    </PageContentContainer>
  );
}
