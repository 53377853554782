import LegalbirdIoModal from "../../../Modal/LegalbirdIoModal";
import Button from "@mui/material/Button";
import React from "react";

function DeletionDeniedModal({ open, handleClose }) {
  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      hideCancelButton
      title={"Fall kann nicht gelöscht werden"}
      submitButton={<Button onClick={handleClose}>Ok</Button>}
    >
      <p className={"center"}>
        Der Fall kann nicht gelöscht werden, da es Buchungseinträge im Konto gibt. Bitte prüfen und im Zweifel die
        E-Mail-Adresse um “xxx_” ergänzen, sodass Mandant keinen Zugang und keine E-Mails mehr erhält.
      </p>
    </LegalbirdIoModal>
  );
}
export default DeletionDeniedModal;
