import React, { useMemo } from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import _ from "lodash";
import { MediaObject } from "../../types/MediaObject";
import { useBackofficeUser } from "../../provider/BackofficeUserProvider";
import { BackofficeUser } from "../../types/BackofficeUser";

export default function MediaObjectUserFilter({
  mediaObjects,
  userFilter,
  setUserFilter,
}: {
  mediaObjects: Array<MediaObject>;
  setUserFilter: Function;
  userFilter: string;
}) {
  const { backofficeUsers } = useBackofficeUser();

  const usersWithMediaObjects: Array<BackofficeUser> = useMemo(() => {
    let availableUsers: Array<BackofficeUser> = _.filter(backofficeUsers, (backofficeUser) => {
      return !!_.find(mediaObjects, (mediaObject) => mediaObject.createdBy === backofficeUser["@id"]);
    });

    availableUsers = _.sortBy(availableUsers, (backofficeUser) => {
      return backofficeUser["@id"] === "/backoffice_users/13";
    });

    if (_.find(mediaObjects, (mediaObject) => mediaObject.createdBy === "customer")) {
      availableUsers = [
        {
          "@id": "customer",
          person: {
            "@id": "person",
            id: 0,
            titles: [],
            gender: "male",
            givenName: "",
            familyName: "Mandant",
            encodedSignature: null,
            fullname: "Mandant",
            beaData: false,
          },
          id: 0,
          roles: [],
          email: "",
          avatar: null,
          calendlyId: null,
          isAccountManager: false,
          isLawyer: false,
          isExternal: false,
          status: "active",
        },
        ...availableUsers,
      ];
    }
    return availableUsers;
  }, [mediaObjects, backofficeUsers]);

  const handleFilterChange = (event: SelectChangeEvent) => {
    setUserFilter(event.target.value);
  };

  return (
    <FormControl sx={{ minWidth: 200 }}>
      <InputLabel>Ersteller</InputLabel>
      <Select onChange={handleFilterChange} value={userFilter} label={"Ersteller"}>
        <MenuItem value={"allUsers"}>Alle Benutzer</MenuItem>
        {_.map(usersWithMediaObjects, (user) => (
          <MenuItem key={user["@id"]} value={user["@id"]}>
            {user.person.fullname}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
