const STATUS_CREATED = 10;
const STATUS_DATA_COMPLETED = 20;
const STATUS_POWER_OF_ATTORNEY_GRANTED = 30;
const STATUS_DOCUMENTS_COMPLETE = 40;
const STATUS_CHECKED = 50;
const STATUS_PROCESS_PAID = 60;
const STATUS_SUBMITTED = 70;
const STATUS_COURT_PAID = 75;
const STATUS_JUDICIAL_PROCESS = 77;
const STATUS_DATE_SET = 80;
const STATUS_HEARING_PAID = 90;
const STATUS_DIVORCE_DONE = 100;
const STATUS_COMPLETED = 110;

export {
  STATUS_CREATED,
  STATUS_DATA_COMPLETED,
  STATUS_POWER_OF_ATTORNEY_GRANTED,
  STATUS_DOCUMENTS_COMPLETE,
  STATUS_CHECKED,
  STATUS_PROCESS_PAID,
  STATUS_SUBMITTED,
  STATUS_COURT_PAID,
  STATUS_JUDICIAL_PROCESS,
  STATUS_DATE_SET,
  STATUS_HEARING_PAID,
  STATUS_DIVORCE_DONE,
  STATUS_COMPLETED,
};
