import React from "react";
import PageContentContainer from "../../Container/PageContentContainer";
import Impersonate from "../../Impersonate/Impersonate";
import GlobalProvider from "../../Provider/GlobalProvider";

export default function AdminPage() {
  return (
    <GlobalProvider>
      <PageContentContainer size={"tiny"}>
        <Impersonate />
      </PageContentContainer>
    </GlobalProvider>
  );
}
