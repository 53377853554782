import React, { useState } from "react";
import * as PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import casePaidTaskStyle from "./casePaidTaskStyle";
import { Box, Grid } from "@mui/material";
import { getApprovalCardEnabled } from "../../services/paidTaskService";
import { formValue, transformFromFormValues } from "../../services/formServiceFunctions";
import { requiredFieldDefault } from "../../services/validationRules";
import ValidatorTextField from "../Validator/ValidatorTextField";
import ValidatorForm from "../Validator/ValidatorForm";
import ButtonLoading from "../Button/ButtonLoading";
import useForm from "../../hooks/useForm";
import moment from "moment";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import _ from "lodash";
import { getCaseLink, getProductData } from "../../services/Product/ProductService";
import { ContentContainer } from "../Container/PageContentContainer";
import { useSnackbar } from "notistack";
import Snackbar from "../Snackbar/Snackbar";
import { PAID_TASK_STATUS_IN_REVIEW } from "../PaidTask/paidTaskStatus";
import ContentBox from "../ContentBox/ContentBox";
import { translate } from "../../services/Translations/translatorService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, updateResource } from "../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

const useStyles = makeStyles(casePaidTaskStyle);

const CasePaidTaskApproval = ({ paidTask, product }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmRequestChangesOpen, setConfirmRequestChangesOpen] = useState(false);
  const [confirmApprovePaidTaskOpen, setConfirmApprovePaidTaskOpen] = useState(false);
  const updateMutation = useMutation(updateResource);
  const queryClient = useQueryClient();

  const onChangesRequested = async () => {
    setConfirmRequestChangesOpen(true);
  };

  const onApproval = async () => {
    setConfirmApprovePaidTaskOpen(true);
  };

  const handleConfirmChangesRequested = async () => {
    setIsLoading(true);
    await updateMutation.mutateAsync({
      id: paidTask.id,
      uri: "paid_tasks",
      data: {
        reviewRemarks: formValue(values, "reviewRemarks"),
        changesRequested: true,
      },
    });
    setIsLoading(false);
    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar
            message={"Änderungen wurden angefragt"}
            buttonType={"link"}
            buttonLink={getCaseLink(product)}
            buttonTarget={"_self"}
            buttonText={"Zum Fall"}
          />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      autoHideDuration: 3000,
    });
    setConfirmRequestChangesOpen(false);
  };

  const initialValues = { reviewRemarks: paidTask.reviewRemarks || "" };

  const {
    values,
    errors,
    handleChange,
    handleSubmit: handleChangesRequested,
    registerValidators,
  } = useForm({ initialValues, onSubmit: onChangesRequested });

  const handleConfirmDone = async () => {
    setIsLoading(true);

    //----update paidtask
    await updateMutation.mutateAsync({
      id: paidTask.id,
      uri: "paid_tasks",
      data: {
        approvedDate: moment().format("DD.MM.YYYY"),
        approvedBy: currentUser["@id"],
      },
    });
    await queryClient.invalidateQueries(queryKeys.item("paid_tasks", paidTask.id));
    //----update product entity with temporarily saved fields filled in by external user
    let updateData = _.cloneDeep(paidTask.formData);
    //remove fields that have nothing to do with the entities. for example editor fields
    updateData = _.mapKeys(updateData, (value, path) => {
      if (_.startsWith(path, "temporaryField.")) {
        return _.replace(path, "temporaryField.", "");
      }
      return "notAFieldToPersist";
    });
    updateData = _.pickBy(updateData, (field, key) => key !== "notAFieldToPersist");
    updateData = transformFromFormValues(product.productClassName, updateData);
    const persist = getProductData(product.productClassName, "persist");
    await persist(updateData, product);

    //---- notify user
    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar
            message={"Aufgabe wurde abgenommen"}
            buttonType={"link"}
            buttonLink={getCaseLink(product)}
            buttonTarget={"_self"}
            buttonText={"Zum Fall"}
          />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      autoHideDuration: 3000,
    });
    setIsLoading(false);
    setConfirmApprovePaidTaskOpen(false);
  };

  const isDisabled = !getApprovalCardEnabled(paidTask.status, currentUser);

  return (
    <Box
      sx={
        isDisabled
          ? {
              opacity: 0.5,
              pointerEvents: "none",
            }
          : {}
      }
    >
      <ContentBox headline="Abnahme" headlineVariant="h2">
        <ContentContainer size={"tiny"}>
          <div className={classes.contentPadding}>
            <ValidatorForm onSubmit={handleChangesRequested}>
              <ValidatorTextField
                label={translate("paidTask.reviewRemarks")}
                name={"reviewRemarks"}
                value={formValue(values, "reviewRemarks")}
                onChange={handleChange}
                registerValidators={registerValidators}
                validators={requiredFieldDefault}
                error={!!errors["reviewRemarks"]}
                helperText={errors["reviewRemarks"]}
                multiline
                disabled={paidTask.status !== PAID_TASK_STATUS_IN_REVIEW || currentUser.isExternal}
              />
              {!currentUser.isExternal && paidTask.status === PAID_TASK_STATUS_IN_REVIEW && (
                <Grid container justifyContent={"space-evenly"} className={classes.buttonArea}>
                  <Grid item>
                    <ButtonLoading isLoading={isLoading} type={"submit"} className={classes.submitButton}>
                      Änderungen notwendig
                    </ButtonLoading>
                  </Grid>
                  <Grid item>
                    <ButtonLoading
                      className={classes.submitButton}
                      variant={"contained"}
                      isLoading={isLoading}
                      onClick={onApproval}
                    >
                      Aufgabe abnehmen und Dateien speichern
                    </ButtonLoading>
                  </Grid>
                </Grid>
              )}
            </ValidatorForm>
          </div>
        </ContentContainer>
        <LegalbirdIoModal
          handleClose={() => setConfirmRequestChangesOpen(false)}
          open={confirmRequestChangesOpen}
          title={"Aufgabe zurückgeben?"}
          submitButton={
            <ButtonLoading variant={"contained"} onClick={handleConfirmChangesRequested} isLoading={isLoading}>
              Änderungen anfragen
            </ButtonLoading>
          }
        >
          Möchten Sie die Aufgabe wieder zurück in Bearbeitung geben und Änderungen beim zugewiesenen Anwalt anfragen?
        </LegalbirdIoModal>
        <LegalbirdIoModal
          handleClose={() => setConfirmApprovePaidTaskOpen(false)}
          open={confirmApprovePaidTaskOpen}
          title={"Aufgabe abnehmen?"}
          submitButton={
            <ButtonLoading variant={"contained"} onClick={handleConfirmDone} isLoading={isLoading}>
              Aufgabe abnehmen
            </ButtonLoading>
          }
        >
          Möchten Sie die Aufgabe abnehmen und zur Vergütung freigeben? Es werden dann alle Dateien und Informationen an
          den Fall übertragen. Ein Wieder-Öffnen der Aufgabe ist nicht möglich.
        </LegalbirdIoModal>
      </ContentBox>
    </Box>
  );
};

CasePaidTaskApproval.propTypes = {
  paidTask: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
};

export default CasePaidTaskApproval;
