import * as PropTypes from "prop-types";
import CostBoxTraffic from "../CaseCostBoxes/CostBoxTraffic";
import CostBoxSettlement from "../CaseCostBoxes/CostBoxSettlement";
import CostBoxDivorce from "../CaseCostBoxes/CostBoxDivorce";
import React from "react";

const CaseCostBox = ({ product }) => {
  switch (product.productClassName) {
    case "traffic":
      return <CostBoxTraffic traffic={product} />;
    case "settlement":
      return <CostBoxSettlement settlement={product} />;
    case "divorce":
      return <CostBoxDivorce divorce={product} />;
    default:
      return null;
  }
};
CaseCostBox.propTypes = {
  product: PropTypes.object.isRequired,
};
export default CaseCostBox;
