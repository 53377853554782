import React, { useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { scrollToAnchor } from "../../theme/commonStyles";
import ContentBox from "../ContentBox/ContentBox";
import { Box, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ApiClient from "../../services/ApiClient";
import { FolderOutlined, ListOutlined, StarOutline } from "@mui/icons-material";
import MediaObjectListView from "./MediaObjectListView";
import MediaObjectFolderView from "./MediaObjectFolderView";
import MediaObjectUserFilter from "./MediaObjectUserFilter";
import _ from "lodash";
import {filterByFolder, getMediaObjectFilters} from "../../services/mediaObjectService";
import MediaObjectOrderBySelect from "./MediaObjectOrderBySelect";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import ButtonLoading from "../Button/ButtonLoading";

const MediaObjects = ({ product }) => {
  const [viewType, setViewType] = useState("list");
  const [userFilter, setUserFilter] = useState("allUsers");
  const [orderBy, setOrderBy] = useState("createdDESC");
  const currentUser = useCurrentUser();

  const mediaObjectsFilter = getMediaObjectFilters(product, currentUser);

  const { data: mediaObjectsCollection } = useQuery(
    queryKeys.collection("media_objects", mediaObjectsFilter),
    () => fetchCollection("media_objects", mediaObjectsFilter),
    {
      enabled: !!product,
    }
  );

  const mediaObjectsArray = mediaObjectsCollection ? mediaObjectsCollection["hydra:member"] : [];

  const filteredMediaObjects = useMemo(() => {
    if (userFilter === "allUsers") {
      return mediaObjectsArray;
    }

    return _.filter(mediaObjectsArray, (mediaObject) => {
      if (userFilter === "customer") {
        return mediaObject.createdBy === "customer";
      }
      return mediaObject.createdBy === userFilter;
    });
  }, [mediaObjectsArray, userFilter]);
  const orderedMediaObjects = useMemo(() => {
    switch (orderBy) {
      case "originalNameASC":
        return _.orderBy(filteredMediaObjects, "originalName");
      case "originalNameDESC":
        return _.orderBy(filteredMediaObjects, "originalName", "desc");
      case "createdASC":
        return _.orderBy(filteredMediaObjects, "created");
      case "createdDESC":
      default:
        return _.orderBy(filteredMediaObjects, "created", "desc");
    }
  }, [filteredMediaObjects, orderBy]);
  const filteredListViewMediaObjects = useMemo(() => {
    return filterByFolder(orderedMediaObjects, "CD_");
  }, [orderedMediaObjects]);
  const filteredFavouritesViewMediaObjects = useMemo(() => {
    return filteredListViewMediaObjects.filter((mediaObject) => mediaObject.isFavourite);
  }, [filteredListViewMediaObjects]);

  if (!mediaObjectsArray) {
    return null;
  }

  return (
    <>
      <Box sx={scrollToAnchor} id={"documents"} />
      <ContentBox headline="Dokumente">
        <Stack direction={"row"} justifyContent={"flex-end"} spacing={2} sx={{ marginBottom: "1rem" }}>
          {!currentUser.isExternal && <ElectronicFileImport product={product} />}
          <MediaObjectOrderBySelect setOrderBy={setOrderBy} orderBy={orderBy} />
          <MediaObjectUserFilter
            mediaObjects={mediaObjectsArray}
            setUserFilter={setUserFilter}
            userFilter={userFilter}
          />
          <ToggleButtonGroup value={viewType} exclusive onChange={(event, newViewType) => setViewType(newViewType)}>
            <ToggleButton value={"list"}>
              <Tooltip title={"Liste"}>
                <ListOutlined />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={"folderStructure"}>
              <Tooltip title={"Ordner"}>
                <FolderOutlined />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={"favourites"}>
              <Tooltip title={"Favoriten"}>
                <StarOutline />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        {viewType === "list" && (
          <MediaObjectListView mediaObjects={filteredListViewMediaObjects || []} product={product} />
        )}
        {viewType === "folderStructure" && (
          <MediaObjectFolderView mediaObjects={orderedMediaObjects || []} product={product} />
        )}
        {viewType === "favourites" && (
          <MediaObjectListView mediaObjects={filteredFavouritesViewMediaObjects || []} product={product} />
        )}
      </ContentBox>
    </>
  );
};

const ElectronicFileImport = ({ product }) => {
  const [showImportModal, setShowImportModal] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [errorImporting, setErrorImporting] = useState(false);
  if (product.productClassName !== "traffic") {
    return null;
  }

  const handleImport = () => {
    setIsImporting(true);
    setErrorImporting(false);
    ApiClient.get("lb/services/batch_import/" + product.id)
      .then(() => {
        setShowImportModal(false);
      })
      .catch(() => {
        setErrorImporting(true);
      })
      .finally(() => {
        setIsImporting(false);
      });
  };

  return (
    <Box sx={{ textAlign: "right" }}>
      <Button fullWidth={false} onClick={() => setShowImportModal(true)}>
        CD-Import
      </Button>

      <LegalbirdIoModal
        handleClose={() => setShowImportModal(false)}
        open={showImportModal}
        title={"CD-Daten importieren"}
        actionsInfo={
          errorImporting && (
            <Typography color={"red"} textAlign={"center"}>
              Es befinden sich noch Unterordner oder mehr als 10 Dateien im Importordner. Bitte Dateien als ZIP-Datei
              zusammenfassen um diese am Fall hochladen zu können.
            </Typography>
          )
        }
        submitButton={
          <ButtonLoading variant={"contained"} isLoading={isImporting} onClick={handleImport}>
            Daten importieren
          </ButtonLoading>
        }
      >
        <p className={"center"}>
          Nach dem Klick auf "Importieren" werden <strong>alle</strong> Dateien, welche sich im Google-Ordner
          "CD-Import" befinden, der E-Akte dieses Falls angehangen. Bitte sicherstellen, dass sich{" "}
          <strong>nur Dateien für diesen Fall und keine Unterordner</strong> im Import-Ordner befinden.
        </p>
        <p>
          <strong>Wichtig:</strong> Bitte größere Dateimengen (mehr als 10 Dateien) vorher zu einer Zip-Datei
          zusammenfügen!
        </p>
      </LegalbirdIoModal>
    </Box>
  );
};

MediaObjects.propTypes = {
  product: PropTypes.object.isRequired,
};

export default MediaObjects;
