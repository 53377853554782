const addressTranslations = {
  streetAddress: {
    label: "Straße und Hausnummer",
  },
  postalCode: {
    label: "PLZ",
  },
  addressLocality: {
    label: "Ort",
  },
  postOfficeBoxNumber: {
    label: "Postfach",
  },
  postOfficeBoxPostalCode: {
    label: "Postfach PLZ",
  },
  postOfficeBoxAddressLocality: {
    label: "Postfach Ort",
  },
};

export default addressTranslations;
