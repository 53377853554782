import React from "react";
import { AbstractCase } from "../../../types/AbstractCase";
import BeaSend from "./BeaSend";
import CommunicationMediumProvider, { useCommunicationMedium } from "./CommunicationMediumProvider";
import FaxSend from "./FaxSend";

interface CommunicationMediumProps {
  product: AbstractCase;
  open: boolean;
  handleClose: () => void;
}

export default function CommunicationMedium({ product, open, handleClose }: CommunicationMediumProps) {
  return (
    <CommunicationMediumProvider product={product} open={open} handleClose={handleClose}>
      <CommunicationMediumInner />
    </CommunicationMediumProvider>
  );
}

function CommunicationMediumInner() {
  const { communicationMedium } = useCommunicationMedium();
  switch (communicationMedium) {
    case "bea":
      return <BeaSend />;
    case "fax":
      return <FaxSend />;
    default:
      return null;
  }
}
