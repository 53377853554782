import React from "react";
import { HitProperties } from "./hitType";
import { Link } from "react-router-dom";
import { Box, CardActionArea } from "@mui/material";
import hitStyles from "./hitStyles";

export default function CustomerHit({ hit, onHitClick, resetHits, itemSize }: HitProperties) {
  const highlightResult = hit._highlightResult;
  if (!highlightResult) {
    return null;
  }

  const handleCardClick = () => {
    resetHits();
    onHitClick && onHitClick(hit.productId);
  };

  return (
    <CardActionArea
      sx={{ height: itemSize + "px", ...hitStyles.cardActionArea }}
      onClick={handleCardClick}
      component={Link}
      to={`/services/mandant/${hit.productId}`}
    >
      <Box sx={hitStyles.headline}>
        {highlightResult.givenName && (
          <span dangerouslySetInnerHTML={{ __html: highlightResult.givenName.value + " " }} />
        )}
        {highlightResult.familyName && <span dangerouslySetInnerHTML={{ __html: highlightResult.familyName.value }} />}
      </Box>
      {highlightResult.email && (
        <div dangerouslySetInnerHTML={{ __html: "E-Mail-Adresse: " + highlightResult.email.value }} />
      )}
    </CardActionArea>
  );
}
