import React from "react";
import { List, ListItem } from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { BeaAttachment } from "../../types/Bea/BeaAttachment";
import _ from "lodash";
import beaCombinableStyle from "./BeaCombinableStyle";
import { WarningOutlined } from "@mui/icons-material";

type BeaDroppableProps = {
  combinable: Array<BeaAttachment>;
  index: number | string;
  backgroundImage?: string;
};

export default function BeaDroppable({ combinable, index, backgroundImage }: BeaDroppableProps) {
  //for dev use StrictModeDroppable instead of Droppable
  return (
    <Droppable droppableId={"" + index}>
      {(provided) => (
        <List
          {...provided.droppableProps}
          ref={provided.innerRef}
          sx={
            backgroundImage
              ? {
                  ...beaCombinableStyle.combinableList,
                  backgroundImage: "url(" + backgroundImage + ")",
                }
              : beaCombinableStyle.combinableList
          }
        >
          {_.map(combinable, (attachment, index) => (
            <Draggable draggableId={"attachment-" + attachment.id} index={index} key={attachment.id}>
              {(provided) => (
                <ListItem
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  sx={beaCombinableStyle.combinableListItem}
                >
                  {attachment.mediaObjectId === 0 && <WarningOutlined sx={{color: "red"}} />}
                  {attachment.reference}
                </ListItem>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </List>
      )}
    </Droppable>
  );
}
