import React from "react";
import * as PropTypes from "prop-types";
import classNames from "classnames";
import { TableCell } from "@mui/material";
import { makeStyles } from "@mui/styles";
import activityStyle from "./activityStyle";

const useStyle = makeStyles(activityStyle);

const SortableColumnCell = ({ sortBy, attribute, toggleSortBy, children }) => {
  const classes = useStyle();

  return (
    <TableCell>
      <span
        className={classNames(sortBy === attribute ? classes.activeSorting : null, classes.sortableHeadline)}
        onClick={() => toggleSortBy(attribute)}
      >
        {children}
      </span>
    </TableCell>
  );
};

SortableColumnCell.propTypes = {
  attribute: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  toggleSortBy: PropTypes.func.isRequired,
};

export default SortableColumnCell;
