import { AbstractCase } from "../../../types/AbstractCase";

export const getRequestSchufaDataCopyAllowed = (product: AbstractCase) => {
  if (!product.responsibleLawyer) {
    return {
      allowed: false,
      message: "Anfrage an Schufa kann nur gestellt werden, wenn ein Anwalt am Fall hinterlegt wurde.",
    };
  }
  return { allowed: true };
};
