import customerTranslations from "./customerTranslations";
import documentTranslations from "./documentTranslations";
import personTranslations from "./personTranslations";
import insuranceTranslations from "./insuranceTranslations";
import abstractCaseTranslations from "./abstractCaseTranslations";

const deathTranslations = {
  label: "Erstberatung Erbrecht/Todesfall",
  customer: customerTranslations,
  ...documentTranslations,
  petitioner: personTranslations,
  client: personTranslations,
  insurance: insuranceTranslations,
  ...abstractCaseTranslations,
  generalCategory: {
    label: "Informationen zu",
    values: {
      death: "Todesfall",
      inheritance: "Erbe",
      both: "Todesfall & Erbe",
    },
  },
  deathCategory: {
    label: "Todesfall aktuell?",
    values: {
      current: "Aktuell",
      preparation: "Vorbereitung",
    },
  },
  personalTextMessage: {
    label: "Fragen und Anmerkungen zum Fall",
  },
  inheritanceCategory: {
    label: "Erbe oder Vererben?",
    values: {
      heir: "Erbe",
      bequeather: "Vererben",
    },
  },
  advisoryAppointment: {
    label: "Beratungsgespräch",
  },
};

export default deathTranslations;
