import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import React, { useState } from "react";
import ApiClient from "../../../services/ApiClient";
import { getProductData } from "../../../services/Product/ProductService";
import PropTypes from "prop-types";

function DocumentResetModal({ product, open, handleClose, refreshPage }) {
  const [isLoading, setIsLoading] = useState(false);

  const resetDocument = async () => {
    setIsLoading(true);
    const uri = getProductData(product.productClassName, "apiUri");
    await ApiClient.put(uri + "/" + product.id, {
      body: JSON.stringify({
        reset: true,
      }),
    });
    refreshPage();
    handleClose();
  };

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={"Dokument zurücksetzen?"}
      submitButton={
        <ButtonLoading onClick={resetDocument} color="primary" variant={"contained"} isLoading={isLoading}>
          Dokument zurücksetzen
        </ButtonLoading>
      }
    >
      <p className={"center"}>Wollen Sie das Dokument wirklich zurücksetzen?</p>
    </LegalbirdIoModal>
  );
}
DocumentResetModal.propTypes = {
  product: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refreshPage: PropTypes.func,
};

DocumentResetModal.defaultProps = {
  refreshPage: () => {},
};
export default DocumentResetModal;
