import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { Box, Checkbox, List, ListItem, Stack } from "@mui/material";
import _ from "lodash";
import { Folder, FolderOpen } from "@mui/icons-material";
import MediaObject from "./MediaObject";
import DisplayMediaObjects from "../MediaObject/DisplayMediaObjects";
import { queryKeys } from "../../services/ReactQuery/reactQueryService";
import { useQueryClient } from "@tanstack/react-query";

export default function MediaObjectFolderView({
  product,
  mediaObjects,
  selectableView,
  selectedMediaObjects,
  setSelectedMediaObjects,
}) {
  const [openFolderStructure, setOpenFolderStructure] = useState([]);
  const [mediaObjectToDisplay, setMediaObjectToDisplay] = useState(null);
  const queryClient = useQueryClient();

  const handleFolderClick = (path) => {
    if (_.includes(openFolderStructure, path)) {
      setOpenFolderStructure(_.filter(openFolderStructure, (entry) => entry !== path));
      return;
    }
    setOpenFolderStructure([...openFolderStructure, path]);
  };

  const refreshFiles = async () => {
    await queryClient.invalidateQueries(queryKeys.collections("media_objects"));
  };

  const fileTree = convertMediaObjectsToFileBrowserData(mediaObjects);

  const getFolderSelectionStatus = (node) => {
    const childrenIdArray = _.map(node.children, (childNode) => childNode.id);

    switch (true) {
      case _.difference(childrenIdArray, selectedMediaObjects).length === 0:
        return "fullySelected";
      case _.intersection(childrenIdArray, selectedMediaObjects).length > 0:
        return "partlySelected";
      default:
        return "nothingSelected";
    }
  };

  const deselectFolderContent = (node) => {
    setSelectedMediaObjects(
      _.filter(
        selectedMediaObjects,
        (mediaObjectId) => !_.find(node.children, (childNode) => childNode.id === mediaObjectId)
      )
    );
  };

  const selectFolderContent = (node) => {
    let newSelectedMediaObjects = [...selectedMediaObjects];
    _.forEach(node.children, (childNode) => newSelectedMediaObjects.push(childNode.id));
    setSelectedMediaObjects(_.uniq(newSelectedMediaObjects));
  };

  const handleCheckBoxClick = (type, node) => {
    switch (type) {
      case "mediaObject":
        if (_.includes(selectedMediaObjects, node.id)) {
          setSelectedMediaObjects(_.filter(selectedMediaObjects, (id) => id !== node.id));
          break;
        }
        setSelectedMediaObjects([...selectedMediaObjects, node.id]);
        break;
      case "folder":
      default:
        if (getFolderSelectionStatus(node) === "fullySelected") {
          deselectFolderContent(node);
          break;
        }
        selectFolderContent(node);
        break;
    }
  };

  const getIndentation = (indentationLevel) => {
    return <div style={{ marginLeft: indentationLevel + "rem", display: "inline-block" }} />;
  };

  const renderTree = (nodes, indentationLevel = 0, path) =>
    _.map(nodes, (node) => {
      if (node.type === "folder") {
        const nodePath = path + "/" + node.name;
        const isOpen = _.includes(openFolderStructure, nodePath);
        const folderSelectionStatus = selectableView && getFolderSelectionStatus(node);
        const hasChildren = node.children.length !== 0;
        return (
          <React.Fragment key={nodePath}>
            <ListItem divider sx={{ minHeight: 60 }}>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                {getIndentation(indentationLevel)}
                {selectableView && (
                  <Checkbox
                    disabled={!hasChildren}
                    checked={hasChildren && folderSelectionStatus === "fullySelected"}
                    indeterminate={hasChildren && folderSelectionStatus === "partlySelected"}
                    onClick={() => handleCheckBoxClick("folder", node)}
                  />
                )}
                {isOpen ? <FolderOpen /> : <Folder />}
                <Box sx={{ fontSize: "1rem", cursor: "pointer" }} onClick={() => handleFolderClick(nodePath)}>
                  {node.name} ({node.children?.length})
                </Box>
              </Stack>
            </ListItem>
            {node.children.length > 0 && isOpen && (
              <>
                {renderTree(node.children, indentationLevel + 1, nodePath)}
                <DisplayMediaObjects
                  mediaObjectToDisplay={mediaObjectToDisplay}
                  mediaObjects={node.children}
                  setMediaObjectToDisplay={setMediaObjectToDisplay}
                />
              </>
            )}
          </React.Fragment>
        );
      }

      return (
        <ListItem key={node.id} divider sx={{ cursor: "pointer" }}>
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            {getIndentation(indentationLevel)}
            {selectableView && (
              <Checkbox
                checked={_.includes(selectedMediaObjects, node.id)}
                onClick={() => handleCheckBoxClick("mediaObject", node)}
              />
            )}
            <MediaObject
              mediaObject={node}
              updateMediaObjects={refreshFiles}
              handleOpenMediaObject={(mediaobject) => setMediaObjectToDisplay(mediaobject)}
              folderStructureView
            />
          </Stack>
        </ListItem>
      );
    });

  return <List>{renderTree(fileTree)}</List>;
}

const convertMediaObjectsToFileBrowserData = (mediaObjects) => {
  let fileStructure = [
    {
      name: "Mandant",
      type: "folder",
      children: [],
    },
    {
      name: "Gerichtliches Verfahren",
      type: "folder",
      children: [],
    },
    {
      name: "Außergerichtliches Verfahren",
      type: "folder",
      children: [],
    },
    {
      name: "RSV",
      type: "folder",
      children: [],
    },
    {
      name: "ToDo",
      type: "folder",
      children: [],
    },
    {
      name: "Rechnungen",
      type: "folder",
      children: [],
    },
    {
      name: "Sonstiges",
      type: "folder",
      children: [],
    },
  ];

  _.forEach(mediaObjects, (mediaObject) => {
    if (mediaObject.electronicFileFolderPath) {
      const folderInFileStructure = _.find(fileStructure, (node) => node.name === mediaObject.electronicFileFolderPath);
      if (!folderInFileStructure) {
        fileStructure.push({
          name: mediaObject.electronicFileFolderPath,
          type: "folder",
          children: [mediaObject],
        });
      } else {
        folderInFileStructure.children.push(mediaObject);
      }
    }
  });

  return fileStructure;

  // working without mediaobject content, may be refined to contain the needed stuff
  /*let fileTree = [];
  let level = {fileTree}
  let paths = [];

  _.forEach(electronicFileMediaObjects, electronicFileMediaObject => {
      paths.push((electronicFileMediaObject.electronicFileFolderPath ? electronicFileMediaObject.electronicFileFolderPath + "/" : "") + electronicFileMediaObject.originalName);
  });

  paths.forEach(path => {
    path.split('/').reduce((r, name) => {
      if(!r[name]) {
        r[name] = {fileTree: []};
        r.fileTree.push({name, children: r[name].fileTree, isFolder: !_.includes(name, ".")})
      }
      return r[name];
    }, level)
  });

  return fileTree;*/
};

MediaObjectFolderView.propTypes = {
  product: PropTypes.object.isRequired,
  mediaObjects: PropTypes.array.isRequired,
};

MediaObjectFolderView.defaultProps = {};
