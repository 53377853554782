import _ from "lodash";
import iconImage from "../assets/icon/collections-24px.svg";
import iconPdf from "../assets/icon/picture_as_pdf-24px.svg";
import iconAttachment from "../assets/icon/attach_file-24px.svg";

export const filterByFolder = (mediaObjects, folder) => {
  return _.filter(mediaObjects, mediaObject => {
    return !_.startsWith(mediaObject.electronicFileFolderPath, folder);
  });
};

export const getIcon = (mediaObject) => {
  switch (mediaObject.mimeType) {
    case "image/jpg":
    case "image/jpeg":
    case "image/png":
      return iconImage;
    case "application/pdf":
      return iconPdf;
    default:
      return iconAttachment;
  }
};


export const getMediaObjectFilters = (product, currentUser) => {
  let mediaObjectsFilter = {
    product: product.productClassName,
    productId: product.id,
  };

  if (currentUser.isExternal) {
    mediaObjectsFilter.showInExternalView = true;
  }
  return mediaObjectsFilter;
};
