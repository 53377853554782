import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import _ from "lodash";
import { PaidTask } from "../../../types/PaidTask";
import ExternalPaidTaskTableRow from "./ExternalPaidTaskTableRow";

interface ExternalPaidTaskTableProps {
  paidTasks: PaidTask[];
  isMinimalView?: boolean;
  isRequestedView?: boolean;
  noPaidTasksText?: string;
}

const PaidTaskTable = ({
  paidTasks,
  isMinimalView = false,
  isRequestedView = false,
  noPaidTasksText = "Keine Aufgaben",
}: ExternalPaidTaskTableProps) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Fall</TableCell>
            <TableCell>Rechtsprodukt</TableCell>
            <TableCell>Aufgabe</TableCell>
            {!isMinimalView && <TableCell>Honorar</TableCell>}
            {isRequestedView && <TableCell>Annahme bis</TableCell>}
            <TableCell>Erledigung bis</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {paidTasks.length === 0 ? (
            <TableRow>
              <TableCell colSpan={10} align={"center"}>
                {noPaidTasksText}
              </TableCell>
            </TableRow>
          ) : (
            _.map(paidTasks, (paidTask) => (
              <ExternalPaidTaskTableRow key={paidTask.id} paidTask={paidTask} isMinimalView={isMinimalView} isRequestedView={isRequestedView}/>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default PaidTaskTable;
