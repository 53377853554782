import makeStyles from '@mui/styles/makeStyles';

const useStylesFileGeneratePage = makeStyles(() => ({
  smallHeadline: {
    textAlign: "center",
    fontSize: "1rem",
  },
  headlineName: {
    textAlign: "center",
  },
  heading: {
    marginTop: "3rem",
    marginBottom: "3rem",
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 200,
    marginBottom: 200,
  },
}));

export default useStylesFileGeneratePage;
