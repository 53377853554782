import ActivityNote from "../../../Activities/ActivityNote";
import React from "react";
import { makeStyles } from "@mui/styles";
import paidTaskHistoryLogStyle from "./paidTaskHistoryLogStyle";
import moment from "moment";
import _get from "lodash/get";
import * as PropTypes from "prop-types";
import PaidTaskCaseHead from "../../../PaidTask/PaidTasksCase/PaidTaskCaseHead";

const useStyles = makeStyles(paidTaskHistoryLogStyle);
const PaidTaskHistoryLog = ({ paidTask }) => {
  const classes = useStyles();

  return (
    <div className={paidTask.deletedDate ? classes.outerPaidTaskContainer : null}>
      <PaidTaskCaseHead paidTask={paidTask} />
      <div className={classes.assignmentInfo}>
        {paidTask.markedAsDoneDate && (
          <>
            Erledigt {moment(paidTask.markedAsDoneDate).format("DD.MM.YYYY")} |{" "}
            {_get(paidTask, "assignee.person.fullname")}
            <br />
          </>
        )}
        {paidTask.approvedDate && (
          <>
            Abgenommen {moment(paidTask.approvedDate).format("DD.MM.YYYY")} |{" "}
            {_get(paidTask, "approvedBy.person.fullname")}
          </>
        )}
      </div>
      <ActivityNote noteContent={paidTask.description} />
      {paidTask.deletedDate && (
        <>
          <div className={classes.overlay} />
          <div className={classes.deletedInfo}>Gelöscht am {moment(paidTask.deletedDate).format("DD.MM.YYYY")}</div>
        </>
      )}
    </div>
  );
};

PaidTaskHistoryLog.propTypes = {
  paidTask: PropTypes.object,
};

export default PaidTaskHistoryLog;
