const mediaObjectStyle = {
  headline: {
    fontSize: "1rem",
  },
  mediaObjectName: {
    fontSize: "1rem",
    color: "#317ae2",
    cursor: "pointer",
    display: "inline-block",
    wordBreak: "break-all",
  },
  bold: {
    fontWeight: 700,
  },
  icon: {
    paddingRight: "2rem",
  },
  description: {
    fontSize: "1rem",
  },
  item: {
    flexGrow: 1,
  },
};

export default mediaObjectStyle;
