import React from "react";
import useNotifications from "../../../hooks/useNotifications";
import InfoBar from "../../../components/TechStatus/InfoBar";
import GeneralActions from "../../Layout/GeneralActions";
import NavigationBar from "../NavigationBar/NavigationBar";
import { Box } from "@mui/material";
import GlobalProvider from "../../../components/Provider/GlobalProvider";

const Layout = ({ children }) => {
  useNotifications();
  return (
    <GlobalProvider>
      <InfoBar />
      <NavigationBar />
      <Box
        sx={{
          margin: "2rem 1rem 5rem 1rem",
        }}
      >
        {children}
      </Box>
      <GeneralActions />
    </GlobalProvider>
  );
};

export default Layout;
