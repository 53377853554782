import React from "react";
import * as PropTypes from "prop-types";
import { submitButtonStyles } from "./submitButtonStyles";
import ButtonLoading from "./ButtonLoading";
import { Box } from "@mui/material";

const SubmitButton = ({ error = false, disabled = false, children, sx = {}, ...rest }) => {
  return (
    <Box sx={sx}>
      {error && (
        <Box sx={submitButtonStyles.error}>
          Leider sind Ihre Angaben noch nicht richtig oder vollständig. Bitte prüfen Sie Ihre Angaben oben.
        </Box>
      )}
      <ButtonLoading sx={submitButtonStyles.button} disabled={disabled || error} {...rest}>
        {children}
      </ButtonLoading>
    </Box>
  );
};

SubmitButton.propTypes = {
  error: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  type: PropTypes.string,
  sx: PropTypes.object,
};

SubmitButton.defaultProps = {};

export default SubmitButton;
