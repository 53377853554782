import * as status from "../LeadStatus/StatusTraffic";
import _ from "lodash";

const STAGE_INCOMPLETE = 0;
const STAGE_READY = 10;
const STAGE_CHECKED = 20;
const STAGE_AUTHORIZED = 23;
const STAGE_INSURANCE_COVERAGE_REQUEST = 26;
const STAGE_ACCESS_TO_RECORDS = 30;
const STAGE_EXTRAJUDICIAL_PAID = 40;
const STAGE_OPINION_SUBMITTED = 50;
const STAGE_AUTHORITY_ANSWERED = 60;
const STAGE_TRIAL = 70;
const STAGE_TRIAL_DATE_SET = 75;
const STAGE_TRIAL_PAID = 80;
const STAGE_DECISION_MADE = 90;
const STAGE_COMPLETED = 100;

export {
  STAGE_INCOMPLETE,
  STAGE_READY,
  STAGE_CHECKED,
  STAGE_AUTHORIZED,
  STAGE_INSURANCE_COVERAGE_REQUEST,
  STAGE_ACCESS_TO_RECORDS,
  STAGE_EXTRAJUDICIAL_PAID,
  STAGE_OPINION_SUBMITTED,
  STAGE_AUTHORITY_ANSWERED,
  STAGE_TRIAL,
  STAGE_TRIAL_DATE_SET,
  STAGE_TRIAL_PAID,
  STAGE_DECISION_MADE,
  STAGE_COMPLETED,
};

export const stageMetaDataTraffic = {
  [STAGE_INCOMPLETE]: {
    label: "Daten vollständig",
    stage: STAGE_INCOMPLETE,
    leadStatus: status.STATUS_DOCUMENTS_COMPLETE,
    isActive: () => true,
    isClickable: () => false,
    showInBoard: true,
  },
  [STAGE_READY]: {
    label: "Vollmacht erteilt",
    stage: STAGE_READY,
    leadStatus: status.STATUS_POWER_OF_ATTORNEY_GRANTED,
    isActive: () => true,
    isClickable: ({ product }) => product.stage >= STAGE_CHECKED,
    showInBoard: true,
  },
  [STAGE_CHECKED]: {
    label: "Überprüft",
    stage: STAGE_CHECKED,
    leadStatus: status.STATUS_CHECKED,
    isActive: () => true,
    isClickable: ({ product }) => product.stage === STAGE_READY || product.stage >= STAGE_AUTHORIZED,
    showInBoard: true,
  },
  [STAGE_AUTHORIZED]: {
    label: "Bestellt",
    stage: STAGE_AUTHORIZED,
    leadStatus: status.STATUS_AUTHORIZED,
    isActive: ({ product }) => !product.fineNoticeReceivedDate,
    isClickable: ({ product }) => product.stage === STAGE_CHECKED || product.stage >= STAGE_INSURANCE_COVERAGE_REQUEST,
    showInBoard: true,
  },
  [STAGE_INSURANCE_COVERAGE_REQUEST]: {
    label: "Deckungsanfrage",
    stage: STAGE_INSURANCE_COVERAGE_REQUEST,
    leadStatus: status.STATUS_INSURANCE_COVERAGE_REQUEST,
    isActive: ({ product }) => product.paymentType !== "private",
    isClickable: ({ product }) =>
      product.paymentType !== "private" &&
      ([STAGE_CHECKED, STAGE_AUTHORIZED].includes(product.stage) || product.stage > STAGE_INSURANCE_COVERAGE_REQUEST),
    showInBoard: true,
  },
  [STAGE_ACCESS_TO_RECORDS]: {
    label: "Einspruch/Akteneinsicht",
    stage: STAGE_ACCESS_TO_RECORDS,
    leadStatus: status.STATUS_ACCESS_TO_RECORDS,
    isActive: ({ product }) => product.appealRevoked === null && !product.processStoppedDate,
    isClickable: ({ product }) =>
      product.appealRevoked === null &&
      !product.processStoppedDate &&
      (product.stage === STAGE_INSURANCE_COVERAGE_REQUEST ||
        (product.paymentType !== "insurance" && product.stage === STAGE_CHECKED) ||
        (product.paymentType === "private" && product.stage === STAGE_AUTHORIZED) ||
        product.stage > STAGE_ACCESS_TO_RECORDS),
    showInBoard: true,
  },
  [STAGE_EXTRAJUDICIAL_PAID]: {
    label: "Kostendeckung RSV",
    stage: STAGE_EXTRAJUDICIAL_PAID,
    leadStatus: status.STATUS_EXTRAJUDICIAL_PAID,
    isActive: ({ product }) =>
      product.appealRevoked === null &&
      !["advocard", "arag"].includes(_.get(product, "acquisitionPartner")) &&
      !product.processStoppedDate &&
      product.paymentType === "insurance",
    isClickable: ({ product }) =>
      product.appealRevoked === null &&
      !product.processStoppedDate &&
      (product.stage === STAGE_ACCESS_TO_RECORDS || product.stage >= STAGE_OPINION_SUBMITTED) &&
      !["advocard", "arag"].includes(_.get(product, "acquisitionPartner")) &&
      product.paymentType === "insurance",
    showInBoard: true,
  },
  [STAGE_OPINION_SUBMITTED]: {
    label: "Stellungnahme",
    stage: STAGE_OPINION_SUBMITTED,
    leadStatus: status.STATUS_OPINION_SUBMITTED,
    isActive: ({ product }) =>
      product.appealRevoked === null && !product.processStoppedDate && product.opinionRequired !== false,
    isClickable: ({ product }) =>
      product.appealRevoked === null &&
      !product.processStoppedDate &&
      product.opinionRequired !== false &&
      (product.stage === STAGE_EXTRAJUDICIAL_PAID ||
        (product.stage === STAGE_ACCESS_TO_RECORDS &&
          (["advocard", "arag"].includes(_.get(product, "acquisitionPartner")) || product.paymentType === "private")) ||
        product.stage >= STAGE_AUTHORITY_ANSWERED),
    showInBoard: true,
  },
  [STAGE_AUTHORITY_ANSWERED]: {
    label: "Antwort Behörde",
    stage: STAGE_AUTHORITY_ANSWERED,
    leadStatus: status.STATUS_AUTHORITY_ANSWERED,
    isActive: ({ product }) =>
      product.appealRevoked === null && !product.processStoppedDate && product.authorityDecision !== "changed",
    isClickable: ({ product }) =>
      product.appealRevoked === null &&
      !product.processStoppedDate &&
      product.authorityDecision !== "changed" &&
      (product.stage === STAGE_OPINION_SUBMITTED ||
        product.stage >= STAGE_TRIAL ||
        (_.includes([STAGE_ACCESS_TO_RECORDS, STAGE_EXTRAJUDICIAL_PAID], product.stage) &&
          product.opinionRequired !== true)),
    showInBoard: true,
  },
  [STAGE_TRIAL]: {
    label: "Gerichtsverfahren",
    stage: STAGE_TRIAL,
    leadStatus: status.STATUS_TRIAL,
    isActive: ({ product }) =>
      !(
        product.appealRevoked !== null ||
        (product.authorityDecision !== "court" && product.authorityDecision !== null) ||
        product.trialWanted === "no" ||
        product.processStoppedDate
      ),
    isClickable: ({ product }) => {
      if (
        product.appealRevoked !== null ||
        product.processStoppedDate ||
        product.authorityDecision !== "court" ||
        product.trialWanted === "no"
      ) {
        return false;
      }
      return product.stage === STAGE_AUTHORITY_ANSWERED || product.stage >= STAGE_TRIAL_DATE_SET;
    },
    showInBoard: true,
  },
  [STAGE_TRIAL_DATE_SET]: {
    label: "Gerichtstermin",
    stage: STAGE_TRIAL_DATE_SET,
    leadStatus: status.STATUS_TRIAL_DATE_SET,
    isActive: ({ product }) =>
      !(
        product.appealRevoked !== null ||
        (product.authorityDecision !== "court" && product.authorityDecision !== null) ||
        (product.courtNotice !== "trial" && product.courtNotice !== null) ||
        product.trialWanted === "no" ||
        product.processStoppedDate
      ),
    isClickable: ({ product }) => {
      if (
        product.appealRevoked !== null ||
        product.processStoppedDate ||
        product.authorityDecision !== "court" ||
        (product.courtNotice !== null && product.courtNotice !== "trial")
      ) {
        return false;
      }
      return product.stage === STAGE_TRIAL || product.stage >= STAGE_TRIAL_PAID;
    },
    showInBoard: true,
  },
  [STAGE_TRIAL_PAID]: {
    label: "Selbstzahler Kosten bezahlt",
    stage: STAGE_TRIAL_PAID,
    leadStatus: status.STATUS_TRIAL_PAID,
    isActive: ({ product }) =>
      product.appealRevoked === null &&
      product.authorityDecision === "court" &&
      product.courtNotice === "trial" &&
      product.paymentType === "private" &&
      !product.processStoppedDate,
    isClickable: ({ product }) => {
      if (
        product.appealRevoked !== null ||
        product.processStoppedDate ||
        product.authorityDecision !== "court" ||
        product.courtNotice !== "trial" ||
        product.paymentType !== "private"
      ) {
        return false;
      }
      return product.stage === STAGE_TRIAL_DATE_SET || product.stage >= STAGE_DECISION_MADE;
    },
    showInBoard: true,
  },
  [STAGE_DECISION_MADE]: {
    label: "Entscheidung gefällt",
    stage: STAGE_DECISION_MADE,
    leadStatus: status.STATUS_DECISION_MADE,
    isActive: () => true,
    isClickable: ({ product }) => {
      if (
        product.appealRevoked !== null ||
        (product.authorityDecision !== null && product.authorityDecision !== "court") ||
        (product.courtNotice !== null && product.courtNotice !== "trial") ||
        product.trialWanted !== "yes" ||
        product.processStoppedDate
      ) {
        return product.stage >= STAGE_CHECKED && product.stage !== STAGE_DECISION_MADE;
      }
      return product.stage >= STAGE_TRIAL_DATE_SET && product.stage !== STAGE_DECISION_MADE;
    },
    showInBoard: true,
  },
  [STAGE_COMPLETED]: {
    label: "Abgeschlossen",
    stage: STAGE_COMPLETED,
    leadStatus: status.STATUS_COMPLETED,
    isActive: () => true,
    isClickable: ({ product }) => product.stage === STAGE_DECISION_MADE,
    showInBoard: true,
  },
};
