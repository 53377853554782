import CircularProgress from "@mui/material/CircularProgress";
import { Checkbox, Tooltip } from "@mui/material";
import React from "react";

interface ActivityDoneCheckBoxProps {
  isLoading: boolean;
  toggleActivityDone: (...args: any[]) => any;
  activityDone: boolean;
  isCheckboxDisabled: boolean;
}

const ActivityDoneCheckBox = ({
  isLoading,
  toggleActivityDone,
  activityDone,
  isCheckboxDisabled,
}: ActivityDoneCheckBoxProps) => {
  if (isLoading) {
    return <CircularProgress size={20} />;
  }
  return (
    <Tooltip title={"Als erledigt markieren"}>
      <Checkbox onChange={toggleActivityDone} checked={activityDone} disabled={isCheckboxDisabled} />
    </Tooltip>
  );
};
export default ActivityDoneCheckBox;
