import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  headline: {
    textAlign: "center",
    fontSize: "1.25rem",
  },
  availabilityStatus: {
    marginBottom: ".5rem",
    color: "#ce7c12",
  },
  dialogContainer: {
    padding: "1rem",
    maxWidth: 700,
  },
  checkbox: {
    color: "#137f7b",
  },
  centered: {
    display: "block",
    width: 100,
    margin: "1rem auto 0 auto",
  },
  activitiesChosenDay: {
    textAlign: "center",
    padding: "1rem",
    fontSize: "1rem",
  },
  editor: {
    minHeight: 250,
    backgroundColor: "#f8f4d0",
  },
  activityForm: {
    padding: "1rem",
    [theme.breakpoints.up("sm")]: {
      borderRight: "1px solid #000",
    },
  },
  activity: {
    fontSize: ".75rem",
    textAlign: "left",
    margin: ".5rem",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  activityGridItem: {
    padding: "0 1rem",
  },
}));

export default useStyles;
