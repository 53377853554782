import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./activityOverviewHeaderStyles";
import Grid from "@mui/material/Grid";
import ProductFilter from "../../../components/Activities/ActivityOverviewHeader/ProductFilter/ProductFilter";
import ActivityTypeFilter from "../../../components/Activities/ActivityOverviewHeader/ActivityTypeFilter/ActivityTypeFilter";
import UserFilter from "../../../components/Activities/ActivityOverviewHeader/UserFilter/UserFilter";
import { MenuItem, Select } from "@mui/material";
import _ from "lodash";
import { ACTIVITY_OVERDUE } from "../../../services/Stages/Stages";
import ValidatorSelect from "../../../components/Validator/ValidatorSelect";

const ActivityOverviewHeader = ({
  productFilter,
  isDeadlineView,
  setProductFilter,
  activityTypeFilter,
  setActivityTypeFilter,
  userFilter,
  setUserFilter,
  timespanFilter,
  setTimespanFilter,
  agents,
  userActivitiesToday,
  isLoading,
  openOrDoneFilter = null,
  setOpenOrDoneFilter = null,
}) => {
  const isCompletedView = openOrDoneFilter === "done";
  const classes = useStyles();
  const userActivitiesOverdue = _.filter(
    userActivitiesToday,
    (activity) => activity.activityStatus === ACTIVITY_OVERDUE
  );

  if (
    (isCompletedView && !["today", "yesterday"].includes(timespanFilter)) ||
    (!isCompletedView && timespanFilter === "yesterday")
  ) {
    setTimespanFilter("today");
  }
  return (
    <Grid container className={classes.filterContainer} spacing={2} alignItems={"center"}>
      <Grid item xs={6} />
      {!isDeadlineView && (
        <Grid item xs={12}>
          <ValidatorSelect
            label="Status"
            onChange={({ target }) => {
              setOpenOrDoneFilter(target.value);
            }}
            value={openOrDoneFilter}
            fullWidth
          >
            <MenuItem value={"open"}>Offen</MenuItem>
            <MenuItem value={"done"}>Erledigt</MenuItem>
          </ValidatorSelect>
        </Grid>
      )}
      <Grid item xs={12}>
        <ProductFilter productFilter={productFilter} setProductFilter={setProductFilter} />
      </Grid>
      <Grid item xs={12}>
        <ActivityTypeFilter
          activityTypeFilter={activityTypeFilter}
          setActivityTypeFilter={setActivityTypeFilter}
          isDeadlineView={isDeadlineView}
        />
      </Grid>
      <Grid item xs={12}>
        <UserFilter
          userFilter={userFilter}
          setUserFilter={setUserFilter}
          agents={agents}
          isDeadlineView={isDeadlineView}
        />
      </Grid>
      <Grid item xs={12}>
        <Select value={timespanFilter} onChange={(event) => setTimespanFilter(event.target.value)} fullWidth>
          {!isCompletedView && <MenuItem value={"overdue"}>Überfällig</MenuItem>}
          {isCompletedView && <MenuItem value={"yesterday"}>Gestern</MenuItem>}
          <MenuItem value={"today"}>Heute</MenuItem>
          {!isCompletedView && <MenuItem value={"tomorrow"}>Morgen</MenuItem>}
        </Select>
      </Grid>
      <Grid item xs={6} className={classes.activitiesToday}>
        {userFilter !== "all" && !isLoading && <span>Heute noch: {userActivitiesToday.length}</span>}
      </Grid>
      <Grid item xs={6} className={classes.activitiesOverdue}>
        {userFilter !== "all" && !isLoading && <span>Davon überfällig: {userActivitiesOverdue.length}</span>}
      </Grid>
    </Grid>
  );
};

ActivityOverviewHeader.propTypes = {
  activityCount: PropTypes.number,
  productFilter: PropTypes.string.isRequired,
  setProductFilter: PropTypes.func.isRequired,
  userFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setUserFilter: PropTypes.func.isRequired,
  timespanFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  setTimespanFilter: PropTypes.func.isRequired,
  userActivitiesToday: PropTypes.array,
  isLoading: PropTypes.bool,
  isDeadlineView: PropTypes.bool,
  openOrClosedFilter: PropTypes.string,
  setOpenOrClosedFilter: PropTypes.func,
};

export default ActivityOverviewHeader;
