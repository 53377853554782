import React from "react";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { BeaMessage } from "../../types/Bea/BeaMessage";
import { Link } from "react-router-dom";

type BeaMessagesTableProps = {
  beaMessages: Array<BeaMessage>;
  hasLink?: boolean;
};
export default function BeaMessagesTable({ beaMessages, hasLink = false }: BeaMessagesTableProps) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Datum</TableCell>
          <TableCell>Betreff</TableCell>
          <TableCell>Absender</TableCell>
          <TableCell>Empfänger</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {_.map(beaMessages, (beaMessage) => (
          <TableRow key={beaMessage.id}>
            <TableCell>{moment(beaMessage.created).format("DD.MM.YYYY, HH:mm")} Uhr</TableCell>
            <TableCell>{beaMessage.subject}</TableCell>
            <TableCell>{_.trimStart(`${beaMessage.sender?.firstname} ${beaMessage.sender?.surname}`)}</TableCell>
            <TableCell>{_.trimStart(`${beaMessage.recipient?.firstname} ${beaMessage.recipient?.surname}`)}</TableCell>
            <TableCell>{hasLink && <Button component={Link} to={"/services/tools/bea/" + beaMessage.id}>Anhänge verarbeiten</Button>}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
