import React, { useMemo, useState } from "react";
import { getRoleToDisplay, sortByRole, userHasRole } from "../../services/backofficeUserService";
import ContentBox from "../ContentBox/ContentBox";
import _ from "lodash";
import { BackofficeUser } from "../../types/BackofficeUser";
import { useBackofficeUser } from "../../provider/BackofficeUserProvider";
import LegalbirdAutoComplete from "../AutoComplete/LegalbirdAutoComplete";
import ButtonLoading from "../Button/ButtonLoading";
import { Navigate, useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../services/ReactQuery/reactQueryService";

interface AutoCompleteOption {
  label: string;
  id: string;
  groupBy?: string;
}

const getOptions = (backofficeUsers: Array<BackofficeUser>): Array<AutoCompleteOption> => {
  return _.map(sortByRole(backofficeUsers), (backofficeUser) => {
    return {
      label: backofficeUser.person.fullname,
      id: backofficeUser.email,
      groupBy: getRoleToDisplay(backofficeUser),
    };
  });
};

export default function Impersonate() {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState<AutoCompleteOption | null | undefined>(null);
  const { backofficeUsers } = useBackofficeUser();
  const queryClient = useQueryClient();

  const handleImpersonate = (impersonateUser: string | undefined) => {
    if (!impersonateUser) {
      return;
    }
    sessionStorage.setItem("impersonate", impersonateUser);
    queryClient.invalidateQueries(queryKeys.all("current_user")).then(() => navigate("/"));
  };

  const clearImpersonate = () => {
    sessionStorage.removeItem("impersonate");
    queryClient.invalidateQueries(queryKeys.all("current_user"));
  };

  const options = useMemo(() => {
    return getOptions(backofficeUsers);
  }, [backofficeUsers]);

  if (!userHasRole(currentUser, "ROLE_ADMIN") && !sessionStorage.getItem("impersonate")) {
    return <Navigate to={"/"} />;
  }

  if (sessionStorage.getItem("impersonate")) {
    return (
      <ContentBox headline={"Benutzeransicht beenden"}>
        <ButtonLoading variant={"contained"} onClick={() => clearImpersonate()}>
          Nutzeransicht "{currentUser.email}" beenden
        </ButtonLoading>
      </ContentBox>
    );
  }

  return (
    <ContentBox headline={"Benutzeransicht wechseln"}>
      <LegalbirdAutoComplete
        value={selectedUser}
        options={options}
        groupBy={(option: AutoCompleteOption) => option.groupBy}
        setValue={(event: any, value: any) => {
          setSelectedUser(_.find(options, (option) => option.id === value.id));
        }}
        label={"Nutzer"}
      />
      <ButtonLoading
        variant={"contained"}
        disabled={!selectedUser}
        onClick={() => handleImpersonate(selectedUser?.id)}
      >
        Nutzeransicht {selectedUser?.id} starten
      </ButtonLoading>
    </ContentBox>
  );
}
