const externals404PageStyle = {
  grey: {
    backgroundColor: "#f4f4f4",
  },
  noAccessBox: {
    maxWidth: 550,
    margin: "0 auto",
    textAlign: "center",
    padding: "2rem",
  },
};
export default externals404PageStyle;
