const previewButtonStyle = {
  detailsButton: {
    marginTop: "1rem",
  },
  embedContainer: {
    minWidth: 880,
    width: "100%",
    height: "80vh",
  },
  imageContainer: {
    maxHeight: "85vh",
    overflow: "auto",
  },
};

export default previewButtonStyle;
