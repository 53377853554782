import { trueFalseDefaultOptions } from "./formDefinitionFunctions";
import InputAdornment from "@mui/material/InputAdornment";
import React from "react";
import * as stagesCase from "../../Stages/StagesCase";

const vehicleProductSpecificDefinition = (product) => [
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.reasonForDispute`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [{ value: "other" }],
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.buyerOrSeller`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [{ value: "buyer" }, { value: "seller" }],
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.vehicleMake`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.model`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.vehicleId`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.mileageAtPurchase`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.currentMileage`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.expectedTotalMileage`,
    options: () => [{ value: "150000" }, { value: "200000" }, { value: "250000" }, { value: "300000" }],
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.purchasePrice`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    additionalProps: {
      InputProps: {
        endAdornment: <InputAdornment position="start">€</InputAdornment>,
      },
    },
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.locationOfGood`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [{ value: "buyer" }, { value: "seller" }, { value: "elsewhere" }, { value: "unknown" }],
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.hasDefects`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => trueFalseDefaultOptions(`${product.productClassName}.hasDefects`),
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.isAccidentCar`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => trueFalseDefaultOptions(`${product.productClassName}.isAccidentCar`),
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.defectsRecognitionDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ product }) => product.hasDefects !== true,
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.defectsDescriptionClient`,
    isDisabled: () => true,
    isHidden: ({ product }) => product.hasDefects !== true,
    additionalProps: {
      multiline: true,
      fullWidth: true,
    },
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.numberRepairAttempts`,
    options: () => [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }],
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ product }) => product.hasDefects !== true,
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.numberReplacementAttempts`,
    options: () => [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }],
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ product }) => product.hasDefects !== true,
  },
];
export default vehicleProductSpecificDefinition;
