const activityActionsStyles = {
  deleteDialog: {
    padding: "2rem",
  },
  deleteDialogHeadline: {
    textAlign: "center",
    fontSize: "1.25rem",
    marginBottom: "1rem",
  },
  deleteDialogText: {
    textAlign: "center",
    marginBottom: "1rem",
  },
  editMenu: {
    width: "50%",
  },
  doneButton: {
    fontSize: 10,
    padding: "3px",
  },
};

export default activityActionsStyles;
