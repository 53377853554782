import React, { useState } from "react";
import PageContentContainer from "../../../Container/PageContentContainer";
import CaseOverviewPageLoader from "../../../ContentLoader/CaseOverviewPageLoader";
import CaseNotFound from "../../../Case/CaseNotFound/CaseNotFound";
import CaseError from "../../../Case/CaseError/CaseError";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import CaseRelatedActions from "../../../Case/CaseRelatedActions/CaseRelatedActions";
import CaseProvider, { useCase } from "../../../../provider/CaseProvider";
import CaseHeader from "../../../Callcenter/Case/CaseHeader";
import Contact from "../../../Callcenter/Case/Contact";
import Activities from "../../../Callcenter/Case/Activities";

const CallcenterCasePage = () => {
  const { productId, productUrlPart } = useParams();
  return (
    <CaseProvider caseUrlPart={productUrlPart} caseId={productId}>
      <CallcenterCasePageInner />
    </CaseProvider>
  );
};

const CallcenterCasePageInner = () => {
  const { product, error, isLoading, refreshCase } = useCase();
  const [activitiesRefreshCounter, setActivitiesRefreshCounter] = useState(0);
  const [emailsRefreshCounter, setEmailsRefreshCounter] = useState(0);

  const refreshActivities = () => {
    setActivitiesRefreshCounter(activitiesRefreshCounter + 1);
  };

  const refreshEmails = () => {
    setEmailsRefreshCounter(emailsRefreshCounter + 1);
  };

  if (isLoading) {
    return (
      <PageContentContainer>
        <CaseOverviewPageLoader />
      </PageContentContainer>
    );
  }

  if (error?.status === 404 || product?.customer?.isFake) {
    return <CaseNotFound />;
  }

  if (!!error) {
    return <CaseError errorMessage={error.error} errorStatus={error.status} />;
  }

  return (
    <>
      <CaseRelatedActions product={product} refreshEmails={refreshEmails} refreshActivities={refreshActivities} />
      <PageContentContainer>
        <Stack spacing={3}>
          <CaseHeader product={product} />
          <Contact product={product} refreshCase={refreshCase} />
          <Activities product={product} refreshCounter={activitiesRefreshCounter} />
        </Stack>
      </PageContentContainer>
    </>
  );
};

export default CallcenterCasePage;
