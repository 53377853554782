import React from "react";
import { useParams } from "react-router-dom";
import LegalBirdPageLoading from "../../ContentLoader/LegalBirdPageLoading";
import CaseRelatedActions from "../../Case/CaseRelatedActions/CaseRelatedActions";
import PageContentContainer from "../../Container/PageContentContainer";
import { ProductHeadline } from "../../PageHeadline/PageHeadline";
import CasePaidTaskDefinition from "../../CasePaidTask/CasePaidTaskDefinition";
import CasePaidTaskSubmission from "../../CasePaidTask/CasePaidTaskSubmission";
import CasePaidTaskApproval from "../../CasePaidTask/CasePaidTaskApproval";
import Grid from "@mui/material/Grid";
import NavigationExtensionPaidTask from "../../CasePaidTask/NavigationExtensionPaidTask";
import { PAID_TASK_STATUS_APPROVED } from "../../PaidTask/paidTaskStatus";
import CaseProvider, { useCase } from "../../../provider/CaseProvider";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { scrollToAnchor } from "../../../theme/commonStyles";
import { useQuery } from "@tanstack/react-query";
import { fetchResource, queryKeys } from "../../../services/ReactQuery/reactQueryService";

const CasePaidTaskPage = () => {
  const { productId, productUrlPart } = useParams();
  return (
    <CaseProvider caseUrlPart={productUrlPart} caseId={productId}>
      <CasePaidTaskPageInner />
    </CaseProvider>
  );
};

const CasePaidTaskPageInner = () => {
  const { paidTaskId } = useParams();
  const { product, refreshCase } = useCase();
  const paidTaskIdAsNumber = parseInt(paidTaskId);
  const { data: paidTask, isLoading } = useQuery(
    queryKeys.item("paid_tasks", paidTaskIdAsNumber),
    () => fetchResource("paid_tasks", paidTaskIdAsNumber),
  );

  if (isLoading || !paidTask || !product) {
    return <LegalBirdPageLoading />;
  }

  if (paidTask.deletedDate || paidTask?.backofficeCase?.id !== product.backofficeCase.id) {
    return (
      <>
        <CaseRelatedActions product={product} refreshPage={refreshCase} />
        <Grid container justifyContent={"center"}>
          <Grid item>
            <Typography variant={"h3"} sx={{ mt: 1, mb: 1 }}>
              Diese Aufgabe existiert nicht
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      <CaseRelatedActions product={product} refreshPage={refreshCase} />
      {paidTask.status < PAID_TASK_STATUS_APPROVED && <NavigationExtensionPaidTask />}
      <PageContentContainer size={"small"}>
        <ProductHeadline title="Ausgeschriebene Aufgabe zum Fall von" product={product} />
        <div>
          <Box sx={scrollToAnchor} id={"definition"} />
          <CasePaidTaskDefinition paidTask={paidTask} product={product} />
        </div>
        {paidTask.status < PAID_TASK_STATUS_APPROVED && (
          <>
            <div>
              <Box sx={scrollToAnchor} id={"submission"} />
              <CasePaidTaskSubmission paidTask={paidTask} product={product} />
            </div>
            <div>
              <Box sx={scrollToAnchor} id={"approval"} />
              <CasePaidTaskApproval paidTask={paidTask} product={product} />
            </div>
          </>
        )}
      </PageContentContainer>
    </>
  );
};

export default CasePaidTaskPage;
