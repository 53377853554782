import customerTranslations from "./customerTranslations";
import basicCaseTranslations from "./basicCaseTranslations";
import abstractCaseTranslations from "./abstractCaseTranslations";

const contractAdvisoryTranslations = {
  label: "Erstberatung Vertragsrecht",
  customer: customerTranslations,
  ...basicCaseTranslations,
  ...abstractCaseTranslations,
  serviceProvidedDate: {
    label: "Leistung erbracht am",
  },
};

export default contractAdvisoryTranslations;
