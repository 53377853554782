import Grid from "@mui/material/Grid";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import _ from "lodash";
import {
  getActivitiesSet,
  getActivityData,
  transformActivitySetToAutoCompleteOptions,
} from "../../../services/Activities/activityTypes";
import MenuItem from "@mui/material/MenuItem";
import ValidatorTextField from "../../Validator/ValidatorTextField";
import { dateFieldDefault, isTodayOrFutureDate, requiredFieldDefault } from "../../../services/validationRules";
import ValidatorDateField from "../../Validator/ValidatorDatePicker";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useForm from "../../../hooks/useForm";
import useStyles from "./editActivityStyles";
import SubmitButton from "../../Button/SubmitButton";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ActivityHistory from "./ActivityHistory";
import { formValue } from "../../../services/formServiceFunctions";
import { Editor } from "react-draft-wysiwyg";
import LegalbirdAutoComplete from "../../AutoComplete/LegalbirdAutoComplete";
import { getRoleToDisplay, sortAssignedUsersToTop, sortByRole } from "../../../services/backofficeUserService";
import ApiClient from "../../../services/ApiClient";
import { ReportProblemOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import WorkingHoursHint from "./WorkingHoursHint";
import { useHolidays } from "../../../provider/HolidayProvider";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";

const availabiltyInfoTranslations = {
  blockedDay: "Der Nutzer ist an dem Tag nicht anwesend",
  capacityReached: "Der Nutzer ist an dem Tag bereits zeitlich ausgelastet",
  available: "",
};

const ActivityForm = ({ open, initialValues, onSubmit, closeDialog, product, productClassName, activity, title }) => {
  const classes = useStyles({});
  const currentUser = useCurrentUser();
  const { agents, internals, externals } = useBackofficeUser();
  const durations = [1, 3, 5, 10, 15, 20, 30, 45, 60, 90, 120, 180];
  const [userAvailabilityStatus, setUserAvailabilityStatus] = useState("");
  const [mandatorySelectDisabled, setMandatorySelectDisabled] = useState(false);
  const { holidays } = useHolidays();
  const {
    values,
    errors,
    handleChange,
    handleDateChange,
    handleSubmit,
    registerValidators,
    handleBlur,
    touchedValues,
    clearForm,
  } = useForm({
    initialValues,
    onSubmit,
    identifier: !!activity ? activity : "new",
    clearFormAfterSubmit: true,
  });

  const fetchAvailabilityStatus = useCallback(
    (assignedUser, dueDate) => {
      const dueDateMoment = moment(dueDate);

      if (
        assignedUser === currentUser["@id"] ||
        !dueDateMoment.isValid() ||
        !assignedUser ||
        !open ||
        _.find(externals, (external) => external["@id"] === assignedUser)
      ) {
        setUserAvailabilityStatus("");
        return;
      }

      ApiClient.get(
        `services/human_resource_management/user_availability_status/${_.replace(
          assignedUser,
          "/backoffice_users/",
          ""
        )}/${dueDateMoment.format("YYYY-MM-DD")}`
      ).then((result) => {
        setUserAvailabilityStatus(result.status);
      });
    },
    [currentUser, open]
  );

  useEffect(() => {
    fetchAvailabilityStatus(values.assignedUser, values.dueDate);
    updateMandatoryActivity(values.assignedUser, values.dueDate, values.type);
  }, [open]);

  const internalCloseDialog = () => {
    clearForm();
    closeDialog();
  };

  const updateMandatoryActivity = (assignedUser, dueDate, activityType) => {
    //should only be executed for create activity
    if (activity) {
      return;
    }

    if (
      (moment().isSame(dueDate, "day") && currentUser["@id"] !== assignedUser) ||
      _.includes(["deadline", "peremptory_term"], activityType)
    ) {
      handleChange({ target: { name: "mandatory", value: true } });
      setMandatorySelectDisabled(true);
      return;
    }
    handleChange({ target: { name: "mandatory", value: false } });
    setMandatorySelectDisabled(false);
  };

  const handleEditorStateChange = (editorState) => {
    handleChange({
      target: {
        name: "note",
        value: editorState,
      },
    });
  };

  const getAgentsList = useCallback(() => {
    switch (true) {
      case activity:
        return agents;
      case product:
      default:
        return internals;
    }
  }, [activity, product, agents, internals]);

  const users = useMemo(() => {
    return getUsers(sortByRole(getAgentsList()), activity ? activity.case : product.backofficeCase);
  }, [activity, product]);

  const activityTypes = useMemo(() => {
    return transformActivitySetToAutoCompleteOptions(getActivitiesSet(productClassName));
  }, [product]);

  const userAutoCompleteValue = _.find(users, (user) => user.id === (values.assignedUser || null));

  const activityTypeAutoCompleteValue = _.find(activityTypes, (activityType) => activityType.name === values.type);

  const handleAssignedUserValueChange = (e, value) => {
    handleChange({
      target: {
        name: "assignedUser",
        value: value.id,
      },
    });
    updateMandatoryActivity(value.id, values.dueDate, values.type);
    fetchAvailabilityStatus(value.id, values.dueDate);
  };

  const handleActivityTypeValueChange = (e, value) => {
    //must be before handleChange to get the old value
    const previousActivityType = getActivityData(productClassName, formValue(values, "type"));

    handleChange({
      target: {
        name: "type",
        value: value.name,
      },
    });
    updateMandatoryActivity(values.assignedUser, values.dueDate, value.name);

    const activityData = getActivityData(productClassName, value.name);

    if (!touchedValues.subject || _.includes([previousActivityType.label, ""], formValue(values, "subject"))) {
      const changeDescriptionEvent = {
        target: {
          name: "subject",
          value: _.get(activityData, "prefillDescription") ? _.get(activityData, "label", "N/A") : "",
        },
      };
      handleChange(changeDescriptionEvent);
    }
  };

  const onDateChange = (date) => {
    handleDateChange(date, "dueDate");
    updateMandatoryActivity(values.assignedUser, date, values.type);
    fetchAvailabilityStatus(values.assignedUser, date);
  };

  const getActionsInfo = () => {
    if (_.includes(holidays, moment(values.dueDate).format("YYYY-MM-DD"))) {
      return (
        <div className={classes.availabilityStatus}>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
            <ReportProblemOutlined />
            Dieser Tag ist ein Feiertag
          </Stack>
        </div>
      );
    }
    if (!_.includes(["", "available"], userAvailabilityStatus)) {
      return (
        <div className={classes.availabilityStatus}>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
            <ReportProblemOutlined />
            {availabiltyInfoTranslations[userAvailabilityStatus]}
          </Stack>
        </div>
      );
    } else if (!!values.assignedUser) {
      return <WorkingHoursHint dueDate={values.dueDate} dueTime={values.dueTime} assignedUser={values.assignedUser} />;
    }
  };

  const dueDateDisabled = activity && (activity.doneTime || activity.dueDate < moment().format("YYYY-MM-DD"));
  let dueDateValidators = [...requiredFieldDefault, ...dateFieldDefault];

  if (!dueDateDisabled) {
    dueDateValidators.push({
      validator: isTodayOrFutureDate,
      message: "Es können keine vergangenen Tage ausgewählt werden",
    });
  }

  return (
    <LegalbirdIoModal
      handleClose={internalCloseDialog}
      open={open}
      disableBackdropClick
      title={title}
      submitButton={
        <SubmitButton
          onClick={handleSubmit}
          variant={"contained"}
          error={_.keys(errors).length > 0}
          disabled={_.keys(touchedValues).length === 0 || values.type === "no_type" || !values.assignedUser}
        >
          Speichern
        </SubmitButton>
      }
      actionsInfo={getActionsInfo()}
    >
      <Grid container>
        <Grid item xs={12} sm={6} className={classes.activityGridItem}>
          <LegalbirdAutoComplete
            setValue={handleAssignedUserValueChange}
            getOptionDisabled={(option) => option.id === null}
            value={userAutoCompleteValue}
            options={users}
            label={"Zu erledigen durch"}
            disabled={activity && activity.doneTime}
            groupBy={(option) => option.orderBy}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.activityGridItem}>
          <LegalbirdAutoComplete
            setValue={handleActivityTypeValueChange}
            getOptionDisabled={(option) => option.disabled}
            value={activityTypeAutoCompleteValue}
            options={activityTypes}
            label={"Aktivitätstyp"}
            disabled={activity && activity.doneTime}
          />
        </Grid>
        <Grid item xs={12} className={classes.activityGridItem}>
          <ValidatorTextField
            label={"Beschreibung"}
            name={"subject"}
            maxLength={10}
            value={formValue(values, "subject")}
            onChange={handleChange}
            onBlur={handleBlur}
            registerValidators={registerValidators}
            validators={requiredFieldDefault}
            error={!!errors["subject"]}
            helperText={errors["subject"]}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.activityGridItem}>
          <ValidatorDateField
            format="DD.MM.YYYY"
            label={"Datum"}
            name={"dueDate"}
            disabled={dueDateDisabled}
            shouldDisableDate={(date) => {
              return date.day() === 0 || date.day() === 6;
            }}
            onChange={(date) => onDateChange(date)}
            onBlur={handleBlur}
            value={formValue(values, "dueDate")}
            helperText={errors["dueDate"]}
            registerValidators={registerValidators}
            validators={dueDateValidators}
            disablePast
            minDateMessage={""}
            showToolbar
            withSkipWeeksToolbar
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.activityGridItem}>
          <ValidatorTextField
            label={"Uhrzeit"}
            onChange={handleChange}
            onBlur={handleBlur}
            InputLabelProps={{
              shrink: true,
            }}
            type={"time"}
            name={"dueTime"}
            disabled={activity && activity.doneTime}
            value={formValue(values, "dueTime")}
            registerValidators={registerValidators}
            error={!!errors["dueTime"]}
            helperText={errors["dueTime"]}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.activityGridItem}>
          <ValidatorSelect
            label={"Pflicht?"}
            name={"mandatory"}
            value={formValue(values, "mandatory")}
            onChange={handleChange}
            disabled={mandatorySelectDisabled}
          >
            <MenuItem value={true}>Pflicht</MenuItem>
            <MenuItem value={false}>Keine Pflicht</MenuItem>
          </ValidatorSelect>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.activityGridItem}>
          <ValidatorSelect
            label={"Dauer"}
            name={"duration"}
            // "/backoffice_users/13" is the system user
            disabled={activity && (activity.creator === "/backoffice_users/13" || activity.doneTime)}
            value={formValue(values, "duration")}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors["duration"]}
            helperText={errors["duration"]}
          >
            <MenuItem disabled value={""}>
              Nicht zugeordnet
            </MenuItem>
            {_.map(durations, (duration) => (
              <MenuItem key={duration} value={duration}>
                {duration} {duration === 1 ? "Minute" : "Minuten"}
              </MenuItem>
            ))}
          </ValidatorSelect>
        </Grid>
        <Grid item xs={12} className={classes.activityGridItem}>
          <Editor
            toolbar={{
              options: ["inline", "list", "link"],
              inline: {
                options: ["bold", "italic", "underline"],
              },
              list: {
                options: ["unordered", "ordered"],
              },
            }}
            stripPastedStyles
            editorState={formValue(values, "note")}
            onEditorStateChange={handleEditorStateChange}
            editorClassName={classes.editor}
            placeholder={"Notiz"}
            localization={{ locale: "de" }}
          />
        </Grid>
      </Grid>
      {activity && <ActivityHistory activity={activity} productClassName={productClassName} />}
    </LegalbirdIoModal>
  );
};

const isAssignedUser = (user, backofficeCase) => {
  switch (true) {
    case _.isString(backofficeCase.accountManager) || _.isString(backofficeCase.lawyer):
      return backofficeCase.accountManager === user["@id"] || backofficeCase.lawyer === user["@id"];
    case _.isObject(backofficeCase.accountManager) || _.isObject(backofficeCase.lawyer):
      return _.get(backofficeCase, "accountManager.id") === user.id || _.get(backofficeCase, "lawyer.id") === user.id;
    default:
      return false;
  }
};

const getUsers = (agents, backofficeCase) => {
  let users = sortAssignedUsersToTop(agents, backofficeCase);
  users = _.map(users, (user) => {
    return {
      label: user.person.fullname,
      id: user["@id"],
      orderBy: isAssignedUser(user, backofficeCase) ? "Zuständig" : getRoleToDisplay(user),
    };
  });
  return [{ label: "Nicht zugeordnet", id: null }, ...users];
};

export default ActivityForm;
