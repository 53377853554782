import React, { useState } from "react";
import * as PropTypes from "prop-types";
import useStyles from "./activityNoteStyle";
import Grid from "@mui/material/Grid";
import classNames from "classnames";
import IconButton from "@mui/material/IconButton";
import { UnfoldLess, UnfoldMore } from "@mui/icons-material";

const ActivityNote = ({ noteContent, isFolded }) => {
  const [noteFolded, setNoteFolded] = useState(isFolded);
  const classes = useStyles({});

  if (!noteContent) {
    return null;
  }

  //used to check for multi line content
  const pTagCount = (noteContent.match(/<p>/g) || []).length;

  return (
    <div className={classNames(classes.noteContainer, "noteContainer")}>
      <Grid container wrap={"nowrap"} justifyContent={"space-between"}>
        <Grid item>
          <div
            className={classNames(classes.note, noteFolded ? classes.collapsedNote : null)}
            dangerouslySetInnerHTML={{ __html: noteContent }}
          />
        </Grid>
        {(noteContent.length > 120 ||
          pTagCount > 1 ||
          noteContent.includes("<br>") ||
          noteContent.includes("<br/>")) && (
          <Grid item>
            <IconButton
              onClick={() => setNoteFolded(!noteFolded)}
              className={classes.icon}
              size="large">
              {noteFolded ? <UnfoldMore /> : <UnfoldLess />}
            </IconButton>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

ActivityNote.propTypes = {
  noteContent: PropTypes.string,
  isFolded: PropTypes.bool.isRequired,
};

ActivityNote.defaultProps = {
  isFolded: true,
};

export default ActivityNote;
