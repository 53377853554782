import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  headline: {
    fontSize: "1.5rem",
    textAlign: "center",
  },
  activityCount: {
    fontSize: "1rem",
    fontWeight: 700,
    marginTop: "1rem",
  },
  activitiesToday: {
    fontWeight: 700,
    fontSize: "1rem",
    marginTop: "1rem",
  },
  activitiesOverdue: {
    color: "#a31b1b",
    fontWeight: 700,
    fontSize: "1rem",
    marginTop: "1rem",
  },
  filterContainer: {
    marginBottom: "1rem",
  },
  badge: {
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 5,
    borderColor: "#137f7b",
    textAlign: "center",
    padding: "5px",
    minWidth: 120,
    fontSize: 16,
  },
  activeBadge: {
    backgroundColor: "#137f7b",
    color: "#fff",
  },
  inactiveBadge: {
    color: "#333",
    cursor: "pointer",
  },
}));

export default useStyles;
