import React from "react";
import * as PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import ActivityForm from "./ActivityForm";
import { EditorState } from "draft-js";
import { editorStateToHTML } from "../../../services/Editor/editorFunctions";
import { useMutation } from "@tanstack/react-query";
import { createResource } from "../../../services/ReactQuery/reactQueryService";

const CreateActivity = ({ open, closeDialog, product, caseId, updateActivities, defaultAssignedUser }) => {
  const createMutation = useMutation(createResource);

  const onSubmit = async ({ values }) => {
    let activityData = _.merge({}, values);
    activityData.dueTime = activityData.dueTime !== "" ? activityData.dueTime : "allDay";
    activityData.case = caseId;
    activityData.note = editorStateToHTML(activityData.note, true);
    await createMutation.mutateAsync({ uri: "activities", data: activityData });
    await updateActivities();
    closeDialog();
  };

  const baseValues = {
    type: "no_type",
    subject: "",
    dueDate: moment(),
    dueTime: "",
    mandatory: false,
    assignedUser: defaultAssignedUser || "",
    note: EditorState.createEmpty(),
  };

  return (
    <ActivityForm
      open={open}
      initialValues={baseValues}
      onSubmit={onSubmit}
      closeDialog={closeDialog}
      product={product}
      productClassName={product.productClassName}
      title={"Aktivität erstellen"}
    />
  );
};

CreateActivity.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  caseId: PropTypes.string.isRequired,
  updateActivities: PropTypes.func,
  defaultAssignedUser: PropTypes.string,
};

export default CreateActivity;
