import React from "react";
import { connectHits } from "react-instantsearch-dom";
import _ from "lodash";
import makeStyles from "@mui/styles/makeStyles";
import BoardCard from "../../Cards/BoardCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import IconButton from "@mui/material/IconButton";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import classNames from "classnames";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    [theme.breakpoints.up("lg")]: {
      //- left reserved action space + scrollbar 70 + 17
      width: "calc(90vw - 87px)",
    },
    [theme.breakpoints.down("lg")]: {
      //- scrollbar 17
      width: "calc(90vw - 17px)",
    },
    margin: "0 auto",
  },
  column: {
    padding: "1rem",
  },
  columnLabel: {
    textAlign: "center",
    fontSize: ".875rem",
    fontWeight: 700,
    height: "3rem",
  },
  caseCount: {
    textAlign: "center",
    fontSize: ".75rem",
  },
  leftArrow: {
    position: "fixed",
    top: "40vh",
    left: 80,
  },
  rightArrow: {
    position: "fixed",
    top: "40vh",
    right: 90,
  },
  arrowColors: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    opacity: 0.5,
  },
}));

const CustomArrow = ({ onClick, direction }) => {
  const classes = useStyles({});
  return (
    <IconButton
      onClick={() => onClick()}
      className={classNames(classes[direction + "Arrow"], classes.arrowColors)}
      size="large"
    >
      {direction === "right" ? <ArrowRight /> : <ArrowLeft />}
    </IconButton>
  );
};

const LegalbirdHits = ({ hits, stages }) => {
  const partitionHits = _.groupBy(hits, (hit) => hit.stage);
  const classes = useStyles({});
  const { backofficeUsers } = useBackofficeUser();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1651 },
      items: 9,
      slidesToSlide: 3,
    },
    shittyMonitor: {
      breakpoint: { max: 1650, min: 1251 },
      items: 6,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1250, min: 1001 },
      items: 5,
      slidesToSlide: 2,
    },
    smallTablet: {
      breakpoint: { max: 1000, min: 701 },
      items: 3,
      slidesToSlide: 1,
    },
    chonkyPhone: {
      breakpoint: { max: 700, min: 451 },
      items: 2,
      slidesToSlide: 1,
    },
    phone: {
      breakpoint: { max: 450, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    //without this div the carousel keeps expanding horizontally because of something from LayoutDeviceBased
    <div className={classes.carouselContainer}>
      <Carousel
        responsive={responsive}
        draggable={false}
        customRightArrow={<CustomArrow direction={"right"} />}
        customLeftArrow={<CustomArrow direction={"left"} />}
        removeArrowOnDeviceType={["tablet", "smallTablet", "chonkyPhone", "phone"]}
      >
        {_.map(stages, (stage, stageId) => {
          const partition = partitionHits[stageId] || [];
          return (
            <div className={classes.column} key={stageId}>
              <div className={classes.columnLabel}>{stages[stageId].label}</div>
              <div className={classes.caseCount}>
                {partition.length} {partition.length === 1 ? "Fall" : "Fälle"}
              </div>
              {_.map(partition, (hit) => {
                return <BoardCard key={hit.objectID} hit={hit} backofficeUsers={backofficeUsers} />;
              })}
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default connectHits(LegalbirdHits);
