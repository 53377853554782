import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./statusValidationErrorsStyle";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import _ from "lodash";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";

const StatusValidationErrors = ({ error, stage, closeDialog }) => {
  const classes = useStyles();

  if (!_.isError(error)) {
    return null;
  }

  return (
    <LegalbirdIoModal
      handleClose={closeDialog}
      open={!!error}
      title={!!error.errors ? "Fehlende Daten" : "Technischer Fehler"}
      submitButton={
        <Button variant={"contained"} onClick={closeDialog}>
          Ok
        </Button>
      }
      hideCancelButton={true}
    >
      <Grid container>
        {error.status === 422 ? (
          <>
            <Grid item xs={12} className={classes.commonText}>
              Für den Übergang in Status "{stage}" fehlen folgende Daten:
            </Grid>
            {_.map(error.errors, (error, index) => (
              <Grid item xs={12} key={index} className={classes.commonText}>
                - {error}
              </Grid>
            ))}
          </>
        ) : (
          <>
            <Grid item xs={12} className={classes.commonText}>
              Leider ist ein technischer Fehler ist aufgetreten. Bitte merken Sie sich, in welcher Situation der Fehler
              aufgetreten ist und kontaktieren Sie die IT.
            </Grid>
            <Grid item xs={12} className={classes.errorText}>
              Fehler: "{error.error}" [{error.status}].
            </Grid>
          </>
        )}
      </Grid>
    </LegalbirdIoModal>
  );
};

StatusValidationErrors.propTypes = {
  error: PropTypes.object,
  closeDialog: PropTypes.func.isRequired,
};

export default StatusValidationErrors;
