import moment from "moment";

export const buildInquiryShortText = (inquiryToCustomer) => {
  switch (inquiryToCustomer.statusText) {
    case "open":
      return (
        "Offene Anfrage an Mandantschaft: Am " +
        moment(inquiryToCustomer.created).format("DD.MM.YYYY") +
        " wurde eine Anfrage von " +
        inquiryToCustomer.createdByFullname +
        " gestellt, die bisher nicht beantwortet wurde."
      );
    case "inProgress":
      return "Termin-Anfrage an Mandantschaft: Termin wurde gebucht. Wenn der Termin erfolgreich stattgefunden hat, wird die Anfrage automatisch erledigt.";
    case "overdue":
      return (
        "Überfällige Anfrage an Mandantschaft: Am " +
        moment(inquiryToCustomer.created).format("DD.MM.YYYY") +
        " wurde eine Anfrage von " +
        inquiryToCustomer.createdByFullname +
        " gestellt, die bisher nicht bis zum " +
        moment(inquiryToCustomer.deadline).format("DD.MM.YYYY") +
        " beantwortet wurde."
      );
  }
};
