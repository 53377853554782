export const courtTranslations = {
    label: "Zuständiges Gericht",
    name: {
      label: "Name Gericht",
    },
    telephone: {
      label: "Telefon Gericht",
    },
    fax: {
      label: "Fax Gericht",
    },
    beaSafeId: {
      label: "beA-Adresse",
    },
    advoAssistId: {
      label: "Advoassist Id",
    },
    address: {
      streetAddress: {
        label: "Straße und Nr Gericht",
      },
      postalCode: {
        label: "PLZ Gericht",
      },
      addressLocality: {
        label: "Ort Gericht",
      },
      postOfficeBoxNumber: {
        label: "Postfach",
      },
    },
};
