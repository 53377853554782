import PropTypes from "prop-types";
import LegalbirdIoModal from "./LegalbirdIoModal";
import ButtonLoading from "../Button/ButtonLoading";
import React, { useState } from "react";

export default function LegalbirdIoConfirm({ open, handleClose, headline, content, confirmText, handleConfirm }) {
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = async (e) => {
    setIsLoading(true);
    await handleConfirm(e);
    setIsLoading(false);
    handleClose();
  };

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={headline}
      submitButton={
        <ButtonLoading onClick={handleClick} color="primary" variant={"contained"} isLoading={isLoading}>
          {confirmText}
        </ButtonLoading>
      }
    >
      {content}
    </LegalbirdIoModal>
  );
}
LegalbirdIoConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  headline: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirmText: PropTypes.string,
  handleConfirm: PropTypes.func,
};
LegalbirdIoConfirm.defaultProps = {
  headline: "",
  content: "",
  confirmText: "Bestätigen",
  handleConfirm: () => {},
};
