import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import ActivityOverviewHeader from "../../Activities/ActivityOverviewHeader/ActivityOverviewHeader";
import moment from "moment";
import ApiClient from "../../../services/ApiClient";
import { ACTIVITY_OVERDUE } from "../../../services/Stages/Stages";
import { buildApiFilters, buildSearchParams } from "../../../services/Activities/activityFunctions";
import useActivitiesFetching from "../../../hooks/useActivitiesFetching";
import PageContentContainer from "../../../components/Container/PageContentContainer";
import OverviewActivityEntry from "./OverviewActivityEntry";
import { Divider } from "@mui/material";
import useStyles from "./activityOverviewStyle";
import { deadlineActivityTypes } from "../../../services/Activities/activityTypes";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";

const DeadlineOverview = () => {
  const { lawyers, backofficeUsers } = useBackofficeUser();
  const classes = useStyles({});

  const [productFilter, setProductFilter] = useState("all");
  const [activityTypeFilter, setActivityTypeFilter] = useState("allDeadlines");
  const [userFilter, setUserFilter] = useState("all");
  const [timespanFilter, setTimespanFilter] = useState("today");
  const [userActivitiesToday, setUserActivitiesToday] = useState([]);
  const [refreshCounter, setRefreshCounter] = useState(0);

  const dataSource = buildSearchParams(
    _.merge(
      buildApiFilters({
        productFilter: productFilter,
        activityTypeFilter: activityTypeFilter,
        userFilter: userFilter,
        timespanFilter: timespanFilter,
      }),
      {
        refreshCounter: refreshCounter,
      }
    )
  );

  let dataSourceString = dataSource.toString();

  if (userFilter === "all") {
    _.forEach(lawyers, (lawyer) => {
      dataSourceString += "&assignedUser[]=" + lawyer["@id"];
    });
  }

  const { activities, isLoading } = useActivitiesFetching(dataSourceString, backofficeUsers);

  const postProcessFilterActivities = useCallback(
    (activities) => {
      if (timespanFilter === "overdue") {
        activities = _.filter(activities, ["activityStatus", ACTIVITY_OVERDUE]);
      }
      return activities;
    },
    [timespanFilter]
  );

  const updateUserActivitiesToday = useCallback(async () => {
    if (userFilter === "all") {
      setUserActivitiesToday([]);
      return;
    }
    const filters = {
      assignedUser: userFilter,
      "dueDate[before]": moment().format("YYYY-MM-DD"),
      type: deadlineActivityTypes,
      done: false,
      deleted: false,
      pagination: false,
    };

    let searchParams = new URLSearchParams();
    _.map(filters, (value, key) => {
      searchParams.append(key, value);
    });

    const userActivitiesResult = await ApiClient.get("activities?" + searchParams.toString());
    setUserActivitiesToday(userActivitiesResult["hydra:member"]);
  }, [userFilter]);

  const updateActivities = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  useEffect(() => {
    updateUserActivitiesToday();
  }, [userFilter, updateUserActivitiesToday]);

  const filteredActivities = isLoading ? [] : postProcessFilterActivities(activities);

  if (lawyers.length < 1) {
    return null;
  }

  return (
    <PageContentContainer>
      <ActivityOverviewHeader
        activityCount={filteredActivities.length}
        setProductFilter={(data) => {
          updateActivities();
          setProductFilter(data);
        }}
        productFilter={productFilter}
        setActivityTypeFilter={(data) => {
          updateActivities();
          setActivityTypeFilter(data);
        }}
        activityTypeFilter={activityTypeFilter}
        userFilter={userFilter}
        setUserFilter={(data) => {
          updateActivities();
          setUserFilter(data);
        }}
        timespanFilter={timespanFilter}
        setTimespanFilter={(data) => {
          updateActivities();
          setTimespanFilter(data);
        }}
        userActivitiesToday={userActivitiesToday}
        isLoading={isLoading}
        agents={lawyers}
        isDeadlineView
      />
      <div className={classes.headline}>Fristenkalender</div>
      {_.map(filteredActivities, (activity, index) => (
        <React.Fragment key={activity.id}>
          <OverviewActivityEntry activity={activity} update={updateActivities} />
          {index !== filteredActivities.length - 1 && (
            <div className={classes.divider}>
              <Divider />
            </div>
          )}
        </React.Fragment>
      ))}
    </PageContentContainer>
  );
};

export default DeadlineOverview;
