import React from "react";
import PageContentContainer from "../../Container/PageContentContainer";
import PageHeadline from "../../PageHeadline/PageHeadline";
import BeaSearch from "../../BeaSearch/BeaSearch";
import ContentBox from "../../ContentBox/ContentBox";

export default function BeaSearchPage() {
  return (
    <PageContentContainer>
      <PageHeadline main={"bea-Adressen"} />
      <ContentBox>
        <BeaSearch />
      </ContentBox>
    </PageContentContainer>
  );
}
