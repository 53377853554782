import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  editor: {
    minHeight: "10rem",
    border: "1px solid #ddd",
    padding: "0 1rem"
  },
}));

export default useStyles;
