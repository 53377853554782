import { PaidTask } from "../../../types/PaidTask";
import ButtonLoading from "../../Button/ButtonLoading";
import React, { useState } from "react";
import ValidatorForm from "../../Validator/ValidatorForm";
import ValidatorTextField from "../../Validator/ValidatorTextField";
import { formValue } from "../../../services/formServiceFunctions";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import useForm from "../../../hooks/useForm";
import { Box } from "@mui/material";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../services/ReactQuery/reactQueryService";

export default function DismissButton({ paidTask }: { paidTask: PaidTask }) {
  const updateMutation = useMutation(updateResource);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const initialValues = {
    reason: "",
    reasonText: "",
  };

  const onSubmit = async ({ values }: any) => {
    setIsLoading(true);
    await updateMutation.mutateAsync({
      uri: "paid_tasks",
      id: paidTask.id,
      data: {
        requestedUser: null,
        requestedUserDate: null,
        reason: values.reason === "other" ? values.reasonText : values.reason,
        status: 10,
      },
    });
    setIsLoading(false);
    navigate("/");
  };

  const { values, handleChange, handleSubmit, handleBlur, registerValidators, errors }: any = useForm({
    initialValues,
    onSubmit,
  });

  return (
    <>
      <ButtonLoading onClick={() => setModalOpen(true)} isLoading={isLoading}>
        Anfrage ablehnen
      </ButtonLoading>
      <LegalbirdIoModal
        handleClose={() => setModalOpen(false)}
        open={modalOpen}
        title={"Anfrage ablehnen"}
        submitButton={
          <ButtonLoading
            variant={"contained"}
            onClick={handleSubmit}
            isLoading={isLoading}
            disabled={values.reason === "" || (values.reason === "other" && values.reasonText.length < 25)}
          >
            Anfrage ablehnen
          </ButtonLoading>
        }
      >
        <p>
          Sie können die Aufgabe nicht übernehmen? Dann teilen Sie uns bitte kurz mit, woran dies liegt und die Anfrage
          wird entfernt.
        </p>
        <ValidatorForm>
          <Box>
            <ValidatorSelect
              label={"Grund für Ablehnung"}
              name={"reason"}
              value={formValue(values, "reason")}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value={"Krankheit"}>Krankheit</MenuItem>
              <MenuItem value={"Urlaub"}>Urlaub</MenuItem>
              <MenuItem value={"Keine Kapazitäten"}>Keine Kapazitäten</MenuItem>
              <MenuItem value={"other"}>Anderer Grund</MenuItem>
            </ValidatorSelect>
          </Box>
          {values.reason === "other" && (
            <Box>
              <ValidatorTextField
                label={"Anderer Grund (min. 25 Zeichen)"}
                name={"reasonText"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={formValue(values, "reasonText")}
                inputProps={{ maxLength: 255 }}
                multiline
                rows={3}
                fullWidth
                registerValidators={() => {}}
                validators={[]}
                isMandatory={false}
                dependentValidationFields={[]}
              />
            </Box>
          )}
        </ValidatorForm>
      </LegalbirdIoModal>
    </>
  );
}
