import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  noCase: {
    textAlign: "center",
    margin: "10rem auto",
    color: "#999999",
    fontSize: "1.25rem",
  },
}));

export default useStyles;
