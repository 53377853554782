import React from "react";
import { CircularProgress, Grid } from "@mui/material";
import LegalbirdLoader from "./LegalbirdLoader";

const LegalBirdPageLoading = ({ type }) => {
  return (
    <Grid container alignContent={"center"} alignItems={"center"} justifyContent={"center"}>
      <Grid item>{type === "spinner" ? <CircularProgress /> : <LegalbirdLoader />}</Grid>
    </Grid>
  );
};

LegalBirdPageLoading.defaultProps = {
  type: "bird",
};

export default LegalBirdPageLoading;
