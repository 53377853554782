import personTranslations from "./personTranslations";
import { Roles } from "../../types/BackofficeUser";

const backofficeUserTranslations = {
  roles: {
    label: "Rollen",
    values: {
      [Roles.external]: "Externer Anwalt",
      [Roles.accountmanager]: "Operations",
      [Roles.accounting]: "Buchhaltung",
      [Roles.lawyer]: "Interner Anwalt",
      [Roles.admin]: "Admin",
      [Roles.developer]: "Entwickler",
      [Roles.user]: "Benutzer",
      [Roles.system]: "System",
      [Roles.banking]: "Banking",
      [Roles.callcenter]: "Externer Kundenberater",
      [Roles.traineeLawyer]: "Referendar",
    },
  },
  status: {
    label: "Status",
    values: {
      inactive: "Deaktiviert",
      active: "Aktiv",
      invited: "Eingeladen",
    },
  },
  person: personTranslations,
}

export default backofficeUserTranslations;
