import { useCase } from "../../../provider/CaseProvider";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createResource, fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import _ from "lodash";
import { editorStateToHTML, htmlToEditorState } from "../../../services/Editor/editorFunctions";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { getEmailSignature } from "../../../services/Template/emailSignatures";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import { FormContainer, MultiSelectElement, TextFieldElement, useForm } from "react-hook-form-mui";
import { Box, Button, Grid, IconButton, MenuItem, Tooltip } from "@mui/material";
import SelectElement from "../../ReactHookFormElements/SelectElement";
import { translate } from "../../../services/Translations/translatorService";
import React, { useMemo, useState } from "react";
import { AbstractCase } from "../../../types/AbstractCase";
import { getAdvoAssistRepresentative } from "../../../services/Product/ProductService";
import { isEmail } from "../../../services/validationRules";
import { FileCopyOutlined, SettingsBackupRestore } from "@mui/icons-material";
import { Editor, EditorState } from "react-draft-wysiwyg";
import AddTextBlock from "../../Templates/AddTextBlock";
import ButtonLoading from "../../Button/ButtonLoading";
import { MediaObject } from "../../../types/MediaObject";
import { fillTemplate } from "../../../services/Template/templateService";
import { apiGet, apiPost } from "../../../services/Api/apiCall";
import { loadSessionStorageState, saveSessionStorageState } from "../../../services/browserStorageService";
import { Channel } from "../../../types/Messaging/Channel";

interface ComposeEmailOrMessageProps {
  open: boolean;
  closeDialog: () => void;
  messageConfig?: {
    conversationIri?: string;
  };
}

export default function ComposeEmailOrMessage({ open, closeDialog, messageConfig = {} }: ComposeEmailOrMessageProps) {
  const { product } = useCase();
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();
  const [carbonCopy, setCarbonCopy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const createMutation = useMutation(createResource);

  const mediaObjectsFilter = {
    product: product?.productClassName,
    productId: product?.id,
  };
  const { data: mediaObjectsCollection } = useQuery(
    queryKeys.collection("media_objects", mediaObjectsFilter),
    () => fetchCollection("media_objects", mediaObjectsFilter),
    {
      enabled: !!product && open,
    }
  );

  const templateFilter = {
    type: "email",
  };

  const { data: templatesCollection } = useQuery(
    queryKeys.collection("mailTemplates", templateFilter),
    () => fetchCollection("templates", templateFilter),
    {
      enabled: open,
    }
  );

  const formContext = useForm({
    mode: "onTouched",
    defaultValues: {
      recipient: product?.customer?.email || "",
      subject: " (Unser Zeichen: " + product?.reference + ")",
      editorState: htmlToEditorState("<p></p>" + getEmailSignature(currentUser, product, true)),
      chosenTemplate: "no_template",
      recipientType: "customer",
      carbonCopyType: "customer",
      attachedMediaObjects: [],
      carbonCopyRecipient: product?.customer?.email || "",
    },
  });

  const isComposeMessage = formContext.watch("recipientType") === "customer";
  const isComposeEmail = !isComposeMessage;

  const recipientMenuItems = useMemo(() => getRecipientMenuItems(product), [product]);
  const emailTemplateMenuItems = useMemo(
    () => getEmailTemplates(templatesCollection, product),
    [templatesCollection, product]
  );

  if (!product || !mediaObjectsCollection || !templatesCollection) {
    return null;
  }

  const mediaObjects = _.get(mediaObjectsCollection, "hydra:member");
  const templates = _.get(templatesCollection, "hydra:member");
  const bccMail = `legalbird-rechtsanwaelte-bcc+${product.productClassName}${product.id}@legalbird.de`;
  const attachmentsFileLimitReached = false;

  const handleSubmit = (values: any) => {
    if (isComposeEmail) {
      handleMailSubmit(values);
    }
    if (isComposeMessage) {
      handleMessageSubmit(values);
    }
  };

  const handleMailSubmit = (values: Record<string, any>) => {
    setIsLoading(true);
    let mailData: any = {
      toMail: values.recipient,
      recipientType: values.recipientType,
      productString: `${product.productClassName}${product.id}`,
      subject: values.subject,
      html: editorStateToHTML(values.editorState),
      mediaObjectIds: values.attachedMediaObjects,
    };
    if (carbonCopy && !!values.carbonCopyRecipient) {
      mailData.cc = values.carbonCopyRecipient;
    }

    apiPost("email/email_send", mailData).then(
      () => {
        queryClient.invalidateQueries(queryKeys.collections("emails"));
        formContext.reset();
        setIsLoading(false);
        closeDialog();
      },
      (error) => {
        console.error(error);
        alert(
          "Mailversand fehlgeschlagen, sollte der Fehler nach einem Neuladen der Seite weiterhin auftreten, " +
            "kontaktieren Sie bitte die IT."
        );
        setIsLoading(false);
      }
    );
  };
  const handleMessageSubmit = async (values: Record<string, any>) => {
    setIsLoading(true);

    const caseChannels = (await apiGet("/channels?case=" + product.id))["hydra:member"];
    const defaultChannelIri = caseChannels.find((channel: Channel) => channel.type === "all");

    if (!defaultChannelIri) {
      alert(
        "Der Defaultchannel für diesen Fall konnte nicht gefunden werden. Bitte Kontakt mit der IT aufnehmen falls das Problem weiterhin besteht."
      );
      return;
    }

    const attachments = values.attachedMediaObjects.map((mediaObjectId: number) =>
      mediaObjects.find((mediaObject: MediaObject) => mediaObject.id === mediaObjectId)
    );

    let messageData: any = {
      subject: values.subject,
      messageText: editorStateToHTML(values.editorState),
      attachments: attachments.length > 0 ? attachments : null,
    };

    if (messageConfig.conversationIri) {
      messageData.conversation = messageConfig.conversationIri;
    } else {
      messageData.conversation = {
        subject: values.subject,
        type: "submitCaseInfo",
        channel: defaultChannelIri,
      };
    }

    await createMutation.mutateAsync({ uri: "/messages", data: messageData });
    await queryClient.invalidateQueries(queryKeys.collection("/conversations", { "channel.case": product.id }));
    formContext.reset();
    setIsLoading(false);
    closeDialog();
  };

  const handleRecipientTypeChange = (recipientType: string) => {
    formContext.setValue("recipient", getDefaultRecipientEmail(recipientType, product));
    const { subject, editorState } = getTemplateData(
      formContext.watch("chosenTemplate"),
      templates,
      product,
      formContext.watch(),
      getEmailSignature(currentUser, product, recipientType === "customer")
    );
    formContext.setValue("subject", subject);
    formContext.setValue("editorState", editorState);
  };

  const handleTemplateChange = (selectedTemplateId: any) => {
    const { subject, editorState } = getTemplateData(
      selectedTemplateId,
      templates,
      product,
      formContext.watch(),
      getEmailSignature(currentUser, product, isComposeMessage)
    );
    formContext.setValue("subject", subject);
    formContext.setValue("editorState", editorState);
  };

  const handleClose = () => {
    formContext.reset();
    setCarbonCopy(false);
    closeDialog();
  };

  const loadContentFromSessionStorage = () => {
    const sessionStorageValues = loadSessionStorageState("email");
    if (sessionStorageValues) {
      formContext.setValue("recipient", sessionStorageValues.recipient);
      formContext.setValue("subject", sessionStorageValues.subject);
      formContext.setValue("editorState", htmlToEditorState(sessionStorageValues.editorState));
      formContext.setValue("chosenTemplate", sessionStorageValues.chosenTemplate);
      formContext.setValue("carbonCopyType", sessionStorageValues.carbonCopyType);
      formContext.setValue("carbonCopyRecipient", sessionStorageValues.carbonCopyRecipient);
      formContext.setValue("attachedMediaObjects", sessionStorageValues.attachedMediaObjects);
    }
  };

  const isConversationAnswer = messageConfig?.conversationIri;

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      disableBackdropClick
      title={"Nachricht schreiben"}
      hideCancelButton
      hasActions={false}
      submitButton={undefined}
    >
      <FormContainer formContext={formContext} onSuccess={handleSubmit}>
        <Grid container alignContent={"center"}>
          <Grid item xs={6} sx={{ paddingRight: "1rem" }}>
            <SelectElement
              disabled={isConversationAnswer}
              label={"Empfänger"}
              name={"recipientType"}
              onChange={handleRecipientTypeChange}
            >
              {recipientMenuItems}
            </SelectElement>
          </Grid>
          {isComposeEmail && (
            <Grid item xs={6} sx={{ paddingLeft: "1rem" }}>
              <TextFieldElement
                label={"Email-Adresse Empfänger"}
                name={"recipient"}
                validation={{
                  required: "Die Email-Adresse des Empfängers ist ein Pflichtfeld",
                  validate: { isEmail: (value: string) => isEmail(value) || "Dies ist keine gültige E-Mail Adresse" },
                }}
              />
            </Grid>
          )}
          <Grid item xs={6} sx={isComposeEmail ? { paddingRight: "1rem" } : {}}>
            <SelectElement label={"Nachrichtenvorlage"} name={"chosenTemplate"} onChange={handleTemplateChange}>
              {emailTemplateMenuItems}
            </SelectElement>
          </Grid>
          {isComposeEmail && (
            <Grid item xs={6} sx={{ paddingLeft: "1rem" }}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "2fr 1fr",
                  columnGap: "1rem",
                  height: "100%",
                  padding: "1rem 0",
                }}
              >
                <Button onClick={() => setCarbonCopy(!carbonCopy)} sx={{ whiteSpace: "nowrap" }}>
                  {carbonCopy ? "CC entfernen" : "CC hinzufügen"}
                </Button>
                <Button onClick={() => copyBccMailToClipboard(bccMail)} endIcon={<FileCopyOutlined />}>
                  BCC
                </Button>
              </Box>
            </Grid>
          )}
          {carbonCopy && (
            <>
              <Grid item xs={6} sx={{ paddingRight: "1rem" }}>
                <SelectElement
                  label={"CC Empfänger"}
                  name={"carbonCopyType"}
                  onChange={(carbonCopyType: any) =>
                    formContext.setValue("carbonCopyRecipient", getDefaultRecipientEmail(carbonCopyType, product))
                  }
                >
                  {recipientMenuItems}
                </SelectElement>
              </Grid>
              <Grid item xs={6} sx={{ paddingLeft: "1rem" }}>
                <TextFieldElement
                  label={"CC E-Mail-Adresse"}
                  name={"carbonCopyRecipient"}
                  disabled={formContext.watch("carbonCopyType") === "customer"}
                  validation={{
                    validate: { isEmail: (value: string) => isEmail(value) || "Dies ist keine gültige E-Mail Adresse" },
                  }}
                />
              </Grid>
            </>
          )}
          {!isConversationAnswer && (
            <Grid item xs={12}>
              <TextFieldElement
                label={"Betreff"}
                name={"subject"}
                validation={{
                  required: "Betreff ist ein Pflichtfeld",
                }}
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sx={{
              marginTop: "1rem",
              minHeight: "37vh",
              border: "1px solid grey",
              padding: "1rem",
            }}
          >
            <Editor
              toolbar={{
                options: ["inline", "list", "link"],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
                list: {
                  options: ["unordered", "ordered"],
                },
              }}
              stripPastedStyles
              editorState={formContext.watch("editorState")}
              onEditorStateChange={(editorState: EditorState) => {
                formContext.setValue("editorState", editorState);
                saveEditorState(formContext.watch());
              }}
              localization={{ locale: "de" }}
              toolbarCustomButtons={[
                <AddTextBlock
                  product={product}
                  formValues={formContext.watch()}
                  onChange={undefined}
                  editorState={undefined}
                />,
              ]}
            />
          </Grid>
          {mediaObjects && (
            <Grid item xs={12} sx={{ padding: "1rem 0" }}>
              <MultiSelectElement
                fullWidth
                label={"Datei anhängen"}
                name={"attachedMediaObjects"}
                options={_.map(mediaObjects, (mediaObject) => ({
                  id: mediaObject.id,
                  label: mediaObject.description || "Ohne Beschreibung",
                }))}
                showCheckbox={true}
                renderValue={(mediaObjectIds: any) => getRenderValue(mediaObjectIds, mediaObjects)}
              />
            </Grid>
          )}
          <Grid item xs={3} />
          <Grid item xs={12} md={6}>
            <ButtonLoading
              variant={"contained"}
              type={"submit"}
              isLoading={isLoading}
              disabled={attachmentsFileLimitReached}
            >
              Nachricht senden
            </ButtonLoading>
          </Grid>
          <Grid item xs={3}>
            <Tooltip title={"Inhalt wiederherstellen"}>
              <IconButton onClick={() => loadContentFromSessionStorage()} size="large">
                <SettingsBackupRestore />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </FormContainer>
    </LegalbirdIoModal>
  );
}

const getRecipientMenuItems = (product: AbstractCase | null) => {
  if (!product) {
    return [];
  }

  let menuItems = [];
  menuItems.push(
    <MenuItem value={"customer"} key={"customer"}>
      Kunde
    </MenuItem>,
  );
  if (product.productCategory === "contractLaw") {
    menuItems.push(
      <MenuItem
        key={"contractualPartner"}
        value={"contractualPartner"}
        disabled={
          !(product.contractualPartnerType === "contractualPartnerLegalEntity"
            ? product.contractualPartnerLegalEntity && product.contractualPartnerLegalEntity.email
            : product.contractualPartnerPerson && product.contractualPartnerPerson.email)
        }
      >
        Gegenseite
      </MenuItem>,
      <MenuItem
        key={"debtCollectionAgency"}
        disabled={!(product.debtCollectionAgency && product.debtCollectionAgency.email)}
        value={"debtCollectionAgency"}
      >
        Inkassobüro
      </MenuItem>
    );
  }

  if(product.productCategory === "contractLaw" || ["settlement", "divorce"].includes(product.productClassName)) {
    menuItems.push(
      <MenuItem
        key={"opponentLawFirm"}
        disabled={!(product.opponentLawFirm && product.opponentLawFirm.email)}
        value={"opponentLawFirm"}
      >
        Anwalt Gegenseite
      </MenuItem>
    );
  }

  if (product.productClassName === "settlement") {
    menuItems.push(
      <MenuItem value={"employerOrganization"} key={"employerOrganization"}>
        E-Mail-Adresse Gegenseite
      </MenuItem>
    );
  }
  if (!!product.processParticipantsPeople) {
    menuItems.push(
      _.map(product.processParticipantsPeople, (person, index) => {
        return (
          <MenuItem key={index} value={"processParticipantsPeople[" + index + "].email"} disabled={!person.email}>
            {translate("participant.labelType.values." + person.labelType) +
              " - " +
              person.givenName +
              " " +
              person.familyName}
          </MenuItem>
        );
      })
    );
  }
  if (!!product.processParticipantsOrganizations) {
    menuItems.push(
      _.map(product.processParticipantsOrganizations, (organization, index) => {
        return (
          <MenuItem
            key={index}
            value={"processParticipantsOrganizations[" + index + "].email"}
            disabled={!organization.email}
          >
            {translate("participant.labelType.values." + organization.labelType) + " - " + organization.legalName}
          </MenuItem>
        );
      })
    );
  }
  if (product.paymentType === "insurance") {
    menuItems.push(
      <MenuItem key={"insurance"} disabled={!product.insurance.insurance?.email} value={"insurance"}>
        Rechtsschutzversicherung
      </MenuItem>
    );
  }
  if (getAdvoAssistRepresentative(product)) {
    menuItems.push(
      <MenuItem key={"advoAssistRepresentative"} value={"advoAssistRepresentative"}>
        Terminsvertreter
      </MenuItem>
    );
  }
  menuItems.push(
    <MenuItem value={"custom"} key={"custom"}>
      Frei auswählen
    </MenuItem>
  );

  return menuItems;
};

const getEmailTemplates = (templatesCollection: any, product: AbstractCase | null) => {
  if (!product || !templatesCollection) {
    return [];
  }
  const templates = templatesCollection["hydra:member"];
  const generalTemplateList = _.filter(templates, (template) => template.productClassName === "general");
  const categoryTemplateList = _.filter(templates, (template) => template.productClassName === product.productCategory);
  const productTemplateList = _.filter(templates, (template) => template.productClassName === product.productClassName);

  const emailTemplates: any = [
    <MenuItem key={0} value={"no_template"}>
      Leerer Entwurf
    </MenuItem>,
  ];
  emailTemplates.push(
    _.map(generalTemplateList, (template) => (
      <MenuItem key={template.id} value={template.id}>
        {template.label}
      </MenuItem>
    ))
  );
  emailTemplates.push(
    _.map(categoryTemplateList, (template) => (
      <MenuItem key={template.id} value={template.id}>
        {template.label}
      </MenuItem>
    ))
  );
  emailTemplates.push(
    _.map(productTemplateList, (template) => (
      <MenuItem key={template.id} value={template.id}>
        {template.label}
      </MenuItem>
    ))
  );
  return emailTemplates;
};

const copyBccMailToClipboard = async (bccMail: string) => {
  await navigator.clipboard.writeText(bccMail);
};

const getRenderValue = (mediaObjectIds: number[], mediaObjects: MediaObject[]) => {
  return _.map(
    mediaObjectIds,
    (mediaObjectId) => _.find(mediaObjects, (mediaObject) => mediaObject.id === mediaObjectId)?.description
  ).join(", ");
};

const getDefaultRecipientEmail = (recipientType: string, product: AbstractCase) => {
  let email = "";
  switch (recipientType) {
    case "customer":
      email = _.get(product, "customer.email", "Nicht befüllt");
      break;
    case "contractualPartner":
      if (product.contractualPartnerType === "contractualPartnerLegalEntity") {
        email = _.get(product, "contractualPartnerLegalEntity.email", "Nicht befüllt");
        break;
      }
      email = _.get(product, "contractualPartnerPerson.email", "Nicht befüllt");
      break;
    case "opponentLawFirm":
      email = _.get(product, "opponentLawFirm.email", "Nicht befüllt");
      break;
    case "debtCollectionAgency":
      email = _.get(product, "debtCollectionAgency.email", "Nicht befüllt");
      break;
    case "insurance":
      email = _.get(product, "insurance.insurance.email", "Nicht befüllt");
      break;
    case "advoAssistRepresentative":
      email = getAdvoAssistRepresentative(product)?.email || "";
      break;
    case "employerOrganization":
      email = _.get(product, "employerOrganization.email", "Nicht befüllt");
      break;
    case "custom":
      break;
    default:
      email = _.get(product, recipientType, "");
      break;
  }
  return email;
};

const getTemplateData = (
  selectedTemplateId: string | number,
  templates: any[],
  product: AbstractCase,
  values: any,
  signature: string
) => {
  if (selectedTemplateId === "no_template") {
    return {
      subject: " (Unser Zeichen: " + product?.reference + ")",
      editorState: htmlToEditorState("<p></p>" + signature),
    };
  }
  const templateToDisplay = _.find(templates, (template) => template.id === selectedTemplateId);
  return {
    subject: fillTemplate(templateToDisplay.subject, product, values) + " (Unser Zeichen: " + product.reference + ")",
    editorState: htmlToEditorState(fillTemplate(templateToDisplay.content, product, values) + signature),
  };
};

const saveEditorState = _.debounce((values: any) => {
  let valuesToPersist = _.merge({}, values);
  valuesToPersist.editorState = editorStateToHTML(values.editorState);
  saveSessionStorageState("email", valuesToPersist);
}, 3000);
