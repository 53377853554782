import React, { FocusEventHandler } from "react";
import { FormControl, InputLabel, Select, SelectChangeEvent } from "@mui/material";

type LabledSelectProps = {
  label: string;
  value: string;
  onChange: (event: SelectChangeEvent) => void;
  children: Array<JSX.Element>;
  disabled?: boolean;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export default function LabeledSelect({
  label,
  value,
  onChange,
  children,
  disabled,
  onFocus,
  onBlur,
}: LabledSelectProps) {
  return (
    <FormControl fullWidth>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        label={label}
        labelId="select-label"
        value={value}
        onChange={onChange}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {children}
      </Select>
    </FormControl>
  );
}
