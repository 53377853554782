import * as status from "../LeadStatus/StatusBasicCase";

const STAGE_CREATED = 10;
const STAGE_DATA_COMPLETED = 20;
const STAGE_CHECKED = 50;
const STAGE_IN_PROGRESS = 100;
const STAGE_COMPLETED = 200;

export { STAGE_CREATED, STAGE_DATA_COMPLETED, STAGE_CHECKED, STAGE_IN_PROGRESS, STAGE_COMPLETED };

export const stageMetaDataBasicCase = {
  [STAGE_CREATED]: {
    label: "Daten befüllt",
    stage: STAGE_CREATED,
    leadStatus: status.STATUS_CREATED,
    isActive: () => true,
    isClickable: () => false,
    showInBoard: false,
  },
  [STAGE_DATA_COMPLETED]: {
    label: "Kundendaten vollständig",
    stage: STAGE_DATA_COMPLETED,
    leadStatus: status.STATUS_DATA_COMPLETED,
    isActive: () => true,
    isClickable: ({ product }) => product.stage >= STAGE_CREATED,
    showInBoard: true,
  },
  [STAGE_CHECKED]: {
    label: "Überprüft",
    stage: STAGE_CHECKED,
    leadStatus: status.STATUS_CHECKED,
    isActive: () => true,
    isClickable: ({ product }) => product.stage >= STAGE_DATA_COMPLETED,
    showInBoard: true,
  },
  [STAGE_IN_PROGRESS]: {
    label: "In Bearbeitung",
    stage: STAGE_IN_PROGRESS,
    leadStatus: status.STATUS_IN_PROGRESS,
    isActive: () => true,
    isClickable: ({ product }) => product.stage >= STAGE_CHECKED,
    showInBoard: true,
  },
  [STAGE_COMPLETED]: {
    label: "Abgeschlossen",
    stage: STAGE_COMPLETED,
    leadStatus: status.STATUS_COMPLETED,
    isActive: () => true,
    isClickable: ({ product }) => product.stage >= STAGE_IN_PROGRESS,
    showInBoard: true,
  },
};
