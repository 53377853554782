import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { apiGet } from "../../../services/Api/apiCall";

function ResendEmailModal({ product, open, handleClose }) {
  const [isLoading, setIsLoading] = useState(false);

  const resetPassword = useCallback(async () => {
    setIsLoading(true);
    const customer = await apiGet(product.customer["@id"]);
    await apiGet("reset_password", null, {
      email: customer.email,
      backofficeCaseId: product.backofficeCase.id,
    });
    setIsLoading(false);
    handleClose();
  }, [product]);

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={product.customer.verification !== null ? "Passwort neu versenden" : "Bestätigung neu versenden"}
      submitButton={
        <ButtonLoading onClick={resetPassword} color="primary" variant={"contained"} isLoading={isLoading}>
          E-Mail senden
        </ButtonLoading>
      }
    >
      <p className={"center"}>
        {product.customer.verification !== null ? (
          <>Möchten Sie dem Kunden eine E-Mail zum Ändern des Passwortes zusenden?</>
        ) : (
          <>
            Möchten Sie dem Kunden die Bestätigungs-E-Mail erneut zusenden, damit er sein Kundenkonto bei Legalbird
            bestätigen kann?
          </>
        )}
      </p>
    </LegalbirdIoModal>
  );
}

ResendEmailModal.propTypes = {
  product: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ResendEmailModal;
