import customerTranslations from "./customerTranslations";
import basicCaseTranslations from "./basicCaseTranslations";
import contractClaimTranslations from "./contractClaimTranslations";
import litigationCaseTranslations from "./litigationCaseTranslations";

const vehicleContractTranslations = {
  customer: customerTranslations,
  ...basicCaseTranslations,
  ...litigationCaseTranslations,
  ...contractClaimTranslations,
  label: "Fahrzeugkaufvertrag",
  reasonForDispute: {
    label: "Problem des Kunden",
    values: {
      other: "Sonstiges",
    },
  },
  buyerOrSeller: {
    label: "Käufer oder Verkäufer",
    values: {
      buyer: "Käufer",
      seller: "Verkäufer",
    },
  },
  vehicleMake: {
    label: "Marke Fahrzeug",
  },
  model: {
    label: "Modell Fahrzeug",
  },
  vehicleId: {
    label: "Fahrzeugidentifikationsnr.",
  },
  purchasePrice: {
    label: "Kaufpreis",
  },
  locationOfGood: {
    label: "Standort des Fahrzeugs",
    values: {
      buyer: "beim Käufer",
      seller: "beim Verkäufer",
      elsewhere: "an einem anderen Ort",
      unknown: "unbekannt",
    }
  },
  hasDefects: {
    label: "Mängel vorhanden",
  },
  isAccidentCar: {
    label: "Unfallfahrzeug"
  },
  defectsRecognitionDate: {
    label: "Mangel erkannt am",
  },
  defectsDescriptionClient: {
    label: "Mängelbeschreibung Mandant",
  },
  defectsDescriptionLawFirm: {
    label: "Mängelbeschreibung Kanzlei",
  },
  numberRepairAttempts: {
    label: "Nachbesserungsversuche",
    values: {
      0: "Keine",
      1: "Einmal",
      2: "Zweimal",
      3: "Dreimal oder häufiger",
    },
  },
  numberReplacementAttempts: {
    label: "Ersatzversuche",
    values: {
      0: "Keine",
      1: "Einmal",
      2: "Zweimal",
      3: "Dreimal oder häufiger",
    },
  },
  mileageAtPurchase: {
    label: "Kilometerstand bei Kauf",
  },
  currentMileage: {
    label: "Aktueller Kilometerstand",
  },
  kilometersDriven: {
    label: "Gefahrene Kilometer",
  },
  expectedTotalMileage: {
    label: "Erwartete Laufleistung",
    values: {
      150000: "150.000 km (Kleinwagen)",
      200000: "200.000 km (Kompakt-/Mittelklasse)",
      250000: "250.000 km (geh. Mittelklasse)",
      300000: "300.000 km (Oberklasse)",
    }
  }
};

export default vehicleContractTranslations;
