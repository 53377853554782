import makeStyles from '@mui/styles/makeStyles';

const useStylesAllDataPage = makeStyles(() => ({
  smallHeadline: {
    textAlign: "center",
    fontSize: "1rem",
  },
  headlineName: {
    textAlign: "center",
  },
  heading: {
    marginTop: "3rem",
    marginBottom: "3rem",
  },
  naviExtension: {
    marginTop: "-2rem",
    position: "sticky",
    top: 75,
    zIndex: 200,
  },
}));

export default useStylesAllDataPage;
