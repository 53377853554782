import React, { useState } from "react";
import useStyles from "./navigationBarAccountMenuStyle";
import IconButton from "@mui/material/IconButton";
import { AccountCircleOutlined } from "@mui/icons-material";
import { Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { logout } from "../../store/backofficeUser/actions";
import { Link, useNavigate } from "react-router-dom";
import { userHasRole } from "../../services/backofficeUserService";
import { Roles } from "../../types/BackofficeUser";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../services/ReactQuery/reactQueryService";

const NavigationBarAccountMenu = () => {
  const classes = useStyles({});
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();

  const handleButtonClick = (event) => {
    setAnchorEl(event.target);
    setAccountMenuOpen(true);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const clearImpersonate = () => {
    sessionStorage.removeItem("impersonate");
    queryClient.invalidateQueries(queryKeys.all("current_user")).then(() => navigate("/"));
  };

  return (
    <>
      <IconButton onClick={handleButtonClick} size="large">
        <AccountCircleOutlined className={classes.loginIcon} />
      </IconButton>
      <Menu open={accountMenuOpen} onClose={() => setAccountMenuOpen(false)} anchorEl={anchorEl}>
        {!userHasRole(currentUser, Roles.callcenter) && (
          <MenuItem
            component={Link}
            to={"/services" + (userHasRole(currentUser, "ROLE_EXTERNAL") ? "/extern" : "") + "/mein-konto"}
            onClick={() => setAccountMenuOpen(false)}
          >
            Mein Konto
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>Abmelden</MenuItem>
        {!!sessionStorage.getItem("impersonate") && (
          <MenuItem onClick={clearImpersonate}>Benutzeransicht beenden</MenuItem>
        )}
      </Menu>
    </>
  );
};

export default NavigationBarAccountMenu;
