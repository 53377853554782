import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  paper: {
    border: "1px solid #db1919",
    borderRadius: 5,
    padding: "1rem 24px 1rem 24px",
    marginBottom: "2rem",
  },
  headline: {
    margin: "1rem 0",
    fontSize: "1.25rem",
    textAlign: "center",
    color: "#db1919",
  },
}));

export default useStyles;
