import { createTheme } from "@mui/material";

const defaultTheme = createTheme({
  palette: {
    primary: {
      light: "#4b9f9c",
      main: "#137f7b",
      dark: "#086662",
      contrastText: "rgb(255, 255, 255)",
    },
    secondary: {
      light: "#017b88",
      main: "#333333",
      dark: "#013b39",
      contrastText: "rgb(255, 255, 255)",
    },
    text: {
      primary: "#333333",
      secondary: "#666666",
      light: "#999999",
      disabled: "#333333",
    },
    background: {
      default: "#f4f4f4",
      white: "#ffffff"
    },
    border: {
      active: "#137f7b",
      inActive: "#dadada",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        a: {
          color: "#137f7b",
          textDecoration: "none"
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          "&.Mui-disabled": {
            color: "#999999"
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            fieldset: {
              borderStyle: "dashed",
            }
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        margin: "normal",
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 4,
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
        }
      },
      defaultProps: {
        fullWidth: true,
        color: "primary",
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        }
      }
    }
  },
  typography: {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    body1: {
      fontSize: "0.875rem",
    },
    h1: {
      fontSize: "2.25rem",
      fontWeight: 400,
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 400,
    },
    h4: {
      fontSize: "1.25rem",
      fontWeight: 400,
    },
    h5: {
      fontSize: "1rem",
    }
  },
});

export default defaultTheme;
