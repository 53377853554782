import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  headline: {
    paddingTop: "2rem",
    textAlign: "center",
  },
  outerContainer: {
    overflowX: "auto",
    height: "calc(100vh - 96px)",
  },
  outerContainerWithTechStatus: {
    overflowX: "auto",
    height: "calc(100vh - 148px)",
  },
}));

export default useStyles;
