import * as status from "../LeadStatus/StatusDivorce";

const STAGE_INCOMPLETE = 0;
const STAGE_READY = 10;
const STAGE_CHECKED = 20;
const STAGE_PROCESS_PAID = 30;
const STAGE_SUBMITTED = 40;
const STAGE_JUDICIAL_PROCESS = 45;
const STAGE_DATE_SET = 50;
const STAGE_HEARING_PAID = 60;
const STAGE_DIVORCE_DONE = 70;
const STAGE_COMPLETED = 80;

export {
  STAGE_INCOMPLETE,
  STAGE_READY,
  STAGE_CHECKED,
  STAGE_PROCESS_PAID,
  STAGE_SUBMITTED,
  STAGE_JUDICIAL_PROCESS,
  STAGE_DATE_SET,
  STAGE_HEARING_PAID,
  STAGE_DIVORCE_DONE,
  STAGE_COMPLETED,
};

export const stageMetaDataDivorce = {
  [STAGE_INCOMPLETE]: {
    label: "Unvollständig",
    stage: STAGE_INCOMPLETE,
    leadStatus: null,
    isActive: () => true,
    isClickable: () => false,
    showInBoard: true,
  },
  [STAGE_READY]: {
    label: "Vollständig",
    stage: STAGE_READY,
    leadStatus: status.STATUS_DOCUMENTS_COMPLETE,
    isActive: () => true,
    isClickable: ({ product }) => product.stage >= STAGE_CHECKED,
    showInBoard: true,
  },
  [STAGE_CHECKED]: {
    label: "Überprüft",
    stage: STAGE_CHECKED,
    leadStatus: status.STATUS_CHECKED,
    isActive: () => true,
    isClickable: ({ product }) => product.stage === STAGE_READY || product.stage >= STAGE_PROCESS_PAID,
    showInBoard: true,
  },
  [STAGE_PROCESS_PAID]: {
    label: "Verfahrensgebühr bezahlt",
    stage: STAGE_PROCESS_PAID,
    leadStatus: status.STATUS_PROCESS_PAID,
    isActive: ({ product }) => product.paymentType === "private",
    isClickable: ({ product }) =>
      (product.stage === STAGE_CHECKED || product.stage >= STAGE_SUBMITTED) && product.paymentType === "private",
    showInBoard: true,
  },
  [STAGE_SUBMITTED]: {
    label: "Antrag eingereicht",
    stage: STAGE_SUBMITTED,
    leadStatus: status.STATUS_SUBMITTED,
    isActive: () => true,
    isClickable: ({ product }) =>
      product.stage === STAGE_PROCESS_PAID ||
      product.stage >= STAGE_JUDICIAL_PROCESS ||
      (product.stage === STAGE_CHECKED && product.paymentType !== "private"),
    showInBoard: true,
  },
  [STAGE_JUDICIAL_PROCESS]: {
    label: "Gerichtliches Verfahren",
    stage: STAGE_JUDICIAL_PROCESS,
    leadStatus: status.STATUS_JUDICIAL_PROCESS,
    isActive: () => true,
    isClickable: ({ product }) => product.stage >= STAGE_PROCESS_PAID && product.stage !== STAGE_JUDICIAL_PROCESS,
    showInBoard: true,
  },
  [STAGE_DATE_SET]: {
    label: "Scheidungstermin",
    stage: STAGE_DATE_SET,
    leadStatus: status.STATUS_DATE_SET,
    isActive: () => true,
    isClickable: ({ product }) => product.stage >= STAGE_SUBMITTED && product.stage !== STAGE_DATE_SET,
    showInBoard: true,
  },
  [STAGE_HEARING_PAID]: {
    label: "Terminsgebühr bezahlt",
    stage: STAGE_HEARING_PAID,
    leadStatus: status.STATUS_HEARING_PAID,
    isActive: ({ product }) => product.paymentType === "private",
    isClickable: ({ product }) =>
      (product.stage === STAGE_DATE_SET || product.stage >= STAGE_DIVORCE_DONE) && product.paymentType === "private",
    showInBoard: true,
  },
  [STAGE_DIVORCE_DONE]: {
    label: "Durchgeführt",
    stage: STAGE_DIVORCE_DONE,
    leadStatus: status.STATUS_DIVORCE_DONE,
    isActive: () => true,
    isClickable: ({ product }) =>
      product.stage === STAGE_HEARING_PAID ||
      product.stage >= STAGE_COMPLETED ||
      (product.stage === STAGE_DATE_SET && product.paymentType !== "private"),
    showInBoard: true,
  },
  [STAGE_COMPLETED]: {
    label: "Abgeschlossen",
    stage: STAGE_COMPLETED,
    leadStatus: status.STATUS_COMPLETED,
    isActive: () => true,
    isClickable: ({ product }) => product.stage === STAGE_DIVORCE_DONE,
    showInBoard: true,
  },
};
