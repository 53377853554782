import { AbstractCase } from "../../../types/AbstractCase";
import * as stagesTraffic from "../../Stages/StagesTraffic";
import { STAGE_INSURANCE_COVERAGE_REQUEST } from "../../Stages/StagesTraffic";
import { translate } from "../../Translations/translatorService";

const getActionAvailable = (product: AbstractCase) => {
  if (!product.stage) {
    return false;
  }

  return (
    product.paymentType !== "private" &&
    product.stage >= stagesTraffic.STAGE_CHECKED &&
    product.stage < stagesTraffic.STAGE_TRIAL
  );
};

const getPaymentSwitchAllowed = (product: AbstractCase) => {
  switch (true) {
    case !product.finalFineAmount:
      return { allowed: false, message: "Für den Wechsel der Zahlart muss das Feld “Bußgeldhöhe in €“ gefüllt sein." };
    case product.stage === STAGE_INSURANCE_COVERAGE_REQUEST:
      return {
        allowed: false,
        message: "In der Stage “Deckungsanfrage” ist die Funktion nicht möglich. Bitte auf eine andere Stage schieben.",
      };
    default:
      return { allowed: true };
  }
};

export const paymentTypeSwitchDataTraffic = {
  getPaymentSwitchAllowed: getPaymentSwitchAllowed,
  getActionAvailable: getActionAvailable,
  options: [
    { value: "private", label: translate("traffic.paymentType.values.private"), disabled: false },
    {
      value: "insurance",
      label: translate("traffic.paymentType.values.insurance"),
      disabled: true
    },
  ],
};
