import React from "react";
import { Link, Stack } from "@mui/material";
import MimeTypeIcon from "../../MediaObject/MimeTypeIcon";
import { ExternalLawyerInvoice } from "../../../types/ExternalLawyerInvoice";
import moment from "moment";

type ExternalLawyerInvoiceRowProps = {
  invoice: ExternalLawyerInvoice;
};

export default function ExternalLawyerInvoiceRow({ invoice }: ExternalLawyerInvoiceRowProps) {
  return (
    <Stack direction={"row"} alignItems={"center"} spacing={3} sx={{ margin: "1rem 0" }}>
      <MimeTypeIcon mimeType={invoice.mediaObject.mimeType} circled />
      <Link download href={invoice.mediaObject.downloadUrl} underline={"none"}>
        {invoice.mediaObject.originalName} vom {moment(invoice.created).format("DD.MM.YYYY")}
      </Link>
    </Stack>
  );
}
