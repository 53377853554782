import React, { useState } from "react";
import { MediaObject } from "../../types/MediaObject";
import { Box, Stack } from "@mui/material";
import DisplayMediaObject from "../MediaObjectSection/DisplayMediaObject";
import MimeTypeIcon from "./MimeTypeIcon";

type DisplayableMediaObjectProps = {
  mediaObject: MediaObject;
  label: string;
};
export default function DisplayableMediaObject({ mediaObject, label }: DisplayableMediaObjectProps) {
  const [displayMediaObjectOpen, setDisplayMediaObjectOpen] = useState<boolean>(false);
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        onClick={() => setDisplayMediaObjectOpen(true)}
        sx={{ cursor: "pointer" }}
      >
        <MimeTypeIcon mimeType={mediaObject.mimeType} /> <Box sx={{ color: "primary.main" }}>{label}</Box>
      </Stack>
      <DisplayMediaObject
        mediaObject={displayMediaObjectOpen ? mediaObject : null}
        closeDialog={() => setDisplayMediaObjectOpen(false)}
      />
    </>
  );
}
