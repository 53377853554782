import personTranslations from "./personTranslations";
import addressTranslations from "./addressTranslations";

const participantTranslations = {
  ...personTranslations,
  ...addressTranslations,
  labelType: {
    label: "Rolle im Verfahren",
    values: {
      expert: "Gutachter/Sachverständiger",
      witness: "Zeuge",
      judge: "Richter",
      legalRepresentative: "gesetzlicher Vertreter",
      interpreter: "Dolmetscher",
      insolvencyAdministrator: "Insolvenzverwalter",
      other: "Sonstiger Prozessbeteiligter",
    },
  },
  address: addressTranslations,
  legalName: {
    label: "Name des Unternehmens",
  },
};

export default participantTranslations;
