const documentTranslations = {
  reference: {
    label: "Unser Zeichen",
  },
  paymentMethod: {
    label: "Zahlart",
    values: {
      creditcard: "Kreditkarte",
      paypal: "PayPal",
      sofort: "Sofortüberweisung",
    },
  },
  orderDate: {
    label: "Bestelldatum",
  },
};

export default documentTranslations;
