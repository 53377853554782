const preleadTranslations = {
  landingPage: {
    label: "Landingpage",
  },
  classification: {
    label: "Getrennt seit",
    values: {
      easy: "Länger als 10 Monate",
      medium: "Kürzer als 10 Monate",
      hard: "Nocht nicht getrennt",
    },
  },
  numberOfEmployees: {
    label: "Anzahl Mitarbeiter",
    values: {
      10: "0 - 10",
      50: "11 - 50",
      500: "51 - 500",
      1000: "Mehr als 500",
    },
  },
  employedSince: {
    label: "Im Unternehmen seit",
  },
  grossSalary: {
    label: "Mtl. Bruttogehalt",
  },
};

export default preleadTranslations;
