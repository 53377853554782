import customerTranslations from "./customerTranslations";
import basicCaseTranslations from "./basicCaseTranslations";
import contractClaimTranslations from "./contractClaimTranslations";

const membershipContractTranslations = {
  customer: customerTranslations,
  ...basicCaseTranslations,
  ...contractClaimTranslations,
  label: "Mitgliedschaft",
  reasonForDispute: {
    label: "Problem mit Mitgliedschaft",
    values: {
      serviceUnavailable: "Mitgliedschaft nicht (voll) nutzbar",
      cancellationRefused: "Kündigung wurde nicht akzeptiert",
      invoiceIncorrect: "Fehlerhafte Rechnung erhalten",
      cancellationByContractPartner: "Vertrag wurde von Vertragspartner gekündigt",
      other: "Anderes Problem",
      priceIncrease: "Vertragspartner hat die Preise erhöht",
    },
  },
  serviceUnavailableOffer: {
    label: "Ersatzangebot des Gegners",
    values: {
      coupon: "Gutscheine",
      freeMonths: "Freimonate",
      couponFreeMonths: "Gutscheine und Freimonate",
      noOffer: "Kein Angebot",
    },
  },
  cancellationDate: {
    label: "Kündigungsdatum",
  },
  endOfContractDate: {
    label: "Datum Vertragsende",
  },
  contractNumber: {
    label: "Vertrags-/Mitgliedsnummer",
  },
  membershipType: {
    label: "Art der Mitgliedschaft",
    values: {
      gym: "Fitnessstudio",
      club: "Verein",
      other: "Andere Mitgliedschaft",
    },
  },
  feePaymentFrequency: {
    label: "Zahlung erfolgt",
    values: {
      monthly: "Monatlich",
      quaterly: "Pro Quartal",
      yearly: "Jährlich",
    },
  },
  fee: {
    label: "Betrag in €",
  },
  serviceUnavailableFromDate: {
    label: "Nicht nutzbar von",
  },
  serviceUnavailableToDate: {
    label: "Nicht nutzbar bis",
  },
  numberDaysAvailableInBetween: {
    label: "Anzahl nutzbare Tage",
  },
  priceIncreaseAnnouncementDate: {
    label: "Datum Ankündigung Preiserhöhung",
  },
  numberUnpaidPeriods: {
    label: "Anzahl nicht bezahlter Perioden",
  },
  opponentReactionToCancellation: {
    label: "Reaktion Gegenseite auf Kündigung",
    values: {
      noReaction: "Keine Reaktion",
      cancellationRefused: "Kündigung abgelehnt",
      differentDate: "Anderes Kündigungsdatum/Verlängerung",
    },
  },
  endOfContractDateOpponent: {
    question: "Zu welchem Datum endet laut Gegenseite Ihr Vertrag?",
    label: "Vertragsende laut Gegenseite",
  },
};

export default membershipContractTranslations;
