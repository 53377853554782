import PropTypes from "prop-types";
import React, { useState } from "react";
import { Grid, MenuItem } from "@mui/material";
import ApiClient from "../../../../services/ApiClient";
import useForm from "../../../../hooks/useForm";
import LegalbirdIoModal from "../../../Modal/LegalbirdIoModal";
import SubmitButton from "../../../Button/SubmitButton";
import ValidatorSelect from "../../../Validator/ValidatorSelect";
import { formValue } from "../../../../services/formServiceFunctions";
import adminDeleteModalStyle from "./adminDeleteModalStyle";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(adminDeleteModalStyle);

function AdminDeleteModal({ product, open, handleClose, refreshPage, hasBookingEntries, statusProceededToFarOpen }) {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const initialValues = {
    deleteType: "",
  };

  const onSubmit = async () => {
    setIsLoading(true);
    switch (values.deleteType) {
      case "case":
        await ApiClient.delete("backoffice_cases/" + product.backofficeCase.id);
        break;
      case "customer":
        await ApiClient.put("customers/" + product.customer.id, { body: JSON.stringify({ isFake: true }) });
        break;
      default:
        break;
    }
    refreshPage();
    handleClose();
  };

  const { values, handleSubmit, handleChange, clearForm } = useForm({
    initialValues,
    onSubmit,
    identifier: product,
  });

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={"Daten löschen"}
      submitButton={
        <SubmitButton
          isLoading={isLoading}
          disabled={values.deleteType === ""}
          onClick={handleSubmit}
          variant={"contained"}
        >
          Löschen
        </SubmitButton>
      }
    >
      <p>Möchten Sie nur den Fall löschen, oder alle Daten und Fälle zu dem Nutzer?</p>
      <Grid container>
        <Grid item xs={12}>
          <ValidatorSelect
            label="Löschen von"
            name="deleteType"
            value={formValue(values, "deleteType")}
            onChange={handleChange}
          >
            <MenuItem value={"case"}>Nur dieser Fall</MenuItem>
            <MenuItem value={"customer"}>Alle Daten und Fälle</MenuItem>
          </ValidatorSelect>
          {hasBookingEntries && <p className={classes.warning}>Achtung: Dieser Fall hat Buchungseinträge!</p>}
          {statusProceededToFarOpen && (
            <p className={classes.warning}>
              Achtung: Dieser Fall ist schon zu weit fortgeschritten und unterliegt der Aufbewahrungspflicht!
            </p>
          )}
        </Grid>
      </Grid>
    </LegalbirdIoModal>
  );
}

AdminDeleteModal.propTypes = {
  product: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refreshPage: PropTypes.func,
};

AdminDeleteModal.defaultProps = {
  refreshPage: () => {},
};
export default AdminDeleteModal;
