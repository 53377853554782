import { useCallback, useEffect, useRef, useState } from "react";
import { formValidate } from "../services/formServiceFunctions";
import _ from "lodash";
import * as PropTypes from "prop-types";

const useForm = ({ initialValues = {}, onSubmit, hasRedirectOnSubmit = false, identifier = "", clearFormAfterSubmit = false }) => {
  const [values, setValues] = useState(initialValues);
  const [touchedValues, setTouchedValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const validators = useRef({});
  const dependentValidationFields = useRef({});

  const clearForm = () => {
    setValues(initialValues);
    setErrors({});
    setTouchedValues({});
    setIsLoading(false);
  };

  useEffect(clearForm, [identifier]);

  const handleChange = useCallback(({ target }) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setValues((prevData) => {
      return _.set(_.merge({}, prevData), name, value);
    });
    setTouchedValues((prevData) => {
      return _.set(_.merge({}, prevData), name, true);
    });
  }, []);

  const handleBlur = ({ target }) => {
    const { name, value } = target;
    setTouchedValues((prevData) => {
      return _.set(_.merge({}, prevData), name, true);
    });
    let valuesToValidate = _.pick(values, dependentValidationFields.current[name] || []);
    valuesToValidate[name] = value;
    formValidate(valuesToValidate, validators.current).then((currrentFieldError) =>
      setErrors(() => {
        return _.pickBy(_.merge({}, errors, currrentFieldError));
      })
    );
  };

  const updateAllValues = useCallback((updatedValues) => {
    setValues(updatedValues);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    formValidate(values, validators.current).then((currentErrors) => {
      currentErrors = _.pickBy(currentErrors);
      setErrors(currentErrors);
      if (!_.isEmpty(currentErrors)) {
        return;
      }
      setIsLoading(true);
      onSubmit({ values, e: currentErrors }).then(
        () => {
          if (hasRedirectOnSubmit) {
            return;
          }
          if (clearFormAfterSubmit) {
            clearForm();
          } else {
            setTouchedValues({});
          }
          setIsLoading(false);
        },
        (error) => {
          throw error;
        }
      );
    });
  };

  const registerValidators = useCallback((registerName, registerValidators, dependendFields = []) => {
    validators.current[registerName] = registerValidators;
    dependentValidationFields.current[registerName] = dependendFields;
  }, []);

  const handleDateChange = useCallback(
    (date, name) => {
      handleChange({ target: { name: name, value: date } });
    },
    [handleChange]
  );

  return {
    values,
    touchedValues,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    registerValidators,
    clearForm,
    isLoading,
    handleDateChange,
    updateAllValues,
  };
};

useForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  hasRedirectOnSubmit: PropTypes.bool,
};

export default useForm;
