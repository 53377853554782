import React, { useRef, useState } from "react";
import * as PropTypes from "prop-types";
import useStyles from "./emailAttachmentStyle";
import { Grid, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import _ from "lodash";
// import DisplayMediaObject from "../MediaObjectSection/DisplayMediaObject";
import { API_HOST, API_PATH } from "../../config/_entrypoint";
import { loadToken } from "../../services/localStorage";
import MediaObjectFormModal from "../Case/CaseUploadFile/MediaObjectFormModal";

const EmailAttachment = ({ attachment, emailId, product }) => {
  const classes = useStyles({});
  const myAnchor = useRef();

  const [menuOpen, setMenuOpen] = useState(false);
  const [addAttachmentModalOpen, setAddAttachmentModalOpen] = useState(false);

  return (
    <Grid container className={classes.attachment} alignItems={"center"}>
      {/*<Grid item xs={11}>
        <DisplayMediaObject mediaObject={attachment.mediaObject}/>
      </Grid>*/}
      <Grid item xs={attachment.attachmentId ? 11 : 12}>
        {_.truncate(attachment.filename)}
      </Grid>
      {attachment.attachmentId && (
        <Grid item xs={1}>
          <IconButton className={classes.iconButton} onClick={() => setMenuOpen(!menuOpen)} size="large">
            <MoreHoriz ref={myAnchor} />
          </IconButton>
          <Menu open={menuOpen} anchorEl={myAnchor.current} onClose={() => setMenuOpen(false)}>
            <MenuItem
              onClick={() => {
                setAddAttachmentModalOpen(true);
                setMenuOpen(false);
              }}
            >
              Datei zu Fall hinzufügen
            </MenuItem>
            <MenuItem
              component={"a"}
              download={true}
              href={
                API_HOST +
                API_PATH +
                "attachment/download?emailId=" +
                emailId +
                "&attachmentId=" +
                attachment.attachmentId +
                "&token=" +
                loadToken()
              }
            >
              Herunterladen
            </MenuItem>
          </Menu>
          <MediaObjectFormModal
            product={product}
            closeDialog={() => setAddAttachmentModalOpen(false)}
            open={addAttachmentModalOpen}
            fileData={{
              type: "emailAttachment",
              attachmentId: attachment.attachmentId,
              emailId: emailId,
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

EmailAttachment.propTypes = {
  attachment: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
};

export default EmailAttachment;
