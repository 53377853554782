import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 1024,
  },
  formPaper: {
    padding: "1rem",
    marginBottom: 20,
  },
  buttonContainer: {
    padding: "1rem 0",
    textAlign: "center",
  },
  switches: {
    textAlign: "left",
  },
  centered: {
    textAlign: "center",
  },
}));

export default useStyles;
