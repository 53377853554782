const STATUS_CREATED = 10;
const STATUS_DATA_COMPLETED = 20;
const STATUS_DOCUMENTS_COMPLETE = 30;
const STATUS_POWER_OF_ATTORNEY_GRANTED = 40;
const STATUS_CHECKED = 50;
const STATUS_AUTHORIZED = 53;
const STATUS_INSURANCE_COVERAGE_REQUEST = 56;
const STATUS_ACCESS_TO_RECORDS = 60;
const STATUS_EXTRAJUDICIAL_PAID = 70;
const STATUS_OPINION_SUBMITTED = 80;
const STATUS_AUTHORITY_ANSWERED = 90;
const STATUS_TRIAL = 100;
const STATUS_TRIAL_DATE_SET = 110;
const STATUS_TRIAL_PAID = 120;
const STATUS_DECISION_MADE = 130;
const STATUS_COMPLETED = 140;

export {
  STATUS_CREATED,
  STATUS_DATA_COMPLETED,
  STATUS_POWER_OF_ATTORNEY_GRANTED,
  STATUS_DOCUMENTS_COMPLETE,
  STATUS_CHECKED,
  STATUS_AUTHORIZED,
  STATUS_INSURANCE_COVERAGE_REQUEST,
  STATUS_ACCESS_TO_RECORDS,
  STATUS_EXTRAJUDICIAL_PAID,
  STATUS_OPINION_SUBMITTED,
  STATUS_AUTHORITY_ANSWERED,
  STATUS_TRIAL,
  STATUS_TRIAL_DATE_SET,
  STATUS_TRIAL_PAID,
  STATUS_DECISION_MADE,
  STATUS_COMPLETED,
};
