import React from "react";
import { Box, Hidden } from "@mui/material";
import Grid from "@mui/material/Grid";
import CircleIcon from "../../../CircleIcon/CircleIcon";
import { HistoryLog } from "../../../../types/HistoryLog";
import moment from "moment";
import { getCreatedBy } from "../../../../services/backofficeUserService";
import { useBackofficeUser } from "../../../../provider/BackofficeUserProvider";
import { QuestionAnswerOutlined } from "@mui/icons-material";
import { InquiryToCustomer } from "../../../../types/InquiryToCustomer";

type CaseHistoryInquiryToCustomerProps = {
  historyLog: HistoryLog;
  type?: string;
};

export default function CaseHistoryInquiryToCustomer({ historyLog }: CaseHistoryInquiryToCustomerProps) {
  const { backofficeUsers } = useBackofficeUser();
  const inquiryToCustomer: InquiryToCustomer = historyLog.logData.inquiryToCustomer!;
  const logDate = moment(historyLog.logDate);

  const getInquiryTypeText = (inquiryType: "documents" | "appointment" | "information" | "informationAndDocuments") => {
    switch (inquiryType) {
      case "documents":
        return "Dokumente";
      case "appointment":
        return "Termin";
      case "information":
        return "Informationen";
      case "informationAndDocuments":
        return "Informationen und Dokumente";
    }
  };
  const getDoneText = () => {
    if (inquiryToCustomer.inquiryType === "appointment") {
      if (inquiryToCustomer.doneBy === "system") {
        return "Angefragter Termin erfolgreich stattgefunden";
      }
      if (inquiryToCustomer.doneBy === "customer") {
        return "Abgelehnt durch Mandant";
      }
      return getCreatedBy(inquiryToCustomer.doneBy, backofficeUsers);
    }

    if (inquiryToCustomer.doneBy === "customer") {
      return "Beantwortung durch Mandant über Servicewelt";
    }
    return getCreatedBy(inquiryToCustomer.doneBy, backofficeUsers);
  };
  const textOrMinusIfEmpty = (text: string | null) => (!!text ? text : "-");
  const getInformationText = () => {
    let informationText = "";
    if (inquiryToCustomer.inquiryType === "appointment") {
      informationText += `Hintergrund für Gespräch: ${textOrMinusIfEmpty(
        inquiryToCustomer.inquiryTextAppointment
      )}\n\n`;
      informationText += `Begründung zur Ablehnung: ${textOrMinusIfEmpty(
        inquiryToCustomer.inquiryTextAppointmentCustomer
      )}`;
    }

    if (["information", "informationAndDocuments"].includes(inquiryToCustomer.inquiryType)) {
      informationText += `Benötigte Informationen: ${textOrMinusIfEmpty(inquiryToCustomer.inquiryTextInformation)}\n\n`;
      informationText += `Antwort Mandant: ${textOrMinusIfEmpty(inquiryToCustomer.inquiryTextInformationCustomer)}\n\n`;
    }
    if (["documents", "informationAndDocuments"].includes(inquiryToCustomer.inquiryType)) {
      informationText += `Benötigte Dokumente: ${inquiryToCustomer.requestedDocuments
        .map((document: any) => document.documentName)
        .join(", ")}\n\n`;
      informationText += `Erklärung zu Dokumenten: ${textOrMinusIfEmpty(inquiryToCustomer.inquiryTextDocuments)}\n\n`;
      informationText += `Anmerkungen und Fragen Mandant: ${textOrMinusIfEmpty(
        inquiryToCustomer.inquiryTextDocumentsCustomer
      )}\n`;
    }
    return informationText;
  };
  return (
    <>
      <Grid container wrap={"nowrap"}>
        <Hidden only={"xs"}>
          <Grid item sx={{ paddingRight: "2rem" }}>
            <CircleIcon icon={<QuestionAnswerOutlined />} type={"muiIcon"} />
          </Grid>
        </Hidden>
        <Grid item sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  fontSize: "1rem",
                  textDecoration: "line-through",
                }}
              >
                Anfrage für {getInquiryTypeText(inquiryToCustomer.inquiryType)} gestellt{" "}
                {moment(inquiryToCustomer.created).format("DD.MM.YYYY HH:mm")} |{" "}
                {getCreatedBy(inquiryToCustomer.createdBy, backofficeUsers)}
              </Box>
            </Grid>
            <Grid item>
              Erledigt: {logDate.format("DD.MM.YYYY HH:mm")} | {getDoneText()}
            </Grid>
            <Grid item xs={12} sx={{ fontSize: "1rem", paddingTop: 1 }}>
              <span style={{ whiteSpace: "pre-wrap" }}>{getInformationText()}</span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
