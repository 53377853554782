import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    [theme.breakpoints.down('lg')]: {
      padding: 0,
    },
  },
}));

export default useStyles;
