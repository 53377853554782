import React, { useState } from "react";
import { MediaObject } from "../../types/MediaObject";
import { Box, Stack } from "@mui/material";
import MimeTypeIcon from "./MimeTypeIcon";
import DisplayMediaObjects from "./DisplayMediaObjects";

type DisplayableMediaObjectProps = {
  mediaObjects: MediaObject[];
};
export default function DisplayableMediaObjects({ mediaObjects }: DisplayableMediaObjectProps) {
  const [mediaObjectToDisplay, setMediaObjectToDisplay] = useState<MediaObject | null>(null);
  return (
    <>
      {mediaObjects.map((mediaObject, index) => (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          onClick={() => setMediaObjectToDisplay(mediaObjects[index])}
          sx={{ cursor: "pointer" }}
        >
          <MimeTypeIcon mimeType={mediaObject.mimeType} />{" "}
          <Box sx={{ color: "primary.main" }}>{mediaObject.description ?? mediaObject.originalName}</Box>
        </Stack>
      ))}
      <DisplayMediaObjects
        setMediaObjectToDisplay={setMediaObjectToDisplay}
        mediaObjects={mediaObjects}
        mediaObjectToDisplay={mediaObjectToDisplay}
      />
    </>
  );
}
