import PropTypes from "prop-types";
import React, { useState } from "react";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import { Grid, MenuItem } from "@mui/material";
import ValidatorSelect from "../../Validator/ValidatorSelect";

export default function AdvoAssistOpenFeeAgreementModal({advoAssist, submitAction, open, handleClose, refreshPage}) {
  const [isLoading, setIsLoading] = useState(false);
  const [fee, setFee] = useState("open");

  const handleConfirm = async () => {
    await submitAction("open_fee_appointment", {
      isOpenFee: fee === "open"
    });
    refreshPage();
  };

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title="Gerichtstermin neu ausschreiben"
      submitButton={
        <ButtonLoading onClick={handleConfirm} color="primary" variant={"contained"} isLoading={isLoading}>
          Neu Ausschreiben
        </ButtonLoading>
      }
    >
      <p>
        Sollte mit dem Standardtarif kein Terminsvertreter gefunden werden
        können, bitte "Offen Ausschreiben" auswählen, ansonsten "Standardtarif"
      </p>
      <Grid container>
        <Grid item xs={12}>
          <ValidatorSelect
            label="Tarifauswahl"
            name="fee"
            value={fee}
            onChange={({target}) => setFee(target.value)}
          >
            <MenuItem value="open">Offene Ausschreibung - Honorarvorschläge einholen</MenuItem>
            <MenuItem value="default">Standardtarif</MenuItem>
          </ValidatorSelect>
        </Grid>
      </Grid>
    </LegalbirdIoModal>
  );

}
AdvoAssistOpenFeeAgreementModal.propTyes = {
  advoAssist: PropTypes.object.isRequired,
  submitAction: PropTypes.func.isRequired,
}