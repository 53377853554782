const signatureStyles = {
  imageBox: {
    width: "250px",
    height: "100px",
    margin: "1rem auto",
    display: "block",
  },
  button: {
    margin: "1rem auto",
    width: "200px",
    display: "block",
  },
};

export default signatureStyles;
