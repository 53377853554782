import React, { useRef, useState } from "react";
import { Menu, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import * as PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import { FilterList } from "@mui/icons-material";
import _ from "lodash";
import LegalbirdRefinementList from "./LegalbirdRefinementList";
import Overlay from "../../Overlay/Overlay";
import LegalbirdClearRefinements from "./LegalbirdClearRefinements";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    margin: "0 2rem",
  },
  filterHeadline: {
    fontSize: "1rem",
    fontWeight: 700,
    textAlign: "center",
    marginTop: "1rem",
  },
  menu: {
    minWidth: 250,
  },
  filterIcon: {
    color: "#333333",
  },
  clearRefinements: {
    marginBottom: "1rem",
  },
}));

const BoardRefinements = (props) => {
  const { refinements, filterHeadline } = props;
  const classes = useStyles(props);
  const anchor = useRef();
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  return <>
    {filterMenuOpen && <Overlay />}
    <Tooltip title={"Filter"}>
      <IconButton
        className={classes.menuButton}
        onClick={() => setFilterMenuOpen(true)}
        size="large">
        <FilterList className={classes.filterIcon} />
      </IconButton>
    </Tooltip>
    <div ref={anchor} />
    <Menu
      className={classes.menu}
      open={filterMenuOpen}
      anchorEl={anchor.current}
      onClose={() => setFilterMenuOpen(false)}
      keepMounted={true}
    >
      <div className={classes.filterHeadline}>{filterHeadline}</div>
      {_.map(refinements, (refinement) => {
        return (
          <LegalbirdRefinementList
            key={refinement.attribute.name}
            attribute={refinement.attribute.name}
            refinement={refinement}
          />
        );
      })}
      <LegalbirdClearRefinements />
    </Menu>
  </>;
};

BoardRefinements.propTypes = {
  refinements: PropTypes.arrayOf(PropTypes.object).isRequired,
  filterHeadline: PropTypes.string,
};
BoardRefinements.defaultProps = {
  filterHeadline: "Filtern nach",
};

export default BoardRefinements;
