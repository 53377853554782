import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import ApiClient from "../../services/ApiClient";
import _ from "lodash";
import ExternalApplicationRow from "./ExternalApplicationRow";
import Button from "@mui/material/Button";
import { ExternalLawyerApplication } from "../../types/ExternalLawyerApplication";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const COLLAPSED_DISPLAY_AMOUNT = 6;

export default function ExternalApplications({}) {
  const [applications, setApplications] = useState<ExternalLawyerApplication[]>([]);
  const [listCollapsed, setListCollapsed] = useState<boolean>(true);
  const [filter, setFilter] = useState<string>("applied");

  useEffect(() => {
    ApiClient.get("external_lawyer_applications?status[]=applied&status[]=denied").then((result) => {
      setApplications(result["hydra:member"]);
    });
  }, []);

  const filteredApplications = _.filter(applications, (application) => application.status === filter);

  return (
    <>
      <Stack direction={"row"} justifyContent={"flex-end"}>
        <FormControl>
          <Select
            onChange={(event: SelectChangeEvent) => setFilter(event.target.value)}
            value={filter}
            label={"Status"}
          >
            <MenuItem value={"applied"}>Zu Prüfen</MenuItem>
            <MenuItem value={"denied"}>Abgelehnt</MenuItem>
          </Select>
          <InputLabel>Status</InputLabel>
        </FormControl>
      </Stack>
      <Paper sx={{ padding: "2rem", marginTop: "1rem" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name des Bewerbers</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Eingereicht am</TableCell>
              <TableCell>Abgelehnt am</TableCell>
              <TableCell>Geprüft von</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(
              listCollapsed ? filteredApplications.slice(0, COLLAPSED_DISPLAY_AMOUNT) : filteredApplications,
              (application) => (
                <ExternalApplicationRow key={application.id} application={application} />
              )
            )}
            {filteredApplications.length < 1 && (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: "center", color: "text.light" }}>
                  Keine Einträge
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {filteredApplications.length > COLLAPSED_DISPLAY_AMOUNT && (
          <Button
            fullWidth={false}
            sx={{ margin: "2rem auto", display: "block" }}
            onClick={() => setListCollapsed(!listCollapsed)}
          >
            {listCollapsed ? "Mehr anzeigen" : "Weniger anzeigen"}
          </Button>
        )}
      </Paper>
    </>
  );
}
