import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    padding: "1rem",
  },
  loading: {
    maxWidth: 40,
    margin: "5rem auto",
  },
  paper: {
    marginTop: "1rem",
    padding: "1rem",
  },
  searchButton: {
    padding: "0",
  },
  faded: {
    textAlign: "center",
    color: "#dcdcdc",
    fontSize: "1.25rem",
    margin: "2rem",
  },
  search: {
    width: "100%",
  },
  filterSelect: {
    width: "100%",
  },
  pageHeadline: {
    textAlign: "center",
    margin: "2rem 0",
  },
}));

export default useStyles;
