import React from "react";
import { PAID_TASK_STATUS_REQUESTED } from "../paidTaskStatus";
import ExternalPaidTaskTable from "./ExternalPaidTaskTable";
import { Box, Paper } from "@mui/material";
import PageHeadline from "../../PageHeadline/PageHeadline";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";

const RequestedPaidTasks = () => {
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();

  const paidTasksFilters = {
    "exists[deletedDate]": false,
    status: PAID_TASK_STATUS_REQUESTED,
    requestedUser: currentUser["@id"],
  };
  const { data: paidTasks, isLoading } = useQuery(queryKeys.collection("paid_tasks", paidTasksFilters), () =>
    fetchCollection("paid_tasks", paidTasksFilters)
  );

  if (!paidTasks || !paidTasks["hydra:member"] || paidTasks["hydra:member"].length === 0) {
    return null;
  }

  return (
    <Box>
      <PageHeadline main={"Anfragen für Aufgaben"} />
      <Paper sx={{ border: "1px solid red;" }}>
        <ExternalPaidTaskTable
          paidTasks={paidTasks["hydra:member"]}
          isRequestedView={true}
          noPaidTasksText={"Keine Anfragen für Aufgaben"}
        />
      </Paper>
    </Box>
  );
};
export default RequestedPaidTasks;
