import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Paper, Tab, Tabs } from "@mui/material";
import { navigationExtensionEmotionStyle } from "./navigationExtensionAllDataStyle";
import { ContentContainer } from "../Container/PageContentContainer";
import { userHasOneOfTheseRoles, userHasRole } from "../../services/backofficeUserService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

const NavigationExtensionMyAccount = () => {
  const [topOffset, setTopOffset] = useState(69);
  const currentUser = useCurrentUser();

  return (
    <Paper
      square
      ref={(el) => el && setTopOffset(el.getBoundingClientRect().top)}
      sx={navigationExtensionEmotionStyle.tabs({ top: topOffset })}
    >
      <ContentContainer size={"large"}>
        <Tabs variant={"fullWidth"} scrollButtons="auto" value={false} centered>
          <Tab component={Link} to={"#myData"} label={"Meine Daten"} />
          {userHasRole(currentUser, "ROLE_EXTERNAL") && <Tab component={Link} to={"#billing"} label={"Abrechnung"} />}
          {userHasOneOfTheseRoles(currentUser, ["ROLE_EXTERNAL", "ROLE_LAWYER"]) && (
            <Tab component={Link} to={"#signature"} label={"Unterschrift"} />
          )}
          {userHasRole(currentUser, "ROLE_LAWYER") && <Tab component={Link} to={"#bea"} label={"beA"} />}
          {!userHasRole(currentUser, "ROLE_EXTERNAL") && (
            <Tab component={Link} to={"#humanResource"} label={"Abwesenheit & Arbeitszeit"} />
          )}
        </Tabs>
      </ContentContainer>
    </Paper>
  );
};

export default NavigationExtensionMyAccount;
