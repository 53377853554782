const searchBarStyles = {
  searchInput: {
    width: "100%",
    backgroundColor: "#F1F4FB",
    borderRadius: "25px",
  },
  listContent: {
    minWidth: 360,
    backgroundColor: "white",
    border: "1px solid #137f7b",
    borderRadius: "10px",
    width: "100%",
    margin: "4px 0",
  },
}
export default searchBarStyles;