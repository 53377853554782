import React from "react";
import useStyles from "./dutyNotificationStyles";
import Grid from "@mui/material/Grid";
import * as PropTypes from "prop-types";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";

const DutyNotification = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { timeString, description, link, id } = props;
  const { closeSnackbar } = useSnackbar();

  const closeNotification = () => {
    closeSnackbar(id);
  };

  return (
    <Paper ref={ref} className={classes.notificationContainer}>
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item xs={2} />
        <Grid item xs={8} className={classes.headline}>
          Neue Pflicht für heute!
        </Grid>
        <Grid item xs={2}>
          <IconButton className={classes.icon} onClick={closeNotification} size="large">
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <strong>Zeitpunkt: </strong>
          {timeString}
        </Grid>
        <Grid item xs={12}>
          <strong>Beschreibung: </strong>
          {description}
        </Grid>
        <Grid item xs={6}>
          <Button onClick={closeNotification} component={Link} to={link}>
            Zum Fall
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={closeNotification}>Ok</Button>
        </Grid>
      </Grid>
    </Paper>
  );
});

DutyNotification.propTypes = {
  timeString: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string.isRequired,
};

export default DutyNotification;
