import React from "react";
import ContentLoader from "react-content-loader";
import PageContentContainer from "../Container/PageContentContainer";

const CaseOverviewPageLoader = () => {
  return (
    <PageContentContainer>
      <ContentLoader
        speed={2}
        width={1500}
        height={1000}
        viewBox="0 0 1500 1000"
        backgroundColor="#f4f4f4"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="12" rx="0" ry="0" width="1424" height="242" />
        <rect x="12" y="286" rx="0" ry="0" width="1400" height="40" />
        <rect x="0" y="356" rx="0" ry="0" width="474" height="280" />
        <rect x="500" y="356" rx="0" ry="0" width="923" height="370" />
        <rect x="2" y="668" rx="0" ry="0" width="474" height="350" />
        <rect x="500" y="760" rx="0" ry="0" width="923" height="338" />
      </ContentLoader>
    </PageContentContainer>
  );
};

export default CaseOverviewPageLoader;
