import React, { useEffect, useState } from "react";
import DisplayMediaObjects from "../MediaObject/DisplayMediaObjects";
import { MediaObject } from "../../types/MediaObject";
import { Box, Stack } from "@mui/material";
import MimeTypeIcon from "../MediaObject/MimeTypeIcon";
import _ from "lodash";
import ContentBox from "../ContentBox/ContentBox";

type MediaObjectsGalleryProps = {
  mediaObjects: Array<MediaObject>;
  initialMediaObject?: MediaObject;
};

export default function MediaObjectsGallery({ mediaObjects, initialMediaObject }: MediaObjectsGalleryProps) {
  const [mediaObjectToDisplay, setMediaObjectToDisplay] = useState<MediaObject | null>(null);

  useEffect(() => {
    if (initialMediaObject) {
      setMediaObjectToDisplay(initialMediaObject);
    }
  },[initialMediaObject]);

  if (!mediaObjects) {
    return null;
  }

  return (
    <ContentBox>
      {_.map(mediaObjects, (mediaObject) => (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          onClick={() => setMediaObjectToDisplay(mediaObject)}
          sx={{ cursor: "pointer" }}
          key={mediaObject.id}
        >
          <MimeTypeIcon mimeType={mediaObject.mimeType} />
          <Box sx={{ color: "primary.main" }}>{mediaObject.originalName}</Box>
        </Stack>
      ))}
      <DisplayMediaObjects
        setMediaObjectToDisplay={setMediaObjectToDisplay}
        mediaObjects={mediaObjects}
        mediaObjectToDisplay={mediaObjectToDisplay}
      />
    </ContentBox>
  );
}
