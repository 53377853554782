import React from "react";
import NavigationBar from "../NavigationBar/NavigationBar";
import { Box } from "@mui/material";
import GlobalProvider from "../../components/Provider/GlobalProvider";
import LegalbirdLoader from "../../components/ContentLoader/LegalbirdLoader";
import layoutStyle from "./layoutStyle";
import InfoBar from "../../components/TechStatus/InfoBar";
import BackofficeUserNotifications from "../../components/BackofficeUserNotifications/BackofficeUserNotifications";
import GeneralActions from "./GeneralActions";
import { useTechStatus } from "../../provider/TechStatusProvider";

export default function Layout({ children }: { children?: React.ReactNode }) {
  const techStatus = useTechStatus();
  return (
    <GlobalProvider>
      {techStatus?.release && (
        <Box sx={layoutStyle.blockPage}>
          <LegalbirdLoader />
        </Box>
      )}
      <InfoBar />
      <NavigationBar />
      <Box sx={layoutStyle.mobileContentContainer}>{children}</Box>
      <GeneralActions />
      <BackofficeUserNotifications />
    </GlobalProvider>
  );
}
