import React, { useCallback, useState } from "react";
import FileSection from "../ProcessCostSupport/Receipts/FileSection";
import _ from "lodash";
import ApiClient from "../../services/ApiClient";
import moment from "moment";
import DisplayMediaObject from "../MediaObjectSection/DisplayMediaObject";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import casePaidTaskStyle from "./casePaidTaskStyle";
import PropTypes from "prop-types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, updateResource } from "../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

const useStyles = makeStyles(casePaidTaskStyle);

const getInitialIterableAttachmentCount = (paidTask, currentUser) => {
  //get all filename ending numbers to find the highest one: "paidTaskDocumentK5" -> 5
  let iterableAttachmentNumbers = _.map(paidTask.mediaObjects, (mediaObject) => {
    if (!mediaObject.identifier.match(/^paidTaskDocument[A-Z]\d+$/)) {
      return 0;
    }
    return parseInt(mediaObject.identifier.replace(/\D/g, ""));
  });

  return Math.max(...iterableAttachmentNumbers, currentUser.isExternal ? 1 : 0);
};

const replacePlaceholders = (stringWithPlaceholders, product, iterator) => {
  let replacementString = stringWithPlaceholders;
  replacementString = replacementString.replaceAll("{{YYYYMMDD}}", moment().format("YYYYMMDD"));
  if (iterator) {
    replacementString = replacementString.replaceAll("{{iterator}}", iterator);
  }
  replacementString = replacementString.replaceAll("{{clientFamilyName}}", product.client.familyName);
  replacementString = replacementString.replaceAll("{{DD.MM.YYYY}}", moment().format("DD.MM.YYYY"));
  return replacementString;
};

const CasePaidTaskFileUploadArea = ({ paidTaskTypeConfig, product, paidTask, readOnly }) => {
  const updateMutation = useMutation(updateResource);
  const classes = useStyles();
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();

  const [mediaObjectToDisplay, setMediaObjectToDisplay] = useState(null);

  const [additionalAttachmentCount, setAdditionalAttachmentCount] = useState(
    getInitialIterableAttachmentCount(paidTask, currentUser)
  );

  const deleteFile = async (id, identifier) => {
    const truncatedMediaObjects = _.filter(
      paidTask.mediaObjects,
      (mediaObject) => mediaObject.identifier !== identifier
    );
    await updateMutation.mutateAsync({
      id: paidTask.id,
      uri: "paid_tasks",
      data: {
        mediaObjects: truncatedMediaObjects,
      },
    });
    await queryClient.invalidateQueries(queryKeys.item("paid_tasks", paidTask.id));
  };

  const uploadFile = async (files, originalName, description, identifier) => {
    let formData = new FormData();
    formData.append("file", files[0]);
    formData.append("product", product.productClassName);
    formData.append("productId", product.id);
    formData.append("originalName", originalName);
    formData.append("description", description);
    formData.append("fieldname", "paidTaskDocument");
    formData.append("customerId", product.customer.id);
    formData.append("showInExternalView", "true");

    let headers = new Headers();
    headers.set("Content-Type", "multipart/form-data");
    const mediaObject = await ApiClient.post("media_objects", {
      headers: headers,
      body: formData,
    });

    let extendedMediaObjects = _.cloneDeep(paidTask.mediaObjects);
    extendedMediaObjects.push({ ...mediaObject, identifier: identifier });
    await updateMutation.mutateAsync({
      id: paidTask.id,
      uri: "paid_tasks",
      data: {
        mediaObjects: extendedMediaObjects,
      },
    });
    await queryClient.invalidateQueries(queryKeys.item("paid_tasks", paidTask.id));
  };

  const getIterableAttachmentFields = useCallback(() => {
    let uploadFields = [];

    if (!paidTaskTypeConfig.iterableAttachmentsConfig) {
      return [];
    }

    for (let iterator = 1; iterator < additionalAttachmentCount + 1; iterator++) {
      const identifier =
        "paidTaskDocument" +
        replacePlaceholders(paidTaskTypeConfig.iterableAttachmentsConfig.identifier, product, iterator);

      uploadFields.push(
        <FileSection
          key={identifier}
          headline={
            paidTaskTypeConfig.iterableAttachmentsConfig.headline +
            " " +
            replacePlaceholders(paidTaskTypeConfig.iterableAttachmentsConfig.identifier, product, iterator)
          }
          subText={replacePlaceholders(paidTaskTypeConfig.iterableAttachmentsConfig.description, product, iterator)}
          deleteFile={(id) => deleteFile(id, identifier)}
          uploadFile={(files) =>
            uploadFile(
              files,
              replacePlaceholders(paidTaskTypeConfig.iterableAttachmentsConfig.fileName, product, iterator),
              replacePlaceholders(paidTaskTypeConfig.iterableAttachmentsConfig.fileDescription, product, iterator),
              identifier
            )
          }
          currentMediaObjects={_.filter(paidTask.mediaObjects, (mediaObject) => mediaObject.identifier === identifier)}
          setMediaObjectToDisplay={setMediaObjectToDisplay}
          fileLimit={1}
          readOnly={readOnly}
          acceptedFiles={[".pdf"]}
        />
      );
    }

    return uploadFields;
  }, [additionalAttachmentCount, paidTaskTypeConfig, paidTask]);

  //since editor and multiple iterable upload fields are mutually exclusive
  if (paidTaskTypeConfig.hasEditor) {
    return (
      <>
        <FileSection
          headline={"Datei hinzufügen?"}
          subText={
            "Zusätzlich zu dem Schriftsatz ist noch eine weitere Datei für die Erledigung notwendig? Dann laden Sie diese als PDF hoch. Maximalgröße 10MB."
          }
          deleteFile={(id) => deleteFile(id, "paidTaskAdditionalDocument")}
          uploadFile={(files) => {
            const fileEnding = files[0].name.split(".").pop();
            return uploadFile(
              files,
              `${moment().format("YYYYMMDD")}_ergaenzende_datei.${fileEnding}`,
              "Ergänzende Datei zu Schriftsatz vom " + moment().format("DD.MM.YYYY"),
              "paidTaskAdditionalDocument"
            );
          }}
          currentMediaObjects={_.filter(
            paidTask.mediaObjects,
            (mediaObject) => mediaObject.identifier === "paidTaskAdditionalDocument"
          )}
          setMediaObjectToDisplay={setMediaObjectToDisplay}
          fileLimit={1}
          acceptedFiles={[".pdf"]}
        />
        <DisplayMediaObject closeDialog={() => setMediaObjectToDisplay(null)} mediaObject={mediaObjectToDisplay} />
      </>
    );
  }

  const iterableAttachmentFields = getIterableAttachmentFields();

  return (
    <>
      <Typography className={classes.subHeadline}>
        Um die Aufgabe zu erledigen, laden Sie bitte die unten aufgeführten Dateien hoch.
      </Typography>
      <FileSection
        headline={paidTaskTypeConfig.mainDocumentConfig.headline}
        subText={paidTaskTypeConfig.mainDocumentConfig.description}
        deleteFile={(id) => deleteFile(id, "paidTaskMainDocument")}
        uploadFile={(files) =>
          uploadFile(
            files,
            replacePlaceholders(paidTaskTypeConfig.mainDocumentConfig.fileName, product),
            replacePlaceholders(paidTaskTypeConfig.mainDocumentConfig.fileDescription, product),
            "paidTaskMainDocument"
          )
        }
        currentMediaObjects={_.filter(
          paidTask.mediaObjects,
          (mediaObject) => mediaObject.identifier === "paidTaskMainDocument"
        )}
        setMediaObjectToDisplay={setMediaObjectToDisplay}
        fileLimit={1}
        readOnly={readOnly}
        acceptedFiles={[".pdf"]}
      />
      {iterableAttachmentFields}
      {!readOnly && (
        <Button onClick={() => setAdditionalAttachmentCount(additionalAttachmentCount + 1)}>
          Weitere Anlage hinzufügen
        </Button>
      )}
      <DisplayMediaObject closeDialog={() => setMediaObjectToDisplay(null)} mediaObject={mediaObjectToDisplay} />
    </>
  );
};

CasePaidTaskFileUploadArea.propTypes = {
  paidTaskTypeConfig: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  paidTask: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

CasePaidTaskFileUploadArea.defaultProps = {
  readOnly: false,
};

export default CasePaidTaskFileUploadArea;
