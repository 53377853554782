import React from "react";
import { Grid } from "@mui/material";
import PageContentContainer from "../Container/PageContentContainer";
import ClientsAndCases from "./ClientsAndCases";
import Payments from "./Payments";
import Administration from "./Administration";
import Controlling from "./Controlling";
import PageHeadline from "../PageHeadline/PageHeadline";
import ContentBox from "../ContentBox/ContentBox";
import Mail from "./Mail";
import { userHasOneOfTheseRoles } from "../../services/backofficeUserService";
import { Roles } from "../../types/BackofficeUser";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

const Dashboard = () => {
  const currentUser = useCurrentUser();

  return (
    <PageContentContainer>
      <PageHeadline main="Tools" />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <ContentBox headline="Mandate & Fälle" headlineVariant="h2">
            <ClientsAndCases />
          </ContentBox>
          {userHasOneOfTheseRoles(currentUser, [
            Roles.admin,
            Roles.accountmanager,
            Roles.lawyer,
            Roles.banking,
            Roles.developer,
          ]) && (
            <ContentBox headline="Zahlungsein- und ausgänge" headlineVariant="h2">
              <Payments currentUser={currentUser} />
            </ContentBox>
          )}
          {userHasOneOfTheseRoles(currentUser, [Roles.admin, Roles.developer]) && (
            <ContentBox headline="Controlling" headlineVariant="h2">
              <Controlling />
            </ContentBox>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <ContentBox headline="Verwaltung" headlineVariant="h2">
            <Administration currentUser={currentUser} />
          </ContentBox>
          <ContentBox headline="Post" headlineVariant="h2">
            <Mail />
          </ContentBox>
        </Grid>
      </Grid>
    </PageContentContainer>
  );
};

export default Dashboard;
