const layoutStyle = {
  layoutContainer: {
    flexWrap: "nowrap",
    width: "100%",
    display: "flex",
    boxSizing: "border-box",
  },
  generalActionsContainer: {
    margin: 0,
    minWidth: 70,
    flexBasis: 70,
  },
  additionalActionsContainer: {
    margin: 0,
    minWidth: 70,
    flexBasis: 70,
  },
  mainContentContainer: {
    margin: 0,
    flexGrow: 1,
  },
  blockPage: {
    textAlign: "center",
    paddingTop: "200px",
    width: "100%",
    height: "100%",
    position: "fixed",
    left: "0",
    top: "0",
    zIndex: "2000",
    backgroundColor: "rgba(125,129,125,0.4)",
  },
};
export default layoutStyle;
