import React, { useCallback, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import _ from "lodash";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import ProcessCostSupportListItem from "./ProcessCostSupportListItem";
import PageContentContainer from "../Container/PageContentContainer";
import useStyles from "./processCostSupportListStyle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ApiClient from "../../services/ApiClient";
import PageHeadline from "../PageHeadline/PageHeadline";
import ClearableSearchField from "../Search/ClearableSearchField";

const ProcessCostSupportList = () => {
  const classes = useStyles();
  const [processCostSupportList, setProcessCostSupportList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [nameFilter, setNameFilter] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
    statusFilter: "check",
    nameFilter: "",
  });

  const fetchProcessCostSupportList = useCallback(async () => {
    setIsLoading(true);

    const getUrlParameters = () => {
      let searchParams = new URLSearchParams();
      searchParams.append("page", "" + filters.page);

      if (filters.nameFilter) {
        searchParams.append("petitioner.familyName", filters.nameFilter);
      }

      if (filters.statusFilter !== "all") {
        switch (filters.statusFilter) {
          case "incomplete":
            searchParams.append("leadStatus", "10");
            break;
          case "check":
            searchParams.append("leadStatus", "20");
            break;
          case "done":
            searchParams.append("leadStatus", "30");
            break;
          default:
            break;
        }
      }

      return searchParams;
    };

    const result = await ApiClient.get("process_cost_supports?" + getUrlParameters().toString());
    setProcessCostSupportList(result["hydra:member"]);
    setIsLoading(false);
  }, [filters]);

  useEffect(() => {
    fetchProcessCostSupportList();
  }, [fetchProcessCostSupportList]);

  const handleNameSearchClick = async () => {
    if (!nameFilter) {
      return;
    }

    let newFilters = { ...filters };
    newFilters["nameFilter"] = nameFilter;
    newFilters["page"] = 1;
    setFilters(newFilters);
  };

  const handlePageChange = (page) => {
    let newFilters = { ...filters };
    newFilters["page"] = page;
    setFilters(newFilters);
  };

  const handleStatusFilterChange = ({ target }) => {
    let newFilters = { ...filters };
    newFilters["statusFilter"] = target.value;
    newFilters["page"] = 1;
    setFilters(newFilters);
  };

  const handleClearNameFilter = () => {
    if (!nameFilter && !filters.nameFilter) {
      return;
    }

    let newFilters = { ...filters };
    setNameFilter("");
    newFilters["nameFilter"] = "";
    newFilters["page"] = 1;
    setFilters(newFilters);
  };

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <PageContentContainer size={"small"}>
      <PageHeadline main="Anträge auf Verfahrenskostenhilfe" />
      <Grid container alignItems={"center"} spacing={4} justifyContent={"flex-end"}>
        <Grid item xs={5}>
          <ClearableSearchField
            filter={nameFilter}
            placeholder={"Nachname Mandant"}
            setFilter={setNameFilter}
            handleSearchClick={handleNameSearchClick}
            handleClearFilter={handleClearNameFilter}
            showSearchButton
          />
        </Grid>
        <Grid item xs={2}>
          <Select value={filters.statusFilter} onChange={handleStatusFilterChange} className={classes.filterSelect}>
            <MenuItem value={"incomplete"}>Unvollständig</MenuItem>
            <MenuItem value={"check"}>Zu Prüfen</MenuItem>
            <MenuItem value={"done"}>Abgeschlossen</MenuItem>
            <MenuItem value={"all"}>Alle</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
        {processCostSupportList.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Antragsteller / Fall</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Zuletzt bearbeitet am</TableCell>
                <TableCell>Zuständiger</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(processCostSupportList, (processCostSupport) => (
                <ProcessCostSupportListItem
                  key={processCostSupport.id}
                  processCostSupport={processCostSupport}
                  updateProcessCostSupports={fetchProcessCostSupportList}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <div className={classes.faded}>Kein Antrag vorhanden.</div>
        )}
        <Grid container className={classes.buttonContainer} justifyContent={"space-between"} spacing={4}>
          <Grid item>
            <Button
              disabled={filters.page === 1}
              onClick={() => {
                handlePageChange(filters.page - 1);
              }}
            >
              &laquo; vorherige Seite
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={processCostSupportList && processCostSupportList.length < 10}
              onClick={() => {
                handlePageChange(filters.page + 1);
              }}
            >
              nächste Seite &raquo;
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </PageContentContainer>
  );
};

export default ProcessCostSupportList;
