import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { getBadgesMinimal, getCaseLabel, getIsApplicationComplete } from "../../../../services/Product/ProductService";
import { makeStyles } from "@mui/styles";
import caseHeaderStyle from "./caseHeaderStyle";
import BadgesList from "../../../Badges/BadgesList";
import AgentPicker from "../../../AgentPicker/AgentPicker";
import { useBackofficeUser } from "../../../../provider/BackofficeUserProvider";

const useStyles = makeStyles(caseHeaderStyle);

const CaseHeader = ({ product }) => {
  const classes = useStyles();
  const { backofficeUsers, accountManagers, lawyers } = useBackofficeUser();

  if (!backofficeUsers) {
    return null;
  }

  const isApplicationComplete = getIsApplicationComplete(product);

  return (
    <Paper className={classes.caseHeaderContainer}>
      <Grid container>
        <Grid item xs={12} lg={7} sx={{paddingTop: "1rem"}}>
          <Typography variant="h1">{getCaseLabel(product)}</Typography>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Grid container>
            <Grid item xs={12} lg={6}>
              {isApplicationComplete && (
                <AgentPicker
                  label={"Anwalt"}
                  currentAgent={product.backofficeCase.lawyer}
                  product={product}
                  type={"lawyer"}
                  agentList={lawyers}
                  disabled
                />
              )}
            </Grid>
            <Grid item xs={12} lg={6}>
              <AgentPicker
                label={"Zuständiger"}
                currentAgent={product.backofficeCase && product.backofficeCase.accountManager}
                product={product}
                type={"accountManager"}
                agentList={accountManagers}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <BadgesList badges={getBadgesMinimal(product)} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CaseHeader;
