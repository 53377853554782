import { InquiryToCustomer } from "../../types/InquiryToCustomer";
import ButtonLoading from "../Button/ButtonLoading";
import React, { useState } from "react";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { Grid, Stack, Typography } from "@mui/material";
import moment from "moment/moment";
import { formValue } from "../../services/formServiceFunctions";
import _ from "lodash";
import MenuItem from "@mui/material/MenuItem";
import ValidatorSelect from "../Validator/ValidatorSelect";
import ValidatorTextField from "../Validator/ValidatorTextField";
import ValidatorDateField from "../Validator/ValidatorDatePicker";
import { AbstractCase } from "../../types/AbstractCase";
import { userHasOneOfTheseRoles } from "../../services/backofficeUserService";
import { Roles } from "../../types/BackofficeUser";
import { useSnackbar } from "notistack";
import Snackbar from "../Snackbar/Snackbar";
import { queryKeys } from "../../services/ReactQuery/reactQueryService";
import { apiPut } from "../../services/Api/apiCall";
import { useQueryClient } from "@tanstack/react-query";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

export default function ShowInquiryToCustomerModal({
  open,
  handleClose,
  inquiryToCustomer,
  product,
}: {
  open: boolean;
  handleClose: Function;
  inquiryToCustomer: InquiryToCustomer;
  product: AbstractCase;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleDone = async () => {
    setIsLoading(true);
    await apiPut("inquiry_to_customers", inquiryToCustomer.id, {
      status: 40,
      doneBy: currentUser["@id"],
    });
    await queryClient.invalidateQueries(queryKeys.collections("inquiry_to_customers"));
    setIsLoading(false);
    handleClose();
  };
  const handleRemind = async () => {
    setIsLoading(true);
    await apiPut("inquiry_to_customers", inquiryToCustomer.id, {
      sendReminder: true,
    });
    await queryClient.invalidateQueries(queryKeys.collections("inquiry_to_customers"));
    setIsLoading(false);
    handleClose();

    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar message={"Es wurde per E-Mail an die offene Anfrage erinnert."} isNonInteractive />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  };

  const title = (inquiryToCustomer: InquiryToCustomer): string => {
    switch (inquiryToCustomer.statusText) {
      case "open":
      case "inProgress":
        return "Offene Anfrage";
      case "overdue":
        return "Überfällige Anfrage";
      default:
        return "Anfrage";
    }
  };

  return (
    <LegalbirdIoModal
      title={title(inquiryToCustomer)}
      handleClose={handleClose}
      open={open}
      submitButton={
        !userHasOneOfTheseRoles(currentUser, [Roles.callcenter, Roles.external]) ? (
          <ButtonLoading variant={"contained"} isLoading={isLoading} onClick={handleDone}>
            Anfrage erledigen
          </ButtonLoading>
        ) : (
          <></>
        )
      }
    >
      <Typography sx={{ textAlign: "center", fontWeight: "bold", marginBotton: "1rem" }} variant="h5">
        Erstellt am {moment(inquiryToCustomer.created).format("DD.MM.YYYY")} von {inquiryToCustomer.createdByFullname}
      </Typography>
      <Stack sx={{ margin: "1rem 0" }} spacing={2} direction={"row"} justifyContent={"flex-end"}>
        <ButtonLoading fullWidth={false} variant={"text"} isLoading={isLoading} onClick={handleRemind}>
          An Anfrage erinnern
        </ButtonLoading>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ValidatorSelect
            label={"Art der Anfrage"}
            name={"inquiryType"}
            value={formValue(inquiryToCustomer, "inquiryType")}
            disabled={true}
          >
            <MenuItem value={"appointment"}>Terminvereinbarung</MenuItem>
            <MenuItem value={"informationAndDocuments"}>Informationen und Dokumente</MenuItem>
            <MenuItem value={"documents"}>Nur Dokumente</MenuItem>
            <MenuItem value={"information"}>Nur Informationen</MenuItem>
          </ValidatorSelect>
        </Grid>
        {_.get(inquiryToCustomer, "inquiryType") === "appointment" && (
          <Grid item xs={12}>
            <ValidatorTextField
              label={"Hintergrund für Gespräch (optional)"}
              InputLabelProps={{
                shrink: true,
              }}
              name={"inquiryTextAppointment"}
              value={formValue(inquiryToCustomer, "inquiryTextAppointment")}
              multiline
              margin={"normal"}
              fullWidth
              isMandatory={false}
              dependentValidationFields={[]}
              registerValidators={() => {}}
              validators={[]}
              disabled={true}
            />
          </Grid>
        )}
        {["information", "informationAndDocuments"].includes(_.get(inquiryToCustomer, "inquiryType")) && (
          <Grid item xs={12}>
            <ValidatorTextField
              label={"Benötigte Informationen"}
              name={"inquiryTextInformation"}
              value={formValue(inquiryToCustomer, "inquiryTextInformation")}
              registerValidators={() => {}}
              validators={[]}
              multiline
              margin={"normal"}
              fullWidth
              isMandatory={false}
              dependentValidationFields={[]}
              disabled={true}
            />
          </Grid>
        )}
        {["documents", "informationAndDocuments"].includes(_.get(inquiryToCustomer, "inquiryType")) && (
          <>
            <Grid item xs={12}>
              <Typography sx={{ textAlign: "center" }} variant="h3">
                Benötigte Dokumente
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField
                label={"Erklärung zu Dokumenten (optional)"}
                name={"inquiryTextDocuments"}
                value={formValue(inquiryToCustomer, "inquiryTextDocuments")}
                registerValidators={() => {}}
                validators={[]}
                multiline
                margin={"normal"}
                fullWidth
                isMandatory={false}
                dependentValidationFields={[]}
                disabled={true}
              />
            </Grid>
            {_.get(inquiryToCustomer, "requestedDocuments").map((document: any, index: number) => {
              return (
                <Grid item xs={12} key={index}>
                  <ValidatorTextField
                    label={"Name Dokument " + (index + 1)}
                    name={"requestedDocuments[" + index + "].documentName"}
                    value={formValue(inquiryToCustomer, "requestedDocuments[" + index + "].documentName")}
                    registerValidators={() => {}}
                    validators={[]}
                    margin={"normal"}
                    fullWidth
                    isMandatory={false}
                    dependentValidationFields={[]}
                    disabled={true}
                  />
                </Grid>
              );
            })}
          </>
        )}
        <Grid item xs={12} sm={6}>
          <ValidatorDateField
            format="DD.MM.YYYY"
            label={"Fällig am"}
            name={"deadline"}
            value={formValue(inquiryToCustomer, "deadline")}
            onChange={() => {}}
            onBlur={() => {}}
            helperText={"3 Tage vor Ablauf der Frist wird automatisch beim Mandanten nachgefasst"}
            registerValidators={() => {}}
            validators={[]}
            dependentValidationFields={[]}
            isMandatory={false}
            withSkipWeeksToolbar={false}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ValidatorSelect
            label={"Bei fehlender Rückmeldung"}
            name={"overdueConsequences"}
            value={formValue(inquiryToCustomer, "overdueConsequences")}
            validators={[]}
            registerValidators={() => {}}
            disabled={true}
          >
            <MenuItem value={"actionRequired"}>Weitere Bearbeitung notwendig</MenuItem>
            <MenuItem value={"noFurtherActionRequired"}>Keine weitere Bearbeitung notwendig</MenuItem>
          </ValidatorSelect>
        </Grid>
      </Grid>
    </LegalbirdIoModal>
  );
}
