
const caseHistoryItemStyle = {
  headline: {
    fontSize: "1rem",
  },
  info: {
    fontSize: ".875rem",
  },
  historyItem: {
    margin: "30px 0",
  },
  imageGridItem: {
    width: 72,
  },
  noteGridItem: {
    width: "calc(100% - 72px)",
  },
};

export default caseHistoryItemStyle;
