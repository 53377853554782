const activitiesStyle = {
  container: {
    padding: "2rem",
    marginTop: "1rem",
    width: "100%",
  },
  headline: {
    textAlign: "center",
    fontSize: "1.25rem",
    marginBottom: "1rem",
  },
  activityContainer: {
    marginBottom: "2rem",
  },
  faded: {
    textAlign: "center",
    color: "#dcdcdc",
    fontSize: "1.25rem",
    margin: "2rem",
  },
  horizontalLine: {
    borderBottom: "1px solid #999999",
    marginBottom: "2rem",
  },
  loader: {
    display: "block",
    margin: "0 auto",
  },
  button: {
    margin: "1rem auto 0 0",
  },
};

export default activitiesStyle;
