import React from "react";
import { Box } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { translate } from "../../services/Translations/translatorService";

type PaidTaskLogEntryProps = {
  logEntry: any;
  logDateTime: string;
};

export default function PaidTaskLogEntry({ logEntry, logDateTime }: PaidTaskLogEntryProps) {
  const SubLogEntry = ({ subEntry, logType }: { subEntry: any; logType: string }) => {
    //not direct returns because typescript won't allow components to return plain strings
    let returnValue;
    switch (logType) {
      case "userReminded":
        returnValue = "Erledigung angefordert bei " + subEntry;
        break;
      case "userRequested":
        returnValue = "Angefragt bei " + subEntry;
        break;
      case "assignedTo":
        returnValue = "Angenommen von " + subEntry;
        break;
      case "changesRequested":
        returnValue = "Änderungen angefragt: " + subEntry;
        break;
      case "deleted":
        returnValue = "Aufgabe gelöscht";
        break;
      case "approved":
        returnValue = "Aufgabe abgenommen";
        break;
      case "submittedForReview":
        returnValue = "Aufgabe erledigt";
        break;
      case "acceptancePeriodExpired":
      case "requestDismissed":
        returnValue = subEntry;
        break;
      case "updatedBy":
        returnValue = `${moment(logDateTime).format("DD.MM.YYYY | HH:mm")} Uhr | ${logEntry.updatedBy}`;
        break;
      default:
        returnValue = `Geändert: ${translate("paidTask." + logType)} - ${
          moment(subEntry.previous).isValid() ? moment(subEntry.previous).format("DD.MM.YYYY") : subEntry.previous
        } >> ${moment(subEntry.current).isValid() ? moment(subEntry.current).format("DD.MM.YYYY") : subEntry.current}`;
        break;
    }
    return <div>{returnValue}</div>;
  };

  return (
    <Box sx={{ margin: "1rem" }}>
      {_.map(logEntry, (subEntry, key) => (
        <SubLogEntry subEntry={subEntry} key={key} logType={key} />
      ))}
    </Box>
  );
}
