import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  paper: {
    width: 700,
    margin: "0 auto 0 auto",
  },
  button: {
    marginTop: 20,
  },
  container: {
    padding: "1rem 2rem 1rem 2rem",
  },
  centered: {
    justifyContent: "center",
  },
}));

export default useStyles;
