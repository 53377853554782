import * as stagesDocument from "../../Stages/StagesDocument";

const documentFields = (productClassName) => {
  return [
    {
      type: "ValidatorSelect",
      path: `${productClassName}.paymentMethod`,
      isDisabled: () => true,
      options: () => [{ value: "__null__" }, { value: "creditcard" }, { value: "paypal" }, { value: "sofort" }],
    },
    {
      type: "ValidatorDateField",
      path: `${productClassName}.orderDate`,
      isDisabled: () => true,
      additionalProps: {
        format: "DD.MM.YYYY HH:mm",
      },
    },
    {
      type: "ValidatorSelect",
      path: `${productClassName}.basicChoice`,
      isDisabled: () => true,
      isHidden: ({ product }) => product.productClassName !== "alimony",
      options: () => [{ value: "__null__" }, { value: "complete" }, { value: "children" }, { value: "other" }],
    },
    {
      type: "ExpandableTextField",
      path: `${productClassName}.personalTextMessage`,
      isDisabled: () => true,
      additionalProps: {
        multiline: true,
      },
    },
  ];
};

export const stageFormDefinition = (productClassName) => {
  return {
    [stagesDocument.STAGE_READY]: documentFields(productClassName),
    [stagesDocument.STAGE_CHECKED]: documentFields(productClassName),
    [stagesDocument.STAGE_ORDERED]: documentFields(productClassName),
    [stagesDocument.STAGE_PAID]: documentFields(productClassName),
    [stagesDocument.STAGE_COMPLETED]: documentFields(productClassName),
  };
};
