import React from "react";
import * as PropTypes from "prop-types";
import ValidatorSelect from "../Validator/ValidatorSelect";
import _ from "lodash";
import MenuItem from "@mui/material/MenuItem";
import { insurances } from "../../services/formServiceFunctions";

const InsurancePicker = ({ disabled, label, ...rest }) => {
  const sortableLabel = (label) => {
    let newLabel = label.toLowerCase();
    newLabel = _.replace(newLabel, "ö", "o");
    newLabel = _.replace(newLabel, "ä", "a");
    newLabel = _.replace(newLabel, "ü", "u");
    return newLabel;
  };

  const sortedInsurances = _.sortBy(insurances, (insurance) => {
    return sortableLabel(insurance.label);
  });

  return (
    <ValidatorSelect label={label} disabled={disabled} {...rest}>
      <MenuItem disabled value={"__null__"}>
        {" "}
      </MenuItem>
      {_.map(sortedInsurances, (insurance) => {
        return (
          <MenuItem key={insurance.id} value={insurance.id}>
            {insurance.label}
          </MenuItem>
        );
      })}
    </ValidatorSelect>
  );
};

InsurancePicker.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  isMandatory: PropTypes.bool,
};

InsurancePicker.defaultProps = {
  label: "Versicherungsgesellschaft",
  disabled: false,
  isMandatory: false,
};

export default InsurancePicker;
