import insuranceTranslations from "./insuranceTranslations";
import personTranslations from "./personTranslations";

const basicCaseTranslations = {
  reference: {
    label: "Unser Zeichen",
  },
  caseCategory: {
    label: "Anliegen Mandant",
    values: {
      advisory: "Erstberatung",
      enforceClaim: "Forderung durchsetzen (Kläger)",
      defendClaim: "Forderung abwehren (Beklagter)",
    },
  },
  caseCategoryAssessment: {
    label: "Einschätzung des Falls",
    values: {
      confirm: "Anliegen bestätigen",
      reject: "Kein weiteres Vorgehen / rechtliche Grundlage ?",
    },
  },
  caseRejectionCategory: {
    label: "Grund für Einschätzung (Text-Vorlage)",
    values: {
      none: "Keine Vorlage",
      individual: "Vorgehen wie im Telefonat besprochen",
      electricityProvider: "Preiserhöhung Stromversorger",
      opponentNonEu: "Gegenseite Ausland (nicht EU)",
      fraudNoOpponent: "Betrugsfall + Gegenseite unbekannt",
      fraudNoOpponentAlreadyFiled: "Betrugsfall, Anzeige bereits gestellt, aber Gegenseite unbekannt",
      businessToBusinessContract: "Gewerblicher Vertragsabschluss",
      specialistLawyerNeeded: "Fachanwalt notwendig",
      claimEnforcable: "Forderung vollstreckbar/Vollstreckungsbescheid",
      enforceClaimTimeBarred: "Forderung verjährt - Forderung durchsetzen",
      defendClaimTimeBarred: "Forderung verjährt - Forderung abwehren",
      unlikelySuccessful: "Schlechte Erfolgsaussichten",
      fraudLottery: "Betrugsfall Lotto 6/49",
      schufaNoPositiveData: "Schufa - kein Positiveintrag vorhanden",
    },
  },
  assessmentRejectionText: {
    label: "E-Mail Text zur Einschätzung",
  },
  paymentMethod: {
    label: "Bezahlmethode",
  },
  responsibleLawyer: {
    label: "Anwalt",
  },
  legalExpenseInsurance: {
    label: "Rechtschutzversichert",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  powerOfAttorney: {
    label: "Vollmacht erteilt am",
  },
  personalTextMessage: {
    label: "Fragen und Anmerkungen",
  },
  insurance: insuranceTranslations,
  petitioner: {
    ...personTranslations,
  },
  contractorTypeClient: {
    label: "Vertragsabschließender laut Kunde",
    values: {
      customer: "Er selbst",
      coinsured: "Mitversicherter",
      unknown: "Unbekannt",
    },
  },
  client: personTranslations,
};

export default basicCaseTranslations;
