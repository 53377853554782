import { AbstractCase } from "../../../types/AbstractCase";
import * as stagesSettlement from "../../Stages/StagesSettlement";
import { translate } from "../../Translations/translatorService";

export const paymentTypeSwitchDataSettlement = {
  getPaymentSwitchAllowed: () => {
    return { allowed: true };
  },
  getActionAvailable: (product: AbstractCase) => {
    if (!product.stage) {
      return false;
    }
    return (
      product.paymentType === "insurance" &&
      product.stage >= stagesSettlement.STAGE_CHECKED &&
      product.stage <= stagesSettlement.STAGE_DATE_SET_TRIAL
    );
  },
  options: [
    { value: "private", label: translate("settlement.paymentType.values.private"), disabled: false },
    {
      value: "nwnf",
      label: translate("settlement.paymentType.values.nwnf"),
      disabled: false,
    },
    { value: "insurance", label: translate("settlement.paymentType.values.insurance"), disabled: true },
  ],
};
