const PAID_TASK_STATUS_CREATED = 10;
const PAID_TASK_STATUS_REQUESTED = 15;
const PAID_TASK_STATUS_ASSIGNED = 20;
const PAID_TASK_STATUS_IN_REVIEW = 30;
const PAID_TASK_STATUS_APPROVED = 40;
const PAID_TASK_STATUS_PAID = 50;

export {
  PAID_TASK_STATUS_CREATED,
  PAID_TASK_STATUS_REQUESTED,
  PAID_TASK_STATUS_ASSIGNED,
  PAID_TASK_STATUS_IN_REVIEW,
  PAID_TASK_STATUS_APPROVED,
  PAID_TASK_STATUS_PAID,
};
