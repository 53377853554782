import React from "react";
import * as PropTypes from "prop-types";
import _ from "lodash";
import pageContentContainerStyle from "./pageContentContainerStyle";
import {Box, useTheme} from "@mui/material";


export function PageContainer({children, backgroundColor}) {
  const theme = useTheme();
  return <Box
    sx={_.merge(pageContentContainerStyle(theme).pageContainer, {backgroundColor: theme.palette.background[backgroundColor]})}>
    {children}</Box>;
}

export function ContentContainer({size, children}) {
  const theme = useTheme();
  return <Box
    sx={_.merge(pageContentContainerStyle(theme).contentContainer, pageContentContainerStyle(theme)[size])}>{children}</Box>;
}

export default function PageContentContainer({size, children, backgroundColor}) {
  return (
    <PageContainer backgroundColor={backgroundColor}>
      <ContentContainer size={size}>{children}</ContentContainer>
    </PageContainer>
  );
}

PageContentContainer.propTypes = {
  size: PropTypes.oneOf(["large", "small", "tiny"]),
  backgroundColor: PropTypes.oneOf(["default", "white"])
};
PageContentContainer.defaultProps = {
  size: "large",
  backgroundColor: "default"
};
