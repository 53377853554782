const displayOrDownloadMediaObjectCellStyle = theme => ({
  clickableCell: {
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
});

export default displayOrDownloadMediaObjectCellStyle;
