import React, { useState } from "react";
import ContentBox from "../ContentBox/ContentBox";
import _ from "lodash";
import BeaMessage from "./BeaMessage";
import { Box, Button } from "@mui/material";
import { scrollToAnchor } from "../../theme/commonStyles";
import beaMessageStyles from "./beaMessageStyles";
import PropTypes from "prop-types";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../services/ReactQuery/reactQueryService";

export default function BeaMessages({ product }) {
  const [beaMessagesDisplayCount, setBeaMessagesDisplayCount] = useState(3);
  const queryClient = useQueryClient();

  let beaMessagesFilter = {
    case: product.backofficeCase.id,
  };
  const { data: beaMessagesCollection, isLoading } = useQuery(
    queryKeys.collection("bea_messages", beaMessagesFilter),
    () => fetchCollection("bea_messages", beaMessagesFilter),
    {
      enabled: !!product,
    }
  );

  if (isLoading || !beaMessagesCollection) {
    return null;
  }
  const beaMessages = beaMessagesCollection["hydra:member"];
  const beaMessagesToDisplay = _.slice(beaMessages, 0, beaMessagesDisplayCount);

  return (
    <>
      <Box sx={scrollToAnchor} id={"beaMessages"} />
      <ContentBox headline={"beA-Nachrichten"}>
        {_.map(beaMessagesToDisplay, (beaMessage) => (
          <BeaMessage
            beaMessage={beaMessage}
            key={beaMessage.id}
            product={product}
            refreshBeaMessages={() =>
              queryClient.invalidateQueries(queryKeys.collection("bea_messages", beaMessagesFilter))
            }
          />
        ))}
        {beaMessages.length > beaMessagesDisplayCount && (
          <Button onClick={() => setBeaMessagesDisplayCount(beaMessagesDisplayCount + 10)}>Mehr Anzeigen</Button>
        )}
        {beaMessagesToDisplay.length > 3 && (
          <Button onClick={() => setBeaMessagesDisplayCount(3)} sx={{ margin: "1rem auto 0 0" }}>
            Weniger anzeigen
          </Button>
        )}
        {beaMessages.length === 0 && <Box sx={beaMessageStyles.faded}>Keine beA-Nachrichten vorhanden</Box>}
      </ContentBox>
    </>
  );
}

BeaMessages.propTypes = {
  product: PropTypes.object.isRequired,
};
