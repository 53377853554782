import React from "react";
import AllDataContact from "../../../Form/AllDataContact";
import useStyles from "./caseFullFormStyle";
import * as PropTypes from "prop-types";
import SubForm from "./SubForm";
import ScrollTopButton from "../../../Button/ScrollTopButton";
import _ from "lodash";
import { translate } from "../../../../services/Translations/translatorService";
import { getFullFormDefinition } from "../../../../services/Product/ProductService";
import ContentBox from "../../../ContentBox/ContentBox";

const CaseFullForm = ({ product, contactHeadline, formDisabled, refreshPage }) => {
  const classes = useStyles({});
  const formDefinition = getFullFormDefinition(product);

  return (
    <>
      <div className={classes.scrollToAnchor} id={"contact"} />
      <ContentBox headline={contactHeadline} headlineVariant="h2">
        <AllDataContact product={product} formDisabled={formDisabled} />
      </ContentBox>
      {_.map(formDefinition, (currentFormDefinition, key) => {
        return (
          <React.Fragment key={key}>
            <div className={classes.scrollToAnchor} id={key} />
            <SubForm
              headline={translate("allDataForm." + key)}
              formDefinition={currentFormDefinition}
              product={product}
              formDisabled={formDisabled}
              refreshPage={refreshPage}
            />
          </React.Fragment>
        );
      })}
      <ScrollTopButton />
    </>
  );
};

CaseFullForm.propTypes = {
  product: PropTypes.object.isRequired,
  contactHeadline: PropTypes.string,
  formDisabled: PropTypes.bool,
};

CaseFullForm.defaultProps = {
  contactHeadline: "Kontaktdaten",
  formDisabled: false,
};

export default CaseFullForm;
