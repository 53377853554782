import React from "react";
import * as PropTypes from "prop-types";
import _ from "lodash";
import CourtAppointment from "./CourtAppointment";
import { Box } from "@mui/material";
import { scrollToAnchor } from "../../theme/commonStyles";

const CourtAppointments = ({ product, refreshPage }) => {
  return (
    <>
      <Box sx={scrollToAnchor} id={"courtAppointments"} />
      {_.map(
        _.sortBy(product.courtAppointments, (c) => -c.id),
        (courtAppointment, index) => (
          <CourtAppointment
            key={index}
            headline={"Gerichtstermin"}
            product={product}
            courtAppointment={courtAppointment}
            refreshPage={refreshPage}
          />
        )
      )}
    </>
  );
};

CourtAppointments.propTypes = {
  product: PropTypes.object.isRequired,
  refreshPage: PropTypes.func,
};

export default CourtAppointments;
