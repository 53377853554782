import { userHasRole } from "./backofficeUserService";
import { BackofficeUser, Roles } from "../types/BackofficeUser";

export const getSubFolderByUser = (user : BackofficeUser) => {
  switch (true) {
    case userHasRole(user, Roles.external):
      return "/extern";
    case userHasRole(user, Roles.callcenter):
      return "/callcenter";
    default:
      return "";
  }
};
