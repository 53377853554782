import React, { useState } from "react";
import * as PropTypes from "prop-types";
import ActionButton from "../../Actions/ActionButton";
import { caseRelatedActions, caseRelatedAdditionalActions } from "../../../services/navigationActionsService";
import _ from "lodash";
import CaseRelatedModals from "./CaseRelatedModals";
import CaseRelatedContainer from "./CaseRelatedContainer";
import { queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { useQueryClient } from "@tanstack/react-query";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";

const CaseRelatedActions = ({ product, refreshPage }) => {
  const currentUser = useCurrentUser();
  const [open, setOpen] = useState("");
  const [useCondensedView, setUseCondensedView] = useState(false);
  const queryClient = useQueryClient();

  const Actions = ({ actions }) => {
    return _.map(actions, (actionProps, index) => (
      <ActionButton key={index} useCondensedView={useCondensedView} {...actionProps} />
    ));
  };

  const refreshActivities = () => {
    queryClient.invalidateQueries(queryKeys.collections("activities"));
  };

  const refreshEmails = () => {
    queryClient.invalidateQueries(queryKeys.collections("emails"));
  };

  return (
    <div>
      <CaseRelatedContainer
        mainActions={<Actions actions={caseRelatedActions(product, setOpen, currentUser)} />}
        moreActions={<Actions actions={caseRelatedAdditionalActions(product, setOpen, currentUser)} />}
        useCondensedView={useCondensedView}
        setUseCondensedView={setUseCondensedView}
      />
      <CaseRelatedModals
        product={product}
        open={open}
        setOpen={setOpen}
        refreshPage={refreshPage}
        refreshActivities={refreshActivities}
        refreshEmails={refreshEmails}
      />
    </div>
  );
};

CaseRelatedActions.propTypes = {
  product: PropTypes.object.isRequired,
  refreshPage: PropTypes.func,
};

CaseRelatedActions.defaultProps = {
  refreshPage: () => {},
};

export default CaseRelatedActions;
