const beaCombinableStyle = {
  combinableList: {
    background: "#ddd",
    padding: "8px",
    minHeight: "200px",
    minWidth: "200px",
    border: "1px solid #999",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100px",
  },
  combinableListItem: {
    margin: "4px 2px",
    backgroundColor: "white",
    maxWidth: "calc(100% - 4px)",
    border: "1px solid #999",
    wordBreak: "break-all",
  },
};
export default beaCombinableStyle;
