import React from "react";
import Button from "@mui/material/Button";
import { ButtonGroup, Stack } from "@mui/material";

const PaidTaskFilter = ({ paidTaskFilter, setPaidTaskFilter, displayType }) => {
  switch (displayType) {
    case "assigneeView":
      return (
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <ButtonGroup color="primary">
            <Button
              variant={paidTaskFilter === "assigned" ? "contained" : "outlined"}
              onClick={() => setPaidTaskFilter("assigned")}
            >
              Offen
            </Button>
            <Button
              variant={paidTaskFilter === "inReview" ? "contained" : "outlined"}
              onClick={() => setPaidTaskFilter("inReview")}
            >
              In&nbsp;Prüfung
            </Button>
            <Button
              variant={paidTaskFilter === "approved" ? "contained" : "outlined"}
              onClick={() => setPaidTaskFilter("approved")}
            >
              Abgenommen
            </Button>
          </ButtonGroup>
        </Stack>
      );
    case "internalView":
      return (
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <ButtonGroup color="primary">
            <Button
              variant={paidTaskFilter === "assignmentDeadline" ? "contained" : "outlined"}
              onClick={() => setPaidTaskFilter("assignmentDeadline")}
            >
              Annahme&nbsp;überfällig
            </Button>
            <Button
              variant={paidTaskFilter === "doneDeadline" ? "contained" : "outlined"}
              onClick={() => setPaidTaskFilter("doneDeadline")}
            >
              Abgabe&nbsp;überfällig
            </Button>
            <Button
              variant={paidTaskFilter === "open" ? "contained" : "outlined"}
              onClick={() => setPaidTaskFilter("open")}
            >
              Offen
            </Button>
            <Button
              variant={paidTaskFilter === "requested" ? "contained" : "outlined"}
              onClick={() => setPaidTaskFilter("requested")}
            >
              Angefragt
            </Button>
            <Button
              variant={paidTaskFilter === "assigned" ? "contained" : "outlined"}
              onClick={() => setPaidTaskFilter("assigned")}
            >
              In&nbsp;Bearbeitung
            </Button>
            <Button
              variant={paidTaskFilter === "inReview" ? "contained" : "outlined"}
              onClick={() => setPaidTaskFilter("inReview")}
            >
              In&nbsp;Prüfung
            </Button>
            <Button
              variant={paidTaskFilter === "approved" ? "contained" : "outlined"}
              onClick={() => setPaidTaskFilter("approved")}
            >
              Abgenommen
            </Button>
          </ButtonGroup>
        </Stack>
      );
    default:
    case "assignableView":
      return null;
  }
};

export default PaidTaskFilter;
