export default function pageContentContainerStyle(theme) {
  return ({
      pageContainer: {
        padding: "2rem",
        [theme.breakpoints.down("sm")]: {
          padding: "1rem",
        },
      },
      contentContainer: {
        margin: "0 auto",
      },
      large: {
        maxWidth: 1400,
      },
      small: {
        maxWidth: 1100,
      },
      tiny: {
        maxWidth: 750,
      }
    }
  )
}



