import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    padding: "1rem 0",
    textAlign: "center",
  },
}));

export default useStyles;
