import React, { useEffect, useRef, useState } from "react";
import ContentBox from "../../ContentBox/ContentBox";
import { userHasOneOfTheseRoles } from "../../../services/backofficeUserService";
import { Box, Button, Typography } from "@mui/material";
import { scrollToAnchor } from "../../../theme/commonStyles";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import LoginIndependantFileUpload from "../../FileUpload/LoginIndependantFileUpload";
import { Roles } from "../../../types/BackofficeUser";
import signatureStyles from "./signatureStyles";
import ButtonLoading from "../../Button/ButtonLoading";
import { ContentContainer } from "../../Container/PageContentContainer";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { queryKeys, updateResource } from "../../../services/ReactQuery/reactQueryService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function Signature() {
  const currentUser = useCurrentUser();

  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fileToCrop, setFileToCrop] = useState("");
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState<any>();
  const cropperRef = useRef<HTMLImageElement>(null);
  const updateMutation = useMutation(updateResource);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (files.length < 1) {
      setFileToCrop("");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      setFileToCrop(reader.result as any);
    };
  }, [files]);

  if (!userHasOneOfTheseRoles(currentUser, [Roles.external, Roles.lawyer, Roles.admin])) {
    return null;
  }

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas({ width: 250, height: 100 }).toDataURL());
    }
  };

  const handleSignatureSave = async () => {
    if (cropData === "#") {
      return;
    }
    setIsLoading(true);
    await updateMutation.mutateAsync({
      uri: "backoffice_people",
      id: currentUser.person.id,
      data: {
        encodedSignature: cropData,
      },
    });
    await queryClient.invalidateQueries(queryKeys.all("backoffice_users"));
    await queryClient.invalidateQueries(queryKeys.all("current_user"));
    setIsLoading(false);
    setFiles([]);
    setCropData("#");
  };

  return (
    <>
      <Box sx={scrollToAnchor} id={"signature"} />
      <ContentBox headline={"Ihre Unterschrift"}>
        {fileToCrop && (
          <ContentContainer size={"tiny"}>
            <Typography sx={{ textAlign: "center", margin: "1rem 0" }}>
              <strong>Wichtig:</strong> Die Datei muss einen <strong>weißen Hintergrund</strong> und eine{" "}
              <strong>gute Bildqualität</strong> haben. Bitte positionieren Sie das Bild so, dass die Unterschrift{" "}
              <strong>groß genug und vollständig</strong> zu sehen ist. Nutzen Sie bitte immer die{" "}
              <strong>Vorschaufunktion</strong> um zu prüfen, ob die Unterschrift allen Vorgaben entspricht.
            </Typography>
          </ContentContainer>
        )}
        {currentUser.person.encodedSignature && !fileToCrop && (
          <Box component={"img"} sx={signatureStyles.imageBox} src={currentUser.person.encodedSignature} />
        )}
        <LoginIndependantFileUpload
          setFiles={setFiles}
          files={files}
          accept={"image/*"}
          buttonText={"Unterschrift hochladen"}
        />
        {fileToCrop && (
          <>
            <Cropper
              src={fileToCrop}
              style={{ height: 400, width: "100%" }}
              aspectRatio={250 / 100}
              dragMode={"move"}
              ref={cropperRef}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
            <Button onClick={getCropData} sx={signatureStyles.button}>
              Vorschau anzeigen
            </Button>
            {cropData !== "#" && (
              <>
                <Typography variant={"h4"} sx={{ textAlign: "center" }}>
                  Vorschau für Ihre Unterschrift
                </Typography>
                <Box component={"img"} sx={signatureStyles.imageBox} src={cropData} />
                <ButtonLoading
                  isLoading={isLoading}
                  variant={"contained"}
                  onClick={handleSignatureSave}
                  sx={signatureStyles.button}
                >
                  Speichern
                </ButtonLoading>
              </>
            )}
          </>
        )}
      </ContentBox>
    </>
  );
}
