import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 1024,
  },
  headline: {
    textAlign: "center",
  },
  formPaper: {
    padding: "2rem 5rem",
  },
}));

export default useStyles;
