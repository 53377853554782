import React from "react";
import Grid from "@mui/material/Grid";
import { ButtonGroup } from "@mui/material";
import Button from "@mui/material/Button";

const TimeFilter = ({ timespanFilter, setTimespanFilter, isCompletedView }) => {
  if (
    (typeof timespanFilter === "string" && isCompletedView && !["today", "yesterday"].includes(timespanFilter)) ||
    (!isCompletedView && timespanFilter === "yesterday")
  ) {
    setTimespanFilter("today");
  }
  return (
    <Grid container justifyContent={"flex-end"}>
      <ButtonGroup color="primary">
        {!isCompletedView && (
          <Button
            variant={timespanFilter === "overdue" ? "contained" : "outlined"}
            onClick={() => setTimespanFilter("overdue")}
          >
            Überfällig
          </Button>
        )}
        {isCompletedView && (
          <Button
            variant={timespanFilter === "yesterday" ? "contained" : "outlined"}
            onClick={() => setTimespanFilter("yesterday")}
          >
            Gestern
          </Button>
        )}
        <Button
          variant={timespanFilter === "today" ? "contained" : "outlined"}
          onClick={() => setTimespanFilter("today")}
        >
          Heute
        </Button>
        {!isCompletedView && (
          <Button
            variant={timespanFilter === "tomorrow" ? "contained" : "outlined"}
            onClick={() => setTimespanFilter("tomorrow")}
          >
            Morgen
          </Button>
        )}
      </ButtonGroup>
    </Grid>
  );
};

export default TimeFilter;
