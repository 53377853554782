import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  actionsContainer: {
    clipPath: "polygon(0% 0%, 120% 0%, 120% 100%, 0% 100%)",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    zIndex: 1110,
    width: 70,
    position: "fixed",
    height: "100vh",
  },
  icon: {
    height: 30,
    width: "auto",
  },
}));

export default useStyles;
