import * as status from "../LeadStatus/StatusSettlement";

const STAGE_INCOMPLETE = 0;
const STAGE_READY = 10;
const STAGE_CHECKED = 20;
const STAGE_SUBMITTED = 30;
const STAGE_PAID = 40;
const STAGE_DATE_SET_SETTLEMENT = 50;
const STAGE_DATE_SET_TRIAL = 60;
const STAGE_DECISION_MADE = 70;
const STAGE_COMPLETED = 90;

export {
  STAGE_INCOMPLETE,
  STAGE_READY,
  STAGE_CHECKED,
  STAGE_SUBMITTED,
  STAGE_PAID,
  STAGE_DATE_SET_SETTLEMENT,
  STAGE_DATE_SET_TRIAL,
  STAGE_DECISION_MADE,
  STAGE_COMPLETED,
};

export const stageMetaDataSettlement = {
  [STAGE_INCOMPLETE]: {
    label: "Unvollständig",
    stage: STAGE_INCOMPLETE,
    leadStatus: null,
    isActive: () => true,
    isClickable: () => false,
    showInBoard: true,
  },
  [STAGE_READY]: {
    label: "Vollständig",
    stage: STAGE_READY,
    leadStatus: status.STATUS_DOCUMENTS_COMPLETE,
    isActive: () => true,
    isClickable: ({ product }) => product.stage >= STAGE_CHECKED,
    showInBoard: true,
  },
  [STAGE_CHECKED]: {
    label: "Überprüft",
    stage: STAGE_CHECKED,
    leadStatus: status.STATUS_CHECKED,
    isActive: () => true,
    isClickable: ({ product }) => product.stage === STAGE_READY || product.stage >= STAGE_SUBMITTED,
    showInBoard: true,
  },
  [STAGE_SUBMITTED]: {
    label: "Klage eingereicht",
    stage: STAGE_SUBMITTED,
    leadStatus: status.STATUS_SUBMITTED,
    isActive: () => true,
    isClickable: ({ product }) => product.stage === STAGE_CHECKED || product.stage >= STAGE_PAID,
    showInBoard: true,
  },
  [STAGE_PAID]: {
    label: "Bezahlt / Gedeckt",
    stage: STAGE_PAID,
    leadStatus: status.STATUS_PAID,
    isActive: ({ product }) => product.paymentType !== "nwnf",
    isClickable: ({ product }) =>
      (product.stage === STAGE_SUBMITTED && product.paymentType !== "nwnf") ||
      product.stage >= STAGE_DATE_SET_SETTLEMENT,
    showInBoard: true,
  },
  [STAGE_DATE_SET_SETTLEMENT]: {
    label: "Gütetermin",
    stage: STAGE_DATE_SET_SETTLEMENT,
    leadStatus: status.STATUS_DATE_SET_SETTLEMENT,
    isActive: () => true,
    isClickable: ({ product }) =>
      product.stage === STAGE_PAID ||
      (product.stage === STAGE_SUBMITTED && product.paymentType !== "private") ||
      product.stage >= STAGE_DATE_SET_TRIAL,
    showInBoard: true,
  },
  [STAGE_DATE_SET_TRIAL]: {
    label: "Kammertermin",
    stage: STAGE_DATE_SET_TRIAL,
    leadStatus: status.STATUS_DATE_SET_TRIAL,
    isActive: ({ product }) => !product.settlementReachedDate,
    isClickable: ({ product }) =>
      (product.stage === STAGE_DATE_SET_SETTLEMENT && !product.settlementReachedDate) ||
      product.stage >= STAGE_DECISION_MADE,
    showInBoard: true,
  },
  [STAGE_DECISION_MADE]: {
    label: "Entscheidung gefällt",
    stage: STAGE_DECISION_MADE,
    leadStatus: status.STATUS_DECISION_MADE,
    isActive: () => true,
    isClickable: ({ product }) =>
      product.stage === STAGE_DATE_SET_TRIAL ||
      (product.stage === STAGE_DATE_SET_SETTLEMENT && product.settlementReachedDate) ||
      product.stage >= STAGE_COMPLETED,
    showInBoard: true,
  },
  [STAGE_COMPLETED]: {
    label: "Abgeschlossen",
    stage: STAGE_COMPLETED,
    leadStatus: status.STATUS_COMPLETED,
    isActive: () => true,
    isClickable: ({ product }) => product.stage === STAGE_DECISION_MADE,
    showInBoard: true,
  },
};
