import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import useForm from "../../../../hooks/useForm";
import _ from "lodash";
import FormElement from "../FormElement";
import addressFields from "../../../../services/Product/FormDefinitions/modules/addressFields";
import useStyles from "../Full/caseFullFormStyle";
import ApiClient from "../../../../services/ApiClient";
import { persistAddress, sanitizeOrganizationData } from "../../../../services/Persist/Persister";
import { translate } from "../../../../services/Translations/translatorService";
import CircularProgress from "@mui/material/CircularProgress";
import ButtonLoading from "../../../Button/ButtonLoading";
import {
  emailDefault,
  faxNumberDefault,
  isPhoneNumberWithoutSpecialCharactersDefault,
  requiredFieldDefault,
} from "../../../../services/validationRules";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";

const ProcessParticipantPersonForm = ({ product, participant, refreshPage }) => {
  const classes = useStyles({});
  const [isLoading, setIsLoading] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  const formElements = [
    {
      type: "ValidatorSelect",
      path: "participant.labelType",
      validators: requiredFieldDefault,
      options: () => [
        { value: "expert" },
        { value: "witness" },
        { value: "legalRepresentative" },
        { value: "judge" },
        { value: "interpreter" },
        { value: "insolvencyAdministrator" },
        { value: "other" },
      ],
    },
    {
      type: "ValidatorTextField",
      path: `participant.legalName`,
      validators: requiredFieldDefault,
    },

    {
      type: "ValidatorTextField",
      path: `participant.email`,
      validators: emailDefault,
    },
    {
      type: "ValidatorTextField",
      path: `participant.telephone`,
      validators: isPhoneNumberWithoutSpecialCharactersDefault,
    },
    {
      type: "ValidatorTextField",
      path: `participant.fax`,
      validators: faxNumberDefault,
      additionalProps: {
        InputProps: {
          startAdornment: <InputAdornment position="start">+49</InputAdornment>,
        },
      },
    },

    ...addressFields({ path: `participant.address` }),
  ];

  const deleteParticipant = async () => {
    setIsLoading(true);
    await ApiClient.delete("organizations/" + participant.id);
    refreshPage();
    setIsLoading(false);
  };

  const onSubmit = async ({ values }) => {
    const participantData = _.merge({}, values.participant, {
      address: undefined,
      type: "processParticipant",
      caseId: product.id,
    });

    const sanitizedParticipantData = sanitizeOrganizationData(participantData);

    if (!participant) {
      setIsLoading(true);
      const participantOrganization = await ApiClient.post("organizations", {
        body: JSON.stringify(sanitizedParticipantData),
      });
      await persistAddress(participantOrganization.address.id, values.participant.address);
    } else {
      setButtonIsLoading(true);
      await persistAddress(participant.address.id, values.participant.address);
      await ApiClient.put("organizations/" + participant.id, { body: JSON.stringify(sanitizedParticipantData) });
    }
    refreshPage();
    setIsLoading(false);
    setButtonIsLoading(false);
  };

  const initialValues = {
    participant: _.merge(
      {},
      participant || {
        labelType: "",
        legalName: "",
        email: "",
        telephone: "",
        fax: "",
        address: {
          streetAddress: "",
          postalCode: "",
          addressLocality: "",
        },
      }
    ),
  };

  const useFormProps = useForm({
    initialValues,
    onSubmit,
    identifier: product.id + "-" + (participant && participant.id),
    clearFormAfterSubmit: !participant,
  });

  const getLabel = (participant) => {
    if (!participant) {
      return "Neues Unternehmen hinzufügen";
    }

    const labelType = translate("participant.labelType.values." + participant.labelType);
    return labelType + ": " + participant.legalName;
  };

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Grid container className={classes.grid}>
      <Grid item xs={12}>
        <Typography variant={"h3"} sx={{ mt: 1, mb: 1 }}>
          {getLabel(participant)}
        </Typography>
      </Grid>
      {_.map(formElements, (formElementProps) => {
        if (formElementProps.isHidden && formElementProps.isHidden({ values: useFormProps.values, product })) {
          return null;
        }

        const fullWidth = formElementProps.additionalProps && formElementProps.additionalProps.fullWidth;
        return (
          <Grid key={formElementProps.path} item xs={12} md={!fullWidth && 6} lg={!fullWidth && 4}>
            <FormElement product={product} {...formElementProps} {...useFormProps} />
          </Grid>
        );
      })}
      <Grid item xs={12} md={4} />
      <Grid item xs={12} md={4} />
      <Grid item xs={12} md={4}>
        {participant && (
          <Button fullWidth onClick={deleteParticipant}>
            Entfernen
          </Button>
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        <ButtonLoading fullWidth variant="contained" onClick={useFormProps.handleSubmit} isLoading={buttonIsLoading}>
          Speichern
        </ButtonLoading>
      </Grid>
    </Grid>
  );
};
export default ProcessParticipantPersonForm;
