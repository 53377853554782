// Google Tag Manager Configuration depending on Environment (developmemnt, test, production)

export const googleAnalyticsConfig = () => {
  let googleAnalyticsArgs = {
    trackingId: "G-99DLNK4XTS",
  };

  switch (process.env.REACT_APP_DEPLOYMENT) {
    case "production":
      // production environment
      googleAnalyticsArgs.trackingId = "G-L2D71J65TK";
      break;
    case "development":
      // dev environment
      googleAnalyticsArgs.trackingId = "G-99DLNK4XTS";
      break;
    case "testing":
      // staging environment
      googleAnalyticsArgs.trackingId = "G-99DLNK4XTS";
      break;
    default:
      // dev environment
      googleAnalyticsArgs.trackingId = "G-99DLNK4XTS";
  }
  return googleAnalyticsArgs;
};
