import React from "react";
import { Avatar, Box, Paper, Stack, Typography } from "@mui/material";

export default function SmallWidget({ subject, count, icon }: { subject: string; count: number; icon: JSX.Element }) {
  return (
    <Paper sx={{ padding: "1rem", height: "100%" }}>
      <Stack spacing={2} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main }}>{icon}</Avatar>
        <Box>
          <Typography
            sx={{
              hyphens: "auto",
              textAlign: "left",
            }}
            variant={"h5"}
          >
            {subject}
          </Typography>
        </Box>
        <Typography variant={"h4"}>
          <strong>{count}</strong>
        </Typography>
      </Stack>
    </Paper>
  );
}
