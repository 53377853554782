import LegalbirdIoModal from "../../../Modal/LegalbirdIoModal";
import Button from "@mui/material/Button";
import React from "react";
import LegalBirdPageLoading from "../../../ContentLoader/LegalBirdPageLoading";

function CheckingDeleteModal({ open, handleClose }) {
  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      hideCancelButton
      title={"Prüfe löschen"}
      submitButton={<Button onClick={handleClose}>Abbrechen</Button>}
    >
      <LegalBirdPageLoading />
    </LegalbirdIoModal>
  );
}
export default CheckingDeleteModal;
