/*
 * Copyright © 2023. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

const mobileProviders = [
  { label: "1&1", value: "41910" },
  {
    label: "a.t.u.",
    value: "69781",
  },
  { label: "aetkaSMART", value: "69782" },
  { label: "Air Berlin", value: "900000000" },
  {
    label: "ALDI TALK",
    value: "43972",
  },
  { label: "allmobil", value: "69783" },
  { label: "allMobility", value: "900000001" },
  {
    label: "ALPHATEL",
    value: "69784",
  },
  { label: "Amiva", value: "69785" },
  { label: "BASE", value: "69786" },
  {
    label: "BigSIM",
    value: "69787",
  },
  { label: "BILDconnect", value: "69788" },
  { label: "BILDmobil", value: "69789" },
  {
    label: "blau",
    value: "50329",
  },
  { label: "blauworld", value: "69790" },
  { label: "callmobile", value: "69791" },
  {
    label: "cellway",
    value: "69792",
  },
  { label: "chixx", value: "900000002" },
  { label: "congstar", value: "42678" },
  {
    label: "connex Card",
    value: "900000003",
  },
  { label: "Conrad FairPay", value: "900000004" },
  { label: "Crash", value: "69793" },
  {
    label: "debitel",
    value: "43220",
  },
  { label: "debitel light", value: "69794" },
  { label: "Demcom", value: "900000005" },
  {
    label: "DeutschlandSIM",
    value: "69795",
  },
  { label: "discoPLUS", value: "69796" },
  { label: "discoSURF", value: "69797" },
  {
    label: "discoTEL",
    value: "69798",
  },
  { label: "DOKOM 21", value: "69799" },
  { label: "DR.SIM", value: "69800" },
  {
    label: "Drillisch Telekom",
    value: "69801",
  },
  { label: "Drillisch-Prepaid", value: "69802" },
  {
    label: "Duffy-Fon.DE",
    value: "900000006",
  },
  { label: "E WIE EINFACH", value: "900000007" },
  { label: "E-Plus", value: "44879" },
  {
    label: "easyTel",
    value: "69803",
  },
  { label: "Ecofon", value: "69804" },
  { label: "EDEKA mobil", value: "69805" },
  {
    label: "EDEKA smart",
    value: "69806",
  },
  { label: "Encomobil", value: "900000008" },
  { label: "EnoCom", value: "69807" },
  {
    label: "Envacom",
    value: "900000009",
  },
  { label: "envia TEL", value: "69808" },
  { label: "eteleon", value: "69809" },
  {
    label: "EWE",
    value: "69810",
  },
  { label: "Expressmobil", value: "69811" },
  { label: "fastSIM", value: "69812" },
  {
    label: "FCB Mobil",
    value: "69813",
  },
  { label: "fiotel", value: "69814" },
  { label: "FONIC", value: "40379" },
  {
    label: "fraenk",
    value: "69815",
  },
  { label: "freenet", value: "43220" },
  { label: "freenet FUNK", value: "69816" },
  {
    label: "freenet Mobile",
    value: "69817",
  },
  { label: "FreePhone", value: "69818" },
  { label: "FYVE", value: "69819" },
  {
    label: "FYVE Prepaid",
    value: "69820",
  },
  { label: "galaxyexperte.de", value: "69821" },
  { label: "Globus", value: "900000010" },
  { label: "gmx.de", value: "69822" },
  { label: "Gruner + Jahr", value: "900000012" },
  {
    label: "GTCom",
    value: "69823",
  },
  { label: "handyvertrag.de", value: "69824" },
  { label: "HanseNet", value: "69825" },
  {
    label: "HeLi NET",
    value: "69826",
  },
  { label: "helloMobil", value: "69827" },
  { label: "HFO Telecom", value: "69828" },
  {
    label: "HIGH",
    value: "69829",
  },
  { label: "Hit Mobile", value: "69830" },
  { label: "htp", value: "47524" },
  {
    label: "JKR Gruppe",
    value: "69831",
  },
  { label: "K-Classic Mobil", value: "69832" },
  { label: "KabelBW", value: "69833" },
  {
    label: "Kaufland Mobil",
    value: "69834",
  },
  { label: "KEVAG Telekom", value: "43685" },
  { label: "klarmobil", value: "43713" },
  {
    label: "kotel",
    value: "69835",
  },
  { label: "Lebara", value: "43805" },
  {
    label: "Lidl Connect",
    value: "69836",
  },
  { label: "Lidl Digital International GmbH & Co. KG", value: "43820" },
  {
    label: "Lifecell",
    value: "69837",
  },
  { label: "Lokalisten Fon", value: "69838" },
  {
    label: "Lycamobile",
    value: "900000013",
  },
  { label: "M-Net Mobil", value: "43874" },
  { label: "MAINGAU Mobilfunk", value: "43890" },
  {
    label: "Maxi Mobil",
    value: "69839",
  },
  { label: "maXXim", value: "69840" },
  { label: "McSIM", value: "69841" },
  {
    label: "Media Markt",
    value: "69842",
  },
  { label: "MegaSim", value: "69843" },
  { label: "mobi", value: "69844" },
  {
    label: "mobil09",
    value: "69845",
  },
  { label: "mobilcom-debitel", value: "43220" },
  { label: "Mobilka", value: "69846" },
  {
    label: "MoWoTel",
    value: "69847",
  },
  { label: "mp3.de mobile", value: "69848" },
  { label: "MTV Mobile", value: "69849" },
  {
    label: "n-tv",
    value: "69850",
  },
  { label: "N24", value: "900000014" },
  { label: "NetAachen", value: "69851" },
  {
    label: "NetCologne",
    value: "69852",
  },
  { label: "Netcom Kassel", value: "45731" },
  { label: "NettoKOM", value: "69853" },
  {
    label: "Netzclub",
    value: "69854",
  },
  { label: "NordCom", value: "69855" },
  { label: "NORMA Connect", value: "69856" },
  {
    label: "Norma Mobil",
    value: "69857",
  },
  { label: "novamobil", value: "69858" },
  { label: "o2", value: "44208" },
  {
    label: "Ortel Mobile",
    value: "69859",
  },
  { label: "osnatel", value: "69860" },
  { label: "OTELO", value: "69861" },
  {
    label: "Penny Mobil",
    value: "69862",
  },
  { label: "PHONEX", value: "69863" },
  { label: "PremiumSIM", value: "49139" },
  {
    label: "primacall",
    value: "69864",
  },
  { label: "primamobile", value: "69865" },
  { label: "primastrom", value: "39467" },
  {
    label: "ProSieben",
    value: "900000015",
  },
  { label: "PureMobile", value: "69866" },
  { label: "PYUR", value: "66851" },
  {
    label: "Quickster",
    value: "69867",
  },
  { label: "Red Bull Mobile", value: "900000016" },
  { label: "Rossmann mobil", value: "69868" },
  {
    label: "RTL",
    value: "900000017",
  },
  { label: "Saturn", value: "69869" },
  { label: "SAUBER WALDFUNK", value: "69870" },
  {
    label: "Schlecker Prepaid",
    value: "69871",
  },
  { label: "sim.de", value: "42831" },
  { label: "SimDiscount", value: "69872" },
  {
    label: "simfinity",
    value: "69873",
  },
  { label: "SIMon mobile", value: "69874" },
  { label: "simplytel", value: "69875" },
  {
    label: "simquadrat",
    value: "69876",
  },
  { label: "simyo", value: "69877" },
  { label: "Sipgate", value: "69878" },
  {
    label: "smartmobil.de",
    value: "48902",
  },
  { label: "smobil", value: "69879" },
  { label: "sparhandy", value: "69880" },
  {
    label: "STAR Communications",
    value: "69881",
  },
  { label: "STarMoney Handy", value: "69882" },
  { label: "Talkline", value: "69883" },
  {
    label: "Tarifhaus",
    value: "900000018",
  },
  { label: "Tchibo", value: "44862" },
  { label: "Telco", value: "69884" },
  {
    label: "TELE2",
    value: "69885",
  },
  { label: "Telekom", value: "44880" },
  { label: "Teleos", value: "69886" },
  {
    label: "Teleson",
    value: "44884",
  },
  { label: "The Phone House", value: "69887" },
  { label: "TNG", value: "57016" },
  {
    label: "TOGGO mobil",
    value: "900000019",
  },
  { label: "Truphone", value: "69888" },
  { label: "Turkcell Europe", value: "69889" },
  {
    label: "Unitymedia",
    value: "41692",
  },
  { label: "Versatel", value: "69890" },
  { label: "VictorVox", value: "69891" },
  {
    label: "Viva",
    value: "69892",
  },
  { label: "Vodafone", value: "41692" },
  {
    label: "Volkswagen TeleCom",
    value: "900000020",
  },
  { label: "voxenergie", value: "45066" },
  { label: "vybemobile", value: "69893" },
  {
    label: "web.de",
    value: "69894",
  },
  { label: "Weltbild Mobil", value: "69895" },
  { label: "WEtell", value: "69896" },
  {
    label: "WhatsApp SIM",
    value: "69897",
  },
  { label: "wilhelm.tel.Mobil", value: "69898" },
  { label: "winSIM", value: "68158" },
  {
    label: "wirmobil",
    value: "69899",
  },
  { label: "WOBCOM", value: "69906" },
  { label: "Youni", value: "69907" },
  {
    label: "yourfone",
    value: "69908",
  },
  { label: "Z-Tel", value: "900000021" },
];

export default mobileProviders;
