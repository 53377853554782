import customerTranslations from "./customerTranslations";
import documentTranslations from "./documentTranslations";

const powerOfAttorneyTranslations = {
  label: "Vorsorgevollmacht",
  customer: customerTranslations,
  ...documentTranslations,
  petitionerSelection: {
    label: "Vorsorgevollmacht für?",
    values: {
      self: "Für mich",
      other: "Für jemand anderes",
    },
  },
  petitioner: {
    gender: {
      label: "Anrede",
      values: {
        male: "Herr",
        female: "Frau",
      },
    },
    givenName: {
      label: "Vorname",
    },
    familyName: {
      label: "Nachname",
    },
    birthDate: {
      label: "Geburtsdatum",
    },
    residenceAddress: {
      streetAddress: {
        label: "Straße und Hausnummer",
      },
      postalCode: {
        label: "PLZ",
      },
      addressLocality: {
        label: "Ort",
      },
    },
  },
  legallyIncompetent: {
    label: "Wie Geschäftsunfähigkeit feststellen?",
    values: {
      doctor: "schriftliche Bestätigung eines Arztes",
      certificate: "Bevollmächtigte + ärztlichem Gutachten",
    },
  },
  validityPeriod: {
    label: "über den Tod hinaus gelten?",
    values: {
      afterDeath: "Ja",
      limited: "Nein",
    },
  },
  representatives: [
    {
      decisionType: {
        label: "Vertretungsart Bevollmächtigter 1",
        values: {
          single: "Einzelvertretung",
          collective: "Gemeinschaftlich",
        },
      },
      representative: {
        telephone: {
          label: "Telefonnummer Bevollmächtigter 1",
        },
        person: {
          gender: {
            label: "Anrede Bevollmächtigter 1",
            values: {
              male: "Herr",
              female: "Frau",
            },
          },
          givenName: {
            label: "Vorname Bevollmächtigter 1",
          },
          familyName: {
            label: "Nachname Bevollmächtigter 1",
          },
          birthDate: {
            label: "Geburtsdatum Bevollmächtigter 1",
          },
          residenceAddress: {
            streetAddress: {
              label: "Straße und Hausnummer Bevollmächtigter 1",
            },
            postalCode: {
              label: "PLZ Bevollmächtigter 1",
            },
            addressLocality: {
              label: "Ort Bevollmächtigter 1",
            },
          },
        },
      },
    },
    {
      decisionType: {
        label: "Vertretungsart Bevollmächtigter 2",
        values: {
          single: "Einzelvertretung",
          collective: "Gemeinschaftlich",
        },
      },
      representative: {
        telephone: {
          label: "Telefonnummer Bevollmächtigter 2",
        },
        person: {
          gender: {
            label: "Anrede Bevollmächtigter 2",
            values: {
              male: "Herr",
              female: "Frau",
            },
          },
          givenName: {
            label: "Vorname Bevollmächtigter 2",
          },
          familyName: {
            label: "Nachname Bevollmächtigter 2",
          },
          birthDate: {
            label: "Geburtsdatum Bevollmächtigter 2",
          },
          residenceAddress: {
            streetAddress: {
              label: "Straße und Hausnummer Bevollmächtigter 2",
            },
            postalCode: {
              label: "PLZ Bevollmächtigter 2",
            },
            addressLocality: {
              label: "Ort Bevollmächtigter 2",
            },
          },
        },
      },
    },
    {
      decisionType: {
        label: "Vertretungsart Bevollmächtigter 3",
        values: {
          single: "Einzelvertretung",
          collective: "Gemeinschaftlich",
        },
      },
      representative: {
        telephone: {
          label: "Telefonnummer Bevollmächtigter 3",
        },
        person: {
          gender: {
            label: "Anrede Bevollmächtigter 3",
            values: {
              male: "Herr",
              female: "Frau",
            },
          },
          givenName: {
            label: "Vorname Bevollmächtigter 3",
          },
          familyName: {
            label: "Nachname Bevollmächtigter 3",
          },
          birthDate: {
            label: "Geburtsdatum Bevollmächtigter 3",
          },
          residenceAddress: {
            streetAddress: {
              label: "Straße und Hausnummer Bevollmächtigter 3",
            },
            postalCode: {
              label: "PLZ Bevollmächtigter 3",
            },
            addressLocality: {
              label: "Ort Bevollmächtigter 3",
            },
          },
        },
      },
    },
    {
      decisionType: {
        label: "Vertretungsart Bevollmächtigter 4",
        values: {
          single: "Einzelvertretung",
          collective: "Gemeinschaftlich",
        },
      },
      representative: {
        telephone: {
          label: "Telefonnummer Bevollmächtigter 4",
        },
        person: {
          gender: {
            label: "Anrede Bevollmächtigter 4",
            values: {
              male: "Herr",
              female: "Frau",
            },
          },
          givenName: {
            label: "Vorname Bevollmächtigter 4",
          },
          familyName: {
            label: "Nachname Bevollmächtigter 4",
          },
          birthDate: {
            label: "Geburtsdatum Bevollmächtigter 4",
          },
          residenceAddress: {
            streetAddress: {
              label: "Straße und Hausnummer Bevollmächtigter 4",
            },
            postalCode: {
              label: "PLZ Bevollmächtigter 4",
            },
            addressLocality: {
              label: "Ort Bevollmächtigter 4",
            },
          },
        },
      },
    },
    {
      decisionType: {
        label: "Vertretungsart Bevollmächtigter 5",
        values: {
          single: "Einzelvertretung",
          collective: "Gemeinschaftlich",
        },
      },
      representative: {
        telephone: {
          label: "Telefonnummer Bevollmächtigter 5",
        },
        person: {
          gender: {
            label: "Anrede Bevollmächtigter 5",
            values: {
              male: "Herr",
              female: "Frau",
            },
          },
          givenName: {
            label: "Vorname Bevollmächtigter 5",
          },
          familyName: {
            label: "Nachname Bevollmächtigter 5",
          },
          birthDate: {
            label: "Geburtsdatum Bevollmächtigter 5",
          },
          residenceAddress: {
            streetAddress: {
              label: "Straße und Hausnummer Bevollmächtigter 5",
            },
            postalCode: {
              label: "PLZ Bevollmächtigter 5",
            },
            addressLocality: {
              label: "Ort Bevollmächtigter 5",
            },
          },
        },
      },
    },
    {
      decisionType: {
        label: "Vertretungsart Bevollmächtigter 6",
        values: {
          single: "Einzelvertretung",
          collective: "Gemeinschaftlich",
        },
      },
      representative: {
        telephone: {
          label: "Telefonnummer Bevollmächtigter 6",
        },
        person: {
          gender: {
            label: "Anrede Bevollmächtigter 6",
            values: {
              male: "Herr",
              female: "Frau",
            },
          },
          givenName: {
            label: "Vorname Bevollmächtigter 6",
          },
          familyName: {
            label: "Nachname Bevollmächtigter 6",
          },
          birthDate: {
            label: "Geburtsdatum Bevollmächtigter 6",
          },
          residenceAddress: {
            streetAddress: {
              label: "Straße und Hausnummer Bevollmächtigter 6",
            },
            postalCode: {
              label: "PLZ Bevollmächtigter 6",
            },
            addressLocality: {
              label: "Ort Bevollmächtigter 6",
            },
          },
        },
      },
    },
    {
      decisionType: {
        label: "Vertretungsart Bevollmächtigter 7",
        values: {
          single: "Einzelvertretung",
          collective: "Gemeinschaftlich",
        },
      },
      representative: {
        telephone: {
          label: "Telefonnummer Bevollmächtigter 7",
        },
        person: {
          gender: {
            label: "Anrede Bevollmächtigter 7",
            values: {
              male: "Herr",
              female: "Frau",
            },
          },
          givenName: {
            label: "Vorname Bevollmächtigter 7",
          },
          familyName: {
            label: "Nachname Bevollmächtigter 7",
          },
          birthDate: {
            label: "Geburtsdatum Bevollmächtigter 7",
          },
          residenceAddress: {
            streetAddress: {
              label: "Straße und Hausnummer Bevollmächtigter 7",
            },
            postalCode: {
              label: "PLZ Bevollmächtigter 7",
            },
            addressLocality: {
              label: "Ort Bevollmächtigter 7",
            },
          },
        },
      },
    },
    {
      decisionType: {
        label: "Vertretungsart Bevollmächtigter 8",
        values: {
          single: "Einzelvertretung",
          collective: "Gemeinschaftlich",
        },
      },
      representative: {
        telephone: {
          label: "Telefonnummer Bevollmächtigter 8",
        },
        person: {
          gender: {
            label: "Anrede Bevollmächtigter 8",
            values: {
              male: "Herr",
              female: "Frau",
            },
          },
          givenName: {
            label: "Vorname Bevollmächtigter 8",
          },
          familyName: {
            label: "Nachname Bevollmächtigter 8",
          },
          birthDate: {
            label: "Geburtsdatum Bevollmächtigter 8",
          },
          residenceAddress: {
            streetAddress: {
              label: "Straße und Hausnummer Bevollmächtigter 8",
            },
            postalCode: {
              label: "PLZ Bevollmächtigter 8",
            },
            addressLocality: {
              label: "Ort Bevollmächtigter 8",
            },
          },
        },
      },
    },
    {
      decisionType: {
        label: "Vertretungsart Bevollmächtigter 9",
        values: {
          single: "Einzelvertretung",
          collective: "Gemeinschaftlich",
        },
      },
      representative: {
        telephone: {
          label: "Telefonnummer Bevollmächtigter 9",
        },
        person: {
          gender: {
            label: "Anrede Bevollmächtigter 9",
            values: {
              male: "Herr",
              female: "Frau",
            },
          },
          givenName: {
            label: "Vorname Bevollmächtigter 9",
          },
          familyName: {
            label: "Nachname Bevollmächtigter 9",
          },
          birthDate: {
            label: "Geburtsdatum Bevollmächtigter 9",
          },
          residenceAddress: {
            streetAddress: {
              label: "Straße und Hausnummer Bevollmächtigter 9",
            },
            postalCode: {
              label: "PLZ Bevollmächtigter 9",
            },
            addressLocality: {
              label: "Ort Bevollmächtigter 9",
            },
          },
        },
      },
    },
    {
      decisionType: {
        label: "Vertretungsart Bevollmächtigter 10",
        values: {
          single: "Einzelvertretung",
          collective: "Gemeinschaftlich",
        },
      },
      representative: {
        telephone: {
          label: "Telefonnummer Bevollmächtigter 10",
        },
        person: {
          gender: {
            label: "Anrede Bevollmächtigter 10",
            values: {
              male: "Herr",
              female: "Frau",
            },
          },
          givenName: {
            label: "Vorname Bevollmächtigter 10",
          },
          familyName: {
            label: "Nachname Bevollmächtigter 10",
          },
          birthDate: {
            label: "Geburtsdatum Bevollmächtigter 10",
          },
          residenceAddress: {
            streetAddress: {
              label: "Straße und Hausnummer Bevollmächtigter 10",
            },
            postalCode: {
              label: "PLZ Bevollmächtigter 10",
            },
            addressLocality: {
              label: "Ort Bevollmächtigter 10",
            },
          },
        },
      },
    },
  ],
  replacementRepresentative: {
    telephone: {
      label: "Telefonnummer Ersatzbevollmächtigter",
    },
    person: {
      gender: {
        label: "Anrede Ersatzbevollmächtigter",
        values: {
          male: "Herr",
          female: "Frau",
        },
      },
      givenName: {
        label: "Vorname Ersatzbevollmächtigter",
      },
      familyName: {
        label: "Nachname Ersatzbevollmächtigter",
      },
      birthDate: {
        label: "Geburtsdatum Ersatzbevollmächtigter",
      },
      residenceAddress: {
        streetAddress: {
          label: "Straße und Hausnummer Ersatzbevollmächtigter",
        },
        postalCode: {
          label: "PLZ Ersatzbevollmächtigter",
        },
        addressLocality: {
          label: "Ort Ersatzbevollmächtigter",
        },
      },
    },
  },
  sub: {
    label: "Untervollmachten?",
    values: {
      subYes: "Ja",
      subNo: "Nein",
    },
  },
  authorizationMedical: {
    label: "Wie vertreten?",
    values: {
      amFull: "Uneingeschränkte Vertretung",
      amLimited: "Genauere Regelung",
    },
  },
  hasReplacementRepresentative: {
    label: "Ersatzperson bevollmächtigen?",
  },
  medicalType: {
    label: "Was darf Bevollmächtigte, Bezug Ärzte",
    values: {
      seeDocuments: "Unterlagen einsehen",
      treatment: "Behandlungsfragen entscheiden",
      careStaff: "vor Pflegepersonal vertreten",
      mtNone: "Keine Berechtigungen",
    },
  },
  freedomType: {
    label: "Was darf Bevollmächtigte, Bezug Freiheit",
    values: {
      confinement: "Unterbringung und Freiheitsentzug",
      medical: "Ärztliche Zwangsmaßnahmen",
      ftNone: "Keine Bevollmächtigung",
    },
  },
  residencyType: {
    label: "Was darf Bevollmächtigte, Bezug Wohnsituation",
    values: {
      residency: "Bestimmen wo ich wohne",
      rent: "Wohnung mieten",
      retirement: "Heimvertrag",
      rtNone: "Keine Bevollmächtigung",
    },
  },
  lawType: {
    label: "Was darf Bevollmächtigte, Bezug Behörden",
    values: {
      authority: "Uneingeschränkte Vertretung vor Behörden",
      court: "Vertretung gegenüber allen Gerichten",
      lawyer: "Beauftragung von Rechtsanwälten",
      ltNone: "Keine Bevollmächtigung",
    },
  },
  digital: {
    label: "Was darf Bevollmächtigte, Bezug Daten",
    values: {
      complete: "Uneingeschränkt",
      delete: "Nur Löschen",
      digitalNone: "Keine Bevollmächtigung",
    },
  },
  money: {
    label: "Bevollmächtigte Ihr Vermögen verwalten?",
    values: {
      moneyYes: "Ja",
      donate: "Ja, ausgenommen Schenkungen",
      self: "Ja, ausgenommen Selbstschenkungen",
      moneyNo: "Nein",
    },
  },
  additional: {
    label: "Zusätzliche Berechtigungen",
    values: {
      post: "Post öffnen und lesen",
      telecommunication: "Telekommunikationsverträge verwalten",
      bank: "Mich gegenüber Banken vertreten",
      insurance: "Mich gegenüber Versicherungen vertreten",
    },
  },
};

export default powerOfAttorneyTranslations;
