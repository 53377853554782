import React, { ChangeEventHandler, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Button, IconButton, Stack } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { fileCheck } from "../../services/formServiceFunctions";
import { useSnackbar } from "notistack";
import Snackbar from "../Snackbar/Snackbar";

type LoginIndependantFileUploadProps = {
  headline?: string;
  helperText?: string;
  accept?: string;
  buttonText?: string;
  files: Array<any>;
  setFiles: Function;
};

export default function LoginIndependantFileUpload({
  headline,
  helperText,
  files,
  setFiles,
  accept = "image/*, application/pdf",
  buttonText = "Datei Auswählen",
}: LoginIndependantFileUploadProps) {
  const [clearInput, setClearInput] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const handleFilesChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const fileCheckRes = fileCheck(event.target);
    if (fileCheckRes.hasError === true) {
      enqueueSnackbar("", {
        content: () => (
          <div>
            <Snackbar message={fileCheckRes.errorMsg} isNonInteractive={true} />
          </div>
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      return;
    }

    setFiles(event.target.files);
  };

  return (
    <React.Fragment key={clearInput}>
      {headline && <Typography variant={"h5"}>{headline}</Typography>}
      {helperText && <p>{helperText}</p>}
      <label htmlFor={"button-file"}>
        <Box sx={{ display: "none" }}>
          <input accept={accept} id={"button-file"} type="file" onChange={handleFilesChange} />
        </Box>
        <Stack direction="row" justifyContent={"flex-end"}>
          {files.length < 1 && <Button component={"span"}>{buttonText}</Button>}
        </Stack>
      </label>
      {files.length > 0 && (
        <Stack direction="row" justifyContent={"flex-end"} alignItems={"center"} spacing={2}>
          <Box sx={{ wordBreak: "break-all" }}>{files[0].name}</Box>
          <IconButton
            onClick={() => {
              setFiles([]);
              setClearInput(clearInput + 1);
            }}
          >
            <Delete />
          </IconButton>
        </Stack>
      )}
    </React.Fragment>
  );
}
