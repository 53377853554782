import React from "react";
import PageContentContainer from "../../Container/PageContentContainer";
import PageHeadline from "../../PageHeadline/PageHeadline";
import ReleaseUpdates from "../../ReleaseUpdates/ReleaseUpdates";

export default function ReleaseUpdatesPage() {

  return (
    <PageContentContainer>
      <PageHeadline main={"Release-Updates"} />
      <ReleaseUpdates />
    </PageContentContainer>
  );
};
