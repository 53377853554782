const authorityTranslations = {
  label: "Zuständige Behörde",
  name: {
    label: "Name Behörde",
  },
  department: {
    label: "Name Behörde Zeile 2",
  },
  subDepartment: {
    label: "Name Behörde Zeile 3",
  },
  telephone: {
    label: "Telefon Behörde",
  },
  communicationMedium: {
    label: "Kommunikationskanal",
    values: {
      fax: "per Fax",
      bea: "per beA",
    },
  },
  fax: {
    label: "Fax Behörde",
  },
  beaSafeId: {
    label: "beA-Adresse",
  },
  address: {
    streetAddress: {
      label: "Straße und Nr Behörde",
    },
    postalCode: {
      label: "PLZ Behörde",
    },
    addressLocality: {
      label: "Ort Behörde",
    },
    postOfficeBoxNumber: {
      label: "Postfach",
    },
  },
};

export default authorityTranslations;
