import React from "react";
import { BeaMessage } from "../../types/Bea/BeaMessage";
import BeaMessagesTable from "./BeaMessagesTable";
import ContentBox from "../ContentBox/ContentBox";

export default function BeaMessagesAttachments({ beaMessages }: { beaMessages: Array<BeaMessage> }) {
  return (
    <ContentBox>
      <BeaMessagesTable beaMessages={beaMessages} hasLink={true} />
    </ContentBox>
  );
}
