import React from "react";
import * as PropTypes from "prop-types";
import LinkListItemWithIcon from "../LinkListItemWithIcon/LinkListItemWithIcon";
import { List } from "@mui/material";
import authorityImage from "../../assets/icon/icon_behoerde_100px.png";
import familyImage from "../../assets/icon/product/icon_familienrecht.png";
import techStatusImage from "../../assets/icon/icon_admintool.png";
import createReleaseUpdatesImage from "../../assets/icon/release_updates_creation.png";
import releaseUpdatesImage from "../../assets/icon/release_updates.png";
import applicationImage from "../../assets/icon/icon_antrag_erstellen_150px.png";
import lawyerIcon from "../../assets/icon/icon_anwalt_128px.png";
import personIcon from "../../assets/icon/person_faceless_250px.png";
import statsIcon from "../../assets/icon/icon_statistik.png";
import { userHasOneOfTheseRoles } from "../../services/backofficeUserService";
import { BackofficeUser } from "../../types/BackofficeUser";

type AdministrationProps = {
  currentUser: BackofficeUser;
};
const Administration = ({ currentUser } : AdministrationProps) => {

  return (
    <List>
      <LinkListItemWithIcon
        title={"Datensätze verwalten"}
        description={"Daten für Behörde, StA, Inkasso, Gegenseite und Gerichte bearbeiten"}
        to={"/services/tools/datensaetze"}
        imageIcon={authorityImage}
      />
      <LinkListItemWithIcon
        title={"HR Planung"}
        description={"Arbeitsressourcen definieren und planen"}
        to={"/services/tools/hr-planung"}
        imageIcon={familyImage}
      />
      <LinkListItemWithIcon
        title={"Übersicht Release-Updates"}
        description={"Archiv aktueller und vergangener Release-Updates"}
        to={"/services/tools/release-updates"}
        imageIcon={releaseUpdatesImage}
      />
      {userHasOneOfTheseRoles(currentUser, ["ROLE_ADMIN", "ROLE_DEVELOPER"]) && (
        <>
          <LinkListItemWithIcon
            title={"Editor Release-Update"}
            description={"Updates erstellen und veröffentlichen"}
            to={"/services/tools/release-update/neu"}
            imageIcon={createReleaseUpdatesImage}
          />
          <LinkListItemWithIcon
            title={"Nutzerverwaltung"}
            description={"Nutzer hinzufügen, deaktivieren und bearbeiten"}
            to={"/services/tools/nutzer-verwalten"}
            imageIcon={personIcon}
          />
          <LinkListItemWithIcon
            title={"Admin-Board"}
            description={"Tools und Funktionen zum Beheben technischer Probleme"}
            to={"/services/tools/admin-board"}
            imageIcon={techStatusImage}
          />
          <LinkListItemWithIcon
            title={"Nutzeransichten"}
            description={"Software aus Sicht verschiedener Nutzerrollen ansehen"}
            to={"/services/papageienaufstand"}
            imageIcon={familyImage}
          />
          <LinkListItemWithIcon
            title={"Vorlagen"}
            description={"Produktspezifische Vorlagen mit Platzhaltern erstellen"}
            to={"/services/tools/vorlagen"}
            imageIcon={applicationImage}
          />
        </>
      )}
      <LinkListItemWithIcon
        title={"Bewerbungen"}
        description={"Eingehende Bewerbungen externer Anwälte prüfen"}
        to={"/services/tools/bewerbungen"}
        imageIcon={lawyerIcon}
      />
      <LinkListItemWithIcon
        title={"Statistiken"}
        description={"Customer Happiness, Perfect Days & Aufgaben im Überblick"}
        to={"/services/tools/statistiken"}
        imageIcon={statsIcon}
      />
    </List>
  );
};

Administration.propTypes = {
  currentUser: PropTypes.object.isRequired,
};

export default Administration;
