import React, { useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { MoreHoriz } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import * as PropTypes from "prop-types";
import useStyles from "./popUpMenuStyles";
import { Tooltip } from "@mui/material";

const PopUpMenu = ({ children, disabled, icon, tooltipTitle }) => {
  const classes = useStyles({});
  const myAnchor = useRef();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <IconButton onClick={handleMenuOpen} disabled={disabled} className={classes.iconButton} size="large">
          {icon}
        </IconButton>
      </Tooltip>
      <div ref={myAnchor} />
      <Menu open={menuOpen} anchorEl={myAnchor.current} onClose={handleMenuClose} onClick={() => setMenuOpen(false)}>
        {children}
      </Menu>
    </>
  );
};

PopUpMenu.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  tooltipTitle: PropTypes.string,
};

PopUpMenu.defaultProps = {
  disabled: false,
  icon: <MoreHoriz />,
  tooltipTitle: "",
};

export default PopUpMenu;
