/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { Theme } from "@mui/material";

export default function footerStyle(theme: Theme) {
  return ({
    footer: {
      marginTop: "4rem",
    },
    footerContainer: {
      backgroundColor: theme.palette.primary.main,
      padding: "2rem",
      color: theme.palette.primary.contrastText,
    },
    logo: {
      maxWidth: 150,
      maxHeight: 60,
      margin: "0 3rem 0 0",
      [theme.breakpoints.down('sm')]: {
        margin: "0 auto 3rem auto",
        display: "block",
      },
    },
  });
}
