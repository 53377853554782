import React, { useEffect, useMemo, useState } from "react";
import { Badge, IconButton, Menu, MenuItem } from "@mui/material";
import { CheckOutlined, NotificationsOutlined } from "@mui/icons-material";
import { isCookieFetched, mercureFetchCookie, mercureSubscribe } from "../../services/mercure";
import _ from "lodash";
import NavigationBarNotificationItem from "./NavigationBarNotificationItem/NavigationBarNotificationItem";
import { Notification } from "../../types/Notification";
import { apiGet } from "../../services/Api/apiCall";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

export default function NavigationBarNotifications() {
  const [notifications, setNotifications] = useState<any>(null);
  const currentUser = useCurrentUser();
  const cookieFetched = isCookieFetched();
  useEffect(() => {
    if (cookieFetched) {
      const eventSource = mercureSubscribe(currentUser["@id"] + "/notifications", setNotifications);
      return () => {
        eventSource.close();
      };
    } else {
      mercureFetchCookie();
    }
  }, [cookieFetched]);

  useEffect(() => {
    if (!notifications) {
      let options = { headers: new Headers() };
      options.headers.set("Accept", "application/json");
      apiGet("notifications", null, { backofficeUser: currentUser["@id"] }, options).then(
        (notificationsResult: Notification[]) => {
          const maximumNotifications = currentUser.isExternal ? 50 : 5;
          setNotifications(notificationsResult.slice(0, maximumNotifications));
        }
      );
    }
  }, [notifications]);

  return <NotificationsInner notifications={notifications} />;
}

const NotificationsInner = ({ notifications }: { notifications: Notification[] | null }) => {
  const [notificationsOpen, setNotficationsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const handleClick = ({ target }: any) => {
    setAnchorEl(target);
    setNotficationsOpen(true);
  };

  const handleAllRead = () => {
    apiGet("/notifications/read");
    setNotficationsOpen(false);
  };

  const newNotificationCount = useMemo(() => {
    if (!notifications) {
      return null;
    }
    return _.filter(notifications, (notification) => !notification.isRead).length;
  }, [notifications]);

  return (
    <>
      <IconButton onClick={handleClick} size={"large"}>
        <Badge badgeContent={newNotificationCount} color={"primary"}>
          <NotificationsOutlined sx={{ height: "30px", width: "auto" }} />
        </Badge>
      </IconButton>
      {!!notifications && notifications.length > 0 ? (
        <Menu open={notificationsOpen} onClose={() => setNotficationsOpen(false)} anchorEl={anchorEl}>
          {_.map(notifications, (notification) => (
            <MenuItem sx={{ borderBottom: "1px solid #ddd" }} key={notification.id}>
              <NavigationBarNotificationItem
                notification={notification}
                handleClose={() => setNotficationsOpen(false)}
              />
            </MenuItem>
          ))}
          {!!newNotificationCount && (
            <MenuItem onClick={handleAllRead}>
              <CheckOutlined sx={{ marginRight: "1rem" }} />
              <strong>Alle als gelesen markieren</strong>
            </MenuItem>
          )}
        </Menu>
      ) : (
        <Menu open={notificationsOpen} onClose={() => setNotficationsOpen(false)} anchorEl={anchorEl}>
          <MenuItem onClick={() => setNotficationsOpen(false)} sx={{ textAlign: "center" }}>
            Keine aktuellen Benachrichtigungen
          </MenuItem>
        </Menu>
      )}
    </>
  );
};
