import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import * as PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import ActivityInfo from "./ActivityInfo";
import CircleIcon from "../CircleIcon/CircleIcon";
import { getActivityAnchor, getActivityIcon } from "../../services/Activities/activityTypes";
import classNames from "classnames";
import { ACTIVITY_OVERDUE } from "../../services/Stages/Stages";
import { ReportProblemOutlined } from "@mui/icons-material";
import ActivityActions from "./ActivityActions/ActivityActions";
import ActivityNote from "./ActivityNote";
import { HashLink } from "react-router-hash-link";
import { Hidden } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  headline: {
    fontSize: "1rem",
    marginRight: "1rem",
  },
  overdue: {
    color: "#ca0d0d",
  },
  mandatory: {
    color: "#de840f",
  },
  imageGridItem: {
    width: 72,
  },
  activityContent: {
    width: "calc(100% - 72px)",
  },
  activityContentContainer: {
    height: "100%",
    [theme.breakpoints.up("lg")]: {
      flexWrap: "noWrap",
    },
  },
  activityInfo: {
    flexGrow: 1,
    marginLeft: ".5rem",
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 0,
      width: "100%",
    },
  },
  activityActions: {
    minWidth: 180,
  },
}));

const Activity = ({ activity, product, updateActivities }) => {
  const classes = useStyles({});

  if (!activity) {
    return null;
  }

  const getMandatoryOrOverdueClass = (activity) => {
    if (activity.activityStatus === ACTIVITY_OVERDUE) {
      return classes.overdue;
    }
    if (activity.mandatory) {
      return classes.mandatory;
    }
    return null;
  };

  const icon = getActivityIcon(product.productClassName, activity.type);
  const anchor = getActivityAnchor(product.productClassName, activity.type);

  const anchorProps = anchor ? { component: HashLink, to: anchor } : {};

  return (
    <>
      <Grid container>
        <Hidden xsDown>
          <Grid className={classes.imageGridItem} item {...anchorProps}>
            <CircleIcon icon={icon} type={"muiIcon"} />
          </Grid>
        </Hidden>
        <Grid item className={classes.activityContent}>
          <Grid container className={classes.activityContentContainer} alignItems={"flex-start"}>
            <Grid item className={classNames(classes.headline, getMandatoryOrOverdueClass(activity))}>
              {activity.subject}
            </Grid>
            {activity.mandatory && (
              <Grid item>
                <ReportProblemOutlined />
              </Grid>
            )}
            <Grid item className={classes.activityInfo}>
              <ActivityInfo activity={activity} />
            </Grid>
            <Grid item className={classes.activityActions}>
              <ActivityActions activity={activity} updateActivities={updateActivities} product={product} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ActivityNote noteContent={activity.note} />
    </>
  );
};

Activity.propTypes = {
  activity: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
};

export default Activity;
