import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Input } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Clear } from "@mui/icons-material";
import clearableSearchFieldStyles from "./clearableSearchFieldStyles";

type ClearableSearchFieldProps = {
  filter: string;
  placeholder: string;
  setFilter: (value: string) => void;
  handleClearFilter: () => void;
  showSearchButton?: boolean;
  handleSearchClick?: () => void;
};

export default function ClearableSearchField({
  filter,
  setFilter,
  handleClearFilter,
  placeholder,
  showSearchButton,
  handleSearchClick,
}: ClearableSearchFieldProps) {
  return (
    <Input
      value={filter}
      onChange={(event) => setFilter(event.target.value)}
      placeholder={placeholder}
      sx={clearableSearchFieldStyles.searchField}
      startAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        <>
          {showSearchButton && (
            <InputAdornment position="start">
              <Button onClick={handleSearchClick} sx={clearableSearchFieldStyles.button}>
                Suchen
              </Button>
            </InputAdornment>
          )}
          <InputAdornment position="end">
            <IconButton sx={clearableSearchFieldStyles.button} onClick={handleClearFilter} size="large">
              <Clear />
            </IconButton>
          </InputAdornment>
        </>
      }
    />
  );
}
