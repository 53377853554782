import React, { useState } from "react";
import * as PropTypes from "prop-types";
import useStyles from "./emailsStyle";
import _ from "lodash";
import Email from "./Email";
import ButtonLoading from "../Button/ButtonLoading";
import ContentBox from "../ContentBox/ContentBox";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { Box } from "@mui/material";

const Emails = ({ product }) => {
  const classes = useStyles();
  const [emailDisplayCount, setEmailDisplayCount] = useState(3);
  const currentUser = useCurrentUser();

  let emailFilters = {
    backofficeCase: product.backofficeCase["@id"],
  };
  if (currentUser.isExternalUser) {
    emailFilters.hiddenExternally = false;
  }
  const { data: emails, isLoading } = useQuery(
    queryKeys.collection("email_messages", emailFilters),
    () => fetchCollection("email_messages", emailFilters),
    {
      enabled: !!product,
    }
  );

  if (!emails || isLoading) {
    return null;
  }

  const displayedEmails = _.slice(emails["hydra:member"], 0, emailDisplayCount);
  return (
    <>
      <div className={classes.scrollToAnchor} id={"mails"} />
      <ContentBox headline="E-Mails">
        {displayedEmails.length > 0 ? (
          _.map(displayedEmails, (emailMessage, index) => (
            <Box margin={"2rem 0 0 0"} key={index}>
              <Email emailMessage={emailMessage} updateEmailListEntry={() => {}} product={product} />
            </Box>
          ))
        ) : (
          <div className={classes.noMails}>Es sind noch keine Mails vorhanden</div>
        )}
        {emails["hydra:totalItems"] > emailDisplayCount && (
          <ButtonLoading onClick={() => setEmailDisplayCount(emailDisplayCount + 10)} className={classes.button}>
            Mehr anzeigen
          </ButtonLoading>
        )}
        {displayedEmails.length > 3 && (
          <ButtonLoading onClick={() => setEmailDisplayCount(3)} className={classes.button}>
            Weniger anzeigen
          </ButtonLoading>
        )}
      </ContentBox>
    </>
  );
};

Emails.propTypes = {
  product: PropTypes.object.isRequired,
};

export default Emails;
