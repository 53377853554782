import React, { useState } from "react";
import DisplayMediaObject from "../MediaObjectSection/DisplayMediaObject";
import makeStyles from "@mui/styles/makeStyles";
import courtAppointmentStyle from "./courtAppointmentStyle";
import { dateFieldDefault, relatedFieldDefault, requiredFieldDefault } from "../../services/validationRules";
import _ from "lodash";
import FormElement from "../Case/CaseForm/FormElement";
import SubmitButton from "../Button/SubmitButton";
import useForm from "../../hooks/useForm";
import { formValue, transformToFormValues } from "../../services/formServiceFunctions";
import { Grid } from "@mui/material";
import ApiClient from "../../services/ApiClient";
import AdvoAssistData from "../AdvoAssist/AdvoAssistData";
import moment from "moment";
import AdvoAssistActions from "../AdvoAssist/AdvoAssistActions";
import ButtonLoading from "../Button/ButtonLoading";
import LegalbirdIoConfirm from "../Modal/LegalbirdIoConfirm";
import ContentBox from "../ContentBox/ContentBox";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { getCaseLink } from "../../services/Product/ProductService";
import { apiPost } from "../../services/Api/apiCall";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../services/ReactQuery/reactQueryService";

const useStyles = makeStyles(courtAppointmentStyle);

export default function CourtAppointment({ headline, product, courtAppointment, refreshPage }) {
  const [mediaObjectToPreview, setMediaObjectToPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isOpen, setIsOpen] = useState("");
  const updateMutation = useMutation(updateResource);
  const classes = useStyles();

  const getCourtDateTypeOptions = (product) => {
    switch (product.productCategory) {
      case "contractLaw":
        return [{ value: "hearing" }, { value: "hearingOfEvidence" }];
      case "labourLaw":
        return [{ value: "settlement" }, { value: "trialLabourLaw" }];
      case "familyLaw":
        return [{ value: "divorce" }];
      case "trafficLaw":
        return [{ value: "trialTrafficLaw" }];
    }
  };

  const initialValues = transformToFormValues(courtAppointment);
  const onSubmit = async ({ values }) => {
    await updateMutation.mutateAsync({
      id: courtAppointment.courtDate.id,
      uri: "appointments",
      data: {
        appointmentDate: moment(formValue(values, "courtDate.appointmentDate")).format("YYYY-MM-DD"),
        appointmentTime: formValue(values, "courtDate.appointmentTime"),
      },
    });
    await updateMutation.mutateAsync({
      id: courtAppointment.id,
      uri: "court_appointments",
      data: {
        courtDateType: values.courtDateType,
      },
    });
    await apiPost("services/advo_assist/add_court_appointment/0", {
        courtAppointmentId: courtAppointment.id,
        appointmentType: values.courtDateType,
    });
    refreshPage();
  };

  const useFormProps = useForm({
    initialValues,
    onSubmit,
    identifier: courtAppointment,
  });

  const formDefinition = [
    {
      type: "ValidatorDateField",
      path: "courtDate.appointmentDate",
      translationPath: "courtAppointment.courtDate.appointmentDate",
      validators: [
        ...dateFieldDefault,
        ...relatedFieldDefault("courtDate.appointmentTime", "courtAppointment.courtDate.appointmentDate"),
      ],
      isDisabled: () => !!courtAppointment.advoAssist,
    },
    {
      type: "ValidatorTextField",
      path: "courtDate.appointmentTime",
      translationPath: "courtAppointment.courtDate.appointmentTime",
      validators: relatedFieldDefault("courtDate.appointmentDate", "courtAppointment.courtDate.appointmentTime"),
      isDisabled: () => !!courtAppointment.advoAssist,
      additionalProps: {
        type: "time",
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    {
      type: "ValidatorSelect",
      path: "courtDateType",
      translationPath: "courtAppointment.courtDateType",
      validators: requiredFieldDefault,
      options: ({ product }) => getCourtDateTypeOptions(product),
      isDisabled: () => !!courtAppointment.advoAssist,
    },
  ];

  const deleteCourtAppointment = async () => {
    if (!!courtAppointment.advoAssist) {
      return;
    }
    await ApiClient.delete(courtAppointment["@id"]);
    refreshPage();
  };

  return (
    <>
      <ContentBox headline={headline}>
        {!!courtAppointment.invitationCourtDocument && (
          <div
            className={classes.linked}
            onClick={() => setMediaObjectToPreview(courtAppointment.invitationCourtDocument)}
          >
            {courtAppointment.invitationCourtDocument.description}
          </div>
        )}
        <Grid container>
          {_.map(formDefinition, (formElementProps) => (
            <Grid className={classes.spacingRight} item md={6} xs={12} key={formElementProps.path}>
              <FormElement product={product} {...formElementProps} {...useFormProps} />
            </Grid>
          ))}
          {!courtAppointment.advoAssist && (
            <>
              <Grid item xs={12} className={classes.spacingBottom} />
              <Grid item md={6} xs={12} className={classes.spacingRight}>
                <ButtonLoading
                  onClick={() => setIsOpen("delete")}
                  isLoading={isLoading}
                  fullWidth
                  color="primary"
                  variant="text"
                >
                  Löschen
                </ButtonLoading>
                <LegalbirdIoConfirm
                  handleClose={() => setIsOpen("")}
                  open={isOpen === "delete"}
                  handleConfirm={deleteCourtAppointment}
                  confirmText="Löschen"
                  headline="Terminelement löschen"
                  content='Wenn das Terminelement versehentlich erstellt wurde, kann es mit dem Klick auf "Löschen" gelöscht werden.'
                />
              </Grid>
              <Grid item md={6} xs={12} className={classes.spacingRight}>
                <SubmitButton
                  onClick={() => setIsOpen("submit")}
                  className={classes.submitButton}
                  error={_.keys(useFormProps.errors).length > 0}
                  isLoading={useFormProps.isLoading}
                  disabled={_.keys(useFormProps.touchedValues).length === 0}
                  variant={"contained"}
                  type={"submit"}
                >
                  Speichern
                </SubmitButton>
                <LegalbirdIoConfirm
                  handleClose={() => setIsOpen("")}
                  open={isOpen === "submit"}
                  handleConfirm={useFormProps.handleSubmit}
                  confirmText={"Speichern und ausschreiben"}
                  headline={"Gerichtstermin ausschreiben"}
                  content={`Nach dem Klick auf “Speichern und Ausschreiben” wird automatisch ein Gerichtstermin bei AdvoAssist ausgeschrieben. Möchten Sie einen Gerichtstermin für den
                    ${moment(formValue(useFormProps.values, "courtDate.appointmentDate")).format("DD.MM.YYYY")} um
                    ${formValue(useFormProps.values, "courtDate.appointmentTime")} Uhr ausschreiben?`}
                />
              </Grid>
            </>
          )}

          {!!courtAppointment.advoAssist && (
            <>
              <AdvoAssistData
                advoAssist={courtAppointment.advoAssist}
                legalbirdAppointment={courtAppointment.courtDate}
              />
              <Grid item xs={12} className={classes.spacingBottom} />
              <AdvoAssistActions
                advoAssist={courtAppointment.advoAssist}
                legalbirdAppointment={courtAppointment.courtDate}
                product={product}
                refreshPage={refreshPage}
              />
            </>
          )}
          <Grid item md={6} xs={12} sx={{ padding: "0.5rem" }}>
            <Button
              sx={{ marginTop: 1 }}
              className={classes.submitButton}
              variant={"contained"}
              component={Link}
              to={getCaseLink(product) + "/handakte/" + courtAppointment.id}
            >
              Handakte bearbeiten
            </Button>
          </Grid>
        </Grid>
      </ContentBox>
      <DisplayMediaObject mediaObject={mediaObjectToPreview} closeDialog={() => setMediaObjectToPreview(null)} />
    </>
  );
}
