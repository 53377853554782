export const activityClasses = {
  tableHeadline: {
    textTransform: "initial",
    minWidth: 200,
  }
}

const activityStyle = (theme) => ({
  headline: {
    textAlign: "center",
  },
  activityCount: {
    fontSize: "1rem",
    fontWeight: 700,
  },
  activitiesOverdue: {
    marginLeft: "2rem",
    color: "#a31b1b",
  },
  filterContainer: {
    padding: "1rem",
    [theme.breakpoints.only("xs")]: {
      padding: 0,
    }
  },
  activityTable: {
    minWidth: 650,
  },
  spinner: {
    display: "block",
    margin: "0 auto",
  },
  note: {
    paddingRight: 10,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxHeight: 48,
    maxWidth: 100,
  },
  subject: {
    maxHeight: 48,
    maxWidth: 170,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  done: {
    textDecoration: "line-through",
  },
  overdue: {
    color: "#db1919",
  },
  mandatoryCell: {
    minWidth: 130,
  },
  descriptionCell: {
    minWidth: 170,
  },
  noteCell: {
    minWidth: 170,
  },
  activeSorting: {
    color: "#137F7B",
  },
  sortableHeadline: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  tableHeadline: {
    fontSize: "1.5rem",
    textAlign: "center",
    margin: "2rem",
  },
  tabContainer: {
    borderBottom: "1px solid #ddd",
  },
  tabPanel: {
    padding: 0,
  },
});

export default activityStyle;
