import React from "react";
import * as PropTypes from "prop-types";
import { Chip, Grid } from "@mui/material";
import { FolderOutlined, VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { MediaObject } from "../../types/MediaObject";

export default function MediaObjectChips({
    mediaObject,
    showFolder,
}: {
    mediaObject: MediaObject;
    showFolder: boolean;
}) {
    return (
        <Grid container spacing={1}>
            {showFolder && (
                <Grid item>
                    <Chip
                        label={mediaObject.electronicFileFolderPath}
                        icon={<FolderOutlined />}
                        size={"small"}
                        variant={"outlined"}
                    />
                </Grid>
            )}
            {mediaObject.showInExternalView && (
                <Grid item>
                    <Chip
                        label={"Für Extern sichtbar"}
                        icon={<VisibilityOutlined />}
                        size={"small"}
                        variant={"outlined"}
                    />
                </Grid>
            )}
            {mediaObject.showInGallery && (
                <Grid item>
                    <Chip
                        label={"Für Kunde sichtbar"}
                        icon={<VisibilityOutlined />}
                        size={"small"}
                        variant={"outlined"}
                    />
                </Grid>
            )}
            {!mediaObject.showInExternalView && !mediaObject.showInGallery && (
                <Grid item>
                    <Chip
                        label={"Nur intern sichtbar"}
                        icon={<VisibilityOffOutlined />}
                        size={"small"}
                        variant={"outlined"}
                    />
                </Grid>
            )}
        </Grid>
    );
}

MediaObjectChips.propTypes = {
    mediaObject: PropTypes.object.isRequired,
    showFolder: PropTypes.bool,
};

MediaObjectChips.defaultProps = {
    showFolder: true,
};
